import React, { Component } from "react";
import { Container, Section } from "design-react-kit";
import { MTable } from "components/table";

import { MnemoLoading, ModalHandler, BoxDanger, Title } from "components/misc";

import { ROUTES } from "const";
import { networkErrorHelper, adminService } from "libs";
import { MInput } from "components/forms";
import { withRouter } from "react-router-dom";
import { SearchUser } from "components/misc/SearchUser";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      payload: [],
      payloadToRender: [],
      rndKey: 0,
      whereObj: {
        roles: this.props.match.params.role,
        limit: 10000000, // must be convert to pagination
      },
      searchFilter: {
        searchFilter: {},
      },
    };
  } //constructor

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "name",
        Header: "Nome",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        id: "surname",
        Header: "Cognome",
        accessor: "surname", // accessor is the "key" in the data
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
      },
      {
        id: "mobile",
        Header: "Telefono",
        accessor: "mobile",
      },
      {
        id: "tmpEnabled",
        Header: "Stato",
        accessor: "tmpEnabled",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href =
            ROUTES.USER_LIST + "/" + this.state.whereObj.roles + "/" + value;
        },
      },
      {
        id: "delete",
        accessor: "id", // field value received in the onclick event
        label: "Elimina",
        icon: "it-delete",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.deleteRecord(e, value);
        },
      },
      {
        id: "state",
        accessor: "id", // field value received in the onclick event
        label: "Abilita/Disabilita",
        icon: "it-locked",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.manageStatus(e, value);
        },
      },
    ],
  };

  //--- ---

  componentDidMount() {
    this.loadRemote(this.state.whereObj);
  } //componentDidMount

  componentDidUpdate(prevProps) {
    if (this.props.match.params.role !== prevProps.match.params.role) {
      this.setState(
        {
          whereObj: {
            roles: this.props.match.params.role,
            limit: 10000000, // must be convert to pagination
          },
        },
        () => {
          this.loadRemote(this.state.whereObj);
        }
      );
      //this.loadRemote(this.props.match.params.role);
    }
  }

  loadRemote(whereObj) {
    adminService
      .getUser(whereObj)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.result).forEach(([key, val]) => {
          //--- manage exception to render human-readable data ---
          if (val.enabled) {
            val.tmpEnabled = "abilitato";
          } else {
            val.tmpEnabled = "disabilitato";
          }
          //--- ---

          items.push(val);
        });

        this.setState({
          loading: false,
          payload: items,
          payloadToRender: ["student"].includes(this.state.whereObj.roles)
            ? []
            : items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  viewRole = (arg) => {
    switch (arg) {
      case "student":
        return "Studenti";
      case "admin":
        return "Amministratori";
      default:
        return "";
    }
  }; //viewRole

  deleteRecord = (e, value) => {
    ModalHandler.show(
      value,
      "Gestione Account",
      "Vuoi cancellare definitivamente l'account ?",
      null,
      this.deleteConfirmed
    );
  }; //deleteRecord

  deleteConfirmed = (value) => {
    adminService
      .deleteUser(value)
      .then(({ data }) => {
        this.loadRemote(this.state.whereObj);
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
      });
  };

  //--- ---
  manageStatus = (e, value) => {
    ModalHandler.show(
      value,
      "Gestione Account Abilita/Disabilita",
      "Vuoi procedere?",
      null,
      this.changeStatusConfirmed
    );
  }; //manageStatus

  changeStatusConfirmed = (value) => {
    adminService
      .changeUserStatus(value)
      .then(({ data }) => {
        this.loadRemote(this.state.whereObj);
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
      });
  }; //changeStatusConfirmed

  onSearch = (keySearch) => {
    if (
      keySearch.length < 3 &&
      this.state.payloadToRender !== this.state.payload
    ) {
      this.setState({
        payloadToRender: [], //this.state.payload,
        rndKey: Math.floor(Math.random() * 1000 + 1),
      });
      return false;
    }

    if (keySearch.length < 3) return false;
    const results = this.state.payload.filter(function (item) {
      return (
        item.name.toLowerCase().includes(keySearch.toLowerCase()) ||
        item.surname.toLowerCase().includes(keySearch.toLowerCase()) ||
        item.email.toLowerCase().includes(keySearch.toLowerCase())
      );
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });
  }; //onSearch

  goToExt = (data) => {
    if (data?.userId && parseInt(data?.userId || 0) > 0) {
      window.location.href =
        ROUTES.USER_LIST + "/" + this.state.whereObj.roles + "/" + data.userId;
    }
  }; //goToExt

  //--- ---

  render() {
    const searchField = {
      id: "searchuser",
      name: "searchUser",
      label: "cerca per nome, cognome o email",
      type: "text",
      className: "form-control",
      onKeyUp: (elm, value) => {
        this.onSearch(value);
      },
    };

    let { loading } = this.state;
    /*if (this.state.payload.length === 0) {
			loading = false;
		}*/

    return (
      <>
        <div className="container mt-5 mb-4">
          <section className="my-4">
            <Section className="m-0 p-0">
              <Title>
                Lista Utenti: {this.viewRole(this.state.whereObj.roles)}
              </Title>
            </Section>

            {this.props.match.params.role === "student" ? (
              <SearchUser
                goToExt={this.goToExt}
                role={this.props.match.params.role}
              />
            ) : (
              <>
                {
                  <section className="m-0">
                    <div className="form-row m-0 p-0">
                      <div className="col-md-6">
                        <MInput {...searchField} className="mb-0" />
                      </div>
                    </div>
                  </section>
                }

                <hr />

                {loading ? (
                  <MnemoLoading></MnemoLoading>
                ) : (
                  <>
                    <Container>
                      <MTable
                        key={this.state.rndKey}
                        tableData={this.state.payloadToRender}
                        tableConfig={this.tableConfig}
                      />
                    </Container>

                    {this.state.payload.length === 0 && (
                      <BoxDanger key="nrp-1" className="my-3">
                        Lista utenti {this.viewRole(this.state.roleFilter)}.
                        <div className="small text-info">
                          {" "}
                          Non sono presenti dati.
                        </div>
                      </BoxDanger>
                    )}
                  </>
                )}
              </>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default withRouter(UserList);
