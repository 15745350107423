import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";
import { learningService, networkErrorHelper } from "libs";

class MUCoursePaymentRequest extends Component {

    state = {
        loading: true,
        loadingError: false,
        rCode: '',
        rType: ''
    };

    componentDidMount() {       
        const { rCode,rType } = this.props.match.params;
        this.loadRemote(rCode, rType);
    }

    loadRemote(rCode, rType) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {            
                const { course, userCourse } = data.payload;

                this.setState({ course, userCourse, loading: false, loadingError: false, rCode, rType });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true, rCode, rType  });
            });
    }

    renderIFrame() {

        const { course, rCode, rType } = this.state;

        const action = 'unicamillus'+rType+'Manager';
        const aaURL = course.aa.toString().slice(-2);

        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL)
            + action+'/paymentstep/rCode/' + rCode;
        let height = '1000px';
       return (
            <>
                <div>
                    <embed src={src} width="99%" height={height} />
                </div>
            </>
        );
    }

    render() {

        const { loading, loadingError, rCode } = this.state;
        if (loading) return <MnemoLoading />;

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const info = this.renderIFrame();

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
            </NavLink>
            <Title>Pagamento Richiesta </Title>
            {info}
        </>)
    }
}

export default withRouter(MUCoursePaymentRequest);