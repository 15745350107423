import React from "react";

import {
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder    
} from "libs";

import { BoxSuccess } from "components/misc";

export class EnrollFillFormABA extends ValidatedForm {

    PAYLOADS = {
        examSites: [{ label: 'selezionare', value: '' }, { label: 'Milano', value: 'Milano' }, { label: 'Palermo', value: 'Palermo' }, { label: 'Roma', value: 'Roma' }]
    }

    FIELDS_GROUP = [
        [
            {
                field: "examSite",
                label: "Scelta della sede per le lezioni in presenza",
                payload: { options: this.PAYLOADS.examSites },
                component: MSelect,
                className: "col-md-6"
            }
        ]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede per le lezioni in presenza',
    };

    validation = {
        examSite: value => value.length > 0,
    };

    emptyFields = {
        examSite: 0,
    };

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);

        const validations = {
            examSite: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            {code}
            <BoxSuccess>
                <b>ATTENZIONE:</b><br />
                <ul>
                <li>La sede scelta per le lezioni in presenza sarà attivata al raggiungimento di almeno n.30 iscritti.</li>
                <li>Qualora il numero degli iscritti dovesse essere inferiore a 30 per la sede scelta, Mnemosine si riserva di sostituire 
                le lezioni in presenza con delle videolezioni.</li>
                </ul>
            </BoxSuccess>
        </>);
    }
}