import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';
import { proxyBE } from 'libs';

export const examService = {       

    async getLiveLinkCFU(registrationCode){
        const payload = mnemoService.buildPayload('liveLinkCFU', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('liveexam/?payload='+encodeURI(payload));        
    },    

    async getLiveLinkPerfezionamento(registrationCode){
        const payload = mnemoService.buildPayload('liveLinkPerfezionamento', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('liveexam/?payload='+encodeURI(payload));        
    },  

    async ckeckCFUWrittenExam(registrationCode) {
        const payload = mnemoService.buildPayload('ckeckCFUWrittenExam', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('cfuexam/?payload='+encodeURI(payload));        
    },

    getProveProfitto(registrationCode) {
        return proxyBE.get('writtenExam/prova-profitto-all/'+registrationCode);
    },

    async confirmSMS(registrationCode, adCode){
        const payload = mnemoService.buildPayloadObject('confirmSMS', {'registrationCode': registrationCode, 'adCode' : adCode});
        return await proxyApiMnemo.get('sms/?payload='+encodeURI(payload));        
    },
    
    async ckeckPerfezionamentoWrittenExam(registrationCode) {
        const payload = mnemoService.buildPayload('ckeckPerfezionamentoWrittenExam', 'registrationCode', registrationCode);
        return await proxyApiMnemo.get('exam/?payload='+encodeURI(payload));        
    },

    async confirmSMSPerfezionamentoExamWritten(registrationCode){
        const payload = mnemoService.buildPayloadObject('confirmSMSPerfezionamentoExamWritten',{'registrationCode': registrationCode});
        return await proxyApiMnemo.get('sms/?payload='+encodeURI(payload));        
    },

    examInsert(payload) {
        return proxyBE.post('writtenExam/insert', payload);
    },

    examUpdate(payload) {
        return proxyBE.put('writtenExam/update', payload);
    },

    getExamByRCode(registrationCode) {
        return proxyBE.get('writtenExam/getExamByRegistrationCode/'+registrationCode);
    },

    confirmExamWritten(payload) {
        return proxyBE.put('writtenExam/confirmExamWritten', payload);
    },

    getExamReviewByIdExam(idExam) {
        return proxyBE.get('writtenExam/getExamReviewByIdExam/'+idExam);
    },

    downloadPdfExamWritten(idExam) {
        return proxyBE.get('writtenExam/downloadPdfExamWritten/'+idExam);
    },

}