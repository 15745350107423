import React, { Component } from "react";

import {
    Button,
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import {
    adminService,
    dateHelper,
    invalidFieldsMapper,
    networkErrorHelper
} from "libs";

import { getCurrentUser } from "store/actions";

export class UserDetailProfile extends Component {

    constructor(props) {
        super(props);    
        this.state = {
            verifyCf: this.props.data.canverifycf
        }
    }

    /*componentDidMount() {
        this.setState({
            verifyCf: this.props.data.canverifycf
        })
    }*/

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            verifyCf: nextProps.data.canverifycf
        })
    }

    handleCanVerifyCF = (userId) => {
        const data = {
            canverifycf: this.state.verifyCf,
            userId
        }
        adminService.updateVerifyCF(data).then((result) => {
            this.setState({
                verifyCf: (result.data.payload.updateCanVerifyCf === true) ? true : false
            });
        })
        .catch(error => {
            /** show right message errors */
            if (networkErrorHelper.is422(error) || networkErrorHelper.is404(error)) {
                const newState = invalidFieldsMapper(
                    error,
                    this.state,
                    this.ERROR_MESSAGES
                );
                this.setState({ ...newState });
            } else {
                networkErrorHelper.notify(error);
            }
        })
    }//handleCanVerifyCF

    //---

    render() {

        const userLoggedInfo = getCurrentUser();

        if (!this.props.data) return false;
        const { gender, dob, nationality, fiscalCode, phone, mobile, note } = this.props.data;
        
        const genderLabel = (gender !== null) ?
            (((gender.toLowerCase()) === 'm') ? 'Uomo' : 'Donna')
            : '';

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Dati Anagrafici</span>
                    </div>
                    <CardBody>
                        <div><b>Genere:</b> {genderLabel}</div>
                        <div><b>Data di Nascita:</b> {(dob !== null) ? dateHelper.toITDate(dob) : ''}</div>
                        <div><b>Nazionalità:</b> {nationality}</div>
                        <div><b>Codice Fiscale:</b> {fiscalCode}</div>
                        <div><b>Telefono:</b> {phone}</div>
                        <div><b>Cellulare:</b> {mobile}</div>
                        <div><b>Note:</b> {note}</div>


                        {((userLoggedInfo.roles === 'admin' || userLoggedInfo.roles === 'segreteria') && this.props.user.roles === 'student') && (
                            <>
                                <Button
                                    onClick={()=>{ this.handleCanVerifyCF(this.props.user.id)}}
                                    color={`btn btn-primary mt-3 p-3 ${(this.state.verifyCf === true) ? 'bg-success' : 'bg-danger'}`}>
                                    {(this.state.verifyCf === true) ? 'Disabilita' : 'Abilita'} verifica CF
                                </Button>
                            </>
                        )}

                    </CardBody>
                </Card>
            </>
        );
    }

}
