import React, { Component } from "react";
import Headroom from "react-headroom";

import "../../scss/components/mainMenuHeader.scss";

import IconLogout from "../../icons/IconLogout";

import axios from "axios";

export class NHStudent extends Component {
  state = {
    collapsed: true,
    menuItem: null,
    menuProfile: null,
    profileMenuOpen: false,
    isDropdownOpen: false,
    dropdownIndex: 0,
  };

  componentDidMount() {
    (async () => {
      let getMenuItem = null;
      let getMenuProfile = null;
      try {
        getMenuItem = await axios.get("/assets/json/NHStudent.json");
        getMenuProfile = await axios.get("/assets/json/NHStudent_profile.json");
        this.setState({
          menuItem: getMenuItem.data,
          menuProfile: getMenuProfile.data,
        });
      } catch (error) {
        this.setState({ error: true });
      }
    })();
  } //componentDidMount

  manageEvtByLink = (link) => {
    if (link === "/") {
      this.setState({ collapsed: true });
    }
  };

  manageEvtByLink = (link) => {
    this.setState({ collapsed: true });
  };

  //--- ---

  render() {
    const toggleMenuProfile = () => {
      this.setState({ profileMenuOpen: !this.state.profileMenuOpen });
    };

    if (!this.state.menuItem) {
      return false;
    }

    const openSubMenu = (element, index) => {
      if (index !== this.state.dropdownIndex) {
        this.setState({ dropdownIndex: index });
        this.setState({ isDropdownOpen: true });
      } else {
        this.setState({ dropdownIndex: index });
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
      }
    };

    const getSubMenu = (item, x) => {
      if (item.sub.length) {
        return (
          <Headroom className="mainDropdownMenu__headroom">
            <div
              className={`mainDropdownMenu ${
                this.state.isDropdownOpen && this.state.dropdownIndex === x
                  ? "open"
                  : ""
              }`}
              data-menu={`menu-item-${x}`}
            >
              <div className="mainDropdownMenu__wrapper">
                <ul className="mainDropdownMenu__items">
                  {item.sub.map((element, index) => {
                    if (element.label !== "divider") {
                      return (
                        <li
                          className="mainDropdownMenu__item"
                          key={`dropdownmenu-item-${index}`}
                        >
                          <a href={element.to} title="">
                            {element.label}
                          </a>
                        </li>
                      );
                    } else {
                      return (
                        <div className="divider" key={`divider-${index}`}></div>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </Headroom>
        );
      }
    };

    const getProfileSubMenu = (el, index) => {
      if (el.hasSubmenu && el.sub.length) {
        return (
          <ul className="profile__submenu">
            {el.sub.map((item, i) => {
              return (
                <li key={`item-${i}`}>
                  <a href={item.to} title={item.label}>
                    {item.label}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }
    };

    const getLinkProfile = (el) => {
      if (el.tag && el.tag === "logout") {
        return (
          <div onClick={this.props.logout}>
            <span className="icon-logout">
              <IconLogout />
            </span>
            {el.label}
          </div>
        );
      } else if (el.to) {
        return (
          <a href={el.to} title={el.label}>
            <span>{el.label}</span>
          </a>
        );
      }
      // {el.tag && el.tag == "logout" ? (<span className="icon-logout"><IconLogout /></span>) : ''}
      // {el.label}
    };

    // const isOpen = !this.state.collapsed;
    const { menuItem } = this.state;
    const menuProfile = this.state.menuProfile;

    // const additional = menuItem ? this.renderMenu(menuItem) : <></>;

    return (
      <>
        <div className="mainMenuContainer">
          <div className="mainMenuContainer__navigation">
            <ul className="mainMenuContainer__navigation__links">
              {menuItem.map((item, x) => {
                if (item.to) {
                  return (
                    <li key={`menu-item-${x}`} className="link-item">
                      <a
                        className="link-item__text"
                        href={item.to}
                        title={item.label}
                      >
                        <span>{item.label}</span>
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li key={`menu-item-${x}`} className="link-item">
                      <div
                        className={`link-item__text dropdown ${
                          this.state.isDropdownOpen &&
                          this.state.dropdownIndex === x
                            ? "open"
                            : ""
                        }`}
                        data-menu={`menu-item-${x}`}
                        onClick={(e) => openSubMenu(e.currentTarget, x)}
                      >
                        <span className="text">{item.label}</span>
                        <span className="icon">
                          <img
                            src="/img/icon/icon-arrow-down.svg"
                            alt="dropdown icon"
                          />
                        </span>
                      </div>
                      {getSubMenu(item, x)}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          {/* USER */}
          <div
            className={
              "mainMenuContainer__profile " +
              (this.state.profileMenuOpen ? "open" : "")
            }
            onClick={() => toggleMenuProfile()}
          >
            <div className="profile__link">
              <div className="profile__icon">
                <img
                  src="/img/icon/icon-profile.svg"
                  alt="accedi all'area riservata"
                />
              </div>
              <div className="profile__text">
                {/* {this.props.user.name.length > MAX_LENGTH ? 
                            (
                              <span>{`${this.props.user.name.substring(0, MAX_LENGTH)}..`}</span>
                            )  
                            :
                            <span>{this.props.user.name}</span>
                            } */}
                Il mio profilo
                <span className="icon">
                  <img
                    src="/img/icon/icon-arrow-down.svg"
                    alt="dropdown icon"
                  />
                </span>
              </div>
            </div>

            <div className="dropdownMenuProfile">
              <div className="profile-name">Ciao, {this.props.user.name}</div>
              <ul className="dropdownMenuProfile__items">
                {menuProfile.map((el, index) => {
                  return (
                    <li
                      className="dropdownMenuProfile__item"
                      key={`profile-item-${index}`}
                    >
                      <div
                        className={
                          "label " +
                          (!el.to ? "first" : "") +
                          (el.tag === "logout" ? "logout" : "")
                        }
                      >
                        {el.tag !== "logout" ? el.label : ""}
                        {getLinkProfile(el)}
                      </div>

                      {getProfileSubMenu(el, index)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* END USER */}
        </div>
      </>
    );
  }
}
