import React, { Component } from "react";
import { MnemoLoading, PDFView } from "components/misc";
import { enrollmentService, networkErrorHelper } from "libs";


export class UserEnrollmentDocument extends Component {

    state = {
        pageNumber: 0,
        numPages: 0,
        accept: false,
        confirmedMobile: false,
        loading: true,
        loadingError: false,
        userEnrollmentFile: null,
    }

    componentDidMount() {

        this.loadDocument(this.props.parentId);
    }

    loadDocument(enrollmentId) {

        enrollmentService.prepareSign(enrollmentId)
            .then(({ data }) => {

                const { userEnrollmentFile } = data.payload;
                //update after 3 seconds: because the document in S3 isn't istantly available 
                setTimeout(() => {  this.setState({ loading: false, loadingError: false, userEnrollmentFile }) }, 3000);
            })
            .catch(errors => {
                console.log(errors);
                networkErrorHelper.notify(errors);
                this.setState({ loading: false, loadingError: true });
            });                          
    }

    render() {

        const { userEnrollmentFile, loading, loadingError } = this.state;
       
        if (loading) return (<MnemoLoading />);
        
        if (loadingError){
            if (this.props.onError) this.props.onError();
            return (<></>); 
        } else{
            if (this.props.onComplete) this.props.onComplete();
        }

        const file = { url: process.env.REACT_APP_API_URL + '/enrollment/download/' + userEnrollmentFile.id };

        return (<>
            <PDFView file={file.url} />
        </>);
    }
}