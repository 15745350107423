import React from "react";

import {
    Card,
    CardBody,
    Icon, Row, Col
} from 'design-react-kit';

import {
    MToggle, MCheckbox,
    FormEnabler,
    ValidatedForm,
    MInput,
    MRadioButton, MTextArea
} from "components/forms";
import { MCE } from "components/forms";

const RADIO_EXAMPLE =
    [
        { value: "identityCard", label: "Carta d'identità", infoText: 'se possiedi una carta di indentità valida' },
        { value: "drivingLicence", label: "Patente di guida" },
        { value: "passport", label: "Passaporto", infoText: "solamente se passaporto tilasciato in Italia" },
    ];
export class Form extends ValidatedForm {

    state = {
        isForeign: 0,
        formActive: false
    };

    onChange = (field, value) => {
        console.log('--',field, '***',value);
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    loadRemote(additionalState = {}) {

        const newState = {
            ...additionalState
        };

        this.setState(newState);
    }

    loadForm() {

        const { formActive, isForeign, address, testo } = this.state;
        let formValid = true;
        const payload_radio = { options: RADIO_EXAMPLE };

        return (
            <>
                <form className="user" id="example_form">
                    <Row>
                        <Col md={6}>
                            <MCheckbox
                                id='isForeign'
                                label="Indirizzo estero"
                                value={isForeign}
                                disabled={!formActive}
                                infoText="Specificare se è un indirizzo estero o italiano"
                                onChange={this.onChange}
                            />
                        </Col>
                        <Col md={6}>
                            <MToggle
                                id='isForeign'
                                label="Indirizzo estero"
                                value={isForeign}
                                disabled={!formActive}
                                infoText="Specificare se è un indirizzo estero o italiano"
                                onChange={this.onChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <MRadioButton
                                id="documentType"
                                name="documentType"
                                label="scegli il documento"
                                payload={payload_radio}
                                disabled={!formActive}                                
                                infoText="Specificare un documento di identità"
                                value="drivingLicence"
                                onChange={this.onChange}
                            ></MRadioButton>
                        </Col>
                        <Col md={6}>
                            <MInput
                                id='address'
                                label="Indirizzo"
                                value={address}
                                invalid={true}
                                disabled={!formActive}
                                infoText="Specificare se è un indirizzo estero o italiano"
                                onChange={this.onChange}

                            />
                        </Col>
                    </Row>                   
                    <Row>
                        <Col>
                            <MCE
                                id='testo2'
                                name='testo2'
                                label="Testo2"
                                value="<h1>titolo</h1><p><b>testo</b> iniziale</p>"
                                invalid={true}
                                disabled={!formActive}
                                infoText="Specificare il testo2"
                                onChange={this.onChange}
                            ></MCE>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MTextArea
                                id='testo'
                                label="Testo"
                                value={testo}
                                invalid={false}
                                disabled={!formActive}
                                infoText="Specificare il testo"
                                onChange={this.onChange}
                            ></MTextArea>
                        </Col>
                    </Row>
                </form>
                <div >
                    <FormEnabler
                        onToggle={this.toggleForm}
                        isFormActive={formActive}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        disableSave={!formValid}
                    />
                </div>
            </>
        );
    }

    render() {


        let form = this.loadForm();
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-card" padding={false} />
                        <span>Form di esempio</span>
                    </div>
                    <CardBody tag="div">
                        {form}

                    </CardBody>
                </Card>
            </>
        );
    }
}