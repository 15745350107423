import React, { Component } from "react";
import { Container } from "design-react-kit";
import { Title, MnemoLoading, BoxDanger, BoxSuccess, SupportoTecnico } from "components/misc";
import { MobileValidation } from "components/tools";
import { MSection } from "components/misc/MSection";
import {
    userService,
    networkErrorHelper
} from "libs";
import { Link } from "react-router-dom";
import { ROUTES } from "const";

export class MobileConfirm extends Component {

    state = {
        confirmedMobile: false,
        loading: true,
        loadingError: false,
        action: 'conferma profilo',
        tableName: 'userProfile',
        idParent: 0,
        phoneNumber: ''
    };

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile() {
        userService
            .profile()
            .then(({ data }) => {

                const { id, mobile } = data.payload.profile;
                this.setState({ idParent: id, phoneNumber: mobile, loading: false });
            })
            .catch(error => {
                this.setState({ loading: false, loadingError: true });
                networkErrorHelper.notify(error);
            });
    }

    onConfirmed = () => {
        this.setState({ confirmedMobile: true });
    }

    render() {

        const { loading, loadingError, confirmedMobile } = this.state;

        return (
            <>
                {loading ? (<MnemoLoading></MnemoLoading>) : (
                    <>
                        {loadingError ? (<BoxDanger>Si è verificato un errore durante
                            il caricamento della pagina, se il problema persiste <SupportoTecnico /></BoxDanger>) : (
                            <>
                                <Container>
                                    <Title subtitle="Tramite questa procedura è possibili convalidare il tuo numero di telefono">Conferma numero di telefono</Title>
                                    {confirmedMobile && (
                                        <BoxSuccess>Grazie per aver confermato il numero di telefono. Vai alla <Link to={ROUTES.DASHBOARD}>tua area </Link>per completare i tuoi dati.</BoxSuccess>
                                    )}
                                    <MSection className="mb-3">
                                        <MobileValidation {...this.state} onConfirmed={this.onConfirmed}  ></MobileValidation>
                                    </MSection>
                                </Container>
                            </>
                        )}
                    </>
                )}
            </>
        );
    }
}