import React, { Component } from 'react';

import { BoxInfo, MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class RequestCertification extends Component {

    render() {
        const title = 'ATTESTAZIONE CFU';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <p>
                                In questi giorni alcuni docenti che hanno aderito alle nostre Offerte Formative relativamente ai Corsi erogati dall’Università per stranieri “Dante Alighieri” di Reggio Calabria, chiedono la certificazione
                                dei CFU conseguiti per verificare se tali CFU soddisfano il requisito di accesso al prossimo Concorso.</p>
                            <p>
                                I CFU già conseguiti, a  nostro avviso non sono utili, in questo caso specifico, per soddisfare le condizioni del D.M. n. 616 pubblicato il 10 Agosto 2017.</p>

                            <p>Infatti, relativamente ai Corsi Universitari sui 24 CFU, preme sottolineare che l’art. 3 comma 5 del Decreto Ministeriale n. 616 del 10 Agosto 2017
                                prevede una specifica  “Certificazione aggiuntiva” da parte dell’Università che attesti il raggiungimento degli obiettivi formativi previsti specificatamente
                                nell’Allegato A annesso al suddetto Decreto. Poiché tale “Allegato” è stato elaborato nel mese di Agosto 2017 è ovvio che tutte le discipline programmate
                                prima di tale data non possono rispecchiare e soddisfare tali obiettivi formativi. Inoltre, con Circolare n. 29999 del 25/10/2017, il MIUR stabilisce
                                che il riconoscimento non è automatico: cioè non basta indicare i CFU con i vari SSD (Settori Scientifici Disciplinari)
                                ma devono essere indicati anche gli obiettivi formativi che devono essere uguali a quelli stabiliti nel DM 616/2017.</p>

                            <p>Se si è interessati ai  24 CFU  confermiamo che i Corsi presenti su  <a href="https://www.formazionedocenti.it/corsi-online/24CFU">https://www.formazionedocenti.it/corsi-online/24CFU</a>  sono erogati da Università non telematica e rispecchiano tutte le condizioni imposte dal D.M. n. 616 del 10 Agosto 2017. Ovviamente per tali corsi sarà possibile rilasciare la specifica Certificazione</p>
                            <p>Tuttavia, il MIUR impone la costituzione di una commissione che valuterà i vari percorsi didattici.
                                A tal proposito, fermo restando la nostra opinione in merito, l’Università per stranieri “Dante Alighieri” di Reggio Calabria, facendo seguito alla Circolare n. 29999 del 25/10/2017, ha deliberato una procedura per l’eventuale valutazione dei CFU.</p>

                            <BoxInfo>
                                <h6> ATTESTAZIONE CFU CONSEGUITI PRESSO L’UNIVERSITA' DANTE ALIGHIERI</h6>

                                <p>Di seguito riportiamo le procedure stabilite dall’Università</p>
                                <p>Coloro che intendono iscriversi al percorso formativo 24 CFU presso altre Istituzioni universitarie, ed in possesso di
                                    crediti acquisiti presso l’Università per Stranieri “Dante Alighieri” di Reggio Calabria, possono richiedere l’attestazione
                                    riguardante l’attività svolta presso questo Ateneo compilando e presentando il <a href="http://www.unistrada.it/component/riconoscimento/?view=modelloa" target="_blank" rel="noopener noreferrer" >Modello A</a>.</p>

                                <p>Pertanto, per le richieste di riconoscimento, è necessario compilare il <a href="http://www.unistrada.it/component/riconoscimento/?view=modelloa" target="_blank" rel="noopener noreferrer" >Modello A</a> allegando
                                    la documentazione sopra indicata comprensiva bella copia del pagamento di € 36,00 da corrispondere con Bonifico bancario sul conto corrente:</p>
                                <ul>
                                    <li>intestato a:Università per Stranieri “Dante Alighieri” di Reggio Calabria</li>
                                    <li>Iban: IT76P0103016300000003576086</li>
                                    <li>Causale: Richiesta Attestazioni 24 CFU</li>
                                </ul>
                            </BoxInfo>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}