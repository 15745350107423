import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next} from 'react-i18next';
//import Cache from 'i18next-localstorage-cache';

i18n
    .use(LanguageDetector)
    .use(Backend)
    //use(Cache)
    .use(initReactI18next)
    .init({
        //saveMissing: true,
        //saveMissingTo: "all", //current
        /*missingKeyHandler: (ng, ns, key, fallbackValue) => {
            console.log(ng, ns, key, fallbackValue)
        },*/       
        wait: true,
        //initImmediate: false,
        lng: 'it',
        //lng: "it-IT",
        fallbackLng: ['it','en'],
        debug: false,
        detection:{
            order: ['localStorage', 'navigator', 'querystring', 'htmlTag', 'path', 'subdomain', 'cookie', 'sessionStorage'],
            cache: ['localStorage', 'cookie'],
            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,
        },
        interpolation:{
            escapeValue: false
        },
        react: {
           wait: true,
           useSuspense: false
        }
    })

export default i18n;