import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, MSection, SupportoTecnico, Title } from "components/misc";
import { courseService, networkErrorHelper } from "libs";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";

class CourseTutor extends Component {

    state = {
        loading: true,
        ladingError: false,
        rCode: '',
        tutor: 0
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.setState({ rCode: rCode });
        
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        courseService.myTutor(rCode)
            .then(({ data }) => {

                this.setState({
                    loading: false,
                    tutor: data.payload
                })
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({
                    loading: false,
                    ladingError: true
                })
            });
    }

    render() {

        const { loading, loadingError, tutor, rCode } = this.state;


        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError || !tutor) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        const {name, surname, phonetutor, availabilityI, availabilityII, availabilityIII } = tutor;


        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Tutor didattico</Title>
            <MSection>
                <p>Per tutte le informazioni e chiarimenti sul materiale didattico puoi rivolgerti al tutor a te assegnato  {surname}  {name} al seguente numero di telefono {phonetutor}, 
                nelle seguenti giornate:</p>
                <ul>
                    <li>{availabilityI}</li>
                    <li>{availabilityII}</li>
                    <li>{availabilityIII}</li>
                </ul>

                <p>In caso di problemi tecnici puoi <SupportoTecnico />.</p>
            </MSection>
        </>)
    }
}

export default withRouter(CourseTutor);