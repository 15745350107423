import { Title, MSection, BoxDanger, MnemoLoading, BoxSuccess } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";
import { icdlService, learningService, networkErrorHelper } from "libs";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';

class ICDLLesson extends Component {

    state = {
        loading: true,
        loadingError: false,
        isEnabled: 0,
        course: 0,
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse });

                icdlService.getAICACodes(rCode)
                    .then(({ data }) => {
                        const codes = data.payload;

                        this.setState({ loading: false, loadingError: false, codes, rCode });
                    })
                    .catch(error => {
                        networkErrorHelper.notify(error);
                        this.setState({ loading: false, loadingError: true });
                    });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { rCode } = this.props.match.params;
        const { loading, codes, course } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >AICA Digital Academy</Title>
                <MSection>
                    <h6><a href="https://www.aicadigitalacademy.it/aica_academy/" target="_blank" rel="noopener noreferrer"  >Clicca qui per accedere alla piattaforma piattaforma "Aica digital academy"</a></h6>
                    <footer className="blockquote-footer">http://www.aicadigitalacademy.it</footer>
                </MSection>
                <MSection className="mt-2">
                    <h1>Istruzioni Operative</h1>
                    <p>Dopo aver cliccato sul link di accesso <b>Aica digital academy</b> ti ritroverai davanti la seguente schermata (Figura 1). </p>
                    <div className="image-container">
                        <img className="image-90 mb-1" src="/img/learning/aica/01.png" alt="Registrazione piattaforma AICA" />
                        <footer className="blockquote-footer">Figura 1. Registrazione piattaforma AICA</footer>
                    </div>
                    <p>Al primo accesso alla piattaforma “Aica digital academy” è richiesto di registrarsi facendo clic sul pulsante Login che
                        è presente nella barra superiore della schermata (Figura 1) e poi su Crea un account nella schermata successiva
                        (Figura 2):</p>
                    <div className="image-container">
                        <img className="image-90 mb-1" src="/img/learning/aica/02.png" alt="Crea un account" />
                        <footer className="blockquote-footer">Figura 2. Crea un account</footer>
                    </div>
                    <p>La Figura 3 mostra i passaggi successivi, dove si richiede Quanti anni hai? e In quale nazioni vivi?</p>
                    <div className="image-container">
                        <img className="image-90 mb-1" src="/img/learning/aica/03.png" alt="Verifica dell'età e della nazione" />
                        <footer className="blockquote-footer">Figura 3. Verifica dell'età e della nazione</footer>
                    </div>
                    <p>Il passo seguente, dopo aver cliccato su Prosegui, richiede l'inserimento dei seguenti campi obbligatori:</p>
                    <ul>
                        <li>username (a scelta dell'utente)</li>
                        <li>password (a scelta dell'utente)</li>
                        <li>e-mail</li>
                        <li>nome</li>
                        <li>cognome</li>
                        <li>accettazione delle condizioni di utilizzo</li>
                    </ul>
                    {(course.code === 'ICDL238') ? (<>
                        <p>
                            Una volta cliccato su Crea il mio nuovo account, riceverà un'e-mail di conferma registrazione. Una volta confermata
                            l'e-mail e ottenuto l'accesso al sistema, il codice di seguito riportato Le permetterà di attivare il modulo IT SECURITY
                            e di approfondire i contenuti (vedi Figura 4):</p>
                        {(codes) && (
                            <BoxSuccess>Codice Attivazione IT Security: {codes[0]}</BoxSuccess>
                        )}
                        <div className="image-container">
                            <img className="image-90 mb-1" src="/img/learning/aica/04.png" alt="Attivazione Corso IT Security" />
                            <footer className="blockquote-footer"> Figura 4. Attivazione Corso IT Security</footer>
                        </div>
                        <BoxDanger><b>ATTENZIONE</b><hr />Le ricordiamo che per attivare il modulo didattico occorre inserire il codice fornito nella relativa casella Inserisci
                            codice della sezione MiCertifico del sito, di selezionare il modulo IT SECURITY dal menu a tendina Seleziona Corso e
                            di cliccare su Attiva Codice (Figura 4). Dal momento dell'attivazione avrà a disposizione i materiali didattici per i
                            successivi 12 mesi.
                            <hr />
                            <p>Nel caso di attivazione di moduli diversi da quelli acquistati, si dovr&agrave; versare un contributo di Euro 100,00
                                per ogni nuovo codice di attivazione. </p>
                        </BoxDanger>
                    </>) : (<>
                        <p>
                            Una volta cliccato su Crea il mio nuovo account, riceverà un'e-mail di conferma registrazione. Una volta confermata l'e-mail e ottenuto l'accesso al sistema, i 7 codici di seguito riportati Le permetteranno di attivare i 7 moduli del corso e di approfondire i contenuti (vedi Figura 4):</p>
                        {(codes) && (
                            <BoxSuccess>
                                <p>Codici attivazione moduli</p>
                                <ul>
                                    {codes.map(item => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                            </BoxSuccess>
                        )}
                        <div className="image-container">
                            <img className="image-90 mb-1" src="/img/learning/aica/05.png" alt="Attivazione Corso IT Security" />
                            <footer className="blockquote-footer"> Figura 4. Attivazione Moduli del Corso</footer>
                        </div>
                        <BoxDanger><b>ATTENZIONE</b><hr />
                            <p>Le ricordiamo che per attivare ciascun modulo didattico occorre inserire uno dei
                                codici forniti nella casella Inserisci codice della sezione MiCertifico del sito,
                                di selezionare il modulo che si vuole attivare dal menu a tendina Seleziona Corso
                                e di cliccare su Attiva Codice (Figura 4). L'utente è libero di associare ciascun
                                codice ad uno qualsiasi dei sette moduli fino ad utilizzarli tutti. E' chiaro
                                che ciascun codice è utilizzabile una sola volta e per un solo modulo.
                                Dal momento dell'attivazione avrà a disposizione i materiali didattici per i
                                successivi 12 mesi. Per questo motivo consigliamo di attivare i moduli uno alla
                                volta, man mano si procede con lo studio e la preparazione.</p>
                            <hr />
                            <b>IMPORTANTE</b><hr />
                            <p>Come comunicato recentemente da AICA, è stato introdotto il Syllabus 6.0 per i
                                moduli d'esame ICDL Word Processing, Spreadsheet e Presentation. La differenza sta,
                                sostanzialmente, nella versione dei suddetti moduli che sarà la 2016.
                                Si invita, pertanto, a preparare i suddetti esami con il Syllabus 6.0,
                                ovvero con la versione “2016” dei moduli Word Processing, Spreadsheet
                                e Presentation, ricordando che il materiale didattico è a disposizione nella
                                piattaforma di e-learning “AICA Digital Academy”. Invitiamo, quindi, a prestare
                                la massima attenzione nell'attivazione e nella preparazione dei moduli in quanto
                                l'esame finale verterà sulla nuova versione.</p>
                        </BoxDanger>
                        <p>In Figura 5, vengono mostrati i moduli che è possibile attivare ed i 7 moduli corrispondenti alla Certificazione Full Standard,
                            ovvero quelli da attivare:</p>
                        <div className="image-container">
                            <img className="image-90 mb-1" src="/img/learning/aica/06.png" alt="Attivazione Corso Full" />
                            <footer className="blockquote-footer"> Figura 5. Selezione ed attivazione dei singoli moduli del Corso ICDL Full Standard - 7 moduli</footer>
                        </div>
                        <hr />
                        <p>Nel caso di attivazione di moduli diversi da quelli acquistati, si dovr&agrave; versare un contributo di Euro 100,00
                                per ogni nuovo codice di attivazione. </p>
                    </>)}
                </MSection>
            </>);
    }
}

export default withRouter(ICDLLesson);