import React from "react";

export class CertificationRequestStart {

    title = 'INFO';
    iconName = 'it-pa';
    certificationRequest = null;
    userProfile = null;
    user = null;

    setPayload(payload) {
        this.certificationRequest = payload.certificationRequest;
        this.userProfile = payload.userProfile;
        this.user = payload.user;
    }

    async onNextStep() {
        return true;
    }

    render() {

        return (<>
            <img className="unicamillus-banner" alt="Unicamillus" src="https://www.formazionedocenti.it/files/images/Unicamillus_logo.png" />
            <hr/>
            <p className="m-3">Tramite questa procedura è possibile ricevere l'invio della certificazione dei corsi singoli universitari sostenuti rilasciata dall'Università Italiana UniCamillus (Saint Camillus International University of Health Sciences).   </p>
            <p className="m-3">Per inizare la procedura clicca sul tasto <b>AVANTI</b></p>
        </>);
    }
}