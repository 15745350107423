import React, { Component } from "react";
import { ROUTES } from "const";
import { Link } from "react-router-dom";
import { BookCard } from "components/biblioteca";
import IconArrowRight from "icons/IconArrowRight";

import "./BooksList.scss";

export class BooksList extends Component {
  render() {
    const { books, title } = this.props;
    return (
      <div className="booksList">
        <div className="booksList__container">
          {title || this.props.showAll ? (
            <div className="booksList__header">
              {title ? <h2 className="booksList__title">{title}</h2> : null}
              {this.props.showAll ? (
                <Link
                  className="booksList__cta"
                  to={{
                    pathname: `${ROUTES.PAGE_BIBLIOTECA}/libri/`,
                  }}
                >
                  <span>Scopri tutti i libri</span>
                  <IconArrowRight className="icon" />
                </Link>
              ) : null}
            </div>
          ) : null}
          {books ? (
            <div className="booksList__wrapper">
              {books.map((book, index) => (
                <BookCard key={index} book={book} />
              ))}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
