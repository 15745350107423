import React, { Component } from "react";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import { MTable } from "components/table";
import { dateHelper } from "libs";

export class UserDetailSMSList extends Component {

    constructor(props) {
      super(props);
  
      this.state = {
        loading: false,
        rndKey: 0
      }
    }
  
    //--- ---  
    tableConfig = {
      columns: [
        {
          id: 'id', // identifier of columns
          Header: 'Column 0',
          isVisible: false, // IMPORTANT: hidden columns
          accessor: 'id', // accessor is the "key" in the data
  
        },
        {
          id: 'codeCreated',
          Header: 'Codice',
          accessor: 'codeCreated',
  
        },
        {
          id: 'dateCreated',
          Header: 'Data Creazione',
          accessor: row => { return (row.dateCreated) ? dateHelper.toITDate(row.dateCreated) : '' },
  
        },
        {
          id: 'timeCreated',
          Header: 'Ora Creazione',
          accessor: 'timeCreated',
  
        },
        {
          id: 'codeConfirmed',
          Header: 'Codice Confermato',
          accessor: 'codeConfirmed',
        },
        {
          id: 'action',
          Header: 'Utilizzare per',
          accessor: 'action',
        },
        {
          id: 'phoneNumber',
          Header: 'Inviato a',
          accessor: 'phoneNumber',
        },
        {
          id: 'dateConfirmed',
          Header: 'Data Conferma',
          accessor: row => { return (row.dateConfirmed) ? dateHelper.toITDate(row.dateConfirmed) : '' },
  
        },
        {
          id: 'timeConfirmed',
          Header: 'Ora Conferma',
          accessor: 'timeConfirmed',
  
        }
      ]
    }

    render() {

        if (!this.props.data) return false;
        let data = this.props.data;
        const { rndKey } = this.state;
       
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>SMS</span>
                    </div>

                    <CardBody>
                        <MTable key={rndKey} tableData={data} tableConfig={this.tableConfig} />
                    </CardBody>
                </Card>
            </>
        );
    }

}
