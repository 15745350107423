import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col, Icon
} from 'design-react-kit';

import { ROUTES } from "const";
import { Helmet } from 'react-helmet';



export class QualificationEvaluation extends Component {

    qualification_map = () => {

        window.location = ROUTES.QUALIFICATION_MAP;
    }

    render() {
        const title = 'TITOLI DI STUDIO - INSEGNAMENTI O CLASSI DI CONCORSO';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection className="mb-1">
                    <Title>{title}</Title>
                    <hr />
                    <div className="d-xs-flex flex-row">
                        <a className="btn btn-primary bg-dark m-1" href="/corrispondenza-titoli">Titoli di studio - Insegnamenti o Classi di Concorso</a>
                        <a className="btn btn-primary bg-dark primary bg-dark m-2" rel="noopener noreferrer" href="https://miurjb5.pubblica.istruzione.it/VisSediEspr/" target="_blank" >Elenco Scuole Italiane</a>
                    </div>
                </MSection>
                <MSection className="mb-1">
                    <Row>
                        <Col>
                            <h3 className='text-center'>Valutazione titoli docenti diplomati</h3>
                            <hr className="line-green" />

                            <h5>Corsi di Perfezionamento annuali post-diploma di 1500 ore e 60 crediti formativi</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.7) <b>punti 3,00</b></li>
                                <li><Icon icon="it-check" />Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Mobilità e Trasferimenti (<a href="/assets/doc/Sintesi_CCNI_18052022.pdf" target="_blank">C.C.N.I. del 18/05/2022</a> Tabella Titoli Generali Lettera D) <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Concorso a Cattedra (<a href="/assets/doc/tabella_titoli_concorso_docenti.pdf" target="_blank">Tabella Titoli</a>) <b>punti 1,50</b></li>
                            </ul>

                            <h5>Corsi di Perfezionamento annuali post-diploma inferiori a 1500 ore</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.8) <b>punti 1,00</b></li>
                            </ul>
                            <hr />
                        </Col>
                    </Row>
                </MSection>
                <MSection className="mb-1">
                    <Row>
                        <Col>
                            <h3 className='text-center'>Valutazione titoli docenti laureati</h3>
                            <hr className="line-green" />

                            <h5>Corsi di Perfezionamento Annuali post-laurea di 1500 ore e 60 crediti formativi</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.7) <b>punti 3,00</b></li>
                                <li><Icon icon="it-check" />Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 1,00;</b></li>
                                <li><Icon icon="it-check" />Graduatorie per Incarichi Dirigenti Scolastici - <a href="/assets/doc/tabella_incarichi_dirigenti_scolastici.zip">Tabella Incarichi</a> <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Mobilità e Trasferimenti (<a href="/assets/doc/Sintesi_CCNI_18052022.pdf" target="_blank">C.C.N.I. del 18/05/2022</a> Tabella Titoli Generali Lettera D) <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Concorso a Cattedra (<a href="/assets/doc/tabella_titoli_concorso_docenti.pdf" target="_blank">Tabella Titoli</a>) <b>punti 1,50</b></li>
                            </ul>

                            <h5>Corsi di Perfezionamento annuali post-laurea inferiori a 1500 ore</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.8) <b>punti 1,00</b></li>
                            </ul>
                            <hr />

                            <h5>Corsi Master annuali post-laurea di I Livello</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.7) <b>punti 3,00</b></li>
                                <li><Icon icon="it-check" />Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 1,00;</b></li>
                                <li><Icon icon="it-check" />Graduatorie per Incarichi Dirigenti Scolastici - <a href="/assets/doc/tabella_incarichi_dirigenti_scolastici.zip">Tabella Incarichi</a> <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Mobilità e Trasferimenti (<a href="/assets/doc/Sintesi_CCNI_18052022.pdf" target="_blank">C.C.N.I. del 18/05/2022</a> Tabella Titoli Generali Lettera D) <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Concorso Dirigenti Scolastici - <a href="/assets/doc/Tabella_valutazione_titoli_concorso_dirigenza_scolastica.pdf" target="_blank">Tabella Concorso</a> <b>punti 1,00</b></li>
                                <li><Icon icon="it-check" />Concorso a Cattedra (<a href="/assets/doc/tabella_titoli_concorso_docenti.pdf" target="_blank">Tabella Titoli</a>) <b>punti 1,50</b></li>
                            </ul>

                            <h5>Corsi di Perfezionamento Biennale post-laurea di 1500 ore e 60 crediti formativi Annuali</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Graduatorie ad Esaurimento - Terza e Quarta Fascia (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.6) <b>punti 6,00</b></li>
                                <li><Icon icon="it-check" />Mobilità e Trasferimenti (<a href="/assets/doc/Sintesi_CCNI_18052022.pdf" target="_blank">C.C.N.I. del 18/05/2022</a> Tabella Titoli Generali Lettera B) <b>punti 5,00</b></li>
                                <li><Icon icon="it-check" />Graduatorie Perdenti posto personale a T.I. (già di ruolo) <b>punti 5,00;</b></li>
                                <li><Icon icon="it-check" />Graduatorie per Incarichi Dirigenti Scolastici - <a href="/assets/doc/tabella_incarichi_dirigenti_scolastici.zip">Tabella Incarichi</a> <b>punti 3,00</b></li>
                                <li><Icon icon="it-check" />Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 2,00</b></li>
                                <li><Icon icon="it-check" />Concorso a Cattedra(<a href="/assets/doc/tabella_titoli_concorso_docenti.pdf" target="_blank">Tabella Titoli</a>)  <b>(fino ad un massimo di punti 10)</b></li>
                            </ul>

                            <h5>Corsi Master di II Livello per Dirigenti Scolastici</h5>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Concorso Dirigenti Scolastici - <a href="/assets/doc/Tabella_valutazione_titoli_concorso_dirigenza_scolastica.pdf" target="_blank">Tabella Concorso</a> <b>punti 1,50</b></li>
                            </ul>

                        </Col>
                    </Row>
                </MSection>
                <MSection className="mb-1">
                    <Row>
                        <Col>
                            <h3 className='text-center'>Tutti (Docenti e non) - Laureati e/o Diplomati</h3>
                            <hr className="line-green" />

                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" />Certificazioni informatiche: Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 0,50</b>, fino a un massimo di 4 certificazioni per un totale complessivo di <b>2 punti</b>;</li>
                                <li><Icon icon="it-check" />Certificazioni linguistiche, Livello B2: Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 3,00;</b></li>
                                <li><Icon icon="it-check" />Certificazioni linguistiche, Livello C1: Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) <b>punti 4,00;</b></li>
                            </ul>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}