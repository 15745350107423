import React, { Component } from "react";
import { MnemoLoading, Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminLessonLiveAnalyticsForm } from "components/forms/admin/AdminLessonLiveAnalyticsForm";
import { adminService, dateHelper, networkErrorHelper } from "libs";

class LessonLiveScheduleAnalytics extends Component {  

  constructor(props) {
    super(props);

    this.state = {
      idLessonLiveSchedule: 0,
      loading: true,
      lessonLive: null
    }

    const idLessonLiveSchedule = parseInt(this.props.match.params.idLessonLiveSchedule);   

    if (idLessonLiveSchedule) {
      this.loadRemote(idLessonLiveSchedule)
    }
}

  loadRemote(idLessonLiveSchedule) {

    const filter = {
      searchFilter: {
        id: idLessonLiveSchedule,
      },
    };

    adminService
      .loadLessonLiveSchedule(filter)
      .then(({ data }) => {
      
        this.setState({
          loading: false,
          idLessonLiveSchedule:idLessonLiveSchedule,
          lessonLive: data.payload.lessonLiveSchedule
        });
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
        this.setState({ loading: false });
      });
  }

  render() {

    const { idLessonLiveSchedule, loading, lessonLive } = this.state

    if (loading) return <MnemoLoading />;    
    let title = 'Lezione non trovata';
    if (lessonLive) {
      title = lessonLive.title ;
    }

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{title}</Title>
            <ul>
              <li>data: {dateHelper.toITDate(lessonLive.date)}</li>
              <li>ora inizio: {lessonLive.hourStart}</li>
              <li>docente: {lessonLive.teacher}</li>
            </ul>
            <AdminLessonLiveAnalyticsForm idLessonLiveSchedule={idLessonLiveSchedule} />
          </Container>
        </section> 
      </>
    );
  }

}

export default withRouter(LessonLiveScheduleAnalytics);
