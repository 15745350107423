import React from "react";

import { MSelect, FormEnabler, ValidatedForm } from "components/forms";

import {
  payloadBuilder,
  userService,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder
} from "libs";

import { WORKINGSTATUS } from 'const';

import {
  Card,
  CardBody,
  Icon
} from 'design-react-kit';
import { MnemoCardTag, MnemoLoading } from "components/misc";

export class UserTarget extends ValidatedForm {

  payload_nations = [];

  emptyFields = {
    workingStatus: '',
  };

  state = {
    loading: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields
    }
  };

  ERROR_MESSAGES = {
    workingStatus: "Posizione lavorativa selezionata non valida"
  };

  validation = {
    workingStatus: value => WORKINGSTATUS.filter(g => g.value === value).length > 0
  };

  FIELDS_GROUP = [
    [
      {
        field: "workingStatus",
        label: "Posizione lavorativa attuale",
        component: MSelect,
        payload: { options: WORKINGSTATUS },
        className: "col-md-6"
      }
    ],
  ];


  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }


  componentDidMount() {
    this.loadRemote();
  }


  loadRemote(additionalState = {}) {
    try {
      userService
        .getUserTarget()
        .then(({ data }) => {
          const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            data.payload.target
          );

          const newState = {
            defaultValues,
            ...additionalState
          };

          this.setState(newState);
        })
        .catch(error => {
          if (!networkErrorHelper.is404(error)) {
            networkErrorHelper.notify(error);
          }
        })

    } catch (error) {
      networkErrorHelper.notify(error);
      this.setState({ loading: false });
    }
  }//loadremote


  saveRemote = () => {
    const payload = payloadBuilder(this.state);
    userService.manageUserTarget(payload)
      .then(() => {
        const validations = {
          workingStatus: null
        };

        this.setState({ formActive: false, ...validations });
      })
      .catch(errors => {

        if (networkErrorHelper.is422(errors)) {
          const newState = invalidFieldsMapper(
            errors,
            this.state,
            this.ERROR_MESSAGES
          );
          this.setState({ ...newState });

        } else {
          networkErrorHelper.notify(errors);
        }
      });
  }//saveRemote

  showForm = () => {
    this.setState({ formActive: true });
  }

  renderInfo() {

    const { isNew, defaultValues } = this.state;

    const cardProps = {
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      actionLabel: 'Inserisci',
      tag: 'Dati Profilo',
      smallicon: 'it-user',
      isReadOnly: this.props.isReadOnly,
      actionClick: this.showForm
    }

    if (isNew) {
      cardProps.actionLabel = 'Inserisci';

    } else {
      cardProps.actionLabel = 'Modifica';

      cardProps.content = (
        <>
          <b>Posizione lavorativa attuale:</b> {defaultValues.workingStatus}<br />
        </>
      );
    }

    return (<>
      <MnemoCardTag {...cardProps}></MnemoCardTag>
    </>)
  }

  renderForm() {
    const { formActive, defaultValues, loading } = this.state;
    if (loading) return (<><MnemoLoading /></>);

    const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
        <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-user" padding={false} />
            <span>Dati Profilo</span>
          </div>
          <CardBody tag="div">
            <form className="user" id="userTarget_form">
              {code}
            </form>
            <div >
              <FormEnabler
              
                onToggle={this.toggleForm}
                isFormActive={formActive}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {

    const { formActive } = this.state;  
    if (formActive) {
      return this.renderForm();
    } else {
      return this.renderInfo();
    }

  }
}
