import React, { Component } from "react";
import { MSection, Title } from "components/misc";
import { Row, Col } from "design-react-kit";
import { Helmet } from "react-helmet";

export class TeacherCard extends Component {
  render() {
    const title = "COME GENERARE IL BONUS SCUOLA PER I CORSI MNEMOSINE";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <MSection>
          <Row>
            <Col>
              <Title>{title}</Title>
              <hr />
              <ol type="a">
                <li>
                  Per richiedere lo Spid (
                  <a
                    href="https://www.spid.gov.it/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Clicca qui
                  </a>
                  )
                </li>
                <li>
                  Istruzioni per <b>generare</b> (per chi è in possesso dello Spid) il Bonus Scuola € 500 per TUTTI i Corsi proposti
                  dall’Associazione Mnemosine. Riportiamo di seguito i passaggi che le permetteranno di poter usufruire del Bonus Scuola € 500,00:
                </li>
                <ol>
                  <li>
                    collegarsi al link{" "}
                    <a
                      href="https://cartadeldocente.istruzione.it/#/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      https://cartadeldocente.istruzione.it/#/
                    </a>
                  </li>
                  <li>
                    cliccare su “Entra con SPID” e inserire nome utente e
                    password;
                  </li>
                  <li>
                    controllare la correttezza dei dati anagrafici e continuare;
                  </li>
                  <li>prendere visione delle condizioni d’uso;</li>
                  <li>
                    cliccare su <b>“Il mio portafoglio”</b> in alto a sinistra;
                  </li>
                  <li>
                    a piè di pagina cliccare su “<b>CREA NUOVO BUONO</b>”;
                  </li>
                  <li>si aprirà la schermata “<b>Nuovo Buono - Per quale categoria di prodotti vuoi crearlo?</b>” e cliccare su:</li>
                  <ul>
                    <li>“Formazione aggiornamento”;</li>
                    <li>
                      scegliere il prodotto “<b>Corsi aggiornamento enti accreditati/qualificati ai sensi della direttiva 170/2016</b>”;
                    </li>
                    <li>
                      riportare l’importo;
                    </li>
                    <li>Crea il Buono.</li>
                  </ul>
                  <li>
                    Allegare il Buono generato durante la procedura di iscrizione online.
                  </li>
                </ol>
              </ol>
              <p>
                <a
                  href="/files/cartadocente/Come_generare_il_Bonus_per_Mnemosine.pdf"
                  target="_blank"
                >
                  SCARICA QUESTE ISTRUZIONI
                </a>
              </p>
              {/* <BoxSuccess>
                <h6>Nota Bene:</h6>
                <hr />
                <ul>
                  <li>
                    Per i Diplomi di Perfezionamento Annuali e per i Diplomi di
                    Specializzazione Biennale indicare “CORSI POST LAUREAM” o in
                    alternativa CORSI AGGIORNAMENTO ENTI ACCREDITATI/QUALIFICATI
                    AI SENSI DELLA DIRETTIVA 170/2016”;
                  </li>
                  <li>
                    Per le Certificazioni Informatiche, Linguistiche, Corsi di
                    Preparazione e per tutti gli altri corsi indicare “CORSI
                    AGGIORNAMENTO ENTI ACCREDITATI/QUALIFICATI AI SENSI DELLA
                    DIRETTIVA 170/2016”;
                  </li>
                  <li>Per i Master indicare la voce “MASTER UNIVERSITARI”.</li>
                </ul>
              </BoxSuccess> */}
            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}
