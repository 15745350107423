import React from "react";
import {USER_QUALIFICATIONS} from 'const';

import {
    FormEnabler,
    ValidatedForm,
    MInput
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    userService,
    utilsService
} from "libs";


import {
    Card, CardBody
} from 'design-react-kit';
import { MSelect } from "components/forms/shared/MSelect";

export class UserQualificationUniEnrollmentForm extends ValidatedForm {

    ERROR_MESSAGES = {
        aa: "Anno Accademico non valido (AAAA/AAAA)",
        istitution: "Istituto non valido",
    };

    emptyFields = {
        aa: "",
        istitution: "",
        type: USER_QUALIFICATIONS.IMMATRICOLAZIONE
    };

    validation = {
        istitution: value => /^[a-zA-Z0-9.,-/àèé'ìòù/\s"]+$/i.test(value),
        aa: value => this.validateAA(value),
    };

    //--- start: more Validate func ---
    validateAA = (value) => {
        let aa = /^(\d{4})\/(\d{4})$/.test(value);

        if (!aa) {
            return false;
        } else {
            aa = value.split('/');
            if (parseInt(aa[1]) === (parseInt(aa[0]) + 1)) {
                return true;
            } else {
                return false;
            }
        }
    }//validateAA
    //--- end: more Validation func ---

    PAYLOAD_universities = [];

    FIELDS_GROUP = [
        [
            {
                id: "aa",
                field: "aa",
                label: "Anno accademico prima immatricolazione",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Ateneo prima immatricolazione",
                component: MSelect,
                payload:  { options: this.PAYLOAD_universities },
                className: "col-md-12",
            }
        ]
    ];

    //--- ---

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

        this.loadRemote();
        if (typeof this.props.uniEnrollment !== 'undefined') {
            this.setState(state => {
                state.defaultValues = { ...this.props.uniEnrollment }
                return true
            })
        }

    }//componentDidMount    

    loadRemote() {

        if (this.PAYLOAD_universities.length === 0) {
            utilsService.universities()
                .then(({ data }) => {
                    const { payload } = data;
                    const universities = payload.university.map(({ uniCode, name, city }) => ({ label: name, value: name }));
                   
                    this.PAYLOAD_universities.push({ label: 'Seleziona', value: '' });
                    this.PAYLOAD_universities.push({ label: 'ATENEO ESTERO', value: 'ATENEO ESTERO' });
                    this.PAYLOAD_universities.push(...universities);                    
                    this.setState({loading: false});
                })
                .catch(errors => {
                    console.log(errors);
                });            
        }

    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        if (payload.id > 0) {

            userService.updateQualification(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            Object.keys(this.validation)
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });

        } else {

            userService.insertQualification(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            Object.keys(this.validation)
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });
        }
    }//saveRemote

    onCancel = () =>{
        this.props.showFormUniEnrollment(false);
    }//onCancel

    //--- ---
    render() {

        const { formActive, defaultValues, loading } = this.state;
        if (loading) return (<></>);

        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);
        
        return (
            <Card tag="div" className="card-big" >
                <CardBody tag="div" className="card-body-inAccordion">

                    <form className="user mt-5">
                        {code}
                    </form>

                    <div className="m-0 text-right">
                        <FormEnabler
                            onSubmit={this.onSubmit}
                            onCancel={this.onCancel}
                            onToggle={this.toggleForm}
                            isFormActive={formActive}
                            disableSave={!formValid}                          
                        />
                    </div>
                </CardBody>
            </Card>
        )
    }
}