import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class MasterMUPAMSLU extends Component {

    render() {
        const title = 'Master Pubblica Amministrazione e Forze Armate';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <div className="swiper-slide my-2">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corsi-mnemosine/MUPA">
                                                <div className="programme-card__header">
                                                    <img className="programme-card__img" alt="Master di I livello" src="/img/courses/new/mupa.jpg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <h4 className="programme-card__title">Master di I livello <br/><span class="small">1500 ore e 60 CFU</span></h4>
                                                    <h6 className="text-red  text-highlights">Da € 690</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <div className="swiper-slide my-2">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corsi-mnemosine/MSLU">
                                                <div className="programme-card__header">
                                                    <img className="programme-card__img" alt="Master di II livello" src="/img/courses/new/mslu.jpeg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <h4 className="programme-card__title">Master di II livello <br/><span class="small">1500 ore e 60 CFU</span></h4>
                                                    <h6 className="text-red  text-highlights">Da € 980</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}