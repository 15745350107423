import React, { Component } from 'react';
import ReactNotification from 'react-notifications-component'

import {
    Container,
} from 'design-react-kit';
export class IFrame extends Component {

    componentDidMount() {

        // load javascript for the form resize
        const script = document.createElement('script');
        script.src = '/js/iframeResizer.contentWindow.min.js';
        script.id = 'iframeResizer';
        document.body.appendChild(script);
    }

    render() {

        return (
            <>
                <ReactNotification />
                <Container tag="div" id="main-container">
                    {this.props.children}
                </Container>

            </>
        );
    }
}  