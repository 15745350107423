import React, { Component } from 'react';

import { BoxInfo, MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class Promo50 extends Component {

    render() {
        const title = 'PROMO 50 - SCONTI FINO AL 50%';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <p>
                                Per <b>iscrizione contemporanea a due o più corsi</b> proposti dall’Associazione Mnemosine - Ente Accreditato MIUR
                                per la Formazione del Personale Scolastico,  è possibile usufruire dei suddetti sconti:</p>
                            <ol>
                                <li>Corso Universitario (*) + Corso e Certificazione Linguistica: <b>sconto del 30%</b> sul costo della certificazione Linguistica</li>
                                <li>Corso Universitario (*)  + Corso e Certificazione Informatica (singolo corso): <b>sconto del 30%</b> sul costo del singolo corso Informatico.</li>
                                <li>Corso e Certificazione Informatica (singolo corso) + Corso e Certificazione Linguistica: <b>sconto del 25%</b> sul costo del corso o del Corso e Certificazione di minore importo!</li>
                                <li>Corso Universitario (*)  + Corso e Certificazione Informatica (singolo corso) + Corso e Certificazione Linguistica: <b>sconto del 50%</b> sul costo del Corso di minore importo!</li>
                            </ol>
                            <p>(*) Per Corso Universitario si intende uno dei  seguenti Corsi: Master, Perfezionamento e Specializzazione.</p>
                            <p>Modalità : inviare una richiesta tramite email a  <a href="mailto:info@formazionedocenti.it"> info@formazionedocenti.it </a> indicando i propri dati nonché i costi iniziali dei corsi a cui si intende partecipare. Sarà inviato un preventivo anche rateizzato.</p>
                        </Col>
                        <BoxInfo>
                            <p>
                                Per usufruire di tali promozioni inviare una email con oggetto: <b>SCONTO PROMOZIONE </b>
                                a <a href="mailto:info@formazionedocenti.it"> info@formazionedocenti.it </a> specificando dettagliatamente i corsi con relativi importi ai quali si intende iscrivere.
                            </p>
                            <p> <b>Attenzione:</b> usufruendo dei suddetti sconti non sarà possibile richiedere alcun omaggio (Tablet, Corsi vari, ecc…) e nel Modulo d’Iscrizione non potrà riportare alcun codice riservato all’Ufficio.
                            </p>
                        </BoxInfo>
                    </Row>
                </MSection>
            </>);
    }
}