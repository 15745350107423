import { ROUTES } from "const";

export class TFA {

  static async getDashboardCards(registrationCode) {

    let cards = this.getFixedCards();
    cards.push({
      title: `Codice domanda iscrizione: ` + registrationCode,
      link: '#',
      img: "/img/icon/badge.png",
      className: "image-box",
    })

    return cards;
  }

  static getFixedCards() {
    return [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      }
    ];
  }

  static async getDashboardNotices() {

    return [];
  }
}
