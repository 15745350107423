import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const invoiceService = {    

    invoiceCourse(rCode) {
        const payload = mnemoService.buildPayload('list', 'rCode', rCode);
        return proxyApiMnemo.get('/invoice/?payload='+encodeURI(payload));
    },    

    requestSendInvoice(data) {
        const payload = mnemoService.buildPayload('sendInvoice', 'invoice', data);
        return proxyApiMnemo.get('/invoice/?payload='+encodeURI(payload));
    },    
}