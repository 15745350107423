import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import {
  Row, Col, Button,
  Callout, CalloutTitle,
} from 'design-react-kit';

class EnrollmentPrerequisites extends Component {

  componentDidMount() {

  }

  renderNotice = (notice) => {

    return (
      <>
        <Callout className="mnemo-callout-danger" highlight tag="div">
          <CalloutTitle tag="h6">
            {notice.title}
          </CalloutTitle>
          <Row>
            <Col sm={9}>
              {notice.content}
            </Col>
            <Col sm={3}>
              <Button color="primary bg-dark" href={notice.actionLink}>{notice.actionLabel}</Button>
            </Col>
          </Row>
        </Callout>
      </>);
  }

  render() {

    const { notices } = this.props;

    return (
      <>     
      <div className="m-5">
      <hr/>        
        <h6>
          La tua domanda di iscrizione è stata avviata con successo. Prima di completare la procedura
           è necessario inserire le seguenti informazioni.  
           </h6>
           <hr/>        
          {notices.map((notice, index) => (
            <Row key={`notice-${index}`}>
              <Col size="12">
                {this.renderNotice(notice)}
              </Col>
            </Row>
          ))}        
          </div> 
      </>);
  }
}

export default withRouter(EnrollmentPrerequisites);