import React, { Component } from "react";

import UserList from './UserList';
import UserDetail from './UserDetail';

import { withRouter } from 'react-router-dom';


class AdminUser extends Component {
 
  
    render() {
  
      return (
        <>
          <section className="my-4">
              {(typeof this.props.match.params.id === 'undefined') ? <UserList/> : <UserDetail/>}
          </section>
        </>
      );
    }
  
  }
  
  export default withRouter(AdminUser);
  