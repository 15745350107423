import React, { Component } from "react";

import {
    Container, Row, Col, Button,
} from 'design-react-kit';

import { utilsService } from 'libs';

import "./UserQualificationSchool.css";
import { MInput } from "components/forms/shared/MInput";
import { MSelect } from 'components/forms/shared/MSelect';
import { MToggle } from 'components/forms/shared/MToggle';
import { MnemoLoading } from "components/misc";


export class UserQualificationSchool extends Component {

    PAYLOADS = {
        provinces: [],
        cities: [],
        schools: [],
    }

    toSent = {
        city: '',
        istitution: '',
        nation: 'italia'
    }

    constructor(props) {
        super(props);

        this.state = {
            popolateProvince: { options: [{ label: 'Seleziona', value: -1 }] },
            popolateCities: { options: [] },
            popolateSchools: { options: [] },
            actionModule: false,
            openField: {
                view: false,
                city: '',
                istitution: '',
                nation: 'italia'
            },
            switchNation: false,
            loading: false

        };

        this.loadCities     = this.loadCities.bind(this);
        this.loadSchool     = this.loadSchool.bind(this);
        this.activateButton = this.activateButton.bind(this);
        this.resetSelectElm = this.resetSelectElm.bind(this);
    }

    //--- ---

    componentDidMount() {
        this.loadProvince();
    }

    loadProvince = () => {
        utilsService.provinces().then(({ data }) => {
            const { payload } = data;
            const provinces = payload.map(({ denUts, codProvPrev, denUtsShort }) => ({ label: denUts, value: [codProvPrev, denUtsShort, denUts] }));
            this.PAYLOADS.provinces.push({ label: 'Seleziona', value: -1 });
            this.PAYLOADS.provinces.push(...provinces);

            this.setState({
                popolateProvince: { options: this.PAYLOADS.provinces }
            });
        })
        .catch(errors => {
            console.log(errors);
        }); 
    }//loadProvince

    loadCities = (_, value) => {
        const arrCities = value;
        this.popolateCities(arrCities);

        this.setState({
            popolateSchools: { options: [] },
            popolateCities: { options: [] },
            actionModule: false,
            openField: {
                city: '',
                istitution: ''
            },
            loading: true
        })
    }//loadCities


    popolateCities(arrCities) {
        arrCities = arrCities.split(',');

        //utilsService.citiesSchoolByISTATProv(arrCities[0]).then(({ data }) => { //old on school table
        utilsService.citiesSchoolByISTATProv(arrCities[2]).then(({ data }) => {
            const { payload } = data;
            const cities = payload.map(({ name, istatCode }) => ({ label: name, value: [...arrCities, name, istatCode] }));

            this.PAYLOADS.cities = [];
            this.PAYLOADS.cities.push(...cities);
            this.PAYLOADS.cities.unshift({ label: `Seleziona (${cities.length})`, value: -1 });

            this.setState({
                popolateCities: {
                    options: this.PAYLOADS.cities
                },
                loading: false
            });
        })
        .catch(errors => {
            console.log(errors);
        }); 
    }//popolateCities


    loadSchool = (_, value) => {
        const cityData = value;
        this.popolateSchools(cityData);

        this.setState({
            popolateSchools: { options: [] },
            actionModule: false
        })
    }//loadSchool

    popolateSchools(cityData) {
        cityData = cityData.split(',');

         this.toSent.city = cityData[3];

        utilsService.listSchoolByISTATCodeCom(cityData[4]).then(({ data }) => {
            const { payload } = data;
            const schools = payload.map(({ name, type, schoolCode }) => ({ label: name + ' (' + type.toLowerCase() + ')', value: [name, schoolCode] }));

            this.PAYLOADS.schools = [];
            this.PAYLOADS.schools.push(...schools);
            this.PAYLOADS.schools.unshift({ label: `Seleziona (${schools.length})`, value: -1 });

            this.setState({
                popolateSchools: { options: this.PAYLOADS.schools }
            });
        })
        .catch(errors => {
            console.log(errors);
        }); 
    }//popolateSchool

    //--- ---

    /** */
    resetToSend = () =>{
        this.toSent = {
            city: '',
            istitution: '',
            nation: 'italia'
        }
    }

    /** */
    switchNation = () => {
        this.switchSchoolSearcher();
        this.resetToSend();
        this.setState({
            switchNation: !this.state.switchNation
        })
    }//switchNation

    /** */
    switchSchoolSearcher = () => {
        this.setState(state => {
            state.openField.view       = !this.state.openField.view;
            state.openField.city       = null;
            state.openField.istitution = null;
            state.openField.nation     = null;

            state.popolateProvince     = { options: [] };
            state.popolateCities       = { options: [] };
            state.popolateSchools      = { options: [] };
            state.actionModule         = false;

            this.loadProvince();

            return true
        })
    }//switchSchoolSearcher


    /** simple form validator chek only of all value are not empty */
    validateForm = () => {
        let valid = true;
        for (const property in this.toSent) {
           if(this.toSent[property] === ''){
               valid = false;
           }
        }

        if(valid){
            this.setState({ actionModule: true })
        }else{
            this.setState({ actionModule: false })
        }
    }//validateForm


    /** */
    activateButton = (_, value) => {
        if (value === '-1') {
            this.toSent.istitution = '';
            this.setState({ actionModule: false })
        } else {
            this.toSent.istitution = value;
            this.setState({ actionModule: true })
        }
    }//activateButton


    /** */
    nextSchool = () => {
        this.props.popolateDataFromChildSchool(this.toSent);
        this.validateForm();
    }//nextSchool


    /** */
    resetSelectElm = (id, value) => {
        if (this.state.popolateCities.length > 0) { //reset select element only if option exists
            this.setState({
                popolateProvince: { options: [] },
                popolateCities: { options: [] },
                popolateSchools: { options: [] }
            })

            this.loadProvince();
        }//>0

        //--- ---

        /** start: manage value of open field */
        this.setState((state) => {
            if (id === 'openFieldCity') {
                state.openField.city = value;
                this.toSent.city     = value;
            }

            if (id === 'openFieldIstitution') {
                state.openField.istitution = value;
                this.toSent.istitution     = value;
            }

            if (id === 'openFieldNation') {
                state.openField.nation = value;
                this.toSent.nation     = value;
            }

            //--- validate free form and manage state.actionModule = false;
            if (
                this.state.switchNation
                && (this.state.openField.city !== null && this.state.openField.city !== '')
                && (this.state.openField.istitution !== null && this.state.openField.istitution !== '')
                && (this.state.openField.nation !== null && this.state.openField.nation !== '')
            ) {
                state.actionModule = true;
            }

            if (
                !this.state.switchNation
                && (this.state.openField.city !== null && this.state.openField.city !== '')
                && (this.state.openField.istitution !== null && this.state.openField.istitution !== '')
            ) {
                state.actionModule = true;
            }

            //--- validate toSent object ---
            this.validateForm();

            return true;
        })
    }//resetSelectElm


    //--- ---


    render() {

        const { popolateProvince, popolateCities, popolateSchools, 
            openField, actionModule, switchNation, loading } = this.state;

        return (
            <>
                {loading && <MnemoLoading></MnemoLoading>}

                <Container key="schoolSearch" className="mb-0">
                    <p className="mb-4">
                        Selezionare Provincia, città e Istituto presso il quale si è conseguito il Titolo di Studio:
                    </p>

                    {(!openField.view || switchNation) && (<Col lg={12} className="mb-4">
                        <div className="col-lg-6 col-md-6">
                            <><Row><MToggle id="switchNation" label="Titolo conseguito presso Istituto Estero?" onChange={this.switchNation}/></Row></>
                        </div>
                    </Col>)}

                    {(!openField.view) && (
                        <>                            
                            <Row> 
                                <Col lg={6} className="mb-6">
                                    <div className="bootstrap-select-wrapper">
                                        <MSelect id="listProvinces" label="Provincia" payload={popolateProvince} onChange={this.loadCities} />
                                    </div>
                                </Col>
                                <Col lg={6} className="mb-6">
                                    <div className="bootstrap-select-wrapper">
                                        <MSelect id="listCities" label="Città" payload={popolateCities} onChange={this.loadSchool} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} className="mb-12">
                                    <div className="bootstrap-select-wrapper">
                                        <MSelect id="listSchools" label="Istituto" payload={popolateSchools} onChange={this.activateButton} />
                                    </div>
                                </Col>
                            </Row>
                        </>
                        )}

                    {(openField.view) && (
                        <Row className="col-lg-12 m-0 p-0 mt-3">
                            {(switchNation) && (<Col lg={6} className="m-0 fixMB-0Input"><MInput key="openFieldNation" name="openFieldNation" label="Nazione" value={openField.nation || ''} className="mb-0" onChange={this.resetSelectElm}/></Col>)}
                            <Col lg={6} className="m-0 fixMB-0Input">
                                <MInput key="openFieldCity" name="openFieldCity" label="Città" value={openField.city || ''} className="mb-0" onChange={this.resetSelectElm} />
                            </Col>
                            <Col lg={6} className="m-0 fixMB-0Input">
                                <MInput key="openFieldIstitution" name="openFieldIstitution" label="Nome Istituto" value={openField.istitution || ''} className="mb-0" onChange={this.resetSelectElm} />
                            </Col>
                        </Row>)}
                    {/*<Row className="mb-4 ">
                        {(!openField.view)
                            && (<>
                                <Button color="primary bg-dark" onClick={this.switchSchoolSearcher}>
                                    Istituto non trovato
                                </Button>
                            </>)
                        }
                    </Row>*/}
                    <Row>
                        <Col lg={12} className="m-0 text-right">
                            <Button
                                color="primary bg-dark"
                                disabled={actionModule ? false : true}
                                size="md"
                                onClick={this.nextSchool}>SELEZIONA
                        </Button>
                            <Button
                            className="mx-4"
                                color="primary"
                                outline
                                size="md"
                                onClick={() => { this.props.showSearchSchool(false) }}>ANNULLA
                        </Button>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}