import React, { Component } from "react";
import { MInput } from "components/forms";
import { Container, Section } from "design-react-kit";
import { networkErrorHelper, adminService } from "libs";
import { MnemoLoading } from "./MnemoLoading";
import { MTable } from "components/table";
import { BoxDanger } from "./BoxDanger";
import { isEmptyObject } from "jquery";

export class SearchUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      payloadToRender: [],
      rndKey: 0,
      whereObj: {
        roles: this.props.role || "student",
        keySearch: null,
        limit: 200,
      },
      searchFilter: {
        searchFilter: {},
      },
    };
  } //constructor

  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "name",
        Header: "Nome",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        id: "surname",
        Header: "Cognome",
        accessor: "surname", // accessor is the "key" in the data
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
      },
      {
        id: "mobile",
        Header: "Telefono",
        accessor: "mobile",
      },
      {
        id: "tmpEnabled",
        Header: "Stato",
        accessor: "tmpEnabled",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => { this.props.goToExt({userId: value}) }
      }
    ],
  };

  //--- ---

  onSearch = (keySearch) => {
    if (keySearch.length < 3) {
      this.setState({
        loading: false,
        payload: [],
        payloadToRender: [],
        rndKey: Math.floor(Math.random() * 1000 + 1),
      });
      return false;
    }

    const whereObj = {
      ...this.state.whereObj,
      keySearch: keySearch,
    };

    this.loadRemote(whereObj);
  }; //onSearch

  loadRemote(whereObj) {
    adminService
      .getUserBySearch(whereObj)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.result).forEach(([key, val]) => {
          //--- manage exception to render human-readable data ---
          if (val.enabled) {
            val.tmpEnabled = "abilitato";
          } else {
            val.tmpEnabled = "disabilitato";
          }
          //--- ---

          items.push(val);
        });

        this.setState({
          loading: false,
          payload:
            Object.entries(data.payload.result).length === 0 ? [] : items,
          payloadToRender:
            Object.entries(data.payload.result).length === 0 ? [] : items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          this.setState({
            loading: false,
            payload: [],
            payloadToRender: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });

          networkErrorHelper.notify(error);
        } else {
          this.setState({
            loading: false,
            payload: [],
            payloadToRender: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  //--- ---

  render() {
    const searchField = {
      id: "searchuser",
      name: "searchUser",
      label: "cerca per nome, cognome o email",
      type: "text",
      className: "form-control",
      onKeyUp: (elm, value) => {
        this.onSearch(value);
      },
    };

    let { loading } = this.state;

    //---

    return (
      <>
        <Section className="m-0 p-0">
          <div className="form-row m-0 p-0">
            <div className="col-md-6">
              <MInput {...searchField} className="mb-0" />
            </div>
          </div>
        </Section>

        <hr />

        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <>
            <Container>
              <MTable
                key={this.state.rndKey}
                tableData={this.state.payloadToRender}
                tableConfig={this.tableConfig}
              />
            </Container>

            {this.state.payload.length === 0 &&
              !isEmptyObject(this.state.searchFilter.searchFilter) && (
                <BoxDanger key="nrp-1" className="my-3">
                  Lista utenti.
                  <div className="small text-info">
                    {" "}
                    Non sono presenti dati.
                  </div>
                </BoxDanger>
              )}
          </>
        )}
      </>
    );
  }
}
