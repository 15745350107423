import React, { Component } from "react";
import { UserAddress } from "./UserAddress";

import { ADDRESS_TYPES } from 'const';

import {
  Card,
  CardBody,
  Icon,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Button
} from 'design-react-kit';
import { MnemoCallout, ModalHandler } from "components/misc";
import { networkErrorHelper, userAddressService } from "libs";

export class UserAddresses extends Component {

  defaultState = {
    collapseOpen1: false,
    collapseOpen2: false,
  }

  state = {
    ...this.defaultState,
    copyBtnUserAddress: false
  }
  toggle = id => {
    this.setState({
      ...this.defaultState,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
    })
  }

  copyAddress(e) {
    e.stopPropagation();
    ModalHandler.show(null, 'Copia dati indirizzo',
      "I dati dell'indirizzo di verranno sovrascritti con i dati dell'indirizzo di residenza. Confermi? "
      , null, this.copyAddressConfirmed);
  }

  copyAddressConfirmed = () => {
    userAddressService.cloneResidenceAddress()
      .then(() => {
        // reload page
        window.location.reload();
      })
      .catch(errors => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  copyBtnUserAddress = (value) =>{
    this.setState({
      copyBtnUserAddress: value
    })
  }

  //--- ---

  render() {

    const { collapseOpen1, collapseOpen2, copyBtnUserAddress } = this.state;
    return (
      <>
        <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-pa" padding={false} />
            <span>Indirizzi</span>
          </div>
          <CardBody tag="div">
            <Accordion>
              <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                <span className="text-green" >Residenza</span>
              </AccordionHeader>
              <AccordionBody active={collapseOpen1}>
                <UserAddress isReadOnly={this.props.isReadOnly} type={ADDRESS_TYPES.RESIDENCE} copyBtnUserAddress={this.copyBtnUserAddress} />
              </AccordionBody>

              <AccordionHeader active={collapseOpen2} onToggle={() => this.toggle(2)}>
                <span className="text-green" >Domicilio</span>
              </AccordionHeader>
              <AccordionBody active={collapseOpen2}>
                {!this.props.isReadOnly && (<>
                    <MnemoCallout className="mb-2">Indirizzo presso il quale si desidera ricevere eventuali comunicazioni.
                    <br />
                    {(copyBtnUserAddress) && <Button color="primary bg-dark" onClick={(e) => this.copyAddress(e)}>Copia da indirizzo di Residenza </Button>}
                    </MnemoCallout>
                  </>)}               
                  <UserAddress isReadOnly={this.props.isReadOnly} type={ADDRESS_TYPES.DELIVERY} />
              </AccordionBody>
            </Accordion>
          </CardBody>
        </Card>
      </>
    );
  }
}
