import { ROUTES } from "const";
import { courseService, learningLiveService } from "libs";

export class RCCFUALL {

  static RC60CFUALL1 = 'RC60CFUALL1';
  static RC30CFUALL2 = 'RC30CFUALL2';

  static async getDashboardCards(registrationCode) {

    let cards = this.getFixedCards();

    try {
      const remoteResult = await learningLiveService.isOpenClassBooking(
        registrationCode
      );

      const isBookingOpen = (
        remoteResult &&
        remoteResult.data &&
        remoteResult.data.payload &&
        remoteResult.data.payload.length > 0
      );

      const result = await courseService.perfezionamentoActiveLink(
        registrationCode
      );
      
      if (result.data.payload) {
        const { payload } = result.data;

        if (isBookingOpen && payload.lessonLiveClassEnabled === "1") {

          cards.push({
            title: "Assegnazione Aula",
            link: ROUTES.COURSE_BOOKING_CLASS,
            img: "/img/icon/student.png",
            className: "image-box",
          });
        }
      }
    } catch (error) { }

    return cards;
  }

  static getFixedCards() {

    return [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
      {
        title: "Lezioni telematiche in modalità sincrona",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
      {
        title: "Lezioni attività formative in presenza",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_OFFLINE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
    ];
  }

  static getDashboardNotices(courseCode, competitionClass) {

    let notices = [/*
      {
        content: `<p>La sua domanda di ammissione alla selezione per l'accesso ai Percorsi universitari di Formazione Iniziale e Abilitazione dei docenti 
          della scuola secondaria di I e di II grado ai sensi del D.P.C.M. 4 agosto 2023 è stata acquisita dal sistema. 
          La invitiamo a rimanere in attesa di successive comunicazioni.</p>
          La Direzione`,
      },*/
    ];

    switch (courseCode) {

      case this.RC30CFUALL2:
        const n30 = this.getRC30Notices(competitionClass);
        notices.push(...n30);
        break;

      case this.RC60CFUALL1:
        const n60 = this.getRC60Notices();
        notices.push(...n60);
        break;

      default:
        break;
    }

    return notices;
  }

  static getRC60Notices() {

    return [
      {
        content: `<p>Avviso Lezione del 27 Settembre 2024 inerente le Attività formative in modalità telematica sincrona dei 16 CFU dell'Area Specifica per 
        ciascuna classe di concorso del Percorso di formazione iniziale Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto legislativo n. 59 del 2017 e 
        all'art. 7, comma 2 del DPCM 4 agosto 2023 ALL. 1).
        <br><b><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/avviso-lezione-27-settembre-60cfu.pdf" target="_blank">Clicca qui per scaricare il calendario</a></b></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 4 CFU dell'Area Trasversale del 
        Percorso di formazione iniziale Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto legislativo n. 59 del 2017 e all'art. 7, comma 2
         del DPCM 4 agosto 2023  ALL. 1).  
        <br><b><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-lezioni-online-60cfu-all1.pdf" target="_blank">Clicca qui per scaricare il calendario</a></b></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in presenza inerenti i 20 CFU dell'Area Trasversale del Percorso di formazione iniziale 
        Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto legislativo n. 59 del 2017 e all'art. 7, comma 2 del DPCM 4 agosto 2023  ALL. 1). 
        <br><b><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-attivita-formativa-60cfu-all1.pdf">Clicca qui per scaricare il calendario</a></b></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 16 CFU dell'Area Specifica per 
        ciascuna classe di concorso del Percorso di formazione iniziale Abilitante di 60 CFU (ai sensi dell'art. 2 bis del decreto
         legislativo n. 59 del 2017 e all'art. 7, comma 2 del DPCM 4 agosto 2023  ALL. 1). 
        <a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-lezioni-online-60cfu-all1-fase2.pdf" target="_blank">Clicca qui per scaricare il calendario</a></p>`,
      },
      {
        content: `<p>Linee guida per lo svolgimento delle lezioni in modalità telematica sincrona. 
        <a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/linee-guida-lezioni.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
      },
    ];
  }

  static getRC30Notices(competitionClass) {

    const calendarName = `calendario-lezioni-online-30cfu-all2-fase2-${competitionClass}.pdf`;

    return [
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 5 CFU 
        dell'Area Trasversale del Percorso di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, 
        comma 2, del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2).
        <br><b><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-lezioni-online-rc30-all2.pdf" target="_blank">Clicca qui per scaricare il calendario</a></b></p>`,
      },
      {
        content: `<p>Calendario delle Attività formative in presenza inerenti i 12 CFU dell'Area Trasversale del Percorso 
        di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, comma 2, 
        del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2). 
        <br><b><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/calendario-attivita-formativa-30cfu-all2.pdf">Clicca qui per scaricare il calendario</a><§/b></p>`,

      },
      {
        content: `<p>Calendario delle Attività formative in modalità telematica sincrona inerenti i 4 CFU dell'Area Specifica per ciascuna
         classe di concorso del Percorso di formazione iniziale Abilitante di 30 CFU (ai sensi dell'art. 2-ter, comma 4-bis e dell'art. 13, comma 2, 
         del decreto legislativo n. 59 del 2017 e dell'art. 7, comma 6, del DPCM 4 agosto 2023 ALL. 2). 
        <a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RCALL/${calendarName}" target="_blank">Clicca qui per scaricare il calendario</a></p>`,
      },
      {
        content: `<p>Linee guida per lo svolgimento delle lezioni in modalità telematica sincrona. 
        <a href="https://www.formazionedocenti.it/files/corsi/RCALL/linee-guida-lezioni.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
      },
    ];
  }
}
