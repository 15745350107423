import * as React from "react"

const IconFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 122.88"
    width={props.width ? props.width : 40}
    height={props.height ? props.height : 40}
    fill={props.fill ? props.fill : '#ffffff'}
    {...props}
  >
    <title>{"instagram-logo-black-and-white"}</title>
    <path d="M61.45 0C44.76 0 42.66.07 36.11.37A45.08 45.08 0 0 0 21.2 3.23a29.86 29.86 0 0 0-10.88 7.08 30.26 30.26 0 0 0-7.1 10.88A44.92 44.92 0 0 0 .37 36.11C.08 42.66 0 44.75 0 61.44s.07 18.77.37 25.33a45.08 45.08 0 0 0 2.86 14.91 30.12 30.12 0 0 0 7.08 10.88 30.13 30.13 0 0 0 10.88 7.1 45.17 45.17 0 0 0 14.92 2.85c6.55.3 8.64.37 25.33.37s18.77-.07 25.33-.37a45.17 45.17 0 0 0 14.92-2.85 31.54 31.54 0 0 0 18-18 45.6 45.6 0 0 0 2.86-14.91c.29-6.55.37-8.64.37-25.33s-.08-18.78-.37-25.33a45.66 45.66 0 0 0-2.86-14.92 30.1 30.1 0 0 0-7.09-10.88 29.77 29.77 0 0 0-10.88-7.08A45.14 45.14 0 0 0 86.76.37C80.2.07 78.12 0 61.43 0Zm-5.52 11.07h5.52c16.4 0 18.34.06 24.82.36a34 34 0 0 1 11.41 2.11 19 19 0 0 1 7.06 4.6 19.16 19.16 0 0 1 4.6 7.06 34 34 0 0 1 2.11 11.41c.3 6.47.36 8.42.36 24.82s-.06 18.34-.36 24.82a33.89 33.89 0 0 1-2.11 11.4 20.35 20.35 0 0 1-11.66 11.65 33.64 33.64 0 0 1-11.41 2.12c-6.47.3-8.42.36-24.82.36s-18.35-.06-24.83-.36a34 34 0 0 1-11.41-2.12 19 19 0 0 1-7.07-4.59 19 19 0 0 1-4.59-7.06 34 34 0 0 1-2.12-11.41c-.29-6.48-.35-8.42-.35-24.83s.06-18.34.35-24.82a33.7 33.7 0 0 1 2.12-11.41 19 19 0 0 1 4.59-7.06 19.12 19.12 0 0 1 7.07-4.6 34.22 34.22 0 0 1 11.41-2.12c5.67-.25 7.86-.33 19.31-.34Zm38.31 10.2a7.38 7.38 0 1 0 7.38 7.37 7.37 7.37 0 0 0-7.38-7.37Zm-32.79 8.62A31.55 31.55 0 1 0 93 61.44a31.56 31.56 0 0 0-31.55-31.55Zm0 11.07A20.48 20.48 0 1 1 41 61.44 20.48 20.48 0 0 1 61.45 41Z" />
  </svg>
)

export default IconFacebook
