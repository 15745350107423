import React, { Component } from "react";
import { courseService, networkErrorHelper } from "libs";

import ReactHtmlParser from 'react-html-parser';

export class CourseLandingPageGetRates extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listRates: null
        }
    }

    componentDidMount() {
        if (this.props.showRate) {
            this.loadRemote(this.props.courseCode)
        }
    }

    loadRemote(courseCode) {
        courseService.getCourseRates(courseCode)
            .then(({ data }) => {
                this.setState({
                    listRates: data.payload
                })
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }//loadRemote

    //--- ---

    render() {

        const { listRates } = this.state;

        const { showHeader = true } = this.props;
        return (
            <>
                {(showHeader) &&
                    (<>
                        <div className="box-title">
                            <img src="/img/icon/euro.png" alt="Mnemosine" />
                            <h6>COSTI E RATEIZZAZIONE</h6>
                        </div>
                    </>)}


                <div className="box-content">
                    <div className="box-content-left">
                        <ul className="list-left">
                            <li>{this.props.price}</li>
                            {(listRates) && (<>

                                {(listRates.rates).map((rate, idx) => {
                                    return (<li key={idx}>{ReactHtmlParser(rate)}</li>)
                                })}
                            </>)}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}