import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MSection, SupportoTecnico, Title } from "components/misc";
import { Button } from "design-react-kit";
import { ROUTES } from "const";

class GOMPPaymentNotify extends Component {

    state = {
        orderNumber: '',
        error: true
    }

    componentDidMount() {

        const { orderNumber } = this.props.match.params;
        this.setState({ orderNumber, error: false });
    }

    goHome = () => {

        window.location = (ROUTES.DASHBOARD);
    }

    render() {

        const { orderNumber } = this.state;

        return (<>
            <Title>Pagamento PagoPA</Title>
            <MSection>
                <p>La procedura di pagamento relativo alla domanda di iscrizione numero: {orderNumber} si è conclusa.<br />
                    Per qualsiasi richiesta di informazioni puoi <SupportoTecnico /></p>
                <Button color="primary bg-dark" onClick={this.goHome}>Vai alla tua Area riservata</Button>
            </MSection>
        </>);
    }
}

export default withRouter(GOMPPaymentNotify);