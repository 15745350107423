import i18n from "app/i18n";
import { cache } from "libs";
import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

class QuestionFillFromSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: this.props.answers,
            choice: [],
            idQuiz: this.props.idQuiz,
            attemptTracker: (this.props.attemptTracker) ? this.props.attemptTracker : false
        };
    }

    /** */

    setMyState = (e,idQuestion) => {
        let data = {
            idAnswer: e.target.value,
            idQuestion: idQuestion,
            label: e.target.childNodes[e.target.selectedIndex].text
        }

        this.setState(state => {    
            state.choice.pop(); 
            state.choice.push(data);
        
            //--- manage localStorage answer ---
            if(this.state.choice.length===0){
                cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
            }else{
                cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
            }
        });
    }//setMyState

    render() {

        if(!this.state.answers) return false;

        return (
            <div className="wrapperResetQuizSelect">
                {ReactHtmlParser(this.props.label.split('{{answers}}')[0])}                
                {(typeof this.state.answers[0].userAnswer === 'undefined') && (<select
                    id={`r${this.props.idQuestion}`}
                    name={`r${this.props.idQuestion}`}
                    className="resetQuizSelect"
                    onChange={(e) => { this.setMyState(e, this.props.idQuestion); }}
                >
                    <option value="">{i18n.t('choose')}</option>
                    {                            
                        this.state.answers.map((option, idx)=>{
                            return <option key={`opqfs${idx}`} value={option.id}>{option.label.replace(/<[^>]+>/g, '')}</option>
                        })
                    }
                </select>)}
                
                {/**  */}
                {(typeof this.state.answers[0].userAnswer === 'boolean') && (
                    <>
                    {                            
                        this.state.answers.filter((item)=>{ return item.userAnswer === true}).map((option, idx)=>{
                            return <b key={`opans${idx}`} className={(parseInt(option.grade) === 0) ? 'textDanger' : 'textSuccess' }>{ReactHtmlParser(` ${option.label.replace(/<[^>]+>/g, '')}`)}</b>
                        })
                    }
                    
                    {/** show/suggest right answer */}
                    {(this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? ReactHtmlParser(`</br></br><b>${i18n.t('rightAnswer')}:</b></br>`) : ''}
                    {                        
                        this.state.answers.filter((item)=>{ return parseInt(item.grade) > 0 }).map((option, idx)=>{
                            return (
                                (this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? <b key={`opans${idx}`} className={'textSuccess'}>- {ReactHtmlParser(` ${option.label.replace(/<[^>]+>/g, '')} </br>`)}</b> : ''
                            )
                        })
                    }
                    </>
                )
                }
                
                {ReactHtmlParser(this.props.label.split('{{answers}}')[1])}
                
            </div>
        );
    }
}

export default QuestionFillFromSelect;

                    {/*<span
                    className={((parseInt(row.grade)>0)  ? 'textSuccess bold' : ''}>
                    {ReactHtmlParser(row.label)}
                </span>*/}