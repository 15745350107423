import { ROUTES } from "const";
import { courseService } from "libs";

export class M24 {

    static async getDashboardCards(registrationCode) {

        let cards = this.getFixedCards();

        try {
            const result = await courseService.cfuActiveLink(registrationCode);
            if (result.data.payload) {
                const { payload } = result.data;                
                if (payload.writtenReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami',
                        link: ROUTES.C24_EXAMWRITTEN_ONSITE_RESERVATION, //ROUTES.C24_EXAMWRITTEN_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }
                if (payload.writtenEnabled === '1') {
                    cards.push({
                        title: 'Prove di Profitto', link: ROUTES.C24_EXAMWRITTEN,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }
                if (payload.examReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami', 
                        link: ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION,  // ROUTES.COURSE_EXAM_WRITTEN_RESERVATION,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }
                if (payload.writtenEnabledConvalida === '1') {
                    cards.push({
                        title: 'Prova di Profitto', link: ROUTES.PERFEZIONAMENTO_EXAM_WRITTEN,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }
                if (payload.liveReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Orali 24CFU',
                        link: ROUTES.C24_EXAMLIVE_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }
                if (payload.liveReservationPerfezionamentoEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami Orali',
                        link: ROUTES.COURSE_EXAM_LIVE_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }
                if (payload.isLinkExamEnabled === '1') {
                    cards.push({
                        title: 'Avvio di Google Meet',
                        link: ROUTES.C24_EXAMLIVE,
                        img: '/img/icon/call.png', className: 'image-box'
                    });
                }
                if (payload.certRequestEnabled === '1') {
                    cards.push({
                        title: 'Richiesta Certificazione 24 CFU',
                        link: ROUTES.C24_CERT_REQUEST,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }
                if (payload.titleRequestEnabled === '1') {
                    cards.push({
                        title: 'Richiesta Certificazione e Pergamena',
                        link: ROUTES.COURSE_TITLE_REQUEST,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }                
            }
        } catch (error) {

        }

        return cards;
    }

    static getFixedCards() {

        return [
            {
                title: 'Insegnamenti in piattaforma telematica', link: ROUTES.LEARNING_MAIN,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Insegnamenti in presenza', link: ROUTES.M24_LESSON,
                img: '/img/icon/presentation.png', className: 'image-box'
            }, {
                title: 'Invio materiale', link: ROUTES.LEARNING_FINAL_PAPER,
                img: '/img/icon/document.png', className: 'image-box'
            }, {
                title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Bando e Regolamenti', link: ROUTES.COURSE_DOCS,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            }, {
                title: 'Tutor didattico', link: ROUTES.COURSE_TUTOR,
                img: '/img/icon/call.png', className: 'image-box'
            },
            {
                title: 'Progetto Corso Gratuito', link: ROUTES.COURSE_PROVIDER,
                img: '/img/icon/euro2.png', className: 'image-box'
            }
        ];
    }

    static getDashboardNotices() {

        return [
            {
                title: 'Certificazioni',
                content: `<p>A tutti coloro che avranno superato la prova finale del Master verrà rilasciata, se in regola con la posizione amministrativa e contabile, la <b>Certificazione aggiuntiva</b> ai sensi del comma 5 dell'art. 3 del D.M. n. 616 del 10 Agosto 2017 attestante:</p>
                <ul>
                    <li>Gli obiettivi formativi previsti dal D.M. n. 616 del 10 Agosto 2017;</li>
                    <li>Gli insegnamenti e le attività formative;</li>
                    <li>I settori disciplinari;</li>
                    <li>Le votazioni riportati agli esami ed i Crediti Formativi Universitari acquisiti.</li>
                </ul>
                <p>In fase successiva sarà predisposta la pergamena del Master secondo le modalità previste dall'art. 6 del Bando di Partecipazione.</p>`,
            },
            {
                title: 'Prove finali',
                content: `<p>Per gli <b>insegnamenti in presenza</b>  la prova finale sarà in modalità scritta. Essa si svolgerà nella città scelta dal corsista ed attivata secondo quanto riportato nel Bando di partecipazione. Il corsista dovrà affrontare un questionario con dieci quesiti a risposta multipla ed un quesito a risposta aperta.</p>
                    <p>Per ciascuno degli <b>insegnamenti su piattaforma telematica</b> il corsista in itinere dovrà produrre un saggio breve di almeno 3 pagine su un argomento trattato nel modulo preso in considerazione nonché dovrà superare  il questionario finale di modulo. Tali attività saranno sottoposte alla commissione per la loro validità.</p>
                    <p>La prova finale del Master consisterà nell'esposizione orale del "Saggio Finale" di almeno 10 pagine.</p>
                    <p>L'accesso alla prova finale del Master sarà subordinata oltre alla regolare posizione amministrativa-contabile, anche al superamento delle prove degli insegnamenti in presenza e degli insegnamenti su piattaforma telematica nonché dall'aver seguito il corso, all'atto della prenotazione all'esame finale, per almeno 170 ore sulla piattaforma.</p>`,
            }];
    }
}
