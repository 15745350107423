import React from "react";
import { COMPANY, ENROLLMENT_STEPS, PAYMENT_TYPES } from "const";
import { CourseHelper, enrollmentService, networkErrorHelper } from "libs";
import dayjs from 'dayjs';
import { EnrollSignForm } from "./EnrollSignForm";

export class EnrollSign {

    title = 'Firma';
    iconName = 'it-pencil';  
    enrollment = null;
    user = null;
    action = 'Invia domanda di iscrizione';
    tableName = 'userEnrollment';
    _formRef = null;

    constructor() {
        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.user = payload.user;
        this.userProfile = payload.userProfile;
        this.confirmedMobile = false
    }

    async onNextStep() {

        let canNext = this.canNext = this._formRef.current.validate();

        if (canNext) {
            canNext = await this.updateRemote();
        }

        return canNext;
    }

    async updateRemote() {
        
        let retValue = true;
       
        if (this.enrollment.company === COMPANY.UNICAMILLUS) {
            this.enrollment.currentStep = (this.enrollment.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE) ? ENROLLMENT_STEPS.VIRTUALSTAMP : ENROLLMENT_STEPS.PAY ;
        }else if (CourseHelper.isReggio(this.enrollment.courseCode)) {
            this.enrollment.currentStep = ENROLLMENT_STEPS.PAY;
        }else{
            this.enrollment.currentStep = ENROLLMENT_STEPS.END;
        }
        
        this.enrollment.dateSign = dayjs();
        this.enrollment.dateEnd = dayjs();
        await enrollmentService.endEnrollment(this.enrollment)
            .then(() => {
                retValue = true;
            })
            .catch(errors => {
                console.log(errors);
                retValue = false;
                networkErrorHelper.notify(errors);
            });

        return retValue;
    };

    onPrevStep = () => {

        return false;
    }

    render() {

        const validatorProps = {
            action: this.action,
            tableName: this.tableName,
            idParent: this.enrollment.id,
            phoneNumber: this.userProfile.mobile,
        };

        const enrollData = {
            enrollment: this.enrollment,
            user: this.user,
            userProfile: this.userProfile,
            validatorProps
        };

        return (
            <>
                <EnrollSignForm
                    ref={this._formRef}
                    enrollData={enrollData} />
            </>
        );
    }
}