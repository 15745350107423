import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { NavLink } from 'react-router-dom';
//import { ROUTES } from 'const';
import { BoxDanger } from 'components/misc';
import { userService } from 'libs';
import { Button } from 'design-react-kit';

import { UserChangeEmail} from "components/forms/user/profile";
import { getCurrentUser } from 'store/actions';

class ValidateEmailAlert extends Component {

    constructor(props) {
        super(props);

        this.state = {
            emailResent: false,
            isChangeEmail: false
        };
    }

    //--- ---

    resend = () => {
        this.setState({ emailResent: true });

        userService.resendEmail().then(() => {
            console.log('Email sent');
        }).catch(error => {
            console.error('Error while sending email', error);
        });
    }

    changeEmail = () =>{
        this.setState({ isChangeEmail: true })
    }

    //--- ---

    render() {
        const { emailResent, isChangeEmail } = this.state;
        const { confirmed, email }           = this.props.user;
       
        if (confirmed) { return null; }
    
        const user = getCurrentUser();

        return (
            <>
            <BoxDanger className="my-3">
                Il tuo profilo non risulta completamente attivo e non puoi accedere alle funzionalità del sistema. <br/>
                Abbiamo mandato una email di attivazione a <b>{email}</b>. Per
                favore segui le istruzioni contenute nell'email per attivare il tuo
                account. Se l'email non ti arriva, controlla la tua cartella spam.
                <p className="mt-4">
                    <Button
                        aria-label="Invia Nuovamente Email Attivazione"
                        color="primary bg-dark"                        
                        disabled={emailResent}
                        onClick={()=>{this.resend()}}
                    >
                        <span className="d-button-label">Invia Nuovamente Email Attivazione</span>
                    </Button>

                    <Button
                        aria-label="Cambia Indirizzo Email"
                        color="primary"
                        className="ml-3"
                        outline
                        onClick={()=>{this.changeEmail()}}
                    >
                        <span className="d-button-label">Cambia Indirizzo Email</span>
                    </Button>
                </p>
            </BoxDanger>

            {(isChangeEmail) && <UserChangeEmail user={user}/>}
            </>
        );
    }
}

const stateToProps = ({ app }) => {
    const { user } = app;
    return { user };
};


export default connect(stateToProps)(ValidateEmailAlert);