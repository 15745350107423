import React, { Component } from "react";
import { UserInvoiceData } from "components/forms/user/profile";

import { Container } from 'design-react-kit';

export class MyInvoiceData extends Component {
  
  render() {
    return (
      <>
        <section className="my-4">
          <Container>              
              <UserInvoiceData />
          </Container>
        </section>
      </>
    );
  }
}
