import * as React from "react"

const IconSupportoTecnico = (props) => (
  <svg
    width={props.width ? props.width : 20}
    height={props.height ? props.height : 20}
    fill={props.fill ? props.fill : '#ffffff'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.922 8.118h-.306C14.116 5.761 12.218 4 9.956 4 7.69 4 5.792 5.76 5.292 8.118h-.215a.677.677 0 0 0-.677.677v3.667c0 .374.302.677.677.677h1.016a.677.677 0 0 0 .677-.677V8.795a.677.677 0 0 0-.677-.677h-.188c.486-1.988 2.114-3.457 4.05-3.457 1.934 0 3.562 1.468 4.047 3.457h-.096a.677.677 0 0 0-.677.677v3.667c0 .328.233.601.542.664-.373.7-1.212 1.63-3.077 1.84a.593.593 0 0 0-.542-.353H9.016a.593.593 0 0 0-.594.594v.2c0 .327.266.593.594.593h1.137c.275 0 .505-.19.572-.443 2.343-.258 3.303-1.566 3.685-2.418h.512a.677.677 0 0 0 .678-.677V8.795a.678.678 0 0 0-.678-.677Z"
      fill={props.fill ? props.fill : '#ffffff'}
    />
    <circle cx={10} cy={10} r={9.6} stroke={props.stroke ? props.stroke : '#ffffff'} strokeWidth={0.8} />
  </svg>
)

export default IconSupportoTecnico
