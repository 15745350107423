import React, { Component } from "react";
import { NavLibrary, BooksList } from "components/biblioteca";
import { libraryService } from "libs/api/libraryService";
import { MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

import "./Wishlist.scss";

export class Wishlist extends Component {
  state = {
    books: [],
    errorMessage: "",
    isLoading: true,
    error: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    //favorites books
    libraryService
      .wishlist()
      .then((response) => {
        this.setState({
          isLoading: false,
          books: response.data.payload.wishlist,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const {isLoading, networkError, errorMessage, books} = this.state
    if (isLoading) return <MnemoLoading />;
    return (
      <>
      {networkError ? (
        <>Si è verificato il seguente errore: {errorMessage} </>
      ) : (
      <>  
        <NavLibrary />
        <div className="whislist">
          <div className="whishlist__wrapper">
            <BooksList
                title="I tuoi libri preferiti"
                books={books}
                showAll={true}
            />
          </div>
        </div>
      </>
      )}
      </>
    );
  }
}
