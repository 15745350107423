import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from "design-react-kit";
import { BoxDanger } from "components/misc";

import { NavLink } from "react-router-dom";
import { ROUTES } from "const";

import { MnemoLoading, ModalHandler } from "components/misc";

import ReactHtmlParser from "react-html-parser";

class SurveyCmsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      payload: [],
      payloadToRender: [],
      rndKey: 0,
    };
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "title",
        Header: "Questionario",
        accessor: "title",
      },
      {
        id: "code",
        Header: "Codice",
        accessor: "code",
      },
      {
        id: "aa",
        Header: "AA",
        accessor: "aa",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.PAGE_FORM + "/" + value;
        },
      },
      {
        id: "delete",
        accessor: "id", // field value received in the onclick event
        label: "Elimina",
        icon: "it-delete",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.deleteRecord(e, value);
        },
      },
    ],
  };

  //--- ---

  componentDidMount() {
    this.loadSurvey();
  }

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show(
      value,
      "Eliminazione definitiva del dato",
      `Stai eliminando una pagina. Vuoi davvero eliminare?`,
      null,
      this.deleteConfirm
    );
  }

  deleteConfirm = (value) => {
    adminService
      .deletePage(value)
      .then(({ data }) => {
        this.setState({ loading: true });
        this.loadSurvey();
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  };
  //--- end functions to delete procedure ---

  loadSurvey() {
    adminService
      .loadSurvey()
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.surveys).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        const parseItems = items.map((item) => {
          return { ...item, title: ReactHtmlParser(item.title) };
        });

        this.setState({
          loading: false,
          payload: items,
          payloadToRender: parseItems,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
      })
      .catch((error) => {
        //console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            payloadToRender: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  } //loadSurvey

  //--- ---

  render() {
    const { loading } = this.state;

    return (
      <>
        <div className="container mt-5 mb-4">
          <section className="m-0">
            <div className="form-row m-0 p-0">
              <div className="col-md-12 text-right">
                <NavLink
                  to={ROUTES.SURVEY_FORM}
                  className="btn btn-primary bg-dark pb-2">
                  Nuovo Questionario
                </NavLink>
              </div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payloadToRender}
                  tableConfig={this.tableConfig}
                />
              </Container>
            )}

            {this.state.payload.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                Questionari.
                <div className="small text-info">Nessun dato presente.</div>
              </BoxDanger>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default withRouter(SurveyCmsList);
