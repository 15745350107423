import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';

import { withRouter } from 'react-router-dom';
import { AdminSurvey } from "components/forms/admin/AdminSurvey";

class SurveyCmsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idSurvey: parseInt(this.props.match.params.idSurvey) > 0 ? this.props.match.params.idSurvey : 0,
      title: parseInt(this.props.match.params.idSurvey) > 0 ? 'Gestione Questionario' : 'Nuovo Questionario',
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            <AdminSurvey idSurvey={this.state.idSurvey} />
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(SurveyCmsForm);
