import { ROUTES } from "const";

export class MNESE {

    static getDashboardCards() {

        return [
            {
                title: 'Bando', link: ROUTES.COURSE_MANIFESTO,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Assistenza Telefonica', link: ROUTES.ASSISTANCE_TELEPHONE_MNE,
                img: '/img/icon/call.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            },{
                title: 'Prenotazione esami', link: ROUTES.ESB_EXAM_RESERVATION,
                img: '/img/icon/7.webp', className: 'image-box'
            }
        ];
    }
}
