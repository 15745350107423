import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';

import { BoxDanger, MnemoLoading, MSection, SupportoTecnico, Title, PDFView } from "components/misc";
import { learningService, mnemoService, networkErrorHelper } from "libs";
import { Accordion, AccordionHeader, AccordionBody, Icon } from "design-react-kit";
import { ROUTES } from "const";

class FinalPaper extends Component {

    state = {
        collapseOpen1: false,
        rCode: 0,
        course: 0,
        loading: true,
        istructionPDF: '',
        loadingError: false
    };

    componentDidMount() {

        // get registration code from parameter
        const { rCode, moduleProgress } = this.props.match.params;
        this.loadIstruction(rCode);
        this.loadCourse(rCode, moduleProgress);
    }

    loadIstruction(rCode) {

        const payload = mnemoService.buildPayload('istructionPDF', 'registrationCode', rCode);
        const istructionPDF = process.env.REACT_APP_MNEMO_URL+ '/course/?payload='+encodeURI(payload);        
        this.setState({ rCode, istructionPDF });
    }

    loadCourse(rCode, moduleProgress = -1) {
        learningService
            .courseMain(rCode, 1)
            .then(({ data }) => {
                
                this.setState({ rCode, course: data.payload.course, moduleProgress, loading: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    toggle = id => {
        this.setState({
            ...this.defaultState,
            [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
        })
    }

    
    renderIFrame(rCode, aa, moduleProgress = -1) {

        const aaURL = aa.toString().slice(-2);
        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + 'moodleElearning/renderExamDocs/rCode/' + rCode;
        let height = '3000px';
        if (moduleProgress >= 0) {
            src += '/mod/' + moduleProgress;
            height = '900px';
        }

        return (
            <>
                <div>
                    <embed src={src} width="99%" height={height} />
                </div>
            </>
        );
    }

    renderIstruction() {

        const {istructionPDF} = this.state;        

        return (
            <>
                {(this.state.loadingError)
                    ? <>
                        <BoxDanger className="mt-4">
                            Si è verificato un errore durante il caricamento del file, <SupportoTecnico />
                        </BoxDanger>
                    </>
                    : <>
                    <PDFView file={istructionPDF}  canDownload={true} showAlert={false}></PDFView>
                    </>
                }
            </>
        );
    }

    render() {

        const {
             loading, loadingError, course, 
             moduleProgress, rCode,collapseOpen1 
            } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della lezione, se il problema persiste puoi
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const iframe = this.renderIFrame(rCode, course.aa, moduleProgress);
        const istruction = this.renderIstruction();

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title>Invio materiale</Title>
                <MSection>
                <Accordion>
                    <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                        <h5 className="text-dark card-title" >Istruzioni Operative per l'invio del materiale</h5>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen1}>
                        {istruction}
                    </AccordionBody>                   
                </Accordion>

            </MSection>
                <MSection>
                    {iframe}
                </MSection>
            </>);
    }
}

export default withRouter(FinalPaper);