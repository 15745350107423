import React, { Component } from "react";

import "./mainMenuPublic.scss";

import { ROUTES } from "../../../const";

import Axios from "axios";

// icons
import IconUser from "../../../icons/IconUser";
import IconArrowDown from "../../../icons/IconArrowDown";
// Social Icons
import IconLinkedin from "../../../icons/social/IconLinkedin";
import IconInstagram from "../../../icons/social/IconInstagram";
import IconFacebook from "../../../icons/social/IconFacebook";

export class MainMenuPublic extends Component {
  state = {
    menuItem: null,
    menuSecondary: null,
    menuService: null,
    error: false,
    isDropdownOpen: false,
    accordionIndex: 0,
  };

  componentDidMount() {
    (async () => {
      let getMenuItem = null;
      let getMenuSecondary = null;
      let getMenuService = null;
      try {
        getMenuItem = await Axios.get("/assets/json/NHPublic.json");
        getMenuSecondary = await Axios.get("/assets/json/MenuSecondary.json");
        getMenuService = await Axios.get("/assets/json/MenuService.json");
        this.setState({
          menuItem: getMenuItem.data,
          menuSecondary: getMenuSecondary.data,
          menuService: getMenuService.data,
        });
      } catch (error) {
        this.setState({ error: true });
      }
    })();

    // setTimeout(() => {
    //   accordionToggle();
    // }, 1000);
  } //componentDidMount

  //--- ---

  render() {
    if (!this.state.menuItem) {
      return false;
    }

    const menuItem = this.state.menuItem;
    const menuSecondary = this.state.menuSecondary;
    const menuService = this.state.menuService;

    const toggleAccordion = (accordion) => {
      if (accordion !== this.state.accordionIndex) {
        this.setState({ accordionIndex: accordion });
        this.setState({ isDropdownOpen: true });
      } else {
        this.setState({ accordionIndex: accordion });
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
      }
    };

    return (
      <>
        <div className="mainNavigationMenu">
          <div className="mainNavigationMenu__wrapper">
            <div className="mainNavigationMenu__user">
              <a
                href={ROUTES.LOGIN}
                className="user__link"
                title="acceddi all'area riservata"
              >
                <div className="user__icon">
                  <IconUser />
                </div>
                <div className="user__text">
                  <span>Accedi</span>
                </div>
              </a>
            </div>
            <div className="mainNavigationMenu__primary">
              <ul className="menuPrimary__items">
                {menuItem.map((item, x) => {
                  if (item.to) {
                    return (
                      <li key={`menu-item-${x}`} className="menuPrimary__item">
                        <a
                          className="menuPrimary__item__text"
                          href={item.to}
                          title={item.label}
                        >
                          <span>{item.label}</span>
                        </a>
                      </li>
                    );
                  } else if (item.sub) {
                    return (
                      <li key={`menu-item-${x}`} className="menuPrimary__item">
                        <div className="menuPrimary__item__dropdown">
                          <div
                            className={`title accordion__toggle ${
                              this.state.isDropdownOpen &&
                              this.state.accordionIndex === x
                                ? "open"
                                : ""
                            }`}
                            onClick={() => toggleAccordion(x)}
                            data-accordion={`submenu-primary-${x}`}
                          >
                            <div className="accordion__title">{item.label}</div>
                            <span className="icon">
                              <IconArrowDown />
                            </span>
                          </div>
                          <div
                            className={`accordion__content ${
                              this.state.isDropdownOpen &&
                              this.state.accordionIndex === x
                                ? "open"
                                : ""
                            }`}
                            data-accordion={`submenu-primary-${x}`}
                          >
                            <div data-wrapper-height>
                              <ul className="menuPrimary__submenu">
                                {item.sub.map((el, i) => {
                                  if (el.to) {
                                    return (
                                      <li key={`submenu-item-${i}`}>
                                        <a href={el.to} title={el.label}>
                                          {el.label}{" "}
                                        </a>
                                      </li>
                                    );
                                  } else if (el.label === "divider") {
                                    return (
                                      <div
                                        key={`divider-${i}`}
                                        className="divider"
                                      ></div>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="mainNavigationMenu__secondary">
              <ul className="menuSecondary__items">
                {menuSecondary.map((element, index) => {
                  return (
                    <li
                      key={`secondary-item-${index}`}
                      className="menuSecondary__item"
                    >
                      <a href={element.to} title={element.label}>
                        {element.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="mainNavigationMenu__service">
              <ul className="menuService__items">
                {menuService.map((element, index) => {
                  return (
                    <li
                      key={`service-item-${index}`}
                      className="menuService__item"
                    >
                      <a href={element.to} title={element.label}>
                        {element.label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="mainNavigationMenu__social">
              <ul className="social__items">
                <li className="social__item">
                  <a
                    href="https://www.facebook.com/MnemosineFormazioneDocenti/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconFacebook />
                  </a>
                </li>
                <li className="social__item">
                  <a
                    href="https://www.instagram.com/mnemosine_formazione_docenti/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconInstagram />
                  </a>
                </li>
                <li className="social__item">
                  <a
                    href="https://www.linkedin.com/company/mnemosine"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}
