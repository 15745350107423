import React, { Component } from 'react';
import { MnemoCallout } from './MnemoCallout';
import ReactHtmlParser from 'react-html-parser';

export class SupportoTecnicoLive extends Component {

    render() {

        const { courseCode } = this.props;
        let txt = '';

        switch (courseCode) {

            case 'RC60CFUALL1':
                txt = `In caso di problemi per l'accesso alle lezioni telematiche in modalità sincrona invia un messaggio email a <a href="mailto:lezioni60cfu@formazionedocenti.it">lezioni60cfu@formazionedocenti.it</a>.`;
                break;

            case 'RC30CFUALL2':
                txt = `In caso di problemi per l'accesso alle lezioni telematiche in modalità sincrona invia un messaggio email a <a href="mailto:lezioni30cfu@formazionedocenti.it">lezioni30cfu@formazionedocenti.it</a>.`;
                break;

            default:
                txt = `In caso di problemi per l'accesso alla lezione invia un messaggio email a <a href="mailto:lezioni30cfu@formazionedocenti.it">lezioni30cfu@formazionedocenti.it</a>.`;
                break;
        }

        return (
            <>
                <div className="m-2">
                    <MnemoCallout>
                        {ReactHtmlParser(txt)}
                    </MnemoCallout>
                </div>
            </>
        );
    }
}