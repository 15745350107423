import React from "react";

import {
    MFile,
    ValidatedForm
} from "components/forms";

import {
    notNullObjectBuilder,
    payloadBuilder
} from "libs";

export class EnrollFillFormTFAOrdinario extends ValidatedForm {

    emptyFields = {
        document: 0,
    };

    FIELDS_GROUP = [
        [{
            id: "document",
            field: "document",
            label: "Allegare i Moduli",
            infoText: "Caricare il Modulo opportunamente compilato e firmato",
            component: MFile,
            config: { endPoint: '/enrollment/attachment-file', mandatory: true, fieldName: 'document', maxFiles: 4, accept: '.pdf,.doc,.docx,.jpg,.bmp,.jpeg' },
            className: "col-md-12"
        }]
    ];

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            { document: this.props.enrollData.idEnrollment },
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {
        return false;
    };

    onSubmit = () => {
        //TODO: verificare se ha uploadato il file
        this.payload = payloadBuilder(this.state);

        const isValid = this.checkValidation();
        return isValid;
    }

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            <p>Se non l'hai ancora fatto scaricare i seguenti moduli, compilare e allegare</p>
            <ul>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/Modulo-A-TFA-Ordinari.pdf" target="_blank">Modulo A - Dichiarazione Titoli di accesso (Ordinari)</a>. 
                </li>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/Modulo-B-TFA-Ordinari.pdf" target="_blank">EVENTUALE Modulo B - Modulo di richiesta di strumenti compensativi e tempi aggiuntivi per candidati con disabilità o con Disturbi Specifici dell'Apprendimento (DSA);</a>. 
                </li>
                <li>
                    <a href="https://www.formazionedocenti.it/assets/doc/ModuloC-nulla-osta-soprannumerari-TFA.pdf" target="_blank">EVENTUALE Modulo C - Istanza rilascio nulla osta per frequenza in soprannumero percorsi sostegno presso altro ateneo.</a>. 
                </li>
            </ul>
            {code}
        </>);
    }
}