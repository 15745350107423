import React from 'react';
import ReactDOM from 'react-dom';
import { MnemoModal } from 'components/misc';

export class ModalHandler {

    static show = (value, title, content, onCancel, onSubmit,
        confirmText = 'Conferma', cancelText = 'Annulla', showConfirm=true, showCancel=true) => {
           
            ReactDOM.render(<MnemoModal
                value={value}
                onCancel={onCancel}
                onSubmit={onSubmit}
                show={true}
                title={title}
                confirmText={confirmText}
                cancelText={cancelText}
                showCancel={showCancel}
                showConfirm={showConfirm}
                content={content} />,
                document.getElementById('modal-notification'));
        }
}