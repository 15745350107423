import React, { Component } from "react";
import {
  networkErrorHelper,
  learningService,
  userService,
  dateHelper,
  learningLiveService,
} from "libs";
import { withRouter, NavLink } from "react-router-dom";
import {
  BoxDanger,
  BoxSuccess,
  MnemoCallout,
  MnemoLoading,
  ModalHandler,
  SupportoTecnico,
  Title,
} from "components/misc";
import { ROUTES } from "const";
import {
  Card, CardBody, Icon,
  Accordion, AccordionBody, AccordionHeader, Button
} from "design-react-kit";

class CourseBookingClass extends Component {
  
  state = {
    loading: true,
    loadingError: false,
    course: 0,
    rCode: 0,
    selectedClass: 0,
    lessonLiveClasses: [],
    lessonLiveSchedules: [],
    lessonLiveClassBooking: null,
    class1: null,
    class2: null,
    collapseOpen1: false,
    collapseOpen2: false,
  };

  componentDidMount() {
    const { rCode } = this.props.match.params;
    this.setState({ rCode, loading: true });
    this.loadRemote(rCode);
  }

  loadRemote(rCode) {
    learningService
      .courseDashboard(rCode)
      .then(({ data }) => {
        const { course, userCourse } = data.payload;
        this.setState({ course, userCourse, rCode });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false, loadingError: true });
      });

    learningLiveService.myOpenClassBooking(rCode)
      .then(({ data }) => {
        const { lessonLiveClasses, lessonLiveSchedules, lessonLiveClassBooking } = data.payload;
        const class1 = lessonLiveClasses.find(liveclass => (liveclass.progress === 1));
        const class2 = lessonLiveClasses.find(liveclass => (liveclass.progress === 2));
        this.setState({ lessonLiveClasses, lessonLiveSchedules, lessonLiveClassBooking, class1, class2, loading: false });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false, loadingError: true });
      });
  } //loadRemote

  //--- ---  

  onBackPage = () => {
    window.history.back();
  }; //onBackPage

  toggle = id => {
    this.setState({
      ...this.defaultState,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
    })
  }

  saveClass = (selectedClass) => {

    this.setState({ selectedClass });

    ModalHandler.show(null, 'Scelta Aula',
      "Stai scegliendo " + selectedClass.label + ". Questa scelta non sarà revocabile . Confermi tale scelta? "
      , null, this.saveRemote);

  }

  saveRemote = () => {

    this.setState({ loading: true });
    let payload = {}
    const { selectedClass, rCode, course } = this.state;
    payload.idClass = selectedClass.id;
    payload.stage = selectedClass.stage;
    payload.idCourse = parseInt(course.id);
    payload.registrationCode = rCode;

    userService
      .insertClassGroup(payload)
      .then((result) => {

        this.setState({
          loading: false,
          lessonLiveClassBooking: { ...result.data.payload.lessonLiveClassBooking },
        });
      })
      .catch((errors) => {
        networkErrorHelper.notify(errors);
      });
  }; //saveRemote

  renderCalendar(lessonLiveClass) {

    const { lessonLiveSchedules } = this.state;

    return (
      <table className="MTable table  table-striped table-hover" role="table">
        <thead>
          <tr >
            <th>Giorno</th>
            <th>Data</th>
            <th>CFU per lezione</th>
            <th>Inizio lezione</th>
          </tr>
        </thead>
        <tbody>
          {lessonLiveSchedules.map((schedule, index) => (
            (schedule.idClass === lessonLiveClass.id) &&
            <tr key={index}>
              <td>{dateHelper.toITDay(schedule.date)}</td>
              <td>{dateHelper.toITDateUTC(schedule.date)}</td>
              <td>{schedule.cfu}</td>
              <td>{(schedule.hourStart) ? schedule.hourStart.substring(0, schedule.hourStart.length - 3) : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  renderForm() {

    const {
      collapseOpen1, collapseOpen2, class1, class2,
      lessonLiveClassBooking, userCourse } = this.state;

    const calendar1 = this.renderCalendar(class1);
    const calendar2 = this.renderCalendar(class2);

    // find in this.state.lessonLiveClassBooking the class by the id from lessonLiveClassBooking
    let calendarToShow = '';
    let labelChoise = '';
    if (lessonLiveClassBooking) {

      if (lessonLiveClassBooking.idClass === class1.id) {
        calendarToShow = calendar1;
        labelChoise = class1.label;
      } else {
        calendarToShow = calendar2;
        labelChoise = class2.label;
      }
    }

    return (
      
      <>
        {
          lessonLiveClassBooking ? (
            <>
              <BoxSuccess className="mt-4">
                Hai scelto <b>{labelChoise}</b> in data{" "}
                <b>{dateHelper.toITDate(lessonLiveClassBooking.date)}</b>
              </BoxSuccess>
              {calendarToShow}
            </>
          ) : (
            <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
              <div className="flag-icon" />
              <div className="etichetta">
                <Icon icon="it-pa" padding={false} />
                <span>Scelta dell'Aula</span>
              </div>
              <CardBody tag="div">
                <p>
                  Può liberamente scegliere una delle due Aule con il rispettivo calendario dal 15 al 18 Ottobre 2024. <br />
                  Chi non dovesse effettuare la scelta entro tale termine sarà inserito d'ufficio in una delle Aule a insindacabile decisione dell'Università.<br />
                  Non verranno prese in considerazione altre modalità di scelta dell'Aula. Tale scelta non sarà revocabile, non sarà possibile richiedere una personalizzazione del calendario e non si potrà richiedere, dopo la scelta, di passare dall'una all'altra aula.
                  Lo svolgimento e la durata della singola lezione dipenderà esclusivamente dal Docente: normalmente 1 CFU corrisponde a 6 ore.</p>                                
                <p>Clicca su <b>{class1.label}</b> o <b>{class2.label}</b> per consultare il calendario e confermare la tua scelta.</p>
                <Accordion>
                  <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                    <span className="text-green" >{class1.label}</span>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpen1}>
                    {calendar1}
                    <Button color="primary bg-dark" onClick={(e) => this.saveClass(class1)}>Conferma {class1.label} <br />(Ricordando che la scelta è irrevocabile)</Button>
                  </AccordionBody>

                  <AccordionHeader active={collapseOpen2} onToggle={() => this.toggle(2)}>
                    <span className="text-green" >{class2.label}</span>
                  </AccordionHeader>
                  <AccordionBody active={collapseOpen2}>
                    <MnemoCallout className="mb-2">
                      {calendar2}
                      <Button color="primary bg-dark" onClick={(e) => this.saveClass(class2)}>Conferma {class2.label} <br /> (Ricordando che la scelta è irrevocabile)</Button>
                    </MnemoCallout>
                  </AccordionBody>
                </Accordion>
              </CardBody >
            </Card >
          )
        }
      </>
    );
  }

  render() {
    const { loading, loadingError, rCode } = this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;

    if (loadingError) {
      return (
        <BoxDanger className="mt-4">
          Si è verificato un errore durante il caricamento della pagina, se il
          problema persiste puoi
          <br />
          <SupportoTecnico />
        </BoxDanger>
      );
    }

    return (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        <Title>Scelta dell'Aula</Title>
        {this.renderForm()}

      </>
    );
  }
}

export default withRouter(CourseBookingClass);
