import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import {
    Card,
    CardBody
} from 'design-react-kit';

export class AdminAudioCategory extends ValidatedForm {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idAudioCategory) > 0 ? false : true,
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    //--- ---    

    emptyFields = {
        idParent: 0,
        title: ''
    }


    FIELDS_GROUP = [
        [
            {
                id: "title",
                field: "title",
                label: "titolo categoria",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ]
    ];

    ERROR_MESSAGES = {
        title: "Titolo non valido (max 255 caratteri)"
    };

    validation = {
        title: value => /^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value) && value.length <= 255
    };

    
    //--- ---

    componentDidMount(){
        this.loadRemote();
    }
    
    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idAudioCategory) <= 0) {
            return false;
        }

        adminService.loadAudioAssetCategory(parseInt(this.props.idAudioCategory))
            .then(({ data }) => {
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload,
                );
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false,
                };
                
                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }//loadRemote

    onBackPage = () => {
        window.history.back();
    }//onBackPage

    saveRemote = () => {
        let payload = payloadBuilder(this.state);
       
        if (parseInt(this.props.idAudioCategory) > 0) {
            adminService.updateAudioCategory(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertAudioCategory(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    }//saveRemote

    //--- ---

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idAudioCategory) === 0) {
            formActive = true;
        }

        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idAudioCategory) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
