
import { AdvHandler } from "components/adv/AdvHandler";
import React, { Component } from "react";
import ReactNotification from "react-notifications-component";

export class Body extends Component {
  render() {
    return (
      <>
        <main>
          <ReactNotification />
          <div tag="div" id="main-container">
            {this.props.children}
          </div>
        </main>     
        { AdvHandler.renderModal()}
      </>
    );
  }
}
