import { MFile } from "components/forms/shared/MFile";
import React, { Component } from "react";

export class FileHandler extends Component {
    
    render() {        

        return (
            <>
                <MFile
                id='userDocumentFile'
                label="Documento di riconoscimento"
                idParent='10'                
                accept ='.pdf, .jpg, .jpeg, .png, .tiff, .tif, .gif, .bmp'
                infoText="Caricare un documento di riconoscimento valido"
                />
            </>
        );
    }
}