import React, { Component } from "react";

import {
  Card,
  CardBody,
  Icon
} from 'design-react-kit';

import { MTable } from "components/table";
import { ROUTES } from 'const';

import { dateHelper } from 'libs';

export class UserDetailEnrollment extends Component {

  //--- ---
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'orderNumber',
        Header: 'Codice Registrazione',
        accessor: 'orderNumber', // accessor is the "key" in the data

      },
      {
        id: 'aa',
        Header: 'AA',
        accessor: 'aa',

      },
      {
        id: 'dateStart',
        Header: 'Data Inizio',
        //accessor: 'dateStart', // accessor is the "key" in the data
        accessor: row => { return (row.dateStart) ? dateHelper.toITDate(row.dateStart) : '' },

      },
      {
        id: 'currentStep',
        Header: 'STEP',
        accessor: 'currentStep',
      },
      {
        id: 'courseCode',
        Header: 'Codice Corso',
        accessor: 'courseCode',
      },
      {
        id: 'importToPay',
        Header: 'Importo',
        accessor: 'importToPay',
      },
      {
        id: 'paymentType',
        Header: 'Tipo Pagamento',
        accessor: 'paymentType',
      },
      {
        id: 'paymentStatus',
        Header: 'Stato Pagamento',
        accessor: 'paymentStatus',
      },
      {
        id: 'tmpDeleted',
        Header: 'Stato',
        accessor: 'tmpDeleted',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.USER_LIST + '/' + this.props.account.role + '/enrollment/' + value;
        }
      }
    ]
  }

  loadTableData(){
    const items = [];
    Object.entries(this.props.data).forEach(([key, val]) => {
      if (val.deleted === false) {
        val.tmpDeleted = 'abilitato'
      } else {
        val.tmpDeleted = 'disabilitato'
      }

      items.push(val);
    })
    
    return items;
  }


  //--- ---

  render() {

    if (!this.props.data) return false;


    return (
      <>
        <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-user" padding={false} />
            <span>Domande di iscrizione</span>
          </div>
          <CardBody>
            <MTable tableData={this.loadTableData()} tableConfig={this.tableConfig} />
          </CardBody>
        </Card>
      </>
    );
  }

}
