import React, { Component } from 'react';
import { stringHelper } from 'libs';

export class FormRCSUCombo extends Component {

    importToPayTotal = 0;
    importBollo = 16;
    importSegreteria = 50;
    //importToPay = 116;
    importToPay = 66;
    importForCFU=35;
    
    componentDidMount() {

        this.updateImportToPay();
    }

    getValue() {

        const coursesSubcribe = this.props.pluginConfig;

        const pluginValue = {
            // il valore dei corsi selezionati è una stringa separata da virgole con gli id della tabella mbo_module.id mysql
            courses: coursesSubcribe.map(course => course.id).join(','),
        };

        return pluginValue;
    }

    checkValidation() {

        return true;
    }

    getCFUSubscribed() {

        const coursesSubcribe = this.props.pluginConfig;

        // check max 60 CFU
        let cfu = 0;
        coursesSubcribe.forEach(course => {
            cfu += parseInt(course.cfu);
        });

        return cfu;
    }

    updateImportToPay() {

        const coursesSubcribe = this.props.pluginConfig;

        // update import and change it
        const payload = [];
        const promoAmount = this.props.promoAmount ? this.props.promoAmount : 0;

        const cfu = this.getCFUSubscribed(coursesSubcribe);
        payload.push({ value: this.importToPay, label: 'Prima rata ' + stringHelper.formatEuro(this.importToPay) })

        this.importToPayTotal = (this.importForCFU * cfu) - promoAmount + this.importBollo + this.importSegreteria;

        //payload.push({ value: importToPay, label: 'Soluzione unica ' + stringHelper.formatEuro(importToPay) })

        /*const countSubscribed = coursesSubcribe.length;
        if (countSubscribed > 1 && countSubscribed <= 3) {
            const firstRate = importToPay / 2;
            payload.push({ value: firstRate, label: 'Prima rata ' + stringHelper.formatEuro(firstRate) })
        } else if (countSubscribed > 3) {
            const firstRate = (importToPay / 3);;
            payload.push({ value: firstRate, label: 'Prima rata ' + stringHelper.formatEuro(firstRate) })
        }*/

        const { onChangeImport } = this.props;
        setTimeout(function () {
            onChangeImport(payload)
        }, 1000);
    }

    render() {

        return (

            <>

                <p>Certificato rilasciato dall'Università per Stranieri “Dante Alighieri” di Reggio Calabria (D.M. MUR n. 504 del 17 ottobre 2007)</p>
                <p>vedi <a href="/files/corsi/RCSU/Bando_RCSU.pdf" target="_blank"><b>Bando di Partecipazione</b></a></p>
                <hr />
                <div className="alert alert-success fade show" role="alert"><p>Totale da pagare {stringHelper.formatEuro(this.importToPayTotal)}</p></div>
            </>
        );
    }
}