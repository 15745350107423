import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, ModalNotification, MSection, SupportoTecnico, Title } from "components/misc";
import { dateHelper, examService, learningService, networkErrorHelper, toolService, userService } from "libs";
import { ROUTES, SITE_CONFIG } from "const";
import {
    Icon, Row, Col,
    Callout, CalloutTitle
} from "design-react-kit";
import { MobileValidation } from "components/tools";

class C24ExamWritten extends Component {

    state = {
        loading: true,
        outSideHourMessage: '',
        rCode: '',
        isEnabled: true,
        message: '',
        currentConfirm: 0,
        writtenExams: []
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {
        
        toolService
            .siteConfig(SITE_CONFIG.EXAM_WRITTEN_HOUR)
            .then(({ data }) => {             
               const {config} = data.payload;
               if (dateHelper.timeInTheFuture(config.END)){
                   this.setState({outSideHourMessage : 'Esame disponibile solamente entro le ore '+ config.END});
               }
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        userService
            .me()
            .then(({ data }) => {
                this.setState({ user: data.payload.user });
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        userService
            .profile()
            .then(({ data }) => {
                const userProfile = data.payload.profile;
                this.setState({ userProfile });
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        examService
            .getProveProfitto(rCode)
            .then(({ data }) => {

                const { writtenExams } = data.payload;
                this.setState({ writtenExams });
            })
            .catch(error => {
                console.log(error)
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course });

                examService.ckeckCFUWrittenExam(rCode)
                    .then(({ data }) => {
                        const { isEnabled, message } = data.payload;
                        this.setState({ loading: false, isEnabled, course, userCourse, rCode, message });
                    })
                    .catch(error => {

                        networkErrorHelper.notify(error);
                        this.setState({ loading: false, loadingError: true });
                    });
            })
            .catch(error => {

                networkErrorHelper.notify(error);
                this.setState({ loading: false, loadingError: true });
            });
    }

    onSendSMS = (idQuiz) => {
        
        this.setState({ currentConfirm: idQuiz });
    }

    onConfirmed = (idQuiz) => {

        // show message la prova sta per iniziare
        ModalNotification.render('Stai per essere reindirizzato alla prova di profitto',
            'Avvio della prova di profitto');

        const quiz = this.state.writtenExams.filter(item => (item.id === idQuiz));

        if (quiz) {

            // send confirmation to segreteria
            examService.confirmSMS(this.state.rCode, quiz[0].adCode)
                .then(({ data }) => {

                })
                .catch(error => {
                    console.log(error);
                });

            // start it
            setTimeout(() => {
                window.location = ROUTES.LEARNING_QUIZ_MAIN + '/' + quiz[0].resourceId + '/' + idQuiz + '/' + this.state.rCode;
            }, 2000);
        }
    }

    renderValidator(examInfo) {

        const isEnabled = (this.state.currentConfirm > 0 && this.state.currentConfirm !== examInfo.id) ? false : true;

        return (<MobileValidation
            action="convalida_prova_profitto"
            tableName="quiz"
            idParent={examInfo.id}
            canEdit={false}
            isEnabled={isEnabled}
            buttonText="Convalida"
            phoneNumber={this.state.userProfile.mobile}
            onSendSMS={this.onSendSMS}
            onConfirmed={this.onConfirmed} >
        </MobileValidation>);
    }

    render() {

        const { loading, loadingError, outSideHourMessage,
            rCode, user, writtenExams, userProfile,
            isEnabled, message } = this.state;

        if (loading) return <MnemoLoading />;
        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste
                <SupportoTecnico />
            </BoxDanger>);
        }

        if (outSideHourMessage) {
            return (<BoxDanger className="mt-4">
                {outSideHourMessage}
            </BoxDanger>);
        }

        const labelOA  = (userProfile.gender === 'm')  ? 'o' : 'a';
        const labelIlLa = (userProfile.gender === 'm')  ? 'Il' : 'La';        

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" />TORNA ALLA HOME PAGE DEL CORSO
            </NavLink>
            <Title>Prove di profitto</Title>
            {(!isEnabled) ?
                (<>
                    <BoxDanger className="mt-4">{message}</BoxDanger>
                </>) : (<>
                    <MSection>
                        <p>
                            {labelIlLa} sottoscritt{labelOA} <b>{user.name} {user.surname}</b> cliccando su "Convalida" accetta e dichiara sotto la propria responsabilit&agrave;
                            ai sensi delle norme vigenti che:
                        </p>
                        <ol type="a">
                            <li>questa straordinaria modalit&agrave; di svolgimento dell'esame &egrave; dettata dalle normative vigenti riguardanti l’insolita emergenza sanitaria in atto su tutto il territorio Nazionale;</li>
                            <li>svolger&agrave;  le prove senza il sussidio di alcun materiale didattico;</li>
                            <li>nella stanza dove svolge la prova non &egrave; presente nessun’altra persona oltre al sottoscritto;</li>
                            <li>seguir&agrave; tutte le indicazioni inviate per email sulle modalit&agrave; di svolgimento delle prove e successivi adempimenti burocratici.</li>
                        </ol>
                        {(writtenExams && writtenExams.length > 0)
                            ? (writtenExams.map((examInfo, i) => (
                                <Row key={`examInfo-${i}`}  >
                                    <Col size="12" >
                                        <Callout className="mnemo-callout-success p-3" highlight tag="div">
                                            <CalloutTitle tag="h6">
                                                {examInfo.title}
                                            </CalloutTitle>
                                            <hr />
                                            {(examInfo.smsAction && examInfo.smsAction.dateConfirmed) ?
                                                (<>
                                                    <p>Prova Convalidata in data {dateHelper.toITDate(examInfo.smsAction.dateConfirmed)} alle ore {examInfo.smsAction.timeConfirmed}  </p>
                                                </>) : (<>
                                                    {this.renderValidator(examInfo)}
                                                </>)}
                                        </Callout>
                                    </Col>
                                </Row>
                            ))) :
                            <BoxDanger>Non risultano prove di profitto da eseguire.</BoxDanger>
                        }
                    </MSection>
                </>)}
        </>)
    }
}

export default withRouter(C24ExamWritten);