import React, { Component } from "react";

import { BoxSuccess, BoxDanger } from "components/misc";
import ReactHtmlParser from 'react-html-parser';
import i18n from "app/i18n";

class QuestionOutcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: this.props.question,
            answers: this.props.answers,
            status: null,
            message: ''
        };
    }

    componentDidMount() {
        this.setOutcome();
    }

    setOutcome = () => {
        //--- ReInit ---
        this.setState({
            status: null,
            message: i18n.t('noAnswer')
        });
        

        //--- use it to manage message grouping feedback
        let tmpIDQuestion = 0;
        let messageGroup  = '';
        
        this.state.answers.forEach(item => {

            if (item.userAnswer && parseInt(item.grade) > 0) {
                this.setState({
                    status: true,
                    message: i18n.t('rightAnswer')
                });
            }
            
            if (item.userAnswer && parseInt(item.grade) === 0) {

                //--- manage message grouping feedback ---
                if(tmpIDQuestion !== item.idQuestion){
                    tmpIDQuestion = item.idQuestion;
                    messageGroup = '';
                }
                if(item.feedback !== '' && item.feedback !== '{}' && item.feedback !== null){
                    messageGroup += item.feedback+'</br>';
                }else{
                    messageGroup = i18n.t('wrongAnswer');
                }

                this.setState({
                    status: false,
                    message: ReactHtmlParser(messageGroup)
                });
            }

            if(item.userAnswer && this.state.question.type === 'fill' && this.state.question.renderMode === 'fillFreeMulti' && this.state.question.maxChoose !== this.state.answers.filter(item => item.userAnswer === true && parseInt(item.grade) > 0).length){
                this.setState({
                    status: false,
                    message: i18n.t('wrongAnswer')
                });
            }

            //--- manage exception where question type is checkbox and answer not all right ---
            /*if((this.state.question.type === 'check')  && 
            this.state.question.maxChoose !== this.state.answers.filter(item => item.userAnswer === true && parseInt(item.grade) > 0).length){
                this.setState({
                    status: false,
                    message: i18n.t('wrongAnswer')
                });
            }*/
        });
    };//setOutcome


    render() {

        if(['text'].includes(this.state.question.type)){ return false;}
        /** NOTE: stiamo bloccando il feedback per questi casi (richiesta dalle Prof di inglese) */
        //if (this.state.question.type === 'fill' && (this.state.question.renderMode === 'fillFree' || this.state.question.renderMode === 'fillFromSelect')) return false;
        if (!this.state.answers && !this.props.metadata.feedbackAnswer) return false;

        return (
            <div className="m-0 p-0 mt-3">
                {
                    (this.props.metadata.feedbackAnswer && (this.state.status === true || this.state.status === false)) && 
                        ((this.state.status === true) ?
                        (<BoxSuccess>{this.state.message}</BoxSuccess>) :
                        (<BoxDanger>{this.state.message}</BoxDanger>))
                }
            </div>
        );
    }
}

export default QuestionOutcome;