import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminLessonLiveClassForm } from "components/forms/admin/AdminLessonLiveClassForm";


class LessonLiveClassForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
        idLessonLiveClass: parseInt(this.props.match.params.idLessonLiveClass) > 0 ? this.props.match.params.idLessonLiveClass : 0
    }
  }

  render() {

    const { idLessonLiveClass } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{(idLessonLiveClass > 0) ? 'Aggiorna Classe' : 'Crea Classe'}</Title>
            <AdminLessonLiveClassForm idLessonLiveClass={idLessonLiveClass || 0}/>
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(LessonLiveClassForm);
