import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { uriHelper } from 'libs/helpers/uriHelpers';
import { BoxDanger, BoxSuccess, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { enrollmentService, stringHelper } from 'libs';
import { ROUTES } from "const";

class CardPaymentOK extends Component {

    state = {
        orderNumber: '',
        tableName: '',
        loading: true,
        loadingError: false,
        paymentError: false
    }

    componentDidMount() {

        const params = uriHelper.parseQueryString();
        
        const { orderNumber, tableName } = this.props.match.params;
        const { messaggio, esito } = params;

        if (esito === 'KO') {

            // show message from bank
            this.setState({
                orderNumber, tableName
                , messaggio: stringHelper.replaceAll(messaggio, '+', ' ')
                , paymentError: true
                , loading: false
            });
        } else {

            if (tableName === 'userEnrollmentPayment') {
                this.setState({tableName, loading:false, loadingError:false, paymentError: false});
                this.loadPayment(orderNumber, tableName, params);
                window.setTimeout(() => {
                    window.location = ROUTES.MY_ENROLLMENT+ '/' + orderNumber;
                }, 5000);
            } else {
                // TODO: other payments
                this.setState({
                    orderNumber, tableName
                    , messaggio: stringHelper.replaceAll(messaggio, '+', ' ')
                    , loadingError: esito === 'OK' ? false : true
                    , loading: false
                });
            }
        }
    }

    loadPayment(orderNumber, tableName, params) {

        enrollmentService
            .getPayment(orderNumber)
            .then(({ data }) => {
                const { enrollmentPayment } = data.payload;               
                const loadingError = enrollmentPayment.esito === 'KO' ? true : false;
                this.setState({
                    orderNumber, tableName,
                    loading: false,
                    loadingError, enrollmentPayment,
                    messaggio: stringHelper.replaceAll(params.messaggio, '+', ' ')
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, loadingError: true });
            });

    }

    render() {

        const { loading, loadingError, paymentError, tableName, messaggio } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (<>
            <Title>Pagamento con carta di credito completato</Title>
            {paymentError
                ? (<>
                    <BoxDanger>Si è verificato il seguente  errore durante il pagamento: <b>{messaggio}</b>
                        <br />Dalla tua area riservata puoi riprovare ad eseguire il pagamento (o cambiare la modalità di pagamento).
                        <br /> Se hai difficoltà puoi <SupportoTecnico /><br />
                    </BoxDanger>
                </>)
                : (<>{loadingError ? (<>
                    <BoxDanger>Si è verificato un errore durante il caricamento dei dati del pagamento.
                        {messaggio && (<><br />Ulteriori informazioni: <b>{messaggio}</b></>)}
                        <br />Dalla tua area riservata puoi riprovare ad eseguire il pagamento (o cambiare la modalità di pagamento)<br />
                        Se hai difficoltà puoi <SupportoTecnico /><br />
                    </BoxDanger>
                </>) : (<>
                    <BoxSuccess>Il pagamento è andato a buon fine. <br />
                        {(tableName === 'userEnrollmentPayment') ?
                            (<>
                                Stai per essere reindirizzato nella pagina di completamento della domanda di iscrizione.
                            </>) : (<>
                                Siamo lieti di confermare che abbiamo ricevuto il tuo pagamento.
                            </>)}                       
                    </BoxSuccess>
                </>)}
                </>)}
        </>);
    }
}

export default withRouter(CardPaymentOK);