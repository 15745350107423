import React from "react";
import { MSelect, MInput, FormEnabler, ValidatedForm } from "components/forms";
import ReactHtmlParser from 'react-html-parser';
import {
  payloadBuilder,
  userService,
  dateHelper,
  notNullObjectBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  utilsService,
  userAddressService
} from "libs";

import { GENDERS } from 'const';

import {
  Card,
  CardBody,
  Icon
} from 'design-react-kit';
import { MAddress } from "components/forms/shared/MAddress";
import { BoxDanger, MnemoCardTag, MnemoLoading, SupportoTecnico } from "components/misc";
import { FiscalCodeHelper } from "libs/helpers/fiscalCodeHelper";
import { getCurrentUser } from "store/actions";

export class UserProfile extends ValidatedForm {

  payload_nations = [];

  emptyFields = {
    dob: '',
    fiscalCode: '',
    phone: '',
    mobile: '',
    gender: '',
    nationality: 'ITALIA'
  };

  state = {
    isNew: false,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields
    },
    fiscalCodeError: ''
  };

  ERROR_MESSAGES = {
    dob: "Data di nascita non valida",
    fiscalCode: "Codice Fiscale non valido",
    mobile: "Numero di cellulare non valido ",
    gender: "Sesso non valido"
  };

  validation = {
    dob: value => dateHelper.noFuture(value), ///^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
    fiscalCode: value => /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$/.test(value),
    mobile: value => /^((00|\+)\d{2}[. ]??)??\d{3}[. ]??\d{6,8}$/.test(value),
    gender: value => GENDERS.filter(g => g.value === value).length > 0
  };

  FIELDS_GROUP = [
    [
      {
        field: "gender",
        label: "Sesso",
        component: MSelect,
        payload: { options: GENDERS },
        className: "col-md-6"
      },
      {
        field: "dob",
        label: "Data di Nascita",
        component: MInput,
        type: "date",
        className: "col-md-6"
      }
    ],
    [
      {
        field: "bornAddressId",
        label: "Indirzzio",
        type: "born",
        component: MAddress,
        className: "col-md-12"
      },
    ],
    [
      {
        field: "fiscalCode",
        label: "Codice Fiscale",
        component: MInput,
        type: "text",
        className: "col-md-6"
      },
      {
        field: "nationality",
        label: "Prima cittadinanza",
        component: MSelect,
        payload: { options: this.payload_nations },
        className: "col-md-6"
      }
    ],
    [
      {
        field: "phone",
        label: "Telefono",
        component: MInput,
        type: "tel",
        className: "col-md-6"
      },
      {
        field: "mobile",
        label: "Cellulare",
        infoText: "La modifica richiederà l'approvazione del nuovo numero.",
        component: MInput,
        type: "tel",
        className: "col-md-6"
      }
    ]
  ];

  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  loadRemote(additionalState = {}) {

    try {
      if (this.payload_nations.length === 0) {
        utilsService.nations().then(({ data }) => {
          const { payload } = data;
          const nations = payload.map(({ name }) => ({ label: name, value: name }));
          this.payload_nations.push({ label: 'ITALIA', value: 'ITALIA' });
          this.payload_nations.push(...nations);
        })
          .catch(errors => {
            console.log(errors);
          });
      }

      userService
        .profile()
        .then(async ({ data }) => {

          // merge not null values and format dates
          const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            data.payload.profile,
            { dob: dateHelper.toUSDate(data.payload.profile.dob) }
          );

          let isNew = false;
          if (defaultValues.fiscalCode == null || defaultValues.fiscalCode.length == 0) {
            isNew = true;
          }

          userAddressService.address(defaultValues.bornAddressId)
            .then(({ data }) => {
              this.setState({ bornAddress: data.payload.address });
            })

          let fiscalCodeError = '';

          if (!isNew && data.payload.profile.canverifycf === true) {
            const user = getCurrentUser();
            const calculatedFC = await FiscalCodeHelper.calculateFiscalCode(user, defaultValues);
            if (calculatedFC !== defaultValues.fiscalCode) {

              fiscalCodeError = `Codice fiscale <b>${defaultValues.fiscalCode}</b> non valido. Ricontrollare i dati inseriti.`;
            }
          }

          const newState = {
            fiscalCodeError,
            isNew,
            defaultValues,
            ...additionalState,
            loading: false,
          };

          this.setState(newState);
        });

    } catch (error) {
      networkErrorHelper.notify(error);
      this.setState({ loading: false });
    }
  }

  saveRemote = async () => {

    const payload = payloadBuilder(this.state);
    const user = getCurrentUser();

    // validate fiscalCode
    if(payload.canverifycf === true){
      const calculatedFC = await FiscalCodeHelper.calculateFiscalCode(user, payload);
      if (calculatedFC !== payload.fiscalCode) {

        const fiscalCodeError = `Codice fiscale <b>${payload.fiscalCode}</b> non valido. Ricontrollare i dati inseriti.`;
        this.setState({ fiscalCodeError });
        return false;
      } else {
        this.setState({ fiscalCodeError: '' });
      }
    }

    userService.updateProfile(payload)
      .then(() => {

        const validations = {
          title: null,
          dob: null,
          fiscalCode: null,
          mobile: null,
          gender: null
        };

        userAddressService.address(payload.bornAddressId)
          .then(({ data }) => {
            this.setState({ bornAddress: data.payload.address });

          })

        this.setState({ formActive: false, ...validations, isNew: false });
      })
      .catch(errors => {

        if (networkErrorHelper.is422(errors)) {
          const newState = invalidFieldsMapper(
            errors,
            this.state,
            this.ERROR_MESSAGES
          );
          this.setState({ ...newState });

        } else {
          networkErrorHelper.notify(errors);
        }
      });
  };

  showForm = () => {
    this.setState({ formActive: true });
  }

  renderInfo() {

    const { isNew, defaultValues, bornAddress,fiscalCodeError } = this.state;

    const cardProps = {
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      actionLabel: 'Inserisci',
      tag: 'Dati Anagrafici',
      smallicon: 'it-user',
      isReadOnly: this.props.isReadOnly,
      actionClick: this.showForm
    }

    if (isNew) {
      cardProps.actionLabel = 'Inserisci';

    } else {
      cardProps.actionLabel = 'Modifica';

      cardProps.content = (
        <>
          <b>Telefono:</b> {defaultValues.phone}<br />
          <b>Cellulare:</b> {defaultValues.mobile}<br />
          <b>Codice Fiscale:</b> {defaultValues.fiscalCode}<br />
          <b>Sesso:</b> {defaultValues.gender}<br />
          <b>Prima cittadinanza:</b> {defaultValues.nationality}<br />
          <b>Nato il:</b> {dateHelper.toITDate(defaultValues.dob)}<br />
          {bornAddress && (<>
            {bornAddress.isForeign
              ? (<>
                <b>Nato a:</b> {bornAddress.cityForeign}<br />
                <b>Nazione:</b> {bornAddress.nation}<br />
              </>) : (<>
                <b>Nato a:</b> {bornAddress.city}<br />
                <b>Provincia:</b> {bornAddress.province}<br />
              </>)}
          </>)}
          {fiscalCodeError && (<BoxDanger>{ReactHtmlParser(fiscalCodeError)} Se il problema persiste <SupportoTecnico /></BoxDanger>)}
        </>
      );
    }

    return (<>
      <MnemoCardTag {...cardProps}></MnemoCardTag>
    </>)
  }

  renderForm() {
    let { formActive, defaultValues, loading, isNew, fiscalCodeError } = this.state;
    if (loading) return (<><MnemoLoading /></>);

    const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
        <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-user" padding={false} />
            <span>Dati Anagrafici</span>
          </div>
          <CardBody tag="div">
            <form className="user" id="userDocument_form">
              {code}
            </form>
            {fiscalCodeError && (<BoxDanger>{ReactHtmlParser(fiscalCodeError)} Se il problema persiste <SupportoTecnico /></BoxDanger>)}
            <div >
              <FormEnabler
                isNew={isNew}
                onToggle={this.toggleForm}
                isFormActive={formActive}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                disableSave={!formValid}
                hideEdit={this.props.isReadOnly}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {

    const { formActive } = this.state;
    if (formActive) {
      return this.renderForm();
    } else {
      return this.renderInfo();
    }

  }
}
