import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import {
  BoxDanger,
  BoxSuccess,
  MnemoCallout,
  MnemoLoading,
  ModalHandler,
  SupportoTecnico,
  Title,
} from "components/misc";
import { examService, networkErrorHelper } from "libs";
import { Button, Icon, Row, Col } from "design-react-kit";
import { ROUTES } from "const";
import {
  FormEnabler,
  MLabel,
  MRadioButton,
  MTextArea,
  ValidatedForm,
} from "components/forms";

import { rc30ExamService } from "libs/api/rc30ExamService";

class RC30UDAExamWritten extends ValidatedForm {
  emptyFields = {
    examSubject: "",
    titolo: "",
    contestualizzazione: "",
    destinatari: "",
    tempi: "",
    situazione: "",
    disciplina: "",
    competenze: "",
    apprendimento: "",
    attivita: "",
    strategia: "",
    verifica: "",
  };

  state = {
    loading: true,
    loadingError: false,
    showSuccess: false,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    examQuestion: [],
    rCode: "",
    exam: {},
    examRevision: {},
  };

  PAYLOADS = {
    examSubjects: [],
  };

  FIELDS_GROUP = [
    [
      {
        label: "Argomento",
        for: "examSubject",
        infoText: "Seleziona un argomento oggetto dell'UdA",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "examSubject",
        question: "Argomento",
        payload: { options: this.PAYLOADS.examSubjects },
        component: MRadioButton,
        type: "radio",
        className: "col-md-12",
      },
    ],
    [
      {
        label: "Titolo UDA",
        for: "titolo",
        infoText:
          "Il titolo non deve essere troppo lungo e deve essere auto-esplicativo dell'UdA. Può essere accompagnato da un sottotitolo (massimo 500 caratteri).",
        component: MLabel,
        className: "col-md-12  pt-3",
      },
    ],
    [
      {
        field: "titolo",
        question: "Titolo UDA",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Contestualizzazione",
        for: "contestualizzazione",
        infoText:
          "Descrivere brevemente la scelta della situazione/problema/tema dell'UdA, in relazione al percorso formativo degli studenti. Indicare il focus dell'UdA rispetto agli assi culturali/sezioni e/o ai profili di indirizzo e l'eventuale collegamento con altre UdA (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "contestualizzazione",
        question: "Contestualizzazione",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Destinatari",
        for: "destinatari",
        infoText:
          "Specificare il grado di scuola, l'eventuale indirizzo, la classe di riferimento, la numerosità della classe, il gruppo classe, l'eventuale presenza di alunni BES, ecc (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "destinatari",
        question: "Destinatari",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Tempi di realizzazione dell'UdA",
        for: "tempi",
        infoText:
          "Indicare le ore previste per ciascuna attività progettata, comprese quelle realizzate in contesti non formali (ad esempio, ore di studio a casa), e il monte ore complessivo per realizzare l'intera UdA (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "tempi",
        question: "Tempi di realizzazione dell'UdA",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Situazione/problema/tema di riferimento dell'UdA",
        for: "situazione",
        infoText:
          "Individuare dei compiti “autentici” (di realtà) che dovranno essere significativi e sfidanti per gli studenti e coerenti con i focus individuati (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "situazione",
        question: "Situazione/problema/tema di riferimento dell'UdA",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
      },
    ],
    [
      {
        label: "Disciplina/e coinvolta/e",
        for: "disciplina",
        infoText:
          "Specificare la/le disciplina/e coinvolta/e e gli eventuali ambiti trasversali (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "disciplina",
        question: "Disciplina/e coinvolta/e",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Competenze che l'UdA si prefigge di sviluppare",
        for: "competenze",
        infoText:
          "Esplicitare le competenze che l'UdA permette di sviluppare facendo possibilmente riferimento alle competenze chiave di cittadinanza europea e ai traguardi dei profili in uscita dei diversi ordini di scuola (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "competenze",
        question: "Competenze che l'UdA si prefigge di sviluppare",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Setting di apprendimento",
        for: "apprendimento",
        infoText:
          "Occorre prestabilire cosa serve per realizzare questa UdA (libro di testo, libri di approfondimento, LIM, tablet, pc, ecc.) e come deve essere il setting ovvero se può essere svolta in aula o serve un laboratorio ed eventualmente come deve essere organizzata l'aula (banchi a platea, banchi ad isola, banchi a ferro di cavallo, ecc.) (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "apprendimento",
        question: "Setting di apprendimento",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Attività",
        for: "attivita",
        infoText:
          "Specificare tutte le attività che saranno svolte e per ciascuna attività specificare ciò che faranno gli allievi, come lo faranno, e cosa farà l'insegnante (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "attivita",
        question: "Attività",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Strategia/e didattica/che",
        for: "strategia",
        infoText:
          "Specificare solo il nome della/e strategia/e didattica/che utilizzata/e per esempio cooperative learning, brainstorming, ricerca-azione, ecc. (massimo 1.200 caratteri)",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "strategia",
        question: "Strategia/e didattica/che",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
    [
      {
        label: "Modalità di verifica e/o valutazione",
        for: "verifica",
        infoText:
          "Specificare quali strumenti di verifica e/o valutazione saranno utilizzati per le attività dell'UdA e la loro funzione (valutazione formativa o sommativa) (massimo 1.200 caratteri).",
        component: MLabel,
        className: "col-md-12 pt-3",
      },
    ],
    [
      {
        field: "verifica",
        question: "Modalità di verifica e/o valutazione",
        component: MTextArea,
        className: "col-md-12",
        rows: 10,
        maxLen: 500,
      },
    ],
  ];

  MAX_LEN = {
    titolo: 500,
    contestualizzazione: 1200,
    destinatari: 1200,
    tempi: 1200,
    situazione: 1200,
    disciplina: 1200,
    competenze: 1200,
    apprendimento: 1200,
    attivita: 1200,
    strategia: 1200,
    verifica: 1200,
  };

  ERROR_MESSAGES = {
    examSubject: "Selezionare un argomento",
    titolo: `Specificare il Titolo UDA (max ${this.MAX_LEN["titolo"]} caratteri)`,
    contestualizzazione: `Specificare la Contestualizzazione (max ${this.MAX_LEN["contestualizzazione"]} caratteri)`,
    destinatari: `Specificare i Destinatari (max ${this.MAX_LEN["destinatari"]} caratteri)`,
    tempi: `Specificare i Tempi di realizzazione dell'UdA (max ${this.MAX_LEN["tempi"]} caratteri)`,
    disciplina: `Specificare la Disciplina/e coinvolta/e (max ${this.MAX_LEN["disciplina"]} caratteri)`,
    competenze: `Specificare le Competenze che l'UdA si prefigge di sviluppare (max ${this.MAX_LEN["competenze"]} caratteri)`,
    apprendimento: `Specificare le Competenze che l'UdA si prefigge di sviluppare (max ${this.MAX_LEN["apprendimento"]} caratteri)`,
    attivita: `Specificare le Attività (max ${this.MAX_LEN["attivita"]} caratteri)`,
    strategia: `Specificare le Strategia/e didattica/che (max ${this.MAX_LEN["strategia"]} caratteri)`,
    verifica: `Specificare la Modalità di verifica e/o valutazione (max ${this.MAX_LEN["verifica"]} caratteri
    )`,
  };

  validation = {
    examSubject: (value) => value > "",
    titolo: (value) => value !== "" && value.length < this.MAX_LEN["titolo"],
    contestualizzazione: (value) =>
      value !== "" && value.length < this.MAX_LEN["contestualizzazione"],
    destinatari: (value) =>
      value !== "" && value.length < this.MAX_LEN["destinatari"],
    tempi: (value) => value !== "" && value.length < this.MAX_LEN["tempi"],
    disciplina: (value) =>
      value !== "" && value.length < this.MAX_LEN["disciplina"],
    competenze: (value) =>
      value !== "" && value.length < this.MAX_LEN["competenze"],
    apprendimento: (value) =>
      value !== "" && value.length < this.MAX_LEN["apprendimento"],
    attivita: (value) =>
      value !== "" && value.length < this.MAX_LEN["attivita"],
    strategia: (value) =>
      value !== "" && value.length < this.MAX_LEN["strategia"],
    verifica: (value) =>
      value !== "" && value.length < this.MAX_LEN["verifica"],
  };

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  goBack() {
    window.history.back();
  }

  onCancel = () => {
    this.setState({
      showExamSiteList: false,
      validationSummary: "",
    });

    this.resetForm();
  };

  componentDidMount() {
    const { rCode } = this.props.match.params;
    this.loadRemote(rCode);
  }

  loadRemoteExam(rCode) {

    examService
      .getExamByRCode(rCode)
      .then((data) => {
        const { exam, examQuestion, examRevision } = data.data.payload;

        let defaultValues = {};
        examQuestion.map((item) => {
          defaultValues[item.field] = item.answer;
          examQuestion[item.field] = { ...item, value: item.answer };
        });

        // get answer of examQuestion with field examSubject
        let examSubjectLabel = "";
        const examSubject = examQuestion.filter(
          (item) => item.field === "examSubject"
        )[0].answer;

        if (examSubject) {

          // find the label from this.PAYLOADS.examSubjects
          const examSubjectLabelFiltered = this.PAYLOADS.examSubjects.filter(
            (item) => (item.value && item.value === examSubject)
          );
          
          console.log(examSubject, this.PAYLOADS.examSubjects);
          if (examSubjectLabelFiltered.length > 0) {
            examSubjectLabel = examSubjectLabelFiltered[0].label;
          }
        }

        this.setState({
          defaultValues,
          examQuestion,
          examSubjectLabel,
          examRevision,
          rCode,
          loading: false,
          loadingError: false,
          exam,
        });
      })
      .catch((errors) => {
        this.setState({ loading: false });
      })
  }

  /** */
  loadRemote(rCode) {

    this.setState({ loading: true, loadingError: false, rCode });
    if (this.PAYLOADS.examSubjects.length < 1) {
      // load paylods to fill the select options
      rc30ExamService
        .myExamSubjectUDA(rCode)
        .then(({ data }) => {
          const { examSubject } = data.payload;

          this.PAYLOADS.examSubjects.push({
            value: examSubject.id1,
            label: examSubject.title1,
          });
          this.PAYLOADS.examSubjects.push({
            value: examSubject.id2,
            label: examSubject.title2,
          });
          this.PAYLOADS.examSubjects.push({
            value: examSubject.id3,
            label: examSubject.title3,
          });

          this.setState({
            examSubjects: { options: this.PAYLOADS.examSubjects },
          });

          this.loadRemoteExam(rCode);
        })
        .catch((errors) => {
          console.log(errors);
          networkErrorHelper.notify(errors);
        });
    }
  }

  /** */
  onSubmit = () => {
    this.setState({ loading: true, loadingError: false });

    /** save remote */
    this.setState({ registrationCode: this.state.rCode });
    let payload = {
      exam: {},
      examQuestion: [],
    };

    let progress = 1;

    //--- prepare examQuestion data ---
    this.FIELDS_GROUP.map((group) => {
      if (group[0].field) {

        let data = {
          field: group[0].field,
          question: group[0].question,
          answer: this.state[group[0].field].value || "",
          progress: progress++,
        };

        if (typeof this.state.exam.id !== "undefined") {
          const examQuestionObj = this.state.examQuestion;
          const examQuestionArray = Object.keys(examQuestionObj).map(
            (key) => examQuestionObj[key]
          );
          data.id = examQuestionArray.filter(
            (item) => item.field === group[0].field
          )[0]?.id;
        }

        payload.examQuestion.push(data);
      }
    });

    //--- handle draft mode ---
    if (typeof this.state.exam.id === "undefined") {
      payload.exam = {
        registrationCode: this.state.rCode,
        status: "draft",
      };
      this.saveRemote(payload);

    } else {
      payload.exam = { ...this.state.exam };

      payload.examQuestion = payload.examQuestion.map((item) => {
        const existingItem = this.state.examQuestion.find(
          (eq) => eq.field === item.field
        );

        if (existingItem) {
          if (item.answer !== "") {
            existingItem.answer = item.answer;
          } else {
            item.answer = existingItem.answer;
          }
        }

        return item;
      });

      this.setState({
        loading: false,
        loadingError: false,
      });

      this.updateRemote(payload);
    }

    setTimeout(() => {
      this.loadRemoteExam(this.state.rCode);
    }, 1000);
  };

  /** */
  saveRemote(payload) {
    examService
      .examInsert(payload)
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
          showSuccess: true,
          exam: { ...data.data.payload.resExam },
          examQuestion: { ...data.data.payload.resExamQuestion },
        });

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 4000);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  updateRemote(payload) {
    examService
      .examUpdate(payload)
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
          showSuccess: true,
        });
        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 4000);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  confirmExam = (e, value) => {
    ModalHandler.show(
      value,
      "Conferma Elaborato",
      "Vuoi confermare l'invio dell'UdA? La conferma dell'UdA non potrà essere più modificata.",
      null,
      this.onConfirm
    );
  }; //deleteRecord

  onConfirm = (value) => {
    this.setState({
      loading: true,
      loadingError: false,
    });

    let isValidForm = this.checkValidation();
    if (!isValidForm) {
      this.setState({ loading: false });
      return true;
    }

    this.onSubmit();

    examService
      .confirmExamWritten({ ...this.state.exam, status: "confirmed" })
      .then((data) => {
        this.setState({
          loading: false,
          loadingError: false,
        });

        window.scrollTo({ top: 0, behavior: "smooth" });

        setTimeout(() => {
          this.setState({ showSuccess: false });
        }, 1000);

        setTimeout(() => {
          window.location.reload();
        }, 3000);


      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  };

  //---
  downloadPDF = () => {
    console.log("downloadPDF: idExam: " + this.state.exam.id);
    this.setState({ loading: true });

    examService
      .downloadPdfExamWritten(this.state.exam.registrationCode)
      .then((data) => {

        if (data.payload) {
          /*const requestResult = `data:application/pdf;base64,${this.state.exam.registrationCode}`;
          const filename = "uda_" + this.state.exam.registrationCode + ".pdf";

          let a = document.createElement("a");
          a.href = requestResult;
          a.download = filename;
          a.click();*/

          this.setState({ loading: false });
        }
      });
  };

  renderEdit() {
    const { defaultValues, showSuccess, exam, examRevision } = this.state;
    let isPending = false;

    if (exam && exam.status === "revisionPending") {
      // examSubject is readnly
      this.FIELDS_GROUP[1][0].readOnly = true;
      isPending = true;
    }

    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <div className="container">
          {showSuccess && (
            <div className="Container">
              <BoxSuccess className="mt-4">
                Elaborato salvato con successo!
              </BoxSuccess>
            </div>
          )}
          {isPending && (
            <MnemoCallout
              className="mnemo-callout-danger w-100">
              <h5 className="text-red">ATTENZIONE: UDA NON IDONEA</h5>
              <p>L'UDA non risulta idonea. La invitiamo a modificare l'UDA, nel rispetto delle motivazioni di seguito riportate, e premere il tasto <b>Conferma UDA<b></b></b></p>
              <p>  <i>{examRevision.revisionNote}</i></p>
            </MnemoCallout>
          )}
          <form>
            {code}

            <Row className="my-5">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                isFormActive={true}
                buttonText="Salva Modifiche"
              />

              {parseInt(this.state.exam.id) > 0 && (
                <Button
                  color="primary bg-dark"
                  onClick={() => {
                    this.confirmExam();
                  }}>
                  Consegna UDA
                </Button>
              )}
            </Row>
            <Row>
              <Col xs="12">
                <MnemoCallout title="Note per la compilazione">
                  <hr />
                  <ul>
                    <li>
                      Le modifiche correnti saranno salvate solo premendo il
                      tasto <b>Salva Modifiche</b>. Si possono apportare diverse
                      modifiche memorizzandole tramite il tasto{" "}
                      <b>Salva Modifiche</b> per memorizzare le modifiche
                      apportate.
                    </li>
                    <li>
                      Le modifiche salvate verranno memorizzate e sarà possibile
                      continuare la compilazione dell'UDA anche in un secondo
                      momento.
                    </li>
                    <li>
                      Premendo il tasto <b>Consegna UDA</b> l'elaborato verrà
                      inviato e non potrà più essere modificato. Potrà solo
                      essere consultato e scaricato fino al giorno dell'esame.
                    </li>
                    <li>
                      In caso di non idoneità dell'UDA riceverà un avviso con i
                      motivi specifici affinché possa, tempestivamente,
                      procedere ad apportare le modifiche necessarie per rendere
                      l'UDA idonea alla valutazione da parte della Commissione.
                    </li>
                    <li>
                      In caso di mancata consegna, entro i termini previsti
                      tramite il tasto <b>Consegna UDA</b> il sistema acquisirà
                      autonomamente e senza ulteriore avviso il lavoro svolto
                      fino a quel momento.
                    </li>
                  </ul>
                </MnemoCallout>
              </Col>
              /
            </Row>
          </form>
        </div>
      </>
    );
  }

  renderRead() {
    const { examQuestion } = this.state;

    return (
      <>
        <BoxSuccess className="mt-4">
          Elaborato consegnato. Non è possibile modificare i dati.
          {/*<Button
            onClick={this.downloadPDF}
            color="primary bg-dark"
            className="mx-5">
            Scarica PDF
          </Button>*/}
        </BoxSuccess>
        {this.FIELDS_GROUP.map((rowFields, i) => {
          return rowFields.map((field, j) => {
            if (field.for) {
              const question = examQuestion.filter(
                (item) => item.field === field.for
              )[0];

              return (
                <Row key={j}>
                  <MnemoCallout
                    className="mnemo-callout-info w-100"
                    title={field.label}>
                    <p>
                      <i>{field.infoText}</i>
                    </p>
                    <hr />
                    <p>
                      {question && (
                        <>
                          {question.field === "examSubject" ? (
                            <>{this.state.examSubjectLabel}</>
                          ) : (
                            <>{question.answer}</>
                          )}
                        </>
                      )}
                    </p>
                  </MnemoCallout>
                </Row>
              );
            }
          });
        })}
      </>
    );
  }

  render() {
    const { loading, loadingError, rCode } = this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;

    if (loadingError) {
      return (
        <BoxDanger className="mt-4">
          Si è verificato un errore durante il caricamento della pagina,
          <br />
          <SupportoTecnico />
        </BoxDanger>
      );
    }

    return (
      <>
        <NavLink
          to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}
          className="mx-5 btn btn-outline-primary float-right">
          <Icon icon="it-presentation" />TORNA ALLA HOME PAGE DEL CORSO
        </NavLink>
        <Title>Prova scritta</Title>

        <hr />
        {(this.state.exam.status === "confirmed" || this.state.exam.status === "revisioned")
          ? <>{this.renderRead()}</> : <>{this.renderEdit()}</>}
      </>
    );
  }
}

export default withRouter(RC30UDAExamWritten);
