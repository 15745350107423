import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import {
    Card,
    CardBody, CardTitle,
    CardText, Row, Col, Icon
} from 'design-react-kit';

import { NavLink } from 'react-router-dom';

export class MnemoCardSlim extends Component {

    onClick = () => {
       
        if (this.props.actionClick) {
            this.props.actionClick(this.props.actionParam);
        }
    }

    render() {

        const { title, content,
            actionLink = 0, actionClick = 0,
            actionParam } = this.props;

        let showLink = false;
        let showClick = false;
        let link = '';

        if (actionLink) {
            link = actionLink
            showLink = true;
            if (actionParam) {
                link += `/${actionParam}`;
            }
        }


        if (actionClick){
            showClick=true;
        }

        const code = <>
            <article className="card-wrapper card-space">
                <Card noWrapper className="card-bg rounded shadow">
                    <CardBody>
                        <Row>
                            <Col xs={11} >
                                {title && (<CardTitle tag="h5">{ReactHtmlParser(title)}</CardTitle>)}
                                {(content && content.title) ? (<CardText>{ReactHtmlParser(content.title)}</CardText>) : null}
                                {(content && content.showBody) ? (<CardText>{ReactHtmlParser(content.body)}</CardText>) : null}
                            </Col>
                            {(actionLink || showLink) && 
                            (<Col xs={1} >
                                <Icon icon='it-arrow-right-circle'/>
                            </Col>)}
                        </Row>
                    </CardBody>
                </Card>
            </article>
        </>;

        
        return (
            <>
                {showLink && (<> <NavLink className="wrapper-link" to={link}>{code}</NavLink></>)}
                {showClick && (<> <div className="wrapper-link" onClick={event => { this.onClick() }}>{code}</div></>)}
                {!(showLink || showClick) ? (<>{code}</>) : ''}
            </>
        );
    }
}