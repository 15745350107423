import React, { Component } from 'react';
import { MRadioButton } from 'components/forms';

export class FormENG extends Component {

    VALUES = ['SC', 'SE', 'CE'];

    // TODO: rendere il caricamento dei corsi: codici, titolo, prefix e prezzi dal DB
    PAYLOAD =
        {
            options: [
                { label: 'Al solo Corso on-line con assistenza Didattica + Assistenza Tecnica', value: 'SC' },
                { label: 'All\'Esame finale: Esame scritto "Listening, Reading, Use of English e Writing" + Esame orale "Speaking" nelle proprie sedi e con personale Mnemosine', value: 'SE' },
                { label: 'Corso on-line con assistenza Didattica + Assistenza Tecnica + Esame Scritto "Listening, Reading, Use of English e Writing" + Esame Orale "Speaking" nelle proprie sedi e con personale Mnemosine', value: 'CE' }
            ]
        }

    state = {
        validationError: ''
    }

    getValue() {

        const { baseCode, value } = this.extractCode()

        const pluginValue = {
            courseCode: baseCode + value
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        const { value } = this.extractCode();
        const isValid = (value.length > 0);
        if (!isValid) {
            validationError += 'Dovresti specificare il corso';
        }

        this.setState({ validationError })

        return (isValid);
    }

    onChange = (id, value) => {

        const { baseCode } = this.extractCode()
        const courseCode = baseCode + value;

        if (this.props.onChangeCourseCode)
            this.props.onChangeCourseCode(courseCode);
    }

    extractCode() {

        const { courseCode } = this.props;
        let baseCode = '';

        let value = courseCode.slice(-2)
        if (this.VALUES.indexOf(value) >= 0) {
            baseCode = courseCode.substring(0, 5);
        } else {
            value = '';
            baseCode = courseCode;
        }

        return { baseCode, value };
    }

    render() {

        const { value } = this.extractCode();

        return (
            <>
                <h6>Selezionare il corso desiderato</h6>
                <hr />
                <MRadioButton onChange={this.onChange} value={value} id="condition" payload={this.PAYLOAD}></MRadioButton>
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{this.state.validationError}</span></>)}
                <hr />
            </>
        );
    }
}