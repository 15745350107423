import { LOGGED_IN, LOGGED_OUT, LOGGED_FAILED, LOADING_STARTED, LOADING_FINISHED, SETUP_MAINTENANCE_MODE } from '../actions/app';

const initialState = {
    user: null,
    token: null,
    isLoading: true,
    maintenanceMode: {
        active: false,
        message: null
    },
    error: null
};

// Reducer: it updates the central store
export default (state = initialState, action = {}) => {
    switch (action.type) {
        case LOADING_STARTED: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case LOADING_FINISHED: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case SETUP_MAINTENANCE_MODE: {
            const { isMaintenance, message } = action.data;
            return {
                ...state,
                maintenanceMode: {
                    active: isMaintenance,
                    message
                }

            };
        }
        case LOGGED_IN: {
            return {
                ...state,
                ...action.data
            };
        }
        case LOGGED_OUT:
            return {
                ...state,
                user: null,
                token: null
            };
        case LOGGED_FAILED:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
};