import React, { Component } from 'react';
import { blogService, dateHelper } from 'libs';
import { ROUTES } from 'const';
import { Row, Col } from 'design-react-kit';

import './BlogHomeBox.scss';
import { CardNews, CardNewsCategory } from './';

export class BlogHomeBox extends Component {

    state = {
        dimPage: 3,
        articles: [],
        loading: true,
        loadingError: false
    }

    componentDidMount() {
        this.loadArticles();
    }

    loadArticles() {

        blogService.lastArticles(1, this.state.dimPage)
            .then(({ data }) => {

                const { articles } = data.payload;
                this.setState({
                    articles,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, articles } = this.state;

        if (loading || loadingError || articles.length === 0) return (<></>);

        return (

            <div className="blogHomeBox__wrapper">
                <div className="blogHomeBox__title">
                    <h2 >Mnemo News</h2>
                </div>
                <Row className="blogHomeBox__row">
                    {articles.map((article, i) => {
                        return (
                            <Col key={`article-${i}`} sm={12} md={6} lg={3}>
                                <CardNews
                                    image={article.image ? article.image : '/img/blog/corsimnemosine-notizie.jpg'}
                                    date={dateHelper.toITDate(article.date)}
                                    text={article.title}
                                    categoryName={article.categoryName}
                                    categorySlug={article.categorySlug}
                                    link={`${ROUTES.BLOG_ARTICLE}/${article.slug}`}
                                />
                            </Col>
                        )
                    })}
                    <Col sm={12} md={6} lg={3}>                        
                        <CardNewsCategory  />
                    </Col>
                </Row>

            </div>
        )
    }
}