import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MTextArea,
  MInput,
  MCE,
  MSelect,
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import {
  BoxSuccess,
  ErrorNotification,
  ModalHandler,
} from "components/misc";

import { Card, CardBody, Row, Col } from "design-react-kit";

import { PRESET_METADATA_QUIZ } from "../../../const";

const FIELDS_GROUP = [
  [
    {
      id: "metadata",
      field: "metadata",
      label: "Metadata - Configurazione Quiz/Test",
      component: MTextArea,
      rows: 6,
      className: "col-md-12 mb-5",
    },
  ],
  [
    {
      id: "maxGrade",
      field: "maxGrade",
      label: "Punteggio Massimo",
      component: MInput,
      type: "number",
      value: 100,
      className: "col-md-6",
    },
    {
      id: "minRate",
      field: "minRate",
      label: "Punteggio Minimo",
      component: MInput,
      type: "number",
      value: 60,
      className: "col-md-6",
    },
  ],
  [
    {
      field: "description",
      label: "Descrizione",
      component: MCE,
      className: "col-md-12",
      infoText: "Specificare il testo",
    },
  ],
];

export class AdminCourseFormQuizMetadata extends ValidatedForm {
  ERROR_MESSAGES = {
    metadata: "La configurazione presenta degli errori.",
    maxGrade: "Necessario indicare un Punteggio Massimo valido.",
    minRate: "Necessario indicare un Punteggio Minimo valido.",
  };

  emptyFields = {
    metadata: "",
    maxGrade: 100,
    minRate: 60,
    description: "",
  };

  validation = {
    /*metadata: value => {
            return JSON.parse(value) ? true : false
        }*/
    metadata: (value) => value.length > 3,
    maxGrade: (value) => /^[0-9.]+$/i.test(value),
    minRate: (value) => /^[0-9.]+$/i.test(value),
  };

  //--- ---
  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formValid: false,
      formActive: true,
      idResource: this.props.idResource,
      showSuccess: false,
      popolateAA: [],
      popolateRepository: [],
      searchFilter: {
        searchFilter: {
          aa: process.env.REACT_APP_AA_YEAR,
          repoID: 0,
        },
      },
      quizAA: 0,
      rndKey: 0,
      isLoading: true,
      addToDepositSuccess: null,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  PAYLOADS = { aa: [] };

  loadRemote(additionalState = {}) {
    adminService
      .quizByIDParent(this.state.idResource)
      .then((data) => {
        if (!data.data.payload.quiz.aa) {
          return false;
        }
        data.data.payload.quiz.metadata = JSON.stringify(
          data.data.payload.quiz.metadata,
          undefined,
          4
        );

        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.data.payload.quiz
        );

        const newState = {
          defaultValues,
          ...additionalState,
          quizAA: data.data.payload.quiz.aa,
          isLoading: false,
        };

        this.setState(newState);
      })
      .catch((error) => {
        //networkErrorHelper.notify(error);
        console.error(error);
      });
  } //loadRemote

  saveRemote = () => {
    const payload = payloadBuilder(this.state);
    let jpMetaData = "";

    try {
      jpMetaData = JSON.parse(payload.metadata);
    } catch (e) {
      //networkErrorHelper.notify();
      const title = "Errore durante l'elaborazione della richiesta";
      const message = "Configurazione metaData errata.";
      ErrorNotification.render(message, title);
      return false;
    }

    payload.metadata = JSON.stringify(jpMetaData);
    delete payload.createdAt;
    delete payload.updatedAt;
    delete payload.questions;

    if (!payload.hasOwnProperty("id")) {
      payload.idParent = parseInt(this.state.idResource);

      adminService
        .insertQuiz(payload)
        .then((result) => {
          this.setState({
            formActive: !this.state.formActive,
            showSuccess: true,
          });

          //set current idQuiz
          payload.id = result.data.payload.quiz.id;

          setTimeout(() => {
            this.setState({
              showSuccess: false,
            });
          }, 6000);
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      adminService
        .updateQuiz(payload)
        .then((result) => {
          this.setState({
            formActive: !this.state.formActive,
            showSuccess: true,
          });

          setTimeout(() => {
            this.setState({
              showSuccess: false,
            });
          }, 6000);
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  };

  setMetadataPreset = (preset) => {
    this.setState({
      ...this.emptyFields,
    });
    this.setState({
      formValid: true,
      formActive: true,
      defaultValues: {
        ...this.state.defaultValues,
        metadata: preset,
      },
    });
  }; //setMetadataPreset

  loadRemoteRepository = (filter) => {
    adminService
      .loadAllRepoQuiz(filter)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        let items = [];
        Object.entries(data.payload.repoQuiz).forEach(([key, val]) => {
          items.push(val);
        });

        const repository = items.map((item) => ({
          label: item.title,
          value: item.id,
        }));
        items = [];
        items.push(...repository);
        items.unshift({ label: `Seleziona`, value: -1 });
        //--- end: prepare reactTable ---

        //--- construct option array ---
        const aa = data.payload.distinctAA.map(({ aa }) => ({
          label: aa,
          value: aa,
        }));
        this.PAYLOADS.aa = [];
        this.PAYLOADS.aa.push(...aa);
        this.PAYLOADS.aa.unshift({ label: `Seleziona`, value: -1 });
        //--- construct option array ---

        this.setState({
          loading: false,
          payload: items,
          popolateAA: { options: this.PAYLOADS.aa },
          popolateRepository: { options: items },
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });

        this.copyToRepo();
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }; //loadRemote

  filterByAA = (_, aa) => {
    this.setState({
      aa: aa,
      searchFilter: {
        searchFilter: { ...this.state.searchFilter.searchFilter, aa: aa },
      },
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });

    setTimeout(() => {
      this.loadRemoteRepository(this.state.searchFilter);
    }, 250);
  }; //filterByAA

  storeRepoValue = (_, repoID) => {
    this.setState({
      searchFilter: {
        searchFilter: {
          ...this.state.searchFilter.searchFilter,
          repoID: parseInt(repoID),
        },
      },
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });

    setTimeout(() => {
      this.loadRemoteRepository(this.state.searchFilter);
    }, 250);
  }; //filterByAA

  saveToRepository = () => {
    this.setState({
      isLoading: true,
    });

    const payload = {
      quizAA: this.state.quizAA,
      idResource: this.state.idResource,
      ...this.state.searchFilter.searchFilter,
    };

    adminService.cloneToRepository(payload).then(({ data }) => {
      this.setState({
        isLoading: false,
      });

      if (data.payload.repoQuiz === true) {
        this.setState({ addToDepositSuccess: true });
      } else {
        this.setState({ addToDepositSuccess: false });
      }

      setTimeout(() => {
        this.setState({
          addToDepositSuccess: null,
        });
      }, 5000);
    });
  };

  copyToRepo = () => {
    const code = (
      <>
        <p className="m-0 p-0 mb-5">
          Questa procedura clona tutti i dati di questo Test nel deposito delle
          domande che sarà selezionato.
        </p>
        <Row className="row col-md-12">
          <Col>
            <MSelect
              id="aa"
              label="Anno Accademico"
              payload={this.state.popolateAA}
              onChange={this.filterByAA}
            />
          </Col>
          <Col>
            <MSelect
              id="repoID"
              label="Depositi delle Domande"
              payload={this.state.popolateRepository}
              onChange={this.storeRepoValue}
            />
          </Col>
        </Row>
      </>
    );
    ModalHandler.show(
      { data: {} },
      "AVVIA COPIA DEI DATI",
      code,
      null,
      this.saveToRepository
    );
  }; //copyToRepo

  //--- ---

  render() {
    let { formActive, defaultValues, isLoading } = this.state;
    let { formValid, code } = this.renderFields(
      FIELDS_GROUP,
      defaultValues,
      formActive
    );

    if (this.state.formValid) {
      formValid = true;
    }

    /*if (isLoading) {
      return <MnemoLoading />;
    }*/

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <CardBody tag="div">
            {this.state.showSuccess && (
              <BoxSuccess>Configurazione salvata</BoxSuccess>
            )}

            <form className="user" id="quizMetadataForm">
              <Row key="mainRow">
                <Col sm={12} md={9} lg={9}>
                  {code}
                </Col>
                <Col sm={12} md={3} lg={3}>
                  <div>Preset Metadata:</div>
                  <ul className="list-preset-Metdata">
                    {PRESET_METADATA_QUIZ.map((item, idx) => {
                      return (
                        <li
                          key={`preset-${idx}`}
                          onClick={() => this.setMetadataPreset(item.preset)}>
                          {item.label}
                        </li>
                      );
                    })}
                  </ul>

                  <button
                    type="button"
                    className={`btn mt-4 ${
                      this.state.addToDepositSuccess === true ? `btn-success` : ''
                    } ${
                      this.state.addToDepositSuccess === false ? `btn-danger` : ''
                    } ${
                      this.state.addToDepositSuccess === null ? `btn-dark` : ''
                    }`}
                    onClick={(e) =>
                      this.loadRemoteRepository(this.state.searchFilter)
                    }>
                    {this.state.addToDepositSuccess === null ?
                      "Aggiungi a Repository" : ''}
                    {this.state.addToDepositSuccess === true ?
                      "Aggiunto a Repository" : ''}
                    {this.state.addToDepositSuccess === false ? "Errore Repository" : ''}
                  </button>
                </Col>
              </Row>
            </form>

            <div className="mt-4">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onToggle={this.toggleForm}
                isFormActive={formActive}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
