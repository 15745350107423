import React from "react";

import { FormEnabler, ValidatedForm, MInput, MSelect, MToggle } from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import { Card, CardBody } from "design-react-kit";

export class AdminLessonLiveClassForm extends ValidatedForm {
  ERROR_MESSAGES = {
    label: "Nome obbligatorio",
    aa: "Anno Accademico obbligatorio (AAAA)",
    stage: "Fase obbligatoria",
  };

  emptyFields = {
    label: "",
    aa: "",
    stage: 0,
    progress: 0,
    bookibngOpen: false,
  };

  validation = {
    label: (value) => value.length > 0,
    aa: (value) => /^\d{4}$/i.test(value) && value !== null,
    stage: (value) => parseInt(value) > 0,
  };

  arrIdExamSession = [{ label: "Seleziona Sessione Esame", value: "-1" }];

  FIELDS_GROUP = [
    [
      {
        id: "bookingOpen",
        name: "bookingOpen",
        field: "bookingOpen",
        label: "Abilita prenotazioni aula",
        component: MToggle,
        className: "col-md-4",
      },
    ],
    [
      {
        id: "label",
        field: "label",
        label: "Nome",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "idExamSession",
        field: "idExamSession",
        label: "ID SEssione Esame",
        payload: { options: this.arrIdExamSession },
        component: MSelect,
        className: "col-md-6",
      },
      {
        id: "aa",
        field: "aa",
        label: "Anno Accademico",
        component: MInput,
        type: "text",
        className: "col-md-2",
      },
      {
        id: "stage",
        field: "stage",
        label: "Fase",
        component: MInput,
        type: "text",
        className: "col-md-2",
      },
      {
        id: "progress",
        field: "progress",
        label: "Ordine di Visualizzazione",
        component: MInput,
        type: "text",
        className: "col-md-2",
      },
    ],
  ];

  //--- ---

  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      idLessonLiveClass: this.props.idLessonLiveClass,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadRemote();
    this.loadRemoteOption();
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idLessonLiveClass) <= 0) {
      return false;
    }

    let filter = {
      searchFilter: {
        id: parseInt(this.props.idLessonLiveClass),
      },
    };

    adminService
      .loadLessonLiveClass(filter)
      .then(({ data }) => {
        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.lessonLiveClass
        );

        const newState = {
          defaultValues,
          ...additionalState,
          loading: false,
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

  loadRemoteOption() {
    if (this.arrIdExamSession.length === 1) {
      adminService
        .loadLessonLiveEditionExamSession()
        .then(({ data }) => {
          Object.entries(data.payload.lessonLiveEditionExamSession).forEach(
            ([key, val]) => {
              this.arrIdExamSession.push({
                label: val.title,
                value: val.idExamSession,
              });
            }
          );

          this.setState({
            loading: false,
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  } //loadremoteOption

  onCancel = () => {
    this.resetForm();
  }; //onCancel

  onBackPage = () => {
    window.history.back();
  };

  saveRemote = () => {
    const payload = payloadBuilder(this.state);

    if (parseInt(this.props.idLessonLiveClass) > 0) {
      adminService
        .updateLessonLiveClass(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      adminService
        .insertLessonLiveClass(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  };

  render() {
    let { formActive, defaultValues, idLessonLiveClass } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <form className="user" id="promotionalform">
              {code}
            </form>

            <div className="mt-4">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={
                  parseInt(idLessonLiveClass) > 0
                    ? this.onCancel
                    : this.onBackPage
                }
                onToggle={this.toggleForm}
                isFormActive={formActive}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
