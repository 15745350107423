import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class Title extends Component {

    render() {

        const { subtitle } = this.props;

        return (
            <>
                <div className="my-5">
                    <h1>
                        {this.props.children}
                    </h1>
                    {subtitle && (
                        <>
                            <hr />
                            <h6>{ReactHtmlParser(subtitle)}</h6>
                        </>
                    )}
                </div>
            </>
        );
    }
}