let d = new Date();
let day = d.getDate();
let month = d.getMonth() + 1;
let year = d.getFullYear();

export function getTodayDate() {
  return new Date(year, month, day);
}

export function getNextYearDate() {
  return new Date(year + 1, month, day);
}

export function getTomorrow() {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  return new Date(tomorrow);
}
