import React, { Component } from "react";


export class ReCaptchaV3 extends Component {
        
    componentDidMount(){ 
        this.initReCaptcha();
    }

    /*TODO: ReCaptcha è la versione 2 e non  la 3 */
    
    initReCaptcha = () =>{
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=load&onload=onRecaptchaLoadCallback&hl=it";
        script.async = true;
        document.body.appendChild(script);

        const onRecaptchaLoadCallback = () => {
            window.grecaptcha.render(this.props.id, {
                sitekey:'6Lep51oUAAAAAJyF_4jc9VqpbbgMNSdr0xLGZKxb',
                badge: 'inline',
                theme : 'dark',
                callback : this.verifyCallback
            });
        }
        
        window.onRecaptchaLoadCallback = onRecaptchaLoadCallback;
    }//initReCaptcha
    
    verifyCallback = (result) =>{
        this.props.reCaptchaCallback(result);
    }//verifyCallback
    

    //--- ---

    render() {

        return (
            <>
                <div id={this.props.id}></div>
            </>
        );
    }

}