import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
    Card,
    CardBody, CardTitle,
    CardText, 
    CardSignature,
    CardFooter, CardCategory
} from 'design-react-kit';
import { Link } from 'react-router-dom';

export class MnemoCard extends Component {

    openLink(link) {

    }

    render() {

        const { title, content,
             date, signature, 
            actionLabel, actionLink, 
            actionParam, category } = this.props;

        let to = actionLink;
        if (actionParam){
            to += `/${actionParam}` ;
        }

        return (
            <>
                <article className="card-wrapper card-space">
                    <Card noWrapper className="card-bg rounded shadow">
                        <CardBody>
                            <CardCategory date={date}>{category}</CardCategory>
                            {title && (<CardTitle tag="h5"> {ReactHtmlParser(title)}</CardTitle>)}
                            <CardText>{content}</CardText>
                            {signature && (<CardSignature>{signature}</CardSignature>)}
                            <CardFooter >                               
                                    <Link className="float-right wrapper-link btn btn-primary bg-dark" to={to} >
                                        {actionLabel}
                                    </Link>
                                
                            </CardFooter>
                        </CardBody>
                    </Card>
                </article>
            </>
        );
    }
}