import React, { Component } from "react";
import { Title, BoxDanger } from "components/misc";
import ReactHtmlParser from 'react-html-parser';

import {
    Container
} from 'design-react-kit';
import { MSection } from "components/misc/MSection";

export class Maintenance extends Component {

    render() {

        const { message } = this.props;

        return (
            <>
                <Container>                    
                    <Title>Sito in Manutenzione </Title>
                    <MSection>
                        <BoxDanger>{ReactHtmlParser(message)}</BoxDanger>
                    </MSection>
                </Container>
            </>);
    }
}