import { proxyBE } from "./common";

export const libraryService = {
  book(slug) {
    return proxyBE.get(`/library/book/${slug}`);
  },

  books() {
    return proxyBE.get("/library/books");
  },

  featuredBooks() {
    return proxyBE.get("/library/books-featured");
  },

  category(slug) {
    return proxyBE.get(`/library/category/${slug}`);
  },

  categories() {
    return proxyBE.get("/library/categories");
  },

  author(slug) {
    return proxyBE.get(`/library/author/${slug}`);
  },

  authors() {
    return proxyBE.get("/library/authors");
  },

  wishlist() {
    return proxyBE.get("/library/wishlist");
  },

  toggleFavorities(payload) {
    return proxyBE.post(`/library/wishlist`, payload);
  },

  example(slug) {
    return proxyBE.get(`blog/article/${slug}`);
  },
};
