import React, { Component } from "react";
import { ROUTES } from "const";
import { withRouter, NavLink } from "react-router-dom";
import { Button, Icon } from "design-react-kit";
import {
    BoxDanger,
    BoxSuccess,
    MnemoCallout,
    MnemoLoading,
    SupportoTecnico,
    Title
} from "components/misc";
import { certificationService, networkErrorHelper } from "libs";

class CourseUserSelfDeclaration extends Component {

    state = {
        loading: true,
        loadingError: false,
        rCode: '',
        request: false,
        requestError: '',
        requestResult: '',
        courseCode: '',
        userSelfDeclaration: null,
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;

        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        this.setState({ rCode: rCode, loading: false });
    }



    generate = () => {
        this.setState({ loading: true });
        const { rCode } = this.state;
        certificationService.generateSelfDeclaration({ registrationCode: rCode })
            .then(({ data }) => {
                const { userSelfDeclaration } = data.payload;
                this.setState({
                    userSelfDeclaration,
                    loading: false,
                });
                window.open(userSelfDeclaration.location, '_blank');
            })
            .catch(error => {
                this.setState({ loading: false });
                networkErrorHelper.notify(error);
            });
    }

    render() {

        const { loading, loadingError, rCode, request, requestError } = this.state;

        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (<>
            {(loading) && (<MnemoLoading />)}
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Autocertificazione d'iscrizione</Title>
            <MnemoCallout >
                <h5>Nota importante sui certificati</h5>
                <hr />
                A seguito della legge n. 183/2011 i certificati possono essere presentati solo a enti privati, e non possono essere richiesti da pubbliche amministrazioni o enti privati gestori di pubblici servizi.
                Pertanto, qualora si renda necessario dover attestare atti o fatti relativi alla propria carriera universitaria a un ente pubblico o a privati gestori di pubblici servizi, lo studente è tenuto a presentare un'autocertificazione, che sostituisce il certificato a tutti gli effetti di legge.
                <hr />
                I certificati restano validi e utilizzabili nei rapporti con i privati in Italia. La firma autografa è omessa ai sensi dell'art. 3 co. 2 del Decreto Legislativo 12 febbraio 1993 n. 39.
                I certificati uso estero, in italiano e in inglese, devono invece essere stampati dalla segreteria studenti e firmati in originale da appositi funzionari con firma depositata in Prefettura.
            </MnemoCallout>
            {(!request) ? (<>
                <Button color="primary" className="m-5 bg-dark" onClick={this.generate}>Scarica autodichiarazione d'iscrizione</Button>
            </>) : (<>
                {(requestError) ? (<>
                    <BoxDanger>{requestError}</BoxDanger>
                </>) : (<>
                    <BoxSuccess>File correttamente generato e scaricato, controlla nei tuoi download</BoxSuccess>
                </>)}
            </>)}
        </>)
    }
}

export default withRouter(CourseUserSelfDeclaration);