import React, { Component } from 'react';
import { Title } from 'components/misc';
import { withRouter } from 'react-router-dom';
import { Container } from 'design-react-kit';
import { AdminSiteconfigForm } from '../../../components/forms/admin';



export class SiteconfigDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idSiteConfig: parseInt(this.props.match.params.idSiteConfig) > 0 ? this.props.match.params.idSiteConfig : 0
        };
    }

    //--- ---

    render() {

        const { idSiteConfig } = this.state
        
        return (
            <>
            <section className="my-4">
                <Container>
                    <Title>{(idSiteConfig > 0) ? 'Aggiorna Configurazione' : 'Crea Configurazione'}</Title>
                    <AdminSiteconfigForm idSiteConfig={idSiteConfig}/>
                </Container>
            </section>
            </>
        );
    }

}

export default withRouter(SiteconfigDetail);