import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';

export class PageDetails24cfu extends Component {

    render() {
        const title = '24 CFU CORSI PER L\'INSEGNAMENTO';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <hr />
                <MSection>
                    <Row>
                        <Col>
                            <p>Il MIUR stabilisce che NON sono validi tutti i 24 CFU conseguiti nelle Università ed erogati in modalità TELEMATICA.
                                L’Università Dante Alighieri che eroga i 24 CFU proposti in questa pagina, NON è Università Telematica quindi sono validi i 24 CFU proposti!</p>
                            <hr />
                            <p>Per conseguire i "24 CFU" si potrà partecipare ai "Corsi 24 CFU" o al "Master 24 CFU".</p>

                            <p>Qui di seguito la proposta di maturazione del predetto requisito, attraverso la scelta di tre dei quattro
                                corsi Universitari erogati dall’Università NON TELEMATICA “Dante Alighieri” di Reggio di Calabria L.R. con DM n. 504 del 17/10/2007.</p>
                            <p><a href="/24-cfu-accesso">Normativa di Riferimento</a></p>
                            <hr />
                            <h6>Corsi UNIVERSITARI proposti</h6><hr />
                            <ul>
                                <li>Ambito B: <u>Psicologia - </u>Corso: <strong>Psicologia dell’apprendimento</strong> (9 CFU) -&nbsp; M-PSI/04</li>
                                <li>Ambito D: <u>Metodologie e tecnologie didattiche - </u>Corso: <strong>Teoria e metodi di progettazione e valutazione didattica</strong> (9 CFU) – M-PED/04</li>
                                <li>Ambito C: <u>Antropologia - </u>Corso: <strong>Antropologia culturale </strong>(9 CFU) - M-DEA/01</li>
                                <li>Ambito A: <u>pedagogia, pedagogia speciale e didattica dell’inclusione - </u>Corso: <strong>Didattica speciale</strong> (9 CFU) - M-PED/03.</li>
                            </ul>
                            <p>A conclusione dei&nbsp; suddetti percorsi didattici, ai corsisti che avranno adempiuto agli obblighi previsti, ed avranno superato la prova finale sarà rilasciata, se richiesta, una Specifica certificazione secondo il comma 5 dell’art. 3 del D.M. n. 616 del 10 Agosto 2017 nella quale saranno riportati:</p>
                            <ol>
                                <li>Denominazione di ogni singolo insegnamento;</li>
                                <li>Le votazioni riportate agli esami ed i Crediti Formativi Universitari acquisiti;</li>
                                <li>I settori scientifico disciplinari;</li>
                                <li>Gli obiettivi formativi previsti dal D.M. n. 616 del 10 Agosto 2017.</li>
                            </ol>
                            <hr />
                            <p><a href="/corsi-mnemosine/24CFU">Clicca qui per i dettagli</a></p>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}