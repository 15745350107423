import React from "react";

import {
    MFile,
    MLabel,
    MRadioButton,
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentAdminService, networkErrorHelper
} from "libs";

export class EnrollmentFillFormRCCFU30ALL2 extends ValidatedForm {

    emptyFields = {
        document: 0,
        titleAccess: '',
        examSite: ''
    };

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    PAYLOADS = {
        examSites: [],     
    }

    COMPETITION_TITLEACCESS=[
        { value: "servizio", label: "di aver svolto servizio presso le istituzioni scolastiche statali o presso le scuole paritarie per almeno tre anni, anche non continuativi, di cui almeno uno nella specifica classe di concorso per la quale scelgo di conseguire l’abilitazione nei cinque anni precedenti, valutati ai sensi dell'art. 11, comma 14, della Legge 3 maggio 1999, n. 124;" },
        { value: "prova", label: "di aver sostenuto la prova concorsuale relativa alla procedura straordinaria di cui all’art. 59, comma 9-bis del Decreto Legge 25 maggio 2021, n. 73, convertito, con modificazioni, dalla Legge 23 luglio 2021, n. 106, fermo restando il possesso del titolo di studio necessario con riferimento alla classe di concorso." },
    ]


   RC30CFUALL2
    FIELDS_GROUP = [
        [
            {
                field: "labelTitleAccess",
                label: "Dichiaro (selezionare una sola tra le opzioni di seguito):",                
                component: MLabel,
                className: "col-md-12 pt-3"
            },
        ],
        [
            {
                id: "titleAccess",
                name: "titleAccess",
                field: "titleAccess",
                payload: { options: this.COMPETITION_TITLEACCESS },
                component: MRadioButton,
                type: "radio",            
                label: "",              
            }
        ],
        [
            {
                field: "preferExamPresence",
                label: "Sede esami",
                infoText: "Seleziona una città per l'esame in presenza",
                component: MLabel,
                className: "col-md-6 pt-3"
            },
        ], [{
            field: "examSite",
            payload: { options: this.PAYLOADS.examSites },
            component: MSelect,
            className: "col-md-6"
        } ],
        [ {
            field: "preferDocument",
            label: "Modulo A",
            infoText: "Se non l'hai ancora fatto scaricare il seguente <a href=\"/assets/doc/ModelloA_30CFU.pdf\" target=\"_blank\">Modulo A</a>, compilare e allegare",
            component: MLabel,
            className: "col-md-12 pt-3"
        }], [

            {
                id: "document",
                field: "document",
                label: "Allegare il Modulo A",
                infoText: "Caricare il Modulo opportunamente compilato e firmato",
                component: MFile,
                config: { endPoint: '/enrollment/attachment-file', mandatory: true, fieldName: 'document', maxFiles: 4, accept: '.pdf,.jpg,.bmp,.jpeg' },
                className: "col-md-12"
            }]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede esami',
        titleAccess: 'Selezionare il titolo di studio'
    };

    validation = {
        examSite: value => value > '',
        titleAccess: value => value > '',
    };

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        const { idExamSession } = this.props.enrollData;
        if (this.PAYLOADS.examSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(idExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            { document: this.props.enrollData.idEnrollment },
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {
        return false;
    };

    onSubmit = () => {
        //TODO: verificare se ha uploadato il file
        this.payload = payloadBuilder(this.state);

        this.payload.pluginConfig = this.props.enrollData.pluginConfig;
        this.payload.pluginConfig.titleAccess= this.state.titleAccess.value;

        const isValid = this.checkValidation();
        return isValid;
    }

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);
     
        return (<>           
            {code}
        </>);
    }
}