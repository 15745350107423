import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const ticketService = {

    myTickets(rCode) {        
        const payload = mnemoService.buildPayload('list', 'rCode', rCode);
        return proxyApiMnemo.get('ticket/?payload='+encodeURI(payload));
    },
    insertTicket(data){
        const payload = mnemoService.buildPayloadObject('', data);
        return proxyApiMnemo.post('ticket', payload);
    },
    detailTicket(id, rCode){
        const payload = mnemoService.buildPayloadObject('listByTicket', {'idTicket':id, "registrationCode":rCode});
        return proxyApiMnemo.get('ticketMessage/?payload='+encodeURI(payload));
    }
}