import React, { Component } from "react";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

export class UserDetailAccount extends Component {


    render() {

        if (!this.props.data) return false;
        const { name, surname, email, enabled, confirmed, codeConfirmed, registrant, codeCreated, readOnly } = this.props.data;
        
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Account</span>
                    </div>
                    <CardBody><div><b>Nome:</b> {name}</div>
                        <div><b>Cognome:</b> {surname}</div>
                        <div><b>Email:</b> {email}</div>
                        <div><b>Stato:</b> {(enabled) ? 'abilitato' : 'disabilitato'}</div>
                        <div><b>Modifica:</b> {(readOnly) ? 'disabilitata' : 'abilitata'}</div>
                        <div><b>Account confermato:</b> {(confirmed) ? 'confermato' : 'da confermare'}</div>
                        <div><b>Codice SMS generato:</b> {codeCreated}</div>
                        <div><b>Codice SMS confermato:</b> {codeConfirmed}</div>
                        <div><b>Tipo registrazione:</b> {registrant}</div>
                    </CardBody>
                </Card>
            </>
        );
    }

}

