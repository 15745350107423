import { Title, MSection } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';


class CourseTutorMNE extends Component {

    state = {
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    render() {
        const { rCode } = this.props.match.params;

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Tutor dedicato</Title>
                <MSection className="mt-2">
                    <p>Il personale altamente specializzato addetto all'assistenza
                        didattica è disponibile da Lunedì a Venerdì dalle ore 09:00 alle ore 13:00 e dalle ore 15:00 alle ore 18:00 al seguente recapito telefonico <a href="tel:+39 3934483737" >3934483737</a>.</p>
                </MSection>
            </>);
    }
}

export default withRouter(CourseTutorMNE);