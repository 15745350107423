import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import app from './reducers/app';

export const history = createBrowserHistory();


const createRootReducer = history => (
    combineReducers({
        router: connectRouter(history),
        app
    })
);

export const initStore = () => {
    const middlewares = [thunk];
    return compose(
        applyMiddleware(
            routerMiddleware(history),
            ...middlewares
        )
    )(createStore)(createRootReducer(history));
};
