import React from "react";

import {
  MSearchSchool,
  MSearchUniversity,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import { FormTitleAConfigurator } from "./FormTitleAConfiguration";


export class FormTitleA extends ValidatedForm {
  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    description: "Dato obbligatorio",
    dataConseguimento: "Dato obbligatorio",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    ciclo: "Dato obbligatorio",
    ufficioScolastico: "Dato obbligatorio",
    riconoscimentoMIUR: "Dato obbligatorio",
    dataDecreto: "Dato obbligatorio",
    dataPresentazioneDomanda: "Dato obbligatorio",
    numProtocollo: "Dato obbligatorio",
    nation: "Dato obbligatorio",
    city: "Dato obbligatorio",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    description: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    ciclo: (value) => value !== "",
    ufficioScolastico: (value) => value !== "",
    riconoscimentoMIUR: (value) => value !== "",
    dataDecreto: (value) => value !== "",
    dataPresentazioneDomanda: (value) => value !== "",
    numProtocollo: (value) => value !== "",
    nation: (value) => value !== "",
  };

  validateAA = (value) => {
    let aa = /^(\d{4})\/(\d{4})$/.test(value);

    if (!aa) {
      return false;
    } else {
      aa = value.split("/");
      if (parseInt(aa[1]) === parseInt(aa[0]) + 1) {
        return true;
      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      console.log(888);
      return false;
    }
    const date = new Date(value);

    if (Number.isNaN(date.getTime())) {
      return false;
    }
    const [year, month, day] = value.split("-").map(Number);

    if (month > 12) {
      return false;
    }
    if (day > 31) {
      return false;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear - 70 || year > currentYear) {
      return false;
    }
    return true;
  };

  emptyFields = {
    description: "",
    istitution: "",
    city: "",
    aa: "",
    dataConseguimento: "",
    ciclo: "",
    ufficioScolastico: "",
    riconoscimentoMIUR: "",
    dataDecreto: "",
    dataPresentazioneDomanda: "",
    numProtocollo: "",
    nation: "",
  };

  state = {
    showSearchUniversity: false,
    showSearchSchool: false,
    university: {},
    school: {},
    ...this.emptyFields,
    defaultValues: [],
  };

  componentDidMount() {
    const defaultValues = FormTitleAConfigurator.getDefaultValues(
      this.props.template
    );
    this.setState({ defaultValues: defaultValues });
  }

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.template = this.props.template;
    this.payload.extCode = FormTitleAConfigurator.getExtCode(
      this.props.template
    );
    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    if (this.state.university && this.state.university.uniCode) {
      this.payload.uniCode = this.state.university.uniCode;
      this.payload.uniCode = this.state.university.uniCode;
    } else if (this.state.school && this.state.school.schoolCode) {
      this.payload.uniCode = this.state.school.schoolCode;
    }

    return this.payload;
  };

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  searchSchool = () => {
    this.setState({ showSearchSchool: !this.state.showSearchSchool });
  };

  onChangeSchool = (school) => {
    this.onChange("city", school.city);
    this.onChange("istitution", school.istitution);
    this.onChange("province", school.province);
    this.onChange("address", school.address);
    this.setState({ showSearchSchool: false, school: school });
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({ showSearchUniversity: false, university: university });
  };

  //--- ---
  render() {
    const { showSearchUniversity, showSearchSchool, defaultValues } =
      this.state;

    const fields = FormTitleAConfigurator.getFields(
      this.props.template,
      this.searchUniversity,
      this.searchSchool
    );

    const { code } = this.renderFields(fields, defaultValues, true);

    return (
      <>
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
            />
          </>
        )}
        {showSearchSchool && (
          <>
            <MSearchSchool
              onChange={this.onChangeSchool}
              searchSchool={this.searchSchool}
            />
          </>
        )}
        <form className="user mt-5">{code}</form>
      </>
    );
  }
}
