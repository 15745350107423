export class stringHelper {

  static replaceAll = (content, search, replace) => {
    if (content && content.length > 0)
      return content.split(search).join(replace);
    else
      return content;
  };

  static makeSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  };

  static copy2Clipboard = (str) => {
    var input = document.createElement('input');
    input.setAttribute('value', str);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }//copy2Clipboard

  static countItemInString = (str, subStr) => {
    let count = 0;
    let startIndex = 0;
  
    while (true) {
        startIndex = str.indexOf(subStr, startIndex);
        if (startIndex === -1) {
            break;
        }
        count++;
        startIndex += subStr.length;
    }
  
    return count;
  }//countItemInString

  
  static formatNumer = (value) => {
    if (value === null || value === undefined) return '';
    return value.toLocaleString('it-IT');
  }//formatNumer

  static formatEuro = (value) => {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
  }//formatEuro

  static formatPercentage = (value) => {
    if (value === null || value === undefined) return '';
    return value.toLocaleString('it-IT', { style: 'percent', minimumFractionDigits: 2 });
  }//formatPercentage
}