import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getCurrentUser } from "store/actions";
import { Link } from "react-router-dom";
import { ROUTES } from "const";

import { NavLibrary, BooksSlider } from "components/biblioteca";

import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";
import "./Book.scss";

class Book extends Component {
  state = {
    slug: null,
    book: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
    isPdfOpen: false,
    error: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    // get slug code from parameter
    const { slug } = this.props.match.params;
    libraryService
      .book(slug)
      .then((response) => {
        this.setState({
          isLoading: false,
          book: response.data.payload.book,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const user = getCurrentUser();
    const { isLoading, networkError, errorMessage, book } = this.state;

    const getPdfBook = () => {
      if (!user) {
        return (
          <Link
            className="login-to-download"
            to={{
              pathname: `${ROUTES.PAGE_BIBLIOTECA}/libri/libro/${book.slug}`,
              state: book,
            }}
          >
            Accedi per consultare il libro
          </Link>
        );
      }
      return (
        <Link
          className="book-download"
          to={{
            pathname: `${ROUTES.PAGE_BIBLIOTECA}/libri/libro/${book.slug}`,
            state: book,
          }}
        >
          Consulta il libro
        </Link>
      );
    };

    if (isLoading) return <MnemoLoading />;

    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            <div className="bookCover">
              <div className="bookCover__container">
                <div className="bookCover__wrapper">
                  {book ? (
                    <>
                      <div className="bookCover__column title">
                        <h1 className="book-title">{book.title}</h1>
                      </div>
                      <div className="bookCover__column content">
                        <div className="content__item">
                          <p className="label">Categoria</p>
                          <p className="text">{book.category.name}</p>
                        </div>
                        <div className="content__item">
                          <p className="label">Autore</p>
                          <p className="text">
                            <Link
                              className="author-link"
                              to={{
                                pathname: `${ROUTES.PAGE_BIBLIOTECA}/autori/${book.author.slug}`,
                                state: book.author,
                              }}
                            >
                              {book.author.fullName}
                            </Link>
                          </p>
                        </div>
                        <div className="content__item half">
                          <p className="label">Anno</p>
                          <p className="text">{book.year}</p>
                        </div>
                        <div className="content__item half">
                          <p className="label">Numero di pagine</p>
                          <p className="text">{book.printLength}</p>
                        </div>
                        <div className="content__item">
                          <p className="text">{book.description}</p>
                        </div>

                        <div className="buttons-actions">{getPdfBook()}</div>
                      </div>
                    </>
                  ) : (
                    <BoxSuccess>Libro non trovato</BoxSuccess>
                  )}
                </div>
              </div>
            </div>
            <BooksSlider />
          </>
        )}
      </>
    );
  }
}

export default withRouter(Book);
