import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { ValidateEmailAlert } from 'components/dashboard';
import { keepAliveWorker, userService } from 'libs';

class StudentPage extends Component {

    constructor(props) {

        super(props);
        this.state = {
            verifyIntegrityrCode: true
        }
    }

   myVar = null;
    UNSAFE_componentWillReceiveProps(propsSuccessive){
      
        if (propsSuccessive && propsSuccessive.match && propsSuccessive.match.params && propsSuccessive.match.params.rCode) {
            const {rCode} = propsSuccessive.match.params;
            if (rCode) {
                clearInterval(this.myVar);
                keepAliveWorker.keep(window.location.pathname,
                    window.location.search, rCode);
                this.myVar = setInterval(function () {
                    keepAliveWorker.keep(window.location.pathname,
                        window.location.search, rCode);
                }, process.env.REACT_APP_KEEPINTERVAL);
    
                this.verifyIntegrityrCode(rCode);
            }
        }
    }
  
    componentWillUnmount() {
        clearInterval(this.myVar);
    }

    verifyIntegrityrCode = (rCode) => {
        userService.verifyIntegrityrCode(rCode).then((result) => {
            this.setState({
                verifyIntegrityrCode: result.data.payload.verify
            })
        }).catch(error => {
            this.setState({
                verifyIntegrityrCode: false
            })
            console.error(false);
        });
    }//verifyIntegrityrCode

    //--- ---

    render() {

        const { user, children } = this.props;
        if (!user) return null;

        const { confirmed = false } = user;
        const { verifyIntegrityrCode } = this.state;

        return (
            <>
                {(!verifyIntegrityrCode && this.props.match.params.rCode) && <Redirect to="/401" />}

                {
                    confirmed ?
                        <>{children}</>
                        : <ValidateEmailAlert />
                }
            </>
        );
    }
}

const stateToProps = ({ app }) => {
    const { user } = app;
    return { user };
};

const SP = withRouter(StudentPage);

export default connect(
    stateToProps
)(SP);