import React, { Component } from "react";
import { Container, Row, Col, Button } from "design-react-kit";
import { utilsService } from "libs";
import { MSelect } from "./MSelect";

export class MSearchUniversity extends Component {
  PAYLOADS = {
    regions: [],
    university: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      popolateRegions: { options: [{ label: "Seleziona", value: -1 }] },
      popolateUniversity: { options: [{ label: "Seleziona", value: -1 }] },
      actionModule: false,
      istitutionData: false,
    };

    this.loadUniversity = this.loadUniversity.bind(this);
  }

  //--- ---

  componentDidMount() {
    this.loadRegions();
  }


  loadRegions = () => {
    utilsService
      .regions()
      .then(({ data }) => {
        const { payload } = data;
        const regions = payload.regions.map(({ codReg, denReg }) => ({
          label: denReg,
          value: codReg,
        }));

        this.PAYLOADS.regions = [];
        this.PAYLOADS.regions.push({ label: "Seleziona", value: -1 });
        this.PAYLOADS.regions.push(...regions);

        this.setState({
          popolateRegions: { options: this.PAYLOADS.regions },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  }; //loadRegions

  loadUniversity = (_, value) => {
    const arrUni = value === "-1" || value === "" ? "" : value;
    this.popolateUniversity(arrUni);

    this.setState({
      popolateUniversity: { options: [{ label: "Seleziona", value: -1 }] },
      actionModule: false,
    });
  }; //loadUniversity

  popolateUniversity(arrUni) {
    arrUni = arrUni.split(",");

    utilsService
      .universitiesByCodReg(arrUni[0])
      .then(({ data }) => {
        const { payload } = data;
        const university = payload.university.map(
          ({ uniCode, name, city }) => ({
            label: name,
            value: [uniCode, city, name],
          })
        );

        this.PAYLOADS.university = [];
        this.PAYLOADS.university.push(...university);
        this.PAYLOADS.university.unshift({
          label: `Seleziona (${university.length})`,
          value: -1,
        });

        this.setState({
          popolateUniversity: { options: this.PAYLOADS.university },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  } //popolateUniversity

  prepareDataToSend = (_, value) => {
    this.setState({
      istitutionData: value.split(","),
      actionModule: true,
    });
  }; //prepareDataToSend

  handleChange = () => {
    if (this.props.onChange) {
      const data = {
        istitution: this.state.istitutionData[2],
        city: this.state.istitutionData[1],
        uniCode: this.state.istitutionData[0],
      };

      this.props.onChange(data);
      this.props.searchUniversity();
    }
  };

  render() {
    const { popolateRegions, popolateUniversity, actionModule } = this.state;

    return (
      <>
        <Container className="m-0 p-0">
          <p className="pb-4">
            Selezionare l'Università dove hai conseguito il Titolo.
          </p>
          <Row>
            <Col lg={5} className="mb-1">
              <div className="bootstrap-select-wrapper">
                <MSelect
                  id="listRegions"
                  label="Regione"
                  payload={popolateRegions}
                  onChange={this.loadUniversity}
                />
              </div>
            </Col>

            <Col lg={7} className="mb-1">
              <div className="bootstrap-select-wrapper">
                <MSelect
                  id="listUniversity"
                  label="Università"
                  payload={popolateUniversity}
                  onChange={this.prepareDataToSend}
                />
              </div>
            </Col>

            <Col lg={12} className="m-0 text-right">
              <Button
                color="primary bg-dark"
                disabled={actionModule ? false : true}
                size="md"
                onClick={()=>{ this.handleChange() }}>
                SELEZIONA
              </Button>

              <Button
                color="primary"
                className="mx-5"
                outline
                size="md"
                onClick={() => {
                  this.props.searchUniversity();
                }}>
                ANNULLA
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
