import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col, Icon
} from 'design-react-kit';
import { Helmet } from 'react-helmet';

export class ReferenceLegislation extends Component {

    render() {

        const title = 'NORMATIVA DI RIFERIMENTO E ATTESTAZIONE CFU';

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <hr />
                            <p>
                                Il D.L. n. 59 del 13 Aprile 2017 nonché il DM del 10 Agosto 2017 e relativi relativi Allegati prevedono che
                                per l’accesso ad un Concorso Pubblico nazionale per Docenti Scuola Secondaria di primo e Secondo grado, occorrerà
                                anche la “certificazione” del possesso di almeno di “24 CFU/CFA acquisiti nelle discipline antropo-psico-pedagogiche e
                                nelle metodologie e tecnologie didattiche, garantendo comunque il possesso di almeno sei crediti in almeno tre dei
                                seguenti quattro ambiti disciplinari: pedagogia, pedagogia speciale e
                                didattica dell’inclusione; psicologia; antropologia; metodologie e tecnologie didattiche”.</p>
                            <p>Con Nota MIUR n. 29999 del 25/10/2017 stabilisce, tra l’altro,  che i crediti conseguiti nei SSD indicati dal D.M. 616/17 non sono
                                automaticamente attestabili come validi per il percorso formativo.</p>
                            <ul className="list-left m-3">
                                <li><Icon icon="it-check" /><a href="/files/24cfu/decreto-legislativo-59-del-13-aprile-2017-24-CFU.pdf" target="_blank">D.L. n. 59 del 13 Aprile 2017</a> ed <a href="/files/24cfu/allegato+al+D.M.+del+10.8.2017+n.616.pdf" target="_blank">Allegati</a></li>
                                <li><Icon icon="it-check" /><a href="/files/24cfu/Decreto_MIUR_del _10_Agosto_2017_24_CFU.pdf" target="_blank">DM del 10 Agosto 2017</a></li>
                                <li><Icon icon="it-check" /><a href="/files/24cfu/Nota_MIUR _n_29999_del_25_ottobre-2017.pdf" target="_blank">Nota MIUR  n. 29999 del 25/10/2017</a></li>                                
                            </ul>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}