import React, { Component } from "react";

import { Header, Body, Footer } from ".";
//import MessengerCustomerChat from "react-messenger-customer-chat";

export class MBootstrap extends Component {
  render() {
    const { isLoggedIn } = this.props;

    return (
      <>
        <Header isLoggedIn={isLoggedIn} />
        <Body isLoggedIn={isLoggedIn}>{this.props.children}</Body>
        <Footer />        
      </>
    );
  }
}
