import React from "react";
import { AdvModal } from "./advModal";
import { AdvBannerTop } from "./AdvBannerTop";
import { showBannerTopCookie, showAdvModalCookie, setAdvModalCookie, setBannerTopCookie } from "libs/helpers/cookieHelper";

export class AdvHandler {

    // -------- START ADV settings --------
    static utm_adv = 'utm_adv';

    static advSettings = [
        {
            name: 'BLACKFRIDAY',
            imageModal: '/img/adv-modal/blackfriday.jpeg',
            textBanner: 'Buono sconto su qualsiasi corso utilizzando il Codice Riservato <b>BLACKFRIDAY</b>',
            enabled: false,
        },
        {
            name: 'MNEMONATALE2022',
            imageModal: '/img/adv-modal/natale.jpeg',
            textBanner: 'Buono sconto dal 10% al 50% su qualsiasi corso utilizzando il Codice Riservato  <b>MNEMONATALE2022</b>',
            enabled: true,
        },
        {
            name: 'INV100',
            imageModal: '/img/adv-modal/inv100.jpg',
            textBanner: 'Sconto di <b>100€</b> su tutti i corsi universitari, utilizza il Codice Riservato <b>INV100</b>.Non applicabile per il percorso di formazione di 5cfu, per i Corsi Singoli Universitari e per i Percorsi Abilitanti.',
            enabled: true,
        }
    ];

    // -------- END ADV settings --------    

    static getCurrentAdv() {

        // verify the url param
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let advParam = urlParams.get(this.utm_adv);
       
        if (advParam) {
            advParam = advParam.toUpperCase();
        }
        return advParam;
    }

    static isModalToEnable() {

        let isEnabled = false;

        const advParam = this.getCurrentAdv();

        // verify if the are a campain active
        if (advParam) {
            const adv = this.advSettings.find(c => c.name === advParam);
            if (adv && adv.enabled) {
                isEnabled = adv;
                setAdvModalCookie(advParam, 'true');
            } else {
                setAdvModalCookie(advParam, 'false');
            }
        } else {

            // verify if the are a campain active in the cookie
            this.advSettings.forEach(advItem => {
                const showModal = showAdvModalCookie(advItem.name);
                if (advItem.enabled) {

                    if (showModal === 'true') {                        
                        isEnabled = advItem;
                    }
                }
            });

        }

        return isEnabled;
    }

    static isBannerTopToEnable() {

        let isEnabled = false;

        const advParam = this.getCurrentAdv();

        // verify if the are a campain active in the url
        if (advParam) {
            const adv = this.advSettings.find(c => c.name === advParam);
            if (adv && adv.enabled) {
                isEnabled = adv;
                setBannerTopCookie(advParam, 'true');
            } else {
                setBannerTopCookie(advParam, 'false');
            }
        } else {
            // verify if the are a campain active in the cookie
            this.advSettings.forEach(advItem => {
                const showBanner = showBannerTopCookie(advItem.name);
                if (advItem.enabled) {

                    if (showBanner === 'true') {
                        isEnabled = advItem;
                    }
                }
            });
        }

        return isEnabled;
    }

    static renderModal() {

        const adv = this.isModalToEnable();        
        if (adv) {
            return (<AdvModal image={adv.imageModal} advName={adv.name} />);
        }

        return (<></>)
    }

    static renderBannerTop() {

        const adv = this.isBannerTopToEnable();        
        if (adv) {
            return (<AdvBannerTop textBanner={adv.textBanner} advName={adv.name} />)
        }
        return (<></>)
    }
}