import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import DayJS from 'react-dayjs';
import ReactHtmlParser from 'react-html-parser';
import { MnemoLoading, BoxDanger, SupportoTecnico, TimeWatcher } from "components/misc";
import { MnemoCallout } from "components/misc/MnemoCallout";
import { learningService, networkErrorHelper } from "libs";

import { CourseSection } from 'components/learning/CourseSection';

import {
    Container, Icon
} from 'design-react-kit';
import { ROUTES } from "const";

class RCSULesson extends Component {

    state = {
        rCode: 0,
        myCourse: 0,
        homeConfig: 0,
        loading: true,
        loadingError: false
    };

    componentDidMount() {

        // get registration code from parameter
        const { rCode } = this.props.match.params;
        this.setState({ rCode });

        this.loadCourse(rCode);
    }

    loadCourse(rCode) {
        learningService
            .courseMain(rCode, 1)
            .then(({ data }) => {
                const { userCourse, course, homeConfig } = data.payload;
                this.setState({ myCourse: userCourse, course, homeConfig, loading: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, myCourse, course, homeConfig, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
        const showTimer = (course && course.metadata && course.metadata.showTimer) ? true : false;

        return (

            <>
                {loadingError ? (<BoxDanger className="mt-4">Si è verificato un errore durante
                    il caricamento dei dati del corso, <SupportoTecnico /> </BoxDanger>) : (
                    <>
                        <section className="my-4">
                            <Container>
                                <MnemoCallout>
                                    <h5>{ReactHtmlParser(course.title)}</h5>
                                    <hr/>
                                    <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                                    <b>Codice corso</b>: {course.code}<br />
                                    <b>Codice registrazione</b>: {rCode}<br />
                                    <b>data Inizio</b>: <DayJS format="DD/MM/YYYY">{myCourse.dateStart}</DayJS><br />
                                    {(showTimer) && (<TimeWatcher rCode={rCode} metadata={course.metadata} />)}
                                </MnemoCallout>
                            </Container>
                        </section>
                        <section className="my-4">
                            <Container>
                                {Object.values(homeConfig).map((row, i) => (
                                    <CourseSection key={`course-section-${i}`} sectionInfo={row} rCode={rCode} />
                                ))}
                            </Container>
                        </section>
                    </>
                )}
            </>);
    }
}

export default withRouter(RCSULesson);