import React, { Component } from "react";

import { Button, Col, Row } from "design-react-kit";

import { BoxDanger, BoxSuccess, MnemoLoading, MSection } from "components/misc";
import { MTable } from "components/table";

import { networkErrorHelper, qualificationService } from "libs";
import { MInput, MSelect } from "components/forms";
import { ROUTES } from "const";
import { Helmet } from "react-helmet";

const VIEWS = {
    MAIN: 'main',
    QT_LIST: 'qualificationTitle_list',
    QT_DETAIL: 'qualificationTitle_detail',
    CC_LIST: 'competitionClass_list',
    CC_DETAIL: 'competitionClass_detail',
    CCO_LIST: 'competitionClassOld_list',
    CCO_DETAIL: 'competitionClassOld_detail'
};

export class QualificationMap extends Component {

    state = {
        view: VIEWS.MAIN,
        title: 'Classi di concorso e titoli di accesso',
        loading: true,

        CCListPayload: [],
        CCFilter: false,
        CCListFiltered: [],

        payloadCCDetail_CCO: [],

        CCOListPayload: [],
        CCOFilter: false,
        CCOListFiltered: [],

        QTListPayload: [],
        QTFilterSearch: '',
        QTFilterSelect: '',
        QTListFiltered: [],

        QTDetailPayload: [],
    }

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote() {

        this.loadQTList();
        this.loadCCList();
        this.loadCCOList();

    }

    // ----------------------- Competition Class List (Classi di concorso) -------------------------------------

    TABLECC_CONFIG = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data
            }, {
                id: 'code',
                Header: 'Codice',
                accessor: 'code', // accessor is the "key" in the data  
                width: 50
            }, {
                id: 'description',
                Header: 'Descrizione',
                accessor: 'description',
            }, {
                id: 'note',
                Header: 'Note',
                accessor: 'note',
            }

        ],
        onRowClick: (e, value) => {
            this.showCCDetail(value);
        },
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Mostra dettagli',
                text: 'Mostra dettagli',
                color: 'primary bg-dark',
                onClick: (e, value) => {
                    this.showCCDetail(value);
                }
            },
            {
                id: 'subscribe',
                accessor: 'id',
                label: 'Master consigliati',
                text: 'Master consigliati',
                color: 'primary bg-dark',
                onClick: (e, value) => {
                    this.showMaster(value);
                }
            },
        ]
    }

    showCCList = () => {

        this.setState({
            view: VIEWS.CC_LIST,
            CCFilter: false,
            title: 'Classi di concorso e di abilitazione'
        });
    }

    loadCCList() {

        const items = [];

        qualificationService.loadAllCompetitionClass()
            .then(({ data }) => {

                //--- start: prepare reactTable ---

                Object.entries(data.payload.competitionClasses).forEach(([key, val]) => {
                    items.push(val);
                });
                //--- end: prepare reactTable ---               

                this.setState({
                    CCListPayload: items,
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    searchCCList = (_, keySearch) => {

        if (keySearch.length > 0) {

            const CCListFiltered1 = this.state.CCListPayload.filter(
                item => item.description.toLowerCase().indexOf(keySearch.toLowerCase()) > -1);

            const CCListFiltered2 = this.state.CCListPayload.filter(
                item => item.code.toLowerCase().indexOf(keySearch.toLowerCase()) > -1);

            this.setState({
                CCFilter: true,
                CCListFiltered: CCListFiltered1.concat(CCListFiltered2),
            });
        } else {

            this.setState({
                CCFilter: false,
                CCListFiltered: [],
            });
        }
    }

    renderCCList() {

        const rndKey = Math.floor((Math.random() * 1000) + 1)
        const tableData = this.state.CCFilter ? this.state.CCListFiltered : this.state.CCListPayload;

        let code = <>
            <MSection className="mb-2">
                <Row className="mb-3">
                    <Col>
                        <Button color="primary" outline className="width-full" >
                            Nuove classi di concorso
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary bg-dark " className="width-full" onClick={this.showCCOList}>
                            Vecchie classi di concorso
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <MInput
                        id="searchCC"
                        className="width-full mb-0"
                        type="text"
                        onChange={this.searchCCList}
                        label="Cerca classe di concorso" />
                </Row>
            </MSection>
            <MTable
                key={rndKey}
                tableData={tableData}
                tableConfig={this.TABLECC_CONFIG}
            />
        </>;
        return code;
    }

    // ----------------------- Competition Class Detail (Classe di concorso) -------------------------------------

    TABLECC_QT_CONFIG_DETAIL = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data
            }, {
                id: 'title',
                Header: 'Descrizione',
                accessor: 'title',
            }, {
                id: 'note',
                Header: 'Note',
                accessor: 'note',
            }
        ],
        onRowClick: (e, value) => {
            this.showQTDetail(value);
        },
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Mostra dettagli',
                text: 'Mostra dettagli',
                color: 'primary  bg-dark',
                onClick: (e, value) => {
                    this.showQTDetail(value);
                }
            },
        ]
    }

    TABLECC_CCO_CONFIG_DETAIL = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data
            }, {
                id: 'code',
                Header: 'Codice',
                accessor: 'code', // accessor is the "key" in the data
                width: 50
            }, {
                id: 'codeOther',
                Header: 'Altro codice',
                accessor: 'codeOther',
            }, {
                id: 'description',
                Header: 'Descrizione',
                accessor: 'description',
            }, {
                id: 'note',
                Header: 'Note',
                accessor: 'note',
            }
        ],
        onRowClick: (e, value) => {
            this.showCCODetail(value);
        },
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Mostra dettagli',
                text: 'Mostra dettagli',
                color: 'primary bg-dark',
                onClick: (e, value) => {
                    this.showCCODetail(value);
                }
            },
        ]
    }

    loadCCDetail_QT(idCC) {

        qualificationService.loadQualificationTitle(idCC)
            .then(({ data }) => {

                //--- start: prepare reactTable ---
                const items = [];
                Object.entries(data.payload.qualificationTitles).forEach(([key, val]) => {
                    items.push(val);
                });
                //--- end: prepare reactTable --              

                this.setState({
                    payloadCCDetail_QT: items,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    payloadCCDetail_QT: [],
                    loading: false
                });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    loadCCDetail_CCO(idCC) {

        qualificationService.loadCompetitionClassOldByCC(idCC)
            .then(({ data }) => {

                //--- start: prepare reactTable ---
                const items = [];
                Object.entries(data.payload.competitionClasses).forEach(([key, val]) => {

                    if (val.otherCode.length > 0) {
                        const ccs = this.state.CCOListPayload.filter(item => (item.code === val.code && item.codeOther === val.otherCode));
                        if (ccs.length > 0) {
                            val.codeOther = ccs[0].codeOther;
                            val.id = ccs[0].id;
                            val.description = ccs[0].description;
                        }
                    } else {
                        const ccs = this.state.CCOListPayload.filter(item => item.code === val.code);
                        if (ccs.length > 0) {
                            val.codeOther = ccs[0].codeOther;
                            val.id = ccs[0].id;
                            val.description = ccs[0].description;
                        }
                    }

                    items.push(val);
                });
                //--- end: prepare reactTable --              

                this.setState({
                    payloadCCDetail_CCO: items,
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    payloadCCDetail_CCO: [],
                });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    showMaster = (idCC) => {

        const ccs = this.state.CCListPayload.filter(item => item.id === idCC);

        const cc = ccs[0];
        const url = ROUTES.COURSE_SSD_BROWSE + '/' + cc.code;

        window.open(url, '_blank');
    }

    showCCDetail = (idCC) => {

        const ccs = this.state.CCListPayload.filter(item => item.id === idCC);

        const cc = ccs[0];
        this.setState({
            view: VIEWS.CC_DETAIL,
            title: cc.code + ' - ' + cc.description,
            subtitle: cc.note,
            loading: true
        });

        this.loadCCDetail_CCO(idCC);
        this.loadCCDetail_QT(idCC);
    }

    renderCCDetail() {

        const rndKeyCC = Math.floor((Math.random() * 1000) + 1);
        const rndKeyCCO = Math.floor((Math.random() * 1000) + 1);
        const { title, subtitle } = this.state;

        let code = <>
            <MSection className="mb-1">
                <Row >
                    <Col>
                        <Button color="primary" outline className="width-full" >
                            Nuove classi di concorso
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary bg-dark" className="width-full" onClick={this.showCCOList}>
                            Vecchie classi di concorso
                        </Button>
                    </Col>
                </Row>
            </MSection>
            <MSection className="mb-1">
                <h4>{title}</h4>
                <hr />
                {subtitle}
            </MSection>

            <MTable
                title="Vecchie classi di concorso"
                key={rndKeyCCO}
                tableData={this.state.payloadCCDetail_CCO}
                tableConfig={this.TABLECC_CCO_CONFIG_DETAIL}
            />
            <BoxDanger className="mt-2">
                <p><b>Attenzione</b>: ai sensi del <a href="/assets/doc/nota-5499-del-19-maggio-2017-anticipazioni-decreto-correttivo-classi-di-concorso-259-17.zip">DM 259/17</a>, per chi ha conseguito il titolo di studio entro il 23 febbraio 2016, per l'accesso alle graduatorie
                    d'istituto e ai futuri concorsi, è possibile fare riferimento ai titoli di accesso ad una delle pregresse classi di concorso (confluite nell'attuale)
                    ai sensi del DM 39/98 (<a href="https://archivio.pubblica.istruzione.it/normativa/1998/dm039_98.pdf" rel="noopener noreferrer" target="_blank">qui</a> il testo del Decreto) e del DM 22/05.
                </p>
            </BoxDanger>
            <MTable
                title="Titoli di ammissione alla classe di concorso"
                key={rndKeyCC}
                tableData={this.state.payloadCCDetail_QT}
                tableConfig={this.TABLECC_QT_CONFIG_DETAIL}
            />
        </>;
        return code;
    }

    /**
     *  ----------------------- Competition Class Old List (Vecchie Classi di concorso ) -------------------------------------
     */

    TABLECCO_CONFIG = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data
            }, {
                id: 'code',
                Header: 'Codice',
                accessor: 'code', // accessor is the "key" in the data
                width: 50
            }, {
                id: 'codeOther',
                Header: 'Altro codice',
                accessor: 'codeOther',
            }, {
                id: 'description',
                Header: 'Descrizione',
                accessor: 'description',
            }
        ],
        onRowClick: (e, value) => {
            this.showCCODetail(value);
        },
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Mostra dettagli',
                text: 'Mostra dettagli',
                color: 'primary bg-dark',
                onClick: (e, value) => {
                    this.showCCODetail(value);
                }
            },
        ]
    }

    showCCOList = () => {

        this.setState({ view: VIEWS.CCO_LIST, title: 'Classi di concorso e di abilitazione' });
    }

    loadCCOList() {

        const items = [];

        qualificationService.loadAllCompetitionClassOld()
            .then(({ data }) => {

                //--- start: prepare reactTable ---               
                Object.entries(data.payload.competitionClasses).forEach(([key, val]) => {
                    items.push(val);
                });
                //--- end: prepare reactTable ---

                this.setState({
                    CCOListPayload: items,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    searchCCOList = (_, keySearch) => {

        if (keySearch.length > 0) {

            const CCOListFiltered = this.state.CCOListPayload.filter(
                item => item.description.toLowerCase().indexOf(keySearch.toLowerCase()) > -1);

            this.setState({
                CCOFilter: true,
                CCOListFiltered,
            });
        } else {
            this.setState({
                CCOFilter: false,
                CCOListFiltered: [],
            });
        }
    }

    renderCCOList() {

        const rndKey = Math.floor((Math.random() * 1000) + 1)
        const tableData = this.state.CCOFilter ? this.state.CCOListFiltered : this.state.CCOListPayload;

        let code = <>
            <MSection className="mb-2">
                <Row className="mb-3">
                    <Col>
                        <Button className="width-full" color="primary bg-dark" onClick={this.showCCList}>
                            Nuove classi di concorso
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary" outline className="width-full" >
                            Vecchie classi di concorso
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <MInput
                        id="searchCCO"
                        className="width-full mb-0"
                        type="text"
                        onChange={this.searchCCOList}
                        label="Cerca classe di concorso" />
                </Row>
            </MSection>
            <MTable
                key={rndKey}
                tableData={tableData}
                tableConfig={this.TABLECCO_CONFIG}
            />
        </>;
        return code;
    }

    /**
     *  ----------------------- Competition Class Old Detali (Vecchia Classi di concorso ) -------------------------------------
     */

    loadCCODetail(idCC) {

        qualificationService.loadCompetitionClassByCCO(idCC)
            .then(({ data }) => {

                //--- start: prepare reactTable ---
                const items = [];
                Object.entries(data.payload.competitionClasses).forEach(([key, val]) => {

                    const ccs = this.state.CCListPayload.filter(item => item.code === val.code);
                    if (ccs.length > 0) {
                        val.id = ccs[0].id;
                        val.description = ccs[0].description;
                    }
                    items.push(val);
                });
                //--- end: prepare reactTable --              

                this.setState({
                    payloadCCODetail: items,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    showCCODetail = (idCC) => {
        const ccs = this.state.CCOListPayload.filter(item => item.id === idCC);

        const cc = ccs[0];
        this.setState({
            view: VIEWS.CCO_DETAIL,
            title: cc.code + ' - ' + cc.description,
            subtitle: cc.note,
            loading: true
        });

        this.loadCCODetail(idCC);
    }

    renderCCODetail() {

        const rndKeyCCO = Math.floor((Math.random() * 1000) + 1);
        const { title } = this.state;

        let code = <>
            <MSection className="mb-1">
                <Row >
                    <Col>

                        <Button color="primary bg-dark" className="width-full" >
                            Nuove classi di concorso
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary" outline className="width-full" onClick={this.showCCOList}>
                            Vecchie classi di concorso
                        </Button>
                    </Col>
                </Row>
            </MSection>
            <MSection className="mb-1">
                <h4>{title}</h4>
            </MSection>
            <MTable
                title="Nuove classi di concorso"
                key={rndKeyCCO}
                tableData={this.state.payloadCCODetail}
                tableConfig={this.TABLECC_CONFIG}
            />
        </>;
        return code;
    }

    // ----------------------- Qualification Title List (Titoli di studio ) -------------------------------------

    TABLEQT_CONFIG = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data
            }, {
                id: 'title',
                Header: 'Descrizione',
                accessor: 'title',
            }, {
                id: 'type_label',
                Header: 'Titolo di studio',
                accessor: 'type_label',
            }
        ],
        onRowClick: (e, value) => {
            this.showQTDetail(value);
        },
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Mostra dettagli',
                text: 'Mostra dettagli',
                color: 'primary bg-dark',
                onClick: (e, value) => {
                    this.showQTDetail(value);
                }
            },
        ]
    }

    TITLE_KEYS = {
        'vecchio': 'Lauree vecchio ordinamento',
        'magistrali': 'Laurea magistrale',
        'specialistiche': 'Laurea specialistica',
        'diplomi': 'Diploma o altro titolo',
        'afam': 'Titoli AFAM',
        'altri': 'Altri titoli e requisiti'
    }

    TITLE_TYPES = [
        { label: 'Tutti i titoli', value: '' },
        { label: 'Laurea magistrale', value: 'magistrali' },
        { label: 'Laurea specialistica', value: 'specialistiche' },
        { label: 'Lauree vecchio ordinamento', value: 'vecchio' },
        { label: 'Titoli AFAM', value: 'afam' },
        { label: 'Diploma o altro titolo', value: 'diplomi' },
        { label: 'Altri titoli e requisiti', value: 'altri' },
    ]

    loadQTList() {

        let items = [];
        let magistrali = [];
        let specialistiche = [];
        let vecchio = [];
        let others = [];

        qualificationService.loadAllQualificationTitle()
            .then(({ data }) => {


                Object.entries(data.payload.qualificationTitles).forEach(([key, val]) => {

                    val.type_label = this.TITLE_KEYS[val.type];
                    switch (val.type) {
                        case 'magistrali':
                            magistrali.push(val);
                            break;

                        case 'specialistiche':
                            specialistiche.push(val);
                            break;

                        case 'vecchio':
                            vecchio.push(val);
                            break;

                        default:
                            others.push(val);
                            break;
                    }
                });

                items = magistrali.concat(specialistiche).concat(vecchio).concat(others);
                this.setState({
                    QTListPayload: items,
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    showQTList = () => {

        this.setState({
            view: VIEWS.QT_LIST,
            title: 'Titoli di studio',
            QTListFiltered: [],
            QTFilterSelect: '',
            QTFilterSearch: '',
        });
    }

    searchQTList = (_, keySearch) => {

        const QTListFiltered = this.getQTFiltered(keySearch, this.state.QTFilterSelect);
        this.setState({
            QTFilterSearch: keySearch,
            QTListFiltered,
        });
    }

    selectQTList = (_, keySelect) => {

        const QTListFiltered = this.getQTFiltered(this.state.QTFilterSearch, keySelect);
        this.setState({
            QTFilterSelect: keySelect,
            QTListFiltered,
        });
    }

    getQTFiltered = (keySearch, keySelect) => {

        const list = this.state.QTListPayload;
        let itemsSelect = [];
        let itemsSearch = [];

        if (keySelect.length > 0) {
            itemsSelect = list.filter(
                item => item.type.toLowerCase().indexOf(keySelect.toLowerCase()) > -1);
        } else {
            itemsSelect = list;
        }

        if (keySearch.length > 0) {
            itemsSearch = itemsSelect.filter(
                item => item.title.toLowerCase().indexOf(keySearch.toLowerCase()) > -1);
        } else {
            itemsSearch = itemsSelect
        }

        return itemsSearch;
    }

    renderQTList() {

        const rndKey = Math.floor((Math.random() * 1000) + 1)
        const tableData = (this.state.QTFilterSearch.length > 0 || this.state.QTFilterSelect.length > 0) ? this.state.QTListFiltered : this.state.QTListPayload;
        const payload = { options: this.TITLE_TYPES };
        const { title } = this.state;

        let code = <>
            <MSection>
                <h4>{title}</h4>
            </MSection>
            <MSection className="mb-1">
                <Row>
                    <Col sm={12} lg={6}>
                        <MSelect
                            id="selectQT"
                            className="width-full mb-0"
                            type="text"
                            payload={payload}
                            onChange={this.selectQTList}
                        />
                    </Col>
                    <Col sm={12} lg={6}>
                        <MInput
                            id="searchQT"
                            className="width-full mb-0"
                            type="text"
                            onChange={this.searchQTList}
                            label="Cerca titolo di studio" />
                    </Col>
                </Row>
            </MSection>
            <MTable
                key={rndKey}
                tableData={tableData}
                tableConfig={this.TABLEQT_CONFIG}
            />

        </>;
        return code;
    }

    // ----------------------- Qualification Title Detail (Titolo di studio ) -------------------------------------

    loadQTDetail(idQT) {

        qualificationService.loadCompetitionClassByQT(idQT)
            .then(({ data }) => {

                //--- start: prepare reactTable ---
                const items = [];
                Object.entries(data.payload.competitionClasses).forEach(([key, val]) => {
                    items.push(val);
                });
                //--- end: prepare reactTable --              

                this.setState({
                    QTDetailPayload: items,
                    loading: false
                });

            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });
    }

    showQTDetail = (idQT) => {
        const titles = this.state.QTListPayload.filter(item => item.id === idQT);

        const title = titles[0];
        this.setState({
            view: VIEWS.QT_DETAIL,
            title: title.title,
            loading: true
        });

        this.loadQTDetail(idQT);
    }

    renderQTDetail() {

        const rndKey = Math.floor((Math.random() * 1000) + 1)
        const { title } = this.state;

        let code = <>
            <MSection className="mb-1">
                <h4>{title}</h4>
            </MSection>
            <BoxDanger>
                <p><b>Attenzione</b>: ai sensi del <a href="/assets/doc/nota-5499-del-19-maggio-2017-anticipazioni-decreto-correttivo-classi-di-concorso-259-17.zip">DM 259/17</a>, per chi ha conseguito il titolo di studio entro il 23 febbraio 2016, per l'accesso alle graduatorie
                    d'istituto e ai futuri concorsi, è possibile fare riferimento ai titoli di accesso ad una delle pregresse classi di concorso (confluite nell'attuale)
                    ai sensi del DM 39/98 (<a href="https://archivio.pubblica.istruzione.it/normativa/1998/dm039_98.pdf" rel="noopener noreferrer" target="_blank">qui</a> il testo del Decreto) e del DM 22/05.</p>
            </BoxDanger>
            <MTable
                title="Nuove classi di concorso"
                key={rndKey}
                tableData={this.state.QTDetailPayload}
                tableConfig={this.TABLECC_CONFIG}
            />
            <BoxSuccess className="mt-3">
                Controllare anche le classi di concorso relative a titoli di studio che possono comprendere quello in proprio possesso: es: <b>qualsiasi diploma, qualsiasi laurea, laurea in ingegneria (tutti gli indirizzi), ecc.</b>
            </BoxSuccess>
        </>;
        return code;
    }

    // ------------------------------------------------------- Main view ---------------------------------------

    showMain = () => {
        this.setState({ view: VIEWS.MAIN, title: 'Classi di concorso e titoli di accesso' })
    }

    renderMain() {

        let code =
            <>
                <MSection>
                    <h6>Attenzione le Classi di Concorso<br />A-01, A-17, A-12, A-22 , A-20, A-24, A-25, A-27, A-29, A-30, A-48, A-49, A-53, A-70, A-72, A-71, A-73 
                        <br />sono state soggette a Revisione e aggiornamento. Pertanto si rimanda alla normativa di riferimento <a href="/assets/doc/decreto-22-dicembre-2023.pdf" target="_blank">“DECRETO 22 dicembre 2023” 
                        (GU Serie Generale n.34 del 10-02-2024) </a>.La pagina è in aggiornamento.</h6>
                </MSection>
                <MSection>
                    <h6>Le Tabelle seguenti aiutano il Corsista ad individuare a quale Insegnamento si può accedere essendo in possesso di un Titolo di Studio.</h6>
                </MSection>
                <Row className="my-2">
                    <Col sm={6}>
                        <MSection className="text-center">
                            <img className="image-3" src="/img/icon/graduate.png" alt="Titoli di studio" title="Titoli di studio" />
                            <hr />
                            <Button onClick={this.showQTList} className="m-3" color="primary bg-dark" >Cerca per titolo di studio</Button>
                            <h6>Con il tuo titolo di studio scopri cosa puoi Insegnare</h6>
                        </MSection>
                    </Col>
                    <Col sm={6}>
                        <MSection className="text-center">
                            <img className="image-3" src="/img/icon/presentation.png" alt="Classi di concorso e di abilitazione" title="Classi di concorso e di abilitazione" />
                            <hr />
                            <Button onClick={this.showCCList} color="primary bg-dark" className="m-3" >
                                Cerca Insegnamento o Classe di concorso</Button>
                            <h6>Scopri quale titolo di studio occorre per insegnare una Materia</h6>
                        </MSection>
                    </Col>
                </Row>
                <BoxSuccess>
                    <h6>Note</h6>
                    <ol>
                        <li>In base all'art.5 del DM 259/17 chi è in possesso di un titolo di studio conseguito entro la data di entrata in vigore del nuovo regolamento (23 febbraio 2016) può fare riferimento, sia per le graduatorie che per i futuri concorsi, ai requisiti previsti per le corrispondenti classi di concorso (confluite nell'attuale) ai sensi del DM 39/98 (<a href="https://archivio.pubblica.istruzione.it/normativa/1998/dm039_98.pdf" rel="noopener noreferrer" target="_blank">qui</a> il testo del Decreto) e del DM 22/05.</li>
                        <li>Gli esami e i CFU richiesti, per integrare i requisiti di accesso alle classi di concorso, possono essere conseguiti tramite corsi di laurea (di primo livello, magistrale, specialistica o di vecchio ordinamento) e tramite singoli insegnamenti universitari. Non sono computabili i CFU conseguiti tramite la tesi di laurea.</li>
                        <li>I laureati Vecchio Ordinamento dovranno sostenere esami di nuovo ordinamento da 12 CFU per ciascuna annualità richiesta e di 6 CFU per i semestrali. Qualora le università non rendano più disponibili esami con le denominazioni indicate, ovvero non trovino diretta equipollenza con un altro esame, potranno essere sostenuti i corrispondenti esami nei SSD-Settori Scientifico Disciplinari previsti per le lauree di nuovo ordinamento.</li>
                    </ol>
                    <p>Sul sito del Miur è disponibile una <a href="https://www.miur.gov.it/web/guest/titoli-di-accesso" target="_blank" rel="noopener noreferrer"  >pagina</a> dedicata alle nuove classi di concorso, ma non è presente un motore di ricerca.</p>
                    <h6>Normativa di riferimento</h6>
                    <ul>
                        <li><a href="/assets/doc/decreto-22-dicembre-2023.pdf" target="_blank">DECRETO 22</a> dicembre 2023 - Revisione e aggiornamento della tipologia delle classi di concorso per l'accesso ai ruoli del personale docente della scuola secondaria di primo e di secondo grado.</li>
                        <li><a href="/assets/doc/tabellaA.pdf" target="_blank">Tabella A</a> - DECRETO 20 novembre 2023 Integrazione dei requisiti di accesso alle classi di concorso A-26 Matematica e A-28 Matematica e scienze. Provvedimento attuativo del PNRR - Riforma M4C1R2.1 della Missione 4 - Istruzione e Ricerca - Componente 1 - Potenziamento dell'offerta dei servizi di istruzione: dagli asili nido alle Universita' - Milestone UE M4C1-10. (24A00132) (GU Serie Generale n.12 del 16-01-2024)</li>
                        <li><a href="/assets/doc/decreto-ministeriale-259-del-9-maggio-2017-revisione-dpr-19-16-nuove-classi-di-concorso.zip">DM 259/17</a> e relative tabelle (Decreto correttivo e integrativo del DPR 19/16)</li>
                        <li><a href="/assets/doc/decreto-del-presidente-della-repubblica-19-del-14-febbraio-2016-regolamento-classi-di-concorso.pdf" target="_blank">DPR 19/16</a> e relative <a href="/assets/doc/decreto-del-presidente-della-repubblica-19-del-14-febbraio-2016-regolamento-classi-di-concorso-tabelle.zip" target="_blank">tabelle</a> (Nuovo regolamento delle classi di concorso)</li>
                        <li><a href="/assets/doc/decreto-interministeriale-9-luglio-2009-equiparazione-lauree.pdf" target="_blank">Decreto Interministeriale del 9 luglio 2009</a> (Equiparazioni tra diplomi di lauree di vecchio ordinamento, lauree specialistiche e lauree magistrali)</li>
                        <li><a href="/assets/doc/decreto-ministeriale-92-del-23-febbraio-2016-titoli-di-specializzazione-italiano-l2.pdf" target="_blank">Decreto Ministeriale 92 del 23 febbraio 2016</a> - Titoli di specializzazione per A-23 (Italiano L2)</li>
                        <li><a href="/assets/doc/decreto-ministeriale-93-del-23-febbraio-2016-ambiti-disciplinari-classi-di-concorso.pdf" target="_blank">Decreto Ministeriale 93 del 23 febbraio 2016</a> - Ambiti disciplinari nuove classi di concorso</li>
                        {/* riattivare dopo approvazione decreto: vedi skype Pina
                            <li><a href="/assets/doc/schema-di-decreto-integrazione-requisiti-accesso-classi-di-concorso-a26-matematica-e-a28-matematica-e-scienze.pdf" target="_blank">Schema di Decreto integrazione requisiti accesso classi di concorso A26 Matematica e A28 Matematica e Scienze</a> - Integrazione MATEMATICA E SCIENZE A28 E MATEMATICA A26 entrata in vigore 1 Settembre 2022</li>
                        */ }
                    </ul>

                </BoxSuccess>

            </>;
        return code;
    }

    render() {

        const { view, loading } = this.state;
        if (loading) {
            return <><MnemoLoading></MnemoLoading></>;
        }

        let code = '';
        let mainButton = <Button onClick={this.showMain} color="primary bg-dark" className="m-2">Home Applicazione</Button>;
        let QTButton = <Button onClick={this.showQTList} color="primary bg-dark" className="m-1">Titoli di Studio</Button>;
        let CCButton = <Button onClick={this.showCCList} color="primary bg-dark" className="m-2">Insegnamenti o Classi di Concorso</Button>

        switch (view) {

            case VIEWS.MAIN:
                code = this.renderMain();
                mainButton = <Button onClick={this.showMain} color="primary" outline className="m-2">Home Applicazione</Button>;
                break;

            case VIEWS.QT_LIST:
                code = this.renderQTList();
                QTButton = <Button onClick={this.showQTList} color="primary" outline className="m-1">Titoli di Studio</Button>;
                break;

            case VIEWS.QT_DETAIL:
                code = this.renderQTDetail();
                QTButton = <Button onClick={this.showQTList} color="primary" outline className="m-1">Titoli di Studio</Button>;
                break;

            case VIEWS.CC_LIST:
                code = this.renderCCList();
                CCButton = <Button onClick={this.showCCList} color="primary" outline className="m-2">Insegnamenti o Classi di Concorso</Button>
                break;

            case VIEWS.CCO_LIST:
                code = this.renderCCOList();
                CCButton = <Button onClick={this.showCCList} color="primary " outline className="m-2">Insegnamenti o Classi di Concorso</Button>
                break;

            case VIEWS.CC_DETAIL:
                code = this.renderCCDetail();
                CCButton = <Button onClick={this.showCCList} color="primary" outline className="m-2">Insegnamenti o Classi di Concorso</Button>
                break;

            case VIEWS.CCO_DETAIL:
                code = this.renderCCODetail();
                CCButton = <Button onClick={this.showCCList} color="primary" outline className="m-2">Insegnamenti o Classi di Concorso</Button>
                break;

            default:
                code = this.renderMain();
                mainButton = <Button onClick={this.showMain} color="primary" outline className="m-2">Home Applicazione</Button>;
        }

        const title = 'Corrispondenza Titoli di studio Classi di concorso (Insegnamenti scuola di I e II grado)';

        return (<>

            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
            </Helmet>
            <MSection className="mb-1">
                <div className="d-flex flex-row">
                    <h4>{title}</h4>
                </div>
                <hr />
                <div className="d-xs-flex flex-row">
                    {mainButton}
                    {QTButton}
                    {CCButton}
                </div>
            </MSection>
            {code}
        </>);
    }
}