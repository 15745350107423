import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReviewHistory30Cfu from "./ReviewHistory30Cfu";
import ReviewHistoryEnglish from "./ReviewHistoryEnglish";
import { getCurrentUser } from "store/actions";

class ReviewHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idUser: this.props.match.params.idUser || 0,
    };
  }

  //--- ---

  render() {
    const { idUser } = this.state;
    const user = getCurrentUser();

    return (
      <>
        {(user.revisor.context === '30cfu') && <ReviewHistory30Cfu idUserRevisor={idUser} />}
        {(user.revisor.context === 'english') && <ReviewHistoryEnglish idUserRevisor={idUser} />}
      </>
    );
  }
}

export default withRouter(ReviewHistory);
