import React, { Component } from 'react';

import { NavLink } from "react-router-dom";

export class DemoAgenda extends Component {

    render() {
        return (
            <>
            <div className="it-timeline-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="timeline-element">
                    <div className="it-pin-wrapper it-evidence">
                      <div className="pin-icon">
                        <svg className="icon">
                          <use xlinkHref="/svg/sprite.svg#it-code-circle"></use>
                        </svg>
                      </div>
                      <div className="pin-text"><span>15 Ottobre 2019</span></div>
                    </div>
                    <div className="card-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title big-heading">Iscrizione al corso</h6>
                          <p className="card-text">Iscrizione al corso PCMU2130</p>
                          <span className="card-signature">La segreteria</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="timeline-element">
                    <div className="it-pin-wrapper it-evidence">
                      <div className="pin-icon">
                        <svg className="icon">
                          <use xlinkHref="/svg/sprite.svg#it-code-circle"></use>
                        </svg>
                      </div>
                      <div className="pin-text"><span>17 Ottobre 2019</span></div>
                    </div>
                    <div className="card-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title big-heading">Immatricolazione</h6>
                          <p className="card-text">Immatricolazione al coroso "bla bla bla" con matricola n. presso UNIVEERSITA' MNEOMSNIE</p>
                          <span className="card-signature">La segreteria</span>
                          <NavLink className="read-more" to="#">
                            <span className="text">Scarica il certificato</span>
                            <svg className="icon">
                              <use xlinkHref="/svg/sprite.svg#it-arrow-right"></use>
                            </svg>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div >
                </div >
                <div className="col-12">
                  <div className="timeline-element">
                    <span className="it-now-label d-none d-lg-flex">Oggi</span>
                    <div className="it-pin-wrapper it-now">
                      <div className="pin-icon">
                        <svg className="icon">
                          <use xlinkHref="/svg/sprite.svg#it-code-circle"></use>
                        </svg>
                      </div>
                      <div className="pin-text"><span>19 Novembre 2019</span></div>
                    </div>
                    <div className="card-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title">Non dimenticare di pagare la seconda rata</h6>
                          <p className="card-text">Perchè pagare è importante</p>
                          <NavLink className="read-more" to="#">
                            <span className="text">Procedi con il pagamento</span>
                            <svg className="icon">
                              <use xlinkHref="/svg/sprite.svg#it-arrow-right"></use>
                            </svg>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
                <div className="col-12">
                  <div className="timeline-element">
                    <div className="it-pin-wrapper">
                      <div className="pin-icon">
                        <svg className="icon">
                          <use xlinkHref="/svg/sprite.svg#it-code-circle"></use>
                        </svg>
                      </div>
                      <div className="pin-text"><span>Febbraio 2020</span></div>
                    </div>
                    <div className="card-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title big-heading">Pagamento Terza rata</h6>
                          <p className="card-text">Ebbene si</p>
                          <NavLink className="read-more" to="#">
                            <span className="text">Procedi con il pagamento</span>
                            <svg className="icon">
                              <use xlinkHref="/svg/sprite.svg#it-arrow-right"></use>
                            </svg>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div >
                </div >
                <div className="col-12">
                  <div className="timeline-element">
                    <div className="it-pin-wrapper">
                      <div className="pin-icon">
                        <svg className="icon">
                          <use xlinkHref="/svg/sprite.svg#it-code-circle"></use>
                        </svg>
                      </div>
                      <div className="pin-text"><span>Marzo 2020</span></div>
                    </div>
                    <div className="card-wrapper">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title">Esami</h6>
                          <p className="card-text">Si svolgono gli esami.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              </div >
            </div >
            </>
        );
    }
}
