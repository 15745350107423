import React, { Component } from "react";

import {
    Container
} from 'design-react-kit';

import { MnemoLoading, ModalHandler } from 'components/misc';
import { MTable } from "components/table";

export class Grid extends Component {

    state = {
        loading: true,
        payload: []
    };

    tableConfig = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data

            },
            {
                id: 'col1',
                Header: 'Column 1',
                accessor: 'col1', // accessor is the "key" in the data

            }, {
                id: 'col2',
                Header: 'Column 2',
                accessor: 'col2',
            }
        ],
        actions: [
            {
                id: 'delete',
                accessor: 'id', // field value received in the onclick event
                label: 'Elimina',
                icon: 'it-delete',
                onClick: (e, value) => { this.deleteExample(e, value) }
            },
            {
                id: 'edit',
                accessor: 'id',
                label: 'Modifica',
                icon: 'it-pencil',
                onClick: (e, value) => { console.log('modifica clicked', value) }
            },
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                onClick: (e, value) => { console.log('dettagli clicked', value) }
            }
        ]
    }

    componentDidMount() {
        this.loadRemote();
    }

    deleteExample(e, value) {

        ModalHandler.show(value, 'Eliminazione del dato',
            'vuoi davvero eliminare?', this.deleteCancelled, this.deleteConfirmed);
    }

    deleteCancelled = (value) => {
        console.log('eliminazione annullata: ' + value);
    }

    deleteConfirmed = (value) => {
        console.log('conferma eliminazione:' + value);
    }

    loadRemote() {

        const data = [
            {
                id: 1,
                col1: 'Hello',
                col2: 'World',
            },
            {
                id: 2,
                col1: 'react-table',
                col2: 'rocks',
            },
            {
                id: 3,
                col1: 'whatever',
                col2: 'you want',
            }, {
                id: 4,
                col1: 'wonderful',
                col2: 'world bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla world bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla ',
            }, {
                id: 5,
                col1: 'with or without you with or without you',
                col2: 'Lorem ipsum',
            }
        ]

        this.setState({ loading: false, payload: data });
    }

    render() {

        const { loading, payload } = this.state;

        return (
            <>
                {loading ? (<MnemoLoading></MnemoLoading>) : (
                    <>
                        <Container>
                            <MTable
                                tableData={payload}
                                tableConfig={this.tableConfig}
                            ></MTable>
                        </Container>
                    </>)}
            </>)
    }
}
