import Axios from 'axios';

export const proxyBE = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 300000 // 300 seconds
});

export const proxyApiMnemo = Axios.create({
    baseURL: process.env.REACT_APP_MNEMO_URL,
    timeout: 300000 // 300 seconds
});

export const mnemoService = {
    buildPayload(action, paramName, paramValue) {
      const data = {
        action,
        payload: {},
      };
  
      data.payload[paramName] = paramValue;
      return JSON.stringify(data);
    },
  
    buildPayloadObject(action, payloadObject) {
      const data = {
        action,
        payload:{...payloadObject},
      };
      
      return JSON.stringify(data);
    },
  };
  