import React, { Component } from 'react';

import {
    Container, Icon,
    Row, Card, CardBody,
    CardTitle, CardText,
    CardCategory,  CardReadMore
} from 'design-react-kit';

import {
    userService,
    dateHelper,
    networkErrorHelper
} from "libs";

import { MnemoLoading, BoxSuccess } from "components/misc";
export class NoticeBoardStudent extends Component {

    state = {
        notices: null,
        loading: true,
    };

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote() {
        userService
            .noticeAlerts()
            .then(({ data }) => {

                const notices = data.payload.notices.map(notice => {

                    notice.date = dateHelper.toITDate(notice.date);
                    return notice;
                });

                if (notices && notices.length>0) this.setState({ notices });
                
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }                

            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {

        const { loading, notices } = this.state;        
        return (
            <>
                {loading ? (<MnemoLoading></MnemoLoading>) : (
                    <>
                        {(notices) ?
                            (<section id="nodiceBoard">
                                <div className="section section-muted mb-3">
                                    <Container>
                                        <Row>
                                            <div className="py-2 card-wrapper card-teaser-wrapper card-overlapping card-teaser-wrapper-equal card-teaser-block-3">
                                                {notices.map((notice, i) => (
                                                    <Card key={`notice-${i}`}
                                                        teaser
                                                        noWrapper
                                                        className="card-teaser-image card-flex no-after rounded shadow">
                                                        <div className="card-image-wrapper with-read-more pb-5">
                                                            <CardBody className="p-4">
                                                                <CardCategory>
                                                                    <Icon className="icon-danger " icon="it-error" /> {notice.date}<br/>{notice.category}</CardCategory>
                                                                <CardTitle className="font-weight-semibold">
                                                                    {notice.title}
                                                                </CardTitle>
                                                                <CardText className="card-text">
                                                                    {notice.content}
                                                                </CardText>
                                                            </CardBody>
                                                        </div>
                                                        <CardReadMore
                                                            className="pl-4"
                                                            iconName="it-arrow-right"
                                                            text={notice.actionLabel}
                                                            href={notice.actionLink}
                                                        />
                                                    </Card>
                                                ))}
                                            </div>
                                        </Row>
                                    </Container>
                                </div>
                            </section>) : (<><BoxSuccess>Non risultano notifiche</BoxSuccess></>)}
                    </>
                )}
            </>
        );
    }
}