import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userService } from 'libs';
import { loggedIn, loggedOut } from '../../store/actions';

import { 
    BoxSuccess, BoxDanger, 
    SupportoTecnico, MnemoLoading 
} from 'components/misc';

import {
    Container
} from 'design-react-kit';

class Confirm extends Component {

    state = {
        loading: true,
        confirmed: false,
        justConfirmed: false,
        error: false
    }

    componentDidMount() {

        const { match } = this.props;
        userService.confirmEmail({ key: match.params.key })
            .then(({ data }) => {
                
                if(data.payload.token !== undefined){
                    const { user, token } = data.payload;
                    this.props.login(user, token);
                    this.setState({ loading: false, confirmed: true });
                }else{
                    this.setState({ loading: false, justConfirmed: true });                    
                }

            }).catch(error => {
                console.log(error);
                this.setState({ loading: false, error: true});
                setTimeout(this.props.logout, 3000);
            });
    }

    render() {

        const { loading, error, confirmed, justConfirmed } = this.state;
      
        if (loading) {
            return <><MnemoLoading></MnemoLoading></>;
        }

        let message = '';
        if (error) {
            message = <p>Errore nella conferma dell'email, <SupportoTecnico /></p>
        }

        return (
            <>
                <section className="my-4">
                    <Container>
                        {error && ( <BoxDanger>{message}</BoxDanger> )}
                        {confirmed && ( <BoxSuccess>Il tuo account è stato attivato con successo
                                puoi navigare nelle varie sezioni tramite i pulsanti in alto.
                            </BoxSuccess> )}
                        {justConfirmed && ( <BoxSuccess>Il tuo account è stato già attivato con successo.
                            </BoxSuccess> )}
                    </Container>
                </section>
            </>
        );
    }
}

const stateToProps = () => {
    return {};
};

const dispatchToProps = dispatch => {
    return {
        login(user, token) {
            dispatch(loggedIn(user, token));
        },
        logout() {
            dispatch(loggedOut());
        }
    };
};

export default connect(
    stateToProps,
    dispatchToProps
)(Confirm);