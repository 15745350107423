import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col, Table
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class AccessTitleATA extends Component {

    render() {
        const title = 'LAVORARE A SCUOLA : I TITOLI DI ACCESSO AI PROFILI DEL PERSONALE ATA';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <hr />
                <MSection>
                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th scope='col'>Profilo</th>
                                        <th scope='col'>Titolo di accesso</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope='row'>Assistente Amministrativo</th>
                                        <td>Diploma di maturità</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Collaboratore Scolastico</th>
                                        <td>Qualsiasi diploma di maturità, diploma di qualifica triennale rilasciato da un istituto professionale, diploma di maestro d’arte, diploma di scuola magistrale per l’infanzia , attestati e/ o diplomi di qualifica professionale, entrambi di durata triennale, rilasciati o riconosciuti dalle Regioniesco</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Assistente Tecnico</th>
                                        <td>Diploma di maturità Specifico (Tecnico e Professionale)</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Cuoco</th>
                                        <td>Diploma di qualifica professionale di Operatore dei servizi di ristorazione, settore cucina</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Infermiere</th>
                                        <td>Laurea in scienze infermieristiche o altro titolo ritenuto valido dalla vigente normativa per l’esercizio della professione di infermiere</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Guardarobiere</th>
                                        <td>Diploma di qualifica professionale di Operatore della moda</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>Addetto alle aziende agrarie</th>
                                        <td>Diploma di qualifica professionale di:
                                            <ol><li>operatore agrituristico;</li>
                                                <li>operatore agro industriale;</li>
                                                <li>operatore agro ambientale.</li></ol></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}