import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Row, Col, Button } from 'design-react-kit';

import { ModalHandler } from "components/misc";

import './PDFView.css';

// far dicentare un componente: vedere https://github.com/wojtekmaj/react-pdf/issues/321

export function PDFView(props) {

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  console.log(pdfjs.version);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [canDownloadIN, setCanDowload] = useState(false);

  //--- ---

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setCanDowload(true);
  }

  function onLoadError(error) {
    setCanDowload(false);
    console.log(error);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {    
    if (pageNumber > 1)
      changePage(-1);
  }

  function nextPage() {
    if (pageNumber<numPages)
      changePage(1);
  }


  const forceDownload = (e, value) => {
    e.stopPropagation();

    const { showAlert=true } = props;

    if(showAlert){
        ModalHandler.show(value, 'Download File',
        `il materiale modulo sarà salvato sul tuo pc. ricordiamo 
      che il tempo dedicato alla consultazione del materiale scaricato non 
      sarà conteggiato dalla piattaforma.?`, null, forceDownloadConfirmed);
    }else{
      forceDownloadConfirmed(value);
    }
  }

  const forceDownloadConfirmed = (file) => {
    window.open(file, '_blank');
  }//forceDownloadConfirmed

  const { file, canDownload } = props;
  
  //--- ---
  return (
    <>
      <div>
        <Document
         className="pdf-viewer"
          file={file}       
          renderMode="svg"
          noData="Si è verificato un errore durante il caricamento del file"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
        >
          <Page pageNumber={pageNumber} />
          <div className="page-controls">
            <button type="button" onClick={previousPage} >‹</button><span> Pagina {pageNumber || (numPages ? 1 : '--')} di {numPages || '--'}</span>
            <button type="button" onClick={nextPage}  >›</button>
            </div>
        </Document>

        {(canDownload && canDownloadIN) && <Row>
          <Col className="text-center">
            <Button onClick={(e) => forceDownload(e, file)}>Scarica File</Button>
          </Col>
        </Row>}
      </div>
    </>
  );
}