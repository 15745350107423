import React, { Component } from "react";
import { adminService, dateHelper, networkErrorHelper } from "libs";
import { Button, Card, CardBody, Container } from "design-react-kit";
import { BoxDanger, Title } from "components/misc";
import { MTable } from "components/table";

import { MnemoLoading } from "components/misc";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "const";
import { MInput } from "components/forms";

class LessonLiveCalendarWebEx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      payloadToRender: [],
      rndKey: 0,
      searchFieldCC: [
        {
          id: "searchFrom",
          field: "searchFrom",
          label: "dal",
          type: "date",
          className: "form-control",
          onChange: (elm, value) => {
            this.onSearchForm(value);
          },
          value: "",
        },
        {
          id: "searchTo",
          field: "searchTo",
          label: "al",
          type: "date",
          className: "form-control",
          onChange: (elm, value) => {
            this.onSearchForm(value);
          },
          value: "",
        },
      ],
    };
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "id",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "ssd",
        Header: "SSD",
        accessor: "ssd",
      },
      {
        id: "sad",
        Header: "SAD",
        accessor: "sad",
      },
      {
        id: "competitionClass",
        Header: "Classe di Concorso",
        accessor: "competitionClass",
      },
      {
        id: "teacher",
        Header: "Docente",
        accessor: "teacher",
      },
      {
        id: "note",
        Header: "Note",
        accessor: "note",
      },
    ],
    actions: [
      {
        id: "download",
        accessor: "actionData",
        label: "Download CSV iscritti",
        icon: "it-download",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.loadRemoteDataSet(value);
        },
      },
    ],
  };
  //--- ---

  componentDidMount() {
    this.loadRemote();
  }

  onSearchForm = () => {
    const searchFrom = document.getElementById("searchFrom").value.trim();
    const searchTo = document.getElementById("searchTo").value.trim();

    let searchFieldCCUpdated = [...this.state.searchFieldCC];
    searchFieldCCUpdated[0].value = searchFrom;
    searchFieldCCUpdated[1].value = searchTo;

    this.setState({
      ...this.state,
      searchFieldCC: searchFieldCCUpdated,
    });

    const searchFromDate = searchFrom
      ? new Date(searchFrom + "T00:00:00")
      : null;
    const searchToDate = searchTo ? new Date(searchTo + "T23:59:59") : null;

    const results = this.state.payload.filter((item) => {
      let match = true;

      const itemDate = new Date(item.date);

      if (searchFromDate && !isNaN(searchFromDate.getTime())) {
        match = match && itemDate >= searchFromDate;
      }

      if (searchToDate && !isNaN(searchToDate.getTime())) {
        match = match && itemDate <= searchToDate;
      }

      return match;
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });
  }; //onSearchForm

  loadRemote(additionalState = {}) {
    adminService
      .loadLessonLiveCalendarWebex()
      .then(({ data }) => {
        const newState = {
          payload: data.payload.lessonLiveCalendarWebex || [],
          payloadToRender: data.payload.lessonLiveCalendarWebex || [],
          loading: false,
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

  loadRemoteDataSet(actionData) {

    let [idClass, competitionClass, classGroup, date, hourStart, idLesson] = actionData;

    adminService
      .loadDataSetToDownloadCSV(idClass, competitionClass, idLesson)
      .then((result) => {
        //---
        const array = result.data.payload.lessonLiveClassBooking;
        let str = "Nome;Indirizzo e-mail\r\n";

        for (let i = 0; i < array.length; i++) {
          let line = "";
          for (let index in array[i]) {
            if (line !== "") line += ";";
            line += array[i][index];
          }

          str += line + "\r\n";
        }

        const teacher = this.state.payload
          .filter((item) => item.date === date && item.hourStart === hourStart)
          .map((item) => {
            return item.classes.filter(
              (item) => item.competitionClass === competitionClass
            );
          });

        let downloadLink = document.createElement("a");
        downloadLink.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(str);
        downloadLink.download =
          classGroup.replace(" ", "_") +
          "_" +
          dateHelper.toUSDate(date) +
          "_" +
          (teacher[0][0]?.teacher || teacher[1][0]?.teacher || "")
            .replace(/ /g, "_")
            .replace(/\./g, "") +
          ".csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        //---
      })
      .catch((error) => {
        if (networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  } //loadRemoteDataSet

  loadRemoteFullDataSet(item) {
    
    const idClass = item.classes[0].actionData[0];
    const filename =
      item.classGroup.replace(" ", "_") +
      "_" +
      dateHelper.toITDate(item.date).replace(/\//g, "-") +
      "_" +
      item.hourStart.replace(/:/g, "") +
      "_" +
      item.hourEnd.replace(/:/g, "") +
      ".csv";

    adminService
      .loadFullSetToDownload(
        idClass,
        dateHelper.toUSDate(item.date),
        item.hourStart
      )
      .then((result) => {
        //---
        const array = result.data.payload.lessonLiveClassBooking;
        let str = "COGNOME;NONE;EMAIL;TELEFONO;CLASSE DI CONCORSO;DOCENTE\r\n";

        for (let i = 0; i < array.length; i++) {
          let line = "";
          for (let index in array[i]) {
            if (line !== "") line += ";";
            line += array[i][index];
          }

          str += line + "\r\n";
        }

        let downloadLink = document.createElement("a");
        downloadLink.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(str);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        //---
      })
      .catch((error) => {
        if (networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  } //loadRemoteFullDataSet

  //--- ---

  render() {
    const { loading } = this.state;

    return (
      <>
        <div className="container mt-3 mb-4">
          <section className="m-0">
            <div className="form-row">
              <div className="col-md-12 text-right"></div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <Title>{"Calendario Aule Webex Specifiche"}</Title>

                <div className="form-row mb-4">
                  <div className="col-md-4">
                    <MInput {...this.state.searchFieldCC[0]} className="mb-0" />
                  </div>
                  <div className="col-md-4">
                    <MInput {...this.state.searchFieldCC[1]} className="mb-0" />
                  </div>
                </div>

                {(this.state.payloadToRender || []).map((item, index) => (
                  <Card
                    key={index}
                    tag="div"
                    className="card-bg card-big rounded shadow border-bottom-card mb-3">
                    <CardBody>
                      <div>
                        <section className="m-0 mb-2 p-0">
                          <div className="form-row">
                            <div className="col-md-3">
                              <h3 className="mb-0">{item.classGroup}</h3>
                              <h6 className="text-muted mb-0">
                                {dateHelper.toITDate(item.date)} dalle{" "}
                                {item.hourStart.substring(
                                  0,
                                  item.hourStart.length - 3
                                )}{" "}
                                alle{" "}
                                {item.hourEnd.substring(
                                  0,
                                  item.hourEnd.length - 3
                                )}
                              </h6>
                            </div>
                            <div className="col-md-9 text-right">
                              <Button
                                className="btn btn-primary bg-dark mx-1"
                                onClick={() => {
                                  this.loadRemoteFullDataSet(item);
                                }}>
                                Elenco Corsisti Aula
                              </Button>
                              <NavLink
                                to={ROUTES.LESSON_LIVE_CALENDAR_WEBEX}
                                className="btn btn-primary bg-dark mx-1">
                                Scheda Operativa
                              </NavLink>
                            </div>
                          </div>
                        </section>

                        <Container>
                          <MTable
                            key={this.state.rndKey}
                            tableData={item.classes || []}
                            tableConfig={this.tableConfig}
                          />
                        </Container>
                      </div>
                    </CardBody>
                  </Card>
                ))}

                {(this.state.payloadToRender || []).length === 0 && (
                  <BoxDanger key="nrp-1" className="my-3">
                    Ricerca non valida.
                    <div className="small text-info">
                      Nessun dato disponibile.
                    </div>
                  </BoxDanger>
                )}
              </Container>
            )}

            {this.state.payload.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                Ricerca non valida.
                <div className="small text-info">Nessun dato disponibile.</div>
              </BoxDanger>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default LessonLiveCalendarWebEx;
