import {proxyBE} from './common';

export const qualificationService = {

    loadAllCompetitionClass() {        
        return proxyBE.get('qualifications/competitionClass');        
    },

    loadCompetitionClassByQT(idQualification) {        
        return proxyBE.get(`qualifications/competitionClass/idQT/${idQualification}`);        
    },

    loadCompetitionClassByCCO(idCompetitionClassOld) {        
        return proxyBE.get(`qualifications/competitionClass/idCCO/${idCompetitionClassOld}`);        
    },

    loadAllCompetitionClassOld() {        
        return proxyBE.get('qualifications/competitionClassOld');        
    },

    loadCompetitionClassOld(idCompetitionClassOld){
        return proxyBE.get(`qualifications/competitionClassOld/${idCompetitionClassOld}`);  
    },

    loadCompetitionClassOldByCC(idCompetitionClass){
        return proxyBE.get(`qualifications/competitionClassOld/idCCN/${idCompetitionClass}`);  
    },

    loadAllQualificationTitle() {        
        return proxyBE.get('qualifications/title');        
    },

    loadQualificationTitle(idCompetitionClass){
        return proxyBE.get(`qualifications/title/${idCompetitionClass}`);  
    },

    getSchoolTypeCode(){
        return proxyBE.get(`qualifications/schoolTypeCode`);  
    }
}