import React, { Component } from "react";
import { MnemoCardTag, MnemoLoading, MSection } from "components/misc";
import { getCurrentUser } from 'store/actions';
import { userService, networkErrorHelper, dateHelper } from "libs";

import {
  Container, Row, Col
} from 'design-react-kit';

//---
import {
  UserQualificationSchool,
  UserQualificationSchoolForm,
  UserQualificationUniEnrollmentForm,
  UserQualificationUniversity,
  UserQualificationUniversityForm,
} from 'components/forms/user/profile';

//---
export class MyQualifications extends Component {

  state = {
    school: {},
    university: {},
    loading: true,
    showSearchSchool: false,
    showFormSchool: false,
    schoolData: {},
    showSearchUniversity: false,
    showFormUniversity: false,
    uniEnrollment: {},
  }

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    userService.qualifications().then((data) => {

      const school = data.data.payload.qualifications.school;
      const uniEnrollment = data.data.payload.qualifications.uniEnrollment;
      const university = data.data.payload.qualifications.university

      if (school) {
        school.dateAchievement = dateHelper.toUSDate(school.dateAchievement);
      }

      if (university) {
        university.dateAchievement = dateHelper.toUSDate(university.dateAchievement);
        university.dateSignup = dateHelper.toUSDate(university.dateSignup);
      }

      this.setState({
        university,
        uniEnrollment,
        school,
        loading: false
      });

    }).catch(error => {
      if (!networkErrorHelper.is404(error)) {
        networkErrorHelper.notify(error);
        console.log(error);
      }

      this.setState({ loading: false });
    });
  }//loadRemote


  /** */
  createSchoolProps() {

    const { school } = this.state;

    const schoolProps = {
      title: 'Titolo di scuola superiore',
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      tag: 'Scuola superiore',
      actionLabel: 'Inserisci',
      smallicon: 'it-pa',
      actionClick: this.showFormSchool
    }

    if (typeof school === 'object') { //check if object why render call it before it's set
      if (school.id) {
        schoolProps.title = school.istitution;

        schoolProps.content = (
          <>
            <b>Anno Scolastico:</b> {school.aa}<br />
            <b>Istituto:</b> {school.istitution}<br />
            <b>Città:</b> {school.city}<br />
            <b>Nazione:</b> {school.nation}<br />
            <b>Titolo:</b> {school.description}<br />
            <b>Voto:</b> {school.vote}<br />
            <b>Data conseguimento:</b> {dateHelper.toITDate(school.dateAchievement)}            
          </>
        );
        
        schoolProps.actionLabel = 'Modifica';
      }
    }else{
      schoolProps.isEmpty= true;
    }
    //object

    return schoolProps;
  }//createSchoolProps

  
  /** */
  createUniEnrollmentProps() {
    const { uniEnrollment } = this.state;

    const props = {
      title: 'Prima immatricolazione al sistema universitario',
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      tag: 'Immatricolazione',
      actionLabel: 'Inserisci',
      smallicon: 'it-pa',
      actionClick: this.showFormUniEnrollment
    }

    if (typeof uniEnrollment === 'object') { //check if object because render call it before it's set
      if (uniEnrollment.id) {

        props.title = "Prima immatricolazione al sistema universitario";
        props.content = (
          <>
            <b>Anno Accademico:</b> {uniEnrollment.aa}<br />
            <b>Istituto:</b> {uniEnrollment.istitution}<br />
          </>
        );

        props.actionLabel = 'Modifica';
      }
    }else{
      props.isEmpty=true;
    } //object

    return props;
  }//createUniverityProps

  /** */
  createUniverityProps() {

    const { university } = this.state;

    const universityProps = {
      title: 'Titolo di studio Universitario',
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      tag: 'Laurea',
      actionLabel: 'Inserisci',
      smallicon: 'it-pa',
      actionClick: this.showFormUniversity
    }

    if (typeof university === 'object') { //check if object because render call it before it's set
      if (university.id) {
        universityProps.title = university.istitution;

        universityProps.content = (
          <>
            <b>Anno Accademico conseguimento titolo:</b> {university.aa}<br />
            <b>Istituto:</b> {university.istitution}<br />
            {(university.nation) ? (<><b>Nazione:</b> {university.nation}<br /></>) : ''}
            <b>Città:</b> {university.city}<br />
            <b>Laurea:</b> {university.laureaType}<br />
            <b>Titolo:</b> {university.description}<br />
            <b>Voto:</b> {university.vote}<br />
            <b>Lode:</b> {(university.lode) ? ('SI') : ('NO')}<br />
            <b>Data conseguimento:</b> {dateHelper.toITDate(university.dateAchievement)}
          </>
        );

        universityProps.actionLabel = 'Modifica';
      }
    }else{     
      universityProps.isEmpty=true;
    }
    //object

    return universityProps;
  }//createUniverityProps


  /** */
  showSearchSchool = (status) => {
    this.setState({
      showSearchSchool: status
    })
  }//showSearchSchool


  /** */
  showFormSchool = (status) => {
    this.setState(state => {
      state.showFormSchool = status

      if (!status) {
        this.loadRemote();
        state.showSearchSchool = status
      }

      return state;
    });
  }//showFormSchool


  /** */
  popolateDataFromChildSchool = (data) => {
    if (data) {
      const school      = this.state.school;
      school.city       = data.city;
      school.nation     = data.nation;

      //--- normalized data before send to BE ---
      let tmpIstitution, tmpSchoolCode;

      const tmpArr = (data.istitution).match(/(.*),(.*)/);
      if(tmpArr){
        tmpIstitution = tmpArr[1];
        tmpSchoolCode = tmpArr[2];
      }else{
        tmpIstitution = data.istitution;
        tmpSchoolCode = '';
      }

      school.istitution = tmpIstitution;
      school.schoolCode = tmpSchoolCode;

      this.setState({
        school
      })
    }
    this.showSearchSchool(false);
  }//popolateDataFromChildSchool


  /** */
  popolateDataFromChildSchoolForm = (data) => {
    if (data) {
      let { school } = this.state;
      school = Object.assign({}, school, data);

      this.setState({
        school
      })
    }

    this.showSearchSchool(true);
  }//popolateDataFromChildSchoolForm

  //--- ---

  /** */
  showSearchUniversity = (status) => {
    this.setState({
      showSearchUniversity: status
    })
  }//showSearchUniversity


  /** */
  showFormUniEnrollment = (status) => {
    this.setState(state => {
      state.showFormUniEnrollment = status

      if (!status) {
        this.loadRemote();
        state.showFormUniEnrollment = status
      }

      return state;
    });
  }//showFormUniversity


  /** */
  showFormUniversity = (status) => {
    this.setState(state => {
      state.showFormUniversity = status

      if (!status) {
        this.loadRemote();
        state.showFormUniversity = status
      }

      return state;
    });
  }//showFormUniversity


  /** */
  popolateDataFromChildUniversity = (data) => {
    if (data) {
      let { university } = this.state;
      university = Object.assign({}, university, data);

      this.setState({
        university
      })

      this.renderUniversity();
    }
    this.showSearchUniversity(false);
  }//popolateDataFromChildUniversity


  /** */
  popolateDataFromChildUniversityForm = (data) => {

    if (data) {
      let { university } = this.state;
      university = Object.assign({}, university, data);

      this.setState({
        university
      })
    }
    this.showSearchUniversity(true);
  }//popolateDataFromChilduniversityForm

  //--- ---

  renderSchool(isReadOnly) {

    const schoolProps = this.createSchoolProps();
    const school      = this.state.school;

    const readOnly = isReadOnly && !schoolProps.isEmpty;

    return (
      <Row id="school">
        <Col className="sm-12">
          {(this.state.showFormSchool) ?
            <MSection className="mb-4 border-bottom-card">
              {(this.state.showSearchSchool) ?
                (<UserQualificationSchool
                  showSearchSchool={this.showSearchSchool}
                  popolateDataFromChildSchool={this.popolateDataFromChildSchool}
                />)

                : (<UserQualificationSchoolForm                 
                  school={school}
                  schoolData={this.state.schoolData}
                  showFormSchool={this.showFormSchool}
                  popolateDataFromChildSchoolForm={this.popolateDataFromChildSchoolForm}
                />)
              }
            </MSection>
            : null}

          {(!this.state.showFormSchool) ?
            <MnemoCardTag className="m-2" isReadOnly={readOnly} {...schoolProps}  ></MnemoCardTag>
            : null}
        </Col>
      </Row>);
  }


  renderUniEnrollment(isReadOnly) {

    const uniEnrollmentProps = this.createUniEnrollmentProps();
    const readOnly = isReadOnly && !uniEnrollmentProps.isEmpty;

    return (
      <Row id="uniEnrollment">
        <Col className="sm-12">
          {(this.state.showFormUniEnrollment) ?
            <MSection className="mb-4 border-bottom-card">
              <UserQualificationUniEnrollmentForm        
                uniEnrollment={this.state.uniEnrollment}
                showFormUniEnrollment={this.showFormUniEnrollment}
              />
            </MSection>
            : (<MnemoCardTag className="m-2" isReadOnly={readOnly}  {...uniEnrollmentProps}></MnemoCardTag>)
          }
        </Col>
      </Row>);
  }


  renderUniversity(isReadOnly) {

    const universityProps = this.createUniverityProps();
    const university = this.state.university;

    const readOnly =  isReadOnly && !universityProps.isEmpty;
    
    return (
      <Row id="university">
        <Col className="sm-12">
          {(this.state.showFormUniversity) ?
            <MSection className="mb-4 border-bottom-card">
              {(this.state.showSearchUniversity) ?
                (<UserQualificationUniversity
                  showSearchUniversity={this.showSearchUniversity}
                  popolateDataFromChildUniversity={this.popolateDataFromChildUniversity}
                />)
                : (<UserQualificationUniversityForm
                  university={university}
                  showFormUniversity={this.showFormUniversity}
                  popolateDataFromChildUniversityForm={this.popolateDataFromChildUniversityForm}
                />)
              }
            </MSection>
            : (<>
            <MnemoCardTag className="m-2" isReadOnly={readOnly} {...universityProps}></MnemoCardTag>
            </>)}
        </Col>
      </Row>);
  }

  render() {

    const { loading } = this.state;
    if (loading) return (<MnemoLoading></MnemoLoading>);
    
    const user = getCurrentUser();    
    const codeSchool = this.renderSchool(user.readOnly);
    const codeUniEnroll = this.renderUniEnrollment(user.readOnly);
    const codeUni = this.renderUniversity(user.readOnly);

    return (
      <>
        <Container>          
          {codeSchool}
          {codeUniEnroll}
          {codeUni}
        </Container>
      </>
    );
  }
}
