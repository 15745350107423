import { Title, MSection } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';


class AssistanceOnlineMNE extends Component {

    state = {
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    render() {
        const { rCode } = this.props.match.params;

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Assistenza Online</Title>
                <MSection className="mt-2">
                    <p>
                        Il personale di Mnemosine è disponibile a fornire chiarimenti riguardo il materiale didattico da Lunedì a Venerdì dalle ore 09:00 alle ore 13:00
                        e dalle ore 15:00 alle ore 18:00 al seguente indirizzo e-mail  <a href="mailto:English@formazionedocenti.it">English@formazionedocenti.it</a><br/>
                        Per l'assistenza tecnica di eventuali segnalazioni dovuti a  malfunzionamenti inerenti la sua area riservata la invitiamo a rivolgersi al
                        seguente indirizzo e-mail <a href="mailto:supporto@formazionedocenti.it">supporto@formazionedocenti.it</a></p>
                </MSection>
            </>);
    }
}

export default withRouter(AssistanceOnlineMNE);