/**
 * permette la revisione del quiz:
 * 
 * voto
 * testo
 */

import React, { Component } from "react";
import { Title } from "components/misc";
import { Button, Container, Col } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminQuizReview } from "components/forms/admin/AdminQuizReview";

class Review extends Component {

  constructor(props) {
    super(props);
    this.state = {
      idQuizReview: parseInt(this.props.match.params.idQuizReview) > 0 ? this.props.match.params.idQuizReview : 0
    }
  }

  goBack() {
    window.history.back();
  }

  render() {

    const { idQuizReview } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{"Revisione"}</Title>
            <Col lg={{ size: 12 }} className="text-right">
                  <Button onClick={this.goBack} color="btn btn-primary bg-dark m-2 p-3">Torna Indietro</Button>
            </Col>
            <AdminQuizReview idQuizReview={idQuizReview} />
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(Review);
