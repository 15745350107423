// MAIN PAGE --> PAGE BIBLIOTECA
// hero
// Category list
// Featured books
// Featured books

import React, { Component } from "react";

//components

import {
  NavLibrary,
  HeroBiblioteca,
  BooksList,
  CategoryGrid,
} from "components/biblioteca";
// import { SliderFeaturedCourses } from "components/sliders/sliderFeaturedCousers/sliderFeaturedCourses";

import { libraryService } from "libs/api/libraryService";
import { MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

export class Biblioteca extends Component {
  state = {
    booksItems: [],
    featuredBooks: [],
    categories: [],
    error: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    //-- books --//
    libraryService
      .featuredBooks()
      // .books(userId)
      .then((response) => {
        this.setState({
          isLoading: false,
          featuredBooks: response.data.payload.books,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });

    //-- categories --//
    libraryService
      .categories()
      .then((response) => {
        this.setState({
          isLoading: false,
          categories: response.data.payload.categories,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    // get books and categories
    const { isLoading, networkError, errorMessage, featuredBooks, categories } =
      this.state;
    if (isLoading) return <MnemoLoading />;
    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            {/* Hero top */}
            <HeroBiblioteca />

            {/* Categories */}
            {categories ? (
              <CategoryGrid
                categories={categories}
                goCategories={true}
                title="Lorem ipsum dolor sit amet"
              />
            ) : null}

            {/* Featured Books  */}
            {featuredBooks ? (
              <BooksList
                books={featuredBooks}
                title="Libri in evidenza"
                showAll={true}
              />
            ) : null}

            {/* Slider courses */}
            {/* <SliderFeaturedCourses /> */}
          </>
        )}
      </>
    );
  }
}
