import React, { Component } from "react";
// import Headroom from "react-headroom";

import { MainMenuPublic } from "./mainMenu";

// import { loggedOut } from '../../store/actions';

import "../../scss/components/mainMenuHeader.scss";

// import IconArrowDown from "../../icons/IconArrowDown";
import IconLogout from "../../icons/IconLogout";

import axios from "axios";

export class NHStudentMenu extends Component {
  state = {
    collapsed: true,
    menuItem: null,
    menuProfile: null,
    isDropdownOpen: false,
    dropdownIndex: 0,
  };

  componentDidMount() {
    (async () => {
      let getMenuItem = null;
      let getMenuProfile = null;
      try {
        getMenuItem = await axios.get("/assets/json/NHPublic.json");
        getMenuProfile = await axios.get("/assets/json/NHStudent_profile.json");
        this.setState({
          menuItem: getMenuItem.data,
          menuProfile: getMenuProfile.data,
        });
      } catch (error) {
        this.setState({ error: true });
      }
    })();
  } //componentDidMount

  manageEvtByLink = (link) => {
    if (link === "/") {
      this.setState({ collapsed: true });
    }
  };

  manageEvtByLink = (link) => {
    this.setState({ collapsed: true });
  };

  //--- ---

  render() {
    if (!this.state.menuItem) {
      return false;
    }

    // const openSubMenu = (element, index) => {
    //   this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    //   this.setState({ dropdownIndex: index });
    // };

    // const getSubMenu = (item, x) => {
    //   if (item.sub.length) {
    //     return (
    //       <Headroom className="mainDropdownMenu__headroom">
    //         <div
    //           className={`mainDropdownMenu ${
    //             this.state.isDropdownOpen && this.state.dropdownIndex == x
    //               ? "open"
    //               : ""
    //           }`}
    //           data-menu={`menu-item-${x}`}
    //         >
    //           <div className="mainDropdownMenu__wrapper">
    //             <ul className="mainDropdownMenu__items">
    //               {item.sub.map((element, index) => {
    //                 if (element.label != "divider") {
    //                   return (
    //                     <li
    //                       className="mainDropdownMenu__item"
    //                       key={`dropdownmenu-item-${index}`}
    //                     >
    //                       <a href={element.to} title="">
    //                         {element.label}
    //                       </a>
    //                     </li>
    //                   );
    //                 } else {
    //                   return (
    //                     <div className="divider" key={`divider-${index}`}></div>
    //                   );
    //                 }
    //               })}
    //             </ul>
    //           </div>
    //         </div>
    //       </Headroom>
    //     );
    //   }
    // };

    const getProfileSubMenu = (el, index) => {
      if (el.hasSubmenu && el.sub.length) {
        return (
          <ul className="profile__submenu">
            {el.sub.map((item, i) => {
              return (
                <li key={`item-${i}`}>
                  <a href={item.to} title={item.label}>
                    {item.label}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }
    };

    const getLinkProfile = (el) => {
      if (el.tag && el.tag === "logout") {
        return (
          <div onClick={this.props.logout}>
            <span className="icon-logout">
              <IconLogout />
            </span>
            {el.label}
          </div>
        );
      } else if (el.to) {
        return (
          <a href={el.to} title={el.label}>
            <span>{el.label}</span>
          </a>
        );
      }
      // {el.tag && el.tag == "logout" ? (<span className="icon-logout"><IconLogout /></span>) : ''}
      // {el.label}
    };

    // const isOpen = !this.state.collapsed;
    // const menuItem = this.state.menuItem;
    const menuProfile = this.state.menuProfile;

    // const additional = menuItem ? this.renderMenu(menuItem) : <></>;

    return (
      <>
        <div className="nhstudentmenu">
          {/* USER */}
          <div className="mainMenuContainer__profile open insideMainMenu">
            <div className="profile__link">
              <div className="profile__icon">
                <img
                  src="/img/icon/icon-profile.svg"
                  alt="accedi all'area riservata"
                />
              </div>
              <div className="profile__text">Ciao, {this.props.user.name}</div>
            </div>

            <div className="dropdownMenuProfile">
              <ul className="dropdownMenuProfile__items">
                {menuProfile.map((el, index) => {
                  return (
                    <li
                      className="dropdownMenuProfile__item"
                      key={`profile-item-${index}`}
                    >
                      <div
                        className={
                          "label " +
                          (!el.to ? "first" : "") +
                          (el.tag === "logout" ? "logout" : "")
                        }
                      >
                        {el.tag !== "logout" ? el.label : ""}
                        {getLinkProfile(el)}
                      </div>

                      {getProfileSubMenu(el, index)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {/* END USER */}

          <MainMenuPublic />
        </div>
      </>
    );
  }
}
