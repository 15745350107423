import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class EnglishCertification extends Component {

    render() {
        const title = 'Certificazione lingua inglese B2 e C1';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className="swiper-slide">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corsi-mnemosine/ENG">
                                                <div className="programme-card__header">
                                                    <img class="programme-card__img" alt="Inglese B2-C1" src="/img/courses/new/esb.jpg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <p>Ente Certificatore Internazionale delle competenze linguistiche per la lingua inglese : <b>ESB - English Speaking Board</b></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className="swiper-slide">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corsi-mnemosine/ESAT">
                                                <div className="programme-card__header">
                                                    <img class="programme-card__img" alt="Inglese B2-C1" src="/img/courses/new/esat.jpg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <p>Ente Certificatore Internazionale delle competenze linguistiche per la lingua inglese : <b>ESaT MALTA - Educational services and testing</b></p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <h5><b>Valutazione</b></h5>
                            <ul>
                                <li>Certificazioni linguistiche, Livello B2: Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) punti 3,00;</li>
                                <li>Certificazioni linguistiche, Livello C1: Graduatorie Provinciali delle Supplenze (Ordinanza Ministeriale del 10-07-2020) punti 4,00.</li>
                            </ul>
                            <hr />
                            <h5><b>Scadenza Iscrizione:  <span class="text-danger">11/05/2023</span></b></h5>
                            <hr />
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}