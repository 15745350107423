import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { blogService, dateHelper, networkErrorHelper } from "libs";
import { BoxDanger, MnemoLoading, Title } from "components/misc";

import { Row, Col } from "design-react-kit";
import { BlogSidebar } from "components/blog";
import { Helmet } from "react-helmet";

// Social Share
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

class BlogArticle extends Component {
  state = {
    slug: 0,
    article: 0,
    loading: true,
    loadingError: false,
  };

  componentDidMount() {
    // get slug code from parameter
    const { slug } = this.props.match.params;
    this.setState({ slug });
    this.loadRemote(slug);
  }

  loadRemote(slug) {
    blogService
      .article(slug)
      .then(({ data }) => {
        const { article } = data.payload;
        this.setState({
          article,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);

        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          // TODO: handle 404 error
        }

        this.setState({ loading: false, loadingError: true });
      });
  }

  render() {
    const { loading, loadingError, article } = this.state;

    if (loading) return <MnemoLoading></MnemoLoading>;
    if (loadingError)
      return (
        <BoxDanger>
          TODO: Si è verificato un errore durante il caricamento della pagina{" "}
        </BoxDanger>
      );

    const title = ReactHtmlParser(article.title);
    const content = ReactHtmlParser(article.content);
    //const image = process.env.REACT_APP_HOME + article.image
    const image = article.image || "/img/blog/corsimnemosine-notizie.jpg";

    article.caption = "";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
        </Helmet>
        <Title>{title}</Title>
        <section className="m-5 clearfix">
          <Row>
            <Col md={8}>
              <div className="mb-4 text-center">
                <img
                  className="w-90"
                  title={title}
                  alt={title}
                  src={image}
                ></img>
                {article.caption && (
                  <>
                    <span className="figcaption">{article.caption}</span>
                  </>
                )}
              </div>
              <div>
                {content}
                <hr />
                <span className="articleitem-card__footer">
                  {dateHelper.toITDate(article.date)}
                </span>
              </div>
            </Col>
            <Col md={4}>
              <BlogSidebar></BlogSidebar>
            </Col>
          </Row>

          {/* Social Share */}
          <div className="social-share-container">
            <FacebookShareButton url={window.location.href} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <WhatsappShareButton url={window.location.href} quote={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url={window.location.href} quote={title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href} quote={title}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(BlogArticle);
