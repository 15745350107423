import { BoxSuccess, MSection, Title } from 'components/misc';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

export class PageContactRequest extends Component {

    render() {
        const title = 'Richiesta di contatto';

        const aaURL = process.env.REACT_APP_AA_YEAR.slice(-2);
        const adminUrl = 'https://mne'+aaURL+'.mnemosine-elearning.it/segreteria/frontEndContactRequest/';

        return (<>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
            </Helmet>
            <MSection className="my-4">
                <Title>{title}</Title>
                <BoxSuccess>Inserisci i tuoi dati e il momento in cui vuoi essere contattato. Sarai richiamato da un nostro operatore nella fascia oraria da te indicata.</BoxSuccess>
                <div>
                    <iframe title="richiesta di contatto" className="embed-responsive-item" src={adminUrl} width="100%" height="900" frameBorder="0" scrolling="no"></iframe>
                </div>
            </MSection>
        </>);
    }
}