import React from "react";
import { getCurrentUser } from 'store/actions';

import {
    MSelect,
    FormEnabler,
    ValidatedForm,
    MInput, MFile
} from "components/forms";

import {
    payloadBuilder,
    userService,
    dateHelper,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder
} from "libs";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import { DOC_TYPES, DOC_TYPES_MAP } from 'const';
import { MnemoCardTag } from "components/misc";

const FIELDS_GROUP = [
    [
        {
            id: "type",
            field: "type",
            label: "Tipo di documento",
            component: MSelect,
            payload: { options: DOC_TYPES },
            className: "col-md-6"
        },
        {
            id: "number",
            field: "number",
            label: "Numero documento",
            component: MInput,
            type: "text",
            className: "col-md-6"
        }
    ],
    [
        {
            id: "source",
            field: "source",
            label: "Rilasciato da",
            component: MInput,
            type: "text",
            className: "col-md-12"
        }
    ],
    [
        {
            id: "releaseDate",
            field: "releaseDate",
            label: "Rilasciato il",
            component: MInput,
            type: "date",
            className: "col-md-6"
        },
        {
            id: "expiryDate",
            field: "expiryDate",
            label: "Valido fino al",
            component: MInput,
            type: "date",
            className: "col-md-6"
        }
    ], [
        {
            id: "document",
            field: "document",
            label: "Documento di riconoscimento",
            infoText: "Caricare un documento di riconoscimento valido",
            component: MFile,
            config: { endPoint: '/my/profile/document', mandatory: true, fieldName: 'document', maxFiles: 2 },
            className: "col-md-12"
        }
    ]

];

export class UserDocument extends ValidatedForm {

    ERROR_MESSAGES = {
        type: "Tipo di documento non valido",
        number: "Numero documento non valido",
        source: "Valore non valido (minimo 3 caratteri)",
        releaseDate: "Data di rilascio non valida",
        expiryDate: "Data di scadenza non valida"
    };

    emptyFields = {
        type: '',
        number: '',
        source: '',
        releaseDate: '',
        expiryDate: '',
    };

    state = {
        isNew: false,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    validation = {
        type: value => DOC_TYPES.filter(t => t.value === value).length > 0,
        number: value => value !== null && value.length > 2,
        source: value => value !== null && value.length > 2,
        releaseDate: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
        expiryDate: value => /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value),
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    loadRemote(additionalState = {}) {
        userService
            .document()
            .then(({ data }) => {

                // merge not null values and format dates
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.document,
                    {
                        releaseDate: dateHelper.toUSDate(data.payload.document.releaseDate),
                        expiryDate: dateHelper.toUSDate(data.payload.document.expiryDate),
                        file: { idParent: data.payload.document.id } // set idParent of MFile component
                    }
                );

                const newState = {
                    isNew: (defaultValues.source === null || defaultValues.source.length === 0),
                    defaultValues,
                    ...additionalState
                };

                this.setState(newState);
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

            });
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);
        userService.updateDocument(payload)
            .then(() => {

                const validations = {
                    type: null,
                    number: null,
                    source: null,
                    releaseDate: null,
                    expiryDate: null,
                };

                this.setState({ formActive: false, ...validations, isNew: false });
            })
            .catch(errors => {

                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
    };

    showForm = () => {
        this.setState({ formActive: true });
    }

    renderInfo() {

        const { isNew, defaultValues } = this.state;
        const user = getCurrentUser();
        
        const cardProps = {
            content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
            actionLabel: 'Inserisci',
            tag: 'Documento di riconoscimento',
            smallicon: 'it-card',
            isReadOnly: user.readOnly,
            actionClick: this.showForm
        }

        if (isNew) {
            cardProps.actionLabel = 'Inserisci';

        } else {
            cardProps.actionLabel = 'Modifica';

            cardProps.content = (
                <>
                    <b>Tipo di documento:</b> {DOC_TYPES_MAP[defaultValues.type]}<br />
                    <b>Numero:</b> {defaultValues.number}<br />
                    <b>Rilasciato da:</b> {defaultValues.source}<br />
                    <b>Rilasciato il:</b> {dateHelper.toITDate(defaultValues.releaseDate)}<br />
                    <b>Valido fino al:</b> {dateHelper.toITDate(defaultValues.expiryDate)}<br />
                </>
            );
        }

        return (<>
            <MnemoCardTag {...cardProps}></MnemoCardTag>
        </>)
    }

    renderForm() {

        const { formActive, defaultValues, isNew } = this.state;
        const { formValid, code } = this.renderFields(FIELDS_GROUP, defaultValues, formActive);

        const user = getCurrentUser();

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-card" padding={false} />
                        <span>Documento di riconoscimento</span>
                    </div>
                    <CardBody tag="div">
                        <form className="user" id="userDocument_form">
                            {code}
                        </form>
                        <div >
                            <FormEnabler
                                isNew={isNew}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                disableSave={!formValid}
                                hideEdit={user.readOnly}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    render() {

        const { formActive } = this.state;
        if (formActive) {
            return this.renderForm();
        } else {
            return this.renderInfo();
        }

    }
}