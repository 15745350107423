import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { ticketService, dateHelper, networkErrorHelper } from "libs";
import { ROUTES } from "const";
import { CourseList, CourseTicketsForm } from "components/course";
import CourseTicketDetail from 'views/course/CourseTicketDetail';

import {
  BoxSuccess, MnemoLoading,
  Title, MSection
} from "components/misc";

import {
  Container, Icon,
  Row, Col, Button,
  Callout, CalloutTitle
} from 'design-react-kit';

class CourseTickets extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formTicket: false,
      ticketList: {}
    };

    //this.MnemoCardSlimRefs = [];
  }


  state = {
    rCode: 0,
    loading: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {

    const { rCode } = nextProps.match.params;
    if (this.state.rCode !== rCode) {
      this.setState({ rCode: rCode });
      this.loadRemote(rCode);
    }

  }

  componentDidMount() {

    // get course code from parameter
    const { rCode } = this.props.match.params;
    this.setState({ rCode: rCode });

    if (rCode) {
      this.loadRemote(rCode);
    } else {
      this.setState({ myTickets: [], loading: false });
    }
  }

  addTicket = (rCode) => {
    this.setState({
      formTicket: true
    })
  }//addTicket

  loadRemote(rCode) {
    ticketService
      .myTickets(rCode)
      .then(({ data }) => {

        const newState = data.payload.map((ticket, i) => {
          return {
            title: ' Inviato il '
              + dateHelper.toITDateTime(ticket.dateCreation)
              + ' (' + (ticket.status === 'aperto' ? 'in attesa di risposta' : 'risposta ricevuta') + ')',
            subject: ticket.subject,
            body: ticket.body,
            showBody: false,
            style: (ticket.status === 'aperto' ? 'mnemo-callout-warning my-2' : 'mnemo-callout-success my-2'),
            actionParam: ticket.id,
            toggle: false//((ticket.status === 'chiuso' && i===0) ? true : false)
          };
        })

        this.setState({ myTickets: newState, loading: false });

      })
      .catch(error => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  }

  //--- ---
  formTicketShow = () => {
    this.setState({
      formTicket: false
    })
  }//formTicketShow

  refresh = () => {
    window.location.reload(true);
  }//refresh

  onDetail = (idx, ticketId) => {
    //const filtered =  this.MnemoCardSlimRefs.filter(item => item.props.actionParam === ticketId);
    //console.log(filtered)

    this.setState(state => {
      state.myTickets[idx].toggle = !this.state.myTickets[idx].toggle;
      state.myTickets[idx].showBody = !this.state.myTickets[idx].showBody;
      return state;
    });

  }//onDetail

  render() {

    const { loading, myTickets, rCode } = this.state;

    return (
      <>
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            <Container>
              {(this.state.formTicket) ?
                <CourseTicketsForm myTicketsRefresh={this.refresh} rCode={rCode} formTicketShow={this.formTicketShow} />
                : <>

                  {(rCode && rCode.length > 0) ?
                    (
                      <>
                        <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                        <Title>Richieste assistenza</Title>
                        <MSection className="my-2">
                          <Row >
                            <Col sm={9}>
                              <p>Da quest'area puoi gestire le tue richieste di assistenza, inoltrarne di nuove e controllare le risposte.</p>
                            </Col>
                            <Col sm={3}>
                              <Button className="float-right" color="primary bg-dark" onClick={() => this.addTicket(rCode)}>Nuova richiesta</Button>
                            </Col>
                          </Row>
                        </MSection>
                        {myTickets ? myTickets.map((ticket, i) => (
                          <Row key={`ticket-${i}`}>
                            <Col size="12">
                              <div className="wrapper-link rounded shadow" onClick={event => { this.onDetail(i) }}>
                                <Callout className={ticket.style} highlight tag="div">
                                  <CalloutTitle tag="h5">
                                    <Row>
                                      <Col xs={11}><h6>{ticket.title}</h6></Col>
                                      <Col xs={1}><Icon icon='it-arrow-right-circle' /></Col>
                                    </Row>
                                  </CalloutTitle>
                                  {ticket.subject}
                                  {(ticket.toggle) ?
                                    (<>
                                      <hr />
                                      <p><b>Richiesta: </b> {ticket.body}</p>
                                      <hr />
                                      <CourseTicketDetail rCode={this.state.rCode} ticketid={ticket.actionParam} /></>) : null}
                                </Callout>
                              </div>
                            </Col>
                          </Row>
                        )) : <BoxSuccess>Non ci sono richieste di assistenza</BoxSuccess>}

                      </>
                    ) : (
                      <>
                        <Title>Richieste assistenza</Title>
                        <MSection className="my-2">Seleziona il corso a cui la richiesta si riferisce</MSection>
                        <CourseList actionLink={ROUTES.COURSE_TICKETS}></CourseList>
                      </>)
                  }
                </>}
            </Container>
          </>)}
      </>
    );
  }
}

export default withRouter(CourseTickets);