import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { login } from "store/actions";
import { ROUTES } from "const";
import { BoxDanger, MSection, Title } from "components/misc";

import { MInput } from "components/forms/shared/MInput";
import { Button } from "design-react-kit";
import { Helmet } from "react-helmet";

class Login extends Component {
  state = {
    email: null,
    password: null,
  };

  onChange = (field, value) => {
    this.setState({ [field]: value });
  };

  submit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    // Redirect After login
    const redirectUrl = this.generateRedirectUrl();

    this.props.login(email, password, redirectUrl);
  };

  generateRedirectUrl() {
    if (this.props.location && !this.props.location.state) return null;
    const { from } = this.props.location.state;
    return from;
  }

  render() {
    const { email, password } = this.state;
    const isValid = email && password && email.length + password.length > 6;

    return (
      <>
        <Helmet>
          <title>Mnemosine Login</title>
          <meta property="og:title" content="Mnemosine Login" />
        </Helmet>
        <Title>Accedi alla tua area riservata</Title>
        <MSection>
          {this.props.msgLogin && (
            <BoxDanger key="nrp-1">
              Dati di Accesso non validi.
              <div className="small text-info">
                se il problema persiste, contatta il servizio assistenza.
              </div>
              <hr />
              <div className="small text-info">
                Se sei iscritto all'anno accademico 2020/2021 o ad anni
                accademici precedenti allora
                <a
                  href="https://www.mnemosine-elearning.it/login/index.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="">
                  {" "}
                  clicca qui
                </a>
              </div>
            </BoxDanger>
          )}
          <form className="login mt-3">
            <div className="form-group">
              <MInput
                type="email"
                name="email"
                id="email"
                label="Email"
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <MInput
                type="password"
                name="password"
                label="Password"
                id="password"
                onChange={this.onChange}
              />
            </div>

            <div className="py-4">
              <Button
                color="primary bg-dark"
                disabled={!isValid}
                onClick={this.submit}>
                Accedi
              </Button>
            </div>
            <p>
              Se non hai ancora creato un account,{" "}
              <Link to={ROUTES.REGISTER}>Registrati</Link> adesso
            </p>
            <p>
              Se invece hai dimenticato la password, puoi{" "}
              <Link to={ROUTES.PASSWORD_RESET}>Reimpostarla</Link>.
            </p>
            <p>
              Se sei iscritto all'anno accademico 2020/2021 o agli anni
              accademici precedenti allora
              <a
                href="https://www.mnemosine-elearning.it/login/index.php"
                target="_blank"
                rel="noopener noreferrer"
                className="">
                {" "}
                clicca qui
              </a>
            </p>
          </form>
        </MSection>
      </>
    );
  }
}

const stateToProps = ({ app }) => {
  return {
    msgLogin: app.error,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    login(email, password, redirectUrl) {
      dispatch(login(email, password, redirectUrl));
    },
  };
};

export default connect(stateToProps, dispatchToProps)(Login);
