import React, { Component } from "react";
import { ROUTES } from "const";
import { Link } from "react-router-dom";

import "./CategoryCard.scss";

export class CategoryCard extends Component {
  render() {
    const { category } = this.props;

    if (!category) {
      return null;
    }

    return (
      <Link
        className="categoryCard"
        to={{
          pathname: `${ROUTES.PAGE_BIBLIOTECA}/categorie/${category.slug}`,
          state: category,
        }}
      >
        <div className="categoryCard__image">
          {/* image placeholder if not exist */}
          <img
            src={
              category.coverCardUrl
                ? category.coverCardUrl
                : "/img/biblioteca/cover-category.webp"
            }
            alt={category.label}
            width="300"
            height="200"
          />
        </div>
        {/* content title */}
        <div className="categoryCard__content">{category.name}</div>
      </Link>
    );
  }
}
