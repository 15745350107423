import React, { Component } from "react";
import { ROUTES } from "const";
import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

import "./Hero.scss";

const DATA_HERO = {
  title: "Biblioteca del Docente. <br/>I testi per la scuola, a portata di click",
  subtitle:
    "<strong>Sei sempre alla ricerca dei testi giusti per i tuoi studenti e non sai mai dove trovarli e scaricarli?</strong> <br/>Con la nostra biblioteca on-line, adesso, hai libero accesso a un catalogo di titoli selezionati pensando proprio alle esigenze dei docenti di istruzione primaria e secondaria. Sfoglia il catalogo per vedere se c’è ciò che stai cercando. Un attimo però, sei già registrata/o  al nostro sito? Ricorda che per scaricare gratuitamente i nostri testi devi avere un account personale. Inizia subito, è facile e veloce!",
  cta_label: "Lorem ipsum",
  cta_link: "#",
  background: "/img/biblioteca/background.webp",
};

export class HeroBiblioteca extends Component {
  render() {
    return (
      <>
        <div className="heroBiblioteca">
          <div className="heroBiblioteca__background">
            <img src={DATA_HERO.background} alt={DATA_HERO.title}></img>
          </div>
          <div className="heroBiblioteca__content">
            <div className="logo">
              <img
                src="/img/mnemo/logo_main.png"
                alt="logo associazione mnemosine"
                width="280"
                height="90"
              />
            </div>
            <h1 className="heroBiblioteca__title" dangerouslySetInnerHTML={{__html: DATA_HERO.title}} />
            {/* <p className="heroBiblioteca__subtitle">{DATA_HERO.subtitle}</p> */}
          </div>
        </div>
        <div className="introBiblioteca">
          <div className="introBiblioteca__wrapper">
            <p dangerouslySetInnerHTML={{ __html: DATA_HERO.subtitle }} />
            <div className="introBiblioteca__buttons">
              <div className="introBiblioteca__buttons--login">
                <ButtonPrimary href={`${ROUTES.LOGIN}`} label="Accedi" />
              </div>
              <div className="introBiblioteca__buttons--expolore">
                <ButtonPrimary
                  href={`${ROUTES.PAGE_BIBLIOTECA}/categorie/`}
                  color="yellow"
                  label="Esplora le categorie"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HeroBiblioteca;
