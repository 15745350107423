import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
    Container,
} from 'design-react-kit';
import { Helmet } from 'react-helmet';

class NotFoundPage extends Component {

    goPrev = () => {
        // go back two pages              
        this.props.history.go(-2);
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta name="googlebot" content="noindex" />
                </Helmet>
                <section className="my-4">
                    <Container>
                        <div className="ErrorPage mx-auto text-center">
                            <h1 className="ErrorPage-title">404</h1>
                            <h2 className="ErrorPage-subtitle">Pagina non trovata</h2>
                            <p>Oops! La pagina che cerchi non è stata trovata,
                                <Link to="#" onClick={this.goPrev} > torna indietro </Link>
                                o utilizza il menu per continuare la navigazione.</p>
                            <Link className="btn btn-primary bg-dark" to="/"> Torna alla Home </Link>
                        </div>
                    </Container>
                </section>
            </>
        );
    }
}

export default withRouter(NotFoundPage);