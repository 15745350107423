import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import {
    dateHelper,
    isEmptyObject,
} from "libs";

export class UserDetailQualification extends Component {

    render() {

        if (!this.props.data) return false;
        const data = this.props.data;

        /** qualification detail */

        //--- school ---
        let school = '<h6>Scuola Superiore:</h6><div> nessun dato inserito</div><hr/>';
        if (!isEmptyObject(data.school)) {
            school = `
            <h6><b>Scuola Superiore:</b></h6>
            <div><b>Anno Scolastico conseguimento titolo:</b> ${data.school.aa}</div>
            <div><b>Istituto:</b> ${data.school.istitution}</div>
            <div><b>Codice Istituto:</b> ${data.school.schoolCode || ''}</div>
            <div><b>Città:</b> ${data.school.city}</div>
            <div><b>Nazione:</b> ${data.school.nation}</div>
            <div><b>Titolo:</b> ${data.school.description}</div>
            <div><b>Voto:</b> ${data.school.vote}</div>
            <div><b>Data conseguimento:</b> ${dateHelper.toITDate(data.school.dateAchievement)}</div>
            <hr/>`;
        }//school

        //--- uniEnrollment ---
        let uniEnrollment = '<h6>Prima Immatricolazione:</h6><div> nessun dato inserito</div><hr/> ';
        if (!isEmptyObject(data.uniEnrollment)) {
            uniEnrollment = `
            <h6><b>Prima Immatricolazione:</b></h6>
            <div><b>Anno Accademico:</b> ${data.uniEnrollment.aa}</div>
            <div><b>Istituto:</b> ${data.uniEnrollment.istitution}</div>
            <hr/>`;
        }//uniEnrollment

        //--- university ---
        let university = '<h6>Laurea:</h6><div> nessun dato inserito</div><hr/> ';
        if (!isEmptyObject(data.university)) {
            university = `
            <h6><b>Laurea:</b></h6>
            <div><b>Immatricolazione:</b> ${dateHelper.toITDate(data.university.dateSignup)}</div>
            <div><b>Anno Accademico conseguimento titolo :</b> ${data.university.aa}</div>
            <div><b>Istituto:</b> ${data.university.istitution}</div>
            <div><b>Città:</b> ${data.university.city}</div>
            <div><b>Tipo Laurea:</b> ${data.university.laureaType}</div
            <div><b>Titolo:</b> ${data.university.description}</div>
            <div><b>Voto:</b> ${data.university.vote}</div>
            <div><b>Lode:</b> ${(data.university.lode) ? 'SI' : 'NO'}</div>
            <div><b>Data conseguimento:</b> ${dateHelper.toITDate(data.university.dateAchievement)}</div>
            <hr/>`;
        }//uniEnrollment


        const qualificationDetail = school + uniEnrollment + university;

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Titoli di Studio</span>
                    </div>
                    <CardBody>{ReactHtmlParser(qualificationDetail)}</CardBody>
                </Card>
            </>
        );
    }

}

