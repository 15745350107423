import React from "react";
import { EnrollPayPagoPaForm } from "./EnrollPayPagoPaForm";

export class EnrollVirtualStamp {

    title = 'Bollo Virtuale';
    iconName = 'it-card';
    userProfile = null;
    enrollment = null;
    payment = null;
    course = null;
    canNext = false;
    _formRef = null;

    constructor() {

        this._formRef = React.createRef();
    }

    setPayload(payload) {
        this.enrollment = payload.enrollment;
        this.course = payload.course;
        this.user = payload.user;
        this.userProfile = payload.userProfile;
        this.payment = payload.payment;
    }

    async onNextStep() {

        this.canNext = this._formRef.current.canNext();
        return this.canNext;
    }

    async updateRemote() {

        let retCode = false;

        return retCode;
    };

    render() {

        const enrollData =
        {
            datePayment: null,
            orderNumber: this.enrollment.orderNumber,
            id: this.enrollment.id
        }

        return (
            <>
                <EnrollPayPagoPaForm
                    ref={this._formRef}
                    onSaveRemote={this.onSaveRemote}
                    enrollData={enrollData} />
            </>);
    }
}