import React, { Component } from "react";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

export class UserDetailTarget extends Component {

    render() {
           
        const { workingStatus } = this.props.data;
       
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Profilo</span>
                    </div>
                    <CardBody>
                        <div><b>Stato lavorativo:</b> {workingStatus}</div>
                    </CardBody>
                </Card>
            </>
        );
    }

}
