import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const icdlService = {    

    getAICACodes(rCode) {
        const payload = mnemoService.buildPayload('codes', 'rCode', rCode);
        return proxyApiMnemo.get('/icdl/?payload='+encodeURI(payload));
    },       
    
}