import React, { Component } from "react";
import { formHelper } from 'libs/helpers';
import {
    smsService,
    dateHelper,
    networkErrorHelper
} from "libs";

import { Button, Col, Row } from "design-react-kit";
import { MInput } from "components/forms";

export class MobileValidation extends Component {

    state = {
        loading: true,
        isWaitingConfirm: false,
        isToSend: true,
        sms: null
    }

    componentDidMount() {
        this.loadSMS();
    }

    loadSMS() {

        const { action, tableName, idParent, phoneNumber } = this.props;
        smsService
            .get(action, tableName, idParent, phoneNumber)
            .then(({ data }) => {

                this.updateFromSMS(data.payload.sms);
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false });
            });
    }

    updateFromSMS(sms) {

        let isWaitingConfirm = false;
        let isToSend = true;

        if (sms) {
            if (sms.codeConfirmed && sms.dateConfirmed) {
                isToSend = false;
                isWaitingConfirm = false;
            } else {
                isToSend = false;
                isWaitingConfirm = true;
            }
        }

        this.setState({ loading: false, sms, isWaitingConfirm, isToSend });
    }

    sendCode = () => {
        const { action, tableName, idParent, phoneNumber } = this.props;
        smsService
            .send(action, tableName, idParent, phoneNumber)
            .then(({ data }) => {

                this.updateFromSMS(data.payload.sms);

                if (formHelper.isFunction(this.props.onSendSMS)) {
                    this.props.onSendSMS(this.props.idParent);
                }               
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                // TODO: notify error
            });
    }

    confirmCode = () => {

        const { valid, codeConfirmed, sms } = this.state;
        if (valid) {
            smsService
                .confirm(sms.id, codeConfirmed)
                .then(({ data }) => {

                    this.updateFromSMS(data.payload.sms);

                    // notify to parent
                    if (formHelper.isFunction(this.props.onConfirmed)) {
                        this.props.onConfirmed(this.props.idParent);
                    }
                })
                .catch(error => {
                    if (!networkErrorHelper.is404(error)) {
                        networkErrorHelper.notify(error);
                    }

                    // TODO: notify error
                });
        }

    }

    onCodeChange = (id, code) => {

        let valid = (code === this.state.sms.codeCreated.toString());
        this.setState({ codeConfirmed: code, valid: valid, invalid: !valid });
    }

    confirmedCodeForm = () => {

        const { sms } = this.state;
        return (
            <>
                <Row>
                    <p>L'operazione di conferma del codice si è conclusa con successo.</p>
                </Row>
                <Row>
                    <ul>
                        <li><b>Numero telefono:</b> {sms.phoneNumber}</li>
                        <li><b>Data:</b> {dateHelper.toITDate(sms.dateConfirmed)}</li>
                        <li><b>Ora:</b> {sms.timeConfirmed}</li>
                    </ul>
                </Row>
            </>);
    }

    toConfirmCodeForm = () => {

        const { phoneNumber, canEdit = true } = this.props;

        const { valid, invalid } = this.state;

        let inputAttr = {};
        let bntnAttr = {};

        if (valid) {
            inputAttr.valid = 'true';
        } else {
            bntnAttr.disabled = true;
        }

        if (invalid) {
            inputAttr.invalid = 'true';
        }

        return (
            <>
                <Row>
                    <p>Inserisci il codice a 6 cifre che hai ricevuto
                        sul tuo cellulare con numero <b>{phoneNumber}</b>.
                        {canEdit && (<>
                            Se il numero è errato allora <a href="/my/profile" >modifica il tuo profilo</a>
                        </>)}
                    </p>
                </Row>
                <Row className="mb-4">
                    <Col size="12" sm={12} lg={6}>
                        <MInput
                            id="code" label="codice"
                            {...inputAttr}
                            infoText="Inserisci il codice a sei cifre ricevuto tramite sms"
                            onChange={this.onCodeChange}
                        ></MInput>
                    </Col>
                    <Col className="text-right" size="12" sm={12} lg={6}>
                        <Button {...bntnAttr} color="primary bg-dark" tag="button" onClick={this.confirmCode} >Conferma Codice</Button>
                    </Col>
                </Row>
            </>);
    }

    toSendForm = () => {

        const { phoneNumber, canEdit = true, isEnabled = true, buttonText= 'Invia Codice' } = this.props;

        return (
            <>
                {(isEnabled) ? (<>
                    <p>Clicca sul tasto <b>{buttonText}</b> e invieremo
                        un sms con un codice a 6 cifre  al tuo cellulare
                        con  numero <b>{phoneNumber}</b>.
                        {(canEdit) && (<>
                            Se il numero è errato allora <a href="/my/profile" >modificalo dal tuo profilo</a>.
                        </>)}
                    </p>
                    <div className="text-right">
                        <Button color="primary bg-dark" tag="button" onClick={this.sendCode} >{buttonText}</Button>
                    </div>
                </>) : (<>
                <h6>Momentaneamente disabilitato</h6>
                </>)}
            </>
        );
    }

    render() {

        const { isWaitingConfirm, isToSend, loading } = this.state;
        if (loading) return (<></>);

        if (isToSend) {
            return this.toSendForm();
        } else if (isWaitingConfirm) {
            return this.toConfirmCodeForm();
        } else {
            return this.confirmedCodeForm();
        }
    }
}