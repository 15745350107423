import React, { Component } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter as Router } from "connected-react-router";

import { initStore, history } from "./store";
import Main from "./Main";

import { statCookie } from "libs/helpers/cookieHelper";
import { withTranslation } from "react-i18next";

const store = initStore();

class App extends Component {
  render() {
    statCookie();
    return (
      <Provider store={store}>
        <Router history={history}>
          <Main />
        </Router>
        <div id="modal-notification"></div>        
      </Provider>
    );
  }
}

export default withTranslation()(App);
