import { push } from 'connected-react-router';
import { setSession } from 'libs/api';
import { userService, toolService, cache, keepAliveWorker } from 'libs';
import { CACHE_KEYS } from 'const';

export const LOADING_STARTED = 'loading_started';
export const LOADING_FINISHED = 'loading_finished';

export const LOGGED_IN = 'logged_in';
export const LOGGED_OUT = 'logged_out';
export const LOGGED_FAILED = 'logged_failed';

export const SETUP_MAINTENANCE_MODE = 'setup_maintenance_mode';
export const CHANGE_VIEW = 'change_view';

export const preRenderChecks = () => {
    return dispatch => {
        Promise.all([
            checkExistingSession(dispatch),
            //checkMaintenanceMode(dispatch), //TODO riattivare check manutenzione
            keepAlive(dispatch)
        ]).then(() => dispatch(loadingFinished()));
    };
};

export const  keepAlive = dispatch => {
    const user = getCurrentUser();
    if (user){
        // send kepp alive without courseCode
        keepAliveWorker.keep(window.location.pathname,
            window.location.search);        
    }
}

export const checkExistingSession = dispatch => {
    const existingSession = cache.get(CACHE_KEYS.SESSION);
    if (existingSession) {
        const { token } = existingSession;
        setSession(token);
        return userService.me().then(({ data }) => {
            const { user } = data.payload;
            dispatch(loggedIn(user, token));
        }).catch(error => {
            console.error('INVALID TOKEN', { error });
            dispatch(loggedOut());
        });
    }
    return Promise.resolve();
};

export const checkMaintenanceMode = dispatch => {
    return toolService.isMaintenance()
        .then(({ data }) => {
            const { isMaintenance, message } = data.payload;
            dispatch(setupMaintenanceMode(isMaintenance, message));
        })
        .catch(error => {
            console.error('maintenance', { error });
            //TODO: check what to do in the case of 
            // maintenance endpoint broken, maybe set 
            // it to be on maintenance?
        });
};

export const getCurrentUser = () => {
    const existingSession = cache.get(CACHE_KEYS.SESSION);
    if (existingSession) {
        const { user } = existingSession;
        return user;
    }

    return null;
};

export const login = (email, password, redirectUrl) => {
    return dispatch => {
        userService.login({ email, password }).then(({ data }) => {
            const { user, token } = data.payload;
            dispatch(loggedIn(user, token));

            // if a Redirect is set           
            if(redirectUrl){
                dispatch(push(redirectUrl));
            } 
        }).catch(error => {
            dispatch(loggedInFailed());
            console.error(error);
        });
    };
};

export const loginByUserId = (userId, redirectUrl) => {
    return dispatch => {
        userService.loginByUserId({ userId }).then(({ data }) => {
            const { user, token } = data.payload;
            dispatch(loggedIn(user, token));

            // if a Redirect is set
            redirectUrl && dispatch(push(redirectUrl));
        }).catch(error => {
            dispatch(loggedInFailed(error));
            console.error(error);
        });
    };
};

export const setupMaintenanceMode = (isMaintenance, message) => {
    return {
        type: SETUP_MAINTENANCE_MODE,
        data: {
            isMaintenance,
            message
        }
    };
};


export const loggedInFailed = () => {
    return {
        type: LOGGED_FAILED,
        data: {
            error: true
        }
    };
};

export const loggedIn = (user, token) => {
    setSession(token);
    cache.set(CACHE_KEYS.SESSION, { user, token });
    return {
        type: LOGGED_IN,
        data: {
            user,
            token,
            error: null
        }
    };
};

export const loggedOut = () => {
    cache.forget(CACHE_KEYS.SESSION);
    return {
        type: LOGGED_OUT
    };
};


export const loadingStarted = () => {
    return {
        type: LOADING_STARTED
    };
};

export const loadingFinished = () => {
    return {
        type: LOADING_FINISHED
    };
};

