import { ROUTES } from "const";
import { courseService } from "libs/api";

export class RCSU {

    static async getDashboardCards(registrationCode) {

        let cards = this.getFixedCards();

        try {
            const result = await courseService.perfezionamentoActiveLink(registrationCode);
            if (result.data.payload) {
                const { payload } = result.data;
                if (payload.writtenReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami',
                        link: ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION, // ROUTES.COURSE_EXAM_WRITTEN_RESERVATION,
                        img: '/img/icon/badge.png',
                        className: 'image-box'
                    });
                }
            }

        } catch (error) {

        }

        return cards;
    }

    static getFixedCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.RCSU_LESSON,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Istruzioni Operative per la prova finale', link: ROUTES.COURSE_ISTRUCTION,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Bando', link: ROUTES.MU_COURSE_DOCS,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            },
        ];
    }

    static getDashboardNotices() {

    }
}
