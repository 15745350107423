import React, { Component } from 'react';

import { Card, CardBody } from 'design-react-kit';

export class MSection extends Component {

    render() {

        let className = "card-bg";
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        return (
            <>
                <Card className={className} tag="div">
                    <CardBody tag="div">
                        {this.props.children}
                    </CardBody>
                </Card>
            </>
        );
    }
}