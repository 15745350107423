import { ROUTES } from "const";

export class ICDL {

    static getDashboardCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.ICDL_LESSON,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Bando', link: ROUTES.COURSE_MANIFESTO,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            }, {
                title: 'Certificazioni conseguite', link: ROUTES.ICDL_CERTIFICATION,
                img: '/img/icon/badge.png', className: 'image-box'
            } 
            ,{
                title: 'Prenotazione esami', link: ROUTES.ICDL_EXAM_RESERVATION,
                img: '/img/icon/7.webp', className: 'image-box'
            }
        ];
    }
}