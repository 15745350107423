import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class CorsiSingoliCSURCSU extends Component {

    render() {
        const title = 'Corsi singoli';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <Row>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <div className="swiper-slide my-2">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corso-online-mnemosine/CSUUNICAMILLUS">
                                                <div className="programme-card__header">
                                                    <img className="programme-card__img" alt="Corsi singoli universitari" src="/img/courses/new/plu.jpg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <h4 className="programme-card__title"> Corsi Singoli Universitari <br /><span class="small">a.a. 2024/2025</span></h4>
                                                    <h6 className="text-highlights">Università Unicamillus</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={4}>
                                    <div className="swiper-slide my-2">
                                        <div className="programme-card">
                                            <a className="programme-card__link" href="../corso-online-mnemosine/CSUREGGIO">
                                                <div className="programme-card__header">
                                                    <img className="programme-card__img" alt="Corsi singoli universitari" src="/img/courses/new/pd.jpg" />
                                                </div>
                                                <div className="programme-card__body">
                                                    <h4 className="programme-card__title"> Corsi Singoli Universitari <br /><span class="small">a.a. 2023/2024</span></h4>
                                                    <h6 className="text-highlights">Università Dante Alighieri</h6>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}