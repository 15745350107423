import React, { Component } from "react";
import { Title } from "components/misc";
import { Container, Row, Col, Button } from 'design-react-kit';

import { AdminCourseFormQuiz, 
         AdminCourseFormQuizQuestion,
         AdminRepoQuizForm
} from "components/forms/admin";

import { withRouter } from 'react-router-dom';


class RepoQuizForm extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      lengthQuestion: 0,
      idRepoQuiz: parseInt(this.props.match.params.idRepoQuiz) > 0 ? this.props.match.params.idRepoQuiz : 0,
      accordionKey: 0
    }
  }

  //--- ---

  addQuestion = (answerType) =>{
    this.child.current.addQuestion(answerType); //use ref function from child
  }

  reloadCounter = (length) =>{
    this.setState({
      lengthQuestion: length
    })
  }

  reloadAccordion = () =>{
    this.setState({
      accordionKey: Math.floor((Math.random() * 1000) + 1)
    })
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>Gestione guidata Deposito delle Domande</Title>

            <AdminRepoQuizForm idRepoQuiz={this.state.idRepoQuiz} />

            <Row className="header-quiz-composer border border-top-0 border-right-0 border-left-0 border-primary rounded pb-4 mx-0 bg-white">
                <div className="col-lg-12 col-md-12 text-center my-3">
                  <h6>{(this.state.lengthQuestion === 0) ? 'PRIMA DOMANDA?' : 'AGGIUNGI UN ALTRA DOMANDA'}</h6></div>
                
                <Col className="text-right px-1">                        
                    <Button id="radio" color="primary bg-dark" onClick={(e) => { this.addQuestion(e.target.id) }}>Risposta Singola</Button>
                </Col>
                <Col className="text-center px-1">
                    <Button id="check" color="primary bg-dark" onClick={(e) => { this.addQuestion(e.target.id) }}>Risposta Multipla</Button>
                </Col>
                <Col className="text-left px-1">
                    <Button id="text" color="primary bg-dark" onClick={(e) => { this.addQuestion(e.target.id) }}>Risposta Aperta</Button>
                </Col>
            </Row>

            <AdminCourseFormQuizQuestion key={`acfqq-1`} model="repoQuiz" ref={this.child} idRepoQuiz={this.state.idRepoQuiz} reloadAccordion={this.reloadAccordion} formActive={true} />
            <AdminCourseFormQuiz key={this.state.accordionKey} model="repoQuiz" uriParams={this.props.match.params} reloadCounter={this.reloadCounter} />

          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(RepoQuizForm);
