
import {
    userService,
    networkErrorHelper
} from "libs";

let timespan = [];

export class keepAliveWorker {

    static getDuration(rCode) {

        let retCode = 0;
        if (timespan[rCode] !== undefined) {
            retCode = timespan[rCode];
        }              
        return retCode;
    }

    static keep(url, info = '', rCode = '') {

        if (!rCode || rCode === undefined) {
            rCode = 'NONE';
        }

        // get from status current url
        userService.keepAlive(url, info, rCode)
            .then(({ data }) => {

                timespan[rCode] = data.payload.timespan;
            })
            .catch(error => {                
                if (networkErrorHelper.is401(error)) {
                    // logout                      
                    window.location= '/login';               
                }else{
                    networkErrorHelper.notify(error);
                }

                
            })
    }
}
