import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';

import {
    isEmptyObject,
} from "libs";


export class UserDetailAddress extends Component {


    render() {

        if (!this.props.data) return false;

        const {bornAddress, residenceAddress, deliveryAddress} = this.props.data;

        /** address detail */

        //--- born address ---
        let bornAddressLabel = 'non ancora inserito';
        if (!isEmptyObject(bornAddress)) {
            if (!bornAddress.isForeign) {
                bornAddressLabel = `
                <h6><b>Luogo di nascita:</b></h6>
                <div><b>Indirizzo:</b> ${bornAddress.address}</div>
                <div><b>Civico:</b> ${bornAddress.houseNumber}</div>
                <div><b>CAP:</b> ${bornAddress.cap}</div>
                <div><b>Città:</b> ${bornAddress.city}</div>
                <div><b>Provincia:</b> ${bornAddress.province}</div>
                <div><b>Note:</b> ${bornAddress.note}</div>
                <hr/>`;
            } else {
                bornAddressLabel = `
                <h6><b>Luogo di nascita:</b></h6>
                <div><b>Indirizzo:</b> ${bornAddress.address}</div>
                <div><b>Civico:</b> ${bornAddress.houseNumber}</div>
                <div><b>CAP:</b> ${bornAddress.zip}</div>
                <div><b>Città:</b> ${bornAddress.cityForeign}</div>
                <div><b>Località:</b> ${bornAddress.locally}</div>
                <div><b>Nazionalità:</b>${bornAddress.nation}</div>
                <div><b>Note:</b> ${bornAddress.note}</div>
                <hr/>`;
            }
        } //bornAddressId

        //--- address ---
        let addressLabel = 'non ancora inserito';
        if (!isEmptyObject(residenceAddress)) {
            if (!residenceAddress.isForeign) {
                addressLabel = `
                <h6><b>Residenza:</b></h6>
                <div><b>Indirizzo:</b> ${residenceAddress.address}</div>
                <div><b>Civico:</b> ${residenceAddress.houseNumber}</div>
                <div><b>CAP:</b> ${residenceAddress.cap}</div>
                <div><b>Città:</b> ${residenceAddress.city}</div>
                <div><b>Provincia:</b> ${residenceAddress.province}</div>
                <div><b>Note:</b> ${residenceAddress.note}</div>
                <hr/>`;
            } else {
                addressLabel = `
                <h6><b>Residenza:</b></h6>
                <div><b>Indirizzo:</b> ${residenceAddress.address}</div>
                <div><b>Civico:</b> ${residenceAddress.houseNumber}</div>
                <div><b>CAP:</b> ${residenceAddress.zip}</div>
                <div><b>Città:</b> ${residenceAddress.cityForeign}</div>
                <div><b>Località:</b> ${residenceAddress.locally}</div>
                <div><b>Nazionalità:</b>${residenceAddress.nation}</div>
                <div><b>Note:</b> ${residenceAddress.note}</div>
                <hr/>`;
            }
        } //addressId

        //--- delivery address ---
        let deliveryAddressLabel = 'non ancora inserito';
        if (!isEmptyObject(deliveryAddress)) {
            if (!deliveryAddress.isForeign) {
                deliveryAddressLabel = `
                <h6><b>Domicilio</b></h6>
                <div><b>Indirizzo:</b> ${deliveryAddress.address }</div>
                <div><b>Civico:</b> ${deliveryAddress.houseNumber}</div>
                <div><b>CAP:</b> ${deliveryAddress.cap}</div>
                <div><b>Città:</b> ${deliveryAddress.city}</div>
                <div><b>Provincia:</b> ${deliveryAddress.province}</div>
                <div><b>Note:</b> ${deliveryAddress.note}</div>
                <hr/>`;
            } else {
                deliveryAddressLabel = `
                <h6><b>Domicilio</b></h6>
                <div><b>Indirizzo:</b> ${deliveryAddress.address}</div>
                <div><b>Civico:</b> ${deliveryAddress.houseNumber}</div>
                <div><b>CAP:</b> ${deliveryAddress.zip}</div>
                <div><b>Città:</b> ${deliveryAddress.cityForeign}</div>
                <div><b>Località:</b> ${deliveryAddress.locally}</div>
                <div><b>Nazionalità:</b>${deliveryAddress.nation}</div>
                <div><b>Note:</b> ${deliveryAddress.note}</div>
                <hr/>`;
            }
        }//deliveryAddressId

        const addressDetail = bornAddressLabel + addressLabel + deliveryAddressLabel;

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Indirizzi</span>
                    </div>
                    <CardBody>{ReactHtmlParser(addressDetail)}</CardBody>
                </Card>
            </>
        );
    }

}
