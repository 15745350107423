import React, { Component } from "react";

import { MnemoLoading, MSection } from "components/misc";
import { Button } from "design-react-kit";
import { adminService } from "libs";

export class RemoteLoadWait extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      label: 'TEST CHIAMATA REMOTA CON SIMULAZIONE LATENZA',
      btnClass: 'btn btn-danger btn-lg'
    };
  }

  remoteCall = async () => {

    this.setState({
        loading: true,
        label: '...sto aspettando',
        btnClass:'btn btn-warning btn-lg'
    })

    /*
     * avvio chiamata remota
     * tolgo il loader solo quando termina
     * il proprio job
    */
    const call1 = adminService.remotetestloadwait();

    const jobs = [call1];

    Promise.all(jobs).then((res) => {
        if(res){
            
            this.setState({
                loading: false,
                label: 'Ho aspettato chiamata remote async',
                btnClass:'btn btn-success btn-lg'
            })
            
            setTimeout(() => {
                this.setState({
                    label: 'TEST CHIAMATA REMOTA CON SIMULAZIONE LATENZA',
                    btnClass: 'btn btn-danger btn-lg'
                })                
            }, 6000);
        }
    });

  };

  //--- ---

  render() {

    const { loading, label, btnClass } = this.state;

    return (
      <>
        {loading && <MnemoLoading></MnemoLoading>}

        <MSection>
          <Button
            className={btnClass}
            onClick={() => {
              this.remoteCall();
            }}
          >
            {label}
          </Button>
        </MSection>
      </>
    );
  }
}
