import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MInput
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
} from "libs";

import { Card, CardBody } from "design-react-kit";

export class AdminLessonLiveEditionForm extends ValidatedForm {
  ERROR_MESSAGES = {
    label: "Titolo obbligatorio",
    idExamSession: "ID Sessione Esame obbligatorio (intero)",
  };

  emptyFields = {
    title: "",
    description: "",
    idExamSession: "",
    aa: "",
  };

  validation = {
    title: (value) => value.length > 0,
    idExamSession: (value) => /^[0-9]+$/i.test(value),
    aa: value => /^\d{4}$/i.test(value) && value !== null,
  };

  FIELDS_GROUP = [
    [
      {
        id: "title",
        field: "title",
        label: "Titolo",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "description",
        field: "description",
        label: "Descrizione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "idExamSession",
        field: "idExamSession",
        label: "ID Sessione Esame",
        component: MInput,
        type: "text",
        className: "col-md-9",
      },
      {
        id: "aa",
        field: "aa",
        label: "Anno Accademico",
        component: MInput,
        type: "text",
        className: "col-md-3",
      }
    ],
  ];

  //--- ---

  constructor(props) {
    super(props);

    this.state = {
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: true,
      idLessonLiveEdition: this.props.idLessonLiveEdition,
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote(additionalState = {}) {
    //--- prevent loadRemote when not required ---
    if (parseInt(this.props.idLessonLiveEdition) <= 0) {
      return false;
    }

    let filter = {
      searchFilter: {
        id: parseInt(this.props.idLessonLiveEdition),
      },
    };

    adminService
      .loadLessonLiveEdition(filter)
      .then(({ data }) => {
        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.lessonLiveEdition
        );

        const newState = {
          defaultValues,
          ...additionalState,
          loading: false,
        };

        this.setState(newState);
      })
      .catch((error) => {
        networkErrorHelper.notify(error);
        console.log(error);
        this.setState({ loading: false });
      });
  } //loadremote

  onCancel = () => {
    this.resetForm();
  }; //onCancel

  onBackPage = () => {
    window.history.back();
  };

  saveRemote = () => {
    const payload = payloadBuilder(this.state);

    if (parseInt(this.props.idLessonLiveEdition) > 0) {
      adminService
        .updateLessonLiveEdition(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      adminService
        .insertLessonLiveEdition(payload)
        .then((result) => {
          this.onBackPage();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              this.ERROR_MESSAGES
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  };

  render() {
    let { formActive, defaultValues, idLessonLiveEdition } = this.state;
    const { formValid, code } = this.renderFields(
      this.FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        <Card
          tag="div"
          className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <CardBody tag="div">
            <form className="user" id="promotionalform">
              {code}
            </form>

            <div className="mt-4">
              <FormEnabler
                onSubmit={this.onSubmit}
                onCancel={
                  parseInt(idLessonLiveEdition) > 0
                    ? this.onCancel
                    : this.onBackPage
                }
                onToggle={this.toggleForm}
                isFormActive={formActive}
                disableSave={!formValid}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );
  }
}
