import React, { Component } from "react";
import Axios from "axios";
import { MnemoLoading, Title } from "components/misc";
import { Button, Container } from "design-react-kit";
import { MTextArea } from "components/forms";
import { utilsService } from "libs";

class ANSCmsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      results: "",
      objToSend: "",
      syncResult: [],
    };
  }

  ansUrl = [
    {
      label: "Diplomi ANS",
      url: "diplomi",
      isActive: false,
      target: "diplomians",
    },
    {
      label: "Città ANS",
      url: "city",
      isActive: false,
      target: "cityans",
    },
    {
      label: "Scuole ANS",
      url: "scuole",
      isActive: true,
      target: "schoolans",
    },
    {
      label: "Univeristà ANS",
      url: "university",
      isActive: true,
      target: "universityans",
    },
    {
      label: "Classi ANS",
      url: "classi",
      isActive: false,
      target: "classans",
    },
  ];

  //--- ---

  startSync = () => {
    this.setState({ isLoading: true });
    utilsService.updateANSData(this.state.objToSend).then((result) => {
      this.setState({
        isLoading: false,
        syncResult: result.data.payload.result,
      });
    });
  }; //startSync

  loadRemoteXML = (obj) => {
    if (obj.url === "") {
      return false;
    }

    this.setState({ isLoading: true });

    Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    const path =
      'https://api.mnemosine-elearning.it/api-staging/syncANS/?payload={"action":"syncANSData","payload":{"ansUrl":"diplomi"}}'; //obj.url;
    Axios.get(path, {})
      .then((content) => {        
        /*
            let data = (content.data).map((item)=>{

                //--- prepare data to send server ---
                if(obj.target === 'universityans-'){
                    delete item['$type'];
                    delete item['_codiceInterno'];
                    delete item['_comuneFuoriVocabolario'];
                    delete item['<ID>k__BackingField'];
                    delete item['<Cessato>k__BackingField'];
                    delete item['<DataIstituzione>k__BackingField'];
                    delete item['_denominazione_EN'];

                    item.uniCode     = item['_codiceCineca'];
                    item.name        = item['_denominazione'];
                    item.type        = (item['_pubblico'] === true) ? 'statale' : 'non statale';
                    item.uniType     = '';
                    item.denUtsShort = (item['_comune']) ? item['_comune']['_provincia']['_sigla'] : '';
                    item.city        = (item['_comune']) ? item['_comune']['_denominazione'] : '';
                    item.postCode    = (item['_comune']) ? item['_comune']['_codiceIstat'].replace(/^0+/, '') : '';
                    item.address     = (item['_comune']) ? (item['_via'] !== null ? item['_via'] : '') : '';
                    item.note        = '';

                    delete item['_codiceCineca'];
                    delete item['<Provincia>k__BackingField'];
                    delete item['_denominazione'];
                    delete item['_pubblico'];
                    delete item['_comune'];
                    delete item['_via'];
                    delete item['_indirizzoAteneo'];
                    delete item['_Owner'];
                    delete item['_uid'];

                    if(item.denUtsShort === 'EE' || item.denUtsShort === ''){
                        item = {};
                    }
                }//universityans

                //--- ---

                if(obj.target === 'schoolans-'){
                    delete item['$type'];
                    delete item['<UID>k__BackingField'];
                    delete item['<TELEFONO>k__BackingField'];
                    delete item['<LIVELLO_FUNZIONALE>k__BackingField'];
                    delete item['<TIPOLOGIA>k__BackingField'];
                    delete item['<TIPOLOGIA_SEDE>k__BackingField'];
                    delete item['<PARITA>k__BackingField'];
                    delete item['<CODICE_TIPO_SCUOLA>k__BackingField'];

                    item.idScuola       = item['<ID_SCUOLA>k__BackingField'];
                    item.schoolCode     = item['<CODICE_SCUOLA>k__BackingField'];
                    item.name           = item['<DENOMINAZIONE>k__BackingField'];
                    item.type           = item['<TIPO_SCUOLA>k__BackingField'];
                    item.ente           = item['<ENTE>k__BackingField'];
                    item.region         = item['<REGIONE>k__BackingField'];
                    item.province       = item['<PROVINCIA>k__BackingField'];
                    item.city           = item['<COMUNE>k__BackingField'];
                    item.address        = item['<INDIRIZZO>k__BackingField'];
                    item.zipcode        = item['<CAP>k__BackingField'];
                    item.mainSchoolCode = item['<CODICE_SCUOLA_RIFERIMENTO>k__BackingField'];
                    item.istatCode      = item['<CODICE_ISTAT>k__BackingField'];

                    delete item['<ID_SCUOLA>k__BackingField'];
                    delete item['<CODICE_SCUOLA>k__BackingField'];
                    delete item['<DENOMINAZIONE>k__BackingField'];
                    delete item['<TIPO_SCUOLA>k__BackingField'];
                    delete item['<ENTE>k__BackingField'];
                    delete item['<REGIONE>k__BackingField'];
                    delete item['<PROVINCIA>k__BackingField'];
                    delete item['<COMUNE>k__BackingField'];
                    delete item['<INDIRIZZO>k__BackingField'];
                    delete item['<CAP>k__BackingField'];
                    delete item['<CODICE_SCUOLA_RIFERIMENTO>k__BackingField'];
                    delete item['<CODICE_ISTAT>k__BackingField'];
                }//schoolans

                return item;
            })
            */

        //--- remove empty objs ---
        /*
            data = data.filter(element => {
                if (Object.keys(element).length !== 0) {
                  return true;
                }
                return false;
            })

            this.setState({
                results: JSON.stringify(data, undefined, 4),
                isLoading: false,
                objToSend: obj
            })
            */
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }; //loadRemoteXML

  //--- ---

  render() {
    let { isLoading, results, syncResult } = this.state;
    if (isLoading) return <MnemoLoading />;

    return (
      <>
        <div className="container mt-5 mb-4">
          <section className="my-4">
            <section className="m-0 p-0">
              <Title>Aggiornamento dati ANS</Title>
            </section>

            <section className="m-0">
              <div className="form-row m-0 p-0">
                <div className="col-md-12 mb-4">
                  {this.ansUrl
                    .filter((items) => {
                      return items.isActive;
                    })
                    .map((item, idx) => {
                      return (
                        <Button
                          key={idx}
                          className="ml-2"
                          color="primary bg-dark"
                          onClick={() => {
                            this.loadRemoteXML(item);
                          }}>
                          {item.label}
                        </Button>
                      );
                    })}
                </div>
              </div>
            </section>

            <Container>
              <MTextArea value={results} rows={14} />
              <hr />
              <Button
                className="ml-2"
                color="primary bg-dark"
                onClick={() => {
                  this.startSync();
                }}>
                {" "}
                Avvia Sync{" "}
              </Button>

              {syncResult.length > 0 ? (
                <>
                  <hr />
                  <div>Risultato ({syncResult.length}):</div>
                  <MTextArea
                    value={JSON.stringify(syncResult, undefined, 4)}
                    rows={14}
                  />
                </>
              ) : (
                ""
              )}
            </Container>
          </section>

          {/*<hr />

          <section className="m-0 p-0 my-4">
            <section className="m-0 p-0">
              <Title className="m-0">SQL inserimento nuovo istituto</Title>
              <Container>

              </Container>
            </section>
              </section>*/}
        </div>
      </>
    );
  }
}

export default ANSCmsMain;
