import React, { Component } from "react";
import { cache } from '../../libs';
import i18n from "app/i18n";

class QuestionText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            choice: [],
            idQuiz: this.props.idQuiz,
            textareaDefVal:'',
            validate:{
                isPaste: false, //true is allowed
                maxChar: 2000, //zero (0) to no limits
                maxWord: 250, //zero (0) to no limits
                currChar: 0, //init ,
                currWord: 0, //init ,
                isWordCount: (this.props?.metadata && this.props.metadata.feedbackFinal?.isWordCount === true) ? true : false
            }
        };
    }

    //--- ---

    componentDidMount(){
        const textareaDefVal = this.isChecked(this.state.idQuiz, this.props.idQuestion);
        this.setState({
            textareaDefVal
        })
    }

    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion) =>{
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);
        let result = '';

        if(getValue !== null){
            let valAnswer = JSON.parse(JSON.stringify(getValue));
            setTimeout(() => {
                this.setMyState(idQuestion, valAnswer[0].label)                    
            }, 150); 
            result = valAnswer[0].label;
        }

        return result;
    }//isChecked

    /** */
    handleOnPaste = (e) =>{
        if(!this.state.validate.isPaste){
            e.preventDefault();
            return false;
        }
    }//handleOnPaste


    /** */
    setMyState = (idQuestion, answer, e) => {
        let data = {
            idQuestion: idQuestion,
            label: answer
        }

        //--- validate textarea content ---

        /** validazione sul numero di caratteri */
        if(((this.state.validate.currChar >= this.state.validate.maxChar) || (answer.length >= this.state.validate.maxChar)) 
            && this.state.validate.maxChar > 0){
                this.setState({ textareaDefVal: answer.slice(0, -1) })
        }

        if(answer.length !== this.state.validate.currChar){
            this.setState({
                validate:{
                    ...this.state.validate,
                    currChar: answer.length
                },
                textareaDefVal: answer
            })

            this.setState(state => {    
                state.choice.pop(); 
                state.choice.push(data);
        
                //--- manage localStorage answer ---
                if(this.state.choice.length === 0){
                    cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }else{
                    cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }
            });
        }

        /** validazione sul numero di parole */        
        if(answer.split(' ').length > parseInt(this.state.validate.maxWord) && this.state.validate.isWordCount === true){
            this.setState({ textareaDefVal: answer.slice(0, -1) })
        }
            
        if(answer.split(' ').length <= parseInt(this.state.validate.maxWord) && this.state.validate.isWordCount === true){
            this.setState({
                validate:{
                    ...this.state.validate,
                    currWord: (answer.length === 0) ? (answer.split(' ').length-1) : answer.split(' ').length
                },
                textareaDefVal: answer
            })

            this.setState(state => {    
                state.choice.pop(); 
                state.choice.push(data);
        
                //--- manage localStorage answer ---
                if(this.state.choice.length === 0){
                    cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }else{
                    cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`,this.state.choice);
                }
            });
        }
    }//setMyState


    render() {

        if(!this.state.type) return false;

        return (
            <>
            {(this.props.answers[0].label === '') && (<div>
                <textarea 
                    id={`t${this.props.idQuestion}`}
                    name={`t${this.props.idQuestion}`}
                    rows="8"
                    maxLength={this.state.validate.maxChar}
                    onChange={(e) => { this.setMyState(this.props.idQuestion, e.target.value); }}
                    onPaste={(e) => { this.handleOnPaste(e) }}
                    value={this.state.textareaDefVal}
                ></textarea>

                {(!this.props.metadata.hasOwnProperty('hideLabel') || this.props.metadata?.hideLabel === false) ? <label htmlFor={`t${this.props.idQuestion}`}>Risposta aperta</label> : ''}

                {/** */}
                {(this.state.validate.maxChar > 0 && this.state.validate.isWordCount === false) && (
                    <>
                        <div>
                            <small>puoi scrivere un testo di massimo {this.state.validate.maxChar} caratteri. Ti rimangono ancora {this.state.validate.maxChar-this.state.validate.currChar} caratteri.</small>
                        </div>

                        {(this.state.validate.currChar >= this.state.validate.maxChar && this.state.validate.maxChar > 0) && (
                            <p className="text-danger">Attenzione. Hai raggiunto i {this.state.validate.maxChar} caratteri consentiti</p>
                        )}
                    </>
                )}

                {/** */}
                {(this.state.validate.maxWord > 0 && this.state.validate.isWordCount === true) && (
                    <>
                    <div>
                        <small>{i18n.t('infoLimitWord').replace('{{maxWord}}', this.state.validate.maxWord).replace('{{calc}}', (this.state.validate.maxWord-this.state.validate.currWord))}</small>
                    </div>

                    {(this.state.validate.currWord >= this.state.validate.maxWord && this.state.validate.maxWord > 0) && (
                        <p className="text-danger">{i18n.t('errLimitWord').replace('{{maxWord}}', this.state.validate.maxWord)}</p>
                    )}
                    </>
                )}
            </div>)}

            {/** */}            
            {(this.props.answers[0].label !== '' && (this.props.answers[0]?.review?.revisionNote || '') !== '') ? (
                <>                
                <div>{this.props.answers[0].label}</div>
                <div className="mt-2">
                <h6>{i18n.t('revisionInfo')}</h6>
                <p>{this.props.answers[0]?.review?.revisionNote || ''}</p>
                <p><b>{i18n.t('revisionOutcome')}:</b> {this.props.answers[0]?.review?.revisionResult || ''}</p>
                </div>
                </>
            ) : <div>{this.props.answers[0].label}</div>}
            </>
        );
    }
}

export default QuestionText;