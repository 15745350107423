import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { MTable } from "components/table";
import { ROUTES } from "const";
import { Container } from 'design-react-kit';
import { BoxDanger, ModalHandler } from 'components/misc';

import {
    MnemoLoading
} from "components/misc";

import { adminService, networkErrorHelper } from "libs";


class AssetsAudioMain extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            payload: [],
            rndKey: 0
        }
    }

    //--- ---

    componentDidMount() {
        this.loadAllAudioAssetCategory();
    }


    loadAllAudioAssetCategory() {
        adminService.loadAllAudioAssetCategory().then(({ data }) => {
            this.setState({
                loading: false,
                payload: data.payload,
                rndKey: Math.floor((Math.random() * 1000) + 1)
            });
        })
        .catch(error => {
            //console.log(error);
            this.setState({ loading: false });
            if (!networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            } else {
                this.setState({
                    payload: [],
                    rndKey: Math.floor((Math.random() * 1000) + 1)
                })
            }
        });
    }//loadAllAudioAssetCategory

    tableConfig = {
        columns: [
            {
                id: 'id', // identifier of columns
                Header: 'Column 0',
                isVisible: false, // IMPORTANT: hidden columns
                accessor: 'id', // accessor is the "key" in the data

            },
            {
                id: 'title',
                Header: 'Categoria',
                accessor: 'title',
            }
        ],
        actions: [
            {
                id: 'audios',
                accessor: 'id',
                label: 'Assets-Audio',
                icon: 'it-list',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    window.location.href = ROUTES.ASSETS_AUDIO_FILE_MANAGER + '/' + value;
                }
            },
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    window.location.href = ROUTES.ASSETS_AUDIO_CATEGORY_FORM + '/' + value;
                }
            },
            {
                id: 'delete',
                accessor: 'id', // field value received in the onclick event
                label: 'Elimina',
                icon: 'it-delete',
                className: 'MTable-actions',
                onClick: (e, value) => { this.deleteRecord(e, value) }
            }
        ]
    }
    
    //--- start functions to delete ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione del dato',
            'vuoi davvero eliminare?', null, this.deleteConfirmed);
    }

    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        /*let resAPI = null;        
        resAPI = adminService.deleteAnswer(payload.id).then(({ data }) => {
            this.props.loadAllAnswer(data.payload.answer.idQuestion, this.state.model);
            this.onCancel();
        })

        resAPI.catch(error => {
                console.log(error);
                if (networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
            });*/
    }
    //---- end functions to delete ----

    //--- ---

    render() {

        const { loading } = this.state;

        return (
            <>
                <div className="container mt-8 mb-4 mt-4">
                    <section className="m-0">
                        <div className="form-row m-0 p-0">
                            <div className="col-md-9">
                                <div className="col-md-5"></div>
                            </div>

                            <div className="col-md-2 text-right">
                                <NavLink to={ROUTES.ASSETS_AUDIO_CATEGORY_FORM} className="btn btn-primary bg-dark pb-2">Nuova Categoria</NavLink>
                            </div>
                        </div>
                    </section>

                    <hr />

                    <section className="my-4">
                        {loading ? (<MnemoLoading></MnemoLoading>) : (
                            <Container>
                                <MTable
                                    key={this.state.rndKey}
                                    tableData={this.state.payload}
                                    tableConfig={this.tableConfig}
                                />
                            </Container>
                        )}

                        {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
                            Categorie Assets Audio.
                            <div className="small text-info"> Nessun dato presente. </div>
                        </BoxDanger>)}
                    </section>
                </div>
            </>
        );
    }
}

export default AssetsAudioMain;
