import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { AdminBlogCategory } from "components/forms/admin";
import { BlogCmsCategory  } from '.';

import { withRouter } from 'react-router-dom';

class BlogCmsCategoryForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idBlogCategory: parseInt(this.props.match.params.idBlogCategory) > 0 ? this.props.match.params.idBlogCategory : 0,
      title: parseInt(this.props.match.params.idBlogCategory) > 0 ? 'Gestione Categoria' : 'Nuova Categoria',
      parentList: [{ label: 'Seleziona', value: 0 }]
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            {<AdminBlogCategory idBlogCategory={this.state.idBlogCategory} />}
          </Container>
          {
            (parseInt(this.state.idBlogCategory) > 0) &&
            (<BlogCmsCategory idBlogCategory={parseInt(this.state.idBlogCategory)} />)
          }
        </section>
      </>
    );
  }

}

export default withRouter(BlogCmsCategoryForm);
