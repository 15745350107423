import React, { Component } from 'react';

import {
    Alert
} from 'design-react-kit';

export class BoxWarning extends Component {

    render() {
        return (
            <>
                <Alert color="warning" tag="div" {...this.props}  >
                    {this.props.children}
                </Alert>
            </>
        )
    }
}