import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Dropzone from 'react-dropzone';
import { proxyBE } from 'libs/api/common';

import './MFile.css';

import {
    Button,
    Card,
    CardBody,
    Icon,
} from 'design-react-kit';
import { BoxDanger, BoxInfo, MnemoLoading } from 'components/misc';
import { networkErrorHelper } from 'libs';

/**
   * PROPS: 
   * id       : identifier 
   * name     : name of input
   * label    : brief description of fields        
   * value    : the value idParent
   * config   : configuration of compoent, current options are:
   *      endpoint: url to use for the remote actions (upload, list, download)
   *      accept : extensions enabled, default are:  pdf,jpg,jpeg,png,tiff,tif,gif,bmp           
   *      maxFiles : Maximum accepted number of files The default value is 0 which means there is no limitation to how many files are accepted.
   *      minFiles : Minimum accepted number of files The default value is 0 which means there is no limitation to how many files are accepted.
   *      filter: further filter different from idParent
   *      mandatory: if the file is mandatory  
   * infoText : addtional text used when the input in invalid
   * disabled : insert for readonly 
   * readOnly : is readOnly
   * valid    : set  when the input is valid
   * invalid  : set  when the input is invalid
   */
class MFile extends Component {
    state = {
        files: [],
        loading: true,
        loadingError: false,
    };

    getConfig(name) {
        return this.props.config[name] || MFile.defaultProps.config[name];
    }

    componentDidMount() {

        // add this component to validatedForm
        if (this.props.onValidatedForm) {
            this.props.onValidatedForm(this);
        }

        /** 
         *  TODO SAVERIO: "value" is the idParent:
         * to complete with other filter in configuration
         * when I will use it from different forms 
         */
        this.loadRemote();
    }

    checkValidation = () => {

        let result = true;
        const {mandatory, minFiles=0 } = this.props.config;
        const { files } = this.state;

        if (mandatory) {
            const { files } = this.state;
            if (files.length === 0) {
                result = false;
                this.setState({ isInvalid: true, isValid: false, loadingError: 'inserire almeno un file' })
            }
        }else if (minFiles>0 && minFiles>files.length) {
            result = false;
            this.setState({ isInvalid: true, isValid: false, loadingError: 'inserire almeno '+minFiles+' file' })
        }

        if (result) this.setState({ isValid: true, isInvalid: false, loadingError: '' })
        return result;
    }

    // beacaus is a valitadetComponent
    onSubmit() {
        const isValid = this.checkValidation();
        return isValid;
    }

    loadRemote() {
        
        let endPoint = this.getConfig('endPoint') + '/list';
        const idParent = this.props.value;
        if (idParent) {
            endPoint += '/' + idParent;
        }

        proxyBE.get(endPoint)
            .then(({ data }) => {
                this.setState({ loading: false, files: data.payload.files });
                this.checkValidation();
            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    const loadingError = networkErrorHelper.getErrorMessage(error);
                    this.setState({ loading: false, files: [], loadingError });
                } else {
                    this.setState({ loading: false, files: [] });
                }

                this.checkValidation();
            });
    }

    onDownload({ id, originalName }) {

        const endPoint = this.getConfig('endPoint');

        proxyBE.get(`${endPoint}/download/${id}`, { responseType: 'blob' })
            .then(({ data }) => {

                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', originalName); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    };

    onDelete({ id }) {

        const endPoint = this.getConfig('endPoint');
        proxyBE.delete(`${endPoint}/${id}`)
            .then(({ data }) => {

                // relaod files
                this.loadRemote();
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }

    onDropAccepted = (acceptedFiles) => {
        
        const maxFiles = parseInt(this.getConfig('maxFiles'));
        const current = acceptedFiles.length + this.state.files.length;

        if (current > maxFiles) {
            this.setState({ loadingError: 'Superato il numero massimo consentito' });
            return;
        }

        this.setState({ loading: true, loadingError: false });

        // upload the file 
        acceptedFiles.forEach(file => {
            this.upload(file);
        });

        if (!this.state.loadingError) {
            this.setState({ files: acceptedFiles });
        }

        // relaod files
        //this.loadRemote();
    };

    onUploadDone = () => {        

        if (this.props.onUploadDone) {
            this.props.onUploadDone(this.props.name);
        }
    }

    upload(file) {
        
        let endPoint = this.getConfig('endPoint') + '/upload';
        if (this.props.value) {
            endPoint += '/' + this.props.value;
        }
        
        const fieldName = this.props.name;

        const formData = new FormData();
        formData.append(fieldName, file);

        proxyBE.post(endPoint,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
        ).then(({ data }) => {
            this.onUploadDone();
            this.loadRemote();
        }).catch(error => {
            console.log('Upload Failure', error);
            const loadingError = networkErrorHelper.getErrorMessage(error);
            this.setState({ loadingError, loading:false });
        });
    }

    renderFileSection() {
        const { files } = this.state;
        if (!Array.isArray(files) || files.length < 1) return (<BoxInfo>Non hai caricato nessun file</BoxInfo>);

        const { disabled, readOnly } = this.props;
        const isDeletable = !(disabled || readOnly);

        return files.map(file => (
            <BoxInfo className="my-0 mnemo-dopzone-boxInfo" key={`file-${file.id}`}>
                {file.originalName}
                <Button
                    className="mx-1"
                    color="primary bg-dark"
                    size="xs"
                    onClick={() => this.onDownload(file)}>
                    Scarica
                </Button>
                {isDeletable && (
                    <Button color="danger" size="xs" outline onClick={() => this.onDelete(file)}> Elimina</Button>
                )}
            </BoxInfo>
        ));
    }

    render() {
    
        const { loading, loadingError } = this.state;
              
        const {
            id, name,
            disabled = false, readOnly = false,
            label, valid, invalid,
            infoText
        } = this.props;

        const accept = this.getConfig('accept');
        const maxFiles = this.getConfig('maxFiles');
        const minFiles = this.getConfig('minFiles');

        // className
        let className = (valid) ? 'is-valid' : '';
        className += (invalid) ? 'is-invalid' : '';
        className += (this.props.className) ? this.props.className : '';

        if (this.state.loadingError || this.state.isInvalid) {
            className += 'is-invalid';
        }

        return (
            <>
                {(loading) && <MnemoLoading />}
                <Card tag="div">

                    <div className="etichetta" id="mfile-label">
                        <Icon icon="it-card" padding={false} />
                        <span>{label}</span>
                    </div>
                    {/*!(disabled || readOnly) && <>{infoText && (<BoxInfo className="m-0">{infoText}</BoxInfo>)}</>*/}
                    <CardBody tag="div" className="px-0">
                        {!(disabled || readOnly)
                            ?
                            (
                                <Dropzone accept={accept} maxFiles={maxFiles}
                                    onDropAccepted={this.onDropAccepted} >
                                    {({ getRootProps, getInputProps }) => (
                                        <section className={className}>
                                            <div {...getRootProps({ className: 'dropzone mnemo-dopzone' })}>
                                                <input id={id} name={name} {...getInputProps()} />

                                                <Icon icon="it-upload" padding={false} />
                                                <div className="line001">{infoText}. Trascina il file qui</div>
                                                <div className="line002">oppure</div>
                                                <div className="btn btn-xs btn-primary bg-dark">clicca qui</div>
                                                <div className="line002">
                                                {(minFiles !== 0) && (<>Devi caricare almeno {minFiles} file.<br /></>)}
                                                    Puoi caricare massimo {maxFiles} files
                                                    <br />
                                                    Estensioni permesse: {accept}
                                                </div>

                                                {loadingError && (<BoxDanger>{loadingError}</BoxDanger>)}

                                            </div>

                                            {this.renderFileSection()}

                                        </section>
                                    )}
                                </Dropzone>
                            ) : (
                                <section>
                                    {this.renderFileSection()}
                                </section>
                            )}
                    </CardBody>
                </Card>
            </>
        );
    }
}

MFile.propTypes = {
    config: PropTypes.shape({
        endPoint: PropTypes.string.isRequired,
        accept: PropTypes.string,
        fieldName: PropTypes.string,
        maxFiles: PropTypes.number,
        minFiles: PropTypes.number,
    })
};

MFile.defaultProps = {
    config: {
        accept: '.pdf, .jpg, .jpeg, .png, .tiff, .tif, .gif, .bmp',
        endPoint: null,
        fieldName: 'file',
        maxFiles: 5,
        minFiles: 1
    }
};

export { MFile };