import React, { Component } from 'react';
import { learningService, networkErrorHelper } from "libs";
import { BoxSuccess, MnemoCardSlim, MnemoLoading } from 'components/misc';

import {
    Row, Col
} from 'design-react-kit';

export class CourseList extends Component {

    state = {
        courses: [],
        loading: true
    };

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote() {

        const {actionLink} = this.props;

        learningService
            .myCourses()
            .then(({ data }) => {

                const courses = data.payload.courses.map(course => {

                    return Object.assign({},
                        {
                            title: course.code + ' - ' + course.title,                         
                            actionLink,
                            actionParam: course.registrationCode,
                        }
                    );
                })

                this.setState({ courses, loading: false });

            })
            .catch(error => {              
                if (!networkErrorHelper.is404(error)) {                   
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false });
            });
    }

    //--- ---

    render() {

        const { loading, courses } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);
        
        return (
            <>
                {(courses && courses.length>0)
                    ? (courses.map((course, i) => (
                        <Row key={`ticket-${i}`}  >
                            <Col size="12" >
                                <MnemoCardSlim {...course} />
                            </Col>
                        </Row>
                    ))) : 
                    <BoxSuccess>Non risultano iscrizioni a corsi.</BoxSuccess>}
            </>);
    }

}