import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminPromotionalForm } from '../../../components/forms/admin';


class PromotionalDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
        idPromotional: parseInt(this.props.match.params.idPromotional) > 0 ? this.props.match.params.idPromotional : 0
    }
  }

  render() {

    const { idPromotional } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{(idPromotional > 0) ? 'Aggiorna Promozione' : 'Crea Promozione'}</Title>
            <AdminPromotionalForm idPromotional={idPromotional}/>
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(PromotionalDetail);
