import React from "react";

import {
    FormEnabler,
    ValidatedForm,
} from "components/forms";

import {
    userService,
    payloadBuilder,
    networkErrorHelper
} from "libs";

import { MInput } from 'components/forms/shared/MInput';
import { BoxDanger, BoxSuccess, MSection, Title } from "components/misc";

export class PasswordReset extends ValidatedForm {

    ERROR_MESSAGES = {
        emailAddr: "Indirizzo email obbligatorio/non valido",
    };

    emptyFields = {
        emailAddr: '',
    };

    validation = {
        emailAddr: value => /\S+@\S+\.\S+/.test(value)
    };

    FIELDS_GROUP = [
        [
            {
                id: "emailAddr",
                field: "emailAddr",
                label: "Email",
                component: MInput,
                type: "email",
                className: "col-md-12"
            }
        ]
    ];

    onCancel = () => {
        this.setState({
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            msgRecoveryPassword: false,
            msgSuccessRecoveryPassword: false
        })
    }//onCancel

    //--- ---
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            msgRecoveryPassword: false,
            msgSuccessRecoveryPassword: false
        }

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        userService.resetPassword(payload).then((result) => {
                if(result.data.payload.userRecoveryPassword){
                    this.setState({
                        msgSuccessRecoveryPassword: true,
                        msgRecoveryPassword: false,
                        ...this.emptyFields,
                        defaultValues: { ...this.emptyFields }
                    })
                }else{
                    this.setState({
                        msgSuccessRecoveryPassword: false,
                        msgRecoveryPassword: true,
                        ...this.emptyFields
                    })

                }
        }).catch(errors => {
            if (networkErrorHelper.is422(errors)) {
                this.setState({
                    msgRecoveryPassword: true,
                });
            } else {
                networkErrorHelper.notify(errors);
            }
        });
            
    };//saveRemote   


  render() {
        
    let { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
       <Title> Reimposta la password</Title>
        <MSection className="mb-5">
            {(this.state.msgRecoveryPassword) && (<BoxDanger key="nrp-1" className="mt-4">
              Indirizzo email non presente.
              <div className="small text-info">Assicurati di aver utilizzato l'indirizzo email corretto, utilizzato al momento della registrazione.</div>
            </BoxDanger>)}

            {(this.state.msgSuccessRecoveryPassword) && (<BoxSuccess key="nrp-2" className="mt-4">
              Abbiamo inviato una comunicazione all'indirizzo email indicato<br/>
              con la procedura da seguire per il cambio password.
            </BoxSuccess>)}

            {(!this.state.msgSuccessRecoveryPassword) && (
            <>            
            <p className="mt-3">
              Hai dimenticato la password?<br/>Inserisci il tuo indirizzo e-mail, ti
              manderemo una e-email per permetteri di reimpostarla.
            </p>
            <hr/>
            <form className="user mt-5" id="courseform">
                {code}
            </form>

            <div className="mt-4">
                <FormEnabler
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    onToggle={this.toggleForm}
                    isFormActive={formActive}
                    disableSave={!formValid}
                />
            </div>
            </>)}
        </MSection>
      </>
    );
  }
}
