import React, { Component } from "react";

export class LessonVideo extends Component {

    render() {

        const resource = this.props.lesson;

        return (
            <>
                <iframe
                    title = "videoFeedLesson"
                    src={resource.content}
                    width="100%"
                    height="100%"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen>
                </iframe>
            </>
        );
    }
}