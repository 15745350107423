import { ROUTES } from "const";
import { courseService } from "libs";

export class PFU {

    static async getDashboardCards(code, registrationCode) {

        let cards = this.getFixedCards(code);

        try {
            const result = await courseService.perfezionamentoActiveLink(registrationCode);
            if (result.data.payload) {
                const { payload } = result.data;

                if (payload.examReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami',
                        link: ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION,
                        img: '/img/icon/badge.png',
                        className: 'image-box'
                    });
                }

                if (payload.titleRequestEnabled === '1') {
                    cards.push({
                        title: 'Richiesta Certificato Finale',
                        link: ROUTES.MU_COURSE_TITLE_REQUEST,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }

                if (payload.SelfDeclarationEnabled === '1' && code == "PFU5CFU2") {
                    cards.push({
                        title: 'Autocertificazione d\'iscrizione',
                        link: ROUTES.MU_SELF_DECLARATION,
                        img: '/img/icon/badge.png',
                        className: 'image-box'
                    });
                }

            }
        } catch (error) {

        }

        return cards;
    }

    static getFixedCards(code) {

        if (code == "PFU5CFU2") {
            return [
                {
                    title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
                    img: '/img/icon/lessons.png', className: 'image-box'
                }, {
                    title: 'Invio materiale', link: ROUTES.LEARNING_FINAL_PAPER,
                    img: '/img/icon/document.png', className: 'image-box'
                }, {
                    title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
                    img: '/img/icon/graduate.png', className: 'image-box'
                }, {
                    title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                    img: '/img/icon/message.png', className: 'image-box'
                }, {
                    title: 'Situazione pagamenti', link: ROUTES.MU_COURSE_PAYMENTS,
                    img: '/img/icon/payment.png', className: 'image-box'
                },
            ];
        } else {
            return [
                {
                    title: 'Situazione pagamenti', link: ROUTES.MU_COURSE_PAYMENTS,
                    img: '/img/icon/payment.png', className: 'image-box'
                },
                {
                    title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                    img: '/img/icon/message.png', className: 'image-box'
                }
            ];
        }
    }

    static getDashboardNotices(code) {

        if (code != "PFU5CFU2") {
            return [
                {
                    title: '',
                    content: `<p>Gentile Corsista,
                        nel rispetto delle norme vigenti, dal primo Febbraio l'Università Unicamillus ha attivato il corso relativo ai 5 CFU ai sensi del Dm 108/2022.<br/>
                    Il nuovo corso, con prova orale finale in presenza in una qualsiasi delle 100 città, è articolato in 40 ore di attività che devono essere 
                    tutte effettuate sulla piattaforma.<br/>
                    Il costo del corso è stabilito in € 150 comprensivo del costo della marca da bollo.<br/>
                    Viste le suddette novità le proponiamo due opzioni:<br/>
                    <ul>
                    <li>rinunciare al corso al quale aveva partecipato a Novembre con il rimborso di tutte le somme versate 
                    compilando ed inviando il “Modulo di Rinuncia con Rimborso” entro e non oltre mercoledì 8 Febbraio tramite 
                    pec all'indirizzo <a href="mailto:postlaurea.unicamillus@pec.it">postlaurea.unicamillus@pec.it</a> e per conoscenza a <a href="mailto:comunicazioni@Formazionedocenti.it">Comunicazioni@Formazionedocenti.it</a> <br/><br/>
                    <span style="text-align:center !important;"><a href="/files/corsi/PFU/modulo_rinuncia_con_rimborso.pdf">Modulo di Rinuncia con Rimborso</a></span><br/><br/>
                    </li>
                    <li>effettuare un passaggio corso con il rimborso solo delle somme già versate ed eccedenti i 150 euro accettando le disposizioni del nuovo Bando di partecipazione 
                    (frequenza 40 ore dall'iscrizione, reinviare eventuali elaborati e, come previsto dalle norme attuali, esame orale in presenza in una qualsiasi delle 100 città). 
                    In tal caso la invitiamo a compilare ed inviare il “Modulo passaggio” 
                    (contenente anche la comunicazione delle coordinate iban per il suddetto rimborso) entro e non oltre mercoledì 8 Febbraio 
                    a <a href="mailto:comunicazioni@Formazionedocenti.it">Comunicazioni@Formazionedocenti.it</a>;
                    <br/><br/>
                    <span style="text-align:center !important;"><a href="/files/corsi/PFU/modulo_passaggio.pdf">Modulo Passaggio</a></span><br/><br/></li>
                    </ul>
                    </p>`
                },
            ];
        }

    }
}
