import React, { Component } from "react";

import {
  Card,
  CardBody,
  Icon
} from 'design-react-kit';

import { MTable } from "components/table";
import { dateHelper } from 'libs';

export class UserDetailEnrollmentAbilitation extends Component {

  //--- ---
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'aa',
        Header: 'AA',
        accessor: 'aa',

      },
      {
        id: 'courseCode',
        Header: 'Codice Corso',
        accessor: 'courseCode', // accessor is the "key" in the data

      },
      {
        id: 'enrollDate',
        Header: 'Data Utilizzo',
        //accessor: 'dateStart', // accessor is the "key" in the data
        accessor: row => { return (row.enrollDate) ? dateHelper.toITDate(row.enrollDate) : '' },

      },
      {
        id: 'tmpIsActive',
        Header: 'Stato',
        accessor: 'tmpIsActive',

      },
    ],
    actions: []
  }

  loadTableData(){    
    const items = [];
    Object.entries(this.props.data).forEach(([key, val]) => {
      if (val.isActive) {
        val.tmpIsActive = 'abilitato'
      } else {
        val.tmpIsActive = 'disabilitato'
      }

      items.push(val);
    })
    
    return items;
  }


  //--- ---

  render() {

    if (!this.props.data) return false;


    return (
      <>
        <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-user" padding={false} />
            <span>Domande di iscrizione Abilitate</span>
          </div>
          <CardBody>
            {<MTable tableData={this.loadTableData()} tableConfig={this.tableConfig} />}
          </CardBody>
        </Card>
      </>
    );
  }

}
