import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { uriHelper } from 'libs/helpers/uriHelpers';
import { BoxDanger, SupportoTecnico, Title } from "components/misc";
import { NavLink } from "react-router-dom";
import { ROUTES } from "const";
import { stringHelper } from "libs";

class CardPaymentKO extends Component {

    state = {
        orderNumber: '',
        tableName: '',
    }

    componentDidMount() {
        const params = uriHelper.parseQueryString();        
        const { orderNumber, tableName } = this.props.match.params;
        let { messaggio } = params;
        messaggio = stringHelper.replaceAll(messaggio, '+', ' ');
        this.setState({ orderNumber, tableName, messaggio });
    }

    render() {

        const { tableName, messaggio } = this.state;

        return (<>
            <Title>Pagamento con carta di credito annullato o rifiutato</Title>
            <BoxDanger>Il pagamento non è andato a buon fine. <br />
                {(tableName === 'userEnrollmentPayment') ?
                    (<>
                        Dalla tua area riservata puoi riprovare il pagamento oppure cambiare la modalità di pagamento.
                        <NavLink to={ROUTES.MY_ENROLLMENT_PENDING}><b> Vai nella sezione "Domande di iscrizione".</b></NavLink>
                    </>) : (<>
                        Puoi ripetere l'operazione attraverso la tua area riservata in sistuazione pagamenti.
                    </>)}
                <br />{messaggio}
                <br />Se hai delle difficoltà puoi <SupportoTecnico />
            </BoxDanger>
        </>);
    }
}

export default withRouter(CardPaymentKO);