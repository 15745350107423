import React, { Component } from "react";
import { Title } from "components/misc";
import { Container, Row, Col } from 'design-react-kit';
import { AdminCourseFormLesson } from "components/forms/admin";
import { CourseManagerChildListLesson } from 'views/admin/course/CourseManagerChildListLesson';

import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../const';


import { withRouter } from 'react-router-dom';


class LessonForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        idCourse: parseInt(this.props.match.params.idcourse) > 0 ? this.props.match.params.idcourse : 0,
        idSection: parseInt(this.props.match.params.idsection) > 0 ? this.props.match.params.idsection : 0,
        idLesson: parseInt(this.props.match.params.idlesson) > 0 ? this.props.match.params.idlesson : 0,
        currentLevel: parseInt(this.props.match.params.currentlevel) > 0 ? this.props.match.params.currentlevel : 1,
        title: parseInt(this.props.match.params.idlesson) > 0 ? 'Gestione lezione' : 'Nuova lezione'
    }
  }

  //--- ---

  render() {

    const { idCourse, idSection, idLesson, title, currentLevel } = this.state;

    return (
      <>
        <section className="my-4">
          <Container>

            <Row>
              <Col>
                <Title className="m-0 p0">{title}</Title>
              </Col>
              <Col className="text-right">
                <NavLink to={ROUTES.COURSE_FORM+'/'+idCourse} className="btn btn-primary bg-dark m-5">torna al Corso</NavLink>
              </Col>
            </Row>

            <AdminCourseFormLesson idCourse={idCourse} idSection={idSection} idLesson={idLesson} action={this.props.match.params.action} currentLevel={currentLevel} />

            {(idLesson > 0) && (
              <>
                <Title>Lezioni</Title>
                <CourseManagerChildListLesson data={this.state}/>
              </>
            )}
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(LessonForm);
