import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../const';

export const SupportoTecnico = () => (
    <>
    contattare il 
    <NavLink
        to={ROUTES.PAGE_SUPPORT}
        tag="a"
        title="supporto tecnico"
        className="link-dark"
    > <b>supporto tecnico</b></NavLink>
    </>
);

