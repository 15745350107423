import { RC30_EDITION, ROUTES } from "const";
import { learningLiveService, courseService } from "libs";

export class RC30 {

  // SAVERIO: PEZZA per mostrare avvisi differenti per edizioni differenti
  static firstEdition = RC30_EDITION.first;
  static secondEdition = RC30_EDITION.second;
  static thirdEdition = RC30_EDITION.third;
  static fourthEdition = RC30_EDITION.fourth;

  static async getDashboardCards(registrationCode, idExamSession) {
    let cards = this.getFixedCards(idExamSession);

    try {

      const remoteResult = await learningLiveService.isOpenClassBooking(
        registrationCode
      );

      const isBookingOpen = (
        remoteResult &&
        remoteResult.data &&
        remoteResult.data.payload &&
        remoteResult.data.payload.length > 0
      );

      // verify dynamic links
      const result = await courseService.perfezionamentoActiveLink(
        registrationCode
      );
      if (result.data.payload) {
        const { payload } = result.data;

        if (isBookingOpen && payload.lessonLiveClassEnabled === "1") {

          cards.push({
            title: "Assegnazione Aula",
            link: ROUTES.COURSE_BOOKING_CLASS,
            img: "/img/icon/student.png",
            className: "image-box",
          });
        }

        if (payload.examReservationEnabled === "1") {
          cards.push({
            title: "Prenotazione prova finale",
            link: ROUTES.COURSE_RC30_EXAM_RESERVATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }
       
        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }

        if (payload.SelfDeclarationEnabled === "1") {
          cards.push({
            title: "Autocertificazione d'iscrizione",
            link: ROUTES.USER_SELF_DECLARATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }

        {/** esame scritto */ }
        if (payload.enableUDA === "1") {
          cards.push({
            title: "Prova scritta",
            link: ROUTES.COURSE_RC30_UDA_EXAMWRITTEN,
            img: "/img/icon/examdone.png",
            className: "image-box",
          });
        }

        if (payload.enableExamSubject === "1") {
          cards.push({
            title: "Argomento Lezione Simulata",
            link: ROUTES.COURSE_RC30_EXAM_SUBJECT,
            img: "/img/icon/7.png",
            className: "image-box",
          });
        }

        if (payload.certRequestEnabled === "1") {
          cards.push({
            title: "Consultazione Esito Finale",
            link: ROUTES.COURSE_RC30_CERT_REQUEST,
            img: "/img/icon/1.png",
            className: "image-box",
          });
        }

      }
    } catch (error) { }

    return cards;
  }

  static getFixedCards(idExamSession) {
    let cards = [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
      {
        title: "Lezioni",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      }
    ];


    if (idExamSession === this.fourthEdition || idExamSession === this.thirdEdition) {
      cards.push({
        title: "Conferma o modifica la sede d'esame",
        link: ROUTES.COURSE_RC30_CONFIRM_EXAMSITE,
        img: "/img/icon/geo.png",
        className: "image-box",
      });
    }

    return cards;
  }

  static getDashboardNotices(idExamSession) {
    if (idExamSession === this.firstEdition) {
      return [
        {
          title: `Linee guida svolgimento prova finale`,
          content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/Sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale (aggiornate al  27/05/2024): <a href="https://www.formazionedocenti.it/files/corsi/RC30/Modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Allegato A Modello di Unità di apprendimento: <a href="https://www.formazionedocenti.it/files/corsi/RC30/Allegato_A_Modello_Unita_apprendimento.docx">Clicca qui per scaricare l'informativa</a></li>
          <li>Allegato B Modello lezione simulata : <a href="https://www.formazionedocenti.it/files/corsi/RC30/Allegato_B_Modello_lezione_simulata.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
        },
        {
          title: `Linee guida svolgimento delle lezioni`,
          content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `<ul><li>Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-corsi-abilitanti-30cfu.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
                  <li>Seconda fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-corsi-abilitanti-30cfu-fase-2-prima-edizione.pdf" target="_blank">Clicca qui per scaricare l'informativa </a></li></ul>`,
        },
      ];
    } else if (idExamSession === this.secondEdition) {
      return [
        {
          title: `Linee guida svolgimento prova finale`,
          content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/Sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale (aggiornate al  27/05/2024): <a href="https://www.formazionedocenti.it/files/corsi/RC30/Modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Allegato A Modello di Unità di apprendimento: <a href="https://www.formazionedocenti.it/files/corsi/RC30/Allegato_A_Modello_Unita_apprendimento.docx">Clicca qui per scaricare l'informativa</a></li>
          <li>Allegato B Modello lezione simulata : <a href="https://www.formazionedocenti.it/files/corsi/RC30/Allegato_B_Modello_lezione_simulata.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
        },
        {
          title: `Linee guida svolgimento delle lezioni`,
          content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `<ul><li>Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-corsi-abilitanti-30cfu-fase-1-seconda-edizione.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Seconda fase del percorso (Rimodulato)  - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-corsi-abilitanti-30cfu-fase-2-seconda-edizione.pdf" target="_blank">Clicca qui per scaricare l'informativa </a></li></ul>`,
        },
      ];
    } else if (idExamSession === this.thirdEdition) {
      return [
        {
          title: `Linee guida svolgimento prova finale`,
          content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modello lezione simulata: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modello_lezione_simulata_rc30.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
        },
        {
          title:
            "Avviso Lezione del 27 Settembre 2024 inerente la Seconda fase del percorso",
          content: `<p><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RC30/avviso-lezione-27-settembre-rc30-III.pdf">Clicca qui per scaricare l'informativa</a></p>`,
        },
        {
          title: `Linee guida svolgimento delle lezioni`,
          content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu.pdf">Clicca qui per scaricare le linee guida</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `<p>Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-corsi-abilitanti-30cfu-fase-1-terza-edizione.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `Seconda fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/rc30-calendario-lezioni-edizione3-fase2.pdf" target="_blank">Clicca qui per scaricare l'informativa</a>`,
        },
      ];
    } else if (idExamSession === this.fourthEdition) {
      return [
        {
          title: `Linee guida svolgimento prova finale`,
          content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modello lezione simulata: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modello_lezione_simulata_rc30.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
        },
        {
          title:
            "Avviso Lezione del 27 Settembre 2024 inerente la Seconda fase del percorso",
          content: `<p><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/RC30/avviso-lezione-27-settembre-rc30-IV.pdf">Clicca qui per scaricare l'informativa</a></p>`,
        },
        {
          title: "Linee guida svolgimento delle lezioni",
          content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu-generic.pdf">Clicca qui per scaricare le linee guida</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/rc30-calendario-lezioni-edizione4-fase1.pdf" target="_blank">Clicca qui per scaricare l'informativa</a>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `Seconda fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/rc30-calendario-lezioni-edizione4-fase2.pdf" target="_blank">Clicca qui per scaricare l'informativa</a>`,
        },
      ];
    }
  }
}
