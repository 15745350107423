import React, { Component } from 'react';

import { BoxDanger, MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';

export class Concessions extends Component {

    render() {
        const title = 'AGEVOLAZIONI';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <p>
                                Potrai decurtare la somma complessiva di euro 31 per l’iscrizione ad uno o più corsi contemporaneamente se sei un iscritto
                                ad un qualsiasi valido Sindacato Scuola che è  firmatario dei contratti collettivi nazionali di lavoro del Comparto Scuola
                                (Contratto sulla Mobilità Docenti) di cui si riporta uno stralcio ….</p>
                            <p>
                                “Contratto Collettivo Nazionale Integrativo concernente la mobilità del personale docente, educativo ed A.T.A. per l’a .s. 2017/2018,
                                sottoscritto il giorno 11 aprile 2017 in Roma, presso il Ministero dell’Istruzione, dell’Università e della Ricerca in sede di negoziazione 7
                                integrativa a livello ministeriale</p>

                            <p className="text-center">TRA</p>

                            <p>la delegazione di parte pubblica costituita con D.M. n. 776 del 5 ottobre 2015</p>

                            <p className="text-center">E</p>

                            <p>i rappresentanti delle Organizzazioni Sindacali F.L.C.-C.G.I.L., C.I.S.L.-SCUOLA, U.I.L.-SCUOLA, S.N.A.L.S.- C.O.N.F.S.A.L., F.G.U. Gilda-Unams, firmatarie dei contratti collettivi nazionali di lavoro del Comparto Scuola…”</p>
                            <p>Elenco Sindacati:</p>
                            <ul>
                                <li><b>CF.L.C.-C.G.I.L.;</b></li>
                                <li><b>C.I.S.L.-SCUOLA;</b></li>
                                <li><b>U.I.L.-SCUOLA</b></li>
                                <li><b>S.N.A.L.S.- C.O.N.F.S.A.L;</b></li>
                                <li><b>F.G.U. Gilda-Unams;</b></li>
                                <li><b>Acli;</b></li>
                                <li><b>CONFAL federazione scuola.</b></li>
                            </ul>
                        </Col>
                        <BoxDanger>
                            <p>
                                Per usufruire dell’agevolazione basta allegare solo all’atto dell’iscrizione  copia di un qualsiasi documento che attesti l’iscrizione ad uno dei suddetti sindacati.</p>
                            <p>Si precisa che la presente iniziativa non è cumulabile con altre iniziative commerciali e nello spazio riservato all’ufficio in
                                alto a sinistra del modulo di iscrizione non deve essere riportato nessun codice (pena la non convalida della presente).</p>

                            <p>La decurtazione della somma di 31 Euro sarà applicata all’ultima rata.</p>

                            <p>ATTENZIONE: invitiamo eventuali altre sigle sindacali che vogliono aderire alla promozione di
                                inviare una mail a <a href="mailto:info@formazionedocenti.it">info@formazionedocenti.it </a>
                                per essere inseriti nel suddetto elenco e fornire tale agevolazione ai loro iscritti.</p>

                        </BoxDanger>
                    </Row>
                </MSection>
            </>);
    }
}