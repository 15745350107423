import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { AdminLibraryAuthor } from "components/forms/admin";

import { withRouter } from 'react-router-dom';

class LibraryCmsBookForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idLibraryAuthor: parseInt(this.props.match.params.idLibraryAuthor) > 0 ? this.props.match.params.idLibraryAuthor : 0,
      title: parseInt(this.props.match.params.idLibraryAuthor) > 0 ? 'Gestione Autore' : 'Nuova Autore',
      parentList: [{ label: 'Seleziona', value: 0 }]
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            <AdminLibraryAuthor idLibraryAuthor={this.state.idLibraryAuthor} />
          </Container>
          {/*
            (parseInt(this.state.idLibraryAuthor) > 0) &&
            (<LibraryCmsAuthor idLibraryAuthor={parseInt(this.state.idLibraryAuthor)} />)
          */}
        </section>
      </>
    );
  }

}

export default withRouter(LibraryCmsBookForm);
