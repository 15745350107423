import React, { Component } from 'react';
import { MRadioButton } from 'components/forms';

export class FormCV extends Component {

    VALUES = ['CV', 'CVL'];

    PAYLOAD =
        {
            options: [
                { label: 'Corso di Formazione online di 30 ore + verifica finale', value: 'CV' },
                { label: 'Corso di Formazione online di 30 ore + n. 5 ore di lezioni in presenza + verifica finale', value: 'CVL' },
            ]
        }

    state = {
        validationError: ''
    }

    getValue() {

        const { baseCode, value } = this.extractCode()

        const pluginValue = {
            courseCode: value + '-' + baseCode
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        const { value } = this.extractCode();
        const isValid = (value.length > 0);
        if (!isValid) {
            validationError += 'Dovresti specificare il corso';
        }

        this.setState({ validationError })

        return (isValid);
    }

    onChange = (id, value) => {

        const { baseCode } = this.extractCode();
        const courseCode =  value + '-' + baseCode;

        if (this.props.onChangeCourseCode)
            this.props.onChangeCourseCode(courseCode);
    }

    extractCode() {

        const { courseCode } = this.props;
        const index = courseCode.indexOf('-');

        const value = courseCode.substring(0, index);
        const baseCode = courseCode.substring(index+1, courseCode.length);
       // console.log(value, baseCode)

        return { baseCode, value };
    }

    render() {

        const { value } = this.extractCode();

        return (
            <>
                <h6>Selezionare il corso desiderato</h6>
                <hr />
                <MRadioButton onChange={this.onChange} value={value} id="condition" payload={this.PAYLOAD}></MRadioButton>
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{this.state.validationError}</span></>)}
                <hr />
            </>
        );
    }
}