import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';

import { ROUTES } from 'const';



const FIELDS_GROUP = [
    [
        {
            id: "aa",
            field: "aa",
            label: "Anno Accademico",
            component: MInput,
            type: "number",
            className: "col-md-3"
        },
        {
            id: "code",
            field: "code",
            label: "codice",
            component: MInput,
            type: "text",
            className: "col-md-3"
        }
    ],
    [
        {
            id: "title",
            field: "title",
            label: "titolo",
            component: MInput,
            type: "text",
            className: "col-md-12"
        }
    ],
    [
        {
            id:"description",
            field: "description",
            label: "descrizione",
            component: MTextArea,
            rows: 3,
            className: "col-md-12"
        }
    ]
];

export class AdminRepoQuizForm extends ValidatedForm {

    ERROR_MESSAGES = {
        aa: "Anno Accademico non valido",
        code: "Codice corso non valido",
        title: "Titolo corso non valido"
    };

    emptyFields = {
        aa: '',
        code: '',
        title: '',
        description: ''
    };

    validation = {
        aa: value => /^\d{4}$/i.test(value) && value !== null,
        code: value => /^[a-z0-9]+$/i.test(value) && value !== null,
        title: value => /^[a-z0-9:!?.,-/àèéì'òù,/\s"]+$/i.test(value)
    };

    //--- ---
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idRepoQuiz) > 0 ? false : true
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        this.loadRemote()
    }

    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idRepoQuiz) <= 0) {
            return false;
        }

        let filter = {
            searchFilter:{
              id: parseInt(this.props.idRepoQuiz)
            }
        }   

        adminService.loadAllRepoQuiz(filter)
            .then(({ data }) => {

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.repoQuiz
                );
               
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
                this.setState({loading:false});
            });
    }

    onBackPage = () => {
        window.history.back();
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        if (parseInt(this.props.idRepoQuiz) > 0) {
            adminService.updateRepoQuiz(payload).then(
                (result) => {
                    this.onCancel();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertRepoQuiz(payload).then(
                (result) => {
                    window.location.href = ROUTES.REPOQUIZ_FORM + '/' + result.data.payload.repoQuiz.id;
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    console.log(errors)
                    networkErrorHelper.notify(errors);
                }
            });
        }
    };



    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idRepoQuiz) === 0) {
            formActive = true;
        }
        
        const { formValid, code } = this.renderFields(FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idRepoQuiz) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
