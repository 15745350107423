import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MSelect,
    MCE
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,    
    adminService,
} from "libs";

import { QUIZ_QUESTION_RENDER_MODE } from 'const';

import {
    Card, CardBody, Button
} from 'design-react-kit';

import _ from 'lodash';

import ReactHtmlParser from 'react-html-parser';
import { BoxInfo } from "components/misc";


export class AdminCourseFormQuizQuestion extends ValidatedForm {

    ERROR_MESSAGES = {
        order: "Ordine non valido",
        grade: "Punteggio non valido",
        label: "Formato domanda da verificare",
        type: "Tipo domanda non valido",
        renderMode: "Valore richiesto"
    };

    emptyFields = {
        order: 1,
        grade: "0",
        label: "",
        type: "",
        renderMode: ""
    };

    validation = {
        order: value => /^\d*$/.test(value),
        grade: value => /^[0-9.]+$/i.test(value),
        label: value => value.length > 3,
        type: value => /^[a-z]+$/i.test(value),
        //renderMode: value => value.length > 3,
    };


    FIELDS_GROUP = [
        [
            {
                id: "type",
                field: "type",
                component: MInput,
                type: "hidden"
            },
            {
                id: "order",
                field: "order",
                label: "Ordine",
                component: MInput,
                type: "number",
                className: "col-sm-6 col-md-6 col-lg-4"
            },
            {
                id: "grade",
                field: "grade",
                label: "Punteggio",
                component: MInput,
                className: "col-sm-6 col-md-6 col-lg-4"
            },
            {
                id: "renderMode",
                field: "renderMode",
                label: "tipo di formattazione",
                component: MSelect,
                payload: { options: QUIZ_QUESTION_RENDER_MODE },
                className: "col-sm-6 col-md-6 col-lg-4"
            }
        ],
        [
            {
                id: "label-question",
                field: "label",
                label: "Scrivi la domanda",               
                component: MCE,
                config: {
                    height: 200,
                },
                type: "text",
                className: "col-md-12"
            }
        ]
    ];

    //--- ---
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: this.props.formActive,
            idResource: parseInt(this.props.idResource) > 0 ? this.props.idResource : 0,
            idQuiz: 0,
            showFormAddQuestion: false,
            answerType: "",
            idQuestion: parseInt(this.props.idQuestion) > 0 ? this.props.idQuestion : null,
            model: this.props.model,
            idRepoQuiz: parseInt(this.props.idRepoQuiz) > 0 ? this.props.idRepoQuiz : 0
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        if(parseInt(this.state.idQuestion) > 0){  
            this.setState({
                showFormAddQuestion: true
            })

            //--- popolate form ---
            let values = _.omit(this.props.questionValue, ['createdAt', 'updatedAt']);

            const newState = {
                ...values,
                defaultValues: {
                    ...values
                },
                answerType: values.type
            };

            this.setState(newState);
        }
    }//componentDidMount


    onCancel = () => {
        if(this.state.idQuestion === null){
            this.setState({
                showFormAddQuestion: false,
                answerType: ""
            })

            this.resetForm();
        }else{
            this.setState({
                formActive: !this.state.formActive
            })            
        }
    }//onCancel
    

    addQuestion = (answerType) =>{
        /** */
        const newState = {
            ...this.emptyFields,
            defaultValues: {
                ...this.emptyFields
            }
        };
        this.setState(newState);

        this.setState(state => {
            state.showFormAddQuestion = true;
            state.answerType = answerType;
            state.idQuestion = null;

            return state;
        });
    }//addQuestion

    //--- ---

    //--- Start SaveRemote ---
    saveRemote = () =>{
        
        const payload = payloadBuilder(this.state);
        let resAPI = null;

        if (parseInt(this.state.idQuestion) > 0) {

            if(this.state.model === 'Course'){
                resAPI = adminService.updateQuizQuestions(payload).then((result) => {
                        this.setState({formActive: !this.state.formActive});
                        this.props.loadQuestions(payload.idQuiz);
                })
            }

            if(this.state.model === 'repoQuiz'){
                resAPI = adminService.updateRepoQuizQuestions(payload).then((result) => {
                        this.setState({formActive: !this.state.formActive});
                        this.props.loadQuestions(payload.idQuiz);
                })
            }

            resAPI.catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        }else{

            if(this.state.model === 'Course'){
                payload.idResource = this.state.idResource;
                resAPI = adminService.insertQuizQuestions(payload).then((result) => {
                        this.setState({idQuiz: result.data.payload.quizQuestion.idQuiz});
                })
            }

            if(this.state.model === 'repoQuiz'){
                payload.idRepoQuiz = this.state.idRepoQuiz;
                resAPI = adminService.insertRepoQuizQuestions(payload).then((result) => {
                        this.setState({idQuiz: result.data.payload.repoQuizQuestion.idQuiz});
                })
            }

            resAPI
            .then(()=>{    
                this.props.reloadAccordion();
                this.onCancel();
            })
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        Object.keys(this.validation)
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        }
    }//saveRemote
    //--- End SaveRemote ---


    //--- ---
    renderMode = (formActive, defaultValues, mode) => {

        defaultValues.type = mode;
        //this.FIELDS_GROUP[1][0].id = this.FIELDS_GROUP[1][0].id + Math.floor((Math.random() * 1000) + 1);
        var { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>                
                {(this.state.showFormAddQuestion) && (
                <Card key={defaultValues.id} tag="div" className="card-big mt-4" >
                    <CardBody tag="div" className="card-body-inAccordion">
                        <form className="user">
                            {code}
                        </form>

                        <div className="mt-4 mb-3">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                                    
                            {(this.state.idQuestion > 0) && <Button color="primary bg-dark" className="mx-2 mt-6" onClick={this.props.deleteRecord}>Elimina</Button>}
                        </div>

                        <BoxInfo className="text-left mt-5 fix-quiz-form">
                            <div className="font-weight-bold">Anteprima domanda:</div>
                            {ReactHtmlParser(defaultValues.label)}
                        </BoxInfo>
                    </CardBody>
                </Card>)}
            </>
        );
    }//renderMode


    //--- ---

    
    render() {

        let { formActive, defaultValues } = this.state;        
        let code = this.renderMode(formActive, defaultValues, this.state.answerType);

        return (
            <>{code}</>
        );
    }
}
