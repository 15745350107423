import React, { Component } from "react";
import { Title } from "components/misc";
import { Button, Container, Col } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminExamReview30Cfu } from "components/forms/admin/AdminExamReview30Cfu";
import { ROUTES } from "const";

class Review30Cfu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      idExamReview: parseInt(this.props.match.params.idExamReview) > 0 ? this.props.match.params.idExamReview : 0
    }
  }

  goBack() {
    window.location.href = ROUTES.DASHBOARD;
  }

  render() {

    const { idExamReview } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{"Revisione"}</Title>
            <Col lg={{ size: 12 }} className="text-right">
                  <Button onClick={this.goBack} color="btn btn-primary bg-dark m-2 p-3">Torna Indietro</Button>
            </Col>
            <AdminExamReview30Cfu idExamReview={idExamReview} />
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(Review30Cfu);
