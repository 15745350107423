import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    ticketService,
    invalidFieldsMapper,
    networkErrorHelper
} from "libs";

import dayjs from 'dayjs';

import {
    Card,
    CardBody
} from 'design-react-kit';


import { getCurrentUser } from 'store/actions';


export class CourseTicketsForm extends ValidatedForm {

    ERROR_MESSAGES = {
        subject: "Oggetto della domanda obbligatorio",
        body: "Testo della domanda obbligatorio",
    };

    emptyFields = {
        subject: '',
        body: ''
    };

    validation = {
        subject: value => /^[a-z0-9!?.,-/àèé’'ìòù/\s"]+$/i.test(value),
        body: value => /^[a-z0-9!?.,-/àè’'éìòù/\s"]+$/i.test(value),
    };


    FIELDS_GROUP = [
        [
            {
                id: "subject",
                field: "subject",
                label: "Oggetto",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "body",
                field: "body",
                label: "Messaggio",
                component: MTextArea,
                rows: 6,
                className: "col-md-12"
            }
        ]
    ];

    //--- ---
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true
        }

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }


    onCancel = () => {
        this.resetForm();
        this.props.formTicketShow(false);
    }//onCancel


    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        //--- fix payload to send on mnemosine-api ---
        const user = getCurrentUser();
        
        payload.idStudent    = user.id;
        payload.status       = 'aperto';
        payload.rCode        = this.props.rCode;
        payload.dateCreation = dayjs().format('YYYY-MM-DD H:mm:s')
        
        ticketService.insertTicket(payload).then((data) => {
            if((data.data.status).toLowerCase() === 'ok'){
                this.onCancel();
                this.props.myTicketsRefresh(this.props.rCode);
            }else{
                //calogero
            }
        })
        .catch(errors => {
            if (networkErrorHelper.is422(errors)) {
                const newState = invalidFieldsMapper(
                    errors,
                    this.state,
                    Object.keys(this.validation)
                );
                this.setState({ ...newState });
            } else {
                networkErrorHelper.notify(errors);
            }
        });
    };//saveRemote


    render() {
        
        let { formActive, defaultValues } = this.state;
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);
        
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={this.onCancel}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );

    }

}