import React, { Component } from 'react';

import {
    Alert
} from 'design-react-kit';

export class BoxDanger extends Component {

    render() {
        return (
            <Alert color="danger" tag="div"  {...this.props}     >
                {this.props.children}
            </Alert>
        )
    }
}