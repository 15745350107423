import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export class ModalNotification {

    static render(message, title) {

        store.addNotification({
            title: title,
            message: message,
            type: "info",
            insert: "top",
            container: "center",           
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }
}