import React, { Component } from "react";
import { withRouter} from 'react-router-dom';
import { MSection, PDFView } from "components/misc";
import { Button, Icon } from "design-react-kit";


class ShowFile extends Component {
  
    goBack = () => {
        window.history.back();
    }

    render() {

        const { fileId } = this.props.match.params;
        const fileUrl = process.env.REACT_APP_API_URL + '/lessonliveschedule-file-slide/download/' + fileId;
        return (<>
            <Button color="primary" outline  onClick={this.goBack} className="mx-5 float-right"><Icon icon="it-presentation" /> TORNA INDIETRO</Button>
            <MSection>
                <PDFView file={fileUrl} canDownload={false} ></PDFView>
            </MSection>
        </>);
    }
}

export default withRouter(ShowFile);