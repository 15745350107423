import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea,
    MToggle,
    MSelect
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
    stringHelper
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';

import ReactHtmlParser from 'react-html-parser';

export class AdminBlogCategory extends ValidatedForm {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idBlogCategory) > 0 ? false : true,
            forceUpdateParentList: 0
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    //--- ---    

    emptyFields = {
        isVisible: false,
        isTop: false,
        idParent: 0,
        title: '',
        subTitle: '',
        summary: '',
        slug: ''
    }

    parentList = []


    FIELDS_GROUP = [   
        [
            {
                id: "idParent",
                name: "idParent",
                field: "idParent",
                label: "Associa a",
                payload: { options: this.parentList },
                component: MSelect,
                className: "col-md-6 mt-2"
            },
            {
                id: "isVisible",
                name: "isVisible",
                field: "isVisible",
                label: "Visibile",
                component: MToggle,
                className: "col-md-3"
            },
            {
                id: "isTop",
                name: "isTop",
                field: "isTop",
                label: "in Evidenza",
                component: MToggle,
                className: "col-md-3"
            }
        ],
        [
            {
                id: "title",
                field: "title",
                label: "titolo categoria",
                component: MInput,
                type: "text",                
                onChange: (_, value) => this.onChangeMakeSlug(_, value), 
                className: "col-md-12"
            }
        ],
        [
            {
                id: "subTitle",
                field: "subTitle",
                label: "sotto titolo categoria",
                component: MInput,
                type: "text",
                className: "col-md-12"
            }
        ],
        [
            {
                id: "summary",
                field: "summary",
                label: "Descrizione",
                component: MTextArea,
                rows: 5,
                className: "col-md-12"
            }
        ],
        [
            {
                id: "slug",
                field: "slug",
                label: "URL (slug)",
                component: MInput,
                type: "text",
                className: "col-md-12 mt-4"
            }
        ]
    ];

    ERROR_MESSAGES = {
        title: "Titolo non valido (max 255 caratteri)",
        subTitle: "Sotto Titolo non valido",
        summary: "Massimo 255 caratteri",
        slug: "Slug (URL) non valido"
    };

    validation = {
        title: value => /^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value) && value.length <= 255,
        subTitle: value => !value || (/^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value) && value.length <= 255),
        summary: value => { return (value !== '' && value.length > 512) ? false : true} ,
        slug: value => value.length>0 && value.length <= 255,
    };

    
    //--- ---

    componentDidMount(){
        this.loadRemote();
        
        // load list category and popolate MSelect
        this.loadRemoteParent();
    }    

    onChangeMakeSlug = (_, value) => {
        const ret = stringHelper.makeSlug(value);
       
        this.setState({
            slug: ret,
            defaultValues:{
                ...this.state.defaultValues,
                slug: ret
            }
        })
    }

    loadRemoteParent(){       
        let filter = { searchFilter:{ isVisible: true } };
        
        adminService.loadAllBlogCategory(filter).then(({ data }) => {
            this.parentList.push({ label: 'Seleziona', value: 0 });
            Object.entries(data.payload.blogCategories).forEach(([key, val]) => {
                this.parentList.push(
                    {
                        label: ReactHtmlParser(val.title),
                        value: val.id
                    }
                )
            });

            this.setState({
                forceUpdateParentList: Math.floor((Math.random() * 1000) + 1)
            })
        })
        .catch(error => {
            console.log(error);
            if (!networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            }
        });
    }//loadRemoteParent

    
    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idBlogCategory) <= 0) {
            return false;
        }

        let filter = {
            searchFilter:{
              id: parseInt(this.props.idBlogCategory)
            }
        }

        adminService.loadAllBlogCategory(filter)
            .then(({ data }) => {
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.blogCategories,
                );
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false,
                };
                
                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }//loadRemote

    onBackPage = () => {
        window.history.back();
    }//onBackPage

    saveRemote = () => {
        let payload = payloadBuilder(this.state);
       
        if (parseInt(this.props.idBlogCategory) > 0) {
            adminService.updateBlogcategory(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertBlogcategory(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    }//saveRemote

    //--- ---

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idBlogCategory) === 0) {
            formActive = true;
        }

        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idBlogCategory) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
