import React from "react";

import { FormEnabler } from "components/forms";
import { networkErrorHelper, userAddressService, userService } from "libs";
import { ADDRESS_TYPES_ACCESSORS } from 'const';
import { MAddress } from "components/forms/shared/MAddress";

import { ValidatedForm } from "components/forms/ValidatedForm";
import {  SupportoTecnico } from "components/misc";

import {
  Card, CardBody,
  CardFooter, CardSignature, Button
} from "design-react-kit";

export class UserAddress extends ValidatedForm {


  FIELDS_GROUP = [
    [
      {
        id: "addressId",
        name: "addressId",
        field: "addressId",
        label: "Indirizzo",
        component: MAddress,
        className: "col-md-12",
      },
    ]
  ];

  emptyFields = {
    addressId: 0,
  };

  state = {
    isNew: false,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields
    }
  };
  
  /*
    validation = {
      addressId: value => value !== null && value > 0,
    };
  
    ERROR_MESSAGES = {
      addressId: "Indirizzo non valido",
    }
  */
  
  saveRemote() {
    this.setState({ formActive: false, isNew: false });
   
    setTimeout(()=>{ userAddressService.address(this.state.defaultValues.addressId)
      .then(({ data }) => {
        //console.log(data.payload.address)
        this.setState({ dataAddress: data.payload.address });
      
        //--- controllo il bottone copia inirizzo esterno al componente ---
        if(this.props.type === 'addressId' && typeof(this.props.copyBtnUserAddress) === 'function' && data.payload.address.address !== ''){
          this.props.copyBtnUserAddress(true);
        }

      })
    }, 500);
  }

  loadRemote(additionalState = {}) {

    const { type } = this.props;
    const accessKey = ADDRESS_TYPES_ACCESSORS[type];
    userService.address(accessKey)
      .then(({ data }) => {

        const addressId = (data.payload.address) ? data.payload.address.id : 0;
        const defaultValues =
        {
          addressId
        };

        let newState = {
          loading: false,
          isNew: (data.payload.address === undefined || data.payload.address.address == null || data.payload.address.address.length === 0),
          defaultValues,
          ...additionalState
        };

        //--- controllo il bottone copia inirizzo esterno al componente ---
        if(this.props.type === 'addressId' && typeof(this.props.copyBtnUserAddress) === 'function' && !newState.isNew){
          this.props.copyBtnUserAddress(true);
        }

        userAddressService.address(addressId)
          .then(({ data }) => {
            this.setState({ dataAddress: data.payload.address });
          })

        this.setState(newState);

      })
      .catch(error => {

        console.log(error);
        const defaultValues =
        {
          addressId: 0
        };

        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({
          loading: false,
          defaultValues,
          ...additionalState
        });
      });
  }

  showForm = () => {
    this.setState({ formActive: true });
  }

  renderInfo() {

    const { isNew, dataAddress } = this.state;
    const cardProps = {
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      actionLabel: 'Inserisci',
      isReadOnly: this.props.isReadOnly,
      actionClick: this.showForm
    }

    if (isNew) {
      cardProps.actionLabel = 'Inserisci';

    } else if (dataAddress) {
      cardProps.actionLabel = 'Modifica';

      cardProps.content = (
        <>
          <b>Indirizzo:</b> {dataAddress.address} {dataAddress.houseNumber}<br />
          {dataAddress.isForeign
            ? (<>
              <b>ZIP Code:</b> {dataAddress.zip}<br />
              <b>Citta:</b> {dataAddress.cityForeign}<br />
              <b>Nazione:</b> {dataAddress.nation}<br />
            </>) : (<>
              <b>CAP:</b> {dataAddress.cap}<br />
              <b>Citta:</b> {dataAddress.city}<br />
              <b>Provincia:</b> {dataAddress.province}<br />
            </>)}
          <b>Frazione:</b> {dataAddress.locality}<br />
          <b>Annotazioni Indirizzo:</b> {dataAddress.note}<br />
        </>
      );
    }

    return (<>
      <Card noWrapper={false} tag="div" >
        <CardBody tag="div" className="p-0">
          {cardProps.content}
          <CardSignature>{cardProps.signature}</CardSignature>
          {!this.props.isReadOnly ?
            (<>
              <CardFooter >
                {cardProps.actionClick && (
                  <Button
                    className="float-right"
                    color="primary"
                    outline
                    tag="button"
                    onClick={() => { cardProps.actionClick(true) }
                    }>
                    {cardProps.actionLabel}
                  </Button>
                )}
              </CardFooter>
            </>)
            : (
              <CardFooter className="text-right" >Dati non modificabili. Se hai riscontrato degli errori nei dati inseriti  <SupportoTecnico /></CardFooter>
            )}
        </CardBody>
      </Card>
    </>)
  }

  renderForm() {

    const { formActive, defaultValues, loading, isNew } = this.state;

    if (loading) return <></>;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);
    // to enable save button (because the MAddress is the unique component)
    const formValid = true;
    return (
      <>
        <form className="user" id="userAddress_form">
          {code}
        </form>
        <div >
          <FormEnabler
            isNew={isNew}
            onToggle={this.toggleForm}
            isFormActive={formActive}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            disableSave={!formValid}
            hideEdit={this.props.isReadOnly}
          />
        </div>
      </>
    );
  }

  render() {

    const { formActive } = this.state;
    if (formActive) {
      return this.renderForm();
    } else {
      return this.renderInfo();
    }

  }
}
