import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { networkErrorHelper, isEmptyObject, dateHelper, learningService, CourseHelper, userService } from "libs";

import { MnemoLoading, Title, MSection, ModalHandler } from 'components/misc';
import { Container, Row, Col, Button } from 'design-react-kit';

class CourseDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dataUserCourse: false,
      dataDuration: false,
      dataActivityLog: false
    };
  }

  componentDidMount() {
    this.loadRemote(this.props.match.params.userId, this.props.match.params.idCourse);
  }//componentDidMount

  loadRemote = (userId, idCourse) => {
    learningService.myCourseByID(userId, idCourse).then(({ data }) => {
      this.setState({
        dataUserCourse: data.payload.userCourse,
        dataDuration: data.payload.duration,
        dataActivityLog: data.payload.activityLog
      })
    })
      .catch(errors => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }//loadRemote

  setElapsedTime = (obj)=>{
    const { duration, userId, courseCode } = obj;
    userService.setuserDuration({userId, courseCode, duration}).then(({ data }) => {
      window.location.reload();
    })
    .catch(errors => {
      console.log(errors);
      networkErrorHelper.notify(errors);
    });
  }//setElapsedTime
  
  ModalSetElapsedTime(duration, userId, courseCode) {
    ModalHandler.show({duration, userId, courseCode}, 'Informazioni',
      'vuoi procedere?', null, this.setElapsedTime);
  }
  


  HTMLData = (dataUserCourse, dataDuration, dataActivityLog) => {

    const { enabled, registrationCode, dateStart, dateEnd, courseCode, courseId, userId } = dataUserCourse;
    const elapsed       = dateHelper.secondsToTime(dataDuration.Item.timespan);
    const durationByLog = CourseHelper.calcDurationByLog(dataActivityLog);
    const timeFromLog   = dateHelper.secondsToTime(durationByLog);

    return (<>
      <div><b>Abilitato:</b> {(enabled ? 'SI' : 'NO')}</div>
      <div><b>Codice Registrazione:</b> {registrationCode}</div>
      <div><b>Codice Corso:</b> {courseCode} ({courseId})</div>
      <div><b>Data inizio:</b> {(dateStart !== null) ? dateHelper.toITDate(dateStart) : ''}</div>
      <div><b>Data fine:</b> {(dateEnd !== null) ? dateHelper.toITDate(dateEnd) : ''}</div>
      <div><b>Tempo trascorso in piattaforma:</b> {elapsed}</div>
      <div><b>Tempo calcolato dal log attività:</b> {timeFromLog}</div>
      <hr />
      <Button
        className="btn btn-primary bg-dark"
        aria-label="Riassegna Tempo Trascorso in Piattaforma dal log attività"
        onClick={()=>{this.ModalSetElapsedTime(durationByLog, userId, courseCode)}}
      >
          Riassegna Tempo Trascorso in Piattaforma dal log attività
      </Button>
    </>)

  }//HTMLData

  //--- ---

  render() {

    const { dataUserCourse, dataDuration, dataActivityLog } = this.state;
    
    if (isEmptyObject(dataUserCourse)) { return <MnemoLoading />; }

    return (
      <>
        <Container>
          <Row>
            <Col>
              <Title className="m-0 p0">Codice Corso: {dataUserCourse.courseCode}</Title>
            </Col>
          </Row>

          <MSection className="mb-4">
            {this.HTMLData(dataUserCourse, dataDuration, dataActivityLog)}
          </MSection>
        </Container>
      </>
    );
  }

}

export default withRouter(CourseDetail);