import { XPay } from "./xpay";

export const creditCardHelper = {

    startPayment(email, codTrans, amount, tableName, company = 'TEST') {
        
        const xpay = new XPay();

        // configure from company
        xpay.configure(company);
        
        // creat the link
        const xpayLink = xpay.getUrl({email, codTrans, amount, tableName});
              
        // open link
        window.location.href= xpayLink;
    },
    
}