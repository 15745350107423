import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MTextArea,
    MSelect
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
} from "libs";

import { revisionService } from "libs/api/revisionService";

import dayjs from 'dayjs';

import ReactHtmlParser from 'react-html-parser';

import {
    Card,
    CardBody,
} from 'design-react-kit';
import { RESULTQUIZ } from "const";


export class AdminQuizReview extends ValidatedForm {

    ERROR_MESSAGES = {
        //revisionVote: "Voto non valido"
    };

    emptyFields = {
        revisionDate: '',
        revisionNote: '',
        //revisionVote: '',
        revisionResult: '',
    };

    validation = {
        //revisionVote: value => /^[0-9]+$/i.test(value),
        revisionResult: value => (value && value.length>0),
    };

    FIELDS_GROUP = [
        [
            {
                id: "revisionResult",
                field: "revisionResult",
                label: "Risultato revisione",
                component: MSelect,
                payload: { options: RESULTQUIZ },
                className: "col-md-8"
            },
            /*{
                id: "revisionVote",
                field: "revisionVote",
                label: "Voto revisione",
                component: MInput,
                type: "number",
                className: "col-md-4"
            }*/
        ],
        [
            {
                id: "revisionNote",
                field: "revisionNote",
                label: "Note revisione",
                component: MTextArea,
                type: "text",
                className: "col-md-8"
            },
        ],
    ];

    //--- ---

    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            idQuizReview: this.props.idQuizReview,
            isReadOnly: false
        }
    }

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idQuizReview) <= 0) {
            return false;
        }

        revisionService.getQuizReview(parseInt(this.props.idQuizReview))
            .then(({ data }) => {

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.quizReview
                );

                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading: false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
                this.setState({ loading: false });
            });
    }//loadremote


    onCancel = () => {
        this.resetForm();
    }//onCancel

    onBackPage = () => {
        window.history.back();
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        if (parseInt(this.props.idQuizReview) > 0) {
            payload.revisionDate = dayjs().format('YYYY-MM-DD H:mm:s');
            payload.toFeedback = true;
            revisionService.updateQuizReview(payload).then(
                (result) => {
                    this.onBackPage();
                }
            ).catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    };


    render() {
        let { formActive, defaultValues, idQuizReview, isReadOnly } = this.state;

        if (defaultValues.revisionDate) {
            formActive = false;
            isReadOnly = true;
        }

        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>

                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                    <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="question" class="active">Modulo</label>
                                    {ReactHtmlParser(defaultValues.numberModule)}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="question" class="active">Domanda</label>
                                    {ReactHtmlParser(defaultValues.question)}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="answer" class="active">Risposta</label>
                                    {defaultValues.answer}
                                </div>
                            </div>
                        </div>
                        <form className="user" id="quizreviewform">
                            {code}
                        </form>
                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(idQuizReview) > 0 ? this.onCancel : this.onBackPage}
                                isFormActive={formActive}
                                disableSave={!formValid}
                                hideEdit={isReadOnly}
                            />
                        </div>
                    </CardBody >
                </Card >
            </>
        );

    }

}