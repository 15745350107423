import { MInput } from "components/forms";
import { BoxDanger, MnemoLoading, Title } from "components/misc";
import { MTable } from "components/table";
import { Button, Container, Section } from "design-react-kit";
import { isEmptyObject } from "jquery";
import { adminService, networkErrorHelper } from "libs";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ViewerUDA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      rndKey: 0,
      whereObj: {
        keySearch: null,
        limit: 200,
      },
      searchFilter: {
        searchFilter: {},
      },
      searchField: {
        id: "finder",
        field: "finder",
        name: "finder",
        label: "cerca per data esame",
        type: "date",
        className: "form-control",
        onChange: (elm, value) => {
          this.onSearchForm(value);
        },
        value: "",
      },
    };
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: "id",
        Header: "Column 0",
        isVisible: false,
        accessor: "id",
      },
      {
        id: "fullName",
        Header: "Nome e Cognome",
        accessor: "fullName",
      },
      {
        id: "email",
        Header: "Email",
        accessor: "email",
      },
      {
        id: "mobile",
        Header: "Telefono",
        accessor: "mobile",
      },
      {
        id: "registrationCode",
        Header: "Codice Registrazione",
        accessor: "registrationCode",
      },
      {
        id: "status",
        Header: "Stato UDA",
        accessor: "status",
      },
      {
        id: "competitionClass",
        Header: "Classe di Conc.",
        accessor: "competitionClass",
      },
      {
        id: "forceEditig",
        Header: "Modifica",
        accessor: "forceEditig",
      },
    ],
    /*actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Abilita/Disabilita Modifica",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.props.toggleEditing({ id: value });
        },
      },
    ],*/
  };

  onFinder = () => {
    const keySearch = document.getElementById("finder").value;

    if (keySearch.length < 3 || keySearch === "") {
      this.setState({
        loading: false,
        payload: [],
        rndKey: Math.floor(Math.random() * 1000 + 1),
      });
      return false;
    }

    const whereObj = {
      ...this.state.whereObj,
      keySearch: keySearch,
    };

    this.loadRemote(whereObj);
  }; //onSearch

  loadRemote(whereObj) {
    adminService
      .getUDAByExamDate(whereObj)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.result).forEach(([key, val]) => {
          //--- manage exception to render human-readable data ---
          if (val.status === null) {
            val.status = "--- ---";
          }
          /*if (val.enabled) {
            val.tmpEnabled = "abilitato";
          } else {
            val.tmpEnabled = "disabilitato";
          }*/

          items.push(val);
        });

        this.setState({
          loading: false,
          payload:
            Object.entries(data.payload.result).length === 0 ? [] : items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
        //--- end: prepare reactTable ---
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          this.setState({
            loading: false,
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });

          networkErrorHelper.notify(error);
        } else {
          this.setState({
            loading: false,
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  onSearchForm = () => {
    const finder = document.getElementById("finder").value.trim();
    this.setState({
      ...this.state,
      searchField: { ...this.state.searchField, value: finder },
    });
  }; //onSearchForm

  onDownload = () => {
    const array = this.state.payload;
    let str =
      "INome e Cognome;Email;Telefono;Codice Corso; Stato UDA; Codice Registrazione; Classe di Concorso;\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (index === "id" || index === "userId" || index === "version" || index === "revisionProgress" || index === "revisionProblem" || index === "createdAt") continue;
        if (line !== "") line += ";";
        line += array[i][index];
      }

      str += line + "\r\n";
    }

    let downloadLink = document.createElement("a");
    downloadLink.href =
      "data:text/csv;charset=utf-8," + encodeURIComponent(str);
    downloadLink.download = "consultazione_UDA.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }; //onDownload

  //--- ---

  render() {
    let { loading } = this.state;

    //--- ---

    return (
      <>
        <Section className="m-0 p-0">
          <Title>Consultazione UDA</Title>
        </Section>

        <Section className="my-4 p-0">
          <div className="form-row m-0 p-0">
            <div className="col-md-4">
              <MInput {...this.state.searchField} className="mb-0" />
            </div>
            <div className="col-md-1">
              <Button
                color="primary bg-dark"
                className="d-block"
                onClick={() => {
                  this.onFinder();
                }}>
                CERCA
              </Button>
            </div>
            {this.state.payload.length > 0 && (
              <div className="col-md-1 text-left">
                <Button
                  color="primary bg-dark"
                  className="d-block"
                  onClick={() => {
                    this.onDownload();
                  }}>
                  DOWNLOAD
                </Button>
              </div>
            )}
          </div>
        </Section>

        {loading ? (
          <MnemoLoading></MnemoLoading>
        ) : (
          <>
            <Container>
              <MTable
                key={this.state.rndKey}
                tableData={this.state.payload}
                tableConfig={this.tableConfig}
              />
            </Container>

            {this.state.payload.length === 0 &&
              !isEmptyObject(this.state.searchFilter.searchFilter) && (
                <BoxDanger key="nrp-1" className="my-3">
                  <div className="small text-info">Non sono presenti dati.</div>
                </BoxDanger>
              )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(ViewerUDA);
