
import React, { Component } from 'react';
import { MnemoLoading, BoxDanger, Title } from 'components/misc';
import { networkErrorHelper, adminService } from "libs";
import { CSVLink } from "react-csv";

import { MTable } from "components/table";
import { Container } from 'design-react-kit';


export class ContactRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rndKey: 0,
            payload: [],
            type: '60cfu'
        };
    }

    headers = [
        { label: "Nome", key: "name" },
        { label: "Cognome", key: "surname" },
        { label: "Email", key: "email" },
        { label: "Telefono", key: "mobile" },
        { label: "Regione", key: "region" },
        { label: "Città", key: "city" },
        { label: "Tipo Adesione", key: "type" },
        { label: "30 CFU", key: "competitionClass130" },
        { label: "36 CFU", key: "competitionClass136" },
        { label: "60 CFU", key: "competitionClass160" },
        { label: "30 CFU", key: "competitionClass230" },
        { label: "36 CFU", key: "competitionClass236" },
        { label: "60 CFU", key: "competitionClass260" },
        { label: "30 CFU", key: "competitionClass330" },
        { label: "36 CFU", key: "competitionClass336" },
        { label: "60 CFU", key: "competitionClass360" },
        { label: "30 CFU", key: "competitionClass430" },
        { label: "36 CFU", key: "competitionClass436" },
        { label: "60 CFU", key: "competitionClass460" },
        { label: "30 CFU", key: "competitionClass530" },
        { label: "36 CFU", key: "competitionClass536" },
        { label: "60 CFU", key: "competitionClass560" },

    ];


    //--- ---

    tableConfig = {
        columns: [
            {
                id: 'name',
                Header: 'Nome',
                accessor: 'name',
            },
            {
                id: 'surname',
                Header: 'Cognome',
                accessor: 'surname',
            },
            {
                id: 'email',
                Header: 'Email',
                accessor: 'email',
            },
            {
                id: 'mobile',
                Header: 'Telefono',
                accessor: 'mobile',
            },
            {
                id: 'region',
                Header: 'Regione',
                accessor: 'region',
            },
            {
                id: 'city',
                Header: 'Città',
                accessor: 'city',
            },
        ],
    }

    //--- ---

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote() {

        this.setState({ loading: true });
        adminService.listAllContactRequest(this.state.type).then(({ data }) => {
            //--- start: prepare reactTable ---
            const items = [];
            Object.entries(data.payload.contactRequest).forEach(([key, val]) => {
                items.push(val);
            });

            this.setState({
                loading: false,
                payload: items,
                rndKey: Math.floor((Math.random() * 1000) + 1)
            });
            //--- end: prepare reactTable ---
        })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({
                        payload: [],
                        rndKey: Math.floor((Math.random() * 1000) + 1)
                    })
                }

                this.setState({ loading: false });
            });
    }

    generateExcel = () => {

    }

    render() {

        const { loading } = this.state;

        return (
            <>
                <Title>Lista adesioni 60 CFU</Title>
                <hr />
                <section className="my-4">
                    {loading ? (<MnemoLoading></MnemoLoading>) : (
                        <Container>
                            <div className="col-md-12 text-right">
                                <CSVLink
                                    data={this.state.payload}
                                    headers={this.headers}
                                    filename={"adesioni_60cfu.csv"}
                                    className="btn btn-dark"
                                    separator={";"}
                                    target="_blank"
                                >
                                    Esporta file Excel</CSVLink>
                            </div>
                            <MTable
                                key={this.state.rndKey}
                                tableData={this.state.payload}
                                tableConfig={this.tableConfig}
                            />
                        </Container>
                    )}

                    {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
                        <div className="small text-info">
                            Non ci sono dati da mostrare in database.
                        </div>
                    </BoxDanger>)}
                </section>
            </>);
    }

}