import { notNullObjectBuilder } from "libs";
import { ROUTES } from 'const';
import Axios from 'axios';
import { networkErrorHelper } from "libs/helpers";

const crypto = require('crypto');
const util = require('util');

const XPAY_CONF = {
    DIVISA: 'EUR',
    LINGUA: 'ITA',
    TCONTAB: 'I',
    EMAIL: 'versamenti@formazionedocenti.it',
    SELECTEDCARD: 'CC',

    RETURN_URL_OK: process.env.REACT_APP_HOME + ROUTES.PAYMENT_RESULT_OK,
    RETURN_URL_KO: process.env.REACT_APP_HOME + ROUTES.PAYMENT_RESULT_KO,
    RETURN_URL_MS: process.env.REACT_APP_API_URL + '/payment-ms',
    SERVLET_URL: 'https://ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet'
};

/**
 * XPay Nexi Service
 */
export class XPay {

    SRL = {
        KEY: process.env.REACT_APP_XPAY_SRL_KEY,
        ALIAS: process.env.REACT_APP_XPAY_SRL_ALIAS,
    };

    ASSOCIAZIONE = {
        KEY: process.env.REACT_APP_XPAY_ASSOCIAZIONE_KEY,
        ALIAS: process.env.REACT_APP_XPAY_ASSOCIAZIONE_ALIAS,
    };

    TEST = {
        KEY: 'TLGHTOWIZXQPTIZRALWKG',
        ALIAS: 'payment_3444153',
        SERVLET_URL:  'https://coll-ecommerce.cartasi.it/ecomm/ecomm/DispatcherServlet' // 'https://int-ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet' 
    };

    /**
     * init
     * @param configuration
     */
    configure(company, configuration) {

        company = company.toUpperCase();
        // init configuration
        configuration = configuration || {}; // init default, need to work on 5.7
        this._xpayConf = notNullObjectBuilder(
            XPAY_CONF,
            this[company],
            configuration,
        );        
    }

    /**
     * get payment nexi xpay url
     * @param transactionCode, quantity, macCode
     */
    getUrl(options) {

        // calculate the mac
        let shasum = crypto.createHash('sha1');
        let hashpayload = util.format('codTrans=%sdivisa=%simporto=%s%s',
            options.codTrans, this._xpayConf.DIVISA, options.amount * 100,
            this._xpayConf.KEY);

        shasum.update(hashpayload);
        const macCode = shasum.digest('hex');

        const paramsUrl = '/' + options.codTrans + '/' + options.tableName;
        const koUrl = this._xpayConf.RETURN_URL_KO + paramsUrl;
        const okUrl = this._xpayConf.RETURN_URL_OK + paramsUrl;
        const msUrl = this._xpayConf.RETURN_URL_MS + paramsUrl;

        // create the link
        let xpayLink = util.format(
            '%s?mac=%s&url_back=%s&alias=%s&url=%s&importo=%s&divisa=%s&codTrans=%s&languageId=%s&urlpost=%s&mail=%s&selectedcard=%s&tcontab=%s',
            this._xpayConf.SERVLET_URL,
            macCode,
            koUrl,
            this._xpayConf.ALIAS,
            okUrl,
            options.amount * 100,
            this._xpayConf.DIVISA,
            options.codTrans,
            this._xpayConf.LINGUA,
            msUrl,
            options.email,
            this._xpayConf.SELECTEDCARD,
            this._xpayConf.TCONTAB
        );

        // done
        return xpayLink;
    };

    sendRequest(options) {

        // calculate the mac
        let shasum = crypto.createHash('sha1');
        let hashpayload = util.format('codTrans=%sdivisa=%simporto=%s%s',
            options.codTrans, this._xpayConf.DIVISA, options.amount * 100,
            this._xpayConf.KEY);

        shasum.update(hashpayload);
        const macCode = shasum.digest('hex');

        const paramsUrl = '/' + options.codTrans + '/' + options.tableName;
        const koUrl = this._xpayConf.RETURN_URL_KO + paramsUrl;
        const okUrl = this._xpayConf.RETURN_URL_OK + paramsUrl;
        const msUrl = this._xpayConf.RETURN_URL_MS + paramsUrl;

        // create the link
        const data =
        {
            mac: macCode,
            url_back:koUrl,
            alias: this._xpayConf.ALIAS,
            url: okUrl,
            importo : options.amount * 100,
            divisa: this._xpayConf.DIVISA,
            codTrans: options.codTrans,
            languageId: this._xpayConf.LINGUA,
            urlpost: msUrl,
            mail: options.email,
            selectedcard: this._xpayConf.SELECTEDCARD,
            tcontab: this._xpayConf.TCONTAB
        }            
         
        Axios.post(this._xpayConf.SERVLET_URL, data).then((content) => {
                      
        })
            .catch(error => {
                networkErrorHelper.notify(error);
               console.log(error);
            });

     
    };

    /**
     * verify payment transaction
     * @param nexi response, macCode
     */
    verify(xpayResponse) {

        //check nexi response required params
        var requiredParams = ['codTrans', 'esito', 'importo', 'divisa', 'data', 'orario', 'codAut', 'mac'];
        var emptyParams = [];
        requiredParams.forEach(function (param, index) {
            if (param in xpayResponse) {
                //continue
            } else {
                emptyParams.push(param);
            }
        });

        if (emptyParams.length > 0) return false; //if not found params

        // calculate the mac
        let shasum = crypto.createHash('sha1');
        let hashpayload = util.format('codTrans=%sesito=%simporto=%sdivisa=%sdata=%sorario=%scodAut=%s%s',
            xpayResponse.codTrans, xpayResponse.esito, xpayResponse.importo, xpayResponse.divisa, xpayResponse.data, xpayResponse.orario, xpayResponse.codAut,
            this._xpayConf.XPAY_KEY);
        shasum.update(hashpayload);
        var macCalculated = shasum.digest('hex');

        // Verifico corrispondenza tra MAC calcolato e MAC di ritorno
        if (macCalculated.toString() !== xpayResponse.mac.toString()) {
            return false;
        }

        if (xpayResponse.esito === 'OK') {
            return true;
        } else {
            return false;
        }


    };
}
