import React, { Component } from "react";
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from "design-react-kit";
import { BoxDanger } from "components/misc";

import { MnemoLoading } from "components/misc";

import { NavLink } from "react-router-dom";
import { ROUTES } from "const";

class LessonLiveEdition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      searchFilter: {
        searchFilter: {},
      },
      rndKey: 0,
    };
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "title",
        Header: "Titolo",
        accessor: "title",
      },
      {
        id: "aa",
        Header: "A.A.",
        accessor: "aa",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.LESSON_LIVE_EDITION_FORM + "/" + value;
        },
      },
    ],
  };
  //--- ---

  componentDidMount() {
    this.loadLessonLiveEdition(this.state.searchFilter);
  }

  loadLessonLiveEdition(filter) {
    adminService
      .loadLessonLiveEdition(filter)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.lessonLiveEdition).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <div className="container mt-3 mb-4">
          <section className="m-0">
            <div className="form-row">
              <div className="col-md-12 text-right">
                <NavLink
                  to={ROUTES.LESSON_LIVE_EDITION_FORM}
                  className="btn btn-primary bg-dark mx-1">
                  Nuova Edizione
                </NavLink>{" "}
              </div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payload}
                  tableConfig={
                    this.state.searchFilter.searchFilter.deleted
                      ? this.tableConfigDelete
                      : this.tableConfig
                  }
                />
              </Container>
            )}

            {this.state.payload.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                Ricerca non valida.
                <div className="small text-info">Nessun dato disponibile.</div>
              </BoxDanger>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default LessonLiveEdition;
