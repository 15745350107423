import React from "react";

import {
    FormEnabler,
    ValidatedForm,
} from "components/forms";

import {
    userService,
    payloadBuilder,
    networkErrorHelper
} from "libs";

import { MInput } from 'components/forms/shared/MInput';
import { BoxDanger, BoxSuccess } from "components/misc";

import { ROUTES } from "../../const";
import { withRouter, Link } from 'react-router-dom';

import {
    Container
} from 'design-react-kit';
import { getCurrentUser, loggedOut } from "store/actions";



class PasswordResetToken extends ValidatedForm {

    ERROR_MESSAGES = {
        'password': 'La Password non è valida (lunghezza almeno 6 caratteri, almeno una lettera maiuscola e almeno un numero)',
        'passwordRepeat': 'Le due password non coincidono',
    };

    emptyFields = {
        password: '',
        passwordRepeat: ''
    };

    validation = {
        'password': value => /(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value),
        'passwordRepeat': (value, { password }) => password && value === password.value
    };

    FIELDS_GROUP = [
        [
            {
                id: "password",
                field: "password",
                label: "Password",
                component: MInput,
                type: "password",
                className: "col-md-6"
            },
            {
                id: "passwordRepeat",
                field: "passwordRepeat",
                label: "Ripeti Password",
                component: MInput,
                type: "password",
                className: "col-md-6"
            }
        ]
    ];

    onCancel = () => {
        this.setState({
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            msgTokenExpired: false,
            msgSuccessRecoveryPassword: false            
        })
    }//onCancel

    //--- ---
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            msgTokenExpired: false,
            msgSuccessRecoveryPassword: false,
            token: this.props.match.params.token
        }

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    saveRemote = () => {
        const payload = payloadBuilder(this.state);

        /** add current recovery token to payload*/
        payload.token = this.state.token;

        const user = getCurrentUser();

        if(typeof payload.token !== 'undefined'){
            userService.resetPasswordByToken(payload).then(
                (result) => {
                    if(result.status === 200){
                        this.setState({
                            msgSuccessRecoveryPassword: true
                        });

                        //--- timer off view message success ---
                        setTimeout(() => {
                            window.location.href = ROUTES.LOGIN;
                        }, 10000);
                    }
                }
            ).catch(errors => {
                if (networkErrorHelper.is422(errors) || networkErrorHelper.is404(errors)) {
                    this.setState({
                        msgTokenExpired: true
                    });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }//token !undefined

        //--- ---

        if(typeof payload.token === 'undefined' && parseInt(user.id)>0){

            delete payload.token;

            userService.resetPasswordOnLogged(payload).then(
                (result) => {
                    if(result.status === 200){
                        const res = loggedOut();
                        if(res.type === "logged_out"){
                            window.location.href = ROUTES.LOGIN;
                        }
                    }
                }
            ).catch(errors => {
                    networkErrorHelper.notify(errors);
            });
        }//token undefined
            
    };//saveRemote   


  render() {
        
    let { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
        <section className="my-4">
          <Container>
            <h1>Reimposta la password</h1>
            <p className="mt-3">
              Segui gli step successivi per concludere il cambio password.
            </p>            

            {(this.state.msgTokenExpired) && (<BoxDanger key="nrp-1">
              Richiesta non valida.
              <div className="small text-info">
                  Potrebbe essere scaduto il tempo disponibile per effettuare l'operazione richiesta.
                  <br/>
                  Puoi riprovare a reimpostare la password oppure effettuare una nuova richiesta per <Link to={ROUTES.PASSWORD_RESET}>Reimpostarla</Link>.
                </div>
            </BoxDanger>)}

            {(this.state.msgSuccessRecoveryPassword) && (<BoxSuccess key="nrp-2">
            La password del tuo Account è stata aggiornata.
            </BoxSuccess>)}

            {(!this.state.msgSuccessRecoveryPassword) && (
            <>
            <form className="user mt-5" id="courseform">
                {code}
            </form>

            <div>
                <FormEnabler
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    onToggle={this.toggleForm}
                    isFormActive={formActive}
                    disableSave={!formValid}
                />
            </div>
            </>)}
          </Container>
        </section>
      </>
    );
  }
}



export default withRouter(PasswordResetToken);
