import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, MSection, PDFView, SupportoTecnico, Title } from "components/misc";
import { mnemoService } from "libs";
import { Icon} from 'design-react-kit';
import { ROUTES } from "const";

class MUCourseDocs extends Component {   

    state = {
        loading: true,
        ladingError: false,
        rCode: '',
        manifestoPDF: '',
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        const payload = mnemoService.buildPayload('manifestoPDF', 'registrationCode', rCode);
        const manifestoPDF = process.env.REACT_APP_MNEMO_URL + '/course/?payload=' + encodeURI(payload);
        this.setState({ rCode, manifestoPDF, loading: false });
    }       

    render() {

        const { loading, loadingError, rCode, manifestoPDF } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
      
        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`}  className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Bando</Title>
            <MSection>
            {(loadingError)
                    ? <>
                        <BoxDanger className="mt-4">
                            Si è verificato un errore durante il caricamento del file, se il problema persiste puoi <SupportoTecnico />
                        </BoxDanger>
                    </>
                    : <>
                        <PDFView file={manifestoPDF}  canDownload={true} showAlert={false} ></PDFView>
                    </>
                }

            </MSection>
        </>);
    }
}

export default withRouter(MUCourseDocs);