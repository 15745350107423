import { MSection } from "components/misc";
import React, { Component } from "react";
import { XPay } from "libs/services";

export class Payment extends Component {

    state = {
    };

    constructor(props) {
        super(props);

        this.readConf();
    }

    readConf() {

        const email= 'saverio_lombardo@yahoo.it';
        const codTrans='abcde11';
        const amount=1;

        const xpay = new XPay();
        xpay.configure('TEST');       

        this.url = xpay.getUrl( {email, codTrans, amount});
    }

    render() {

        return (
            <>
                <MSection>
                   <a href={this.url}> {this.url}</a>
                </MSection>
            </>
        );
    }
}