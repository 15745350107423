import React, { Component } from "react";

import "./BookCard.scss";
import { ROUTES } from "const";
import { Link } from "react-router-dom";
import { getCurrentUser } from "store/actions";
import { libraryService } from "libs/api/libraryService";
// import { networkErrorHelper } from "libs";

//icon
import IconArrowRight from "icons/IconArrowRight";
import IconHeart from "icons/IconHeart";



export class BookCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
        isWishList: this.props.book.isWishList,
    };
}
  

  toggleWishlist(book) {
    let payload = { libraryBookId: book.id };
    libraryService
    .toggleFavorities(payload)
    .then((response ) => {
      this.setState({isWishList: !this.state.isWishList});
      })
      .catch((error) => {
        // console.log("error")
      });
  }
  
  render() {
    const user = getCurrentUser();
    const { book } = this.props;
    return (
      <div className="bookCard">
        {user ? (
          <div className="bookCard__wishlist" onClick={() => this.toggleWishlist(book)}>
            {this.state.isWishList ? (
              <IconHeart fill="#e7c000" stroke="#e7c000" />
              ): (
                <IconHeart fill="#17324d" stroke="#e7c000" />
            )}
          </div>
        ) : null}
        <Link
          className="bookCard__content"
          to={{
            pathname: `${ROUTES.PAGE_BIBLIOTECA}/libri/${book.slug}`,
            state: book,
          }}
        >
          <p className="bookCard__category">{book.category.name}</p>
          <div className="bookCard__title">
            <h3 className="title">{book.title}</h3>
          </div>
          <div className="bookCard__author">
            <p className="author">{book.author.fullName}</p>
          </div>
          <div className="bookCard__button">
            <span>Leggi</span>
            <IconArrowRight className="icon" />
          </div>
        </Link>
      </div>
    );
  }
}
