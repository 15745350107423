import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

import {
  setBannerTopCookie, 
  showBannerTopCookie,
} from "libs/helpers/cookieHelper";

import { Icon } from "design-react-kit";

export class AdvBannerTop extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    this.setState({ isOpen: showBannerTopCookie(this.props.advName) });
  }

  toggleBanner() {
    this.setState({ isOpen: false });
    setBannerTopCookie(this.props.advName, 'false');   
  }

  render() {
   
    if ( this.state.isOpen) {
      return (
        <div className="header__bannertop">
          <div className="header__bannertop__wrapper">
            <div className="header__bannertop__content">             
                {ReactHtmlParser(this.props.textBanner)}
              
              <div
                className="header__bannertop__close"
                onClick={() => this.toggleBanner()}
              >
                <Icon className="align-bottom" icon="it-close" size="sm" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}