import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class PromoFreeCourse extends Component {

    render() {
        const title = 'PROGETTO CORSO GRATUITO';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <h6>AGGIORNATI GRATUITAMENTE A VITA CON I CORSI UNIVERSITARI DA NOI PROPOSTI</h6>
                            <hr />
                            <p>
                                L’Associazione Mnemosine - Ente Accreditato Ministero Istruzione Università e
                                Ricerca per la formazione del personale scolastico - presenta il progetto: “Aggiornati Gratuitamente a Vita con i Corsi Universitari
                                da noi proposti”.</p>
                            <p>Suggerisci l’iscrizione a qualunque collega o amico ed entrambi usufruirete di uno sconto di euro 50,00 (Cinquanta/00) CADAUNO.</p>
                            <p>Tutto ciò <b>senza alcun limite</b> di tempo o di corsisti presentati.</p>
                            <p>Il Bonus maturato potrà essere utilizzato in uno o più dei seguenti modi: </p>
                            <ol>
                                <li>Iscriverti gratuitamente ai corsi Universitari per tutta la vita o fino all’esaurimento del tuo credito;</li>
                                <li>Iscrivere gratuitamente i tuoi amici, colleghi o familiari ad un qualsiasi corso Universitario;</li>
                                <li><b>Novità assoluta</b>: cedere il valore del tuo credito <b>all’Istituto Scolastico</b>  da te segnalato o ad una specifica classe scolastica
                                    che riceverà la quota in beni strumentali come computer, Lim , Tablets, ecc… o che opterà per ricevere gratuitamente i corsi
                                    per le certificazioni informatiche (Icdl, Nuova Icdl, Icdl Specalised, Microsoft, Lim 100 ore, ecc…)
                                    o per le Certificazioni Internazionali Lingua Inglese (B2 e C1)!</li>
                            </ol>
                            <p>Leggi il regolamento e partecipa anche tu: potrai promuovere la vera formazione nella tua scuola!</p>
                            <div>
                                <p><a href="/files/promozioni/RegolamentoprogettoCorsiGratuiti.pdf"
                                    className="link-dark">REGOLAMENTO (istruzioni per partecipare)</a></p>
                            </div>
                            <div>
                                <iframe title="provider login" src="https://mne20.mnemosine-elearning.it/segreteria/studentProvider/login" width="100%" height="500" frameBorder="0" scrolling="no"></iframe>
                            </div>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}