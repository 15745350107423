import React, { Component } from "react";
import { adminService, dateHelper, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from "design-react-kit";
import { BoxDanger } from "components/misc";

import { MnemoLoading } from "components/misc";

import { NavLink } from "react-router-dom";
import { ROUTES } from "const";

class LessonLiveClass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      searchFilter: {
        searchFilter: {},
      },
      rndKey: 0,
    };
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "label",
        Header: "Nome",
        accessor: "label",
      },
      {
        id: "maxMember",
        Header: "Max Posti",
        accessor: "maxMember",
      },
      {
        id: "bookingCount",
        Header: "Posti Occupati",
        accessor: "bookingCount",
      },
      {
        id: "aa",
        Header: "A.A.",
        accessor: "aa",
      },
      {
        id: "createdAt",
        Header: "Creato il",
        accessor: (row) => {
          return row.createdAt ? dateHelper.toITDate(row.createdAt) : "";
        },
      },
      {
        id: "idExamSession",
        Header: "ID Sessione Esame",
        accessor: "idExamSession",
      },
      {
        id: "bookingOpen",
        Header: "Prenotazioni",
        accessor: (row) => {
          return row.bookingOpen ? "APERTE" : "CHIUSE";
        },
      },
      {
        id: "progress",
        Header: "Progressivo",
        accessor: "progress",
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.LESSON_LIVE_CLASS_FORM + "/" + value;
        },
      },
      {
        id: "download",
        accessor: "id",
        label: "Download CSV iscritti",
        icon: "it-download",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.loadRemoteDataSet(value);
        },
      },
    ],
  };
  //--- ---

  componentDidMount() {
    this.loadLessonLiveClass(this.state.searchFilter);
  }

  loadLessonLiveClass(filter) {
    adminService
      .loadLessonLiveClass(filter)
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.lessonLiveClass).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  loadRemoteDataSet(id) {
    adminService
      .loadDataSetToDownload(id)
      .then((result) => {
        //---
        const array = result.data.payload.lessonLiveClassBooking;

        const headerCSV = {fullname: 'Nome', email: 'Indirizzo e-mail'}
        array.unshift(headerCSV);

        let str = "";

        for (let i = 0; i < array.length; i++) {
          let line = "";
          for (let index in array[i]) {
            if (line !== "") line += ";";
            line += array[i][index];
          }

          str += line + "\r\n";
        }

        let downloadLink = document.createElement("a");
        downloadLink.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(str);
        downloadLink.download =
          (result.data.payload.classGroup || "dataset") + ".csv";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        //---
      })
      .catch((error) => {});
  } //loadRemoteDataSet

  render() {
    const { loading } = this.state;

    return (
      <>
        <div className="container mt-3 mb-4">
          <section className="m-0">
            <div className="form-row">
              <div className="col-md-12 text-right">
                <NavLink
                  to={ROUTES.LESSON_LIVE_CLASS_FORM}
                  className="btn btn-primary bg-dark mx-1">
                  Nuova Aula
                </NavLink>{" "}
              </div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payload}
                  tableConfig={
                    this.state.searchFilter.searchFilter.deleted
                      ? this.tableConfigDelete
                      : this.tableConfig
                  }
                />
              </Container>
            )}

            {this.state.payload.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                Ricerca non valida.
                <div className="small text-info">Nessun dato disponibile.</div>
              </BoxDanger>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default LessonLiveClass;
