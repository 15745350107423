import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLibrary, BooksList } from "components/biblioteca";

import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

import "./Category.scss";

class CategoryBooks extends Component {
  state = {
    category: [],
    books: [],
    categories: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  componentDidMount() {
    this.loadremote();
  }

  loadremote() {
    //get slug code from url params
    const { slug } = this.props.match.params;
    // set state category with slug value
    this.setState({ category: slug });

    libraryService
      .category(slug)
      .then((response) => {
        this.setState({
          isLoading: false,
          category: response.data.payload,
          books: response.data.payload.books,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const { isLoading, errorMessage, networkError, category, books } =
      this.state;
    if (isLoading) return <MnemoLoading />;
    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            <div className="categoryPage">
              <div className="categoryPage__container">
                <div className="categoryPage__wrapper">
                  {category ? (
                    <>
                      <div className="categoryPage__info">
                        <div className="categoryPage__cover">
                          <div className="categoryPage__cover__image">
                            <img
                              src={
                                category?.coverCardUrl
                                  ? category.coverCardUrl
                                  : "/img/biblioteca/cover-category.webp"
                              }
                              alt="categoria"
                            />
                          </div>
                          {/* category name */}
                          {category?.name ? (
                            <div className="categoryPage__category-title">
                              <h1 className="title">{category?.name}</h1>
                            </div>
                          ) : null}
                        </div>
                        {/* category description */}
                        {category?.description ? (
                          <div className="categoryPage__category-description">
                            <p>{category?.description}</p>
                          </div>
                        ) : null}
                      </div>
                      {books ? (
                        <div className="categoryPage__books">
                          <BooksList books={Object.values(books)} />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <BoxSuccess>Categoria non presente</BoxSuccess>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(CategoryBooks);
