import React, { Component } from "react";
//import { NavLink } from 'react-router-dom';
import { Icon, Form, Button } from 'design-react-kit';
import { MInput } from 'components/forms';
import { ReCaptchaV3 } from 'components/misc';
import { pageService, networkErrorHelper } from "libs";


export class SubscribeNewsletter extends Component {

    ERROR_MESSAGES = {
        email: "Devi inserire un indirizzo email Valido."
    };

    ERROR_MESSAGES__CHECKBOX = {
        email: "Per iscriverti alla newsletter è necessario accettare la privacy policy"
    };

    emptyFields = {
        email: ''
    };

    validation = {
        email: value => /\S+@\S+\.\S+/.test(value),
    };


    constructor(props) {
        super(props);
        this.state = {
            ...this.emptyFields,
            errors: { ...this.emptyFields },
            loadReCaptcha: false,
            deleteSubcribe: false,
            isChecked: false
        }
    }

    handleChange = (_, value) => {
        this.setState({
            email: value,
            errors: {
                ...this.emptyFields
            }
        });
    }

    handleReset = () => {
        this.setState({
            ...this.emptyFields,
            errors: { ...this.emptyFields },
            loadReCaptcha: false
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validation.email(this.state.email) && this.state.isChecked) {
            this.setState({
                loadReCaptcha: true
            })
        } else if (!this.validation.email(this.state.email) && this.state.isChecked) {
            this.setState({
                errors: {
                    ...this.ERROR_MESSAGES
                }
            })
        } else {
            this.setState({
                errors: {
                    ...this.ERROR_MESSAGES__CHECKBOX
                }
            })
        }
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }


    reCaptchaCallback = (token) => {
        this.setState({
            deleteSubcribe: true
        })

        //--- verify recaptcha token on server ide isHuman ---        
        pageService.validatereCaptcha(token).then(({ data }) => {
            const { success } = data.payload.result;
            if (success) {
                //--- send email addr to API and remove reCaptcha ---

                /** prepare payload about send to API Mnemo*/
                const payload = {
                    action: 'subscribe',
                    payload: {
                        email: this.state.email
                    }
                }
                pageService.subscribeNewsletterTo(payload).then(({ data }) => {
                    this.handleReset();
                    this.setState({
                        errors: {
                            email: data.payload.message
                        }
                    })

                    setTimeout(() => {
                        this.setState({
                            ...this.emptyFields,
                            errors: {
                                ...this.emptyFields
                            }
                        });
                    }, 6000);
                })
            }/*else{
                //reset captcha
            }*/

        })
            .catch(error => {
                networkErrorHelper.notify(error);
            });
    }

    //--- ---

    render() {

        return (
            <>
                <h4> Iscriviti alla Newsletter </h4>
                {(!this.state.loadReCaptcha) ? (
                    <Form action="#" className="form-newsletter" method="post" tag="form" onSubmit={this.handleSubmit}>
                        <MInput id="email" name="email" type="text" onChange={this.handleChange} />
                        <div className="checkbox__newsletter">
                            <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={this.state.isChecked}
                                    onChange={this.toggleChange}
                                />
                                Dichiaro di aver letto la <a href="/page/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> ai sensi del Regolamento UE 2016/679 (GDPR)
                            </label>
                        </div>
                        {(this.state.errors.email !== '') && (<div className="pt-0 mt-0 error-mail">{this.state.errors.email}</div>)}
                        <Button className="mt-2" color="primary bg-dark" icon={false} tag="button" type="submit">
                            <Icon className="mr-1" color="white" icon="it-mail" padding={false} size="" /> Iscriviti
                        </Button>
                    </Form>) : (
                    <>
                        <ReCaptchaV3 id="SubscribeNewsLetter" reCaptchaCallback={this.reCaptchaCallback} />
                        {(!this.state.deleteSubcribe) && <Button color="primary bg-dark" icon={false} tag="button" onClick={this.handleReset}>
                            <Icon className="mr-1" color="white" icon="it-close-circle" padding={false} size="" /> Annulla
                        </Button>}
                    </>)}
            </>
        );
    }

}