import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
} from "libs";

import {
    Card,
    CardBody
} from 'design-react-kit';


export class AdminSiteconfigForm extends ValidatedForm {


    ERROR_MESSAGES = {
        metadata: "La configurazione presenta degli errori.",
    };

    emptyFields = {
        code: '',
        config: '',
    };

    validation = {};


    FIELDS_GROUP = [
        /*[
            {
                id: "code",
                field: "code",
                label: "Codice",
                component: MInput,
                className: "col-md-12"
            }
        ],*/
        [
            {
                id: "config",
                field: "config",
                label: "Configurazione",
                component: MTextArea,
                rows: 6,
                className: "col-md-12"
            }
        ]
    ];

    //--- ---

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            idSiteConfig: this.props.idSiteConfig
        }

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadRemote();
    }

    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idSiteConfig) <= 0) {
            return false;
        }

        adminService.getSiteconfigDetail(parseInt(this.props.idSiteConfig))
            .then(({ data }) => {
                data.payload.siteConfig.config = JSON.stringify(data.payload.siteConfig.config, undefined, 4);

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.siteConfig
                );

                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading: false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
                this.setState({ loading: false });
            });
    }//loadremote


    onCancel = () => {
        this.resetForm();
    }//onCancel

    onBackPage = () => {
        window.history.back();
    }

    saveRemote = () => {
        const payload   = payloadBuilder(this.state);
        payload.config  = (JSON.stringify(JSON.parse(payload.config)));

        if (parseInt(this.props.idSiteConfig) > 0) {
            adminService.updateSiteconfig(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });

        } else {

            adminService.insertSiteconfig(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
                .catch(errors => {
                    if (networkErrorHelper.is422(errors)) {
                        const newState = invalidFieldsMapper(
                            errors,
                            this.state,
                            this.ERROR_MESSAGES
                        );
                        this.setState({ ...newState });
                    } else {
                        networkErrorHelper.notify(errors);
                    }
                });
        }
    };


    render() {

        let { formActive, defaultValues, idSiteConfig } = this.state;
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        
                        <h4>Codice: {defaultValues.code}</h4>

                        <form className="user" id="siteconfigform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(idSiteConfig) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>
            </>
        );

    }

}