import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminAudioFile } from "components/forms/admin/AdminAudioFile";

class AssetsAudioFileMainForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idAudioCategory: parseInt(this.props.match.params.idAudioCategory) > 0 ? this.props.match.params.idAudioCategory : 0,
      idAudio: parseInt(this.props.match.params.idAudio) > 0 ? this.props.match.params.idAudio : 0,
      title: parseInt(this.props.match.params.idAudioCategory) > 0 ? 'Gestione Audio File' : 'Nuova Audio File',
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            {<AdminAudioFile idAudioCategory={this.state.idAudioCategory} idAudio={this.state.idAudio} />}
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(AssetsAudioFileMainForm);
