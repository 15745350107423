import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { learningService } from "libs";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";

class CourseProvider extends Component {

    state = {
        loading: true,
        loadingError: false,
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {
        const { rCode } = this.props.match.params;        
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course } = data.payload;
                this.setState({ loading: false, course });

            })
            .catch(error => {

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {


        const { rCode } = this.props.match.params;;
        const { loading, loadingError, course } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste 
                <SupportoTecnico />
            </BoxDanger>);
        }

        const aaURL = course.aa.toString().slice(-2);
        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + 'moodleElearning/renderStudentProvider/rCode/' + rCode;
        let height = '1000px';

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title >Progetto Corso Gratuito</Title>
            <div>
                <embed src={src} width="99%" height={height} />
            </div>
        </>)
    }
}

export default withRouter(CourseProvider);