import React, { Component } from "react";

import { Link } from "react-router-dom";
import { ROUTES } from "const";

import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

import { NavLibrary } from "components/biblioteca";

// styles
import "./Authors.scss";

export class Authors extends Component {
  state = {
    authors: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    libraryService
      .authors()
      .then((response) => {
        this.setState({
          isLoading: false,
          authors: response.data.payload.authors,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;

        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const { authors, isLoading, errorMessage, networkError } = this.state;

    if (isLoading) return <MnemoLoading />;

    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            <div className="authorsPage">
              <div className="authorsPage__container">
                <div className="authorsPage__wrapper">
                  {authors ? (
                    <>
                      <ul className="authorsPage__list">
                        {authors.map((author, index) => (
                          <li className="authorsPage__list-item" key={index}>
                            <Link
                              to={{
                                pathname: `${ROUTES.PAGE_BIBLIOTECA}/autori/${author.slug}`,
                              }}
                            >
                              {author.fullName}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <BoxSuccess>Non sono presenti degli autori</BoxSuccess>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
