import React, { Component } from "react";

import "./ButtonPrimary.scss";

export class ButtonPrimary extends Component {
  /* 
        props:
        href --> link to
        label --> testo del pulsante
    */

  render() {
    const getButtonContent = () => {
      if (this.props.href) {
        return (
          <a href={this.props.href} alt={this.props.label}>
            {this.props.label}
          </a>
        );
      } else {
        return <span>{this.props.label}</span>;
      }
    };

    return (
      <div
        className={
          "buttonPrimary " +
          (this.props.color ? this.props.color : "") +
          (this.props.disable ? " disable" : "")
        }
      >
        <div className="buttonPrimary__content">{getButtonContent()}</div>
      </div>
    );
  }
}
