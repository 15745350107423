import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
    dateHelper,
    isEmptyObject
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';
import { MCheckbox } from "../shared/MCheckbox";

const FIELDS_GROUP = [
    [
        {
            id: "aa",
            field: "aa",
            label: "Anno Accademico",
            component: MInput,
            type: "text",
            className: "col-md-3",
            
        },
        {
            id: "code",
            field: "code",
            label: "codice corso",
            component: MInput,
            type: "text",
            className: "col-md-9"
        }
    ],
    [
        {
            id: "enrollEnabled",
            field: "enrollEnabled",
            label: "iscrizione abilitata",
            component: MCheckbox,
            className: "col-md-4 form-group"
        },
        {
            id: "diploma",
            field: "diploma",
            label: "richiesto diploma",
            component: MCheckbox,
            className: "col-md-4 form-group"
        },
        {
            id: "laurea",
            field: "laurea",
            label: "richiesta laurea",
            component: MCheckbox,
            className: "col-md-4 form-group"
        },
        {
            id: "courseHidden",
            field: "courseHidden",
            label: "nascondi corso",
            component: MCheckbox,
            className: "col-md-4 form-group"
        }
    ],
    [
        {
            id: "title",
            field: "title",
            label: "titolo corso",
            component: MInput,
            type: "text",
            className: "col-md-12"
        }
    ],
    [
        {
            id: "enrollTemplate",
            field: "enrollTemplate",
            label: "Template file  Modulo iscrizione",
            component: MInput,
            type: "text",
            className: "col-md-9"
        },
        {
            id: "courseType",
            field: "courseType",
            label: "Tipo Corso",
            component: MInput,
            type: "text",
            className: "col-md-3"
        }
    ],
    [
        {
            id: "tagSchool",
            field: "tagSchool",
            label: "Scuola",
            component: MInput,
            type: "text",
            className: "col-md-5"
        },
        {
            id: "tagArea",
            field: "tagArea",
            label: "Area",
            component: MInput,
            type: "text",
            className: "col-md-4"
        },    
        {
            id: "enrollDeadline",
            field: "enrollDeadline",
            label: "Scadenza inscrizione",
            component: MInput,
            type: "date",
            className: "col-md-3"
        }
    ],
    [
        {
            id: "enrollSheet",
            field: "enrollSheet",
            label: "URL scheda sintetica",
            component: MInput,
            type: "text",
            className: "col-md-12"
        }
    ],[
        {
            id: "enrollManifesto",
            field: "enrollManifesto",
            label: "URL bando di partecipazione",
            component: MInput,
            type: "text",
            className: "col-md-12"
        }
    ],
    [
        {
            id: "institute",
            field: "institute",
            label: "Ente erogatore",
            component: MTextArea,
            rows: 5,
            className: "col-md-12"
        }
    ],
    [
        {
            id:"summary",
            field: "summary",
            label: "descrizione corso",
            component: MTextArea,
            rows: 7,
            className: "col-md-12 mt-4h"
        }
    ],
    [
        {
            id:"furtherInfo",
            field: "furtherInfo",
            label: "informazioni aggiuntive",
            component: MTextArea,
            rows:7,
            className: "col-md-12 mt-4"
        }
    ],
    [
        {
            id:"examInfo",
            field: "examInfo",
            label: "informazioni esami",
            component: MTextArea,
            rows:7,
            className: "col-md-12 mt-4"
        }
    ],
    [
        {
            id: "scoring",
            field: "scoring",
            label: "Valutazione/Punteggio",
            payload: { options: [] },
            component: MTextArea,
            rows: 7,
            className: "col-md-12 mt-4"
        }
    ],
    [
        {
            id: "metadata",
            field: "metadata",
            label: "Metadata - Configurazione Corso",
            component: MTextArea,
            rows: 6,
            className: "col-md-12 mt-4"
        }
    ]
];

export class AdminCourse extends ValidatedForm {

    ERROR_MESSAGES = {
        title: "Titolo corso non valido",
        code: "Codice corso non valido",
        summary: "Minimo 5 caratteri",
        aa: "Anno Accademico non valido",
        enrollTemplate: "Template Modulo Inscrizione non valido",
        courseType: "Tipo Corso non valido",
        institute: "descrizione Instituti eroganti non valida",
        enrollSheet: "URL scheda sintetica non valido",
        enrollManifesto: "URL Bando di partecipazione non valido",
    };

    emptyFields = {
        title: '',
        code: '',
        summary: '',
        diploma: false,
        laurea: false,
        enrollEnabled: false,
        enrollTemplate: '',
        courseType: '',
        aa: '',
        furtherInfo: '',
        examInfo: '',
        tagSchool: '',
        tagArea: '',
        scoring: '',
        institute: '',
        enrollDeadline: '',
        enrollSheet: '',
        enrollManifesto: '',
        metadata: ''
    };

    validation = {
        title: value => /^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value),
        code: value => value.length>2,
        summary: value => value.length>4,
        aa: value => /^\d{4}$/i.test(value) && value !== null,
    //    enrollTemplate: value => /^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value),
        courseType: value => /^[a-z0-9:!?.,-/àèéì'òù,/\s"]+$/i.test(value) && value !== null,
        institute: value => value.length>4,
    //    enrollSheet: value => /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(value) && value !== null,
    };

    //--- ---
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idCourse) > 0 ? false : true
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idCourse) <= 0) {
            return false;
        }

        let filter = {
            searchFilter:{
              id: parseInt(this.props.idCourse)
            }
        }  


        adminService.course(filter)
            .then(({ data }) => {

                data.payload.courses.metadata = (!isEmptyObject(data.payload.courses.metadata)) ? JSON.stringify(data.payload.courses.metadata, undefined, 4) : '';

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.courses,
                   {
                       enrollDeadline: dateHelper.toUSDate(data.payload.courses.enrollDeadline)
                    },
                );
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }

    onBackPage = () => {
        window.history.back();
    }

    saveRemote = () => {
        let payload = payloadBuilder(this.state);

        if(payload.metadata === ''){
            payload.metadata = {};
        }else{
            payload.metadata = (JSON.stringify(JSON.parse(payload.metadata)));
        }
       
        if (parseInt(this.props.idCourse) > 0) {
            adminService.updateCourse(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertCourse(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    };

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idCourse) === 0) {
            formActive = true;
        }

        //--- force AA field to readOnly when edit/update before render ---
        FIELDS_GROUP[0][0].readOnly = parseInt(this.props.idCourse) > 0 ? true : false;
        
        const { formValid, code } = this.renderFields(FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idCourse) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
