import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class BenefitsM24 extends Component {

    render() {
        return (
            <>
                <Helmet>
                    <title>PERCHÉ SCEGLIERE IL MASTER DI I LIVELLO DI 1500 ORE E 60 CFU</title>
                    <meta property="og:title" content="PERCHÉ SCEGLIERE IL MASTER DI I LIVELLO DI 1500 ORE E 60 CFU - VANTAGGI" />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>PERCHÉ SCEGLIERE IL MASTER DI I LIVELLO DI 1500 ORE E 60 CFU IN</Title>
                            <h5>“Metodologie didattiche, psicologiche, antropologiche e teoria e metodi di progettazione” - Vantaggi</h5>
                            <hr />
                            <ul>
                                <li>Possibilità di ottenere una Specifica certificazione secondo il comma 5 dell’art. 3 del D.M. n. 616 del 10 Agosto
                                    2017 nella quale saranno riportati: </li>
                                <ul>
                                    <li>Denominazione di ogni singolo insegnamento;</li>
                                    <li>Le votazioni riportate agli esami ed i Crediti Formativi Universitari acquisiti;</li>
                                    <li>I settori disciplinari;</li>
                                    <li>Gli obiettivi formativi previsti dal D.M. n. 616 del 10 Agosto 2017.</li>
                                </ul>
                            </ul>
                            <ul>
                                <li>Valutabile punti 3,00nelle Graduatorie ad Esaurimento (laddove previsti)
                                    (<a href="/assets/doc/allegato2_titoli_III_fascia_esaurimento.pdf" target="_blank">Allegato 2 al D.M. 235 del 1 Aprile 2014</a> punto C.7 nota 11 v. “Le metodologie didattiche si ritengono coerenti con tutti gli insegnamenti”);</li>
                            </ul>
                            <ul>
                                <li>Valutabile punti 1,00nelle Graduatorie Provinciali delle Supplenze (GPS);</li>
                            </ul>
                            <ul>
                                <li>Valutabile punti 0,50 nei concorsi a cattedra (<a href="/assets/doc/tabella_titoli_concorso_docenti.pdf" target="_blank">Tabella Titoli 8</a>).</li>
                            </ul>
                            <ul>
                                <li>Valutabile punti 1,00nella Mobilità e Trasferimento del personale docente (<a href="/assets/doc/Sintesi_CCNI_11_04_17.pdf" target="_blank">C.N.I. del 11/04/2017</a> Tabella Titoli Generali Lettera D).</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Title>SVANTAGGI
                            </Title>
                            <hr />
                            <ul>
                                <li>

                                    Incompatibili con qualsiasi altro Corso Universitario

                                </li>

                            </ul>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}