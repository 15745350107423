import React, { Component } from 'react';

export class MSeparator extends Component {
    
    /**
     * PROPS: 
     * id       : identifier 
     */
    render() {
                
        const className = (this.props.className) ? this.props.className : '';
        
        return (
            
            <div className={className}>               
                <hr className='mseparator' />
            </div>
        );
    }

}