const { MButton2, MInput } = require("components/forms");

export class FormTitleBConfigurator {

    static extCode = {
        LaureaTriennale: "LAU_03",
        LaureaMagistrale02: "LAU_02_270",
        LaureaMagistraleCU: "LAU_CU_270",
        LaureaSpecialistica02: "LAU_02_509",
        LaureaSpecialisticaCU: "LAU_CU_509",
        LaureaVecchioOrdinamento:"LAU_VO", 
        AltroTitolo: "ALTRO"      
    };

    static forms = [
        {
            template: 'LaureaTriennale',
            label: "Laurea triennale",
        },
        {
            template: 'LaureaMagistrale02',
            label: "Laurea Magistrale biennale conseguita in base al D.M. 270/2004",
        },
        {
            template: 'LaureaMagistraleCU',
            label: "Laurea Magistrale a ciclo unico conseguita in base al D.M. 270/2004",
        },
        {
            template: 'LaureaSpecialistica02',
            label: "Laurea Specialistica biennale conseguita in base al D.M. 509/1999",
        },
        {
            template: 'LaureaSpecialisticaCU',
            label: "Laurea Specialistica a ciclo unico conseguita in base al D.M. 509/1999",
        },
        {
            template: 'LaureaVecchioOrdinamento',
            label: "Laurea conseguita in base alla normativa antecedente il D.M. 509/1999 (vecchio ordinamento)",            
        },                         
        {
            template: 'AltroTitolo',
            label: "Altro titolo di studio",
        },
    ];
    
    static LaureaTriennale = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", 
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionLT",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "classeLT",
                field: "classeLaurea",
                label: "Classe di laurea",
                component: MInput,
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimentoLT",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "aaLT",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "votoTitoloLT",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaTriennale = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        classeLaurea: "",
        city: "",
    };

    static LaureaMagistrale02 = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "description02",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "classe02",
                field: "classeLaurea",
                label: "Classe di laurea",
                component: MInput,
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimento02",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "aa02",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "votoTitolo02",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaMagistrale02 = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        classeLaurea: "",
        city: "",
    };

    static LaureaMagistraleCU = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionCU",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "classeCU",
                field: "classeLaurea",
                label: "Classe di laurea",
                component: MInput,
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimentoCU",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "aaCU",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "votoTitoloCU",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaMagistraleCU = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        classeLaurea: "",
        city: ""
    };

    static LaureaSpecialistica02 = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionLS2",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "classeLS2",
                field: "classeLaurea",
                label: "Classe di laurea",
                component: MInput,
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimentoLS2",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "aaLS2",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "votoTitoloLS2",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaSpecialistica02 = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        classeLaurea: "",
        city: ""
    };

    static LaureaSpecialisticaCU = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionLSCU",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [
            {
                id: "classeLSCU",
                field: "classeLaurea",
                label: "Classe di laurea",
                component: MInput,
                className: "col-md-6 pt-3",
            },
            {
                id: "dataConseguimentoLSCU",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },
        ],
        [
            {
                id: "aaLSCU",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            },
            {
                id: "votoTitoloLSCU",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaSpecialisticaCU = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        classeLaurea: "",
        city: ""
    };

    static LaureaVecchioOrdinamento = [
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-12 mb-3",
            classElement: "btn btn-primary bg-dark"
        }],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionVO",
                field: "description",
                label: "laurea in",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [           
            {
                id: "dataConseguimentoVO",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },       
            {
                id: "aaVO",
                field: "aa",
                label: "Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            }
        ],[
            {
                id: "votoTitoloVO",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_LaureaVecchioOrdinamento = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: "",
        city: ""
    };

    static AltroTitolo = [       
        [{
            id: "searchUniversity",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Ateneo",
            className: "col-md-6 mb-5",
            classElement: "btn btn-primary bg-dark"
        },{
            id: "searchSchool",
            component: MButton2,
            onClick: () => { console.error('errore: event unhandled') },
            label: "Cerca Istituto",
             className: "col-md-6 mb-5",            
            classElement: "btn btn-primary bg-dark"
        }
        ],
        [
            {
                id: "istitution",
                field: "istitution",
                label: "Presso", // diverse label
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
            {
                id: "city",
                field: "city",
                label: "Città",
                component: MInput,
                type: "text",
                className: "col-md-6",
                readOnly: true,
            },
        ],
        [
            {
                id: "descriptionAT",
                field: "description",
                label: "Denominazione titolo",
                component: MInput,
                type: "text",
                className: "col-md-12",
            },
        ],
        [           
            {
                id: "dataConseguimentoAT",
                field: "dataConseguimento",
                label: "Data conseguimento titolo",
                component: MInput,
                type: "date",
                className: "col-md-6 pt-3",
            },       
            {
                id: "aaAT",
                field: "aa",
                label: "Anno Scolastico o Anno Accademico conseguimento Titolo",
                component: MInput,
                infoText: "inserire formato AAAA/AAAA",
                className: "col-md-6 pt-3",
            }
        ],[
            {
                id: "votoTitoloAT",
                field: "votoTitolo",
                label: "Voto",
                component: MInput,
                className: "col-md-6 pt-3",
            },
        ],
    ];

    static dv_AltroTitolo = {
        description: "",
        istitution: "",
        aa: "",
        dataConseguimento: "",
        votoTitolo: ""
    };

    static getDefaultValues(template) {
        return this['dv_' + template];
    }

    static getFields(template, onSelectedUniversity=0, onSelectedSchool=0) {

        const fieldGroup = this[template];

        if (fieldGroup) {           
            
            if ( fieldGroup[0] && fieldGroup[0][0] && fieldGroup[0][0].id === 'searchUniversity') {
                fieldGroup[0][0].onClick = onSelectedUniversity;
            } 

            if ( fieldGroup[0] && fieldGroup[0][1] && fieldGroup[0][1].id === 'searchSchool') {                       
                fieldGroup[0][1].onClick = onSelectedSchool;
            }
        }else{
            console.error(template + ' not found');
        }
        return fieldGroup
    }

    static getExtCode(template) {

        return this.extCode[template];        
    }
}

