import { ROUTES } from "const";
import { courseService } from "libs/api";

export class MNE {

    static async getDashboardCards(registrationCode) {

        let cards = this.getFixedCards();
        const result = await courseService.mneActiveLink(registrationCode);

        if (result.data.payload) {
            const { payload } = result.data;                
            if (payload.simulationEnable === '1') {
                cards.push({
                    title: 'Simulazione Esami',
                    link: ROUTES.MNE_SIMULATION_EXAM, 
                    img: '/img/icon/8.png', className: 'image-box'
                });
            }
        }

        return cards;
    }

    static getFixedCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Bando', link: ROUTES.COURSE_MANIFESTO,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Tutor dedicato', link: ROUTES.COURSE_TUTOR_MNE,
                img: '/img/icon/call.png', className: 'image-box'
            },{
                title: 'Assistenza Telefonica', link: ROUTES.ASSISTANCE_TELEPHONE_MNE,
                img: '/img/icon/call.png', className: 'image-box'
            },{
                title: 'Assistenza Online', link: ROUTES.ASSISTANCE_ONLINE_MNE,
                img: '/img/icon/call.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            },{
                title: 'Prenotazione esami', link: ROUTES.ESB_EXAM_RESERVATION,
                img: '/img/icon/7.webp', className: 'image-box'
            }
        ];
    }
}
