import React, { Component } from "react";

import {
  Card,
  CardBody,
  Icon
} from 'design-react-kit';

import { MTable } from "components/table";

import { MInput } from "components/forms";
import { networkErrorHelper, adminService, learningService, dateHelper } from "libs";
import { BoxDanger, MnemoLoading, ModalHandler } from "components/misc";
import { ROUTES } from "const";

export class UserDetailCourses extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      courseCode: '',
      userID: this.props.account.id,
      registrationError: '',
      rndKey: 0,
      reloadData: this.props.data
    }
  }

  //--- ---  
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'aa',
        Header: 'A.A',
        accessor: 'aa', // accessor is the "key" in the data

      },
      {
        id: 'code',
        Header: 'Codice',
        accessor: 'code', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Corso',
        accessor: 'title',
      },
      {
        id: 'dateStart',
        Header: 'Data inizio',
        //accessor: 'dateStart',
        accessor: row => { return (row.dateStart) ? dateHelper.toITDate(row.dateStart) : '' },
      },
      {
        id: 'status',
        Header: 'Stato',
        accessor: 'status',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.USER_LIST + '/' + this.props.account.role + '/course/' + this.state.userID + '/' + value;
        }
      }
    ]
  }


  reloadRemote(userID) {
    learningService.getCoursesByIDUser(userID).then(({ data }) => {
      this.setState({
        loading: false,
        rndKey: Math.floor((Math.random() * 1000) + 1),
        reloadData: data.payload.courses
      })
    })
      .catch(error => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  }//reloadRemote


  enrollmentCodeSubmit = (_, value) => {
    this.setState({
      courseCode: value
    })
  }//enrollmentCodeSubmit

  setStudentEnrollment(e) {
    e.stopPropagation();
    this.setState({
      courseCode: '',
      registrationError: ''
    })

    const enrollmentAttr = {
      label: 'Codice Corso',
      className: "mt-1 mb-3",
    }
    const code = <>
      <MInput {...enrollmentAttr} onChange={this.enrollmentCodeSubmit} />
      <div className="small text-info">Digitare il Codice corso al quale iscrivere lo Studente.</div>
    </>;

    ModalHandler.show(null, 'Iscrizione Studente', code, null, this.setStudentEnrollmetConfirmed);
  }

  setStudentEnrollmetConfirmed = () => {
    if (this.state.courseCode === '') {
      return false;
    }

    this.setState({ loading: true })
    const payload = { ...this.state };

    adminService.registerOnUserCourse(payload).then(({ data }) => {
      this.reloadRemote(payload.userID)
    })
      .catch(error => {
        console.log(error);
        if (networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
        if (networkErrorHelper.is422(error)) {
          this.setState({
            registrationError: 'Questa iscrizione non è andata a buon fine. Lo studente potrebbe essere già iscritto a questo Corso.'
          })

          setTimeout(() => {
            this.setState({ registrationError: '' })
          }, 14000);
        }
        this.setState({
          loading: false
        })
      });
  }//setStudentEnrollmetConfirmed

  //--- ---

  render() {

    if (!this.props.data) return false;
    let data = this.props.data;
    const { reloadData, rndKey, registrationError } = this.state;
    if (reloadData) { data = reloadData }

    return (
      <>
        {this.state.loading && (<MnemoLoading></MnemoLoading>)}

        <section className="my-4">

          <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">

            <div className="flag-icon" />
            <div className="etichetta">
              <Icon icon="it-user" padding={false} />
              <span>Corsi</span>
            </div>

            <CardBody>
              <MTable key={rndKey} tableData={data} tableConfig={this.tableConfig} />
              {(registrationError !== '') && <BoxDanger>{registrationError}</BoxDanger>}
              {/*<Button className="btn btn-sm btn-primary bg-dark mt-3" onClick={(e) => this.setStudentEnrollment(e)}>ISCRIVI</Button>*/}
            </CardBody>

          </Card>

        </section>
      </>
    );
  }
}
