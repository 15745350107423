import React, { Component } from "react";
import { utilsService } from "libs";
import { Container, Row, Col, Button } from "design-react-kit";
import { MSelect } from "components/forms";
import { MnemoLoading } from "components/misc";

export class MSearchSchool extends Component {
  PAYLOADS = {
    provinces: [],
    cities: [],
    schools: [],
  };

  toSent = {
    city: "",
    istitution: "",
    province: "",
    address: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      popolateProvince: { options: [{ label: "Seleziona", value: -1 }] },
      popolateCities: { options: [] },
      popolateSchools: { options: [] },
      actionModule: false,
      loading: false,
      openField: {
        view: false,
        city: "",
        istitution: "",
        province: "",
        address: ""
      },
    };

    this.loadCities = this.loadCities.bind(this);
    this.loadSchool = this.loadSchool.bind(this);
    this.activateButton = this.activateButton.bind(this);
    this.resetSelectElm = this.resetSelectElm.bind(this);
  }

  //--- ---

  componentDidMount() {
    this.loadProvince();
  }

  loadProvince = () => {
    utilsService
      .provinces()
      .then(({ data }) => {
        const { payload } = data;
        const provinces = payload.map(
          ({ denUts, codProvPrev, denUtsShort }) => ({
            label: denUts,
            value: [codProvPrev, denUtsShort, denUts],
          })
        );
        this.PAYLOADS.provinces.push({ label: "Seleziona", value: -1 });
        this.PAYLOADS.provinces.push(...provinces);

        this.setState({
          popolateProvince: { options: this.PAYLOADS.provinces },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  }; //loadProvince

  prepareDataToSend = (_, value) => {
    this.setState({
      istitutionData: value.split(","),
      actionModule: true,
    });
  }; //prepareDataToSend

  loadCities = (_, value) => {
    const arrCities = value;
    this.popolateCities(arrCities);

    this.setState({
      popolateSchools: { options: [] },
      popolateCities: { options: [] },
      actionModule: false,
      openField: {
        city: "",
        istitution: "",
        province: "",
        address: ""
      },
    });
  }; //loadCities

  popolateCities(arrCities) {
    arrCities = arrCities.split(",");
    this.setState({ loading: true });

    //utilsService.citiesSchoolByISTATProv(arrCities[0]).then(({ data }) => { //old on school table
    utilsService
      .citiesSchoolByISTATProv(arrCities[2])
      .then(({ data }) => {
        const { payload } = data;
        const cities = payload.map(({ name, istatCode }) => ({
          label: name,
          value: [...arrCities, name, istatCode],
        }));

        this.PAYLOADS.cities = [];
        this.PAYLOADS.cities.push(...cities);
        this.PAYLOADS.cities.unshift({
          label: `Seleziona (${cities.length})`,
          value: -1,
        });

        this.setState({
          loading: false,
          popolateCities: {
            options: this.PAYLOADS.cities,
          },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  } //popolateCities

  loadSchool = (_, value) => {
    const cityData = value;
    this.popolateSchools(cityData);

    this.setState({
      popolateSchools: { options: [] },
      actionModule: false,
    });
  }; //loadSchool

  popolateSchools(cityData) {

    cityData = cityData.split(",");

    this.toSent.city = cityData[3];
    this.toSent.province = cityData[2];

    utilsService
      .listSchoolByISTATCodeCom(cityData[4])
      .then(({ data }) => {
        const { payload } = data;
        const schools = payload.map(({ name, type, schoolCode, address }) => ({
          label: name + " (" + type.toLowerCase() + ")",
          value: [name, schoolCode, address],          
        }));

        this.PAYLOADS.schools = [];
        this.PAYLOADS.schools.push(...schools);
        this.PAYLOADS.schools.unshift({
          label: `Seleziona (${schools.length})`,
          value: -1,
        });

        this.setState({
          popolateSchools: { options: this.PAYLOADS.schools },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  } //popolateSchool

  /** */
  resetToSend = () => {
    this.toSent = {
      city: "",
      istitution: "",
      province: "",
      address: ""
    };
  };

  /** */
  activateButton = (_, value) => {
    if (value === "-1") {
      this.toSent.istitution = "";
      this.setState({ actionModule: false });
    } else {
      this.toSent.istitution = value;
      this.setState({ actionModule: true });
    }
  }; //activateButton

  /** */
  resetSelectElm = (id, value) => {
    if (this.state.popolateCities.length > 0) {
      //reset select element only if option exists
      this.setState({
        popolateProvince: { options: [] },
        popolateCities: { options: [] },
        popolateSchools: { options: [] },
      });

      this.loadProvince();
    } //>0

    //--- ---

    /** start: manage value of open field */
    this.setState((state) => {
      if (id === "openFieldCity") {
        state.openField.city = value;
        this.toSent.city = value;
      }

      if (id === "openFieldIstitution") {
        state.openField.istitution = value;
        this.toSent.istitution = value;
      }

      //--- validate toSent object ---
      this.validateForm();

      return true;
    });
  }; //resetSelectElm

  handleChange = () => {

    if (this.props.onChange) {
      const data = {
        istitution: this.toSent.istitution.split(",")[0],
        city: this.toSent.city,
        province: this.toSent.province,
        address: this.toSent.istitution.split(",")[2],
        schoolCode: this.toSent.istitution.split(",")[1],
      };

      this.props.onChange(data);    
    }
  };

  //--- ---

  render() {
    const { popolateProvince, popolateCities, popolateSchools, actionModule, loading } =
      this.state;

    return (
      <>
        {loading && <MnemoLoading></MnemoLoading>}

        <Container key="schoolSearch" className="m-0 p-0">
        <p className="pb-4">
            Selezionare l'Istituto Scolastico dove hai conseguito il Titolo.
          </p>
            <Row>
              <Col lg={6} className="mb-6">
                <div className="bootstrap-select-wrapper">
                  <MSelect
                    id="listProvinces"
                    label="Provincia"
                    payload={popolateProvince}
                    onChange={this.loadCities}
                  />
                </div>
              </Col>
              <Col lg={6} className="mb-6">
                <div className="bootstrap-select-wrapper">
                  <MSelect
                    id="listCities"
                    label="Città"
                    payload={popolateCities}
                    onChange={this.loadSchool}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mb-12">
                <div className="bootstrap-select-wrapper">
                  <MSelect
                    id="listSchools"
                    label="Istituto"
                    payload={popolateSchools}
                    onChange={this.activateButton}
                  />
                </div>
              </Col>
            </Row>         

          <Row>
            <Col lg={12} className="m-0 text-right">
              <Button
                color="primary bg-dark"
                disabled={actionModule ? false : true}
                size="md"
                onClick={this.handleChange}>
                SELEZIONA
              </Button>
              <Button
                className="mx-4"
                color="primary"
                outline
                size="md"
                onClick={() => {
                  this.props.searchSchool();
                }}>
                ANNULLA
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
