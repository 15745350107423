import { proxyBE, proxyApiMnemo } from "./common";

export const setSession = (token) => {
  proxyBE.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  proxyApiMnemo.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

//------
export const utilsService = {
  nations() {
    return proxyBE.get("utils/nations");
  },
  provinces() {
    return proxyBE.get("utils/provinces");
  },
  regions() {
    return proxyBE.get("utils/regions");
  },
  cities(province = null) {
    return proxyBE.get(`utils/cities?province=${province}`);
  },
  city(id) {
    return proxyBE.get(`utils/city/${id}`);
  },
  listSchoolByISTATCodeCom(istatCom) {
    return proxyBE.get(`utils/school/${istatCom}`);
  },
  citiesSchoolByISTATProv(istatProv = null) {
    return proxyBE.get(`utils/school/city/${istatProv}`);
  },
  universities() {
    return proxyBE.get("utils/universities/");
  },
  universitiesByCodReg(codReg) {
    return proxyBE.get(`utils/universities/region/${codReg}`);
  },
  listUniversityQualification(payload) {
    return proxyBE.get(`/utils/university/${payload.qualificationID}/course/${encodeURI(JSON.stringify(payload))}`);
  },
  laureaType() {
    return proxyBE.get("utils/laureaType");
  },
  sendContactForm(payload) {
    return proxyBE.post('utils/contactform', payload);
  },
  updateANSData(payload) {
    return proxyBE.post('/admin/utils', payload);
  },

  sendContactFormUnicam(data) {
    data.textmessage  = data.textmessage + ' --- ' + data.courseLType;
    data.template = 'UNICAMILLUS_LAUREA';
    data.subject  = 'Contatti Mnemosine';
    return proxyBE.post('/utils/outer-contactform-unicam', { data });
  },
};