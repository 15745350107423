import React, { Component } from "react";
import SlimHeader from "./SlimHeader";

import { getCurrentUser } from "store/actions";

export class Header extends Component {
  render() {
    const { isLoggedIn } = this.props;
    const user = getCurrentUser();
    let roles = null;

    if (user) {
      roles = user.roles;
    }

    return (
      <>
        {/* <MainHeader isLoggedIn={isLoggedIn} user={user} roles={roles} /> */}
        <SlimHeader isLoggedIn={isLoggedIn} user={user} roles={roles} />
        {/* <Headers shadow={true}  >         
          <div className="it-nav-wrapper">
            <MainHeader isLoggedIn={isLoggedIn} />
            <NavHeader isLoggedIn={isLoggedIn} roles={roles} />         
          </div>
        </Headers> */}
      </>
    );
  }
}
