import React from "react";
import { MButton2, MInput, MSearchUniversity, MSelect, ValidatedForm } from "components/forms";
import { payloadBuilder } from "libs";

export class InsegnamentiMultiManager extends ValidatedForm {

  PAYLOAD_votoTitolo = {
    options: [
      { value: "", label: "Selezionare il voto" },
      { value: "18", label: "18" },
      { value: "19", label: "19" },
      { value: "20", label: "20" },
      { value: "21", label: "21" },
      { value: "22", label: "22" },
      { value: "23", label: "23" },
      { value: "24", label: "24" },
      { value: "25", label: "25" },
      { value: "26", label: "26" },
      { value: "27", label: "27" },
      { value: "28", label: "28" },
      { value: "29", label: "29" },
      { value: "30", label: "30" },
      { value: "IDONEO", label: "IDONEO" },
    ]
  };

  FIELDS_GROUP = [
    [{
      id: "searchUniversity"+this.props.idx,
      component: MButton2,
      onClick: () => { this.searchUniversity() },
      label: "Cerca Ateneo",
      className: "col-md-12 mb-3",
      classElement: "btn btn-primary bg-dark"
    }
    ],
    [
      {
        id: "istitution"+this.props.idx,
        field: "istitution",
        label: "Istituzione universitaria o accademica",
        component: MInput,
        className: "col-md-12",
        readOnly: true,
      },
      {
        id: "city"+this.props.idx,
        field: "city",
        label: "Città",
        component: MInput,
        className: "col-md-6",
        readOnly: true,
      }
    ], [
      {
        id: "description"+this.props.idx,
        field: "description",
        label: "Denominazione insegnamento",
        component: MInput,
        className: "col-md-12",
      },
    ], [
      {
        id: "dataConseguimento"+this.props.idx,
        field: "dataConseguimento",
        label: "Data di svolgimento del relativo esame",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "votoTitolo"+this.props.idx,
        field: "votoTitolo",
        label: "Superato con voto",
        component: MSelect,
        payload: this.PAYLOAD_votoTitolo,
        className: "col-md-4",
      },
      {
        id: "cfucfa"+this.props.idx,
        field: "cfucfa",
        label: "numero CFU/CFA",
        component: MInput,
        infoText: "(per gli insegnamenti conseguiti durante il corso di laurea V.O. indicare SEMESTRALITÀ o ANNUALITÀ)",
        className: "col-md-4",
      },
    ], [
      {
        id: "ssd"+this.props.idx,
        field: "ssd",
        label: "SSD/SAD",
        component: MInput,
        className: "col-md-4",
      },
      {
        id: "ambitoCorsoStudi"+this.props.idx,
        field: "ambitoCorsoStudi",
        label: "Nell'ambito del seguente Corso di Studi/Master/Corsi singoli",
        component: MInput,
        className: "col-md-8",
      },
    ]
  ];

  ERROR_MESSAGES = {
    istitution: "Inserire l'Istituzione universitaria o accademica",
    city: "Inserire l'Istituzione universitaria o accademica",
    description: "Inserire la denominazione insegnamento",
    dataConseguimento: "Inserire la data di svolgimento dell'esame",
    votoTitolo: "Inserire il voto",
    cfucfa: "Inserire il numero di CFU/CFA",
    ssd: "Inserire l'SSD",
    ambitoCorsoStudi: "Inserire il corso di studi",
  };

  validation = {
    istitution: (value) => value !== "",
    city: (value) => value !== "",
    description: (value) => value !== "",
    dataConseguimento: (value) => value !== "",
    votoTitolo: (value) => value !== "",
    cfucfa: (value) => value !== "",
    ssd: (value) => value !== "",
    ambitoCorsoStudi: (value) => value !== "",
  };

  emptyFields = {
    istitution: "",
    city:"",
    description: "",
    dataConseguimento: "",
    votoTitolo: "",
    cfucfa: "",
    ssd: "",
    ambitoCorsoStudi: "",
  };

  state = {
    showSearchUniversity: false,
    university: {},
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  }

  onChangeUniversity = (university) => {

    this.onChange('city', university.city);
    this.onChange('istitution', university.istitution);
    this.setState({ showSearchUniversity: false, university: university })
  }

  getPayload = () => {
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.qualificationCode = 'titleD';
    this.payload.nation = 'Italia';
    this.payload.template = 'InsegnamentoMultiManager';

    if (this.state.university) {
      this.payload.uniCode = this.state.university.uniCode;
    }

    return this.payload;
  };

  render() {
    const { defaultValues, showSearchUniversity } = this.state;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        {(showSearchUniversity) ? (<>
          <MSearchUniversity onChange={this.onChangeUniversity} searchUniversity={this.searchUniversity} />
        </>) : (<>
          {code}
        </>)}
      </>
    );
  }
}
