import React, { Component } from 'react';
import { MCheckbox, MSelect } from 'components/forms';
import ReactHtmlParser from 'react-html-parser';

export class FormRCCFUALL extends Component {

    PAYLOAD_COURSE =
        {
            options: [
                { label: 'SELEZIONARE', value: '' },
                { label: '60 CFU (All. 1) destinato a Laureati, Laureandi, Docenti di percorsi di istruzione e formazione professionale regionali', value: 'RC60CFUALL1' },
                { label: '30 CFU (All. 2) destinato a chi ha svolto tre anni di servizio d\'insegnamento o chi ha sostenuto la prova concorsuale straordinaria ex D.L. 73/2021 e ss.mm.ii.', value: 'RC30CFUALL2' },
            ]
        }

    PAYLOAD_CLASS =
        {
            options: [
                { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                { label: 'A011-Discipline Letterarie e Latino', value: 'A011' },
                { label: 'A012-Discipline Letterarie negli Istituti di Istruzione Secondaria di II Grado', value: 'A012' },
                { label: 'A019-Filosofia e Storia ', value: 'A019' },
                { label: 'A022-Italiano, Storia, Geografia nella Scuola Secondaria di I Grado', value: 'A022' },
                { label: 'A045-Scienze Economico-Aziendali', value: 'A045' },
                { label: 'A046-Scienze Giuridico-Economiche', value: 'A046' },
                { label: 'A048-Scienze Motorie e Sportive negli Istituti di Istruzione Secondaria di II Grado', value: 'A048' },
                { label: 'A049-Scienze Motorie e Sportive nella Scuola Secondaria di I Grado', value: 'A049' },
                { label: 'A054-Storia dell\'Arte', value: 'A054' },
                { label: 'AB24-Lingue e Culture Straniere negli Istituti di Istruzione di II Grado (Inglese)', value: 'AB24' },
                { label: 'AB25-Lingua Inglese e Seconda Lingua Comunitaria nella Scuola Secondaria I Grado (Inglese)', value: 'AB25' },
            ]
        }

    state = {
        validationError: '',
        courseCode: '',
        competitionClass: '',
        accept11: false,
        accept12: false,
        accept13: false,
    }

    getValue() {

        const { accept11, accept12, accept13, courseCode, competitionClass } = this.state;

        const pluginValue = {
            courseCode: courseCode,
            competitionClass: competitionClass,
            accept11: accept11,
            accept12: accept12,
            accept13: accept13
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;

        const { courseCode, competitionClass, accept11, accept12, accept13 } = this.state;

        if (courseCode === '') {
            validationError += '<li>E\' necessario selezionare un Percorso abilitante.</li>';
            isValid = false;
        }

        if (competitionClass === '') {
            validationError += '<li>Dovresti selezionare una classe di concorso</li>';
            isValid = false;
        }

        if (!accept11 || !accept12 || !accept13) {
            validationError += '<li>E\' necessario accettare tutte le dichiarazioni.</li>';
            isValid = false;
        }

        if (!isValid) validationError = '<ul>' + validationError + '</ul>';
        this.setState({ validationError })

        return (isValid);
    }

    onChangeCompletitionClass = (id, value) => {
        this.setState({ competitionClass: value });
    }

    onConditionClick = (id, value) => {

        this.setState({ [id]: value });
    }

    onChangeCourseCode = (id, value) => {
        // reload the form
        this.setState({ courseCode: value });
    };

    render() {

        const { courseCode } = this.state;

        return (
            <>
                <h5>Percorso abilitante</h5>
                <MSelect onChange={this.onChangeCourseCode} id="courseCode" value={courseCode} payload={this.PAYLOAD_COURSE}></MSelect>

                <h5>Classe di concorso</h5>
                <MSelect onChange={this.onChangeCompletitionClass} id="completitionClass" payload={this.PAYLOAD_CLASS}></MSelect>

                <h5>Dichiarazioni</h5>
                <MCheckbox onChange={this.onConditionClick} id="accept11">
                    Dichiaro di essere in possesso dei requisiti di ammissione indicati nel bando di partecipazione.
                    Tali requisiti verranno ulteriormente autocertificati in fase di completamento dell'iscrizione dal
                    sottoscritto tramite lo specifico modulo.
                </MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept12">
                    Dichiaro di essere in possesso dei titoli di accesso alla classe di concorso per la quale si intende
                    conseguire la nuova abilitazione. Tali requisiti verranno ulteriormente autocertificati in fase
                    di completamento dell'iscrizione dal sottoscritto tramite lo specifico modulo.
                </MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept13">
                    Dichiaro di non aver presentato domanda di partecipazione per percorsi relativi alla medesima
                    classe di concorso in altre istituzioni.
                </MCheckbox>
                <hr />
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{ReactHtmlParser(this.state.validationError)}</span></>)}
                <hr />
            </>
        );
    }
}