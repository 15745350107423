import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { enrollmentService, networkErrorHelper, isEmptyObject, dateHelper } from "libs";

import { MnemoLoading, Title, MSection } from 'components/misc';
import { Container, Row, Col } from 'design-react-kit';
import ReactHtmlParser from 'react-html-parser';
import { ModalHandler } from 'components/misc';


class EnrollmentDetail extends Component {

  constructor(props){
    super(props);

    this.state = {
      dataEnrollment: {},
      dataEnrollmentFile: {},
      dataPaymentFile: {}
    };
  }

  componentDidMount(){
    this.loadRemote(this.props.match.params.idEnrollment);
  }//componentDidMount

  loadRemote = (idEnrollment) =>{
    enrollmentService.enrollmentByID(idEnrollment).then(({ data }) => {

      this.setState({
        dataEnrollment: data.payload.enrollment,
        dataEnrollmentFile: data.payload.enrollmentFile,
        dataPaymentFile: data.payload.paymentFile
      })
    })
    .catch(errors => {
        console.log(errors);
        networkErrorHelper.notify(errors);
    });
  }//loadRemote

  //--- start functions to delete procedure ---
  deleteEnrollment = (idEnrollment) =>{
    ModalHandler.show(idEnrollment, 'Annullamento Iscrizione', 'vuoi davvero annullare questa Iscrizione?', null, this.confirmDelete);
  }//deleteEnrollment

  confirmDelete = (value) =>{
    const payload = {
      idEnrollment: value
    }
    enrollmentService.enrollmentDelete(payload).then(({ data }) => {
      if(data.payload.deleted){
        this.setState({ dataEnrollment: data.payload })
        setTimeout(() => {
          this.props.history.goBack();
        }, 250);
      }
    })
    .catch(errors => {
        console.log(errors);
        networkErrorHelper.notify(errors);
    });
  }//confirmDelete
  //--- end functions to delete procedure ---

  //--- start functions to reset procedure ---
  resetEnrollment = (idEnrollment) =>{
    ModalHandler.show(idEnrollment, 'Reset Iscrizione', 'vuoi davvero fare il reset di questa Iscrizione?', null, this.confirmReset);
  }//resetEnrollment

  confirmReset = (value) =>{
    const payload = {
      idEnrollment: value
    }
    enrollmentService.enrollmentReset(payload).then(({ data }) => {
      if(data.payload){
        this.setState({ dataEnrollment: data.payload })
        setTimeout(() => {
          this.props.history.goBack();
        }, 250);
      }
    })
    .catch(errors => {
        console.log(errors);
        networkErrorHelper.notify(errors);
    });
  }//confirmDelete
  //--- end functions to reset procedure ---


  HTMLData = (dataEnrollment, dataEnrollmentFile, dataPaymentFile) =>{

    const {
      company, courseCode, currentStep, officeCode, orderNumber,
      dateStart, dateEnd, dateSign,
      importToPay, paymentStatus, paymentType, datePayment,
      examSession, examSite, examSiteOnline,
      promoDescription, promoImport,
      plugin, pluginConfig, digest,
      hasBonusCourse, titleFreeCourse,
      syncState, codeConfirmed, codeCreated
    } = dataEnrollment;

    const { location, originalName } = dataEnrollmentFile;

    let paymentFileList = '';
    if(!isEmptyObject(dataPaymentFile)){
      Object.keys(dataPaymentFile).forEach(key => {
        paymentFileList += `<div>- <a href="${dataPaymentFile[key].location}" download="${dataPaymentFile[key].originalName}" target="_blank">${dataPaymentFile[key].originalName}</a></div>`;
      })
    }

    return (
      `
      <div><b>Stato Registrazione:</b> ${syncState}</div>      
      <div><b>Numero Ordine:</b> ${orderNumber}</div>
      <div><b>Azienda:</b> ${company}</div>
      <div><b>Codice Riservato:</b> ${officeCode}</div>
      <div><b>Codice Corso:</b> ${courseCode}</div>
      <div><b>Stato Domanda:</b> ${currentStep}</div>
      
      <div><b>Data Inizio:</b> ${(dateStart !== null) ? dateHelper.toITDate(dateStart) : ''}</div>
      <div><b>Data Fine:</b> ${(dateEnd !== null) ? dateHelper.toITDate(dateEnd) : ''}</div>
      <div><b>Data Registrazione:</b> ${(dateSign !== null) ? dateHelper.toITDate(dateSign) : ''}</div>

      <hr/>

      <div><b>Importo:</b> ${importToPay}</div>
      <div><b>Stato Pagamento:</b> ${paymentStatus}</div>
      <div><b>Tipo Pagamento:</b> ${paymentType}</div>
      <div><b>Data Pagamento:</b> ${(datePayment !== null) ? dateHelper.toITDate(datePayment) : ''}</div>
      <div><b>Ricevute Pagamenti:</b><br/> ${paymentFileList}</div>

      <hr/>

      <div><b>Corso Gratuito:</b> ${(hasBonusCourse) ? 'SI' : 'NO'}</div>
      <div><b>Titolo Corso Gratuito:</b> ${(titleFreeCourse !== null) ? titleFreeCourse : ''}</div>

      <hr/>

      <div><b>Sessione Esame:</b> ${(examSession !== null) ? examSession : ''}</div>
      <div><b>Sede Esame:</b> ${examSite}</div>
      <div><b>Città Esame Remoto:</b> ${(examSiteOnline !== null) ? examSiteOnline : ''}</div>

      <hr/>

      <div><b>Promozione:</b> ${(promoDescription !== null) ? promoDescription : ''}</div>
      <div><b>Promozione Importo:</b> ${promoImport}</div>

      <hr/>

      <div><b>PlugIn:</b> ${(plugin !== null) ? plugin : ''}</div>
      <div><b>PlugInConfig:</b> ${(pluginConfig !== null) ? JSON.stringify(pluginConfig, undefined, 4) : ''}</div>
      <div><b>Digest:</b> ${(digest !== null) ? digest : ''}</div>
      <div><b>Codice SMS firma generato:</b> ${(codeCreated !== null) ? codeCreated : ''}</div>
      <div><b>Codice SMS firma:</b> ${(codeConfirmed !== null) ? codeConfirmed : ''}</div>

      ${(typeof originalName === 'string') ? `<hr/><div><b>Modulo d'Iscrizione:</b> <a href="${location}" download="${originalName}" target="_blank">${originalName}</a></div>` : ''}
      `
    );
  }//HTMLData

  //--- ---

  render() {

    const { dataEnrollment, dataEnrollmentFile, dataPaymentFile } = this.state;
    const { history } = this.props;
    if(isEmptyObject(dataEnrollment)) { return <MnemoLoading />; }
    const canDelete = (!dataEnrollment.deleted &&
      (dataEnrollment.syncState !== 'toApprove' && dataEnrollment.syncState !== 'approved' && dataEnrollment.syncState !== 'selfapproved')) ;

    return (
      <>      
        <Container>
            <Row>
              <Col>
                <Title className="m-0 p0">Iscrizione: {dataEnrollment.orderNumber}</Title>
              </Col>
              <Col className="text-right">
                <span className="btn btn-primary bg-dark m-2 mt-5" onClick={()=>{history.goBack()}}>indietro</span>
                {canDelete && <span className="btn btn-primary bg-danger m-2 mt-5" onClick={()=>{this.resetEnrollment(this.props.match.params.idEnrollment)}}>Reset Iscrizione</span>}
                {canDelete && <span className="btn btn-primary bg-danger m-2 mt-5" onClick={()=>{this.deleteEnrollment(this.props.match.params.idEnrollment)}}>Annulla Iscrizione</span>}
              </Col>
            </Row>

            <MSection className="mb-4">                
                {ReactHtmlParser(this.HTMLData(dataEnrollment, dataEnrollmentFile, dataPaymentFile))}       
            </MSection>
        </Container>
      </>
    );
  }

}

export default withRouter(EnrollmentDetail);