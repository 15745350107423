import React, { Component } from "react";
import { NHStudentMenu } from "components/navigation";

import { MainMenuPublic } from "components/navigation/mainMenu";

export class NavMainMenu extends Component {
  loadNHComponent = (isLoggedIn, roles) => {
    if (!isLoggedIn) {
      return <MainMenuPublic />;
    }

    if (isLoggedIn && roles === "student") {
      return (
        <NHStudentMenu user={this.props.user} logout={this.props.logout} />
      );
    }

    // if(isLoggedIn && roles === 'admin'){
    //   return <NHAdmin />
    // }

    // if(isLoggedIn && roles === 'segreteria'){
    //   return <NHSegreteria />
    // }

    // if(isLoggedIn && roles === 'editor'){
    //   return <NHEditor />
    // }
  }; //loadNHComponent

  render() {
    const { isLoggedIn, roles } = this.props;
    return <>{this.loadNHComponent(isLoggedIn, roles)}</>;
  }
}
