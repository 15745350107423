import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class BenefitsC24 extends Component {

    render() {
        const title = 'PERCHÉ SCEGLIERE I “PERCORSI FORMATIVI 24 CFU” - VANTAGGI';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title>{title}</Title>
                            <hr />
                            <ul>
                                <li>Possibilità di ottenere una Specifica certificazione secondo il comma 5 dell’art. 3 del D.M. n. 616 del 10 Agosto 2017
                                    nella quale saranno riportati:</li>
                                <ul>
                                    <li>Denominazione di ogni singolo insegnamento;</li>
                                    <li>Le votazioni riportate agli esami ed i Crediti Formativi Universitari acquisiti;</li>
                                    <li>I settori disciplinari;</li>
                                    <li>Gli obiettivi formativi previsti dal D.M. n. 616 del 10 Agosto 2017.</li>
                                </ul>
                            </ul>
                            <ul>
                                <li> Possibilità di integrare e acquisire contestualmente all’iscrizione ai <b>“Percorsi Formativi 24 CFU” anche i Crediti Formativi Universitari,
                                    tramite corsi erogati dall’Università per stranieri “Dante Alighieri” di Reggio Calabria, per accedere alle Classi di Concorso.</b></li></ul>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}