import React from "react";
import { FormGroup, Label, Input } from "design-react-kit";
import { TITLEREQUEST_STEPS } from "const";
import { CourseHelper, networkErrorHelper, titleRequestService } from "libs";
import { ErrorNotification } from "components/misc";

export class TitleRequestStart {

    title = 'INFO';
    iconName = 'it-pa';
    titleRequest = null;
    userProfile = null;
    userCourse = null;
    importToPay = 50;
    user = null;

    setPayload(payload) {

        this.titleRequest = payload.titleRequest;
        this.userProfile = payload.userProfile;
        this.user = payload.user;
        this.userCourse = payload.userCourse;
    }

    async onNextStep() {

        let canNext = false;
        if (this.importToPay > 0) {
            // send import to BE and update status
            canNext = await this.updateRemote();
        } else {
            // show alert
            ErrorNotification.render('E\' necessario specificare una opzione di pagamento   ', ' Selezione del pagamento');
        }

        return canNext;
    }

    async updateRemote() {


        let retCode = false;

        this.titleRequest.currentStep = TITLEREQUEST_STEPS.SIGN;
        if (CourseHelper.isPFU(this.userCourse.courseCode)) {
            this.titleRequest.importToPay = 0;
        } else {
            this.titleRequest.importToPay = this.importToPay;
        }

        await titleRequestService.updateRequestStep(this.titleRequest)
            .then(() => {

                retCode = true;
            })
            .catch(errors => {
                console.log(errors);
                retCode = false;
                networkErrorHelper.notify(errors);
            });

        return retCode;
    };

    setImport = (importToPay) => {

        this.importToPay = importToPay;
    }

    render() {
        if (CourseHelper.isPFU(this.userCourse.courseCode)) {
            return (<>

                <p>Tramite questa procedura è possibile richiedere il Certificato Finale.<br />
                    Per tale richiesta è necessario il pagamento, dovuto allo stato Italiano, di € 16,00 di bollo virtuale tramite servizio PagoPa (come previsto dalla normativa vigente). </p>
                <hr />
                <p>Per iniziare la procedura cliccare sul tasto <b>AVANTI</b></p>
            </>)

        } else if (this.userCourse.aa < 2023) {
            return (<>

                <p>Tramite questa procedura è possibile richiedere il certificato finale di conseguimento titolo e pergamena<br />
                    Tale procedura richiede il versamento di 32 euro di bolli virtuali e il pagamento della somma di euro 70 (Settanta):</p>
                <FormGroup check>
                    <Input name='importToPay' type='radio' id='importToPay70' onClick={() => this.setImport(70)} />
                    <Label check htmlFor='importToPay70'>
                        <b>euro 70 (Settanta/00) con bonifico o carta di credito </b> <br />
                        per l'invio della Pergamena finale nei prossimi mesi (mezzo raccomandata 1/corriere express) al proprio indirizzo.
                    </Label>
                </FormGroup>
                <hr />
                <p>Per iniziare la procedura clicca sul tasto <b>AVANTI</b></p>
            </>)
        } else {
            return (<>

                <p>Tramite questa procedura è possibile richiedere il certificato finale di conseguimento titolo e pergamena.<br />
                    Tale richiesta  richiede il versamento di 32 euro di bolli virtuali e il pagamento della seguente somma in base alla propria scelta:</p>
                {/*  TODO: in data 07/07/2023 "a.s 2022/2023" è stato sostituito con aa+1 poichè da questo momento in poi i corsisti iscritti all'aa 2022/2023 
                devono visualizzare 2023/2024. Invece i corsisti iscritti nell'aa 2021/2022 devono continuare a visualizzare 2022/2023. Richiesta di Francesco */}
                <FormGroup check>
                    <Input name='importToPay' type='radio' id='importToPay50' defaultChecked onClick={() => this.setImport(50)} />
                    <Label check htmlFor='importToPay50'>
                        <b>euro 50 (Cinquanta/00) con bonifico o carta di credito </b> <br />
                        nel caso in cui si impegna a ritirare la pergamena finale personalmente,
                        o tramite un proprio delegato, presso le sedi che saranno indicate nel calendario che invieremo entro la fine dell'a.s.
                        {parseInt(this.userCourse.aa + 1)}/{parseInt(this.userCourse.aa + 2)}.
                    </Label>
                </FormGroup>
                <hr />
                <FormGroup check>
                    <Input name='importToPay' type='radio' id='importToPay70' onClick={() => this.setImport(70)} />
                    <Label check htmlFor='importToPay70'>
                        <b>euro 70 (Settanta/00) con bonifico o carta di credito </b> <br />
                        per l'invio della Pergamena finale nei prossimi mesi (mezzo raccomandata 1/corriere express) al proprio indirizzo.
                    </Label>
                </FormGroup>
                <hr />
                <p>Per iniziare la procedura clicca sul tasto <b>AVANTI</b></p>
            </>)
        }
    }
}