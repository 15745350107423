import React, { Component } from "react";


import { Title, MnemoLoading } from 'components/misc';
import { Container } from "design-react-kit";

export class Career extends Component {

  state = {
    loading: true,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {


    this.setState({ loading: false });
  }

  render() {

    const { loading } = this.state;

    return (
      <>
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            <Container>
                <Title>La mia carriera in Mnemosine</Title>
            </Container>
          </>)}
      </>)
  }
}
