import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { MnemoLoading, BoxDanger, SupportoTecnico } from "components/misc";
import { learningService, networkErrorHelper } from "libs";

import { CourseSection } from 'components/learning/CourseSection';

import {
    Container
} from 'design-react-kit';

class MNESimulationExam extends Component {

    state = {
        rCode: 0,
        myCourse: 0,
        homeConfig: 0,
        loading: true,
        loadingError: false
    };

    componentDidMount() {

        // get registration code from parameter
        const { rCode } = this.props.match.params;
        this.setState({ rCode });

        this.loadCourse(rCode);
    }

    loadCourse(rCode) {
        learningService
            .courseMNESimulationExam(rCode, 1)
            .then(({ data }) => {

                const { userCourse, course, homeConfig } = data.payload;
                this.setState({ myCourse: userCourse, course, homeConfig, loading: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { loading, loadingError, homeConfig, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);
        
        return (

            <>
                {loadingError ? (<BoxDanger className="mt-4">Si è verificato un errore durante
                    il caricamento dei dati del corso, se il problema persiste <SupportoTecnico /> </BoxDanger>) : (
                    <>                        
                        <section className="my-4">
                            <Container>
                                {Object.values(homeConfig).map((row, i) => (
                                    <CourseSection key={`course-section-${i}`} sectionInfo={row} rCode={rCode} />
                                ))}
                            </Container>
                        </section>
                    </>
                )}
            </>);
    }
}

export default withRouter(MNESimulationExam);