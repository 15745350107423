import React, { Component } from "react";
import { UserDocument } from "components/forms/user/profile";

import { Container } from 'design-react-kit';

export class MyDocument extends Component {
  render() {
    return (
      <>
        <section className="my-4">
          <Container>             
              <UserDocument />
          </Container>
        </section>
      </>
    );
  }
}
