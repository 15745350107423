import React, { Component } from "react";

import { Helmet } from "react-helmet";
import {
  Row,
  Col,
} from "design-react-kit";

import "./PageSolidarity.scss";

export class PageSolidarity2 extends Component {
  render() {
    const title = "Mnemosine per il sociale";
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        {/* <Title>{title}</Title> */}
        <div className="pageSolidarity">
          <div className="pageSolidarity__wrapper">
            <div className="pageSolidarity__title">
              <h1 className="title">{title}</h1>
            </div>
            <Row>
              <p>
                La nostra Associazione, oggi azienda leader in Italia nel settore della formazione e del personale scolastico, fin dalla sua costituzione nel 2005 si è sempre impegnata in progetti di solidarietà.
              </p>
            </Row>
            <Row className="mb-5">
              <Col md="6" >
                <div className="container_solidarity">
                <iframe className="video_solidarity" src="https://www.youtube.com/embed/gcz7ILISE6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </Col>
              <Col md="6" className="p-5">
                <p>Negli ultimi mesi sono stati diversi i momenti che ci hanno visti partecipare attivamente, in Italia e all'estero. In Madagascar, a Novembre 2022, una nostra delegazione ha portato, ai bambini dell'isola, beni di prima necessità e articoli scolastici, affinché l'alfabetizzazione sia davvero un diritto accessibile a tutti, donando inoltre borse di studio agli studenti della comunità locale. </p>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="6">
                <img className="w-100" src="/img/pages/mnemosine_donazione_curia_agrigento.jpeg" alt="mnemosine_donazione_curia_agrigento" />
              </Col>
              <Col md="6" className="p-5" >
                <p>Il 30 dicembre, a conclusione del 2022, abbiamo donato un defibrillatore alla curia di Agrigento, erano presenti il Vescovo di Agrigento, il Presidente della croce rossa, e il presidente del Lions Club della città.</p>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="6">
              <img className="w-100" src="/img/pages/mnemosine_donazion_lilt.jpeg" alt="mnemosine_donazion_lilt" />
              </Col>
              <Col md="6" className="p-5">
                <p>Sabato 4 febbraio abbiamo donato un ecografo Samsung a 3 alla LILT di Santa Margherita di Belìce, in Sicilia. Con la nuova strumentazione, la lilt del comune agrigentino è adesso in grado di estendere il ventaglio di servizi all'utenza. Non solo visite al seno mirate alla prevenzione del tumore che colpisce le donne, ma anche visite ginecologiche, urologiche e otorinolaringoiatriche ed ecografiche.</p>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col md="6">
              <img className="w-100" src="/img/pages/phoenix.jpeg" alt="mnemosine_donazione_university_phoenix" />
              </Col>
              <Col md="6" className="p-5">
              
                <p>Dall' anno accademico 2022, inoltre, finanziamo la borsa di studio offerta ad una studentessa, per la frequenza del corso universitario presso la University of Phoenix, in Arizona negli Stati Uniti d'America.</p>
              </Col>
            </Row>
            <p>
              Il nostro sostegno alla ricerca e il nostro impegno nella condivisione degli strumenti per garantire a sempre più persone il diritto alla salute e all'istruzione, sono esempi concreti della filosofia alla base di Mnemosine, un ente di formazione che vuole integrare crescita e benessere individuale con quello territoriale e globale.</p>
            <p>Perché “Il futuro esige una visione umanista dell'economia e una politica che realizzi sempre più e meglio la partecipazione della gente, eviti gli élitarismi e sradichi la povertà. Che nessuno sia privo del necessario e che a tutti sia assicurata dignità, fratellanza e solidarietà.” Papa Francesco
            </p>          
          </div>
        </div>
        
      </>
    );
  }
}
