import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { cache } from '../../libs';

import {
    CookieBar, CookieBarButtons, CookieBarButton
} from 'design-react-kit';

import { ROUTES } from 'const';

export class CookieBarMnemo extends Component {

    state = {
        accepted: false,
    };

    accept = () => {
        this.setState({ accepted: true });
        cache.set('privacy',{'accepted':true});
    }

    componentDidMount(){
        //--- check if accepted on device ---
        if(cache.get('privacy')!==null){
            this.setState({ accepted: true });
        }
    }

    render() {

        const { accepted } = this.state;

        return (
            <>
                {!accepted && (
                    <>
                        <CookieBar>
                            <p>Questo sito utilizza cookie tecnici, analytics e di terze parti.
Proseguendo nella navigazione accetti l'utilizzo dei cookie.</p>
                            <CookieBarButtons>
                                <CookieBarButton href="#" onClick={this.accept} data-accept="cookiebar" >Accetto</CookieBarButton>
                                <NavLink className="cookiebar-btn" to={ROUTES.PRIVACY_POLICY}>Privacy Policy</NavLink>
                            </CookieBarButtons>
                        </CookieBar>
                    </>)}
            </>
        );
    }
}
