import React, { Component } from 'react';
import Helmet  from 'react-helmet';

export class MSEO extends Component {

    render() {

        const { title, image='', type='website', url=window.location.href } = this.props;

        return (<>    
        <div>
            <Helmet>
                {(title && title.length>0) && (<>
                    <title>{`${title}`}</title>
                    <meta property="og:title" content={`${title}`} />
                
                </>)}
                {(image && image.length>0) && (<>
                    <meta property="og:image" content={`${image}`} />
                </>)}
                {(url && url.length>0) && (<>
                    <meta property="og:url" content={`${url}`} />
                </>)}
                {(type && type.length>0) && (<>
                    <meta property="og:type" content={`${type}`} />
                </>)}                
            </Helmet>      
            </div>         
        </>);
    }
}