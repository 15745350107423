import React, { Component } from "react";
import { PDFView } from "components/misc";
import { withRouter } from "react-router-dom";

class BookViewer extends Component {  

  render() {
    const { state } = this.props.location;
    console.log(state);
    let bookPDF = "";
    if (state && state.pdfUrl) {
      
      bookPDF = process.env.REACT_APP_API_URL + "/" + state.pdfUrl;
    }
    // console.log("book", bookPDF);
    return (
      <PDFView file={bookPDF} canDownload={true} showAlert={false}></PDFView>
    );
  }
}

export default withRouter(BookViewer);
