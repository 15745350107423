import React, { Component } from 'react';

import {
    Card,
    CardBody, CardTitle,
    CardText, Button,
    CardFooter
} from 'design-react-kit';

import { Link } from 'react-router-dom';

export class MnemoCardImage extends Component {

   render() {

        const { title, content, image = "lezione.webp",
            actionLabel, actionLink,
            actionParam, className } = this.props;

        let to = actionLink;
        if (actionParam) {
            to += `/${actionParam}`;
        }

        let imageSrc = "/img/" + image;
        return (
            <>
                <article className= {`card-wrapper card-space ${className}`} >
                    <Card noWrapper className="card-bg card-img shadow">
                        <div className="img-responsive-wrapper">
                            <div className="img-responsive">
                                <figure className="img-wrapper">
                                    <img
                                        alt={title}
                                        src={imageSrc}
                                        title={title}
                                    />
                                </figure>                                
                            </div>
                        </div>
                        <CardBody>
                            {title && (<CardTitle tag="h5">{title}</CardTitle>)}
                            <CardText>{content}</CardText>
                            <CardFooter >
                                <Button className="float-right" color="primary" outline>
                                    <Link className="text-dark" to={to} >
                                        {actionLabel}
                                    </Link>
                                </Button>
                            </CardFooter>
                        </CardBody>
                    </Card>
                </article>
            </>
        );
    }
}