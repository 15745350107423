

import * as React from "react"

const IconArrowRight = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" 
        className="h-6 w-6"  
        viewBox="0 0 24 24" 
        stroke="currentColor" 
        strokeWidth={2}
        width={props.width ? props.width : 24}
        height={props.height ? props.height : 24}
        fill={props.fill ? props.fill : '#ffffff'}
        {...props}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
    </svg>
)
export default IconArrowRight