import React, { Component } from "react";
import { networkErrorHelper, learningService, CourseHelper } from "libs";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { ROUTES } from "const";
import { CourseList } from "components/course";
import { Icon } from "design-react-kit";

class CoursePayments extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        rCode: 0
    };

    componentDidMount() {
        const { rCode } = this.props.match.params;
        if (rCode) {
            this.loadRemote(rCode);
        } else {
            this.setState({
                loading: false
            });
        }
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;

                // if unicamillus redirct to MU_PAYMENT
                if (CourseHelper.isUnicamillus(course.code)){
                    window.location = ROUTES.MU_COURSE_PAYMENTS+'/'+rCode;
                }
                this.setState({ loading: false, course, userCourse, rCode });               
            })
            .catch(error => {
                
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }//loadRemote

    renderIFrame = () => {

        const { course, rCode } = this.state;
        if (course) {
            const aaURL = course.aa.toString().slice(-2);
            let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + 'moodleElearningPayment/renderPaymentList/rCode/' + rCode;
            let height = '10500px';

            return (
                <>
                    <div>
                        <embed src={src} width="99%" height={height} />
                    </div>
                </>
            );
        }
    }

    render() {

        const { loading, loadingError, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        return (
            <>
                {(rCode) ? (
                    <>
                        <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                        <Title>Situazione Pagamenti</Title>                       
                        {this.renderIFrame()}
                    </>
                ) : (<>
                    <Title subtitle="Seleziona il corso">Situazione pagamenti</Title>
                    <CourseList actionLink={ROUTES.COURSE_PAYMENTS}></CourseList>
                </>)}
            </>)
    }

}

export default withRouter(CoursePayments);