import React, { Component } from 'react';
import { MnemoLoading, BoxDanger, ModalHandler } from 'components/misc';
import { adminService, networkErrorHelper, dateHelper } from "libs";
import dayjs from 'dayjs';

import { MTable } from "components/table";
import { Container } from 'design-react-kit';

import { MToggle } from 'components/forms';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';


export class Promotional extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rndKey: 0,
            payload: [],
            searchFilter: {
              searchFilter: {
                isExpired: false,
                isDisabled: true
              }
            },
        };
    }

    //--- ---

    tableConfig = {
        columns: [
            {
                id: 'code',
                Header: 'Codice',
                accessor: 'code',
            },
            {
                id: 'courseCode',
                Header: 'Corso',
                accessor: 'courseCode',
            },
            {
                id: 'dateStart',
                Header: 'dal',
                //accessor: 'dateStart',
                accessor: row => { return (row.dateStart) ? dateHelper.toITDate(row.dateStart) : ''},
            },
            {
                id: 'dateEnd',
                Header: 'al',
                //accessor: 'dateEnd',
                accessor: row => { return (row.dateEnd) ? dateHelper.toITDate(row.dateEnd) : ''},
            },
			{
				id: 'amount',
				Header: 'Importo',
				accessor: 'amount',
			},
			{
				id: 'tmpEnabled',
				Header: 'Stato',
				accessor: 'tmpEnabled',
			}
        ],
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    window.location.href = ROUTES.PROMOTIONAL_FORM + '/' + value;
                }
            },
            {
                id: 'delete',
                accessor: 'id', // field value received in the onclick event
                label: 'Elimina',
                icon: 'it-delete',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    this.deleteRecord(e, value)
                }
            },
            {
                id: 'status',
                accessor: 'id', // field value received in the onclick event
                label: 'Abilita/Disabilita',
                icon: 'it-less-circle',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    this.willDeleted(e, value)
                }
            }
        ]
    }

    //--- ---

    componentDidMount(){
        this.loadRemote(this.state.searchFilter);
    }

    loadRemote(filter) {

        this.setState({ loading: true });

        adminService.getPromotional(filter).then(({ data }) => {

			//--- start: prepare reactTable ---
			const items = [];
			Object.entries(data.payload.promotional).forEach(([key, val]) => {

				//--- manage exception to render human-readable data ---
				if (val.state) {
					val.tmpEnabled = 'abilitato'
				} else {
					val.tmpEnabled = 'disabilitato'
				}

                /** check if expired and re-assign val.tmpEnabled to right render message */
                if(!dayjs().isBefore(dayjs(val.dateEnd))){
					val.tmpEnabled = 'scaduto';
                }
                if(!dayjs().isBefore(dayjs(val.dateEnd)) && !val.state){
					val.tmpEnabled = 'scaduto/disabilitato';
                }
				//--- ---

				items.push(val);
			});

			this.setState({
				loading: false,
				payload: items,
				rndKey: Math.floor((Math.random() * 1000) + 1)
			});
			//--- end: prepare reactTable ---
        })
        .catch(error => {              
            if (!networkErrorHelper.is404(error)) {
                console.log(error);
                networkErrorHelper.notify(error);
            }else{
                this.setState({
                  payload:[],
                  rndKey: Math.floor((Math.random() * 1000) + 1)
                })          
            }

            this.setState({ loading: false });
        });
    }


    //--- start functions to willdeletd procedure ---
    willDeleted(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Disabilita/Abilita promozione',
            'vuoi davvero procedere?', null, this.changeStatusConfirmed);
    }

    changeStatusConfirmed = (value) => {
        adminService.changePromotionalStatus(value).then(({ data }) => {
            this.loadRemote(this.state.searchFilter);
        })
        .catch(error => {
            console.log(error);
            if (networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            }
        });
    }
    //---- end functions to willdeleted procedure ----
    
    //--- start functions to delete procedure ---
    deleteRecord(e, value) {
        e.stopPropagation();
        ModalHandler.show(value, 'Eliminazione della Promozione',
            'vuoi davvero eliminare?', null, this.deleteConfirmed);
    }

    deleteConfirmed = (value) => {
        let payload = {
            id: value
        };

        adminService.deletePromotional(payload.id).then(({ data }) => {
            this.loadRemote(this.state.searchFilter);
        })
        .catch(error => {
            console.log(error);
            networkErrorHelper.notify(error);
        });
    }
    //---- end functions to delete procedure ----

    onChangeDisabled = () => {
        this.setState({
            searchFilter: {
                searchFilter: {
                    isExpired: this.state.searchFilter.searchFilter.isExpired,
                    isDisabled: !this.state.searchFilter.searchFilter.isDisabled
                }
            }
        }, () => {
            this.loadRemote(this.state.searchFilter);
        });
    }//onChangeDisabled

    onChangeExpired = () => {
        this.setState({
            searchFilter: {
                searchFilter: {
                    isExpired: !this.state.searchFilter.searchFilter.isExpired,
                    isDisabled: this.state.searchFilter.searchFilter.isDisabled
                }
            }
        }, () => {
            this.loadRemote(this.state.searchFilter);
        });
    }//onChangeExpired

    //--- ---

    render() {

        const { loading } = this.state;
        
        return (
            <>
            
            <section className="m-0 mt-4">
                <div className="form-row">
                    <div className="col-md-4">
                        <MToggle
                        id='isDisabled'
                        label="Disabilitate/Abilitate"
                        value={this.state.searchFilter.searchFilter.isDisabled}
                        disabled={false}
                        onChange={this.onChangeDisabled}
                        />
                    </div>

                    <div className="col-md-3">
                        <MToggle
                        id='isExpired'
                        label="Scadute"
                        value={this.state.searchFilter.searchFilter.isExpired}
                        disabled={false}
                        onChange={this.onChangeExpired}
                        />
                    </div>

                    <div className="col-md-5 text-right">
                        <NavLink to={ROUTES.PROMOTIONAL_FORM} className="btn btn-primary bg-dark pb-2">Nuova Promozione</NavLink>
                    </div>
                </div>
            </section>

            <hr />

            <section className="my-4">
            {loading ? (<MnemoLoading></MnemoLoading>) : (
                <Container>
                    <MTable
                        key={this.state.rndKey}
                        tableData={this.state.payload}
                        tableConfig={this.tableConfig}
                    />
                </Container>
            )}           

            {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
              Ricerca non valida.
              <div className="small text-info">
                  Nessun dato per questa selezione, cambia i parametri di ricerca.
              </div>
            </BoxDanger>)}
            </section>
            </>);
    }

}