import * as React from "react";

const IconHeart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    // fill={props.fill ? props.fill : "#ffffff"}
    stroke={props.stroke ? props.stroke : "#ffffff"}
    fill={props.fill ? props.fill : "#ffffff"}
    // fill={}
    {...props}
  >
    <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
  </svg>
);

export default IconHeart;
