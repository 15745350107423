import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { BoxDanger, BoxInfo, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { certificationService, learningService, networkErrorHelper } from "libs";
import { Button } from "design-react-kit";

class ICDLCertification extends Component {

    state = {
        loading: true,
        loadingError: false,
        isEnabled: 0,
        course: 0,
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse });

                certificationService.isEnabled(course.aa, rCode)
                    .then(({ data }) => {                        
                        const isEnabled = parseInt(data);

                        this.setState({ loading: false, loadingError:false, isEnabled, rCode });
                    })
                    .catch(error => {
                        networkErrorHelper.notify(error);
                        this.setState({ loading: false, loadingError: true, isEnabled:false, rCode  });
                    });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    renderIFrame() {

        const { course, rCode } = this.state;
        const aaURL = course.aa.toString().slice(-2);
        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) 
        + 'moodleElearning/icdlCertificate/rCode/' + rCode;

        let height = '10500px';

        return (
            <>          
                <div>
                    <embed src={src} width="99%" height={height} />
                </div>
            </>
        );
    }

    render() {

        const { loading, loadingError, isEnabled } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        const info = isEnabled ? this.renderIFrame() :
            <><BoxInfo>Non è presente nessuna certificazione da richiedere. <Button onClick={this.goBack} color="primary bg-dark" >Torna indietro</Button></BoxInfo></>;

        return (<>

            <Title >Richiesta Certificazioni</Title>
            {info}
        </>)
    }
}

export default withRouter(ICDLCertification);