import React from "react";

import {
    MLabel,
    MInput,
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentAdminService, networkErrorHelper
} from "libs";

export class EnrollFillFormM24 extends ValidatedForm {

    PAYLOADS = {
        examSites: [],
        learningSites: [],
        freeCourses: [],
        examSession: null
    }

    FIELDS_GROUP = [
        [
            {
                field: "idFreeCourse",
                label: "Richiedi Gratuitamente il tuo Omaggio di benvenuto: Iscrizione e partecipazione ad uno dei seguenti Corsi ",
                payload: { options: this.PAYLOADS.freeCourses },
                component: MSelect,
                className: "col-md-12"
            }
        ],
        [
            {
                field: "preferExamOnline",
                label: "Esami a distanza",
                infoText: "Compatibilmente con le norme sanitarie vigenti, in caso di esami a distanza, puoi indicare un Città",
                component: MLabel,
                className: "col-md-12 pt-3"
            }
        ],[
            {
                field: "examSiteOnline",
                label: "Inserisci una città",
                component: MInput,
                className: "col-md-6  pt-3"
            }
        ],
        [
            {
                field: "labelLEaringSite",
                label: "Prove di profitto scritte",
                infoText: "Seleziona una città dove sostenerele prove di profitto scritte, secondo quanto riportato nell’art. 3 comma 2 del Bando di Partecipazione",
                component: MLabel,
                className: "col-md-6 pt-3"
            }
        ],[
            {
                field: "learningSite",               
                payload: { options: this.PAYLOADS.learningSites },
                component: MSelect,
                className: "col-md-6"
            }
        ] ,
        [
            {
                field: "preferExamPresence",
                label: "Prova orale finale del Master",
                infoText: "Seleziona una città dove sostenere la prova esame orale secondo quanto riportato nell’art. 3 comma 3 del Bando di Partecipazione",
                component: MLabel,
                className: "col-md-6 pt-3"
            }
        ],[
            {
                field: "examSite",               
                payload: { options: this.PAYLOADS.examSites },
                component: MSelect,
                className: "col-md-6"
            }
        ]        
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede per la prova orale finale del Master',
        learningSite: 'Specificare una sede per le prove di profitto scritte',
        idFreeCourse: 'Selezionare un corso gratuito'
    };

    validation = {
        examSite: value => value > '',
        learningSite: value => value > '',
        idFreeCourse: value => value > '',
    };

    emptyFields = {
        examSiteOnline: '',
        officeCode: '',
        examSite: 0,
        learningSite: 0,
        hasBonusCourse: false,
        idFreeCourse: 0,
    };

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const { idExamSession, courseCode } = this.props.enrollData;

        if (this.PAYLOADS.examSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(idExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        if (this.PAYLOADS.learningSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.learningSites(idExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.learningSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.learningSites.push(...sites);

                    this.setState({
                        learningSites: { options: this.PAYLOADS.learningSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        if (this.PAYLOADS.freeCourses.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.freeCourses(courseCode)
                .then(({ data }) => {

                    const { payload } = data;
                    const courses = payload.map(({ id, title }) => ({ label: title, value: id }));

                    this.PAYLOADS.freeCourses.unshift({ label: 'Seleziona corso', value: 0 });
                    this.PAYLOADS.freeCourses.push(...courses);

                    this.setState({
                        esamSites: { options: this.PAYLOADS.esamSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }
        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);
        const { idFreeCourse, learningSite } = this.state.defaultValues;
        this.payload.pluginConfig = {learningSite: learningSite};
        
        if (idFreeCourse) {
            this.payload.hasBonusCourse = true;
            const courses = this.PAYLOADS.freeCourses.filter(item => (item.value === idFreeCourse));
            if (courses) {
                this.payload.titleFreeCourse = courses[0].label;
            }
        } else {
            this.payload.titleFreeCourse = '';
            this.payload.hasBonusCourse = false;
        }

        const validations = {
            examSite: null,
            learningSite: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            {code}
        </>);
    }
}