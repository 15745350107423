import React, { Component } from "react";
import { setAdvModalCookie, showAdvModalCookie } from "libs/helpers/cookieHelper";

import {
    Icon
} from 'design-react-kit';

import "./advModal.scss";

export class AdvModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true
        };
    }

    componentDidMount() {
        this.setState({ isOpen: showAdvModalCookie(this.props.advName) })
    }

    toggleModal() {
        this.setState({ isOpen: false });       
        setAdvModalCookie(this.props.advName, 'false');             
    }

    render() {
              
        if (this.state.isOpen) {

            return (
                <div className="advModal">
                    <div className="advModal__overlay" onClick={() => this.toggleModal()}></div>
                    <div className="advModal__wrapper">
                        <div className="advModal__content">
                            <div className="advModal__close" onClick={() => this.toggleModal()}> <Icon className='align-bottom' icon='it-close-circle' color="white" /></div>
                            <img
                                src={this.props.image}
                                alt="Promozione Mnemosine"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return null;
    }
}