import React from "react";
import { CertificationRequestDownloadForm } from "./";

export class CertificationRequestDownload {

    title = 'DONWLOAD';
    iconName = 'it-file';
    certificationRequest = null;

    setPayload(payload) {

        this.certificationRequest = payload.certificationRequest;
        this.certificationRequestMNEMO = payload.certificationRequestMNEMO;
        this.certificationRequestGOMP = payload.certificationRequestGOMP;
    }

    async onNextStep() {
        return false;
    }

    render() {

        return (<>

            <CertificationRequestDownloadForm
                registrationCode={this.certificationRequestMNEMO.registrationCode}
                requestID={this.certificationRequest.id}
            />
        </>);
    }
}