import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class PageGsuiteDetails extends Component {

    render() {
        const title = 'LA DIDATTICA CON GOOGLE CLASSROOM IN AMBIENTE G-SUITE';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <h6>Scopo</h6>
                            <p>
                                Accompagnare i corsisti ad acquisire, progressivamente, le competenze per utilizzare le APP di Google.</p>
                            <h6>Argomenti</h6>
                            <p>Il percorso è orientato a fornire competenze operative per organizzare e gestire compiti, per la didattica a distanza (DAD e DID),
                                lavorando con classroom, in ambiente Google Gsuite. </p>
                            <p>Si analizzeranno varie tipologie di compiti, nelle fasi dalla preparazione all’assegnazione,
                                dalla consegna degli allievi alla correzione, dalla restituzione dei risultati all’archiviazione, con integrazione della griglia di valutazione.</p>
                            <p>L’integrazione con Estensioni di Chrome e Componenti aggiuntive forniranno valore aggiunto al processo educativo,
                                consentendo di creare oggetti didattici innovativi.</p>
                            <p>L’utilizzo di lavagne virtuali e mappe interattive combinate all’esecuzione di Meet consentiranno di raffinare le tecniche
                                per coinvolgere attivamente gli allievi.</p>
                            <p>In ambito didattico, si sperimenteranno diversi compiti strutturati, riferiti all’applicazione delle diverse APP,
                                la cui integrazione in proposte didattiche fornirà valore aggiunto al processo educativo, consentendo di creare risorse didattiche innovative,
                                immediatamente sperimentabili in classe:</p>
                            <ul>
                                <li>Classroom</li>
                                <li>Google Meet</li>
                                <li>Applicazione di Google Earth</li>
                                <li>Powtoon</li>
                                <li>Story telling per la didattica</li>
                                <li>Kahoot</li>
                                <li>Youtube</li>
                            </ul>
                            <h6>Finalità</h6>
                            <p>Favorire lo sviluppo delle competenze necessarie per preparare ed erogare lezioni che prevedono l’utilizzo delle diverse APP Google sia in modalità online (sincrona e asincrona) che in presenza, con la capacità di strutturare elaborati coinvolgenti ed
                                accattivanti per favorire il coinvolgimento attivo degli alunni, nonché per gestire la condivisione e l’archiviazione degli stessi.</p>
                            <p>Sviluppare competenze operative per erogare lezioni in modalità online sincrona, attraverso sessioni in diretta, coadiuvate da una pluralità di strumenti interattivi, nonché in modalità asincrona attraverso la strutturazione di elaborati coinvolgenti ed accattivanti, creati con l’ausilio di altri strumenti integrativi ed aggiuntivi. Si finalizzerà, altresì,
                                il percorso alla corretta archiviazione del materiale didattico prodotto (compiti, correzioni, valutazioni, feedback, video delle lezioni online…).</p>
                            <h6>Obiettivi</h6>
                            <ul>
                                <li>Individuare i principali strumenti digitali e piattaforme utili alla creazione di videolezioni.</li>
                                <li>Conoscere ed utilizzare i principali device a supporto della didattica.</li>
                                <li>Conoscere gli strumenti necessari a lavorare in ambienti online e all’interno di comunità di apprendimento online.</li>
                                <li>Individuare potenzialità e criticità della valutazione a distanza.</li>
                                <li>Attuare strategie di inclusione a distanza.</li>
                                <li>Acquisire consapevolezza operativa nella didattica innovativa, relativamente all’organizzazione,
                                    strutturazione e gestione di tutto il processo didattico, attraverso l’interazione delle diverse APP di Google,</li>
                                <li>Utilizzare le tante potenzialità delle APP google per la costruzione e condivisione di risorse educative,
                                    che favoriscono l’apprendimento sia in presenza che nella DID, grazie ad un sapiente utilizzo degli strumenti della tecnologia</li>
                                <li>Acquisire consapevolezza operativa nella Didattica a Distanza, relativamente alla gestione dei compiti durante l’intero processo didattico, attraverso l’interazione degli strumenti disponibili in G-Suite,
                                    integrando gli stessi per la creazione di risorse educative aperte, atte a favorire l’apprendimento nella DaD.</li>
                            </ul>
                            <h6>Contenuti</h6>
                            <ul>
                                <li>Usare la tecnologia in classe;</li>
                                <li>Creare una rete per facilitare l’espansione delle risorse per l’apprendimento;</li>
                                <li>limitare l’uso della carta in classe e aumentare l’utilizzo degli strumenti tecnologici;</li>
                                <li>La comunicazione nel processo didattico;</li>
                                <li>Gestione efficace del tempo nell’organizzazione di attività didattiche;</li>
                                <li>Programmare, coordinare e gestire riunioni online in ambito didattico;</li>
                                <li>Lavorare online con gli studenti in maniera collaborativa;</li>
                                <li>La trasmissione efficace delle competenze digitali agli studenti;</li>
                                <li>La creazione delle lezioni digitali e interattive;</li>
                                <li>Rendere la lezione più accattivante attraverso l’uso di video e risorse multimediali;</li>
                                <li>Facilitare la condivisione e il lavoro di gruppo tra gli studenti;</li>
                                <li>Promuovere la cittadinanza digitale e un utilizzo virtuoso delle risorse online.</li>
                                <li>La predisposizione e organizzazione digitale nella Didattica a Distanza (sincrona e asincrona),
                                    mediante strumenti integrati che concorrono alla realizzazione organica di una lezione a distanza</li>
                                <li>Integrazione nei compiti delle funzionalità di Documenti e Presentazioni, in modalità collaborativa per stimolare il coinvolgimento attivo degli allievi</li>
                                <li>Utilizzo di Google Moduli per la somministrazione di test con autovalutazione e Google Sites</li>
                                <li>Correzione dei compiti mediante modalità operative differenti, in base alle peculiari caratteristiche dei compiti assegnati e delle consegne degli allievi</li>
                                <li>restituzione delle correzioni, con distribuzione su differenti canali, in base ai device utilizzati dagli allievi</li>
                                <li>Esportazione, organizzazione ed archiviazione dei compiti con matrice, consegne, correzioni ed eventuale valutazione</li>
                                <li>Integrazione di lavagne condivise nelle sessioni di video conferenza con Meet, a supporto del processo didattico attraverso estensioni e componenti aggiuntive per la realizzazione di unità formative interattive.</li>
                            </ul>
                            <h6>Articolazione</h6>
                            <p>Il percorso si struttura su complessive n.60 ore di formazione, suddivise in</p>
                            <ul>
                                <li>45 ore attività in e-learning per studio materiale strutturato</li>
                                <li>10 ore in videolezioni</li>
                                <li>5 ore verifiche apprendimento (3 verifiche in itinere e 1 finale)</li>
                            </ul>
                            <p>L’attività in e-learning potrà essere svolta dai partecipanti in qualunque fascia oraria, comodamente da casa,
                                collegandosi mediante il proprio PC alla piattaforma e-learning, attraverso materiali didattici ed in forma di videolezioni</p>
                            <h6>Modulo 1 - Introduzione alla didattica a distanza</h6>
                            <ul>
                                <li>Che cos’è la DaD e la DID e come si attuano.</li>
                                <li>Strategie e utilizzo di piattaforme per la DaD e la DID (Classroom)</li>
                                <li>Come strutturare la didattica a distanza.</li>
                                <li>Valutazione formativa e oggettiva.</li>
                            </ul>
                            <h6>Modulo 2 - Gli ambienti online per la didattica a distanza G-Suite for Education</h6>
                            <ul>
                                <li>Che cos’è Google Classroom</li>
                                <li>Accedere a Google Classroom</li>
                                <li>Creare ed aggiungere studenti a un corso</li>
                                <li>Visualizzare la pagina Stream</li>
                                <li>Visualizzare la pagina Lavori del corso</li>
                                <li>Visualizzare la pagina Persone</li>
                                <li>Creare compiti</li>
                                <li>Fornire feedback e voti</li>
                                <li>Creare classi con Google Classroom e web conference con google Meet.</li>
                                <li>Preparare questionari di valutazione con Moduli e utilizzare la scrittura collaborativa su Drive.</li>
                                <li>Utilizzo di Google Moduli per la somministrazione di test con autovalutazione</li>
                                <li>Restituzione delle correzioni, con distribuzione su differenti canali, in base ai device utilizzati dagli allievi</li>
                                <li>Esportazione, organizzazione ed archiviazione dei compiti con matrice, consegne, correzioni ed eventuale valutazione</li>
                            </ul>
                            <h6>
                                Modulo 3 - Strumenti e tecnologie per lezioni in videoconferenza sincrona ed asincrona
                            </h6>
                            <p>Google meet:</p>
                            <ul>
                                <li>Avviare una riunione video</li>
                                <li>Partecipare a una riunione video</li>
                                <li>Aggiungere persone a una riunione</li>
                                <li>Personalizzare le riunioni video</li>
                                <li>Cambiare i layout dello schermo in una riunione</li>
                                <li> Fissare, disattivare l’audio o rimuovere partecipanti in una riunione</li>
                                <li>Utilizzare i sottotitoli in una riunione video</li>
                                <li>Utilizzare il telefono per l’audio in una riunione video</li>
                                <li>Condividere le risorse in una riunione video</li>
                                <li>Visualizzare i dettagli e gli allegati di una riunione</li>
                                <li>Inviare messaggi di chat ai partecipanti a riunioni video</li>
                                <li>Esporre una presentazione durante una riunione video</li>
                                <li>Trasmettere riunioni video a un gruppo più grande</li>
                                <li>Registrare una riunione</li>
                                <li>Trasmettere una riunione video in live streaming</li>
                            </ul>
                            <p>Kahoot:</p>
                            <ul>


                                <li>Cos’ è Kahoot!?</li>
                                <li>Come ci si registra Kahoot!?</li>
                                <li>Come funziona Kahoot!!?</li>
                                <li>Creare un nuovo Kahoot!?</li>
                                <li>Come cercare quiz su Kahoot</li>
                                <li>Interattività di Kahoot!</li>
                                <li>Come cercare quiz su Kahoot</li>

                            </ul>
                            <h6>Modulo 4 - La didattica digitale tra narrazione e gioco (Digital Storytelling e Gamification)</h6>
                            <ul>


                                <li>Digital storytelling (PoowToon)</li>
                                <li>Storytelling e codici comunicativi: testo, audio, video, immagini.</li>
                                <li>Differenti tipologie di storytelling: lineare, non lineare, adattivo, collaborativo.</li>
                                <li>Il ruolo dei differenti device nella creazione/fruizione di contenuti: lo storytelling mobile.</li>
                                <li>Dallo storytelling al gioco: lo storytelling interattivo</li>

                            </ul>
                            <h6>Modulo 5 - Utilizzo degli strumenti geografici di google hearth education</h6>
                            <ul>
                                <li>Download ed installazione</li>
                                <li>Le diverse aree funzionali Le diverse aree funzionali</li>
                                <li>Configurazioni Configurazioni</li>
                                <li> Comandi di navigazione Comandi di navigazione</li>
                                <li> Ricerca luoghi d’interesse ed indicazioni Ricerca luoghi d’interesse ed indicazioni indicazioni</li>
                                <li> Inserimento punti, linee e poligoni Inserimento punti, linee e poligoni</li>
                                <li> Modifica di elementi esistenti Modifica di elementi esistenti</li>
                                <li>Gestione degli stili Gestione degli stili</li>
                                <li>Inserimento di linee e poligoni 3d Inserimento di linee e poligoni 3d</li>
                                <li>Gestione dei “Miei luoghi” e dei “Luoghi temporanei” temporanei”</li>
                            </ul>
                            <h6>Materiali Didattici</h6>
                            <ul>
                                <li>Videolezioni sui principali strumenti per la didattica a distanza.</li>
                                <li>Dispense del corso.</li>
                                <li>Slide di presentazione degli argomenti.</li>
                                <li>Format di progettazione.</li>
                                <li>Schede e link di approfondimento.</li>
                                <li>Esempi di buone pratiche.</li>
                            </ul>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}