import * as React from "react"

const IconArrowDown = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    fill={props.fill ? props.fill : '#ffffff'}
    {...props}>
    <path
      fillRule="evenodd"
      d="m4.102 6.356 7.886 8.45 7.93-8.45c1.044-1.142 2.832.732 1.742 1.873l-8.931 9.637c-.392.41-1.045.41-1.394 0L2.317 8.229c-1.045-1.143.74-3.015 1.786-1.873h-.001Z"
    />
  </svg>
)

export default IconArrowDown