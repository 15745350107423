import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminLessonLiveScheduleForm } from "components/forms/admin/AdminLessonLiveScheduleForm";


class LessonLiveScheduleForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
        idLessonLiveSchedule: parseInt(this.props.match.params.idLessonLiveSchedule) > 0 ? this.props.match.params.idLessonLiveSchedule : 0
    }
  }

  render() {

    const { idLessonLiveSchedule } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{(idLessonLiveSchedule > 0) ? 'Aggiorna Lezione Sincrona' : 'Crea Lezione Sincrona'}</Title>
            <AdminLessonLiveScheduleForm idLessonLiveSchedule={idLessonLiveSchedule || 0}/>
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(LessonLiveScheduleForm);
