import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';

import { learningService, networkErrorHelper } from "libs";
import ReactHtmlParser from 'react-html-parser';

import { MnemoLoading, BoxDanger, ModalHandler, Title, SupportoTecnico, MSection, BoxInfo } from "components/misc";
import { Button, Icon, Row, Col } from "design-react-kit";
import { ROUTES } from "const";
import { MRadioButton } from "components/forms";

class SurveyMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: null,
            surveyAttempt: null,
            rCode: this.props.match.params.rCode || '',
            codeSurvey: this.props.match.params.scode,
            isLoading: true,
            loadingError: false,
            answerAttempt: ''
        };
    }

    componentDidMount() {

        learningService.getSurvey(this.state.codeSurvey)
            .then(({ data }) => {

                this.setState({
                    isLoading: false, loadingError: false,
                    survey: data.payload.survey,
                    surveyAttempt: data.payload.surveyAttempt
                })


            })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                console.log(error)
                this.setState({ isLoading: false, loadingError: true });
            })


    }//componentDidMount

    /** */
    sendAnswer = () => {

        this.setState({
            isLoading: true,
            message: null
        });

        const { survey } = this.state;
        const questions = survey.questions;

        // TODO: sistemare meglio sta cosa
        const question = questions[0];

        const surveyAttempt = {
            idSurvey: survey.id,
            title: survey.title,
            description: survey.description,
        };

        surveyAttempt.question = {
            type: question.type,
            label: question.label,
            order: question.order,
        };

        surveyAttempt.question.answer = {
            label: this.state.answerAttempt
        }

        learningService.sendSurvey(surveyAttempt)
            .then(({ data }) => {

                this.setState({
                    isLoading: false,
                    surveyAttempt: data.payload.surveyAttempt
                })
            })
            .catch(error => {

                networkErrorHelper.notify(error);
                console.log(error)
                this.setState({ isLoading: false, loadingError: true });
            })
    }//sendSurvey

    onChangeAnswer = (id, value) => {

        this.setState({ answerAttempt: value })
    }

    /** */
    renderAttempt(surveyAttempt) {

        let code = '';
        if (surveyAttempt && surveyAttempt.questions) {

            const question = surveyAttempt.questions[0];
            if (question && question.answers) {

                const answer = question.answers[0];
                if (answer) {
                    code = answer.label;
                }
            }
        }
        return code;
    }


    renderSurvey(survey) {

        let code = '';

        if (survey && survey.questions) {
            code = survey.questions.map((question, i) => {

                const options = question.answers.map((answer, j) => {
                    return { value: answer.label, label: answer.label }
                })

                const payloadRadio = { options }
                return (<div key={i}>
                    {(question.label) && (<p>{question.label}</p>)}
                    <Row>
                        <Col>
                            <MRadioButton
                                onChange={this.onChangeAnswer}
                                id={question.id}
                                payload={payloadRadio}
                                value={this.state.answerAttempt}
                            ></MRadioButton>
                        </Col>
                    </Row>

                </div>
                )
            });

            code = (<div>{code}<Button color="primary" onClick={this.confirmSendSurvey}>Invia</Button></div>);
        }

        return code;
    }

    /** */
    confirmSendSurvey = () => {

        if (this.state.answerAttempt) {
            ModalHandler.show(null, 'Invio Indagine', 'Confermi di inviare le risposte?', null, this.sendAnswer);
        } else {
            ModalHandler.show(null, 'Invio Indagine', 'Devi fornire una risposta', null, null, '', 'ok', false, true);
        }
    }//confirmSendQuiz   

    //--- ---

    render() {

        const { survey, surveyAttempt, isLoading, loadingError, rCode } = this.state;

        if (isLoading) return (<MnemoLoading />);

        if (loadingError) return (<BoxDanger className="m-5">Si è verificato un errore durante l'elaborazione della richiesta,
            se il problema persiste puoi  <SupportoTecnico /></BoxDanger>);

        return (
            <>
                <div>
                    <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                    <Title>{survey.title}</Title>
                </div >
                <div className="container mt-4 mb-4 noSelect">
                    <MSection>                       
                        <div>{(surveyAttempt) ? (<>
                            <h5>La tua risposta</h5><hr/>
                            <BoxInfo title='aaa'>
                            {ReactHtmlParser(this.renderAttempt(surveyAttempt))}
                            </BoxInfo>
                        </>) :
                            (<>
                             <div className="px-5">{ReactHtmlParser(survey.description)}</div>
                                {this.renderSurvey(survey)}
                                <div className="p-5">{ReactHtmlParser(survey.footer)}</div>
                            </>)}
                        </div>

                    </MSection>
                </div>
            </>
        );
    }
}

export default withRouter(SurveyMain);