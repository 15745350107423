import React from "react";

import {
  FormEnabler,
  ValidatedForm,
  MInput,
  MTextArea,
  MToggle,
  MCE
} from "components/forms";

import {
  payloadBuilder,
  invalidFieldsMapper,
  networkErrorHelper,
  notNullObjectBuilder,
  adminService,
  courseService,
} from "libs";

import { BoxSuccess, MnemoLoading, ModalHandler } from "components/misc";

import { Card, CardBody, Button } from "design-react-kit";

import "./AdminCourseFormSection.css";

const FIELDS_GROUP = [
  [
    {
      field: "inIndex",
      label: "Visibile sull'indice",
      component: MToggle,
      className: "col-md-7",
    },
    {
      field: "orderNumber",
      label: "Ordine",
      component: MInput,
      type: "number",
      className: "col-md-5",
    },
  ],
  [
    {
      field: "title",
      label: "titolo",
      component: MInput,
      type: "text",
      className: "col-md-12",
    },
  ],
  [
    {
      field: "summary",
      label: "descrizione",
      component: MInput,
      /*rows: 4,*/
      type: "text",
      className: "col-md-12",
    },
  ],
  [
    {
      field: "abstract",
      label: "sommario",
      component: MCE,
      rows: 3,
      infoText: "Specificare il testo",
      className: "col-md-12 mt-3",
    },
  ],
  [
    {
      field: "maintenance",
      label: "manutenzione",
      component: MTextArea,
      rows: 3,
      type: "textarea",
      className: "col-md-12 mt-3",
    },
  ],
];

export class AdminCourseFormSection extends ValidatedForm {
  ERROR_MESSAGES = {
    orderNumber: "Ordine non valido",
    title: "Titolo non valido",
    summary: "Minimo 5 caratteri",
  };

  emptyFields = {
    idParent: 0,
    title: "",
    summary: "",
    inIndex: false,
    orderNumber: 1,
    level: 1,
    maintenance: "",
    abstract: "",
  };

  validation = {
    title: (value) => value !== null && value.length > 2,
    summary: (value) => value !== null && value.length > 2,
    orderNumber: (value) => /^\d*$/.test(value),
  };

  //--- ---

  constructor(props) {
    super(props);

    let forceShow = false;
    if (this.props.forceShow) {
      forceShow = this.props.forceShow;
    }

    this.state = {
      loading: false,
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
      formActive: this.props.formActive,
      idSection: this.props.idSection,
      showForm: forceShow,
      forceShow: forceShow,
      onClosed: this.props.onClosed,
      onReload: this.props.onReload,
      forceReset: this.props.forceReset,
      formSectionData: this.props.formSectionData,
      cloneStatus: false,
      clonerData: {
        codeIdFrom: "",
        moduleFrom: "",
        codeTo: "",
        moduleTo: "",
      },
    };

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  //--- ---

  componentDidMount() {
    if (parseInt(this.state.idSection) > 0) {
      this.loadSection(this.state.idSection);
    }
  }

  loadSection = (idSection) => {
    //--- FIX manage/use one component many more on same page ---
    if (this.state.forceReset) {
      this.resetForm();
      return false;
    }
    //--- ---

    courseService
      .loadSection(idSection)
      .then(({ data }) => {
        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.section
        );

        const newState = {
          defaultValues,
        };

        this.setState(newState);
      })
      .catch((error) => {
        //networkErrorHelper.notify(error);
        console.error(error);
      });
  }; //loadSection

  onCancel = () => {
    this.setState({
      formActive: !this.state.formActive,
    });

    //--- condition to used in accordion mode form ---
    if (typeof this.props.accordionToggle === "function") {
      //this.props.accordionToggle(); //use if U want close accordion
      return false;
    }

    this.closeForm();
  };

  closeForm = () => {
    this.setState({
      showForm: this.state.forceShow ? !this.state.showForm : true,
      ...this.emptyFields,
      defaultValues: { ...this.emptyFields },
    });

    if (typeof this.state.onReload === "function") {
      this.state.onReload(this.props.formSectionData.idCourse);
      return false;
    }
  };
  //--- ---

  saveRemote = () => {
    const payload = payloadBuilder(this.state);

    if (parseInt(this.state.idSection) > 0 && payload.idParent > 0) {
      adminService
        .updateSection(payload)
        .then((result) => {
          this.state.formSectionData.reloadNested(payload.idParent);
          this.onCancel();
        })
        .catch((errors) => {
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    } else {
      //--- set before save idParent in emptyFileds as default value ---
      payload.idParent = this.props.formSectionData.idCourse;

      adminService
        .insertSection(payload)
        .then((result) => {
          this.closeForm();
        })
        .catch((errors) => {
          console.log(errors);
          if (networkErrorHelper.is422(errors)) {
            const newState = invalidFieldsMapper(
              errors,
              this.state,
              Object.keys(this.validation)
            );
            this.setState({ ...newState });
          } else {
            networkErrorHelper.notify(errors);
          }
        });
    }
  }; //saveRemote

  //--- start functions to clone procedure ---
  cloneCourseModule = (e, idModule) => {
    const form2Clone = [
      {
        name: "code",
        field: "code",
        label: "Codice Corso:",
        type: "text",
        className: "mt-3 mb-1",
      },
      {
        name: "module",
        field: "module",
        label: "Numero Modulo:",
        type: "number",
        className: "mt-3 mb-1",
      },
    ];

    const code = (
      <>
        <p>
          Questa procedura clona tutti i dati di questo Modulo sul Modulo di
          destinazione. Questa operazione elimina tutti i dati presenti nel
          Modulo di destinazione. Vuoi davvero procedere?
        </p>
        <div className="row mt-3">
          <div className="col-md-6">
            <MInput {...form2Clone[0]} onChange={this.clonerData} />
            <div className="small text-info">Codice corso di destinazione.</div>
          </div>
          <div className="col-md-6">
            <MInput {...form2Clone[1]} onChange={this.clonerData} />
            <div className="small text-info">Numero Modulo di destinazione</div>
          </div>
        </div>
      </>
    );
    ModalHandler.show(
      { idModule: idModule },
      "ATTENZIONE. Procedura irreversibile",
      code,
      this.modalAbort,
      this.modalConfirmed
    );
  }; //cloneCourseModule

  clonerData = (_, value) => {
    this.setState({
      clonerData: {
        ...this.state.clonerData,
        codeIdFrom: this.state.formSectionData.idCourse,
        moduleFrom: this.state.defaultValues.orderNumber,
        codeTo: _ === "code" ? value : this.state.clonerData.codeTo,
        moduleTo: _ === "module" ? value : this.state.clonerData.moduleTo,
      },
    });
  }; //cloneForm

  modalConfirmed = () => {
    //TODO - FIX verificare come evitare la chiusura della modale nei casi in cui non si debba chiudere
    // come da suo comportamento nativo
    const validation = {
      codeIdFrom: (value) => /^\d*$/.test(value) && value > 0,
      moduleFrom: (value) => /^\d*$/.test(value) && value > 0,
      codeTo: (value) => value !== null && value.length > 2,
      moduleTo: (value) => /^\d*$/.test(value) && value > 0,
    };

    for (let [key, value] of Object.entries(this.state.clonerData)) {
      if (!validation[key](value)) {
        //alert(`Attenzione il campo ${key} deve essere compilato correttamente.`)
        return false;
      }
    }

    this.setState({
      loading: true,
      cloneStatus: true,
    });

    setTimeout(() => {
      this.setState({ cloneStatus: false });
    }, 7000);

    adminService
      .cloneFullModule(this.state.clonerData)
      .then((result) => {
        this.setState({ loading: false });
      })
      .catch((errors) => {
        networkErrorHelper.notify(errors);
        this.setState({ loading: false });
      });
  };

  modalAbort = () => {
    this.setState({
      clonerData: {
        codeIdFrom: "",
        moduleFrom: "",
        codeTo: "",
        moduleTo: "",
      },
    });
  };
  //--- end functions to clone procedure ---

  //--- ---

  render() {
    const { loading, showForm, cloneStatus, formActive, defaultValues } =
      this.state;
    if (loading) {
      return (
        <>
          <MnemoLoading></MnemoLoading>
        </>
      );
    }

    const { formValid, code } = this.renderFields(
      FIELDS_GROUP,
      defaultValues,
      formActive
    );

    return (
      <>
        {(() => {
          if (showForm) {
            return (
              <Card tag="div" className="card-big">
                <CardBody tag="div" className="card-body-inAccordion">
                  <form className="user">{code}</form>

                  <div className="mt-4">
                    <FormEnabler
                      onSubmit={this.onSubmit}
                      onCancel={this.onCancel}
                      onToggle={this.toggleForm}
                      isFormActive={formActive}
                      disableSave={!formValid}
                    />

                    {defaultValues.idParent > 0 && (
                      <Button
                        color="primary bg-dark"
                        className="mx-2 mt-6"
                        onClick={(e) =>
                          this.cloneCourseModule(e, defaultValues.id)
                        }>
                        Clona Modulo
                      </Button>
                    )}

                    {defaultValues.idParent > 0 && (
                      <Button
                        color="primary bg-dark"
                        className="mx-2 mt-6"
                        onClick={this.props.deleteRecord}>
                        Elimina
                      </Button>
                    )}
                  </div>

                  {cloneStatus && (
                    <BoxSuccess className="mt-3">
                      Clonazione Dati terminata.
                    </BoxSuccess>
                  )}
                </CardBody>
              </Card>
            );
          }
        })()}
      </>
    );
  }
}
