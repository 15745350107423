import React, { Component } from 'react';

import { Title } from 'components/misc';


export class NewsCancel extends Component {

    render() {
        const title = 'CANCELLAZIONE NEWSLETTER';
        const info = this.renderIFrame();
        return (
            <>

                <Title>{title}</Title>
                <hr />
                {info}
            </>);
    }

    renderIFrame() {

        let src = 'https://mne16.mnemosine-elearning.it/segreteria/frontEndNewsletter/newscancel/';

        return (
            <>
                <div>
                    <embed  src={src} width="99%" height="300px" />
                </div>
            </>
        );
    }

}