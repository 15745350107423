import React, { Component } from 'react';
import Slider from "react-slick";

// Import css files
import "./MnemoSlider.css";

export class MnemoSlider extends Component {

    settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    render() {

        return (
            <>
                <Slider {...this.settings}>
                {this.props.children}
                </Slider>
            </>
        );
    }
}