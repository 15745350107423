import React, { Component } from "react";
import { ROUTES } from "const";

export class CardNewsCategory extends Component {

  state = {
    categories: [],
    loading: true,
    loadingError: false
  }

  componentDidMount() {
    this.loadCategories();
  }

  loadCategories() {

    this.setState({
      loading: false,
      categories: [
        {
          title: 'Docenti',
          slug: 'docenti'
        },
        {
          title: 'Personale Ata',
          slug: 'ata'
        },
        {
          title: 'Decreti e Note Ministeriali',
          slug: 'decreti-e-note-ministeriali'
        },
        {
          title: 'Concorsi',
          slug: 'concorsi'
        },
        {
          title: 'Attualità',
          slug: 'attualita'
        },
        {
          title: 'Scuole',
          slug: 'scuole'
        },

      ]
    });

  }

  render() {

    const { loading, loadingError, categories } = this.state;

    if (loading || loadingError || categories.length === 0) return (<></>);

    return (
      <div >
        <div className="cardNews__content">
         
              <ul className="list-left">
                {categories.map((category, i) => {
                  return (
                    <div key={`category-${i}`}>
                      <li key={`category-${i}`} >
                        <a className="link-light cardNews__link cardNews__listItem" title={category.slug} href={`${ROUTES.BLOG_HOME}/${category.slug}`} >{category.title}</a>
                      </li>
                    </div>
                  )
                })}
              </ul>
            
        </div>
      </div >
    );
  }
}
