import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Title,
  MnemoLoading,
  BoxSuccess,
  BoxDanger,
  ModalHandler,
} from "components/misc";
import { connect } from "react-redux";

import { Row, Col, Container, Button } from "design-react-kit";
import { getCurrentUser, loginByUserId } from "store/actions";

import { networkErrorHelper, adminService, dateHelper } from "libs";

import {
  UserDetailAccount,
  UserDetailTarget,
  UserDetailProfile,
  UserDetailAddress,
  UserDetailQualification,
  UserDetailEnrollment,
  UserDetailEnrollmentAbilitation,
  UserDetailCourses,
  UserDetailSMSList,
  UserDetailDocument,
} from "./";

import { withRouter } from "react-router-dom";
import { MInput } from "components/forms";
import { FiscalCodeHelper } from "libs/helpers/fiscalCodeHelper";
import { ROUTES } from "const";

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      whereObj: {
        id: this.props.match.params.id,
        role: this.props.match.params.role,
      },
      fiscalCodeError: "",
      detail: {
        user: false,
        profile: false,
        target: false,
        address: false,
        qualification: false,
        enrollment: false,
        enrollmentAbilitation: false,
        courses: false,
        documents: false,
        sms: false,
      },
      loading: false,
      collectAndSend: {
        state: null,
        message: "",
      },
      abilitationCourseCode: "",
    };
  } //constructor

  componentDidMount() {
    this.loadRemote(this.state.whereObj);
  } //componentDidMount

  loadRemote(whereObj) {
    adminService
      .userDetail(whereObj)
      .then(async ({ data }) => {
        const {
          user,
          profile,
          target,
          address,
          qualification,
          enrollment,
          enrollmentAbilitation,
          documents,
          course,
          sms,
        } = data.payload.resObj;

        this.setState({
          detail: {
            user,
            profile,
            target,
            address,
            qualification,
            enrollment,
            enrollmentAbilitation,
            documents,
            courses: course,
            sms,
          },
        });

        this.verifyFC(user, profile);
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  } //loadRemote

  verifyFC = (user, profile) => {
    if (profile.canverifycf === false) {
      return true;
    }

    let fiscalCodeError = "";
    profile.dob = dateHelper.toUSDate(profile.dob);

    const promiseFC = FiscalCodeHelper.calculateFiscalCode(user, profile);
    promiseFC.then((calculatedFC) => {
      if (calculatedFC !== profile.fiscalCode) {
        fiscalCodeError = `Codice fiscale <b>${profile.fiscalCode}</b> non valido. Il codice fiscale corretto è <b>${calculatedFC}</b> .`;
      }

      this.setState({ fiscalCodeError: fiscalCodeError });
    });
  };

  collectAndSend = (uid) => {
    this.setState({ loading: true });

    adminService
      .collectAndSendTo(uid)
      .then((result) => {
        const { reportUserData } = result.data.payload;

        adminService
          .syncUserDataTo(reportUserData)
          .then((res) => {
            this.setState({
              loading: false,
              collectAndSend: {
                state: res.data.payload.state,
                message: res.data.payload.message,
              },
            });

            //--- remove returned message ---
            setTimeout(() => {
              this.setState({
                collectAndSend: {
                  state: null,
                  message: "",
                },
              });
            }, 6000);
          })
          .catch((error) => {
            if (!networkErrorHelper.is404(error)) {
              console.log(error);
              networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
          });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }

        this.setState({ loading: false });
      });
  }; //sendUserData

  collectEnrollmentAndSend = (uid) => {
    adminService
      .collectUserEnrollment(uid)
      .then((res) => {
        const result = res.data.payload.result;

        adminService
          .syncEnrollmentDataTo(result)
          .then((res) => {
            //console.log(res)
          })
          .catch((error) => {
            if (!networkErrorHelper.is404(error)) {
              console.log(error);
              networkErrorHelper.notify(error);
            }
          });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          console.log(error);
          networkErrorHelper.notify(error);
        }
      });
  }; //collectEnrollmentAndSend

  /** Personification */
  personification = (userId) => {
    const code = (
      <>
        <p>Vuoi sloggarti e riloggarti con l'Account selezionato?</p>
      </>
    );
    ModalHandler.show(
      userId,
      "Attenzione",
      code,
      null,
      this.runPersonification
    );
  }; //personification

  runPersonification = (userId) => {
    this.props.loginByUserId(userId, "/dashboard");
  };

  /** Enable User to edit profile data */
  enableUserEdit = (e, userId) => {
    const code = (
      <>
        <p>
          Questa procedura abilita il corsista a poter modificare il proprio
          profilo. Vuoi davvero procedere?
        </p>
      </>
    );
    ModalHandler.show(
      parseInt(userId),
      "Attenzione",
      code,
      null,
      this.runEnableUserEdit
    );
  }; //changeUserReadOnly

  runEnableUserEdit = (userId) => {
    this.setState({ loading: true });
    adminService
      .changeUserReadOnly({ userId, readOnly: false })
      .then(({ data }) => {
        this.setState({ loading: false });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
        this.setState({ loading: false });
      });
  };

  /** Enable User to edit profile data */
  disableUserEdit = (e, userId) => {
    const code = (
      <>
        <p>
          Questa procedura disabilita il corsista a poter modificare il proprio
          profilo. Vuoi davvero procedere?
        </p>
      </>
    );
    ModalHandler.show(
      parseInt(userId),
      "Attenzione",
      code,
      null,
      this.runDisableUserEdit
    );
  }; //changeUserReadOnly

  runDisableUserEdit = (userId) => {
    this.setState({ loading: true });
    adminService
      .changeUserReadOnly({ userId, readOnly: true })
      .then(({ data }) => {
        this.setState({ loading: false });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
        this.setState({ loading: false });
      });
  };

  /** allow multiple enrollments */
  allowMultipleEnrollment = (e, userId) => {
    const formInput = [
      {
        name: "courseCode",
        field: "courseCode",
        label: "Codice Corso:",
        type: "text",
        className: "mt-3 mb-1",
      },
    ];

    const code = (
      <>
        <p>
          Questa procedura abilita il corsista all'iscrizione ad un ulteriore
          corso. Vuoi davvero procedere?
        </p>
        <div className="row mt-3">
          <div className="col-md-6">
            <MInput {...formInput[0]} onChange={this.getCourseCode} />
            <div className="small text-info">Codice corso d'abilitare.</div>
          </div>
        </div>
      </>
    );
    ModalHandler.show(
      parseInt(userId),
      "Attenzione",
      code,
      null,
      this.runallowMultipleEnrollment
    );
  }; //allowMultipleEnrollment

  getCourseCode = (_, value) => {
    this.setState({
      abilitationCourseCode: value,
    });
  }; //getCourseCode

  runallowMultipleEnrollment = (userId) => {
    this.setState({ loading: true });
    adminService
      .insertUserEnrollmentAbilitation({
        userId,
        courseCode: this.state.abilitationCourseCode,
      })
      .then(({ data }) => {
        console.log(data);
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);

        this.setState({ loading: false });
      });
  };

  manageStatus = (e, value) => {
    ModalHandler.show(
      value,
      "Gestione Account Abilita/Disabilita",
      "Vuoi procedere?",
      null,
      this.changeStatusConfirmed
    );
  }; //manageStatus

  changeStatusConfirmed = (value) => {
    adminService
      .changeUserStatus(value)
      .then(({ data }) => {
        this.loadRemote(this.state.whereObj);
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
      });
  }; //changeStatusConfirmed

  deleteRecord = (e, value) => {
    ModalHandler.show(
      value,
      "Gestione Account",
      "Vuoi cancellare definitivamente l'account ?",
      null,
      this.deleteConfirmed
    );
  }; //deleteRecord

  deleteConfirmed = (value) => {
    adminService
      .deleteUser(value)
      .then(({ data }) => {
        this.loadRemote(this.state.whereObj);
        window.location.href = ROUTES.USER_LIST + "/" + this.state.whereObj.role;
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
      });
  };

  deleteRequestGDPR = (e, value) => {
    ModalHandler.show(
      value,
      "Gestione Account",
      "Vuoi cancellare (esercizio GDPR) l'account ?",
      null,
      this.deleteRequestGDPRConfirmed
    );
  }; //deleteRecord

  deleteRequestGDPRConfirmed = (value) => {
    adminService
      .deleteRequestGDPRConfirmed(value)
      .then(({ data }) => {
        window.location.href = ROUTES.USER_LIST + "/" + this.state.whereObj.role;
      })
      .catch((error) => {
        console.log(error);
        networkErrorHelper.notify(error);
      });
  }; //changeStatusConfirmed

  //--- ---

  render() {
    const {
      user,
      profile,
      target,
      address,
      qualification,
      enrollmentAbilitation,
      enrollment,
      courses,
      sms,
      documents,
    } = this.state.detail;

    const { whereObj, loading, collectAndSend, fiscalCodeError } = this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;

    const userLoggedInfo = getCurrentUser();

    return (
      <>
        <section className="my-4">
          <Title>
            <Row>
              <Col lg={{ size: 6 }}>Dettaglio Account</Col>
              {
                <Col lg={{ size: 6 }} className="text-right">
                  {/** */}
                  {(userLoggedInfo.roles === "admin" ||
                    userLoggedInfo.roles === "segreteria" ||
                    userLoggedInfo.roles === "editor") &&
                    user.roles === "student" && (
                      <>
                        {/*<Button onClick={(e) => this.collectAndSend(whereObj.id)}>Invia Report alla Segreteria</Button>*/}
                        {/*<Button onClick={(e) => this.collectEnrollmentAndSend(whereObj.id)}>TEST Send Enrollments</Button>*/}
                        <Button
                          color="btn btn-primary bg-dark m-2 p-3"
                          onClick={(e) =>
                            this.allowMultipleEnrollment(e, whereObj.id)
                          }>
                          Abilita Iscrizioni multiple
                        </Button>
                        <Button
                          color="btn btn-primary bg-danger m-2 p-3"
                          onClick={(e) => this.personification(whereObj.id)}>
                          Login come {user.name + " " + user.surname}
                        </Button>
                        {user.readOnly ? (
                          <Button
                            color="btn btn-primary bg-dark m-2 p-3"
                            onClick={(e) =>
                              this.enableUserEdit(e, whereObj.id)
                            }>
                            Abilita modifiche profilo
                          </Button>
                        ) : (
                          <Button
                            color="btn btn-primary bg-dark m-2 p-3"
                            onClick={(e) =>
                              this.disableUserEdit(e, whereObj.id)
                            }>
                            Disabilita modifiche profilo
                          </Button>
                        )}
                      </>
                    )}

                  {/** */}
                  {userLoggedInfo.roles === "admin" && (
                    <>
                      <Button
                        color="btn btn-primary bg-dark m-2 p-3"
                        onClick={(e) =>
                          this.manageStatus(e, whereObj.id)
                        }>
                        {(user.enabled === true) ? 'Disabilita' : 'Abilita'}
                      </Button>
                      <Button
                        color="btn btn-primary bg-danger m-2 p-3"
                        onClick={(e) => this.deleteRecord(e, whereObj.id)}>
                        Elimina
                      </Button>
                      <Button
                        color="btn btn-primary bg-danger m-2 p-3"
                        onClick={(e) => this.deleteRequestGDPR(e, whereObj.id)}>
                        Elimina (esercizio GDPR)
                      </Button>
                    </>
                  )}
                </Col>
              }
            </Row>
          </Title>

          {collectAndSend.message !== "" && (
            <Container className="mt-2">
              <Row>
                <Col>
                  {!collectAndSend.state && (
                    <BoxDanger>{collectAndSend.message}</BoxDanger>
                  )}
                  {collectAndSend.state && (
                    <BoxSuccess>{collectAndSend.message}</BoxSuccess>
                  )}
                </Col>
              </Row>
            </Container>
          )}
          {fiscalCodeError && (
            <BoxDanger>{ReactHtmlParser(fiscalCodeError)} </BoxDanger>
          )}
          <UserDetailAccount data={user} />
          <UserDetailProfile data={profile} user={user} />
          <UserDetailTarget data={target} />
          <UserDetailAddress data={address} />
          <UserDetailQualification data={qualification} />
          <UserDetailDocument data={documents} />
          <UserDetailEnrollment data={enrollment} account={whereObj} />
          <UserDetailEnrollmentAbilitation
            data={enrollmentAbilitation}
            account={whereObj}
          />
          <UserDetailCourses data={courses} account={whereObj} />
          <UserDetailSMSList data={sms} account={whereObj} />
        </section>
      </>
    );
  }
}

const stateToProps = ({ app }) => {
  return {
    msgLogin: app.error,
  };
};

const dispatchToProps = (dispatch) => {
  return {
    loginByUserId(userId, redirectUrl) {
      dispatch(loginByUserId(userId, redirectUrl));
    },
  };
};

export default connect(stateToProps, dispatchToProps)(withRouter(UserDetail));
