import React, { Component } from 'react';
import { Button } from 'design-react-kit';
import { BoxDanger } from 'components/misc';

export class MButton extends Component {

    /**
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of button)    
     * value    : the value
     * infoText : text to show
     * disabled : insert for readonly 
     * readOnly : is readOnly
     * classElement : css class to apply on the button
     * onClick : trigger a function
     */
    render() {

  
        // get properties
        const { id, disabled = false, readOnly = false, 
            label, value, classElement, infoText = '' } = this.props;

        // map properites
        const myProps = { disabled, readOnly, value };
        let className = (classElement) ? classElement : '';        
        return (
            <>
                <span id={id} className="d-none">
                    <Button className={className} {...myProps} onClick={event => this.onClick(event)}>{label}</Button>
                   <BoxDanger className="mb-5 mt-2"><small>{infoText}</small></BoxDanger>
                </span>
            </>
        );
    }

    onClick = (event) => {
        let value = event.target.value;
        if (this.props.onClick) {
            this.props.onClick(this.props.name, value);
        }
    }
}