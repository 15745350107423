import React, { Component } from "react";

import { MnemoLoading, MSection } from "components/misc";
import { Button, Container, Row, Col } from "design-react-kit";
import { MTextArea } from "components/forms";

import {
    learningService
} from "libs";

export class MakeTestFromRepo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      result: '',
      result2: '',
      MakeTestFromRepoParams: '9628,1102,AD123,AD123A,76565'
    };
  }

  //--- ---

  updateMakeTestFromRepoParams = (evt) =>{
    this.setState({
      MakeTestFromRepoParams: evt.target.value
    });
  }

  makeItBulk = async (iterations) =>{
    /*for(let i=0; i<iterations; i++){      
    }*/
    let cnt = 1;
    const timer = setInterval(() => {
      if(cnt >= iterations){
        clearInterval(timer);
        return false;
      }

      this.makeIt(true);
      cnt += 1;
    }, 350);
  }//makeItBulk

  makeIt = async (stopOnErr = false) => {
    let params = this.state.MakeTestFromRepoParams;
    learningService.makeTestfromRepo(params).then(({ data }) => {      
      this.setState({
        results: JSON.stringify(data.payload.quizFromRepo[0], undefined, 4),
        results2: JSON.stringify(data.payload.quizFromRepo[1], undefined, 4)
      });

      //--- provo a fare un parser di verifica della consistenza delle informazioni ---
      this.parserReport(data.payload.quizFromRepo[1], stopOnErr);
    })
    .catch(error => {
        console.log(`si è verificato un errore serverSide`)
    });
  };//makeIt

  parserReport = (payload, stopOnErr) =>{
    (!stopOnErr) && console.clear();
    //console.log(payload)

    payload.forEach(item =>{    
      let logColor = 'color: #bada55';
      let message  = 'Passed';

      if(item.maxChoose !== item.answers.filter(function (e) { return e.grade === 10  }).length){
        logColor = 'color: #ff0000';
        message  = 'Not Passed';
      }

      if(stopOnErr){
        console.log('%c'+message, logColor)
        if(message === 'Not Passed'){
          console.log('%c'+item.type+' - maxChoose:'+item.maxChoose, logColor)
          console.log(item.label)
          console.log('--------------------------')
        }
      }//!stopOnErr

      if(!stopOnErr){  
        console.log('%c'+item.type+' - maxChoose:'+item.maxChoose, logColor)
        console.log(item.label)
        /*if(item.type !== 'text'){
          console.log(item.answers.filter(function (e) { return e.grade === 10  }).length)        
        }else{
          console.log('risposta domand aperta')
        }*/
        console.log('--------------------------')
      }//!stopOnErr
    })
  }

  render() {

    const { loading, MakeTestFromRepoParams } = this.state;

    return (
      <>
        {loading && <MnemoLoading></MnemoLoading>}

        <MSection>
          <Container>
            <Row>
                <Col md={12} ><MTextArea value={this.state.results} rows={9}/></Col>
            </Row>
            <Row>
                <Col md={12} className="mt-3"><MTextArea value={this.state.results2} rows={9}/></Col>
            </Row>
            <Row>
                <Col md={12} className="mt-3"><input id="MakeTestFromRepoParams" type="text" value={MakeTestFromRepoParams} onChange={evt => this.updateMakeTestFromRepoParams(evt)}></input></Col>
                <p>note: idQuiz,idUser,Nome-Repo,Nome-Repo-Domande-Aperte,idResource</p>
            </Row>
          </Container>

          <Button className="btn btn-default btn-lg m-2" onClick={() => { this.makeIt(false); }}>AVVIA SIMULAZIONE</Button>
          <Button className="btn btn-default btn-lg m-2" onClick={() => { this.makeItBulk(500); }}>AVVIA SIMULAZIONE BULK</Button>

          <p className="mt-2">NOTA: aprila la console del browser. i risulati dell'analisi saranno visibili solo sulla console.</p>
        </MSection>
      </>
    );
  }
}
