import React, { Component } from "react";

import {
    Card,
    CardBody,
    Icon
} from 'design-react-kit';
import { DOC_TYPES_MAP } from "const";
import { dateHelper } from "libs";


export class UserDetailDocument extends Component {


    render() {

        if (!this.props.data) return false;

        const { type, number, source, expiryDate, releaseDate } = this.props.data;

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded border-bottom-card mb-4">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-user" padding={false} />
                        <span>Documento di Riconoscimento</span>
                    </div>
                    <CardBody>
                        <div><b>Tipo Documento:</b> {DOC_TYPES_MAP[type]}</div>
                        <div><b>Numero:</b> {number}</div>
                        <div><b>Emesso da:</b> {source}</div>
                        <div><b>Data Rilascio:</b> {dateHelper.toITDate(releaseDate)}</div>
                        <div><b>Data Scadenza:</b> {dateHelper.toITDate(expiryDate)}</div>
                    </CardBody>
                </Card>
            </>
        );
    }

}
