import React, { Component } from 'react';
import { stringHelper } from 'libs';
import { BoxSuccess } from 'components/misc';

export class FormUCSUCombo extends Component {

    importToPayTotal = 0;
    importBollo = 16;
    importSegreteria = 50;
    importForCFU = 35;
    importForCFUPromo = 10;

    componentDidMount() {

        this.updateImportToPay();
    }

    getValue() {

        const coursesSubcribe = this.props.pluginConfig;
        const pluginValue = {
            // il valore dei corsi selezionati è una stringa separata da virgole con gli id della tabella mbo_module.id mysql
            courses: coursesSubcribe.map(course => course.id).join(','),
        };

        return pluginValue;
    }

    checkValidation() {

        return true;
    }

    getCFUSubscribed() {

        const coursesSubcribe = this.props.pluginConfig;

        // check max 60 CFU
        let cfu = 0;
        coursesSubcribe.forEach(course => {
            cfu += parseInt(course.cfu);
        });

        return cfu;
    }

    updateImportToPay() {

        const coursesSubcribe = this.props.pluginConfig;
        const hasMasterPromo = this.props.hasMasterPromo;

        // update import and change it
        const payload = [];
        const promoAmount = this.props.promoAmount ? this.props.promoAmount : 0;

        const cfu = this.getCFUSubscribed(coursesSubcribe);
        this.importToPayTotal = 0;
        if (cfu > 0) {
            this.importToPayTotal  = (this.importForCFU * cfu) - promoAmount + this.importBollo + this.importSegreteria;
            if (hasMasterPromo) {
                this.importToPayTotal  = (this.importForCFUPromo * cfu) + this.importBollo;
            }
            payload.push({ value: this.importToPayTotal , label: 'Soluzione unica ' + stringHelper.formatEuro(this.importToPayTotal ) })

            const countSubscribed = coursesSubcribe.length;
            if (countSubscribed > 1 && countSubscribed <= 3) {
                const firstRate = Math.trunc(this.importToPayTotal  / 2)                
                payload.push({ value: firstRate, label: 'Prima rata ' + stringHelper.formatEuro(firstRate) })
            } else if (countSubscribed > 3) {
                const firstRate =  Math.trunc(this.importToPayTotal  / 3);
                payload.push({ value: firstRate, label: 'Prima rata ' + stringHelper.formatEuro(firstRate) })
            }
        }

        const { onChangeImport } = this.props;
        setTimeout(function () {
            onChangeImport(payload)
        }, 1000);
    }

    render() {

        return (
            <>
                <p>Certificato rilasciato dall'Università Italiana "UniCamillus" (Saint Camillus International University of Health Sciences)</p>
                <p>vedi <a href="/files/corsi/UCSU/Bando_UCSU.pdf" target="_blank"><b>Bando di Partecipazione</b></a></p>
                <p><a href="/files/corsi/UCSU/ISTRUZIONI_CARTA_DOCENTE_UNICAMILLUS_CORSI_SINGOLI.pdf" target="_blank"><b>
                    Istruzioni su come generare la Carta Docenti per i Corsi singoli universitari Unicamillus</b></a></p>
                {this.props.hasMasterPromo && (<BoxSuccess>Agevolazione a te riservata</BoxSuccess>)}
                <div className="alert alert-success fade show" role="alert"><p>Totale da pagare {stringHelper.formatEuro(this.importToPayTotal)}</p></div>
                <hr />

            </>
        );
    }
}