import React, { Component } from "react";
import { networkErrorHelper, learningService, paymentService, dateHelper } from "libs";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { PAYMENT_TYPES, ROUTES } from "const";

import {
    Icon, Row, Col,
    Callout, CalloutTitle, CalloutText, Button
} from "design-react-kit";

class MUCoursePayments extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        rCode: 0
    };

    componentDidMount() {
        const { rCode } = this.props.match.params;
        if (rCode) {
            this.loadPayment(rCode);
            this.loadRemote(rCode);
        } else {
            this.setState({
                loading: false
            });
        }
    }

    goLink = (link) => {

        window.location = link;
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse, rCode });

            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loadingError: true });
            });

    }//loadRemote

    loadPayment(rCode) {

        paymentService.myPayments(rCode).then(({ data }) => {

            let paymentItems = [];
            const isPaymentReferred = data.payload.isPaymentReferred > 0 ? true : false;

            //--- payOneSolution ---
            if (data.payload.payOneSolution !== null) {
                paymentItems.push(
                    {
                        ...data.payload.payOneSolution,
                        numRate: 'Soluzione unica',
                        importPaied: data.payload.payOneSolution.paymentOff,
                        datePaied: data.payload.payOneSolution.datePaymentOff,
                        status: (data.payload.payOneSolution.ispaymentOK ? 'Approvato' : 'InApprovazione')
                    });
            }

            //--- paymentItems ---
            if (data.payload.paymentItems !== null && data.payload.paymentItems.length > 0) {
                paymentItems.push(...data.payload.paymentItems);
            }

            //--- TODO: paymentOtherItems ---            

            this.setState({ loading: false, isPaymentReferred, paymentItems });

        })
            .catch(error => {
                if (!networkErrorHelper.is404(error)) {
                    console.log(error);
                    networkErrorHelper.notify(error);
                    this.setState({ loading: false, loadingError: true });
                }
            });
    }

    renderIFrame = () => {

        const { course, rCode } = this.state;
        if (course) {
            const aaURL = course.aa.toString().slice(-2);
            let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + 'moodleElearningPayment/renderPaymentList/rCode/' + rCode + '/isOnlyOther/1';
            let height = '1000px';

            return (
                <>
                    <div>
                        <embed src={src} width="99%" height={height} />
                    </div>
                </>
            );
        }
    }

    render() {

        const { loading, loadingError, rCode, paymentItems } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        return (
            <>
                {(rCode) ? (
                    <>
                        <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                        <Title>Situazione Pagamenti</Title>
                        {(paymentItems && paymentItems.length > 0) ? (
                            paymentItems.map((item, i) => (
                                <Row key={`paymentItems-${i}`}>
                                    <Col size="12">
                                        <Callout className="border border-light rounded my-2 p-3" tag="div">
                                            <CalloutTitle tag="h6">
                                                <span className={`badge m-0 p-2 ${(item.status === 'Approvato') ? 'badge-success' : 'badge-warning'}`}>
                                                    {item.status}</span> Rata numero: {item.numRate}
                                            </CalloutTitle>

                                            <CalloutText bigText={false} tag="div">
                                                <p className="m-0 p-0"><b>modalità di pagamento</b>: {item.paymentType}</p>
                                                {(item.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE) ? (<>
                                                    <p className="m-0 p-0"><b>importo del voucher</b>: {item.importPaied} euro</p>
                                                </>)
                                                    : (<>

                                                        {(item.status !== 'Approvato' && item.status !== 'InApprovazione') &&
                                                            (<>
                                                                <p className="m-0 p-0"><b>importo da pagare</b>: {item.importToPay} euro</p>
                                                                <p className="m-0 p-0"><b>scadenza</b>: {dateHelper.toITDate(item.dateToPay)}</p>
                                                                {(item.paymentGOMP && item.paymentGOMP.paymentLink) && (<>
                                                                    <Button color="primary bg-dark" className="d-block"
                                                                        onClick={() => this.goLink(item.paymentGOMP.paymentLink)} >
                                                                        Avvia il pagamento
                                                                    </Button>
                                                                </>)}
                                                                {(item.paymentGOMP && item.paymentGOMP.invoice64) && (
                                                                    <Button color="primary bg-dark" className="mt-4 d-block" >
                                                                        <a download={`avviso_pagamento-${item.numRate}.pdf`}
                                                                            href={`data:application/pdf;base64,${item.paymentGOMP.invoice64}`}
                                                                            className="link-white" title='Scarica avviso di pagamento' >Scarica avviso di pagamento</a>
                                                                    </Button>
                                                                )}
                                                                <Button color="primary bg-dark" className="mt-4 d-block"
                                                                    onClick={() => this.goLink(`${ROUTES.COURSE_PAYMENT_CD}/${rCode}/${item.numRate}`)} >
                                                                    Paga con carta del docente
                                                                </Button>
                                                            </>)}

                                                        {(item.status === 'Approvato') && (
                                                            <>
                                                                <p className="m-0 p-0"><b>importo pagato</b>: {item.importPaied} euro</p>
                                                                <p className="m-0 p-0"><b>data pagamento</b>: {dateHelper.toITDate(item.datePaied)}</p>
                                                                {(item.paymentGOMP && item.paymentGOMP.paymentReceipt) && (<>
                                                                    <Button color="primary bg-dark" className="mt-4 d-block" >
                                                                        <a download={`ricevuta_pagamento-${item.numRate}.pdf`}
                                                                            href={`data:application/pdf;base64,${item.paymentGOMP.paymentReceipt}`}
                                                                            className="link-white" title='Scarica ricevuta di pagamento' >Scarica la ricevuta</a>
                                                                    </Button>
                                                                </>)}
                                                            </>)}

                                                        {(item.status === 'InApprovazione') && (<>
                                                            <p className="m-0 p-0"><b>importo pagato</b>: {item.importPaied} euro</p>
                                                            <p className="m-0 p-0"><b>data pagamento</b>: {dateHelper.toITDate(item.datePaied)}</p>
                                                            {(item.paymentGOMP && item.paymentGOMP.paymentReceipt) && (<>
                                                                <Button color="primary bg-dark" className="mt-4 d-block" >
                                                                    <a download={`ricevuta_pagamento-${item.numRate}.pdf`}
                                                                        href={`data:application/pdf;base64,${item.paymentGOMP.paymentReceipt}`}
                                                                        className="link-white" title='Scarica ricevuta di pagamento' >Scarica la ricevuta</a>
                                                                </Button>
                                                            </>)}
                                                        </>)}
                                                    </>)
                                                }
                                            </CalloutText>
                                        </Callout>
                                    </Col>
                                </Row>
                            ))
                        ) : <BoxDanger key="nrp-1">Non risultano rate</BoxDanger>}
                        {this.renderIFrame()}
                        <hr />
                    </>
                ) : (<>
                    <BoxDanger className="mt-4">
                        Si è verificato un errore durante il caricamento della pagina, se il problema persiste puoi
                        <br /><SupportoTecnico /></BoxDanger>
                </>)}
            </>)
    }
}

export default withRouter(MUCoursePayments);