import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { revisionService } from "libs/api/revisionService";
import { dateHelper, networkErrorHelper } from "libs";
import {
  Card,
  CardBody,
} from 'design-react-kit';
import ReactHtmlParser from 'react-html-parser';
import { BoxDanger, MnemoLoading } from "components/misc";

class MyQuizReview extends Component {

  state = {
    review: {},
    loadingError: '',
    isLoading: true,
  }

  componentDidMount() {   
    const { idQuizReview } = this.props.match.params;

    //--- prevent loadRemote when not required ---
    if (parseInt(idQuizReview) <= 0) {
      this.setState({ isLoading: false, loadingError: 'Parametro del Quiz non valido' });
    } else {
      this.loadRemote(idQuizReview);

    }
  }

  loadRemote(idQuizReview) {

    revisionService.getQuizReview(parseInt(idQuizReview))
      .then(({ data }) => {

        const review = data.payload.quizReview;
        this.setState({
          review,
          isLoading: false
        });

        if (review.toFeedback) {
          this.updateToFeedback(review);
        }
      })
      .catch(error => {
        networkErrorHelper.notify(error);
        const errorMessage = networkErrorHelper.getErrorMessage(error);
        this.setState({ isLoading: false, loadingError: errorMessage })
        console.log(error);
      });

  }//loadremote

  updateToFeedback = (review) => {

    const payload = {
      id: review.id,
      toFeedback : false,
    }

    revisionService.updateQuizReviewFeedback(payload).then(
      (data) => {
        
      }
    ).catch(errors => {
      console.log(errors);
    });
  }

  render() {

    const { review, loadingError, isLoading } = this.state;

    if (isLoading) return <MnemoLoading />;

    return (
      <>
        {loadingError ? (
          <BoxDanger>Si è verificato il seguente errore: {loadingError}</BoxDanger>
        ) : (
          <>
            <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
              <div className="flag-icon" />
              <CardBody tag="div">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="courseCode" class="active">Codice Corso</label>
                      {review.courseCode}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="question" class="active">Domanda</label>
                      {ReactHtmlParser(review.question)}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="answer" class="active">Risposta</label>
                      {ReactHtmlParser(review.answer)}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="revisionResult" class="active">Risultato revisione</label>
                      {review.revisionResult}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="reviionDate" class="active">Data revisione</label>
                      {dateHelper.toITDate(review.revisionDate)}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="revisionNote" class="active">Note revisione</label>
                      {review.revisionNote}
                    </div>
                  </div>
                </div>
              </CardBody >
            </Card >
          </>)}
      </>
    );

  }
}

export default withRouter(MyQuizReview);
