import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, BoxSuccess, MnemoLoading, MSection, SupportoTecnico, Title } from "components/misc";
import { courseService, dateHelper, examService, learningService, networkErrorHelper, smsService, toolService, userService } from "libs";
import { ROUTES, SITE_CONFIG } from "const";
import {
    Icon, Row, Col
} from "design-react-kit";
import { MobileValidation } from "components/tools";

class PerfezionamentoExamWritten extends Component {

    state = {
        loading: true,
        outSideHourMessage: '',
        rCode: '',
        isEnabled: true,
        message: '',
        currentConfirm: 0,
        saggioExist: false
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        toolService
            .siteConfig(SITE_CONFIG.EXAM_WRITTEN_HOUR)
            .then(({ data }) => {
                const { config } = data.payload;
                if (dateHelper.timeInTheFuture(config.END)) {
                    this.setState({ outSideHourMessage: 'Esame disponibile solamente entro le ore ' + config.END });
                }
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        userService
            .me()
            .then(({ data }) => {
                this.setState({ user: data.payload.user });
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        userService
            .profile()
            .then(({ data }) => {
                const userProfile = data.payload.profile;
                this.setState({ userProfile });
                learningService
                    .courseDashboard(rCode)
                    .then(({ data }) => {

                        const { course, userCourse } = data.payload;
                        this.setState({ course });

                        examService.ckeckPerfezionamentoWrittenExam(rCode)
                            .then(({ data }) => {
                                const { isEnabled, message } = data.payload;
                                this.setState({ loading: false, isEnabled, course, userCourse, rCode, message });

                                smsService.get("conferma_saggio_finale", "course", course.id, userProfile.mobile)
                                    .then(({ data }) => {
                                        const {sms} = data.payload;
                                        if (sms && sms.id && sms.codeConfirmed && sms.dateConfirmed) {
                                            this.setState({ currentConfirm: true });
                                        }
                                    })
                                    .catch(error => {


                                    });

                            })
                            .catch(error => {

                                networkErrorHelper.notify(error);
                                this.setState({ loading: false, loadingError: true });
                            });
                    })
                    .catch(error => {

                        networkErrorHelper.notify(error);
                        this.setState({ loading: false, loadingError: true });
                    });
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                this.setState({ loadingError: true });
            });

        courseService.saggioFinaleExist(rCode)
            .then(({ data }) => {
                if (data.payload.existSaggio > 0) {
                    this.setState({ saggioExist: true });
                }
            })
            .catch(error => {
                console.log(error);
            });


    }

    onConfirmed = () => {

        // send confirmation to segreteria
        examService.confirmSMSPerfezionamentoExamWritten(this.state.rCode)
            .then(({ data }) => {
                this.setState({ currentConfirm: true });
            })
            .catch(error => {
                console.log(error);
            });

    }

    renderValidator() {

        if (this.state.saggioExist) {
            return (<MobileValidation
                action="conferma_saggio_finale"
                tableName="course"
                idParent={this.state.course.id}
                canEdit={false}
                buttonText="Convalida"
                phoneNumber={this.state.userProfile.mobile}
                onConfirmed={this.onConfirmed} >
            </MobileValidation>);
        } else return (
            <BoxDanger className="mt-4">
                <h6>Non è possibile convalidare la prova. Saggio Finale mancante. <SupportoTecnico /></h6>
            </BoxDanger>
        );
    }

    renderIFrame() {

        const { course, rCode } = this.state;
        const aaURL = course.aa.toString().slice(-2);
        let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL)
            + 'onlineExam/index/rCode/' + rCode;
        let height = '270px';

        return (
            <>
                <div>
                    <embed src={src} width="99%" height={height} />
                </div>
            </>
        );
    }

    render() {

        const { loading, loadingError, outSideHourMessage,
            rCode, isEnabled, message } = this.state;

        if (loading) return <MnemoLoading />;
        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina, se il problema persiste
                <SupportoTecnico />
            </BoxDanger>);
        }

        if (outSideHourMessage) {
            return (<BoxDanger className="mt-4">
                {outSideHourMessage}
            </BoxDanger>);
        }


        const info = this.state.currentConfirm ?
            <BoxSuccess className="mt-4">
                <h6>Prova convalidata</h6>
            </BoxSuccess>
            : this.renderIFrame();


        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" />TORNA ALLA HOME PAGE DEL CORSO
            </NavLink>
            <Title>Convalida Saggio</Title>
            {(!isEnabled) ?
                (<>
                    <BoxDanger className="mt-4">{message}</BoxDanger>
                </>) : (<>
                    <MSection>
                        <Row>
                            <Col size="12" >
                                {info} {this.renderValidator()}
                                <hr />
                            </Col>
                        </Row>
                    </MSection>
                </>)}
        </>)
    }
}

export default withRouter(PerfezionamentoExamWritten);