import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

ReactDOM.render(<App />, document.getElementById('app-site'));
