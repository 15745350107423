import {proxyBE} from './common';

export const learningService = {    

    hasMasterPromo(){
        return proxyBE.get('learning/hasMasterPromo');
    },

    getSurvey(codeSurvey) {
        return proxyBE.get(`learning/survey/${codeSurvey}`);        
    },

    sendSurvey(data) {
        return proxyBE.post(`learning/survey/storeAttempt`, {data});
    },

    quizByIDResource(idResource) {
        return proxyBE.get(`quiz2/${idResource}`);        
    },

    myCourses() {
        return proxyBE.get('learning/courses');
    },

    myCourseByID(userId, idUserCourse) {
        return proxyBE.get('learning/course/'+userId+'/'+idUserCourse);
    },

    myFreeCourses() {
        return proxyBE.get('learning/freecourses');
    },

    getCoursesByIDUser(userId) {
        return proxyBE.get(`learning/courses/${userId}`);
    },

    courseDashboard(rCode) {
        return proxyBE.get(`learning/courseDashboard/${rCode}`);
    },

    courseMain(rCode, level) {
        return proxyBE.get(`learning/courseMain/${rCode}/${level}`);
    },

    courseMainM24(rCode, level) {
        return proxyBE.get(`learning/courseMainM24/${rCode}/${level}`);
    },

    courseMNESimulationExam(rCode, level) {
        return proxyBE.get(`learning/courseMNESimulationExam/${rCode}/${level}`);
    },

    lesson(id) {
        return proxyBE.get(`learning/lessonFull/${id}`);        
    },

    getQuiz(idResource, idQuiz) {
        return proxyBE.get(`quiz/${idResource}/${idQuiz}`);
    },

    makeTestfromRepo(params) {
        return proxyBE.get(`makeTestfromRepo/${params}`);
    },

    getQuizAttempt(idQuizAttempt) {
        return proxyBE.get(`quizAttempt/${idQuizAttempt}`);
    },

    sendQuiz(data) {
        return proxyBE.post(`quiz/store/answer`, {data});
    },

    deleteAttemp(payload) {
        return proxyBE.delete(`quiz/attempt/${payload.id}/${payload.idQuiz}/${payload.attempt}`);
    },

    loadIndexLesson(idParent, level = 0) {
        return proxyBE.get(`lessons/idparent/${idParent}/${level}`);        
    },

    loadLesson(id) {
        return proxyBE.get(`lessons/${id}`);        
    },
}