import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
    Container,    
} from 'design-react-kit';

class Unauthorized extends Component {
    
    goPrev = () => {
        // go back two pages            
        this.props.history.go(-2);
    }

    render() {
        return (
            <>
                <section className="my-4">
                    <Container>
                        <div className="ErrorPage mx-auto text-center">
                            <h2 className="ErrorPage-subtitle">Richiesta non valida</h2>
                            <p>Oops! La pagina che cerchi non è stata trovata,
                            <Link to="#" onClick={this.goPrev} > torna indietro </Link>
                            o utilizza il menu per continuare la navigazione.</p>
                            <Link className="btn btn-primary bg-dark" to="/"> Torna alla Home </Link>
                        </div>
                    </Container>
                </section>
            </>
        );
    }
}

export default withRouter(Unauthorized);