import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";
import React, { Component } from "react";

import { ROUTES } from "const";

// components
import { CategoryCard } from "components/biblioteca";

// styles
import "./CategoryGrid.scss";

export class CategoryGrid extends Component {
  render() {
    // get categories
    const { categories, theme, goCategories, title } = this.props;

    return (
      //-- theme dark default, theme light on props --//
      <div className={`${theme === "light" ? "light" : ""} categoryGrid`}>
        <div className="categoryGrid__container">
          {/* if categories exists map category cards */}
          {/* if exist props title of section */}
          {title ? (
            <div className="categoryGrid__text">
              <p className="title">
                Abbiamo diviso il nostro catalogo per categorie, così da
                facilitare la tua navigazione. Prova a cliccare sulla categoria
                che più ti interessa per visualizzare al suo interno l’elenco
                titoli di riferimento.
              </p>
            </div>
          ) : null}
          {categories ? (
            <div className="categoryGrid__wrapper">
              {categories?.map((category, index) => (
                <CategoryCard key={index} category={category} />
              ))}
            </div>
          ) : null}
          {/* if exist props goCategories | link to categories page */}
          {goCategories ? (
            <div className="categoryGrid__cta">
              <ButtonPrimary
                href={`${ROUTES.PAGE_BIBLIOTECA}/categorie/`}
                color="yellow"
                label="Esplora le categorie"
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
