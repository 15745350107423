import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";
import React, { Component } from "react";

import "./CardSolidarity.scss";

export class CardSolidarity extends Component {
  render() {
    const { linkUrl, targetBlank, imgSrc, imgAlt, title, subtitle } =
      this.props;

    const getCta = (linkUrl) => {
      if (!linkUrl) return;
      return (
        <div className="cardSolidarity__cta">
          <ButtonPrimary
            className="cardSolidarity__cta__button"
            label="scopri"
            color="yellow"
          />
        </div>
      );
    };

    return (
      <a
        href={linkUrl}
        title={title}
        className={`cardSolidarity ${linkUrl ? "link" : ""}`}
        target={targetBlank ? "_blank" : ""}
      >
        <div className="cardSolidarity__image">
          <img src={imgSrc} alt={imgAlt} />
        </div>
        <div className="cardSolidarity__content">
          <h3 className="title">{title}</h3>
          <p className="title">{subtitle}</p>
          {getCta(linkUrl)}
        </div>
      </a>
    );
  }
}
