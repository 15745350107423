import React, { Component } from "react";
import { getCurrentUser } from 'store/actions';

import {
  User,
  UserProfile,
  UserAddresses,
  UserTarget
} from "components/forms/user/profile";

import { Container } from 'design-react-kit';
export class MyProfile extends Component {

  render() {

    const user = getCurrentUser();

    return (
      <>
        <Container>
          <section className="my-4">
            <User isReadOnly={user.readOnly} />
          </section>

          <section className="my-4">
            <UserProfile isReadOnly={user.readOnly} />
          </section>

          <section className="my-4">
            <UserTarget isReadOnly={user.readOnly} />
          </section>

          <section className="my-4">
            <UserAddresses isReadOnly={user.readOnly} />
          </section>

        </Container>

      </>
    );
  }
}
