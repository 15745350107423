import React, { Component } from "react";

import { Container, Row, Col, Button } from "design-react-kit";

import { utilsService } from "libs";
import ReactHtmlParser from "react-html-parser";

import "./UserQualificationSchool.css";
import { MInput, MSelect, MToggle } from "components/forms";

import _ from "lodash";
import { BoxDanger } from "components/misc";

export class UserQualificationUniversity extends Component {
  PAYLOADS = {
    regions: [],
    university: [],
    course: [],
    laureaType: [],
  };

  toSent = {
    istitution: "",
    city: "",
    nation: "italia",
    description: "",
    laureaType: "",
    qualificationID: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      popolateRegions: { options: [{ label: "Seleziona", value: -1 }] },
      popolateLaureaType: { options: [{ label: "Seleziona", value: -1 }] },
      popolateUniversity: { options: [{ label: "Seleziona", value: -1 }] },
      popolateQualification: { options: [{ label: "Seleziona", value: -1 }] },
      actionModule: false,
      openField: {
        view: false,
        city: "",
        istitution: "",
        description: "",
        nation: this.toSent.nation,
        laureaType: "",
      },
      switchNation: false,
      showErrNation: false,
    };

    this.loadUniversity = this.loadUniversity.bind(this);
    this.loadQualification = this.loadQualification.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.resetSelectElm = this.resetSelectElm.bind(this);
    this.laureaTypeFilter = this.laureaTypeFilter.bind(this);
  }

  //--- ---

  componentDidMount() {
 
    this.loadRegions();
    this.loadLaureaType();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.openField &&
      this.state.openField.nation &&
      this.state.openField.nation.trim().toLowerCase() === "italia" &&
      this.state.switchNation === true
    ) {
      this.setState({
        openField: {
          ...this.state.openField,
          nation: "",
        },
        showErrNation: true,
      });

      setTimeout(() => {
        this.setState({
          showErrNation: false,
        });
      }, 7000);
    }
  } //componentDidUpdate

  loadRegions = () => {
    utilsService
      .regions()
      .then(({ data }) => {
        const { payload } = data;
        const regions = payload.regions.map(({ codReg, denReg }) => ({
          label: denReg,
          value: codReg,
        }));

        this.PAYLOADS.regions = [];
        this.PAYLOADS.regions.push({ label: "Seleziona", value: -1 });
        this.PAYLOADS.regions.push(...regions);

        this.setState({
          popolateRegions: { options: this.PAYLOADS.regions },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  }; //loadRegions

  loadLaureaType = () => {
    utilsService
      .laureaType()
      .then(({ data }) => {
        const { payload } = data;
        const laureaType = payload.map(({ code, label }) => ({
          label,
          value: code,
        }));

        //force reset before popolate
        this.PAYLOADS.laureaType = [];
        this.PAYLOADS.laureaType.push({ label: "Seleziona", value: -1 });
        this.PAYLOADS.laureaType.push(...laureaType);

        this.setState({
          popolateLaureaType: { options: this.PAYLOADS.laureaType },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  }; //loadLaureaType

  laureaTypeFilter = (_, value) => {
    this.toSent.laureaType = value === "-1" || value === "" ? "" : value;
    //this.toSent.description = '';

    this.setState({
      popolateQualification: { options: [{ label: "Seleziona", value: -1 }] },
    });

    this.PAYLOADS.course = [];

    this.popolateQualification(this.toSent);
    this.validateForm();
  }; //laureaTypeFilter

  loadUniversity = (_, value) => {
    const arrUni = value === "-1" || value === "" ? "" : value;
    this.popolateUniversity(arrUni);

    //--- reset toSent object property ---
    this.toSent.city = "";
    this.toSent.nation = "italia";
    this.toSent.istitution = "";
    this.toSent.description = "";
    this.toSent.qualificationID = "";

    this.setState({
      popolateQualification: { options: [{ label: "Seleziona", value: -1 }] },
      popolateUniversity: { options: [{ label: "Seleziona", value: -1 }] },
      actionModule: false,
      openField: {
        city: "",
        istitution: "",
        nation: "",
        description: "",
        laureaType: "",
      },
    });

    this.validateForm();
  }; //loadUniversity

  popolateUniversity(arrUni) {
    arrUni = arrUni.split(",");

    utilsService
      .universitiesByCodReg(arrUni[0])
      .then(({ data }) => {
        const { payload } = data;
        const university = payload.university.map(
          ({ uniCode, name, city }) => ({
            label: name,
            value: [uniCode, city, name],
          })
        );

        this.PAYLOADS.university = [];
        this.PAYLOADS.university.push(...university);
        this.PAYLOADS.university.unshift({
          label: `Seleziona (${university.length})`,
          value: -1,
        });

        this.setState({
          popolateUniversity: { options: this.PAYLOADS.university },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  } //popolateUniversity

  loadQualification = (_, value) => {
    const data = value.split(",");
    this.toSent.istitution = data[2];
    this.toSent.city = data[1];
    this.toSent.qualificationID = data[0];
    this.toSent.description = "";

    this.popolateQualification(this.toSent);
    this.validateForm();

    this.setState({
      popolateQualification: { options: [{ label: "Seleziona", value: -1 }] },
      actionModule: false,
    });
  }; //loadQualification

  popolateQualification(universityData) {
    if (universityData.qualificationID === 0) {
      return false;
    }

    utilsService
      .listUniversityQualification(universityData)
      .then(({ data }) => {
        const { payload } = data;
        const sortedPayload = _.orderBy(payload, ["description"], ["asc"]);
        const distinctPayload = _.uniqBy(sortedPayload[0], "description");
        //const distinctPayload = _.uniqBy(sortedPayload[0], v=>[v.description, v.type].join());
        const uniCourse = distinctPayload.map(({ description }) => ({
          label: description,
          value: [description],
        }));

        this.PAYLOADS.course = [];
        this.PAYLOADS.course.push(...uniCourse);
        this.PAYLOADS.course.unshift({
          label: `Seleziona (${uniCourse.length})`,
          value: -1,
        });
        this.PAYLOADS.course.push({ label: `--- ALTRO ---`, value: "other" });

        this.setState({
          popolateQualification: { options: this.PAYLOADS.course },
        });
      })
      .catch((errors) => {
        console.log(errors);
      });
  } //popolateQualification

  //--- ---

  /** */
  resetToSend = () => {
    this.toSent = {
      istitution: "",
      city: "",
      nation: "italia",
      description: "",
      laureaType: "",
      qualificationID: 0,
    };
  };

  /** */
  switchUniversitySearcher = (e) => {
    this.setState((state) => {
      state.openField.view = !this.state.openField.view;
      state.openField.city = null;
      state.openField.istitution = null;
      state.openField.description = null;
      state.openField.nation = null;
      state.laureaType = null;

      state.popolateRegions = { options: [] };
      state.popolateUniversity = { options: [] };
      state.popolateQualification = { options: [] };
      state.popolateLaureaType = { options: [] };
      state.actionModule = false;

      return true;
    });

    this.loadRegions();
    this.loadLaureaType();
  }; //switchUniversitySearcher

  /** simple form validator chek only of all value are not empty */
  validateForm = () => {
    let valid = true;
    for (const property in this.toSent) {
      if (this.toSent[property] === "") {
        valid = false;
      }
    }

    if (valid) {
      this.setState({ actionModule: true });
    } else {
      this.setState({ actionModule: false });
    }
  }; //validateForm

  /** */
  setQualificationChoose = (_, value) => {
    if (value === "other") {
      this.setState({ popolateQualification: { options: [] } });
      this.toSent.description = "";
      return false;
    }

    //--- ---

    if (value === "-1" || value === "") {
      this.toSent.description = "";
    } else {
      this.toSent.description = value;
    }

    this.validateForm();
  }; //setQualificationChoose

  /** */
  nextStepUniversity = () => {
    //--- normalize laureaType code to mnemonic format value ---
    let tmpArr = this.PAYLOADS.laureaType.filter(
      (item) =>
        Array.isArray(item.value) &&
        item.value.join() === this.toSent.laureaType
    );
    this.toSent.laureaType = tmpArr[0].label;
    this.validateForm();

    this.props.popolateDataFromChildUniversity(this.toSent);
  }; //nextStepUniversity

  switchNation = () => {
    this.switchUniversitySearcher();
    this.resetToSend();
    this.setState({
      switchNation: !this.state.switchNation,
    });
  }; //switchNation

  /** */
  resetSelectElm = (id, value) => {
    if (this.state.popolateUniversity.length > 0) {
      //reset select element only if option exists
      this.setState({
        popolateRegions: { options: [{ label: "Seleziona", value: -1 }] },
        popolateUniversity: { options: [{ label: "Seleziona", value: -1 }] },
        popolateQualification: { options: [{ label: "Seleziona", value: -1 }] },
        popolateLaureaType: { options: [{ label: "Seleziona", value: -1 }] },
      });

      this.loadRegions();
      this.loadLaureaType();
    } //>0

    //--- ---

    /** start: manage value of open field */
    this.setState((state) => {
      state.openField.city = this.state.openField.city;
      state.openField.istitution = this.state.openField.istitution;
      state.openField.description = this.state.openField.description;
      state.openField.laureaType = this.state.openField.laureaType;
      state.openField.nation = this.state.openField.nation;

      if (id === "openFieldCity") {
        state.openField.city = value;
        this.toSent.city = value;
      }

      if (id === "openFieldIstitution") {
        state.openField.istitution = value;
        this.toSent.istitution = value;
      }

      if (id === "openFieldDescription") {
        state.openField.description = value;
        this.toSent.description = value;
      }

      if (id === "openFieldNation") {
        state.openField.nation = value;
        this.toSent.nation = value;
      }

      //--- validate free form and manage state.actionModule = false;
      if (
        this.state.switchNation &&
        this.state.openField.city !== null &&
        this.state.openField.city !== "" &&
        this.state.openField.istitution !== null &&
        this.state.openField.istitution !== "" &&
        this.state.openField.description !== null &&
        this.state.openField.description !== "" &&
        this.state.openField.nation !== null &&
        this.state.openField.nation !== ""
      ) {
        state.actionModule = true;
      }

      if (
        !this.state.switchNation &&
        this.state.openField.city !== null &&
        this.state.openField.city !== "" &&
        this.state.openField.istitution !== null &&
        this.state.openField.istitution !== "" &&
        this.state.openField.description !== null &&
        this.state.openField.description !== ""
      ) {
        state.actionModule = true;
      }

      //--- validate toSent object ---
      this.validateForm();

      return true;
    });
  }; //resetSelectElm

  //--- ---
  render() {
    const {
      openField,
      switchNation,
      popolateRegions,
      popolateUniversity,
      popolateLaureaType,
      popolateQualification,
      actionModule,
    } = this.state;

    return (
      <>
        <Container key="schoolSearch" className="mb-0">
          <p className="pb-4">
            Selezionare l'Università e il Titolo conseguito, se non dovesse
            essere disponibile uilizzando la selezione guidata, si potrà
            inserire manualmente:
          </p>
          <Row>
            {(!openField.view || switchNation) && (
              <Col lg={12} className="mb-4">
                <div className="col-lg-6 col-md-6">
                  <MToggle
                    id="switchNation"
                    label="Titolo conseguito presso Università Estera?"
                    onChange={this.switchNation}
                  />
                </div>
              </Col>
            )}

            <Col lg={12} className="mb-1">
              <div className="bootstrap-select-wrapper">
                <MSelect
                  id="laureaType"
                  label="Tipo di Laurea"
                  payload={popolateLaureaType}
                  onChange={this.laureaTypeFilter}
                />
              </div>
            </Col>

            {!openField.view && (
              <>
                <Col lg={5} className="mb-1">
                  <div className="bootstrap-select-wrapper">
                    <MSelect
                      id="listRegions"
                      label="Regione"
                      payload={popolateRegions}
                      onChange={this.loadUniversity}
                    />
                  </div>
                </Col>

                <Col lg={7} className="mb-1">
                  <div className="bootstrap-select-wrapper">
                    <MSelect
                      id="listUniversity"
                      label="Università"
                      payload={popolateUniversity}
                      onChange={this.loadQualification}
                    />
                  </div>
                </Col>

                <Col lg={12} className="mb-1">
                  <div className="bootstrap-select-wrapper">
                    {popolateQualification.options.length > 2 ? (
                      <MSelect
                        id="listQualification"
                        label="Titolo conseguito"
                        payload={popolateQualification}
                        onChange={this.setQualificationChoose}
                      />
                    ) : (
                      <MInput
                        key="guideFieldQualification"
                        name="guideFieldQualification"
                        label="Digita il Titolo Universitario conseguito"
                        className="mb-2"
                        onChange={this.setQualificationChoose}
                      />
                    )}
                  </div>
                </Col>
              </>
            )}

            {openField.view && (
              <Row className="col-lg-12 m-0 p-0">
                {switchNation && (
                  <Col lg={12} className="m-0 fixMB-0Input">
                    <MInput
                      key="openFieldNation"
                      name="openFieldNation"
                      label="Nazione"
                      value={openField.nation || ""}
                      className="mb-0"
                      onChange={this.resetSelectElm}
                    />
                  </Col>
                )}
                <Col lg={6} className="m-0 fixMB-0Input">
                  <MInput
                    key="openFieldCity"
                    name="openFieldCity"
                    label="Città"
                    value={openField.city || ""}
                    className="mb-0"
                    onChange={this.resetSelectElm}
                  />
                </Col>
                <Col lg={6} className="m-0 fixMB-0Input">
                  <MInput
                    key="openFieldIstitution"
                    name="openFieldIstitution"
                    label="Università"
                    value={openField.istitution || ""}
                    className="mb-0"
                    onChange={this.resetSelectElm}
                  />
                </Col>
                <Col lg={12} className="m-0 fixMB-0Input">
                  <MInput
                    key="openFieldDescription"
                    name="openFieldDescription"
                    label="Titolo conseguito"
                    value={openField.description || ""}
                    className="mb-0"
                    onChange={this.resetSelectElm}
                  />
                </Col>
              </Row>
            )}

            {this.state.showErrNation === true ? (
              <Col lg={12} className="m-0 text-right">
                <BoxDanger>
                  {ReactHtmlParser(
                    `Se non è possibile specificare un ateneo italiano, se l'ateneo italiano non è disponible contattare il <a target="_blank" href="/supporto-tecnico">supporto tecnico</a>`
                  )}
                </BoxDanger>
              </Col>
            ) : (
              ""
            )}

            <Col lg={12} className="m-0 text-right">
              <Button
                color="primary bg-dark"
                disabled={actionModule ? false : true}
                size="md"
                onClick={this.nextStepUniversity}>
                SELEZIONA
              </Button>

              <Button
                color="primary"
                className="mx-5"
                outline
                size="md"
                onClick={() => {
                  this.props.showSearchUniversity(false);
                }}>
                ANNULLA
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
