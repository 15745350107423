import React, { Component } from "react";
import { NavLibrary, CategoryGrid } from "components/biblioteca";
import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

export class BooksCategories extends Component {
  state = {
    categories: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    libraryService
      .categories()
      .then((response) => {
        this.setState({
          isLoading: false,
          categories: response.data.payload.categories,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const { isLoading, errorMessage, networkError, categories } = this.state;
    if (isLoading) return <MnemoLoading />;
    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            {categories ? (
              <div className="categoriesPage">
                <div className="categoriesPage__container">
                  <div className="categoriesPage__wrapper">
                    <CategoryGrid
                      categories={categories}
                      theme="light"
                      goCategories={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <BoxSuccess>Nessuna categoria trovata</BoxSuccess>
            )}
          </>
        )}
      </>
    );
  }
}
