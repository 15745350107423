import React, { Component} from "react";

import {
    Card,
    CardBody,
    Icon, Row, Col
} from 'design-react-kit';

import { MSearch, MSelect} from "components/forms";

const PAYLOAD_EXAMPLE =
    [
        { value: "identityCard", label: "Carta d'identità",  },
        { value: "drivingLicence", label: "Patente di guida" },
        { value: "passport", label: "Passaporto"},
    ];
export class Search extends Component {

    state = {
        isForeign: 0,
        formActive: false
    };

    onChange = (field, value) => {
        console.log('--',field, '***',value);
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }
   

    loadForm() {
       
        const payload = { options: PAYLOAD_EXAMPLE };

        return (
            <>
                <form className="user" id="example_form">
                    <Row>
                        <Col md={6}>
                        <MSelect                            
                            field="uno"
                            id="uno"
                            name="uno"
                            label="select -- uno"
                            payload={payload}
                            onChange={this.onChange}
                        >

                        </MSelect>
                        </Col>
                        <Col md={6}>
                        <MSearch                           
                            field="due"
                            id="due"
                            name="due"
                            label="select -- due "
                            payload={PAYLOAD_EXAMPLE}
                            onChange={this.onChange}
                        >
                            </MSearch>
                        </Col>
                    </Row>                    
                </form>
                
            </>
        );
    }

    render() {


        let form = this.loadForm();
        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <div className="etichetta">
                        <Icon icon="it-card" padding={false} />
                        <span>Form di esempio</span>
                    </div>
                    <CardBody tag="div">
                        {form}

                    </CardBody>
                </Card>
            </>
        );
    }
}