import React from "react";
import { TitleRequestDownloadForm } from "./";

export class TitleRequestDownload {

    title = 'DONWLOAD';
    iconName = 'it-file';
    titleRequest = null;

    setPayload(payload) {

        this.titleRequest = payload.titleRequest;
        this.titleRequestMNEMO = payload.titleRequestMNEMO;
        this.titleRequestGOMP = payload.titleRequestGOMP;
        this.userCourse = payload.userCourse;
    }

    async onNextStep() {
        return false;
    }

    render() {

        return (<>

            <TitleRequestDownloadForm
                registrationCode={this.titleRequestMNEMO.registrationCode}
                requestID={this.titleRequest.id}
                courseCode={this.userCourse.courseCode}
            />

        </>);
    }
}