import React, { Component } from "react";
import { withRouter, NavLink } from 'react-router-dom';
import {  MnemoLoading, PDFView, Title } from "components/misc";
import { mnemoService } from "libs";

import { ROUTES } from "const";
import { Icon } from "design-react-kit";

class CourseManifesto extends Component {

    state = {
        loading: true,
        rCode: '',
        manifestoPDF: '',       
    };

    componentDidMount() {
        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {
        const payload = mnemoService.buildPayload('manifestoPDF', 'registrationCode', rCode);
        const manifestoPDF = process.env.REACT_APP_MNEMO_URL + '/course/?payload=' + encodeURI(payload);

        this.setState({ rCode, manifestoPDF, loading: false });
    }

    render() {

        const { loading, manifestoPDF, rCode } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
            <Title>Bando</Title>
            <PDFView file={manifestoPDF} canDownload={true} showAlert={false} ></PDFView>
        </>)
    }
}

export default withRouter(CourseManifesto);