import React, { Component } from "react";
import { learningService, dateHelper, networkErrorHelper, CourseHelper } from "libs";
import { ROUTES } from "const";

import { 
  MnemoCard, 
  MnemoLoading,
  BoxSuccess, 
  Title
} from "components/misc";

import {
  Container
} from 'design-react-kit';

const DEFAULT_VALUE = {
  actionLabel: 'Vai al corso',
  actionLink: ROUTES.COURSE_DASHBOARD,
  actionParam: 'empty',
  date: '21/08/2019',
  title: 'Titolo del coso non trovato',
  content: 'Descrizione del corso non trovata.',
  signature: '',
  category: ''
};

export class CourseList extends Component {

  state = {   
    loading: true,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    learningService
      .myCourses()
      .then(({ data }) => {

        const newState = data.payload.courses.map(course => {

          return Object.assign({},
            DEFAULT_VALUE,
            {
              title: course.title,
              date: dateHelper.toITDate(course.dateStart),
              content: course.summary,
              category: course.code,
              actionLink: CourseHelper.isFree(course) ? ROUTES.LEARNING_MAIN :  ROUTES.COURSE_DASHBOARD,
              actionParam: course.registrationCode,
            }
          );
        })

        this.setState({ myCourses: newState, loading: false });

      })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)){
          networkErrorHelper.notify(error);
        }
        
        this.setState({ loading: false });
      });
  }

  render() {

    const { loading, myCourses } = this.state;

    return (
      <>    
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            <section className="my-4">
              <Container>
                <Title>I miei corsi</Title>
                {myCourses
                ? myCourses.map((course, i) => (
                  <div className="row" key={`course-${i}`}>
                    <div className="col-12">
                      <MnemoCard  {...course} />
                    </div>
                  </div>
                )) : <BoxSuccess>Non risultano iscrizioni</BoxSuccess>
              }
              </Container>
            </section>
          </>)}        
      </>
    );
  }
}
