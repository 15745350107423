import React, { Component } from "react";
import { BoxDanger, MnemoLoading, PDFView, SupportoTecnico, Title } from "components/misc";

export class PDFExample extends Component {

    state = {
        loading: true,
        ladingError: false,
        rCode: '',
        istructionPDF: '',
    };

    componentDidMount() {
      
        this.loadRemote();
    }

    loadRemote(rCode) {

        //const payload = mnemoService.buildPayload('istructionPDF', 'registrationCode', rCode);
        //const manifestoPDF = process.env.REACT_APP_MNEMO_URL + '/course/?payload=' + encodeURI(payload);
        //const pdfUrl = 'https://mne21.mnemosine-elearning.it/segreteria/store/tutor-letter/istruzioni_operative_piattaforma/4_Istruzioni_Piattaforma_pd_pl_pa.pdf' ;
        const pdfUrl = 'https://mne21.mnemosine-elearning.it/segreteria/store/tutor-letter/istruzioni_operative_piattaforma/Istruzioni_Piattaforma_INFOB98.pdf';
        this.setState({ pdfUrl, loading: false });
    }

    render() {

        const { loading, pdfUrl } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);

        return (<>
            <Title>File Pdf</Title>
            {(this.state.loadingError)
                ? <>
                    <BoxDanger className="mt-4">
                        Si è verificato un errore durante il caricamento del file, se il problema persiste puoi <SupportoTecnico />
                    </BoxDanger>
                </>
                : <>
                    <PDFView file={pdfUrl} canDownload={true} showAlert={false}></PDFView>
                </>
            }
        </>)
    }
}
