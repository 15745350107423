import React, { Component } from 'react';

import {
    Alert
} from 'design-react-kit';

export class BoxInfo extends Component {

    render() {
        return (
            <Alert color="info" tag="div"  {...this.props}     >
                {this.props.children}
            </Alert>
        )
    }
}