import React from "react";

import {
    MFile,
    MLabel,
    MRadioButton,
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentAdminService, networkErrorHelper
} from "libs";

export class EnrollmentFillFormRCCFU60ALL1 extends ValidatedForm {

    emptyFields = {
        document: 0,
        titleAccess: '',
        examSite: ''
    };

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    PAYLOADS = {
        examSites: [],     
    }

    COMPETITION_TITLEACCESS=[
        { value: "laureato", label: "di essere in possesso di un titolo di Laurea idoneo come requisito di accesso ai sensi della normativa vigente;" },
        { value: "laureando", label: "di essere Laureando nei corsi di studio di cui all’art. 5, commi 1 e 2, del Decreto Legislativo 13 aprile 2017, n. 59 (iscritto all’ultimo anno dei corsi di laurea magistrale oppure iscritto ai corsi di laurea magistrale a ciclo unico ed ho già acquisito almeno 180 CFU);" },
        { value: "titolare", label: "di essere titolare di contratti di docenza nell’ambito di percorsi di istruzione e formazione professionale delle Regioni nell'anno scolastico precedente all'avvio dei percorsi (quota di riserva del 5%)." },
    ]

    FIELDS_GROUP = [
        [
            {
                field: "labelTitleAccess",
                label: "Dichiaro (selezionare una sola tra le opzioni di seguito):",
                component: MLabel,
                className: "col-md-12 pt-3"
            },
        ],
        [
            {
                id: "titleAccess",
                name: "titleAccess",
                field: "titleAccess",
                payload: { options: this.COMPETITION_TITLEACCESS },
                component: MRadioButton,
                type: "radio",            
                label: "",              
            }
        ],
        [
            {
                field: "preferExamPresence",
                label: "Sede esami",
                infoText: "Seleziona una città per l'esame in presenza",
                component: MLabel,
                className: "col-md-6 pt-3"
            },
        ], [{
            field: "examSite",
            payload: { options: this.PAYLOADS.examSites },
            component: MSelect,
            className: "col-md-6"
        } ],
        [ {
            field: "preferDocument",
            label: "Modulo B",
            infoText: "Se non l'hai ancora fatto scaricare il seguente <a href=\"/assets/doc/ModelloB_60CFU.pdf\" target=\"_blank\">Modulo B</a>, compilare e allegare",
            component: MLabel,
            className: "col-md-12 pt-3"
        }], [

            {
                id: "document",
                field: "document",
                label: "Allegare il Modulo B",
                infoText: "Caricare il Modulo opportunamente compilato e firmato",
                component: MFile,
                config: { endPoint: '/enrollment/attachment-file', mandatory: true, fieldName: 'document', maxFiles: 4, accept: '.pdf,.jpg,.bmp,.jpeg' },
                className: "col-md-12"
            }]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede esami',
        titleAccess: 'Selezionare il titolo di studio'
    };

    validation = {
        examSite: value => value > '',
        titleAccess: value => value > '',
    };

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        const { idExamSession } = this.props.enrollData;
        if (this.PAYLOADS.examSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(idExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            { document: this.props.enrollData.idEnrollment },
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {
        return false;
    };

    onSubmit = () => {
        //TODO: verificare se ha uploadato il file
        this.payload = payloadBuilder(this.state);

        this.payload.pluginConfig = this.props.enrollData.pluginConfig;
        this.payload.pluginConfig.titleAccess= this.state.titleAccess.value;

        const isValid = this.checkValidation();
        return isValid;
    }

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);
     
        return (<>           
            {code}
        </>);
    }
}