import { proxyBE } from './common';

export const userAddressService = {

    address(id) {
        return proxyBE.get(`useraddress/${id}`);
    },

    updateOrInsert(payload) {
        return proxyBE.put('useraddress', payload);
    },

    cloneResidenceAddress() {        
        return proxyBE.put('useraddress-clone');
    },
}