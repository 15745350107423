import { ROUTES } from "const";
import { courseService } from "libs";

export class BU2 {

    static async getDashboardCards(registrationCode) {

        let cards = this.getFixedCards();

        try {
            const result = await courseService.perfezionamentoActiveLink(registrationCode);
            if (result.data.payload) {
                const { payload } = result.data;

                if (payload.examReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami',
                        link: ROUTES.COURSE_EXAMFINAL_ONSITE_RESERVATION,  //ROUTES.COURSE_EXAM_WRITTEN_RESERVATION,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }
                if (payload.writtenEnabled === '1') {
                    cards.push({
                        title: 'Prova di Profitto', link: ROUTES.PERFEZIONAMENTO_EXAM_WRITTEN,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }
                if (payload.liveReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami Orali',
                        link: ROUTES.PERFEZIONAMENTO_EXAMLIVE_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }
                if (payload.isLinkExamEnabled === '1') {
                    cards.push({
                        title: 'Avvio di Google Meet',
                        link: ROUTES.PERFEZIONAMENTO_EXAM_LIVE,
                        img: '/img/icon/call.png', className: 'image-box'
                    });
                }
                if (payload.titleRequestEnabled === '1') {
                    cards.push({
                        title: 'Richiesta Certificazione e Pergamena',
                        link: ROUTES.MU_COURSE_TITLE_REQUEST,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }
                /* 
                Disattivazione RICHIESTA DA FRANESCO in DATA 03/10/2022 via skype                
                if (payload.titleSelfEnabled === '1') {
                    cards.push({
                        title: 'Piano di Studi',
                        link: ROUTES.COURSE_TITLE_SELF_DECLARATION,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }
*/

                if (payload.SelfDeclarationEnabled === '1') {
                    cards.push({
                        title: 'Autocertificazione d\'iscrizione',
                        link: ROUTES.MU_SELF_DECLARATION,
                        img: '/img/icon/badge.png',
                        className: 'image-box'
                    });
                }
            }
        } catch (error) {

        }

        return cards;
    }

    static getFixedCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Invio materiale', link: ROUTES.LEARNING_FINAL_PAPER,
                img: '/img/icon/document.png', className: 'image-box'
            }, {
                title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Tutor didattico', link: ROUTES.COURSE_TUTOR,
                img: '/img/icon/call.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.MU_COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            },
            /*{
                title: 'Progetto Corso Gratuito', link: ROUTES.COURSE_PROVIDER,
                img: '/img/icon/euro2.png', className: 'image-box'
            },*/
        ];
    }

    static getDashboardNotices() {

        return [];
    }
}
