import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { AdminCourseType } from "components/forms/admin";

import { withRouter } from 'react-router-dom';


class CourseTypeForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idCourseType: parseInt(this.props.match.params.idcoursetype) > 0 ? this.props.match.params.idcoursetype : 0,
      title: parseInt(this.props.match.params.idcoursetype) > 0 ? 'Gestione tipo corso' : 'Nuovo tipo corso'
    }
  }

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            <AdminCourseType idCourseType={this.state.idCourseType} />
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(CourseTypeForm);
