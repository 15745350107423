import {proxyBE} from './common';

export const smsService = {

    get(action, tableName, idParent, phoneNumber) {
        return proxyBE.get(`/sms/${action}/${tableName}/${idParent}/${phoneNumber}`);
    },   
    
    send(action, tableName, idParent, phoneNumber) {
        return proxyBE.post('/sms/send', { action, tableName, idParent, phoneNumber});
    }, 
    
    confirm(id, codeConfirmed) {
        return proxyBE.put('/sms/confirm', { id, codeConfirmed});
    }, 
}