import React, { Component } from "react";
import { NavLibrary, BooksList } from "components/biblioteca";

import Select from "react-select";
import IconClose from "icons/IconClose";
import IconFilters from "icons/IconFilters";
import IconArrowDown from "icons/IconArrowDown";

import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";
//styles
import "./Books.scss";

let optionsCategories = [];
let optionsAuthors = [];

export class ListBooks extends Component {
  state = {
    books: [],
    categories: [],
    authors: [],
    booksFiltered: [],
    wishlist: [],
    categorySelected: "",
    authorSelected: "",
    filtersActive: false,
    filtersOpen: false,
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  // json categories and filters
  parseOptionsCategories = (categories) => {
    categories.forEach((item) => {
      let cvalue = item.slug;
      let clabel = item.name;
      let currentCategory = {
        value: cvalue,
        label: clabel,
      };
      optionsCategories.push(currentCategory);
    });
  };

  // json authors and filters
  parseOptionsAuthors = (authors) => {
    authors.forEach((item) => {
      let avalue = item.slug;
      let alabel = item.fullName;
      let currentAuthor = {
        value: avalue,
        label: alabel,
      };
      optionsAuthors.push(currentAuthor);
    });
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    // books
    libraryService
      .books()
      .then((response) => {
        this.setState({
          isLoading: false,
          books: response.data.payload.books,
          booksFiltered: response.data.payload.books,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });

    // categories
    libraryService
      .categories()
      .then((response) => {
        this.setState({
          isLoading: false,
          categories: response.data.payload.categories,
        });
        this.parseOptionsCategories(this.state.categories);
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });

    // Authors
    libraryService
      .authors()
      .then((response) => {
        this.setState({
          isLoading: false,
          authors: response.data.payload.authors,
        });
        this.parseOptionsAuthors(this.state.authors);
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  //--- reset all active filter ---//
  resetFilters = () => {
    this.setState({
      booksFiltered: this.state.books,
      filtersActive: false,
      filtersOpen: false,
      categorySelected: "",
      authorSelected: "",
    });
  };

  //-- filtered books by category and author --//
  getFilteredBooks = (category, author) => {
    let filteredBooks = this.state.books.filter(
      (item) =>
        item.category.slug === category.value ||
        item.author.slug === author.value
    );
    this.setState({
      booksFiltered: filteredBooks,
      filtersActive: true,
      filtersOpen: false,
    });
    return filteredBooks;
  };

  //-- when filter category change --//
  handleCategoryChange = (selectedOption) => {
    if (!selectedOption) return;
    this.setState({ categorySelected: selectedOption, filtersOpen: false });
    this.getFilteredBooks(
      selectedOption,
      this.state.authorSelected,
      this.state.booksItems
    );
  };

  //-- when filter author change --//
  handleAuthorChange = (selectedOption) => {
    if (!selectedOption) return;
    this.setState({ authorSelected: selectedOption, filtersOpen: false });
    this.getFilteredBooks(
      this.state.categorySelected,
      selectedOption,
      this.state.booksItems
    );
  };

  //-- toggle filters wrapper on mobile and tablet --//
  toggleFilter = () => {
    this.setState({ filtersOpen: !this.state.filtersOpen });
  };

  render() {
    const {
      isLoading,
      errorMessage,
      networkError,
      booksFiltered,
      categorySelected,
      authorSelected,
      filtersActive,
    } = this.state;

    if (isLoading) return <MnemoLoading />;

    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            <div className="pageListBooks">
              <div className="pageListBooks__container">
                {booksFiltered ? (
                  <>
                    <div className="pageListBooks__filters">
                      <div
                        className={`${
                          this.state.filtersOpen ? "open" : ""
                        } pageListBooks__filters__title`}
                        onClick={this.toggleFilter}
                      >
                        <div>
                          <IconFilters className="icon-filter" fill="#18171c" />
                          Filtra per
                          <IconArrowDown
                            className="icon-arrow"
                            fill="#18171c"
                          />
                        </div>
                        {filtersActive ? (
                          <div
                            className="pageListBooks__filters__reset"
                            onClick={this.resetFilters}
                          >
                            Annulla filtri <IconClose fill="#000000" />
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={` pageListBooks__filters__items 
                ${this.state.filtersOpen ? "open" : ""} `}
                      >
                        {/* category filters */}
                        {optionsCategories ? (
                          <div className="pageListBooks__filters__item">
                            <span className="label-select">Categoria</span>
                            <Select
                              placeholder="seleziona categoria"
                              value={categorySelected || ""}
                              options={optionsCategories}
                              onChange={this.handleCategoryChange}
                            />
                          </div>
                        ) : null}

                        {/* authors filters */}
                        {optionsAuthors ? (
                          <div className="pageListBooks__filters__item">
                            <span className="label-select">Autori</span>
                            <Select
                              placeholder="seleziona un autore"
                              value={authorSelected || ""}
                              options={optionsAuthors}
                              onChange={this.handleAuthorChange}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="pageListBooks__content">
                      <BooksList books={booksFiltered} title="Tutti i libri" />
                    </div>
                  </>
                ) : (
                  <BoxSuccess>Nessun libro trovato</BoxSuccess>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
