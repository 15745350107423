import React, {Component} from 'react';

/**
 * Questo plugin serve solamente per gestire l'iscrizione a due corsi contemporaneamente (lato BE)
 * per cui il plugin FE iscrizione è vuoto. ritorna solametne i codici corso a cui verrà iscritto il corsista.
 */
export class FormATA extends Component {
    
    state = {  
        courses: ['ICDL238','DATTI298']    
    }

    getValue(){

        const items = this.state.courses.map(item =>{
            return {code: item}
        });

        const pluginValue = { 
            courses: items,
        };
                
        return pluginValue;
    }

    checkValidation() {
        
        const isValid = true; 

        return (isValid);       
    }    


    render() {

        return (
            <>               
            </>
        );
    }
}