import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class PageUnicef extends Component {

    render() {
        const title = 'Unicef : istruzione per i bambini colpiti dalla crisi in Siria';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >Unicef : istruzione per i bambini colpiti dalla crisi in Siria</Title>
                            <hr />
                            <p>
                                <b>Da RadioWebItalia.it - 06/09/2013 </b> </p>
                            <p> L'UNICEF sta facendo un grande sforzo per fare in modo che i bambini colpiti dalla crisi in Siria possano continuare a studiare,
                                al di là degli sviluppi dei prossimi giorni e delle prossime settimane. Circa 1,9 milioni di studenti della scuola dell'obbligo
                                (tra il 1° e il 9° anno) hanno lasciato la scuola nell'ultimo anno scolastico - il 39% dei 4.860.000 studenti registrati,
                                secondo gli ultimi dati del Ministero dell'Istruzione. Oltre 3.000 scuole sono state danneggiate o distrutte e oltre 930 sono
                                utilizzate come rifugi per sfollati. Le province di Idlib e di Aleppo sono le più colpite dalla crisi, con oltre 1.200 scuole
                                danneggiate o distrutte; la frequenza scolastica è scesa fino al 23% ad Aleppo e al 30% ad Idlib. Laddove le scuole sono ancora
                                in funzione, l'afflusso di bambini sfollati implica che non ci sono abbastanza classi, arredi, materiali scolastici e per l'insegnamento.</p>
                            <p>L'UNICEF, con il Ministero dell'Istruzione e UNRWA, lancerà questo mese un programma per l'apprendimento a domicilio che permetterà ad oltre 400.000 bambini
                                coinvolti nelle zone più calde del conflitto di continuare a seguire il programma di studi nazionale.
                                L'istruzione è estremamente importante: questo settore ha subito molti colpi a cominciare dallo scoppio del conflitto due anni e mezzo fa e molti bambini
                                stanno subendo pressioni e assumendo responsabilità che nessun bambino dovrebbe sopportare. Fare in modo che i bambini abbiano la possibilità di imparare,
                                anche se solo per poche ore al giorno, li protegge e consente loro di rimanere in un percorso di vita quanto più normale possibile.</p>

                            <p>Nel complesso l'UNICEF sta lavorando per assistere centinaia di migliaia di bambini perché continuino a studiare. 70 classi prefabbricate,
                                su un totale di 300, sono già nei governatorati in cui le infrastrutture scolastiche sono state maggiormente danneggiate.
                                L'UNICEF ha fornito cartelle (con all'interno materiali di cancelleria) per oltre un milione di bambini in tutti e 14 i governatorati.
                                Migliaia di kit per l'apprendimento e l'insegnamento, forniture ricreative e materiali per l'istruzione per la prima infanzia stanno
                                per essere consegnati.</p>

                            <p>Sono necessari 20milioni di dollari aggiuntivi per stampare e distribuire 8 milioni di libri di testo e per riabilitare
                                le infrastrutture scolastiche.</p>

                            <p>Emergenza Siria, donazioni all'UNICEF tramite:</p>
                            <ol>
                                <li>bollettino di c/c postale numero 745.000, intestato a UNICEF Italia, specificando la causale "Emergenza Siria"</li>

                                <li>carta di credito online sul sito www-unicef.it, oppure telefonando al Numero Verde UNICEF 800 745 000</li>

                                <li>bonifico bancario sul conto corrente intestato a UNICEF Italia su Banca Popolare Etica: IBAN IT51 R050 1803 2000 0000 0510 051,
                                    specificando la causale "Emergenza Siria"</li>
                            </ol>


                        </Col>

                    </Row>
                </MSection>
            </>);
    }
}