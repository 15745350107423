import React, { Component } from "react";
import { networkErrorHelper, learningService } from "libs";
import { withRouter, NavLink } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";

class CoursePaymentCD extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        rCode: 0,
        numRate: 0,
    };

    componentDidMount() {
        const { rCode, numRate } = this.props.match.params;
        if (rCode && numRate) {
            this.loadRemote(rCode, numRate);
        } else {
            this.setState({
                loading: false,
                loadingError: true,
                message: 'Dati del pagamento mancanti o non corretti. '
            });
        }
    }

    loadRemote(rCode, numRate) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;                
                this.setState({ loading: false, loadingError: false, numRate, rCode,  course, userCourse, });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true, message: 'Si è verificato un erroe durante il caricamento dei dati del pagamento. ' });
            });
    }//loadRemote

    renderIFrame = () => {

        const { rCode, numRate, course } = this.state;
        if (course && numRate) {
            const aaURL = course.aa.toString().slice(-2);
            let src = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + 'elearningCartaDocente/renderInsert/rCode/' + rCode + '/tableName/StudentPayment/numRate/'+ numRate;
            let height = '1000px';

            return (
                <>
                    <div>
                        <embed src={src} width="99%" height={height} />
                    </div>
                </>
            );
        }
    }

    render() {

        const { loading, loadingError, message, rCode } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                {message}. Se il problema persiste puoi <SupportoTecnico />
            </BoxDanger>);
        }

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title>Caricamento voucher carta del docente</Title>
                {this.renderIFrame()}
            </>)
    }

}

export default withRouter(CoursePaymentCD);