import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { ticketService, dateHelper, networkErrorHelper } from "libs";
import { MnemoLoading } from "components/misc";

class CourseTicketDetail extends Component {

  state = {
    idTicket: this.props.ticketid,
    rCode: this.props.rCode,
    loading: false,
    content: ''
  };


  componentDidMount() {
    this.loadRemote(this.state.idTicket, this.state.rCode);
  }

  loadRemote(id, rCode) {
    ticketService.detailTicket(id, rCode).then((data)=>{

      if(data.data.status !== undefined){
        if((data.data.status).toLowerCase() === 'ok'){
          let content = (
            <>
              <b>Risposta:</b> {data.data.payload[0].message}<br />
              <p className="small text-muted">
                <b>Del :</b> {dateHelper.toITDateTime(data.data.payload[0].dateCreation)}
              </p>
            </>
          );

          this.setState({ content: content });
        }
      } else {
        let content = (
          <>
            <b>Risposta:</b> In attesa di risposta
          </>
        );

        this.setState({ content: content });
      }
    })
    .catch(error => {
      if (networkErrorHelper.is422(error)) {
        let content = (
          <>
            <b>Risposta:</b> momentaneamente non disponibile <br />
            <p className="small text-muted">se il problema persiste contattare il servizio helpdesk fornendo questo codice: {error.response.status}</p>
          </>
        );     

        this.setState({ content: content });
      } 

      if (networkErrorHelper.is404(error)) {
        let content = (
          <>
            <b>Risposta:</b> in attesa di risposta
          </>
        );     

        this.setState({ content: content });
      } 
    });
  }

  //--- ---

  render() {

    const { loading, content } = this.state;

    return (
      <>
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            {content}
          </>)}
      </>
    );
  }
}

export default withRouter(CourseTicketDetail);