import { MSection } from "components/misc";
import React, { Component } from "react";

export class SCORMPlayer extends Component {

    render() {
        return (<>
            <MSection>                
                <object
                    id="scorm_object" type="text/html"
                    data="https://www.mnemosine-elearning.it/moodledata/custom/php/loadSCO.php?a=33&amp;scoid=96&amp;currentorg=lezione_12_organization&amp;attempt=1" 
                    crossOrigin="anonymous">

                </object>
            </MSection>
        </>)
    }
}