import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea,
    MToggle,    
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
    stringHelper
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';

export class AdminLibraryAuthor extends ValidatedForm {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idLibraryAuthor) > 0 ? false : true,
            forceUpdateParentList: 0
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    //--- ---    

    emptyFields = {
        isVisible: false,
        fullName: '',
        bio: '',
        slug: ''
    }

    parentList = []


    FIELDS_GROUP = [   
        [
            {
                id: "isVisible",
                name: "isVisible",
                field: "isVisible",
                label: "Visibile",
                component: MToggle,
                className: "col-md-3"
            }
        ],
        [
            {
                id: "fullName",
                field: "fullName",
                label: "nome completo",
                component: MInput,
                type: "text",                
                onChange: (_, value) => this.onChangeMakeSlug(_, value), 
                className: "col-md-12"
            }
        ],
        [
            {
                id: "bio",
                field: "bio",
                label: "Biografia",
                component: MTextArea,
                rows: 5,
                className: "col-md-12"
            }
        ],
        [
            {
                id: "slug",
                field: "slug",
                label: "URL (slug)",
                component: MInput,
                type: "text",
                className: "col-md-12 mt-4"
            }
        ]
    ];

    ERROR_MESSAGES = {
        fullName: "Nome non valido (max 255 caratteri)",
        slug: "Slug (URL) non valido"
    };

    validation = {
        fullName: value => /^[a-z0-9:!?.,-/àèéì’'òù,/\s"]+$/i.test(value) && value.length <= 255,
        slug: value => value.length>0 && value.length <= 255,
    };

    
    //--- ---

    componentDidMount(){
        this.loadRemote();
    }    

    onChangeMakeSlug = (_, value) => {
        const ret = stringHelper.makeSlug(value);
       
        this.setState({
            slug: ret,
            defaultValues:{
                ...this.state.defaultValues,
                slug: ret
            }
        })
    }

    
    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idLibraryAuthor) <= 0) {
            return false;
        }

        let filter = {
            searchFilter:{
              id: parseInt(this.props.idLibraryAuthor)
            }
        }

        adminService.loadAllLibraryAuthor(filter)
            .then(({ data }) => {
                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.libraryAuthors,
                );
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false,
                };
                
                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }//loadRemote

    onBackPage = () => {
        window.history.back();
    }//onBackPage

    saveRemote = () => {
        let payload = payloadBuilder(this.state);
       
        if (parseInt(this.props.idLibraryAuthor) > 0) {
            adminService.updateLibraryauthor(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertLibraryauthor(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    }//saveRemote

    //--- ---

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues } = this.state;

        if (parseInt(this.props.idLibraryAuthor) === 0) {
            formActive = true;
        }

        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">
                        <form className="user" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idLibraryAuthor) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
