import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';

export const rc30ExamService = {    
   
    async myExam(registrationCode, competitionClass){
        const payload = mnemoService.buildPayloadObject('myExam', {'registrationCode': registrationCode, 'competitionClass' : competitionClass});
        return await proxyApiMnemo.get('rc30/?payload='+encodeURI(payload));        
    },
    
    async myExamSubject(registrationCode){
        const payload = mnemoService.buildPayloadObject('myExamSubject', {'registrationCode': registrationCode});
        return await proxyApiMnemo.get('rc30/?payload='+encodeURI(payload));        
    },

    async myExamSubjectUDA(registrationCode) {
        const payload = mnemoService.buildPayload('myExamSubjectUDA', 'registrationCode', registrationCode);
        return proxyApiMnemo.get('rc30/?payload='+encodeURI(payload));
    },

    async myExamDone(registrationCode){
        const payload = mnemoService.buildPayloadObject('myExamDone', {'registrationCode': registrationCode});
        return await proxyApiMnemo.get('rc30/?payload='+encodeURI(payload));        
    },

    async reserveExam(registrationCode, idSite, idHour){
        const payload = mnemoService.buildPayloadObject('reserveExam', {'registrationCode': registrationCode, 'idSite' : idSite, 'idHour' : idHour});
        return await proxyApiMnemo.post('rc30/?payload='+encodeURI(payload));        
    },

    async confirmExamSubject(rCode, idSelected){
        const payload = mnemoService.buildPayloadObject('confirmExamSubject', {'registrationCode': rCode, 'idSelected' : idSelected});
        return await proxyApiMnemo.post('rc30/?payload='+encodeURI(payload));        
    }
}