import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col, Accordion, AccordionHeader, AccordionBody
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class Doctorate extends Component {

    defaultState = {
        collapseOpen1: false,
        collapseOpen2: false,
        collapseOpen3: false,
        collapseOpen4: false,
        collapseOpen5: false,
        collapseOpen6: false,
        collapseOpen7: false,
        collapseOpen8: false,
    }

    state = {
        ...this.defaultState,
    }


    toggle = id => {
        this.setState({
            ...this.defaultState,
            [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
        })
    }


    render() {
        const title = 'DOTTORATI DI RICERCA';
        const { collapseOpen1, collapseOpen2, collapseOpen3, collapseOpen4,
            collapseOpen5, collapseOpen6, collapseOpen7, collapseOpen8 } = this.state;

        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <p className='mt-0'>Il Dottorato di Ricerca Universitario, oltre a consentire l'accesso all'Insegnamento in una qualsiasi Università, è valutabile: </p>
                            <ul>
                                <li><b>punti 12</b> nelle <b>GPS</b> di prima fascia!</li>
                                <li><b>punti 12</b> nelle <b>GPS</b> di seconda fascia!</li>
                                <li><b>punti 12</b> nelle GAE (Graduatorie ad Esaurimento - Terza e Quarta Fascia - Allegato 2 al D.M. 235 del 1 Aprile 2014 punto C).</li>
                                <li><b>punti 5</b> nelle procedure concorsuali per titoli ed esami per l'accesso ai ruoli personale docente secondaria di primo e secondo grado, su posto comune e di sostegno</li>
                                <li><b>punti 6</b> nei passaggi di ruolo/cattedra [Tabella <b>B</b> - sezione B2 - lettera G]</li>
                                <li><b>punti 5</b> nei trasferimenti [Tabella <b>A</b> - sezione A3 - lettera F]</li>
                                <li><b>punti 2</b> ai fini della predisposizione delle graduatorie finali degli ammessi al TFA sostegno</li>
                            </ul>

                            <p>Adesioni Aperte da <b>€ 2.000</b></p>
                            <p className='mt-0'>Per il decimo anno, l'Associazione Mnemosine ed Asuniver s.r.l. propongono Dottorati di Ricerca all'estero validi ai sensi delle norme vigenti.</p>
                            <p className='mt-0 mb-0'><b>Complessivamente sono previsti 20 posti.</b></p>
                            <p className='mt-0 mb-0'>Tali percorsi scaturiscono da specifici accordi con varie università Statali Spagnole.</p>
                            <p className='mt-0'>Tutti gli interessati possono inviare il <a href="/files/corsi/dottorati-di-ricerca/modulo_adesione_dottorato_di_ricerca.pdf" target="_blank">“Modulo di Adesione”</a> al seguente indirizzo email: <a href="mailto:adesioni@formazionedocenti.it">adesioni@formazionedocenti.it</a>.</p>

                            <p className='mt-0 mb-0'><b>Compatibilità</b></p>
                            <p className='mt-0 mb-0'>L'iscrizione al Dottorato di Ricerca svolto presso le Università Estere è compatibile con l'iscrizione contemporanea ad altro corso universitario ai sensi della L. n. 33/2022, del D.M. attuativo 930/2022 e <b>relative FAQ emanate dal MUR (faq 12/01/2023 - faq 10/10/2022)</b></p>
                        </Col>
                    </Row>
                </MSection>
                <MSection className="my-4">
                    <Title>Faq inerenti il Dottorato di ricerca all'estero</Title>
                    <Accordion>
                        <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                            <span className="text-green" >Il Dottorato di ricerca proposto è valido?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen1}>
                            <p>Noi proponiamo solo Dottorati di Ricerca validi. Moltissimi iscritti hanno conseguito il titolo che è stato già dichiarato equipollente dal MIUR al titolo di Dottore di
                                Ricerca dell'ordinamento Universitario Italiano.</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen2} onToggle={() => this.toggle(2)}>
                            <span className="text-green" >Quali sono le linee di investigazione seguite dai Dottorati di ricerca nei percorsi da voi proposti?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen2}>
                            <p>Vi sono parecchie linee investigative: “Educazione”, “Immigrazione”, “Psicologia”, “Educazione Motoria”, ecc…</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen3} onToggle={() => this.toggle(3)}>
                            <span className="text-green" >I Dottorati di ricerca proposti chi li eroga?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen3}>
                            <p> I Dottorati sono erogati solo da Università Statali della Spagna.</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen4} onToggle={() => this.toggle(4)}>
                            <span className="text-green" >Essi sono riconosciuti in Italia?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen4}>
                            <p>Si, anche a seguito della “Dichiarazione Congiunta della Conferenza dei Rettori delle Università Italiane (CRUI) e Spagnola (CRUE)”. Ad oggi i Dottorati già conseguiti sono stati dichiarati
                                dal MIUR equipollenti al titolo di Dottore di Ricerca dell'ordinamento Universitario Italiano.</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen5} onToggle={() => this.toggle(5)}>
                            <span className="text-green" >Da quando li proponete?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen5}>
                            <p>Li proponiamo da oltre 9 anni.</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen6} onToggle={() => this.toggle(6)}>
                            <span className="text-green" >Requisiti minimi di accesso al dottorato in Spagna</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen6}>
                            <p>Possono accedere al Dottorato studenti in possesso di un titolo di laurea magistrale, o titolo equivalente</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen7} onToggle={() => this.toggle(7)}>
                            <span className="text-green" >Cosa si deve fare per accedere al Dottorato di Ricerca da voi proposto.</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen7}>
                            <p>Per acquisire il suo interesse al Dottorato deve inoltrare il modulo di adesione, ASSOLUTAMENTE NON VINCOLANTE, compilato in tutte le sue parti e successivamente, non appena
                                sarà attivo il Bando di partecipazione, le invieremo le modalità di accesso al Dottorato di Ricerca.</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen8} onToggle={() => this.toggle(8)}>
                            <span className="text-green" >Quanti posti sono disponibili?</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen8}>
                            <p>Mediamente 20 posti e l'accesso è a numero chiuso.</p>
                        </AccordionBody>

                    </Accordion>
                </MSection>
            </>);
    }
}