import React, { Component } from "react";
import QuestionCheck from "./QuestionCheck";
import QuestionRadio from "./QuestionRadio";
import QuestionText from "./QuestionText";
import QuestionOutcome from "./QuestionOutcome";

import ReactHtmlParser from 'react-html-parser';
//import QuestionFill from "./QuestionFill";
import QuestionFillFromSelect from "./QuestionFromSelect";
import QuestionFillFree from "./QuestionFillFree";
import QuestionMultiFillFree from "./QuestionMultiFillFree";

export class QuizSection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const item = this.props.content;
        const { posOrder, idQuiz, metadata, attemptTracker } = this.props;

        const quizType = (type, answers, idQuestion, idQuiz, label, renderMode, metadata, attemptTracker) => {
            switch (true) {
                case type === 'check':
                    return <QuestionCheck type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} metadata={metadata} attemptTracker={attemptTracker} />;
                case type === 'radio':
                    return <QuestionRadio type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} metadata={metadata} attemptTracker={attemptTracker} />;
                case type === 'text':
                    return <QuestionText type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} metadata={metadata} attemptTracker={attemptTracker} />;
                case type === 'fill' && renderMode === 'fillFromSelect':
                    return <QuestionFillFromSelect type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} label={label} renderMode={renderMode} metadata={metadata} attemptTracker={attemptTracker} />;
                case type === 'fill' && renderMode === 'fillFree':
                    return <QuestionFillFree type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} label={label} renderMode={renderMode} metadata={metadata} attemptTracker={attemptTracker} />;
                case type === 'fill' && renderMode === 'fillFreeMulti':
                    return <QuestionMultiFillFree type={type} idQuestion={idQuestion} answers={answers} idQuiz={idQuiz} label={label} renderMode={renderMode} metadata={metadata} attemptTracker={attemptTracker} />;
                default:
                    return ''
            }
        }//quizType
        
        return (
            <>                 
                {/** regular layout */}
                {((typeof metadata.dnd === 'undefined' || metadata.dnd === false) && (typeof metadata.toMergeQuestions === 'undefined' || metadata.toMergeQuestions === false)) && <section className="my-4">
                    <div className="container">
                        <div id={`q-${item.id}`} className="card card-bg card-big p-4 border-left border-primary">

                            {(!metadata.hasOwnProperty('hideLabel') || metadata?.hideLabel === false) ? <div >Domanda {(posOrder+1)}</div> : ''}

                            <h6 className="fix-quiz-form">
                                {
                                    (item.renderMode !== 'fillFree' && item.renderMode !== 'fillFreeMulti') && (
                                        ['check', 'radio', 'text'].includes(item.type)) && ReactHtmlParser(item.label)
                                }
                            </h6>
                            <div className="card-footer p-0 pt-2 mt-2">
                                {quizType(item.type, item.answers, item.id, idQuiz, item.label, item.renderMode, metadata, attemptTracker)}
                            </div>

                            {(typeof metadata !== 'undefined') && <QuestionOutcome question={item} answers={item.answers} metadata={metadata}/>}
                            {(item.type === 'check' && typeof metadata === 'undefined') && <i className="mt-2">segnare {item.maxChoose} risposte.</i>}
                        </div>
                    </div>
                </section>}


                {/** drag&drop layout */}
                {(typeof metadata.dnd !== 'undefined' && metadata.dnd === true) && <section className="m-0">
                    <div className="container">
                        <div id={`q-${item.id}`} className="card px-2 border-left border-primary">

                            {(!metadata.hasOwnProperty('hideLabel') || metadata?.hideLabel === false) ? <div >Domanda {(posOrder + 1)}</div> : ''}

                            <h6 className="fix-quiz-form">
                                {
                                    (['check', 'radio', 'text'].includes(item.type)) && ReactHtmlParser(item.label)
                                }
                            </h6>
                            <div className="card-footer card-footer-dnd p-0 m-0">
                                {quizType(item.type, item.answers, item.id, idQuiz, item.label, item.renderMode, metadata)}
                            </div>

                            {(typeof metadata !== 'undefined') && <QuestionOutcome question={item} answers={item.answers} metadata={metadata} />}
                            {(item.type === 'check' && typeof metadata === 'undefined') && <i className="mt-2">segnare {item.maxChoose} risposte.</i>}
                        </div>
                    </div>
                </section>
                }


                {/** merged layout */}
                {((typeof metadata.toMergeQuestions !== 'undefined') && metadata.toMergeQuestions === true) && <section className="m-0">
                    <div className="container">
                        <div id={`q-${item.id}`} className="card px-2 border-left border-primary">

                            {(!metadata.hasOwnProperty('hideLabel') || metadata?.hideLabel === false) ? <div >Domanda {(posOrder + 1)}</div> : ''}

                            <h6 className="fix-quiz-form">
                                {
                                    (['check', 'radio', 'text'].includes(item.type)) && ReactHtmlParser(item.label)
                                }
                            </h6>
                            <div className="card-footer card-footer-dnd p-0 m-0 wrapperQuestionParagraph">
                                {quizType(item.type, item.answers, item.id, idQuiz, item.label, item.renderMode, metadata)}
                            </div>

                            {(typeof metadata !== 'undefined') && <QuestionOutcome question={item} answers={item.answers} metadata={metadata} />}
                            {(item.type === 'check' && typeof metadata === 'undefined') && <i className="mt-2">segnare {item.maxChoose} risposte.</i>}
                        </div>
                    </div>
                </section>
                }
            </>
        );
    }
}