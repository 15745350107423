import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { NavLibrary, BooksList } from "components/biblioteca";
import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";

import { ROUTES } from "const";
import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";

//styles
import "./Author.scss";

class Author extends Component {
  state = {
    slug: null,
    author: [],
    books: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    const { slug } = this.props.match.params;
    libraryService
      .author(slug)
      .then((response) => {
        this.setState({
          isLoading: false,
          author: response.data.payload,
          books: response.data.payload.books,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const { isLoading, errorMessage, networkError, author, books } = this.state;
    if (isLoading) return <MnemoLoading />;
    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            <NavLibrary />
            <div className="authorPage">
              <div className="authorPage__container">
                <div className="authorPage__wrapper">
                  {author ? (
                    <>
                      <div className="authorPage__info">
                        <div className="authorPage__cover">
                          <div className="authorPage__cover__image">
                            <img
                              src={
                                author?.image
                                  ? author.image
                                  : "/img/biblioteca/books.jpg"
                              }
                              alt={author.fullName}
                            />
                          </div>
                          {/* author name */}
                          {author?.fullName ? (
                            <div className="authorPage__author-title">
                              <h1 className="title">{author.fullName}</h1>
                            </div>
                          ) : null}
                        </div>
                        {/* author biography */}
                        {author?.bio ? (
                          <div className="authorPage__author-description">
                            <p>{author.bio}</p>
                          </div>
                        ) : null}
                      </div>
                      {books ? (
                        <div className="authorPage__books">
                          <BooksList books={Object.values(books)} />
                        </div>
                      ) : (
                        <div>Nessun libro presente</div>
                      )}
                    </>
                  ) : (
                    <BoxSuccess>Autore non presente</BoxSuccess>
                  )}
                </div>
                <div className="authorPage__allAuthors">
                  <ButtonPrimary
                    color="yellow"
                    label="Vedi tutti gli Autori"
                    href={`${ROUTES.PAGE_BIBLIOTECA}/autori`}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Author);
