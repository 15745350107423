import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Maintenance } from 'components/layout/Maintenance';
import ScrollToTop from 'components/layout/ScrollToTop';

import { MBootstrap } from 'themes/MBootstrap';
import { IFrame } from 'themes/IFrame';
import { uriHelper } from 'libs/helpers/uriHelpers';

import { Routes } from 'views/routing';
import { MnemoLoading } from "components/misc";

import { preRenderChecks } from 'store/actions/app';
import GA4React from "ga-4-react";

class Main extends Component {

  componentDidMount() {
    this.props.preRenderChecks();

    /** googleAnalitycs GA4 version */   
    const ga4react = new GA4React("G-X892S4B7ZK"); //formazionedocenti
    ga4react.initialize();
  }

  render() {
    const { isLoggedIn, isInMaintenance, maintenanceMessage, isLoading } = this.props;

    if (isLoading) {
      return <MnemoLoading />;
    }

    const Theme = this.getTheme();

    if (isInMaintenance) return <Maintenance message={maintenanceMessage} />;

    return (
      <>
        <ScrollToTop />
        <Theme isLoggedIn={isLoggedIn} >
          <Routes isLoggedIn={isLoggedIn} />
        </Theme>
      </>
    );
  }

  getTheme = () => {

    const params = uriHelper.parseQueryString();
    const { theme } = params;

    // TODO: creare un configuratore per stabilire il layout da utilizzare    
    // attualmente unica possibilità è mettere un parametro theme

    if (theme && theme === 'iframe') {
      return IFrame;
    }

    return MBootstrap;
  };
}

const stateToProps = ({ app }) => {
  const { user, isLoading, maintenanceMode, error } = app;
  return {
    isLoggedIn: user !== null,
    isLoading,
    isInMaintenance: maintenanceMode.active,
    maintenanceMessage: maintenanceMode.message,
    error
  };
};

const dispatchToProps = dispatch => {
  return {
    preRenderChecks() {
      dispatch(preRenderChecks());
    },
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(Main);
