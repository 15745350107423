import React, { Component } from "react";

import { MSection, Title } from "components/misc";
import { Row, Col } from "design-react-kit";
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
SwiperCore.use([Pagination]);

export class PageLeoniSicani extends Component {
  render() {
    const title = 'Associazione "A. S. D. LEONI SICANI - ONLUS"';
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <MSection>
          <Row>
            <Col>
              <Title>{title}</Title>
              <hr />
              <p>
                <strong>Regalare un sorriso.</strong> Era questo il desiderio
                dell’<strong>Associazione Mnemosine</strong> nei confronti dei{" "}
                <strong>
                  Leoni Sicani, la squadra di hockey su carrozzina
                </strong>{" "}
                che rappresenta il territorio agrigentino in ambito nazionale.
                Abbiamo dunque pensato di donar loro qualcosa che li avvicinasse
                sempre più alla libertà: un pullman che li possa accompagnare
                ovunque essi vorranno.
              </p>
              <p>
                La libertà di viaggiare dunque, di conoscere, di muoversi. E
                grazie al mezzo donato dal nostro ente, la squadra dei "Leoni
                Sicani" ha potuto partecipare alle trasferte del campionato
                italiano di "Wheelchair Hockey" (l'hockey su carrozzina).
                L’associazione inoltre ha voluto donare il primo vero viaggio
                dei Leoni Sicani a Parigi, luogo nel quale i ragazzi hanno
                potuto fare esperienza concreta delle attrazioni turistiche più
                ambite: la Torre Eiffel, il tour sulla Senna, l’Euro Disney.
              </p>
              <p>
                <i>
                  Per me è stato un enorme orgoglio e piacere offrire una
                  vacanza del genere ai ragazzi dei Leoni Sicani
                </i>{" "}
                - è quanto afferma il <strong>professore Nino Barbera</strong>,
                fondatore dell'Associazione Mnemosine - sono delle persone
                straordinarie e devo dire che il loro entusiasmo mi ha
                contagiato. Vorrei che il mio gesto fosse da stimolo per tutti
                coloro che hanno a disposizione determinate possibilità
                economiche, incentivando la loro attività di solidarietà a
                favore dei Leoni Sicani o nei confronti di realtà simili.”
              </p>
              <h3>
                Grazie a tutti i Corsisti dell'Associazione Mnemosine per aver
                contribuito a tutto questo.
              </h3>
              <p>
                L'Associazione "A. S. D. LEONI SICANI - ONLUS" ha per oggetto
                l'esercizio e la promozione di attività sportive, culturali e
                ricreative per i portatori di handicap, con particolare
                riferimento alla disciplina del Wheelchair Hockey. L'hockey su
                carrozzina elettrica, nasce grazie ad un gruppo di giovani
                affetti da distrofia muscolare che, seguendo già da tempo alcuni
                loro amici disabili impegnati in altre discipline, volevano
                rendersi anche loro protagonisti di uno sport che fosse adatto
                alle loro particolari esigenze.
              </p>
              <p>
                Aiutaci a sostenere le nostre attività, fai una donazione ai
                Leoni Sicani, Codice Iban: IT18H0879683140000030300834.
              </p>
              <p>
                Oppure, destina il tuo 5x1000 alla squadra, inserisci il codice
                fiscale "92020760846″ nel riquadro "Sostegno del volontariato e
                delle altre organizzazioni non lucrative di utilità sociale,
                delle associazioni di promozione sociale e delle associazioni e
                fondazioni riconosciute che operano nei settori di cui all'Art.
                10, C.1, Lett. A, del D.Lgs. N. 460 del 1997". A te non costa
                nulla, per noi è un aiuto importante! I Leoni Sicani sono anche
                su facebook! Diventa membro del gruppo "A. S. D. Leoni Sicani -
                Onlus" e sarai aggiornato su tutte le attività della squadra!
              </p>
              {/* old text */}
              {/* <p>Regalare un sorriso. </p>
              <p>
                Questo è stato il desiderio dell'Associazione Mnemosine guidata
                dal Prof. Nino Barbera nei confronti dei Leoni Sicani, squadra
                di hockey su carrozzina che rappresenta il territorio
                agrigentino in ambito nazionale. Così l'Ente che si occupa di
                formazione per i docenti in tutta Italia, ha voluto regalare un
                viaggio da "sogno" ai ragazzi disabili dell'associazione
                sportiva, che tra il 7 e il 16 settembre hanno avuto il piacere
                di passare delle allegre giornate nel famoso parco di Euro
                Disney oltre ad avere il privilegio di ammirare una città
                affascinante come Parigi attraverso i suoi principali monumenti.
              </p>
              <p>
                Inutile dire che per quasi tutti i ragazzi, accompagnati
                ciascuno da un proprio familiare, è stato il primo viaggio oltre
                i confini italiani, che ha di fatto raddoppiato l'emozione di
                una vacanza inaspettata e organizzata in tutti i minimi
                dettagli. La squadra, arrivata nella capitale francese con un
                mini bus dotato di pedana elevatrice, anch'esso donato dalla
                Mnemosine, ha passato prima due giorni tra le attrazioni e la
                magia di Disneyland Paris e i suoi Studios, dove tutti sono
                tornati un pò bambini riscoprendo il divertimento di una
                montagna russa, o ritrovandosi in una dimensione virtuale grazie
                a degli incredibili effetti 3D. Alla comitiva si è aggiunto il
                prof. Barbera, che ha potuto constatare l'allegria e la felicità
                negli occhi dei ragazzi nel vivere un'esperienza del genere,
                grazie anche all'accessibilità delle attrazioni (alcune si
                facevano con la carrozzina stessa) che un parco come Euro Disney
                mette a disposizione attraverso un'ottima organizzazione volta a
                fornire particolari confort alle persone portatrici di handicap.
              </p>

              <p>
                Poi è stata la volta di Parigi, quindi le tappe "obbligate" alla
                cattedrale di Notre Dame, gli Champs-Elysées, l'immenso museo
                del Louvre, la Tour Eiffel e anche un giro sulla Senna con un
                battello completamente accessibile alle carrozzine che ha
                accolto tutta la squadra a bordo. Ovviamente innumerevoli sono
                state le foto scattate tra i viali e i monumenti di una città
                magica come Parigi, che ha visto un trenino di carrozzine,
                elettriche e manuali, scorazzare per giornate intere davanti
                agli occhi di francesi e turisti incuriositi nel vedere tutti
                insieme gli atleti dei Leoni Sicani. Infine ultima tappa, prima
                di riprendere la nave da Genova per rientrare in Sicilia, è
                stata Torino, con le suggestive piazze, piazza Castello e piazza
                San Carlo, il Duomo e la Mole Antonelliana, monumenti ricchi di
                storia che i "Leoni" hanno apprezzato anche grazie alla
                sorprendente accessibilità, di marciapiedi, attività
                commerciali, della città.
              </p>

              <p>
                "E' stata un'esperienza fantastica - afferma Francesca Massimino
                Presidente dell'A.S.D. Leoni Sicani Onlus - un viaggio "premio"
                per l'incredibile risultato raggiunto nel maggio scorso, quando
                dopo soli quattro anni dalla nostra nascita siamo diventati vice
                campioni d'Italia della nostra disciplina sportiva, ci tenevo a
                ringraziare l'Associazione Mnemosine per questo immenso regalo
                che ha reso felici e increduli allo stesso tempo i nostri
                ragazzi nel vedere una città meravigliosa come Parigi, oltre a
                vivere delle giornate spensierate all'interno del parco
                divertimenti più bello d'Europa che è Disneyland Paris, credo
                sia stata un'emozione per me e per tutti che ci porteremo sempre
                nel cuore, grazie veramente per la sensibilità e vicinanza che
                ci dimostrate attraverso iniziative come questa che purtroppo di
                rado se ne vedono nella società in cui viviamo".
              </p>

              <p>
                "Per me è stato un enorme orgoglio e piacere offrire una vacanza
                del genere ai ragazzi dei Leoni Sicani - è quanto afferma il
                professore Nino Barbera, fondatore dell'Associazione Mnemosine -
                sono delle persone straordinarie che ho avuto modo di conoscere
                già da qualche anno e devo dire che il loro entusiasmo mi ha
                contagiato, il mio gesto vorrei che venga visto come uno stimolo
                per tutti coloro che avendo a disposizione determinate
                possibilità economiche possano prendere da esempio questa
                iniziativa, incentivando la loro attività di solidarietà a
                favore dei Leoni Sicani o nei confronti di realtà simili, enti
                che grazie alla loro quotidiana attività rendono migliore il
                nostro territorio donandoci una "mentalità nuova", dei punti di
                vista diversi, dai quali noi tutti osserviamo le cose, e grazie
                ai quali rendiamo migliore la nostra vita e quella degli altri".
              </p>

              <hr className="line-green" />

              <p>
                <b>Grazie a tutti i Corsisti dell'Associazione Mnemosine</b> è
                stato possibile donare un mezzo che consente alla squadra dei
                "Leoni Sicani" di partecipare alle trasferte del campionato
                italiano di "Wheelchair Hockey" (l'hockey su carrozzina)!
              </p>

              <Col className="box-text-center mt-4 mb-4">
                <img
                  alt="leoni_sicani_1"
                  src="/img/pages/leoni_sicani_1.jpg"
                  title="leoni_sicani_1"
                />
              </Col>

              <p>
                L'Associazione "A. S. D. LEONI SICANI - ONLUS" ha per oggetto
                l'esercizio e la promozione di attività sportive, culturali e
                ricreative per i portatori di handicap, con particolare
                riferimento alla disciplina del Wheelchair Hockey. L'hockey su
                carrozzina elettrica, nasce grazie ad un gruppo di giovani
                affetti da distrofia muscolare che, seguendo già da tempo alcuni
                loro amici disabili impegnati in altre discipline, volevano
                rendersi anche loro protagonisti di uno sport che fosse adatto
                alle loro particolari esigenze.
              </p>
              <p>
                Aiutaci a sostenere le nostre attività, fai una donazione ai
                Leoni Sicani, Codice Iban: IT18H0879683140000030300834.
              </p>
              <p>
                Oppure, destina il tuo 5x1000 alla squadra, inserisci il codice
                fiscale "92020760846″ nel riquadro "Sostegno del volontariato e
                delle altre organizzazioni non lucrative di utilità sociale,
                delle associazioni di promozione sociale e delle associazioni e
                fondazioni riconosciute che operano nei settori di cui all'Art.
                10, C.1, Lett. A, del D.Lgs. N. 460 del 1997". A te non costa
                nulla, per noi è un aiuto importante! I Leoni Sicani sono anche
                su facebook! Diventa membro del gruppo "A. S. D. Leoni Sicani -
                Onlus" e sarai aggiornato su tutte le attività della squadra!
              </p> */}
              <Swiper
                style={{ marginTop: "40px" }}
                navigation
                pagination={{
                  clickable: true,
                }}
              >
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/1.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/2.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/3.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/4.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/5.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/6.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src="/img/leoni-sicani/7.jpeg"
                    title="Leoni Sicani"
                    alt="Leoni Sicani"
                  />
                </SwiperSlide>
              </Swiper>
              {/* <section id="cinquepermille" style={{ marginTop: "40px" }}>
                <a
                  href="https://www.leonisicani.it/dona-il-tuo-5x1000/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/img/leoni-sicani/cinquepermille.jpeg"
                    alt="dona il tuo ciqnue per mille ai leoni sicani"
                  />
                </a>
              </section> */}
              <section id="cinquepermille_leoni">
                <h2 className="cinquepermille_leoni_title">
                  Dona il tuo 5×1000 ai Leoni Sicani <br /> CF 92020760846
                </h2>
                <br />
                <p className="cinquepermille_leoni_description">
                  Metti una firma nella tua dichiarazione dei redditi per i
                  LEONI SICANI e aiutaci a sviluppare e diffondere attività
                  solidali e di inclusione per persone diversamente abili
                </p>
                <div className="cinquepermille_leoni_image">
                  <img
                    src="/img/leoni-sicani/immagine-scheda-compilazione-5x1000-1.jpeg"
                    alt="modello cinque per mille"
                  />
                </div>
                <div className="cinquepermille_leoni_info">
                  <h3 className="cinquepermille_leoni_info_title">
                    Presentando il modello 730 o dei redditi
                  </h3>
                  <ul>
                    <li>Compila il modulo del modello 730 o redditi</li>
                    <li>
                      Nella sezione “SCELTA PER LA DESTINAZIONE DEL CINQUE PER
                      MILLE DELL’IRPEF” firma nella sezione del riquadro
                      “SOSTEGNO DEL VOLONTARIATO…” o “SOSTEGNO DI ORGANIZZAZIONE
                      NON LUCRATIVE E DI UTILITA’ SOCIALE…”
                    </li>
                    <li>
                      Specifica il codice fiscale dei Leoni Sicani Onlus:
                      92020760846{" "}
                    </li>
                  </ul>
                  <h3 className="cinquepermille_leoni_info_title">
                    Per donare il tuo 5x1000 anche se sei esonerato dalla
                    presentazione della dichiarazione dei redditi
                  </h3>
                  <ul>
                    <li>Compila la scheda CU (Certificazione Unica)</li>
                    <li>
                      Firma nella sezione del riquadro “SOSTEGNO DEL
                      VOLONTARIATO…”
                    </li>
                    <li>
                      Specifica il codice fiscale dei Leoni Sicani Onlus:
                      92020760846
                    </li>
                  </ul>
                </div>
              </section>
            </Col>
          </Row>
        </MSection>
      </>
    );
  }
}
