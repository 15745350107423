import React from "react";

import {  
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,   
} from "libs";

export class EnrollFillFormCVL extends ValidatedForm {

    PAYLOADS = {
        examSites: [
            { label: 'selezionare', value: '' },
            { label: 'Agrigento', value: 'Agrigento' },
            { label: 'Alessandria', value: 'Alessandria' },
            { label: 'Ancona', value: 'Ancona' },
            { label: 'Andria', value: 'Andria' },
            { label: 'Aosta', value: 'Aosta' },
            { label: 'Arezzo', value: 'Arezzo' },
            { label: 'Avellino', value: 'Avellino' },
            { label: 'Bari', value: 'Bari' },
            { label: 'Bergamo', value: 'Bergamo' },
            { label: 'Bologna', value: 'Bologna' },
            { label: 'Brescia', value: 'Brescia' },
            { label: 'Cagliari', value: 'Cagliari' },
            { label: 'Caltanissetta', value: 'Caltanissetta' },
            { label: 'Campobasso', value: 'Campobasso' },
            { label: 'Caserta', value: 'Caserta' },
            { label: 'Castelvetrano (TP)', value: 'Castelvetrano (TP)' },
            { label: 'Catania', value: 'Catania' },
            { label: 'Catanzaro', value: 'Catanzaro' },
            { label: 'Como', value: 'Como' },
            { label: 'Cosenza', value: 'Cosenza' },
            { label: 'Cuneo', value: 'Cuneo' },
            { label: 'Ferrara', value: 'Ferrara' },
            { label: 'Firenze', value: 'Firenze' },
            { label: 'Foggia', value: 'Foggia' },
            { label: 'Forlì', value: 'Forlì' },
            { label: 'Frosinone', value: 'Frosinone' },
            { label: 'Genova', value: 'Genova' },
            { label: 'Grosseto', value: 'Grosseto' },
            { label: 'Imperia', value: 'Imperia' },
            { label: 'L\'Aquila', value: 'L\'Aquila' },
            { label: 'La Spezia', value: 'La Spezia' },
            { label: 'Latina', value: 'Latina' },
            { label: 'Lecce', value: 'Lecce' },
            { label: 'Livorno', value: 'Livorno' },
            { label: 'Mantova', value: 'Mantova' },
            { label: 'Matera', value: 'Matera' },
            { label: 'Messina', value: 'Messina' },
            { label: 'Milano', value: 'Milano' },
            { label: 'Modena', value: 'Modena' },
            { label: 'Napoli', value: 'Napoli' },
            { label: 'Novara', value: 'Novara' },
            { label: 'Nuoro', value: 'Nuoro' },
            { label: 'Oristano', value: 'Oristano' },
            { label: 'Padova', value: 'Padova' },
            { label: 'Palermo', value: 'Palermo' },
            { label: 'Parma', value: 'Parma' },
            { label: 'Pavia', value: 'Pavia' },
            { label: 'Perugia', value: 'Perugia' },
            { label: 'Pescara', value: 'Pescara' },
            { label: 'Piacenza', value: 'Piacenza' },
            { label: 'Pisa', value: 'Pisa' },
            { label: 'Potenza', value: 'Potenza' },
            { label: 'Prato', value: 'Prato' },
            { label: 'Ravenna', value: 'Ravenna' },
            { label: 'Reggio Calabria', value: 'Reggio Calabria' },
            { label: 'Reggio Emilia', value: 'Reggio Emilia' },
            { label: 'Rimini', value: 'Rimini' },
            { label: 'Roma', value: 'Roma' },
            { label: 'Salerno', value: 'Salerno' },
            { label: 'Sassari', value: 'Sassari' },
            { label: 'Siracusa', value: 'Siracusa' },
            { label: 'Taranto', value: 'Taranto' },
            { label: 'Terni', value: 'Terni' },
            { label: 'Torino', value: 'Torino' },
            { label: 'Trento', value: 'Trento' },
            { label: 'Treviso', value: 'Treviso' },
            { label: 'Trieste', value: 'Trieste' },
            { label: 'Ucria (ME)', value: 'Ucria (ME)' },
            { label: 'Udine', value: 'Udine' },
            { label: 'Varese', value: 'Varese' },
            { label: 'Venezia', value: 'Venezia' },
            { label: 'Verona', value: 'Verona' },
            { label: 'Vibo Valentia', value: 'Vibo Valentia' },
            { label: 'Vicenza', value: 'Vicenza' },
            { label: 'Viterbo', value: 'Viterbo' },
            { label: 'Vittoria/Ragusa', value: 'Vittoria/Ragusa' },
        ]
    }

    FIELDS_GROUP = [
        [
            {
                field: "examSite",
                label: 'Scelta della sede per le lezioni in presenza',
                payload: { options: this.PAYLOADS.examSites },
                component: MSelect,
                className: "col-md-6"
            }
        ]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una città',      
    };

    validation = {
        examSite: value => value > '',
    };

    emptyFields = {
        examSite: '',
    };

    state = {
        loading: false,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // nothing to do
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);
        const validations = {
            examSite: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            {code}
        </>);
    }
}