import React, { Component } from "react";

import { DemoAgenda } from '../../components/demo';
import { BoxSuccess, MSection, Title } from "components/misc";

import {
  Container
} from 'design-react-kit';

export class Agenda extends Component {

  render() {
    return (
      <>
        <Container>
          <Title>Agenda [DEMO]</Title>
          <MSection>
            <BoxSuccess>DEMO: Timeline degli eventi più importanti passati e futuri</BoxSuccess>
            <DemoAgenda></DemoAgenda>
          </MSection>
        </Container>

      </>
    );
  }
}
