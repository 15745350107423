import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';


export class PromoTablet extends Component {

    render() {
        const title = 'OMAGGIO TABLET - RICHIEDI GRATUITAMENTE IL TUO TABLET!!!';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <MSection>
                    <Row>
                        <Col>
                            <Title >{title}</Title>
                            <hr />
                            <p>
                                L'Università, attraverso l'Associazione Mnemosine - Ente Accreditato MIUR - con OLTRE 16 anni di esperienza Nazionale ed
                                Internazionale regolarmente certificata, è lieta di omaggiarle un Tablet.</p>
                            <p>Il tutto è possibile solo se il costo del Corso Universitario scelto tra Master e Perfezionamento,
                                al quale sta presentando domanda di iscrizione, è superiore a € 350, ad esclusione della II Annualità
                                del Diploma di Perfezionamento Biennale Post - Laurea, del Percorso di formazione di 5 CFU "DM 108/2022", dei Corsi Singoli Universitari e dei Corsi Abilitanti 30 CFU.</p>
                            <p>Basta inviare OBBLIGATORIAMENTE E CONTESTUALMENTE all' iscrizione online
                                il <a href="/files/promozioni/modulo_richiesta_tablet.pdf">Modulo Richiesta Tablet</a> compilato e firmato all' indirizzo di posta elettronica <a href="mailto:comunicazioni@formazionedocenti.it"> comunicazioni@formazionedocenti.it </a>.</p>
                            <p>Assolutamente non saranno presi in considerazione i Moduli di Richiesta Tablet pervenuti NON contestualmente all' iscrizione al Corso. Tale omaggio verrà erogato durante
                                l'Anno Accademico <b>2024/2025</b> e solo dopo il saldo del corso per il quale ha prodotto domanda di iscrizione!</p>
                            <p>La presente iniziativa non è cumulabile con altre iniziative commerciali e
                                nel campo codice riservato presente durante la procedura di iscrizione online dovrà inserire il presente codice RICTABLET.</p>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}
