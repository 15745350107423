import React, { Component }  from "react";
import { Form60Cfu } from "./form60cfu";

export class Form60cfuEmbedded extends Component {

    render() {
       
        return (
            <>
                
                <Form60Cfu />
            </>
        );
    }
}
