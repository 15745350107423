import { ROUTES } from "const";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./NavLibrary.scss";

export class NavLibrary extends Component {
  render() {
    return (
      <div className="navLibrary">
        <div className="navLibrary__wrapper">
          <div className="menuLibrary">
            <Link
              className="menuLibrary__logo"
              to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}` }}
            >
              <img
                src="/img/biblioteca/book.svg"
                width="60"
                height="60"
                alt="logo mnemosine"
              />
            </Link>
            <ul className="menuLibrary__items">
              <li className="menuLibrary__item">
                <Link to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}` }}>
                  Biblioteca
                </Link>
              </li>
              <li className="menuLibrary__item">
                <Link to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}/categorie/` }}>
                  Categorie
                </Link>
              </li>
              <li className="menuLibrary__item">
                <Link to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}/libri/` }}>
                  Libri
                </Link>
              </li>
              <li className="menuLibrary__item">
                <Link to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}/autori/` }}>
                  Autori
                </Link>
              </li>
              <li className="menuLibrary__item">
                <Link to={{ pathname: `${ROUTES.PAGE_BIBLIOTECA}/wishlist/` }}>
                  Libri preferiti
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
