import {proxyBE} from './common';

export const toolService = {

    siteConfig(code) {
        return proxyBE.get(`siteConfig/config/${code}`);
    }, 
    
    isMaintenance() {
        return proxyBE.get('siteConfig/isMaintenance');
    }, 
}