import React, { Component } from "react";

// Button primary
import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";
import "./sliderHome.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
SwiperCore.use([Pagination]);
SwiperCore.use([Autoplay]);

// Import Swiper styles
// import 'swiper/css';

const SLIDER_HOME = [
  {
    image: "/img/slideshow/new/desktop/sedi.jpeg",
    image_mobile: "/img/slideshow/new/mobile/sedi-mobile.jpeg",
    image_alt: "Iscrizione interamente online",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title: "Più di 100 sedi d'esame. <br/>Scegli TU la tua",
    subtitle: "E con iscrizioni DAVVERO on line",
    cta_link: "/sedi-esami",
    cta_text: "Scoprile qui",
  },
  // {
  //   image: "/img/slideshow/new/desktop/1.jpg",
  //   image_mobile: "/img/slideshow/new/mobile/1.jpg",
  //   image_alt: "Iscrizione interamente online",
  //   logo_image: "",
  //   logo_image_alt: "",
  //   label: "",
  //   title: "Iscrizione interamente online",
  //   subtitle: "senza alcun invio cartaceo",
  //   cta_link: "",
  //   cta_text: "",
  // },
  {
    image: "/img/slideshow/new/desktop/2.jpg",
    image_mobile: "/img/slideshow/new/mobile/2.jpg",
    image_alt: "Corsi singoli e master",
    logo_image: "/img/slideshow/new/unicamillus.png",
    logo_image_alt: "logo unicamillus",
    label: "",
    title: "Corsi singoli e master",
    subtitle: "",
    cta_link: "/corsi-mnemosine/master-universitari",
    cta_text: "Iscriviti",
  },
  /*{
    image: "/img/slideshow/new/desktop/3.jpg",
    image_mobile: "/img/slideshow/new/mobile/3.jpg",
    image_alt: "Esami validi in tutta Italia",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title:
      "Scegli fra più di 100 sedi in tutta Italia quella che preferisci e sostieni il tuo esame riconosciuto dal MIUR",
    subtitle: "",
    cta_link: "",
    cta_text: "Iscriviti",
  },
  {
    image: "/img/slideshow/new/desktop/banner-corsi-di-laurea.jpg",
    image_mobile: "/img/slideshow/new/mobile/banner-corsi-di-laurea-mobile.jpg",
    image_alt: "Corsi di Laurea Mnemosine",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title: "Lauree VERE per un futuro lavorativo REALE",
    subtitle: "da Scienze Infermieristiche a Medicina!",
    cta_link: "/corsi-di-laurea",
    cta_text: "Scopri i Corsi di Laurea",
  },*/
  {
    image: "/img/slideshow/new/desktop/4.jpg",
    image_mobile: "/img/slideshow/new/mobile/4.jpg",
    image_alt: "dottorati di ricerca all'estero",
    logo_image: "",
    logo_image_alt: "",
    label: "Adesioni aperte",
    title: "Leader dei dottorati di ricerca all'estero",
    subtitle: "Accesso alla carriera universitaria",
    cta_link: "/dottorati-di-ricerca",
    cta_text: "Iscriviti",
  },
  {
    image: "/img/slideshow/new/desktop/5.jpg",
    image_mobile: "/img/slideshow/new/mobile/5.jpg",
    image_alt: "Certificazioni linguistiche",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title: "Certificazioni linguistiche",
    subtitle: "Lingua inglese",
    cta_link: "/corsi-mnemosine/ENG",
    cta_text: "Iscriviti",
  },
  {
    image: "/img/slideshow/new/desktop/6.jpg",
    image_mobile: "/img/slideshow/new/mobile/6.jpg",
    image_alt: "Certificazioni informatiche",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title: "Certificazioni informatiche",
    subtitle: "da 50€",
    cta_link: "/corsi-mnemosine/INFO",
    cta_text: "Iscriviti",
  },
  {
    image: "/img/slideshow/new/desktop/banner-ucsu.jpeg",
    image_mobile: "/img/slideshow/new/mobile/banner-ucsu-mobile.jpeg",
    image_alt: "Corsi Singoli Universitari",
    logo_image: "/img/slideshow/new/unicamillus.png",
    logo_image_alt: "logo unicamillus",
    label: "",
    title: "Corsi Singoli Universitari",
    subtitle: "",
    cta_link: "/corsi-mnemosine/UCSU",
    cta_text: "Iscriviti",
  },
  /*{
    image: "/img/slideshow/new/desktop/7.jpg",
    image_mobile: "/img/slideshow/new/mobile/7.jpg",
    image_alt: "formazione docenti",
    logo_image: "",
    logo_image_alt: "",
    label: "",
    title:
      "Dal 2005 leader del settore della formazione dei docenti e del personale scolastico",
    subtitle: "con oltre 200.000 iscritti",
    cta_link: "",
    cta_text: "",
  },*/
];

export class SliderHome extends Component {
  render() {
    // image
    const getImage = (image, imageMobile, alt) => {
      if (image && imageMobile && alt) {
        return (
          <div className="slidesHome__image">
            <img
              className="image__desktop"
              src={image}
              alt={alt}
              width="1280"
              height="520"
            />
            <img
              className="image__mobile"
              src={imageMobile}
              alt={alt}
              width="400"
              height="600"
            />
          </div>
        );
      }
    };

    //logo image
    const getLogoImage = (logo, logoAlt) => {
      if (logo) {
        return (
          <div className="slidesHome__content__logo">
            <img src={logo} alt={logoAlt} />
          </div>
        );
      }
    };

    // label
    const getLabel = (label) => {
      if (label) {
        return (
          <div className="slidesHome__content__label">
            <p className="slide__label">{label}</p>
          </div>
        );
      }
    };

    // title
    const getTitle = (title) => {
      if (title) {
        return (
          <div className="slidesHome__content__title">
            {/* <h2 className="slide__title">{title}</h2> */}
            <h2
              className="slide__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
        );
      }
    };

    // subtitle
    const getSubtitle = (subtitle) => {
      if (subtitle) {
        return (
          <div className="slidesHome__content__subtitle">
            <p className="slide__subtitle">{subtitle}</p>
          </div>
        );
      }
    };

    // cta
    const getCta = (ctaLink, ctaText) => {
      if (ctaLink && ctaText) {
        return <ButtonPrimary color="yellow" label={ctaText} href={ctaLink} />;
      }
    };

    return (
      <div className="slidesHome">
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{ delay: 3000 }}
        >
          {SLIDER_HOME.map((item, index) => {
            return (
              <SwiperSlide key={`slide-home-${index}`}>
                <div className="slidesHome__wrapper">
                  {getImage(item.image, item.image_mobile, item.image_alt)}
                  <div className="slidesHome__content">
                    {getLogoImage(item.logo_image, item.logo_image_alt)}
                    {getLabel(item.label)}
                    {getTitle(item.title)}
                    {getSubtitle(item.subtitle)}
                    {getCta(item.cta_link, item.cta_text)}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }
}
