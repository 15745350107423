import React, { Component } from "react";
import { Title, ModalHandler, MnemoLoading, BoxDanger } from "components/misc";
import { Container, Row, Col, Button } from 'design-react-kit';

import { AdminCourseFormQuiz, 
         AdminCourseFormQuizQuestion,
         AdminCourseFormQuizMetadata
} from "components/forms/admin";

import { NavLink, withRouter } from 'react-router-dom';
import { ROUTES } from 'const';

import { MInput } from "components/forms";

import {
  networkErrorHelper,
  adminService
} from "libs";

class QuizForm extends Component {

  constructor(props) {
    super(props);
    this.child = React.createRef();

    this.state = {
      lengthQuestion: 0,
      idResource: parseInt(this.props.match.params.idresource) > 0 ? this.props.match.params.idresource : 0,
      accordionKey: 0,
      quizFormSubmit: '',
      loading: false,
      errorXmlImport: false
    }
  }

  //--- ---

  importQuizFromXML = (value = '') =>{
    const quizFormAttr = {
        label: 'digita URL',
        className: "mt-1 mb-3"
    }
    const code  = <>
                    <MInput {...quizFormAttr} onChange={this.quizFormSubmit}/>
                    <div className="small text-info text-justify"><b>ATTENZIONE:</b> importando un Quiz saranno eliminate definitivamente tutte le attuali informazioni.
                         Vuoi davvero importare e sovrascrivere le informazioni correnti?</div>
                  </>;

    ModalHandler.show(null, 'Importazione Quiz da file XML', code, null, this.importConfirmed);
  }

  quizFormSubmit = (_, value) =>{
      this.setState({
        quizFormSubmit: value
      })
  }//quizFormSubmit

  retryImportQuizFormXML = () =>{
    setTimeout(()=>{
      this.importQuizFromXML()
    }, 225)
  }

  importConfirmed = () =>{
    this.setState({loading:true})

    const res = (this.state.quizFormSubmit).match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);

    if(res === null){
      setTimeout(() => {
        const code  = <> <div className="small text-info text-justify">Formato ULR non valido. Riprovare con un URL valido.</div> </>;
        ModalHandler.show(this.state.quizFormSubmit, 'Importazione Quiz da file XML', code, null, this.retryImportQuizFormXML);        
      }, 225);

      this.setState({
        quizFormSubmit: '',
        loading:false
      })

    }else{

        adminService.quizByIDParent(this.state.idResource).then(({ data }) => {
          if(parseInt(data.payload.quiz.id)>0){

            const payload ={
              "url": this.state.quizFormSubmit,
              "idQuiz": data.payload.quiz.id
            }

            adminService.importQuizFromMoodle(payload).then(({ data }) => {
              if(data.payload.ret){
                this.setState({ loading:false })
              }
            })
            .catch(error => {
                console.error(error);
                if (networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }
                this.setState({
                  errorXmlImport: true,
                  loading:false
                })
      
                setTimeout(() => {
                  this.setState({ errorXmlImport: false })
                }, 7000);
            });

          }
      })
    }

    /*
    
      */

  }//res validate URL
  //--- ---

  addQuestion = (answerType) =>{
    this.child.current.addQuestion(answerType); //use ref function from child
  }

  reloadCounter = (length) =>{
    this.setState({
      lengthQuestion: length
    })
  }

  reloadAccordion = () =>{
    this.setState({
      accordionKey: Math.floor((Math.random() * 1000) + 1)
    })
  }

  //--- ---

  render() {

    if(this.state.loading){ return (<MnemoLoading></MnemoLoading>)}

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>
            <Row>
                <Col lg={{ size: 6 }}>Gestione guidata Quiz</Col>
                <Col lg={{ size: 6 }} className="text-right">
                  <NavLink className="btn btn-primary bg-dark" to={ROUTES.COURSE_FORM + '/' + this.props.match.params.idcourse}>torna al Corso</NavLink>
                </Col>
            </Row>
            </Title>

            <AdminCourseFormQuizMetadata idResource={this.state.idResource} />
            
            <div className="col-lg-12 col-md-12 my-3">
              <h6>{(this.state.lengthQuestion === 0) ? 'PRIMA DOMANDA?' : 'AGGIUNGI UN ALTRA DOMANDA'} (seleziona il tipo di riposta attesa)</h6>
            </div>

            <Row className="header-quiz-composer border border-top-0 border-right-0 border-left-0 border-primary rounded pb-4 mx-0 p-0 bg-white">
              <Col className="col-10 text-left">
                  <Button id="radio" color="primary bg-dark m-1" onClick={(e) => { this.addQuestion(e.target.id) }}>Singola</Button>
                  <Button id="check" color="primary bg-dark m-1" onClick={(e) => { this.addQuestion(e.target.id) }}>Multipla</Button>
                  <Button id="text" color="primary bg-dark m-1" onClick={(e) => { this.addQuestion(e.target.id) }}>Aperta</Button>
                  <Button id="fill" color="primary bg-dark m-1" onClick={(e) => { this.addQuestion(e.target.id) }}>Completamento</Button>
              </Col>
              
              <Col className="col-2 text-left">
                  {/*<Button id="radio" color="primary bg-dark m-1" onClick={() => { this.importQuizFromXML() }}>Importa da XML</Button>*/}
              </Col>
            </Row>

            {(this.state.errorXmlImport) && <BoxDanger className="mt-3 mb-2">Errore Importazione Quiz da File XML</BoxDanger>}

            <AdminCourseFormQuizQuestion key={`acfqq-1`} model="Course" ref={this.child} idResource={this.state.idResource} reloadAccordion={this.reloadAccordion} formActive={true} />
            <AdminCourseFormQuiz key={this.state.accordionKey} model="Course" uriParams={this.props.match.params} reloadCounter={this.reloadCounter} />

          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(QuizForm);
