import React, { Component } from 'react';

import { MSection, Title } from 'components/misc';
import {
    Row, Col
} from 'design-react-kit';
import { Helmet } from 'react-helmet';

export class PageRule extends Component {


    render() {
        const title = 'Regolamento Associazione';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <MSection className="noSelect">
                    <Row>
                        <Col>
                            <p>Art. 1 - Istituzione : L'Associazione Mnemosine è stata costituita nel 2005. Dal 2010 con Direttiva Ministeriale n. 90 del 01/12/2003 è Ente Accreditato MIUR per la formazione del Personale Scolastico; oggi Accreditata ai sensi della Direttiva Ministeriale 170/2016.</p>
                            <p>Art. 2 - Finalità : L'Associazione si propone di :</p>
                            <ul>
                                <li>svolgere attività di formazione professionale e/o orientamento e/o aggiornamento nei settori socio-assistenziale, giuridico, economico-finanziario, culturale e dell'istruzione , agricolo, forestale ed ambientale, industriale, commerciale, artigianale, turistico e dello spettacolo, informatico, sanitario e dei servizi;</li>
                                <li>Per attività di formazione professionale si intendono gli interventi di prequalificazione, qualificazione, riqualificazione, aggiornamento realizzati con sistemi che utilizzano metodologie in presenza e a distanza destinata a utenti residenti sia su territorio Italiano che di altra Nazione Mondiale;</li>
                                <li>Per attività di orientamento si intendono gli interventi di carattere informativo, formativo, consulenziale, finalizzati a promuovere l'auto-orientamento e a supportare la definizione di percorsi personali di formazione e lavoro e il sostegno all'inserimento occupazionale;</li>
                                <li>realizzare, nell'ambito dei settori di cui alla lettera a), attività di studio e di ricerca scientifica, di divulgazione e pubblicazione, sia cartacea che su supporto informatico;</li>
                                <li>coadiuvare le autorità legislative od amministrative, anche mediante attività propositiva, nell'elaborazione di norme destinate a regolamentare i settori di cui alla lettera a);</li>
                                <li>collaborare e stipulare accordi con altre associazioni, sia italiane che estere;</li>
                                <li>promuovere convegni, conferenze, seminari ed ogni altra iniziative possibile;</li>
                                <li>progettare, istituire e gestire corsi di qualificazione e riqualificazione destinati a giovani, disoccupati, inoccupati, lavoratori, che, essendone sprovvisti, vogliano acquisire una qualifica professionale o vogliano riqualificarsi;</li>
                                <li>corsi di specializzazione;</li>
                                <li>master;</li>
                                <li>corsi di aggiornamento e perfezionamento;</li>
                                <li>corsi di formazione continua e permanente;</li>
                                <li>centri di elaborazione dati;</li>
                                <li>corsi di alta formazione;</li>
                                <li>attività di informazione, orientamento e formazione orientativa mirata;</li>
                                <li>corsi speciali per disoccupati;</li>
                                <li>corsi di orientamento, formazione e rieducazione professionale per mutilati, invalidi e portatori di handicap in genere;</li>
                                <li>corsi di orientamento, formazione e riqualificazione per detenuti e disadattati sociali;</li>
                                <li>corsi di orientamento, formazione e riqualificazione per tossicodipendenti;</li>
                                <li>corsi per il conseguimento di particolari patenti, licenze e abilitazioni;</li>
                                <li>corsi per apprendisti;</li>
                                <li>cantieri scuola per lavoratori inoccupati e disoccupati;</li>
                                <li>assistenza e corsi di preparazione per il conseguimento di diplomi e lauree;</li>
                                <li>corsi di preparazione per il conseguimento di maturità e di diplomi di scuola media superiore, anche a distanza;</li>
                                <li>gestione di scuole private di ogni ordine e grado anche a distanza;</li>
                                <li>corsi di formazione a distanza con l'ausilio di mezzi di telecomunicazione e informatici;</li>
                                <li>corsi sperimentali di ogni genere e tipo comunque connessi con le finalità dell'associazione;</li>
                                <li>corsi polivalenti per insegnati di sostegni nella scuola pubblica e privata, sperimentali, incontri di studio e seminari in collaborazione e partnership con scuole pubbliche e private, università degli studi, associazioni, enti e imprese;</li>
                                <li>corsi di orientamento, qualificazione, riqualificazione e per il conseguimento di particolari patenti e/o licenze per disoccupati, inoccupati, lavoratori e imprenditori nel settore dell'agricoltura e del comparto agroalimentare;</li>
                                <li>nursery e consulenze per transizioni al lavoro o avvio di attività imprenditoriali;</li>
                                <li>corsi di lingue, di educazione civica, legislazione sociale, orientamento professionale, qualificazione e riqualificazione professionale e quant'altro sia ritenuto utile per l'inserimento nel tessuto sociale e nel mondo del lavoro degli immigrati comunitari ed extracomunitari e degli emigrati italiani di ritorno;</li>
                                <li>centri di informazione e orientamento volti a rispondere alle necessità degli individui di compiere scelte professionali documentate e consapevoli in relazione alle attitudini ed aspirazioni personali ed alle concrete possibilità occupazionali;</li>
                                <li>uffici per l'integrazione dei soggetti deboli per l'erogazione di azioni di progettazione, coordinamento e sviluppo di interventi volti alla socializzazione e all'inserimento formativo e lavorativo dei soggetti disabili e svantaggiati;</li>
                                <li>centri di risorse multimediali che progettino percorsi e moduli di formazione a distanza, forniscano supporti informatici applicati alla didattica, elaborino pacchetti di formazione a distanza, programmi gestionali, risorse informatiche per la realizzazione di CFP e di attività formative e di sviluppo culturale e professionale operanti nel territorio;</li>
                                <li>uffici per la valutazione e il monitoraggio delle azioni formative per l'attivazione di strumenti idonei a monitorare la qualità del processo formativo e del prodotto della formazione;</li>
                                <li>laboratori per l'integrazione tra sistema di formazione e sistema scolastico con il compito di favorire il processo di integrazione tra il sistema di formazione professionale regionale e il sistema scolastico; di predisporre moduli formativi integrati che consentano l'acquisizione di competenza di base trasversali, comuni a più profili professionali;</li>
                                <li>scuole, doposcuola, circoli ricreativi, biblioteche anche telematiche e di quant'altro utile e necessario per assistere i lavoratori, i disoccupati, gli inoccupati, gli emigrati di ritorno, gli immigrati, gli imprenditori e le loro famiglie.</li>
                            </ul>
                            <p>Art. 3 - Mezzi : L'Associazione, per conseguire le precedenti finalità promuove, organizza, istituisce e gestisce, stabilmente o temporaneamente, corsi di formazione professionale e/o di orientamento, attività di studio e di ricerca scientifica, di divulgazione e pubblicazione, sia cartacea che su supporto informatico, convegni, conferenze, seminari ed ogni altra utile iniziativa. L'Associazione potrà dare la sua collaborazione ad altri enti per lo sviluppo di iniziative conformi alle sue finalità. L'Associazione può anche svolgere altre attività purché direttamente connesse ed accessorie a quelle sopra indicate e comunque non difformi all'oggetto sociale. L'Associazione può promuovere ogni iniziativa pertinente ed utile al raggiungimento delle proprie finalità. La stessa potrà presentare progetti; richiedere contributi; partecipare ad appalti, gare, trattative private; stipulare convenzioni e/o concludere accordi con gli Enti locali e con le Regioni; stipulare convenzioni e/o concludere accordi con Enti morali pubblici e privati, Enti di sviluppo, Enti non commerciali, Organizzazioni Non Lucrative di Utilità Sociale, consorzi, professionisti e/o gruppi professionali e loro associazioni, centri di ricerca ed Università, sia in Italia che all'estero. Per lo svolgimento di tutte le sue attività l'Associazione può assumere personale dipendente o avvalersi di prestazioni di lavoro autonomo nei limiti necessari al regolare funzionamento o per qualificare o specializzare l'attività svolta nel rispetto delle finalità statutarie.</p>
                            <p>Art. 4 - Collaborazioni con Università ed Enti Nazionali ed Internazionali :</p>
                            <ul>
                                <li>Università del Salento;</li>
                                <li>Università per stranieri Dante Alighieri di Reggio Calabria;</li>
                                <li>Accademia di Belle Arti di Agrigento;</li>
                                <li>Università Statale di Granada (Spagna);</li>
                                <li>Università Statale di Cordoba (Spagna);</li>
                                <li>Università Statale di Valencia (Spagna);</li>
                                <li>Università Cattolica di Valencia (Spagna);</li>
                                <li>Università Cattolica di Murcia (Spagna);</li>
                                <li>Università Statale di Almeria (Spagna);</li>
                                <li>Università Statale di Huelva (Spagna);</li>
                                <li>Università Statale di Murcia (Spagna);</li>                                
                                <li>Università Normale di Shenyang (Cina);</li>
                                <li>Henan  Agricultural  University (Cina);</li>
                                <li>Università di Zheng Zhou (Cina);</li>
                                <li>Università tecnologia elettronica di Guilin (Cina)</li>
                                <li>AICA - Associazione Italiana per l'Informatica ed il Calcolo Automatico;</li>
                                <li>Microsoft Italia;</li>
                                <li>LCCI - London Chamber of Commerce and Industry;</li>
                                <li>ESB - English Speaking Board.</li>
                            </ul>
                            <p>Art. 5 - Corsi : Promozione, Gestione e Sviluppo di:</p>
                            <ul>
                                <li>Oltre 200 tra Master di I livello, Master di II livello, Diplomi di Perfezionamento Annuali e Diplomi di Specializzazione Biennale;</li>
                                <li>Corsi di Formazione ed aggiornamento del Personale Scolastico validi per la formazione continua Docenti;</li>
                                <li>Corsi di Preparazione al Concorso per Dirigenti Scolastici erogato in qualità di Ente di Formazione;</li>
                                <li>Certificazioni Informatiche Nuova ECDL - ECDL Livello Specialised con Ente Certificatore: AICA (Associazione Italiana per l'Informatica ed il Calcolo Automatico);</li>
                                <li>Corso LIM (Lavagna Interattiva Multimediale) di 100 ore erogato in qualità di Ente Accreditato presso il Ministero Istruzione Università e Ricerca per la formazione del personale scolastico e accreditamento presso AICA - Associazione Italiana per l'Informatica ed il Calcolo Automatico (AICA);</li>
                                <li>Corsi di Informatica per il conseguimento delle varie Certificazioni Internazionali proposte da AICA;</li>
                                <li>Corsi Innovativi di Preparazione alle prove di accesso al Percorso di Specializzazione per le attività di Sostegno previsto per la scuola Primaria e Infanzia nonché per la Scuola Secondaria di I e II grado erogati in qualità di Ente di Formazione. Corsi Innovativi fruibili in Modalità FAD con l'utilizzo del Webinar e del Question Time;</li>
                                <li> Corsi finalizzati al conseguimento della Certificazione delle competenze previste dal "Quadro comune europeo di riferimento per le lingue" adottato nel 1996 dal Consiglio d'Europa Livelli B1, B2, C1 e C2 Lingua Inglese;</li>
                                <li>Corsi di Formazione Dottorale all'Estero (Ph.D., doctorat, doctorado, doutoramento) sulla base di accordi di collaborazione stipulati con Università Statali Spagnole;</li>
                                <li>Attività di Supporto alle procedure burocratiche e amministrative per l'attivazione di Corsi di Formazione Dottorale all'Estero (Ph.D., doctorat, doctorado, doutoramento) sulla base di accordi di collaborazione stipulati presso varie Università Statali della Spagna.</li>
                            </ul>
                            <p>Art. 6 - Attività sociali e convegni : Contributi volontari a: Unicef, Istituto Maria Immacolata, Ass. Meter contro la Pedo-pornografia, Associazione Leoni Sicani (disabili che praticano Hockey in carrozzina), Ist. Scolastico G. T. di Lampedusa per concorso “Sogno Americano”; Parco Letterario “G. tomasi di Lampedusa”; Associazione Libera; Associazione “Via Maqueda città”; “Revista de Estudios Interculturales” con l'Università Statale di Granada; “Asociación Internacional de Psicología Evolutiva y de la Infancia, Adolescencia, Mayores y Discapacidad” con l'Università Statale di Badajoz; “PAN” . Rivista di Filologia Latina.</p>
                            <p>Art. 7 - Contributi : L'Associazione Mnemosine non percepisce contributi Statali. Tutte le attività sono erogate in piena autonomia gestionale. Per tutti i corsi, i costi sono indicati nei Bandi di Partecipazione. Per tutte le iscrizioni relative ai Corsi Universitari a.a. 2018/2019, a.a. 2019/2020, a.a. 2020/2021, a.a. 2021/2022, a.a 2022/2023, a.a 2023/2024 e 2024/2025 in aggiunta ai contributi previsti nei vari Bandi di Partecipazione per tali Anni Accademici (a.a 2018/2019, a.a 2019/2020, a.a. 2020/2021, a.a. 2021/2022, a.a 2022/2023, a.a 2023/2024 e 2024/2025), per la consegna di un qualsiasi Certificato e/o Pergamena finale dei Corsi Universitari Annuali o Biennali è dovuto un contributo forfettario pari ad euro 50 secondo le indicazioni che verranno fornite al momento della richiesta. La procedura nonché i costi di spedizione per la richiesta della Pergamena finale tramite servizio postale, verranno comunicati successivamente. Parimenti per la Certificazione Finale dei “24 CFU” o “percorsi Formativi 24 CFU” valida ai sensi del DM 616/2017 sarà dovuto un contributo straordinario di euro 98 secondo le indicazioni che verranno fornite al momento della richiesta.</p>
                            <p>Art. 8 - Privacy : I dati personali forniti dai candidati saranno raccolti dall'Associazione Mnemosine e trattati per le attività procedurali correlate ai sensi e per gli effetti dell'art. 7 e ss. del Regolamento UE 2016/679, secondo le modalità e nei limiti di cui all'informativa presente al seguente indirizzo del sito web ufficiale: <a href="https://www.formazionedocenti.it/InformativaTrattamentoDatiPersonali/">https://www.formazionedocenti.it/informativatrattamentodatipersonali/</a></p>
                            <p>Art. 9 - Sedi :</p>
                            <ul>
                                <li>Regione Sicilia : Santa Margherita di Belice (AG) - Largo Monfalcone n. 15 CAP 92018  <a href="Tel:092533231">Tel:092533231</a> Fax:092532708;</li>
                                <li>Regione Lombardia : Milano - Piazza Firenze n. 14 - CAP 20154 -   <a href="Tel:092533231">Tel:092533231</a> Fax:0234532652;</li>
                                <li>Regione Lazio : Roma - Via Properzio n. 6 - 00193 Roma -  <a href="Tel:0670495308">Tel:0670495308</a> Fax 0677456950;</li>
                                <li>Regione Veneto : Padova - Via Andrea Costa n. 19 - CAP 35124 - <a href="Tel:049680148">Tel:049680148</a> Fax: 0498675003;</li>
                                <li>Regione Emilia Romagna : Bologna - Viale della Repubblica n.3/a - CAP 40127 - <a href="Tel:0516335062">Tel:0516335062</a> Fax:0516336563;</li>
                                <li>Regione Toscana : Firenze - Via di Novoli, 37 - Cap 50127 - <a href="Tel:055485899">Tel:055485899</a> Fax:0554626203.</li>
                            </ul>
                            <p>Sito Ufficiale</p>
                            <p>Associazione Mnemosine  - <a href="https://www.formazionedocenti.it">www.Formazionedocenti.it</a> - <a href="mailto:info@formazionedocenti.it">info@formazionedocenti.it</a></p>
                        </Col>
                    </Row>
                </MSection>
            </>);
    }
}