import * as React from "react"

const IconUser = (props) => (
  <svg
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    fill={props.fill ? props.fill : '#ffffff'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.44 40.804c2.812 0 5.444-.53 7.896-1.59a20.817 20.817 0 0 0 6.494-4.395 20.574 20.574 0 0 0 4.405-6.493c1.06-2.46 1.59-5.088 1.59-7.886 0-2.799-.53-5.428-1.59-7.887A20.574 20.574 0 0 0 34.83 6.06a20.785 20.785 0 0 0-6.504-4.395C25.866.605 23.238.075 20.44.075c-2.799 0-5.428.53-7.887 1.59A20.659 20.659 0 0 0 6.06 6.06a20.659 20.659 0 0 0-4.395 6.494C.605 15.011.075 17.64.075 20.44c0 2.798.53 5.427 1.59 7.886a20.658 20.658 0 0 0 4.395 6.493 20.658 20.658 0 0 0 6.494 4.396c2.458 1.06 5.087 1.59 7.886 1.59Zm13.73-7.51-.075-.131c-.48-.771-1.232-1.556-2.258-2.354-1.28-.998-2.888-1.836-4.82-2.515-1.933-.678-4.125-1.018-6.577-1.018-2.425 0-4.603.336-6.536 1.008-1.932.672-3.54 1.5-4.82 2.483-1.026.787-1.787 1.56-2.285 2.321l-.104.168-.05-.053a18.886 18.886 0 0 1-3.536-5.47c-.97-2.271-1.455-4.703-1.455-7.293 0-2.591.485-5.022 1.455-7.294A18.996 18.996 0 0 1 7.15 7.14a18.789 18.789 0 0 1 5.995-4.052c2.272-.97 4.703-1.454 7.294-1.454 2.604 0 5.039.484 7.304 1.454 2.265.97 4.263 2.32 5.995 4.052a18.91 18.91 0 0 1 4.052 6.006c.97 2.272 1.455 4.703 1.455 7.294 0 2.59-.485 5.021-1.455 7.293a18.885 18.885 0 0 1-3.536 5.47l-.085.09Zm-13.73-9.592c1.33 0 2.524-.343 3.584-1.029 1.06-.685 1.901-1.62 2.525-2.805.623-1.184.935-2.51.935-3.98 0-1.385-.315-2.656-.945-3.812a7.676 7.676 0 0 0-2.536-2.785c-1.06-.7-2.247-1.05-3.563-1.05-1.303 0-2.487.35-3.554 1.05a7.642 7.642 0 0 0-2.545 2.785c-.63 1.156-.939 2.427-.925 3.813 0 1.468.308 2.791.925 3.969.616 1.177 1.458 2.109 2.524 2.795 1.067.686 2.259 1.035 3.575 1.05Z"
      fill={props.fill ? props.fill : '#ffffff'}
    />
  </svg>
)

export default IconUser
