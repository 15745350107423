import React, { Component } from "react";
import { cache } from '../../libs';
import ReactHtmlParser from 'react-html-parser';
import i18n from "app/i18n";

class QuestionMultiFillFree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            answers: this.props.answers,
            choice: [],
            idQuiz: this.props.idQuiz,
            inputDefVal: '',
            hideOutComeAnswer: (this.props?.metadata && this.props.metadata.feedbackFinal?.hideOutComeAnswer === true) ? true : false,
            attemptTracker: (this.props.attemptTracker) ? this.props.attemptTracker : false
        
        };
    }


    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion, answer, index) =>{
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);

        if(getValue === null){ return '' }

        const res = getValue.filter((item)=>{ return item.answers.index === index && item.idQuestion === idQuestion && parseInt(item.answers.idAnswer) === parseInt(answer.id)});

        if(res.length > 0){
            let data = {
                idQuestion: idQuestion,
                answers: {
                    index,
                    idAnswer: res[0].answers.idAnswer,
                    label: res[0].answers.label
                }
            }
        
            //this.state.choice.push(data);
        }

        return (res.length === 0) ? '' : res[0].answers.label
    }//isChecked
    
    /** */
    setMyState = (idAnswer, idQuestion, label, idQuiz, index) => {

        let data = {
            idQuestion: idQuestion,
            answers: {
                index,
                idAnswer,
                label
            }
        }

        let result = false;
        
        const getIdx = this.state.choice.findIndex(x => x.answers.index === index);
        
        /*if(data.answers.label.length === 0){
            this.state.choice.splice(getIdx, 1);
            cache.set(`quiz-${idQuiz}-${idQuestion}`,this.state.choice.sort((a, b) => a.answers.index - b.answers.index));

            if(this.state.choice.length === 0){
                cache.forget(`quiz-${idQuiz}-${idQuestion}`);         
            }

            return true
        }*/

        //check if elemet exists on current array value
        const exists = this.state.choice.filter((item)=>{
            return (item.answers.index === index && item.answers.idAnswer === idAnswer && item.idQuestion === idQuestion)
        })
        if(exists.length > 0){
            this.state.choice.splice(getIdx, 1);
        }

        this.setState(state => {
            state.choice.push(data);
            cache.set(`quiz-${idQuiz}-${idQuestion}`,this.state.choice.sort((a, b) => a.answers.index - b.answers.index));
        })

        return result;
    }//setMyState

    /** */
    retRightRender = (idQuestion, idQuiz, resUserAnswer) => {

        const string = this.props.label;
        const subString = '{{answers}}';
        const words = string.replaceAll('<br />', '<br/>').split(' ');
        
        let index = 0;

        const stringReturn = words.map((word, idx) => {

            if (word === subString) {
                let i = index++;
                let row = this.state.answers[index-1];

                return (resUserAnswer.length === 0) ? (<input
                    key={`c${idx + 1}_${idQuestion}`}
                    id={`c${i + 1}_${idQuestion}`}
                    name={`c${i + 1}_${idQuestion}`}
                    maxLength={1000}
                    onKeyUp={(e) => { this.setMyState(row.id, idQuestion, e.target.value, idQuiz, i); }}
                    onPaste={(e) => { this.setMyState(row.id, idQuestion, e.target.value, idQuiz, i); }}
                    defaultValue={ this.isChecked(idQuiz, idQuestion, row, i)}
                    className="resetQuizSelect"
                    order={i}
                />) : (
                    (resUserAnswer.length > 0) && <b key={`c${idx + 1}_${idQuestion}`} className={(parseInt(resUserAnswer[i].grade) === 0) ? 'textDanger' : 'textSuccess'}><u>
                        {ReactHtmlParser(resUserAnswer[i].label.replaceAll(/<(?!br\/?)[^>]+(>|$)/g, ""))}
                    </u></b>
                )
            }else{
                return <span dangerouslySetInnerHTML={{__html: word.replaceAll(/<(?!br\/?)[^>]+(>|$)/g, "")+ ' '}}></span> //ReactHtmlParser(word.replace(/<(?!br\/?)[^>]+(>|$)/g, "")) ;                
            }
            
        })
        
        return stringReturn;
    }//retRightRender


    //--- ---


    render() {

        if (!this.state.answers) return false;
        const { idQuiz, idQuestion } = this.props;
        const resUserAnswer = this.props.answers.filter((item) => { return item.userAnswer === true });        
        const rightAnswer = this.props.answers.filter((item) => { return item.userAnswer === false && parseInt(item.grade) > 0 });

        return (
            <>            
                {/** inline (multiple whit replace) layout */}
                {(this.props.type === 'fill' && this.props.renderMode === 'fillFreeMulti') && (
                    <div className="wrapperResetQuizSelect">{this.retRightRender(idQuestion, idQuiz, resUserAnswer)}</div>
                )}

                {/** show/suggest right answer */}
                {(this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? ReactHtmlParser(`</br><b>${i18n.t('rightAnswer')}:</b></br>`) : ''}
                {                        
                    rightAnswer.map((option, idx)=>{
                        return (
                            (this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? <b key={`opans${idx}`} className={'textSuccess'}>- {ReactHtmlParser(` ${option.label.replace(/<[^>]+>/g, '')} </br>`)}</b> : ''
                        )
                    })
                }

            </>
        );
    }
}

export default QuestionMultiFillFree;