import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import {proxyBE} from '../../../libs/api/common';

import { ROUTES } from 'const';

import { Title } from "components/misc";
import { Grid } from './Grid';
import { SyncIn } from '../user/SyncIn';
import { Form } from './Form';
import { SCORMPlayer} from './Scorm';
import { Payment } from './Payment';
import { MSection } from "components/misc/MSection";
import { FileHandler } from "./FileHandler";
import { Button } from "design-react-kit";
import { networkErrorHelper } from "libs";
import { PDFExample } from "./PDFExample";
import { Search } from './Search';
import { RemoteLoadWait } from './RemoteLoadWait';
import { MakeTestFromRepo } from './MakeTestFromRepo';

class Examples extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.match.params.name
        };
    }

    sendRequest()  {

        proxyBE.get(`test/error`)
        .then(({ data }) => {
            console.log(data);
         })
        .catch(error => {
            networkErrorHelper.notify(error);
        });
    }

    //--- START: bliblioteca ---
    getWishlist(){
        proxyBE.get(`library/wishlist`)
        .then(({ data }) => {
            console.log(data);
         })
        .catch(error => {
            networkErrorHelper.notify(error);
        });
    }

    setWishlist(){
        const payload = {libraryBookId: 100};
        proxyBE.post(`library/wishlist`, payload)
        .then(({ data }) => {
            console.log(data);
         })
        .catch(error => {
            networkErrorHelper.notify(error);
        });
    }
    //--- END: bliblioteca ---

    loadComponent = (name) => {

        if (name === 'grid') {
            return <Grid />;
        }

        if (name === 'payment') {
            return <Payment />;
        }

        if (name === 'form') {
            return <Form />;
        }

        if (name === 'file') {
            return <FileHandler />;
        }

        if (name === 'scorm') {
            return <SCORMPlayer />;
        }

        if (name === 'syncin') {
            return <SyncIn />;
        }

        if (name === 'pdf') {
            return <PDFExample />;
        }

        if (name === 'search') {
            return <Search />;
        }

        if (name === 'remoteloadwait') {
            return <RemoteLoadWait />;
        }

        if (name === 'maketestfromrepo') {
            return <MakeTestFromRepo />;
        }

        return (<p>Esempio non trovato</p>);
    }

    //--- ---

    render() {

        const { name } = this.state;
        const { 
            REACT_APP_MNEMO_URL, REACT_APP_AA_YEAR,
            REACT_APP_API_URL, REACT_APP_CRM_URL,
            REACT_APP_HOME
        } = process.env;

        return (
            <>
                <Title>Esempio {name}</Title>
                {this.loadComponent(name)}
                <MSection className="mb-4">
                    <h6>Elenco esempi</h6><hr />
                    <ul>
                        <li><a href={`${ROUTES.EXAMPLES}/form`} >Form</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/grid`} >Grid</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/payment`} >Payment</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/file`} >File</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/pdf`} >PDF</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/search`} >Search Select</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/syncin`} >SyncIn (sincronizza StudentUpdate su BE)</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/remoteloadwait`} >Aspetta caricamento dati esterno (lento)</a></li>
                        <li><a href={`${ROUTES.EXAMPLES}/maketestfromrepo`} >Generazione Test da Repository</a></li>
                    </ul>
                </MSection>

                <MSection className="mb-4">
                    <h6>Gestione errori</h6>
                    <p>Premi il seguente pulsante per simulare un errore Backend</p>
                    <Button onClick={this.sendRequest} >Invia richiesta</Button>
                </MSection>

                <MSection className="mb-4">
                    <h6>Biblioteca WishList (get list by user logged)</h6>
                    <p></p>
                    <Button onClick={this.getWishlist} >Invia richiesta</Button>

                    <h6>Biblioteca WishList (get list by user logged)</h6>
                    <p></p>
                    <Button onClick={this.setWishlist} >Invia richiesta (set/unset)</Button>
                </MSection>

                <MSection className="mb-4">
                    <h6>Variabili env</h6><hr />
                    <ul>
                        <li><b>REACT_APP_HOME</b>: {REACT_APP_HOME}</li>
                        <li><b>REACT_APP_MNEMO_URL</b>: {REACT_APP_MNEMO_URL}</li>
                        <li><b>REACT_APP_CRM_URL</b>: {REACT_APP_CRM_URL}</li>
                        <li><b>REACT_APP_API_URL</b>: {REACT_APP_API_URL}</li>
                        <li><b>REACT_APP_AA_YEAR</b>: {REACT_APP_AA_YEAR}</li>
                    </ul>
                </MSection>
            </>);
    }
}

export default withRouter(Examples);