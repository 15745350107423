import React, { Component } from "react";
import {  Icon } from 'design-react-kit';


import "./CardNews.scss";
import PropTypes from "prop-types";

export class CardNews extends Component {

  static propTypes = {
    image: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    categoryName: PropTypes.string,
    categorySlug: PropTypes.string,
  };

  static categoryLink = '';

  render() {

    if (this.props.categorySlug) {
      this.categoryLink = `/mnemosine-news/${this.props.categorySlug}`;
    }

    return (
      <div className="cardNews" >
        <a href={this.props.link} className="cardNews__link">
          <div className="cardNews__image">
            <img src={this.props.image} alt={this.props.text} />
          </div>
        </a>
        <div className="cardNews__content">
          <div className="cardNews__header">
            {(this.categoryLink) ? (
              <a href={this.categoryLink} className="cardNews__link_header">{this.props.categoryName}</a>
            ) : (
              <>{this.props.categoryName}</>
            )}
          </div>
          <a href={this.props.link} className="cardNews__link">
            <div className="text__caption">
              <p className="text">{this.props.text}</p>
            </div>
            <div className="cardNews__footer">
              <Icon
                icon="it-check-circle"
                size="sm"
              />{this.props.date}
            </div>
          </a>
        </div>
      </div >
    );
  }
}
