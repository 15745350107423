import React, { Component } from 'react';
import {
    Pager, PagerItem, PagerLink,
    Icon
} from 'design-react-kit';
import { ROUTES } from 'const';

export class BlogPager extends Component {

    prevPage = () => {

        if (this.props.onChangePage) {
            this.props.onChangePage(this.props.currentPage - 1);
        }
    }

    nextPage = () => {

        if (this.props.onChangePage) {
            this.props.onChangePage(this.props.currentPage + 1);
        }
    }

    paginationRender(currentPage, totalPages, slug) {

        let pagination = [];
        let i = 1;
        currentPage = parseInt(currentPage);

        while (i <= totalPages) {
            if ((i <= 3) || //the first three pages
                (i >= totalPages - 2) || //the last three pages
                ((i >= currentPage - 1) && (i <= currentPage + 1))) { //the currentPage, the page before and after

                if (i === currentPage) {
                    pagination.push(
                        <PagerItem key={`pageitem=${i}`}>
                            <PagerLink aria-current='page' href={`${ROUTES.BLOG_HOME}-${i}/${slug}`}>{i}</PagerLink>
                        </PagerItem>
                    );
                } else {
                    pagination.push(
                        <PagerItem key={`pageitem=${i}`} className='d-none d-sm-block' >
                            <PagerLink href={`${ROUTES.BLOG_HOME}-${i}/${slug}`}>{i}</PagerLink>
                        </PagerItem>
                    );
                }
                i++;
            } else { //any other page should be represented by ...
                pagination.push(
                    <PagerItem key={`pageitem=${i}`} className='d-none d-sm-block'>
                        <PagerLink tag='span'>…</PagerLink>
                    </PagerItem>);
                //jump to the next page to be linked in the navigation
                i = i < currentPage ? currentPage - 1 : totalPages - 2;
            }
        }
        return pagination;
    }

    render() {

        let { currentPage, totalPages, slug } = this.props;

        slug = (slug) ? slug : '';

        // Validate page
        if (currentPage < 1) currentPage = 1;
        if (currentPage > totalPages) currentPage = totalPages;

        const prevPage = currentPage - 1;
        const nextPage = parseInt(currentPage) + 1;

        const pagination = this.paginationRender(currentPage, totalPages, slug);

        // TODO 
        return (
            <div className='text-center'>
                <Pager className='mb-3' aria-label='Paginazione'>
                    <ul className='pagination mx-auto'>
                        {(currentPage > 1) &&
                            (<PagerItem>
                                <PagerLink previous href={`${ROUTES.BLOG_HOME}-${prevPage}/${slug}`}>
                                    <Icon icon='it-chevron-left' aria-hidden />
                                </PagerLink>
                            </PagerItem>)
                        }

                        {pagination}

                        {(currentPage < totalPages) &&
                            (<PagerItem>
                                <PagerLink next href={`${ROUTES.BLOG_HOME}-${nextPage}/${slug}`} >
                                    <Icon icon='it-chevron-right' aria-hidden />
                                </PagerLink>
                            </PagerItem>)
                        }
                    </ul>
                </Pager>
            </div>
        )
    }
}