import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import { ROUTES } from "const";
import { cache } from '../../libs';

export class LessonQuiz extends Component {

    render() {

        const { label } = this.props.lesson;
        const { id, /* type, metadata,*/ attempt } = this.props.lesson.resource;

        //--- check if need to resume an onair attempt ---
        let idqa = '';
        if(cache.get(`quiz-${id}`) !== null){
            idqa = '/'+cache.get(`quiz-${id}`).idqa;
        }

        return (
            <> 
                <div className="card card-bg card-big border-bottom-card my-5">
                    <div className="flag-icon"></div>
                    <div className="etichetta">
                        <svg className="icon">
                        <use xlinkHref="/svg/sprite.svg#it-user"></use>
                        </svg>
                        <span>{label}</span>
                    </div>
                    <div className="card-body text-justify">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.

                    <div className="row p-0 mt-5 mb-2">
                        <div className="col-sm-12 col-md-6 col-lg-6 p-0 text-left">{`Tentativi: ${attempt}`}</div>
                        <div className="col-sm-12 col-md-6 col-lg-6 p-0 text-right">
                            <NavLink to={`${ROUTES.LEARNING_QUIZ_MAIN}/${id}${idqa}`}>
                                <button
                                aria-label="avvia Quiz"
                                className="btn btn-primary m-2"
                                type="button"
                            >
                                Avvia Quiz
                            </button>
                            </NavLink>
                        </div>
                    </div>
                    </div>
                </div>
            </>
        );
    }
}