import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { BoxDanger } from 'components/misc';

import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';

import {
  MnemoLoading,
  ModalHandler
} from "components/misc";

import ReactHtmlParser from 'react-html-parser';

class BlogCmsArticle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      payload: [],
      payloadToRender: [],
      isVisible: true,
      searchFilter: {
        searchFilter: {
          isVisible: true,
        }
      },
      rndKey: 0,
    }
  }

  //--- ---

  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Articolo',
        accessor: 'title',
      },
      {
        id: 'cntRead',
        Header: 'Letture',
        accessor: 'cntRead',
      },
      {
        id: 'isFixedToTop',
        Header: 'inEvidenza',
        accessor: 'isFixedToTop',
      },
      {
        id: 'isVisible',
        Header: 'Stato',
        accessor: 'isVisible',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          window.location.href = ROUTES.BLOG_ARTICLE_FORM + '/' + value;
        }
      },     
      {
        id: 'delete',
        accessor: 'id', // field value received in the onclick event
        label: 'Elimina',
        icon: 'it-delete',
        className: 'MTable-actions',
        onClick: (e, value) => { this.deleteRecord(e, value) }
      }
    ]
  }
  

  //--- ---


  componentDidMount() {
    this.loadBlogArticlesByIdCategory(this.state.searchFilter);
  }

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show(value, 'Eliminazione definitiva del dato',
      `Stai eliminando un articolo. Se questo articolo è presente in altre Categorie
       sarà cancellato anche dalle altre Categorie. Se vuoi rimuoverlo da questa categoria, rimuovi la categoria dai dettagli dell'articolo.
       Vuoi davvero eliminare?`, null, this.deleteConfirm);
  }


  deleteConfirm = (value) => {
    adminService.deleteBlogArticle(value).then(({ data }) => {    
      this.setState({ loading: true });
      this.loadBlogArticlesByIdCategory(this.state.searchFilter);
    })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }
  //--- end functions to delete procedure ---


  loadBlogArticlesByIdCategory(filter) {
        
        // check allowed filter by idparent
        if(parseInt(this.props.match.params.idCategory) > 0){
          filter = {searchFilter: {...filter.searchFilter, idBlogCategory: parseInt(this.props.match.params.idCategory)}};
        }
    
        adminService.loadBlogArticlesByIdCategory(filter).then(({ data }) => {

        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.blogArticles).forEach(([key, val]) => {
          
          val.isVisible = (val.isVisible) ? 'visibile' : 'non visibile';
          val.isFixedToTop = (val.isFixedToTop) ? 'si' : 'no';

          items.push(val);
        });
        //--- end: prepare reactTable ---

        const parseItems = items.map((item)=>{
          return {...item, title: ReactHtmlParser(item.title)}
        })

        this.setState({
          loading: false,
          payload: items,
          payloadToRender: parseItems,
          rndKey: Math.floor((Math.random() * 1000) + 1)
        });
      })
      .catch(error => {
        //console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }else{
          this.setState({
            payload:[],
            payloadToRender:[],
            rndKey: Math.floor((Math.random() * 1000) + 1)
          })          
        }
      });
  }  

  onChangeFilterisVisible = () => {
    let isVisible = !this.state.isVisible;

    this.setState({
      isVisible,
      searchFilter: {
        searchFilter: {
          isVisible
        }
      }
    }, () => {
      this.loadBlogArticlesByIdCategory(this.state.searchFilter);
    });
  }//onChangeFilterisVisible

  //--- ---

  render() {

    const { loading } = this.state;

    return (
      <>
        <div className="container mt-5 mb-4">
        <section className="m-0">
            <div className="form-row m-0 p-0">
              <div className="col-md-12 text-right">
                <NavLink to={ROUTES.BLOG_ARTICLE_FORM} className="btn btn-primary bg-dark pb-2">Nuovo Articolo</NavLink>
              </div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (<MnemoLoading></MnemoLoading>) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payloadToRender}
                  tableConfig={this.state.searchFilter.searchFilter.deleted ? this.tableConfigDelete : this.tableConfig}
                />
              </Container>
            )}            

            {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
              Articoli Blog.
              <div className="small text-info">
                  Nessun dato presente.
              </div>
            </BoxDanger>)}
          </section>
        </div>
      </>
    );
  }
}

export default withRouter(BlogCmsArticle);
