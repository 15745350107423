import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { BoxDanger, MnemoLoading, SupportoTecnico, Title } from "components/misc";
import { examService, networkErrorHelper } from "libs";
import { Icon, Row, Col, Button } from "design-react-kit";
import { ROUTES } from "const";

class PerfezionamentoExamLive extends Component {

    state = {
        loading: true,
        rCode: '',
        liveLink: ''
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        examService
            .getLiveLinkPerfezionamento(rCode)
            .then(({ data }) => {
                const { liveLink } = data.payload;
                this.setState({ liveLink, loading: false, loadingError: false, rCode });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, loadingError: true });
            });
    }

    startLive= () => {

        const {liveLink } = this.state;
        window.open(liveLink, '_blank');
    }
    
    render() {

        const { loading, loadingError, rCode } = this.state;
        if (loading) return <MnemoLoading />;

        if (loadingError) {
            return (<BoxDanger className="mt-4">
                Si è verificato un errore durante il caricamento della pagina,
                <br /><SupportoTecnico />
            </BoxDanger>);
        }

        return (<>
            <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right">
                <Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO
            </NavLink>
            <Title >Esami Live</Title>
            <Row className="text-center">
                <Col className="ml-5">                    
                        <img src="https://www.gstatic.com/meet/google_meet_horizontal_wordmark_2020q4_1x_icon_124_40_2373e79660dabbf194273d27aa7ee1f5.png" 
                        alt="" aria-hidden="true"  data-atf="true" data-iml="420" />                       
                 <br/>
                  <Button onClick={this.startLive} className="mt-5" color="primary">Avvia esame</Button>
                </Col>
            </Row>
        </>)
    }
}

export default withRouter(PerfezionamentoExamLive);