import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from 'const';

const ProtectedRouteComponent = ({ location, children, component, isLoggedIn }) => {
    return !isLoggedIn ? (
        <Redirect
            to={{
                pathname: ROUTES.LOGIN,
                state: { from: location }
            }}
        />
    ) : (
        <>{children || component}</>
    );
};

const ProtectedRoute = ({ path, isLoggedIn, children, component }) => {

    return (       
        <Route
            exact
            path={path}
            render={(props) => (
                <ProtectedRouteComponent {...props} children={children} component={component} isLoggedIn={isLoggedIn} />
            )}
        />

    );
};

export default ProtectedRoute;