import React, { Component } from "react";
//import { PDFView } from "components/misc";
import { PDFViewEmbeded } from "components/misc/PDFViewEmbeded";
export class LessonFile extends Component {

  render() {
    const resource = this.props.lesson;
    const fileUrlDownload = process.env.REACT_APP_API_URL +'/learning/lesson-file/download/'+resource.id;
    const fileUrlEmbeded  = process.env.REACT_APP_API_URL +'/learning/lesson-file/embeded/'+resource.id;

    return (
      <>
        {/*<PDFView file={fileUrlDownload} canDownload={true} />*/}
        <PDFViewEmbeded fileUrlDownload={fileUrlDownload} fileUrlEmbeded={fileUrlEmbeded} canDownload={true} />
      </>
    );
  }
}
