import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { AdminLibraryCategory } from "components/forms/admin";
import { LibraryCmsCategory  } from '.';

import { withRouter } from 'react-router-dom';

class LibraryCmsCategoryForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idLibraryCategory: parseInt(this.props.match.params.idLibraryCategory) > 0 ? this.props.match.params.idLibraryCategory : 0,
      title: parseInt(this.props.match.params.idLibraryCategory) > 0 ? 'Gestione Categoria' : 'Nuova Categoria',
      parentList: [{ label: 'Seleziona', value: 0 }]
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            {<AdminLibraryCategory idLibraryCategory={this.state.idLibraryCategory} />}
          </Container>
          {
            (parseInt(this.state.idLibraryCategory) > 0) &&
            (<LibraryCmsCategory idLibraryCategory={parseInt(this.state.idLibraryCategory)} />)
          }
        </section>
      </>
    );
  }

}

export default withRouter(LibraryCmsCategoryForm);
