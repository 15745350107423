import React, { Component } from 'react';
import { formHelper } from 'libs/helpers';

import './MnemoStepper.css';

import {
    StepperContainer, StepperHeader,
    StepperHeaderElement,
    Button, Icon,
    Row
} from 'design-react-kit';
import { MnemoLoading } from '.';

export class MnemoStepper extends Component {

    state = {
        currentProgress: 0,
        loading: false
    }

    componentDidMount() {
        this.setState(this.props);
    }

    onPrevStep = (currentHandler) => {

        let youCan = true;

        // verify parent event
        if (formHelper.isFunction(currentHandler.onPrevStep)) {
            youCan = currentHandler.onPrevStep();
        }

        // if ok 
        if (youCan) {
            let currentProgress = this.state.currentProgress;
            currentProgress--;
            this.setState({ currentProgress });
        }
    }
    
    onNextStep = async (e, currentHandler) => {

        e.preventDefault();
        // verify parente event
        if (formHelper.isFunction(currentHandler.onNextStep)) {
          
            this.setState({ showLoader: true });           

            let promise = new Promise(function (resolve, reject) {
                resolve(currentHandler.onNextStep());
            });

            promise.then(youCan => {

                if (youCan) {
                    let currentProgress = this.state.currentProgress;
                    currentProgress++;
                    this.setState({ currentProgress, showLoader: false });
                }else{
                    this.setState({showLoader: false});
                }
            })
        }
    }

    render() {

        const { currentProgress, showLoader } = this.state;
        const { prevDisabled = false } = this.props;
        const items = this.props.steps.filter(item => item.progress === currentProgress);
        const currentHandler = items[0].handler;
        const nextButton = items[0].nextButton ? items[0].nextButton : 'Avanti';
        const totalStep = this.props.steps.length;

        let variant = "confirmed";
        let icon = "it-check";

        return (
                <StepperContainer tag="div">
                    <>
                        <StepperHeader tag="ul">
                            <>
                                {this.props.steps.map((step, index) => (
                                    <div key={`step-${index}`}>
                                        {(step.progress === currentProgress)
                                            ? (
                                                <>
                                                    {variant = icon = ""}
                                                    <StepperHeaderElement
                                                        iconName={step.handler.iconName}
                                                        variant="active"
                                                    >
                                                        <span>{step.handler.title}</span>
                                                    </StepperHeaderElement>
                                                </>
                                            ) : (
                                                <StepperHeaderElement
                                                    icon={icon}
                                                    iconName={step.handler.iconName}
                                                    variant={variant}
                                                >
                                                    <span>{step.handler.title}</span>
                                                </StepperHeaderElement>
                                            )}
                                    </div>))}
                                <StepperHeaderElement variant="steppers-index">
                                    <span>Passo {currentProgress + 1}/{totalStep}</span>
                                </StepperHeaderElement>
                            </>
                        </StepperHeader>
                        <div className="steppers-content">
                            {(showLoader)  && (<MnemoLoading />) }
                            {currentHandler.render()}
                        </div>
                        <Row tag="nav" className="steppers-nav">
                            {(currentProgress > 0 && !prevDisabled) &&
                                (<Button
                                    className="steppers-btn-prev"
                                    color="primary"
                                    icon={false}
                                    outline
                                    size="sm"
                                    tag="button"
                                    onClick={e => { this.onPrevStep(currentHandler) }}
                                >
                                    <Icon
                                        color="primary"
                                        icon="it-chevron-left"
                                        padding={false}
                                        size=""
                                    />Indietro</Button>
                                )}

                            {(currentProgress < (totalStep - 1)) &&
                                (<div className="ml-auto">
                                    <Button
                                        className="steppers-btn-next"
                                        color="primary"
                                        icon={false}
                                        outline
                                        size="sm"
                                        tag="button"
                                        onClick={e => this.onNextStep(e, currentHandler)}
                                    >{nextButton} <Icon
                                            color="primary"
                                            icon="it-chevron-right"
                                            padding={false}
                                            size=""
                                        />
                                    </Button></div>)}
                        </Row>
                    </>
                </StepperContainer>
            );
    }
}