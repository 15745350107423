import { proxyBE } from './common';

export const blogService = {

    articleCount(slug) {
        return proxyBE.get(`blog/category-count/${slug}`);        
    },

    article(slug) {
        return proxyBE.get(`blog/article/${slug}`);
    },

    category(slug) {
        return proxyBE.get(`blog/category/${slug}`);                
    },

    lastArticles(numPage, dimPage) {
        return proxyBE.get(`blog/last-articles/${numPage}/${dimPage}`);                
    },

    topCategories() {
        return proxyBE.get(`blog/top-categories/`);        
    },

    topArticles(dimPage) {
        return proxyBE.get(`blog/top-articles/${dimPage}`);        
    },

    topFixedArticles(dimPage) {
        return proxyBE.get(`blog/topfixed-articles/${dimPage}`);        
    },

    lastArticlesByCat(slug, numPage, dimPage) {
       return proxyBE.get(`blog/last-cat-articles/${slug}/${numPage}/${dimPage}`);       
    },
}