import React, { Component } from "react";
import "./BooksSlider.scss";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
// import { BookCard } from "components/biblioteca/book-card/BookCard";
import { BookCard } from "components/biblioteca";
import { ButtonPrimary } from "components/ui/buttons/primary/ButtonPrimary";
import { libraryService } from "libs/api/libraryService";
import { BoxSuccess, MnemoLoading } from "components/misc";
import { networkErrorHelper } from "libs";
import { getCurrentUser } from "store/actions";

SwiperCore.use([Pagination]);

let userId = 0;

export class BooksSlider extends Component {
  state = {
    featuredBooks: [],
    errorMessage: "",
    isLoading: true,
    networkError: false,
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {
    const currentUser = getCurrentUser();
    if (currentUser) {
      userId = parseInt(currentUser.id);
    }
    libraryService
      .featuredBooks(userId)
      .then((response) => {
        this.setState({
          isLoading: false,
          featuredBooks: response.data.payload.books,
        });
      })
      .catch((error) => {
        let errorMessage = "";
        let networkError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          errorMessage = networkErrorHelper.getErrorMessage(error);
          networkError = true;
        }

        this.setState({
          networkError: networkError,
          errorMessage: errorMessage,
          error: error,
        });
      });
  }

  render() {
    const { isLoading, errorMessage, networkError, featuredBooks } = this.state;
    if (isLoading) return <MnemoLoading />;
    return (
      <>
        {networkError ? (
          <>Si è verificato il seguente errore: {errorMessage}</>
        ) : (
          <>
            {featuredBooks ? (
              <div className="booksSlider">
                <div className="booksSlider__container">
                  <div className="booksSlider__title">
                    <h2 className="title">Leggi anche</h2>
                    <ButtonPrimary
                      label="Scopri tutti i libri"
                      href="/biblioteca/libri"
                    />
                  </div>
                  <Swiper
                    spaceBetween={20}
                    breakpoints={{
                      0: {
                        slidesPerView: 1.2,
                      },
                      768: {
                        slidesPerView: 2.5,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {featuredBooks.map((book, index) => (
                      <SwiperSlide key={index}>
                        <BookCard book={book} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            ) : (
              <BoxSuccess>Autore non presente</BoxSuccess>
            )}
          </>
        )}
      </>
    );
  }
}
