import React, { Component } from 'react';

import './MnemoCallout.css';

import {
    Callout,
    CalloutTitle,
    CalloutText
} from 'design-react-kit';

export class MnemoCallout extends Component {

    render() {

        let className = "mnemo-callout-success";
        if (this.props.className) {
            className = this.props.className;
        }

        return (
            <Callout className={className} highlight tag="div"   >
                <CalloutTitle tag="h5">
                    {this.props.title}
                </CalloutTitle>
                <CalloutText
                    bigText={false}
                    tag="div"
                >
                    {this.props.children}
                </CalloutText>
            </Callout>
        )
    }
}