import React, { Component } from "react";
import Headroom from "react-headroom";

import "../../scss/components/mainMenuHeader.scss";

import { ROUTES } from "../../const";

import Axios from "axios";

export class NHPublic extends Component {
  state = {
    collapsed: true,
    menuItem: null,
    error: false,
    isDropdownOpen: false,
    dropdownIndex: 0,
  };

  componentDidMount() {
    (async () => {
      let getMenuItem = null;
      try {
        getMenuItem = await Axios.get("/assets/json/NHPublic.json");
        this.setState({ menuItem: getMenuItem.data });
      } catch (error) {
        this.setState({ error: true });
      }
    })();
  } //componentDidMount

  manageEvtByLink = (link) => {
    if (link === "/") {
      this.setState({ collapsed: true });
    }
  };

  //--- ---

  render() {
    if (!this.state.menuItem) {
      return false;
    }

    const openSubMenu = (element, index) => {
      if (index !== this.state.dropdownIndex) {
        this.setState({ dropdownIndex: index });
        this.setState({ isDropdownOpen: true });
      } else {
        this.setState({ dropdownIndex: index });
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
      }
    };

    const getSubMenu = (item, x) => {
      if (item.sub.length) {
        return (
          <Headroom className="mainDropdownMenu__headroom">
            <div
              className={`mainDropdownMenu ${
                this.state.isDropdownOpen && this.state.dropdownIndex === x
                  ? "open"
                  : ""
              }`}
              data-menu={`menu-item-${x}`}
            >
              <div className="mainDropdownMenu__wrapper">
                <ul className="mainDropdownMenu__items">
                  {item.sub.map((element, index) => {
                    if (element.label !== "divider") {
                      return (
                        <li
                          className="mainDropdownMenu__item"
                          key={`dropdownmenu-item-${index}`}
                        >
                          <a href={element.to} title="">
                            {element.label}
                          </a>
                        </li>
                      );
                    } else {
                      return (
                        <div className="divider" key={`divider-${index}`}></div>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </Headroom>
        );
      }
    };

    const menuItem = this.state.menuItem;

    return (
      <>
        <div className="mainMenuContainer">
          <div className="mainMenuContainer__navigation">
            <ul className="mainMenuContainer__navigation__links">
              {menuItem.map((item, x) => {
                if (item.to) {
                  return (
                    <li key={`menu-item-${x}`} className="link-item">
                      <a
                        className="link-item__text"
                        href={item.to}
                        title={item.label}
                      >
                        <span>{item.label}</span>
                      </a>
                    </li>
                  );
                } else {
                  return (
                    <li key={`menu-item-${x}`} className="link-item">
                      <div
                        className={`link-item__text dropdown ${
                          this.state.isDropdownOpen &&
                          this.state.dropdownIndex === x
                            ? "open"
                            : ""
                        }`}
                        data-menu={`menu-item-${x}`}
                        onClick={(e) => openSubMenu(e.currentTarget, x)}
                      >
                        <span className="text">{item.label}</span>
                        <span className="icon">
                          <img
                            src="/img/icon/icon-arrow-down.svg"
                            alt="dropdown icon"
                          />
                        </span>
                      </div>
                      {getSubMenu(item, x)}
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          {/* SEARCH */}
          {/* <div className="mainMenuContainer__search">
            Search  
          </div> */}
          {/* END SEARCH */}

          {/* LOGIN */}
          <div className="mainMenuContainer__user">
            <a
              href={ROUTES.LOGIN}
              className="user__link"
              title="accedi all'area riservata"
            >
              <div className="user__icon">
                <img
                  src="/img/icon/icon-profile.svg"
                  alt="accedi all'area riservata"
                />
              </div>
              <div className="user__text">
                <span>Accedi</span>
              </div>
            </a>
          </div>
          {/* END LOGIN */}
        </div>
      </>
    );
  }
}
