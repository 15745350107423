import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { learningService, networkErrorHelper, cache } from "libs";
import { ROUTES } from 'const';

import { QuizSection } from 'components/quiz/QuizSection';
import ReactHtmlParser from 'react-html-parser';

import dayjs from 'dayjs';
import { MnemoLoading, BoxDanger, Countdown, ModalHandler, Title } from "components/misc";
import { Button, Row, Col, Icon } from "design-react-kit";
import i18n from "app/i18n";
import i18next from "i18next";

class QuizMain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quiz: null,
            rCode: this.props.match.params.rCode || '',
            idResource: this.props.match.params.id,
            idQuiz: this.props.match.params.idqa,
            reloadLastAttempt: false,
            message: null,
            loseFocus: false,
            isLoading: false,
            countdown: {
                params: {
                    onEnd: this.onEndCountdown,
                    duration: 0
                }
            }
        };
    }

    componentDidMount() {
        //document.oncontextmenu = document.body.oncontextmenu = function() {return false;}

        //--- check if need to resume an onair attempt ---
        let idQuizLocal = this.state.idQuiz;
        if (cache.get(`quiz-${this.state.idQuiz}`) !== null) {
            idQuizLocal = cache.get(`quiz-${this.state.idQuiz}`).idq;
        }

        learningService.getQuiz(this.state.idResource, idQuizLocal).then(function (result) {

            //cambio lingua da metadata value
            const tmpLang = result.data.payload.quiz?.metadata?.lang || 'it';
            if(tmpLang !== localStorage.getItem('i18nextLng')){
                i18next.changeLanguage(result.data.payload.quiz?.metadata?.lang || 'it');
            }

            if (!result.data.payload.quiz.message) {
                if (result.data.payload.quiz.loseFocus) {
                    ModalHandler.show((result.data.payload), 'Importante.', `Questa prova prevede che non venga lasciata la pagina
                     di esecuzione della prova. Lasciando la pagina automaticamente la prova sarà conclusa nello stato in cui si
                      trova in quel momento. Se hai compreso "Conferma".`, null, acceptRuleLoseFocus, 'Conferma', '', true, false);
                      //rejectRuleLoseFocus
                } else {
                    resQuiz(result.data.payload);
                }
            }
        })
        .catch(error => {
            if (networkErrorHelper.is422(error)) {
                this.setState({
                    quiz: { 'quiz': { 'message': error.response.data.errors } }
                });
                this.deleteLocalStorage(localStorage, this.state.idQuiz);
            }
        });

        /** */
        const acceptRuleLoseFocus = (payload) => {
            window.addEventListener('blur', doBlur);
            resQuiz(payload);
        }//acceptRuleLoseFocus

        /** */
        const doBlur = () => {
            this.sendQuiz();
        }//doBlur

        /** 
        const rejectRuleLoseFocus = (payload) => {
            learningService.deleteAttemp(payload.quiz).then((result) => {
                window.location.href = ROUTES.LEARNING_MAIN + '/' + this.props.match.params.rCode;
            })
        }//rejectRuleLoseFocus
        */

        /** */
        const resQuiz = (result) => {
            /** save to local memory quiz data */
            cache.set(`quiz-${result.quiz.idQuiz}`, {
                onair: true,
                idq: result.quiz.idQuiz,
                idqa: result.quiz.id
            });

            //--- calculate valid duration about dateStart Quiz Attempt ---
            const expiredDate = dayjs(dayjs(result.quiz.dateStart).add((result.quiz.duration * 60), 'second'));
            const now = dayjs();
            const validDuration = expiredDate.diff(now, "minutes", true);

            this.setState({
                quiz: Object.assign({}, result),
                countdown: {
                    params: {
                        onEnd: this.onEndCountdown,
                        duration: validDuration
                    }
                }
            });
        }//resQuiz
    }//componentDidMount

    /** */
    onEndCountdown = () => {
        this.sendQuiz();
    }//onEndCountdown

    /** */
    openFeedbackFinal = (idQuizAttempt) => {
        /** rediret to lesson or course list page */
        window.location.href = ROUTES.LEARNING_QUIZ_FEEDBACK + '/' + idQuizAttempt + '/' + this.props.match.params.rCode;
    }//openFeedbackFinal

    /** */
    deleteLocalStorage = (localStorage, idQuiz) => {
        //--- remove all localStorage data quiz ---
        Object.entries(localStorage).map(
            item => item[0]
        ).filter(
            item => item.substring(0, ("quiz-" + idQuiz).length) === "quiz-" + idQuiz
        ).map(
            item => localStorage.removeItem(item)
        )
    }//deleteLocalStorage

    /** */
    confirmSendQuiz = () =>{       
        ModalHandler.show(null, i18n.t('confirmSendTest'), i18n.t('confirmSendTestDescription'), null, this.sendQuiz, i18n.t('btnConfirm'), i18n.t('btnCancel'));
    }//confirmSendQuiz

    /** */
    deleteCurrenteAttempt = () =>{        
        ModalHandler.show(null, 'Importante.', `Il tentativo corrente sta per essere annullato, 
        è possibile ripetere la prova successivamente.`, null, this.confirmDeleteCurrenteAttempt);
    }//deleteCurrenteAttempt

    confirmDeleteCurrenteAttempt = () =>{
        const idQuiz    = this.state.quiz.quiz.idQuiz;
        const questions = this.state.quiz.quiz.questions;

        cache.forget(`quiz-${idQuiz}`);
        questions.forEach((question)=>{
            cache.forget(`quiz-${idQuiz}-${question.id}`);
        })

        if((this.state.quiz.quiz.lessonMainID || 0) === 0){
            window.location.href =  + this.props.match.params.rCode;
        }else{
            window.location.href = ROUTES.LEARNING_LESSON + '/' +(this.state.quiz.quiz.lessonMainID || 0)+ '/'+this.state.rCode+'/'+(this.state.quiz.quiz.idSection || 0);
        }
    }//confirmDeleteCurrenteAttempt

    /** drag start */
    dragStart = (e) => {
        e.dataTransfer.setData("text", e.target.textContent);
        e.dataTransfer.setData("sourceId", e.target.id);
    }//dragStart

    loadDDAnswers = (idQuiz) => {
        let allAnswers = [];
        for(let q in this.state.quiz.quiz.questions){
            const answers = this.state.quiz.quiz.questions[q].answers;
            for(let a in answers){
                allAnswers.push(answers[a].label)
            }
        }

        return allAnswers.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
    }//loadDDAnswers


    /** */
    sendQuiz = () => {
        
        //--- get all quiz element state from localStorage and send to BE ---
        let lockSendQuiz = false;
        this.setState({
            isLoading: true,
            message: null
        });

        const idQuiz  = this.state.quiz.quiz.idQuiz;
        let questions = this.state.quiz.quiz.questions;

        if (cache.get(`quiz-${idQuiz}`) !== null) {

            //--- check if exists valid answears and check if mandatory ---
            if (this.state.quiz.quiz.mandatory) {
                let cntAnsw = 0;
                questions.forEach(row => {
                    if (cache.get(`quiz-${idQuiz}-${row.id}`)) {
                        cntAnsw++;
                    }
                })

                //---

                if(cntAnsw < questions.length) {
                    this.setState({
                        isLoading: false,
                        message: i18n.t('allQuestionRequired')
                    })                
                    //window.scrollTo(0,0); //force scroll to top to show error message
                    setTimeout(() => {
                        this.setState({
                            message: null
                        })
                    }, 6000);

                    lockSendQuiz = true;
                }
            }//check if mandatory

            //--- ---

            questions.forEach(row => {
                let lS = cache.get(`quiz-${idQuiz}-${row.id}`);

                if (lS !== null && lS.length > 0) {

                    //--- manage error on type text ---
                    if((row.type).toLowerCase() === 'text' && lS[0].label === ''){
                        this.setState({
                            isLoading: false,
                            message: `Attenzione. La Domanda ${row.order} prevede una risposta aperta.`
                        })

                        lockSendQuiz = true;
                    }

                    //--- manage error on type fill / fillFree ---
                    if((row.type).toLowerCase() === 'fill' && lS[0].label === '' && row.renderMode === 'fillFree'){
                        this.setState({
                            isLoading: false,
                            message: `Attenzione. La Domanda ${row.order} prevede una risposta aperta.`
                        })

                        lockSendQuiz = true;
                    }

                    //--- manage error on type fill / fillFreeMulti ---
                    /*if((row.type).toLowerCase() === 'fill' && row.renderMode === 'fillFreeMulti'){

                        if(parseInt(row.maxChoose) != lS.length){
                            this.setState({
                                isLoading: false,
                                message: `Attenzione. La Domanda ${row.order} prevede una risposta aperta multipla.`
                            })
    
                            lockSendQuiz = true;
                        }
                    }*/
                    
                    //--- manage error on type !text ---
                    if(lS.length !== row.maxChoose && (row.type).toLowerCase() !== 'text' && row.renderMode !== 'fillFree' && row.renderMode !== 'fillFreeMulti'){
                        this.setState({
                            isLoading: false,
                            message: `Attenzione. La Domanda ${row.order} prevede ${row.maxChoose} risposte.`
                        })
    
                        lockSendQuiz = true;
                    }

                    if(!lockSendQuiz){
                        row.answers.forEach((item, idx) => {
                            /*
                                iterate on real answers from localStorage.
                                add marker [true] property to main quiz->questions->answers
                                if idAnswer is in localStorage value
                            */
                            lS.forEach(lSItem => {
                                if (parseInt(lSItem.idAnswer) === parseInt(item.id)) {
                                    item.marker = true;
                                }

                                if(typeof lSItem?.answers?.label === 'undefined'){
                                    if (lSItem.label !== '' && typeof lSItem.idAnswer === 'undefined') {
                                        item.marker = true;
                                        item.label = lSItem.label;
                                    }

                                    if (lSItem.label !== '' && typeof lSItem.idAnswer === 'undefined') {
                                        item.marker = true;
                                        item.label = lSItem.label;
                                    }
                                }

                                if(typeof lSItem?.answers?.label !== 'undefined'){
                                    if([lSItem.answers].filter((line)=>{ return line.index === idx }).length > 0){
                                        //item.marker = true;
                                        item.label = [lSItem.answers].filter((line)=>{ return line.index === idx })[0].label;
                                    }
                                }
                            })
                        })
                    }else{
                        setTimeout(() => {
                            this.setState({
                                message: null
                            })
                        }, 6000);
                    }

                }//localStorage answer
            });


            if(!lockSendQuiz){
                //--- send quiz data to backend ---
                learningService.sendQuiz(this.state.quiz).then((result) => {
                    //console.log(result.data.payload)
                    let idQuiz = result.data.payload.result.idQuiz;
                    let idQuizAttempt = result.data.payload.result.id;
                    //let idLesson = result.data.payload.result.idLesson;

                    this.deleteLocalStorage(localStorage, idQuiz);
                    this.openFeedbackFinal(idQuizAttempt);

                    this.setState({
                        isLoading: false
                    })

                    return result;
                })
            }
        } else {
            //TODO: mostare un errore quando non viene recuperato un quiz dal localstorage
        }
    }//sendQuiz

    //--- ---
    
    render() {

        if (!this.state.quiz) return false;
        const { questions, message, duration, title, quizDescription, metadata, lastQuizAttemp } = this.state.quiz.quiz;

        // redirect to last attempt result view
        if(metadata?.feedbackFinal?.reloadLastAttempt === true && parseInt(lastQuizAttemp)>0 && this.props.match.params?.action !== 'forceRepeat'){
            this.openFeedbackFinal(parseInt(lastQuizAttemp));
            return false;
        }

        return (
            <>
                {(this.state.isLoading) && <MnemoLoading />}

                <div className="container mt-4 mb-4 noSelect">
                    <Title>{title}</Title>
                    {(quizDescription !== '') && <Title className="mt-1"><h6>{ReactHtmlParser(quizDescription)}</h6></Title>}

                    {(this.state.message) && <BoxDanger><h6>{ReactHtmlParser(this.state.message)}</h6></BoxDanger>}
                    {(message) && <BoxDanger><h6>{ReactHtmlParser(message)}</h6></BoxDanger>}

                    {(!message) &&
                        <section id="fullQuiz">
                            <div className="bg-light py-5">
                                <div className="px-4 container">

                                    {(duration > 0) && (
                                        <div className="card" style={{ "position": "sticky", "top": "0", "zIndex": "100" }}>
                                            <div className="row p-3 pb-1 pt-0">
                                                <div className="col">Prova a Tempo. Allo scadere del tempo disponibile la prova sarà terminata automaticamente allo stato di esecuzione</div>
                                                <div className="col text-right">
                                                    <Countdown params={this.state.countdown.params} />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <Row>
                                        <Col>
                                            {questions.map((row, i) => (
                                                <QuizSection key={`quizItem-${i}`} content={row} idQuiz={this.state.idQuiz} metadata={metadata} posOrder={i}></QuizSection>
                                            ))}
                                        </Col>
                                    </Row>

                                    {/** drag&drop handle options */}
                                    {(typeof metadata.dnd !== 'undefined') && ((metadata.dnd === true) && <Row>
                                        <Col>
                                        <ul className="elmDraggale">
                                            {
                                                this.loadDDAnswers(this.state.idQuiz).map((item, idx)=>{
                                                    return <li key={`answers-${idx}`} id={`answers-${idx}`} className="elmDraggale" draggable="true" onDragStart={ (e)=>{ this.dragStart(e) }}>{ReactHtmlParser(item)}</li>
                                                })
                                            }
                                        </ul>
                                        </Col>
                                    </Row>)}

                                    <Row>
                                        <Col>
                                            {
                                                (this.state.message) ? 
                                                <Button color="primary bg-dark" className="m-2" disabled>Correzione Corso <Icon icon="it-clock" color="white"/></Button> :
                                                <Button color="primary bg-dark" className="m-2" onClick={this.confirmSendQuiz}>{i18n.t('confirmTest')}</Button>
                                            }
                                            {(this.state.quiz.quiz.attempts === 0) && <Button color="primary bg-dark" className="m-2" onClick={this.deleteCurrenteAttempt}>{i18n.t('cancelTest')}</Button>}
                                        </Col>
                                    </Row>

                                    {(this.state.message) && <BoxDanger className="mt-3"><h6>{this.state.message}</h6></BoxDanger>}
                                    {(message) && <BoxDanger><h6>{message}</h6></BoxDanger>}

                                </div>
                            </div>
                        </section>
                    }

                </div>
            </>
        );
    }
}

export default withRouter(QuizMain);