import { HomeContent } from "components/dashboard";
import React, { Component } from "react";

/**
 * this is the public home page 
 */
export class Landing extends Component {
  render() {
    return (
      <>
        <HomeContent></HomeContent>                     
      </>
    );
  }
}
