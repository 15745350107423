import React from "react";

import {
    MSelect,
    ValidatedForm
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentAdminService, networkErrorHelper
} from "libs";
import { BoxSuccess } from "components/misc";

export class EnrollFillFormICDL extends ValidatedForm {

    PAYLOADS = {
        examSites: [],
        examSessions: [],
    }

    loadExamSites = (_, selectedExamSession) => {

        this.PAYLOADS.examSites.length = 0;

        if (selectedExamSession) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(selectedExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }
    }

    FIELDS_GROUP = [
        [
            {
                field: "idExamSession",
                label: "Sessione di esami ",
                payload: { options: this.PAYLOADS.examSessions },
                component: MSelect,
                className: "col-md-12",
                onChange: this.loadExamSites
            }
        ],
        [
            {
                field: "examSite",
                label: "Sede esami",
                payload: { options: this.PAYLOADS.examSites },
                component: MSelect,
                className: "col-md-6"
            }
        ]
    ];

    ERROR_MESSAGES = {
        examSite: 'Specificare una sede esami',
        idExamSession: 'Specificare una sessione di esami',
    };

    validation = {
        examSite: value => value > '',
        idExamSession: value => value > '',
    };

    emptyFields = {
        examSite: '',
        idExamSession: '',
    };

    state = {
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        if (this.PAYLOADS.examSessions.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSessions('ICDL')
                .then(({ data }) => {

                    const { payload } = data;
                    const courses = payload.map(({ id, value }) => ({ label: value, value: id }));

                    this.PAYLOADS.examSessions.unshift({ label: 'Seleziona sessione di esami', value: '' });
                    this.PAYLOADS.examSessions.push(...courses);

                    this.setState({
                        examSessions: { options: this.PAYLOADS.examSessions }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        const { idExamSession } = this.props.enrollData;
        if (idExamSession) {
            this.loadExamSites('', idExamSession);
        }

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);
        const { idExamSession } = this.state.defaultValues;

        const examSession = this.PAYLOADS.examSessions.filter(item => (item.value == idExamSession));
        this.payload.examSession = examSession[0].label;

        const validations = {
            examSite: null,
            idExamSession: null
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { courseCode } = this.props.enrollData;
        const { loading, defaultValues } = this.state;
        if (loading) return <></>;

        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            {code}
            <BoxSuccess>Tutte le sedi sono regolarmente attivate ad eccezione di quelle contrassegnate con (*) che lo saranno al raggiungimento di almeno 10 iscritti per sessione d’esame.</BoxSuccess>
            {(courseCode === 'ICDL238-DATTI298') &&
                (<>
                    <BoxSuccess>La sessione e sede di esame si riferiscono al solo corso ICDL IT Security.</BoxSuccess>
                </>)}
        </>);
    }
}