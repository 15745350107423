import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { courseService, networkErrorHelper } from "libs";
import { CourseShopper } from "components/enrollment";
import { BoxInfo, SupportoTecnico } from "components/misc";

class CourseLandingPageIFrame extends Component {

    state = {
        courseCode: 0,
        course: 0,
        loading: true,
        loadingError: false
    };

    componentDidMount() {

        // get registration code from parameter
        const { courseCode } = this.props.match.params;
        this.setState({ courseCode });

        this.loadRemote(courseCode);
    }

    loadRemote(courseCode) {

        courseService
            .getByCode(courseCode)
            .then(({ data }) => {
                this.setState({ courseCode, course: data.payload.course, loading: false, loadingError: false });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });
    }

    render() {

        const { loading, loadingError, courseCode, course } = this.state;
        if (loading) return (<></>);
        const { isLoggedIn } = this.props;
        return (
            <>
                {!loadingError && (
                    <>
                        <section className="course_section">
                            {(course.enrollEnabled) ?
                                (

                                    <CourseShopper plugin={course.plugin} isLoggedIn={isLoggedIn}
                                        enrollManifesto={course.enrollManifesto}
                                        courseCode={courseCode}
                                        courseTitle={course.title}>
                                    </CourseShopper>

                                ) : (<>
                                    <BoxInfo>
                                        <h5 className="p-4">Iscrizione a questo corso disabilitata. Per maggiori informazioni puoi <SupportoTecnico /></h5>
                                    </BoxInfo>
                                </>)}
                        </section>
                    </>)}

            </>);
    }
}

export default withRouter(CourseLandingPageIFrame);