import React, { Component } from 'react';
import { formHelper } from 'libs/helpers';

import {
    ModalHeader, ModalBody, ModalFooter, Modal,
    Button
} from 'design-react-kit';

export class MnemoModal extends Component {

    state = {
        show: true,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.show !== this.state.show) {
            this.setState({ show: nextProps.show });
        }
    }

    cancel = () => {
        this.setState({
            show: false,
        });

        const { onCancel, value } = this.props;
        if (formHelper.isFunction(onCancel)) {
            onCancel(value);
        }
    }

    submit = () => {
        this.setState({
            show: false,
        })

        const { onSubmit, value } = this.props;
        if (formHelper.isFunction(onSubmit)) {
            onSubmit(value);
        }
    }

    render() {

        const { title, content,
            confirmText = 'Conferma', cancelText = 'Annulla',
            showConfirm = true, showCancel = true } = this.props;


        return (
            <>
                <Modal
                    fade={true}
                    centered={true}
                    size="lg"
                    isOpen={this.state.show}
                >
                    <ModalHeader
                        charCode={215}
                        closeAriaLabel="Close"
                        tag="h5"
                        wrapTag="div"
                    >{title}</ModalHeader>
                    <ModalBody tag="div">{content}</ModalBody>
                    <ModalFooter tag="div">
                        {showCancel && 
                        (<Button
                            color="primary"
                            outline
                            icon={false}
                            tag="button"
                            onClick={() => this.cancel()}
                        >{cancelText}</Button>)}
                        {showConfirm && 
                        (<Button
                            color="primary bg-dark"
                            icon={false}
                            tag="button"
                            onClick={() => this.submit()}
                        >{confirmText}</Button>)}
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}