import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { AdminLibraryBook } from "components/forms/admin";

import { withRouter } from 'react-router-dom';

class LibraryCmsBookForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idLibraryBook: parseInt(this.props.match.params.idLibraryBook) > 0 ? this.props.match.params.idLibraryBook : 0,
      title: parseInt(this.props.match.params.idLibraryBook) > 0 ? 'Gestione Libro' : 'Nuovo Libro',
      parentListCategory: [{ label: 'Seleziona', value: 0 }],
      parentListAuthor: [{ label: 'Seleziona', value: 0 }]
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            <AdminLibraryBook idLibraryBook={this.state.idLibraryBook} />
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(LibraryCmsBookForm);
