import { ROUTES } from "const";

export class EMOZIONI {
    
    static getDashboardCards() {

       return   [
        {
            title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
            img: '/img/icon/lessons.png', className: 'image-box'
        }, {
            title: 'Istruzioni Operative', link: ROUTES.COURSE_ISTRUCTION,
            img: '/img/icon/graduate.png', className: 'image-box'
        }, {
            title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
            img: '/img/icon/message.png', className: 'image-box'
        }, {
            title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
            img: '/img/icon/payment.png', className: 'image-box'
        }, {
            title: 'Fatture', link: ROUTES.COURSE_INVOICES,
            img: '/img/icon/invoice.png', className: 'image-box'
        }    
    ];
    }
}
