import React from "react";

import { FormEnabler, ValidatedForm, MInput } from "components/forms";

import {
  payloadBuilder,
  userService,
  networkErrorHelper
} from "libs";

import { Card, CardBody, Icon } from 'design-react-kit';
import { BoxDanger, BoxSuccess, MnemoLoading } from "components/misc";

export class UserChangeEmail extends ValidatedForm {

  payload_nations = [];

  emptyFields = {
    email: '',
  };

  state = {
    loading: false,
    ...this.emptyFields,
    formShow: true,
    errorShow: false,
    defaultValues: {
      ...this.emptyFields,
      email:this.props.user.email,
      id:this.props.user.id,
      name:this.props.user.name,
      surname:this.props.user.surname
    }
  };

  ERROR_MESSAGES = {
    'email': `L'Email non è valida`
  };

  validation = {
    'email': value => /\S+@\S+\.\S+/.test(value)
  };

  FIELDS_GROUP = [
    [
      {
        id: "email",
        name: "email",
        field: "email",
        label: "nuovo indirizzo email",
        component: MInput,
        className: "col-md-6"
      }
    ],
  ];

  componentDidUpdate(props, state){
    if(!state.formShow){
      this.setState({ formShow: true });
    }
  }

  saveRemote = () => {
    const payload = payloadBuilder(this.state);
    this.setState({ loading: true});

    userService.update(payload)
      .then((ret) => {
        this.setState({
          formActive: false,
          loading: false
        });

        if(ret.data.payload.user.email === payload.email){
            userService.resendEmail().then(() => {
                this.setState({ formShow: false });
            }).catch(error => {
              this.setState({ formActive: false, loading: false});
              networkErrorHelper.notify(error);
            });
        }else{ 
          this.setState({ errorShow: true });
          setTimeout(() => {
            this.setState({ errorShow: false });
          }, 7000);
        }
      })
      .catch(errors => {
        console.log(errors)
        this.setState({ formActive: false, loading: false});
        networkErrorHelper.notify(errors);
      });
  }//saveRemote

  renderForm() {
    const { formActive, defaultValues, loading, formShow, errorShow } = this.state;
    if (loading) return (<><MnemoLoading /></>);

    const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
      {(errorShow) && <BoxDanger>Si è verificato un errore durante l'aggiornamento</BoxDanger>}
      
      <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card mb-4">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon icon="it-user" padding={false} />
            <span>Dati Profilo: indirizzo Email</span>
          </div>

          <CardBody tag="div">
            {(formShow) ? (<><form className="user" id="userTarget_form">
              {code}
            </form>
            <div>
              <FormEnabler
                onToggle={this.toggleForm}
                isFormActive={formActive}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                disableSave={!formValid}
              />
            </div></>) : (<BoxSuccess>Abbiamo inviato una nuova email di attivazione a <strong>{this.state.defaultValues.email}</strong> 
              . Per favore segui le istruzioni contenute nell'email per attivare il tuo account. Se l'email non ti arriva, controlla la tua cartella spam.
              </BoxSuccess>)}
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
      return this.renderForm();
  }
}
