import { ROUTES } from "const";
import { courseService } from "libs";

export class CS24 {

    static async getDashboardCards(registrationCode) {

        let cards = this.getFixedCards();

        try {
            const result = await courseService.cfuActiveLink(registrationCode);
            if (result.data.payload) {
                const { payload } = result.data;               
                if (payload.writtenReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Esami',
                        link: ROUTES.C24_EXAMWRITTEN_ONSITE_RESERVATION, // ROUTES.C24_EXAMWRITTEN_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }
                if (payload.writtenEnabled === '1') {
                    cards.push({
                        title: 'Prove di Profitto', link: ROUTES.C24_EXAMWRITTEN,
                        img: '/img/icon/badge.png', className: 'image-box'
                    });
                }

                if (payload.liveReservationEnabled === '1') {
                    cards.push({
                        title: 'Prenotazione Orali 24CFU',
                        link: ROUTES.C24_EXAMLIVE_RESERVATION,
                        img: '/img/icon/8.png', className: 'image-box'
                    });
                }

                if (payload.isLinkExamEnabled === '1') {
                    cards.push({
                        title: 'Avvio di Google Meet',
                        link: ROUTES.C24_EXAMLIVE,
                        img: '/img/icon/call.png', className: 'image-box'
                    });
                }
                if (payload.certRequestEnabled === '1') {
                    cards.push({
                        title: 'Richiesta Certificazione 24 CFU',
                        link: ROUTES.C24_CERT_REQUEST,
                        img: '/img/icon/graduate.png', className: 'image-box'
                    });
                }               
            }
        } catch (error) {

        }

        return cards;
    }

    static getFixedCards() {

        return [
            {
                title: 'Materiale didattico', link: ROUTES.LEARNING_MAIN,
                img: '/img/icon/lessons.png', className: 'image-box'
            }, {
                title: 'Bando e Regolamenti', link: ROUTES.COURSE_DOCS,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            },
            {
                title: 'Progetto Corso Gratuito', link: ROUTES.COURSE_PROVIDER,
                img: '/img/icon/euro2.png', className: 'image-box'
            },
        ];
    }

    static getDashboardNotices() {

        return [
            {
                title: 'Certificazioni',
                content: `<p>A tutti coloro che avranno superato le prove finali dei Corsi 24 CFU ai quali partecipa, verrà rilasciata, se in regola con la posizione amministrativa e contabile, la "Certificazione aggiuntiva" ai sensi del comma 5 dell’art. 3 del D.M. n. 616 del 10 Agosto 2017 attestante</p>
                <ul>
                    <li>Gli obiettivi formativi previsti dal D.M. n. 616 del 10 Agosto 2017;</li>
                    <li>Gli insegnamenti e le attività formative;</li>
                    <li>I settori disciplinari;</li>
                    <li>Le votazioni riportati agli esami ed i Crediti Formativi Universitari acquisiti.</li>
                </ul>`,
            },
            {
                title: 'Prove finali',
                content: `<p>Per ogni Insegnamento in presenza la prova finale sarà in forma scritta e consisterà nella compilazione di un questionario formato da 10 items
                 a risposta multipla ed una a risposta aperta. Ogni risposta esatta, contrassegnata con una “X” sarà valutata punti 2,5 mentre quella errata o non fornita 
                 sarà valutata punti zero. Nel caso di errore, la invitiamo a cerchiare la risposta ritenuta errata e a segnare la nuova risposta. La risposta aperta 
                 sarà valutata fino a punti 5 ma in caso di evidenti errori potrà essere valutata negativamente fino a  -3 punti. Se la risposta aperta non dovesse
                  essere fornita essa sarà valutata punti zero. Per lo svolgimento di ogni questionario avrà a disposizione 20 minuti.</p>
                <p>Il superamento della prova avverrà al raggiungimento di punti 18 sui 30 a disposizione.</p>
                <p>Ricordiamo che gli esami si svolgeranno lo stesso giorno.</p>`,
            }];
    }
}
