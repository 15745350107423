import CodiceFiscale from 'codice-fiscale-js';
import { userAddressService } from 'libs/api';

export class FiscalCodeHelper {

    static async calculateFiscalCode(user, userProfile) {

        let result = '';                                      
                
        try {

            const dataPromise = await userAddressService.address(userProfile.bornAddressId);
  
            const { name, surname } = user;
            const { dob, gender } = userProfile;
            const { city, province, nation, isForeign } = dataPromise.data.payload.address;

            const ddate = dob.split('-');
            if (ddate.length < 3) {
                return result;
            }

            const objFS = {
                name,
                surname,
                gender,
                day: ddate[2],
                month: ddate[1],
                year: ddate[0],
            };

            if (isForeign) {
                objFS.birthplace = nation;
                objFS.birthplaceProvincia = 'EE';
            } else {
                objFS.birthplace = city;
                objFS.birthplaceProvincia = province;
            }
           
            const cf = new CodiceFiscale(objFS);          
            result = cf.code;            
        }
        catch (error) {
            console.log(error);
        }

        return result;
    }
}