import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { MnemoLoading, Title } from "components/misc";

class ExamDone extends Component {

    state = {
        loading: true,
        rCode: ''
    };

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        this.setState({ loading: false, rCode });
    }

    render() {

        const { loading } = this.state;
        if (loading) {
            return <MnemoLoading />;
        }

        return (<>

            <Title>Pagina Esami effettuati</Title>
            <p>Lavori in corso di realizzazione!</p>
        </>)
    }
}

export default withRouter(ExamDone);