import React from 'react';
import { MSection, BoxSuccess, BoxDanger, ReCaptchaV3 } from 'components/misc';
import { pageService } from "libs";

import {
    ValidatedForm,
    MInput, MTextArea
} from "components/forms";

import { Button } from 'design-react-kit';

import {
    payloadBuilder,
    utilsService
} from "libs";


export class ContactForm extends ValidatedForm {

    emptyFields = {
        nameSurname: "",
        subject: "",
        body: "",
        email: "",
        phone: ""
    };
    
    constructor(props) {
        super(props);

        this.state = {
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: true,
            disabled: false,
            sendState: false,
            errorRecaptcha: false,
            reCaptchaToken: null
            
        };
    }

    ERROR_MESSAGES = {
        nameSurname: "Nome e Cognome richiesto",
        subject: "Oggetto richiesto",
        body: "Messaggio richiesto",
        email: "Email richiesta",
        phone: "Telefono richiesto",
    };

    validation = {
        nameSurname: value => value.length > 5,
        subject: value => value.length > 8,
        body: value => value.length > 10,
        email: value => /\S+@\S+\.\S+/.test(value),
        phone: value => /^[0-9.+/"]+$/i.test(value)
    };


    FIELDS_GROUP = [        
        [
            {
                field: "nameSurname",
                label: "Nome e Cognome",
                component: MInput,
                type: "text",
                className: "col-md-6 mt-4"
            }
        ],        
        [
            {
                field: "email",
                label: "Email",
                component: MInput,
                type: "email",
                className: "col-md-6 mt-6"
            },
            {
                field: "phone",
                label: "Telefono",
                component: MInput,
                type: "text",
                className: "col-md-6 mt-6"
            }
        ],
        [
            {
                field: "subject",
                label: "Oggetto",
                component: MInput,
                type: "text",
                className: "col-md-12"
            },
            {
                field: "body",
                label: "Messaggio",
                component: MTextArea,
                rows: 6,
                className: "col-md-12"
            }
        ]
    ];

    //--- ---

    reCaptchaCallback = (token) =>{
     //   console.log(token)
        this.setState({
            reCaptchaToken: token
        })

        //--- verify recaptcha token on server ide isHuman ---
    }

    onSubmit = () => {

        //--- verify recaptcha token on server ide isHuman ---        
        pageService.validatereCaptcha(this.state.reCaptchaToken).then(({ data }) => {
            const { success } = data.payload.result;

            if(success){

                let isValid = this.checkValidation();

                if (isValid) {
                    // validate and submit inner validatedForm component
                    this.innerValidatedForms.forEach(component => {
                        if (!component.onSubmit()) {
                            isValid = false;
                        }
                    });
                }

                // if all components are valid submit this
                if (isValid){
            
                    this.setState({
                        formActive: false,
                        disabled: true,
                        errorRecaptcha: false
                    })

                    const payload = payloadBuilder(this.state);
                    utilsService.sendContactForm(payload).then((result)=>{
            
                            if(result.data.payload.ret){
                            this.setState({
                                formActive: true,
                                disabled: false,
                                sendState: true,
                                ...this.emptyFields,
                                defaultValues: { ...this.emptyFields }
                            })
                            }//true
        
                            setTimeout(() => {
                                this.setState({
                                    sendState: false
                                })
                            }, 5000);
        
                        })
                        .catch(errors => {
                        this.setState({
                            formActive: true,
                            disabled: false
                        })
                            console.log(errors);
                    });
                }

                return isValid;
                
            }else{
                this.setState({
                    errorRecaptcha: true
                })
                setTimeout(() => {
                    this.setState({
                        errorRecaptcha: false
                    })
                }, 5000);
                return false;
            }
        })
    };

    //--- ---

    render() {

        const { formActive, defaultValues, disabled } = this.state;
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive, true);
       
        return (                
            <MSection className="mb-4">
                <div className="d-flex flex-row">
                    <h4>Contattaci</h4>
                </div>
                <hr />
                <form className="user mt-5">
                        {code}
                </form>

                {(this.state.errorRecaptcha) && <BoxDanger className="mt-3">reCaptcha obbligatorio</BoxDanger>}
                {(this.state.sendState) && <BoxSuccess className="mt-3">Email inviata correttamente</BoxSuccess>}

                <hr />

                <div className="m-0 text-right">
                    <ReCaptchaV3 id="contactForm" reCaptchaCallback={this.reCaptchaCallback}/>
                    <Button disabled={disabled} color="primary bg-dark" className="mx-2 mt-6" onClick={() => this.onSubmit()}>INVIA</Button>
                    <Button color="primary" outline className="mx-2 mt-6" onClick={() => this.resetForm()}>Annulla</Button>
                </div>
            </MSection>
        )
    }
}