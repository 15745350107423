import React, { Component } from "react";

import {
    adminService,
    /*networkErrorHelper*/
} from "libs";

import {
    MnemoLoading
} from "components/misc";

import { MTable } from "components/table";
import {
    Container, Callout, Button,
    CalloutText, CalloutTitle, Icon
} from 'design-react-kit';

import ReactHtmlParser from 'react-html-parser';

import { AdminCourceFormAnswer } from "components/forms/admin/AdminCourceFormAnswer";

export class AdminCourseFormQuizListAnswer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            idQuestion: 0,
            loading: false,
            formActive: true,
            rndKey: 0,
            payload: [],
            dataAnswer: null,
            answerType: this.props.answerType,
            answerValue: {},
            model: this.props.model
        };
    }

    //--- ---
    tableConfig = {
        columns: [
            {
                id: 'fraction',
                Header: '',
                accessor: 'fraction',
                className: 'text-center',
            },
            {
                id: 'label',
                Header: 'Titolo',
                accessor: 'label',
            }
        ],
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    this.addNewAnswer(this.props.idQuestion, value)
                }
            }
        ]
    }
    //--- ---

    componentDidMount() {
        this.loadAllAnswer(this.props.idQuestion);
    }//componentDidMount


    //--- start mounter ---
    loadAllAnswer(idQuestion, model=this.state.model) {
        let resAPI = null;
        if (parseInt(idQuestion) <= 0 || idQuestion === null) { return false; }

        if (model === 'Course') {
            resAPI = adminService.loadAnwsers(idQuestion).then(({ data }) => {
                this.setState({
                    showList: true,
                    dataAnswer: data.payload.quizAnswer,
                    rndKey: Math.floor((Math.random() * 1000) + 1)
                })

                if (data.payload.quizAnswer.length > 0) {
                    this.writeAnswerList(data.payload.quizAnswer);
                }
            })
        }//Course

        if (model === 'repoQuiz') {
            resAPI = adminService.loadRepoAnwsers(idQuestion)
        }//repoQuiz

        resAPI
            .then(({ data }) => {
                this.setState({
                    showList: true,
                    dataAnswer: data.payload.repoQuizAnswer,
                    rndKey: Math.floor((Math.random() * 1000) + 1)
                })

                if (data.payload.repoQuizAnswer.length > 0) {
                    this.writeAnswerList(data.payload.repoQuizAnswer);
                }
            })
            .catch(error => {
                //networkErrorHelper.notify(error);
                //console.log(error);
            });
    }//loadAllAnswer
    //--- end mounter ---


    writeAnswerList = (data) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data).forEach(([key, val]) => {
            let label = ReactHtmlParser(val.label);
            val = {...val, label};
            items.push(val);
        });

        this.setState({
            loading: false,
            payload: items,
            rndKey: Math.floor((Math.random() * 1000) + 1)
        });
    }//writeAnswerList
    

    addNewAnswer = (idQuestion, idAnswer) => {

        this.setState({
            showList: false,
            formActive: true,
            answerValue: null
        })

        if (parseInt(idAnswer) > 0) {
            let filterAnswer = this.state.dataAnswer.filter(function (item) {
                return item.id === idAnswer;
            });

            this.setState({
                formActive: false,
                answerValue: { ...filterAnswer },
            })
        }
    }//addNewAnswer

    //--- ---
    renderMode = (type, loading) => {
        if (type !== 'text') {
            return (
                <>
                    {loading ? (<MnemoLoading></MnemoLoading>) : (
                        <Container key={this.state.rndKey} className="container-card-body-ListAnswer fix-quiz-form">
                            <MTable
                                key={this.state.rndKey}
                                tableData={this.state.payload}
                                tableConfig={this.tableConfig}
                            />
                            <div>
                                <Button color="primary bg-dark" className="m-2"
                                    onClick={() => this.addNewAnswer(this.props.idQuestion, this.state.idAnswer)}>
                                    Nuova risposta
                                </Button>
                            </div>
                        </Container>
                    )}
                </>
            )

        } else {

            return (
                <>
                    <Callout color="note" highlight={false} tag="div">
                        <CalloutTitle tag="div">
                            <Icon color="" icon="it-info-circle" padding={false} size="" style={{ ariaHidden: true }} />
                        Risposta aperta
                    </CalloutTitle>
                        <CalloutText bigText={false} tag="p">
                            Questa domanda prevede una risposta aperta, per questo motivo non ci sono opzioni di risposta.
                    </CalloutText>
                    </Callout>
                </>
            )
        }
    }//renderMode

    //--- ---

    renderForm = () => {
        return (
            <AdminCourceFormAnswer
                key={`acfa-${this.props.idQuestion}`}
                answerValue={this.state.answerValue}
                idQuestion={this.props.idQuestion}
                formActive={this.state.formActive}
                showList={this.showList}
                model={this.state.model}
                loadAllAnswer={this.loadAllAnswer}
            />
        )
    }

    showList = () => {
        this.setState(state => {
            state.showList = !this.state.showList;
            state.rndKey = Math.floor((Math.random() * 1000) + 1);
            return state;
        });

        this.loadAllAnswer(this.props.idQuestion);
    }//showList


    render() {

        if (this.props.idQuestion === undefined) { return false; }
        const { loading } = this.state;

        let code = '';
        if (this.state.showList) {
            code = this.renderMode(this.state.answerType, loading);
        } else {
            code = this.renderForm();
        }

        return (
            <>{code}</>
        )
    }

}

