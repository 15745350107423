import React from "react";

import {
  userService,
  payloadBuilder,
  invalidFieldsMapper,
  notNullObjectBuilder,
  networkErrorHelper
} from "libs";

import { MInput, FormEnabler, ValidatedForm } from "components/forms";
import {
  Card,
  CardBody,
  Icon
} from "design-react-kit";
import { MnemoCardTag } from "components/misc";

const FIELDS_GROUP = [
  [
    {
      field: "name",
      label: "Nome",
      component: MInput,
      type: "text",
      className: "col-md-6"
    },
    {
      field: "surname",
      label: "Cognome",
      component: MInput,
      type: "text",
      className: "col-md-6"
    }
  ],
  [
    {
      field: "email",
      label: "Email",
      component: MInput,
      type: "email",
      className: "col-md-12",
      infoText: "La modifica della email disabiliterà il tuo account finchè non approverai la nuova casella email. NON Utilizzare caselle PEC"
    }
  ]
];

export class User extends ValidatedForm {

  emptyFields = {
    name: '',
    surname: '',
    email: '',
  };

  state = {
    ...this.emptyFields,
    formActive: false,
    defaultValues: {
      ...this.emptyFields
    }
  };

  ERROR_MESSAGES = {
    name: "Il Nome non è valido (minimo 3 caratteri)",
    surname: "Il Cognome non è valido (minimo 3 caratteri)",
    email: `L'Email non è valida`
  };

  validation = {
    name: value => value.length > 2,
    surname: value => value.length > 2,
    email: value => /\S+@\S+\.\S+/.test(value)
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  loadRemote(additionalState = {}) {
    userService
      .me()
      .then(({ data }) => {

        const defaultValues = notNullObjectBuilder(
          this.emptyFields,
          data.payload.user
        );

        const newState = {
          defaultValues,
          ...additionalState
        };

        this.setState(newState);
      })
      .catch(error => {
        networkErrorHelper.notify(error);
      });
  }

  saveRemote = () => {
    const payload = payloadBuilder(this.state);
    userService
      .update(payload)
      .then(() => {
        const validations = {
          name: '',
          surname: '',
          email: '',
        }
        this.setState({ formActive: false, ...validations });
      })
      .catch(errors => {

        if (networkErrorHelper.is422(errors)) {
          const newState = invalidFieldsMapper(
            errors,
            this.state,
            this.ERROR_MESSAGES
          );
          this.setState({ ...newState });

        } else {
          networkErrorHelper.notify(errors);
        }
      });
  };

  showForm = () => {
    this.setState({ formActive: true });
  }

  renderInfo() {

    const { isNew, defaultValues } = this.state;

    const cardProps = {
      content: <div className="alert alert-info">Dati non ancora inseriti.</div>,
      actionLabel: 'Inserisci',
      tag: 'Dati account',
      smallicon: 'it-card',
      isReadOnly: this.props.isReadOnly,
      actionClick: this.showForm,
      btnChangePass: true,

    }

    if (isNew) {
      cardProps.actionLabel = 'Inserisci';

    } else {
      cardProps.actionLabel = 'Modifica';

      cardProps.content = (
        <>
          <b>Cognome:</b> {defaultValues.surname}<br />
          <b>Nome:</b> {defaultValues.name}<br />
          <b>Email:</b> {defaultValues.email}<br />
        </>
      );
    }

    return (<>
      <MnemoCardTag {...cardProps}></MnemoCardTag>
    </>)
  }

  renderForm() {

    const { formActive, defaultValues } = this.state;
    const { formValid, code } = this.renderFields(FIELDS_GROUP, defaultValues, formActive);

    return (
      <>
        <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
          <div className="flag-icon" />
          <div className="etichetta">
            <Icon
              icon="it-user"
              padding={false}
            />
            <span >Dati Account</span>
          </div>
          <CardBody tag="div">
            <form className="user" id="user_form">
              {code}
            </form>
            <div >
              <FormEnabler
                onToggle={this.toggleForm}
                isFormActive={formActive}
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                disableSave={!formValid}
                hideEdit={this.props.isReadOnly}
              />
            </div>
          </CardBody>
        </Card>
      </>
    );

  }

  render() {
    const { formActive } = this.state;
    if (formActive) {
      return this.renderForm();
    } else {
      return this.renderInfo();
    }
  }
}
