import { proxyBE } from './common';

export const revisionService = {

    listQuizOpenAll(isRevisioned, idUser) {
        return proxyBE.get(`admin/quizreview/listAll/${isRevisioned}/${idUser}`);
    },

    getQuizReview(payload) {      
        return proxyBE.get(`admin/quizreview/${JSON.stringify(payload)}`);        
    },

    updateQuizReview(payload) {      
        return proxyBE.put(`admin/quizreview`, payload);        
    },

    updateQuizReviewFeedback(payload) {      
        return proxyBE.put(`admin/quizreview-feedback`, payload);        
    },

    listAllConfimedExamByRevisorId() {
        return proxyBE.get(`admin/examreview/listConfimedExamByRevisorId`);
    },

    insertExamReview30Cfu(payload) {
        return proxyBE.post(`admin/examreview/insertExamReview30Cfu`, payload);
    },

    updateExamReview30Cfu(payload) {
        return proxyBE.post(`admin/examreview/updateExamReview30Cfu`, payload);
    },

    listAllRevisionedExamByRevisorId() {
        return proxyBE.get(`admin/examreview/listAllRevisionedExamByRevisorId`);
    },

    listAllRevisionPendingExamByRevisorId() {
        return proxyBE.get(`admin/examreview/listAllRevisionPendingExamByRevisorId`);
    },

}