import React from "react";

import {
    FormEnabler,
    ValidatedForm,
    MInput,
    MTextArea,
    MSearch,
    MFile,
    MToggle,
    MCE
} from "components/forms";

import {
    payloadBuilder,
    invalidFieldsMapper,
    networkErrorHelper,
    notNullObjectBuilder,
    adminService,
    stringHelper,   
    dateHelper
} from "libs";

import {
    Card,
    CardBody,
} from 'design-react-kit';
import ReactHtmlParser from 'react-html-parser';
import { BoxInfo } from "components/misc";

export class AdminBlogArticle extends ValidatedForm {
    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ...this.emptyFields,
            defaultValues: { ...this.emptyFields },
            formActive: parseInt(this.props.idBlogArticle) > 0 ? false : true,
            currentBlogCategory: [],
            selectedBlogCategory: [],
            defaultOptionValues: [],
            rndKey: 0
        };

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    //--- ---    

    emptyFields = {
        title: '',
        summary: '',
        content: '',
        date: '',
        slug: '',       
        image: (parseInt(this.props.idBlogArticle) > 0) ? parseInt(this.props.idBlogArticle) : 0 ,
        caption: '',
        isVisible: false,
        isFixedToTop: false
    }

    payloadBlogCategory = [];

    //--- ---

    FIELDS_GROUP = [  
        [
            {
                id: "date",
                name: "date",
                field: "date",
                label: "Data",
                component: MInput,
                type: "date",
                className: "col-md-3"
            },
            {
                id: "isVisible",
                name: "isVisible",
                field: "isVisible",
                label: "Visibile",
                component: MToggle,
                className: "col-md-3"
            },
            {
                id: "isFixedToTop",
                name: "isFixedToTop",
                field: "isFixedToTop",
                label: "in Evidenza",
                component: MToggle,
                className: "col-md-3"
            }
        ],
        [
            {
                id: "title",
                field: "title",
                label: "titolo",
                component: MInput,
                type: "text",                
                onChange: (_, value) => this.onChangeMakeSlug(_, value), 
                className: "col-md-12"
            }
        ],
        [
            {
                id: "summary",
                field: "summary",
                label: "Descrizione",
                component: MTextArea,
                rows: 5,
                className: "col-md-12"
            }
        ],
        [
            {
                id: "slug",
                field: "slug",
                label: "URL (slug)",
                component: MInput,
                type: "text",
                className: "col-md-12 mt-4"
            }
        ],
        [
            {
                field: "content",
                label: "Page editor",
                component: MCE,
                className: "col-md-12 mt-1",
                infoText: "Specificare il testo"
            }
        ],
        [
            {
                id: "image",
                field: "image",
                label: "Immagine principale",
                component: MFile,
                config: { endPoint: '/admin/blog-article-image', mandatory:false, fieldName: 'image', maxFiles: 1 },
                className: "col-md-12"
            }
        ],
        [
            {
                id: "caption",
                field: "caption",
                label: "Descrizione immagine",
                component: MInput,
                className: "col-md-12"
            }
        ]
    ];

    ERROR_MESSAGES = {
        title: "Titolo non valido",
        summary: "Descrizione non valida (max 512 caratteri)",
        slug: "Slug (URL) non valido"
    };

    validation = {
        title: value => value.length>0 && value.length <= 255,
        summary: value => { return (value !== '' && value.length > 512) ? false : true} ,
        slug: value => value.length>0 && value.length <= 255,
    };
    
    //--- ---

    componentDidMount(){
        this.loadRemote();
        
        // load list category and popolate MSelect
        this.loadRemoteParent();
    }    

    onChangeMakeSlug = (_, value) => {
        const ret = stringHelper.makeSlug(value);
        this.setState({
            slug: ret,
            defaultValues:{
                ...this.state.defaultValues,
                slug: ret
            }
        })
    }

    changeBlogCategory = (i, value) =>{
        let currentBlogCategory = []
        if (value && value.length > 0) {
            currentBlogCategory = value.map((item, i) => {
                return item.value;
            });
        }

        this.setState({ currentBlogCategory, selectedBlogCategory: value });
    }//changeBlogCategory


    loadRemoteParent(){       
        let filter = { searchFilter:{ isVisible: true } };
        
        adminService.loadAllBlogCategory(filter).then(({ data }) => {
            
            Object.entries(data.payload.blogCategories).reverse().forEach(([key, val]) => {
                this.payloadBlogCategory.push(
                    {
                        label: ReactHtmlParser(val.title),
                        value: val.id
                    }
                )
            });
        })
        .catch(error => {
            console.log(error);
            if (!networkErrorHelper.is404(error)) {
                networkErrorHelper.notify(error);
            }
        });
    }//loadRemoteParent

    
    loadRemote(additionalState = {}) {
        //--- prevent loadRemote when not required ---
        if (parseInt(this.props.idBlogArticle) <= 0) {
            return false;
        }

        adminService.loadBlogArticle(parseInt(this.props.idBlogArticle))
            .then(({ data }) => {

                const defaultValues = notNullObjectBuilder(
                    this.emptyFields,
                    data.payload.blogArticles,
                    {
                        date: dateHelper.toUSDate(data.payload.blogArticles.date),                        
                        image: data.payload.blogArticles.id,
                        isVisible: data.payload.blogArticles.isVisible
                    } 
                );
                
                const newState = {
                    defaultValues,
                    ...additionalState,
                    loading:false,
                    defaultOptionValues: data.payload.blogCategories,
                    rndKey: Math.floor((Math.random() * 1000) + 1)
                };

                this.setState(newState);
            })
            .catch(error => {
                networkErrorHelper.notify(error);
                console.log(error);
            });
    }//loadRemote

    onBackPage = () => {
        window.history.back();
    }//onBackPage


    saveRemote = () => {
        if((this.state.currentBlogCategory.length === 0 && this.state.defaultOptionValues.length === 0)){ return }

        let payload = payloadBuilder(this.state);
        payload.selectedBlogCategory = this.state.selectedBlogCategory;
       
        if (parseInt(this.props.idBlogArticle) > 0) {

            adminService.updateBlogArticle(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });

        } else {

            adminService.insertBlogArticle(payload).then(
                (result) => {
                    this.onBackPage();
                }
            )
            .catch(errors => {
                if (networkErrorHelper.is422(errors)) {
                    const newState = invalidFieldsMapper(
                        errors,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(errors);
                }
            });
        }
    }//saveRemote

    //--- ---

    render() {
        
        if (this.loading){
            return (<></>);
        }

        let { formActive, defaultValues, currentBlogCategory, selectedBlogCategory, defaultOptionValues, rndKey } = this.state;

        if (parseInt(this.props.idBlogArticle) === 0) {
            formActive = true;
        }
        
        const { formValid, code } = this.renderFields(this.FIELDS_GROUP, defaultValues, formActive);

        return (
            <>
                <Card tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                    <div className="flag-icon" />
                    <CardBody tag="div">

                        <MSearch
                            key={rndKey}
                            field="category-select"
                            id="category-select"
                            name="-select"
                            placeHolder="Inserisci o seleziona Categoria"
                            payload={this.payloadBlogCategory}
                            isMulti="true"
                            selectedOption={selectedBlogCategory}
                            onChange={this.changeBlogCategory}
                            infoText = {'Devi selezionare almeno una categoria'}
                            defaultOptionValues = {defaultOptionValues}
                        />

                        {(currentBlogCategory.length === 0 && defaultOptionValues.length === 0) && (<BoxInfo className="mt-3">Devi selezionare almeno una categoria.</BoxInfo>)}

                        <form className="user mt-5" id="courseform">
                            {code}
                        </form>

                        <div className="mt-4">
                            <FormEnabler
                                onSubmit={this.onSubmit}
                                onCancel={parseInt(this.props.idBlogArticle) > 0 ? this.onCancel : this.onBackPage}
                                onToggle={this.toggleForm}
                                isFormActive={formActive}
                                disableSave={!formValid}
                            />
                        </div>
                    </CardBody>
                </Card>               
            </>
        );
    }
}
