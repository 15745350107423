import i18n from "app/i18n";
import { cache } from "libs";
import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

class QuestionFillFree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            choice: [],
            idQuiz: this.props.idQuiz,
            inputDefVal: '',
            validate: {
                isPaste: false, //true is allowed
                maxChar: 256, //zero (0) to no limits
                currChar: 0 //init 
            },
            attemptTracker: (this.props.attemptTracker) ? this.props.attemptTracker : false
        };
    }

    //--- ---

    componentDidMount() {
        const inputDefVal = this.isChecked(this.state.idQuiz, this.props.idQuestion);
        this.setState({ inputDefVal })
    }

    drop = (e) => {
        e.stopPropagation();
        e.currentTarget.value = '';
        this.setMyState(this.props.idQuestion, e.dataTransfer.getData("text"));
        document.querySelector(`#${this.props.idQuestion, e.dataTransfer.getData("sourceId")}`).classList.add("dropped");
        document.querySelector(`#${this.props.idQuestion, e.dataTransfer.getData("sourceId")}`).removeAttribute('draggable');
    }//drop

    //--- ---

    /** */
    //--- check if exists local value ---
    isChecked = (idQuiz, idQuestion) => {
        let getValue = cache.get(`quiz-${idQuiz}-${idQuestion}`);
        let result = '';

        if (getValue !== null) {
            let valAnswer = JSON.parse(JSON.stringify(getValue));
            setTimeout(() => {
                this.setMyState(idQuestion, valAnswer[0].label)
            }, 150);
            result = valAnswer[0].label;
        }

        return result;
    }//isChecked

    /** */
    handleOnPaste = (e) => {
        if (!this.state.validate.isPaste) {
            e.preventDefault();
            return false;
        }
    }//handleOnPaste

    /** */
    setMyState = (idQuestion, answer) => {
        let data = {
            idQuestion: idQuestion,
            label: answer
        }

        //--- validate textarea content ---
        if (((this.state.validate.currChar >= this.state.validate.maxChar) ||
            (answer.length >= this.state.validate.maxChar))
            && this.state.validate.maxChar > 0) {
            return false;
        }

        if (answer.length !== this.state.validate.currChar) {
            this.setState({
                validate: {
                    ...this.state.validate,
                    currChar: answer.length
                }
            })

            this.setState(state => {
                state.choice.pop();
                state.choice.push(data);

                //--- manage localStorage answer ---
                if (this.state.choice.length === 0) {
                    cache.forget(`quiz-${this.state.idQuiz}-${idQuestion}`, this.state.choice);
                } else {
                    cache.set(`quiz-${this.state.idQuiz}-${idQuestion}`, this.state.choice);
                }
            });
        }
    }//setMyState

    /** */
    retRightRender = (resUserAnswer) => {
        const string = this.props.label;
        const subString = '{{answers}}';

        const words = string.split(' ');
        const stringReturn = words.map((word, index) => {
            if (word === subString) {
                return (resUserAnswer.length === 0) && <input
                    id={`t${this.props.idQuestion}`}
                    name={`t${this.props.idQuestion}`}
                    maxLength={this.state.validate.maxChar}
                    onKeyUp={(e) => { this.setMyState(this.props.idQuestion, e.target.value); }}
                    onPaste={(e) => { this.handleOnPaste(e) }}
                    defaultValue={this.state.inputDefVal}
                    className="resetQuizSelect"
                    order={index}
                />
            } else {
                return ReactHtmlParser(word.replace(/<\/?[^>]+(>|$)/g, "")) + ' ';
            }
        });

        return stringReturn;
    }//retRightRender

    render() {

        if (!this.state.type) return false;
        const resUserAnswer = this.props.answers.filter((item) => { return item.userAnswer === true });
        const rightAnswer = this.props.answers.filter((item) => { return parseInt(item.grade) > 0 });
        
        return (
            <>
                {/** regular (multiple whit replace) layout */}
                {/*(typeof this.props.metadata.dnd === 'undefined') && <div className="wrapperResetQuizSelect">{this.retRightRender(resUserAnswer)}</div>*/}

                {/** regular layout */}
                {(typeof this.props.metadata.dnd === 'undefined' || this.props.metadata?.dnd === false) && <div className="wrapperResetQuizSelect">
                    {ReactHtmlParser(this.props.label.split('{{answers}}')[0])}

                    {(resUserAnswer.length === 0) && <input
                        id={`t${this.props.idQuestion}`}
                        name={`t${this.props.idQuestion}`}
                        maxLength={this.state.validate.maxChar}
                        onKeyUp={(e) => { this.setMyState(this.props.idQuestion, e.target.value); }}
                        onPaste={(e) => { this.handleOnPaste(e) }}
                        defaultValue={this.state.inputDefVal}
                        className="resetQuizSelect"
                    />}

                    {/*<b className="textInfo"><u>{ReactHtmlParser(this.props.answers[0].label.replace(/<\/?[^>]+(>|$)/g, ""))}</u></b>*/}
                    {(resUserAnswer.length === 1) && <b className={(parseInt(resUserAnswer[0].grade) === 0) ? 'textDanger' : 'textSuccess'}><u>
                        {ReactHtmlParser(resUserAnswer[0].label)}
                    </u></b>}

                    {ReactHtmlParser(this.props.label.split('{{answers}}')[1])}

                    {/** show/suggest right answer */}
                    {(this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? ReactHtmlParser(`</br></br><b>${i18n.t('rightAnswer')}:</b></br>`) : ''}
                    {                        
                        rightAnswer.map((option, idx)=>{
                            return (
                                (this.props.metadata?.feedbackFinal?.showAnsOnAttempt && (this.state.attemptTracker >= this.props.metadata?.feedbackFinal?.showAnsOnAttempt || 0)) ? <b key={`opans${idx}`} className={'textSuccess'}>- {ReactHtmlParser(` ${option.label.replace(/<[^>]+>/g, '')} </br>`)}</b> : ''
                            )
                        })
                    }

                </div>}

                {/** drag&drop layout */}
                {(typeof this.props.metadata.dnd !== 'undefined') && ((this.props.metadata.dnd === true) && <div className="wrapperResetQuizSelect">
                    {ReactHtmlParser(this.props.label.split('{{answers}}')[0])}

                    {(resUserAnswer.length === 0) && <input
                        id={`t${this.props.idQuestion}`}
                        name={`t${this.props.idQuestion}`}
                        maxLength={this.state.validate.maxChar}
                        onKeyUp={(e) => { e.target.value = ''; e.preventDefault(); return false }}
                        onPaste={(e) => { e.preventDefault() }}
                        defaultValue={this.state.inputDefVal}
                        placeholder={i18n.t('ddRightSentence')}
                        className="resetQuizSelect resetQuizSelectOver"
                        onDrop={(e) => { this.drop(e) }}
                    />}

                    {/*<b className="textInfo"><u>{ReactHtmlParser(this.props.answers[0].label.replace(/<\/?[^>]+(>|$)/g, ""))}</u></b>*/}
                    {(resUserAnswer.length === 1) && <b className={(parseInt(resUserAnswer[0].grade) === 0) ? 'textDanger' : 'textSuccess'}><u>
                        {ReactHtmlParser(resUserAnswer[0].label)}
                    </u></b>}

                    {ReactHtmlParser(this.props.label.split('{{answers}}')[1])}
                </div>)}
            </>
        );
    }
}

export default QuestionFillFree;