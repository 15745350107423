import React, { Component } from 'react';

import {
    Card,
    CardBody, CardTitle,
    CardText, Button,
    CardSignature, Icon,
    CardFooter
} from 'design-react-kit';

import { Link } from 'react-router-dom';
import { SupportoTecnico } from './SupportoTecnico';

import './MnemoCardTag.css';
import { ROUTES } from 'const';

export class MnemoCardTag extends Component {


    render() {

        const {
            isReadOnly,
            title, content,
            tag, signature, color,
            actionLabel, actionClick,
            smallicon, actionParam,
            className, actionLink, btnChangePass
        } = this.props;

        let to = actionLink;
        if (actionParam) {
            to += `/${actionParam}`;
        }

        return (
            <>
                <div className={className}>
                    <Card noWrapper={false} tag="div" className="card-bg card-big rounded shadow border-bottom-card">
                        <div className="flag-icon" />
                        <div className="etichetta">
                            {smallicon && (
                                <Icon
                                    color={color}
                                    icon={smallicon}
                                    padding={false}
                                    size=""
                                />
                            )}
                            <span className={`text-${color}`} >{tag}</span>                            
                        </div>
                    
                        <CardBody tag="div">
                            <CardTitle tag="h5">{title}</CardTitle>
                            {content}

                            <CardText>
                                {this.props.children}
                            </CardText>

                            <CardSignature>{signature}</CardSignature>
                            {!isReadOnly ?
                                (<>
                                    {actionLabel && (
                                        <CardFooter >
                                        { actionClick && (
                                            <Button
                                                className="float-right"
                                                color="primary"
                                                outline
                                                tag="button"
                                                onClick={() => { actionClick(true) }
                                                }>
                                                {actionLabel}
                                            </Button>
                                        )}

                                        { btnChangePass && (
                                            <Link to={ROUTES.PASSWORD_RESET_UNTOKEN} className="float-right mx-2" color="primary bg-dark" tag="button" >
                                                <Button color="primary" outline>Modifica password</Button>
                                            </Link>
                                        )}

                                        {actionLink && (
                                            <Link to={to} className="float-right"
                                                color="primary bg-dark"
                                                tag="button" >
                                                {actionLabel}
                                            </Link>
                                        )}
                                        </CardFooter>
                                    )}
                                </>)
                                : (
                                    <CardFooter className="text-right" >Dati non modificabili. Se hai riscontrato degli errori nei dati inseriti  <SupportoTecnico /></CardFooter>
                                )}
                        </CardBody>
                    </Card>
                </div>
            </>
        );
    }
}