import React from "react";

import {
    MLabel,
    MInput,
    MSelect,
    ValidatedForm,
    MButton
} from "components/forms";

import {
    payloadBuilder,
    notNullObjectBuilder,
    enrollmentAdminService, networkErrorHelper, invalidFieldsMapper, adminService
} from "libs";
import { BoxSuccess } from "components/misc";

export class EnrollFillFormDS2 extends ValidatedForm {

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
        this.applyPromo = this.applyPromo.bind(this);
        this.validateApplyButton = this.validateApplyButton.bind(this);
    }

    PAYLOADS = {
        examSites: [],
        freeCourses: [],
        examSession: null,
        importToPay: [
            { label: 'Seleziona importo', value: '-1' },
        ],
    }

    FIELDS_GROUP = [
        [
            {
                field: "importToPay",
                label: "Importo da pagare",
                payload: { options: this.PAYLOADS.importToPay },
                component: MSelect,
                onChange: (_, value) => this.onChangePayment(_, value),
                className: "col-md-6"
            }
        ], [
            {
                field: "officeCode",
                label: "Codice Riservato",
                component: MInput,
                type: "text",
                className: "col-md-6",
                onKeyUp: () => {
                    this.validateApplyButton(this.state.officeCode.value)
                }
            },
            {
                id: "applyPromoBtn",
                label: "Applica",
                infoText: 'Per convalidare il codice riservato digitato cliccare su "Applica"',
                component: MButton,
                className: "col-md-6",
                classElement: "btn btn-primary bg-dark",
                disabled: true,
                onClick: () => {
                    this.applyPromo(this.state.officeCode.value)
                }
            }
        ], [
            {
                field: "idFreeCourse",
                label: "Richiedi Gratuitamente il tuo Omaggio di benvenuto: Iscrizione e partecipazione ad uno dei seguenti Corsi ",
                payload: { options: this.PAYLOADS.freeCourses },
                component: MSelect,
                className: "col-md-12"
            }
        ],
        [
            {
                field: "preferExamOnline",
                label: "Esami a distanza",
                infoText: "Compatibilmente con le norme sanitarie vigenti, in caso di esami a distanza, puoi indicare un Città",
                component: MLabel,
                className: "col-md-6 pt-3"
            },
            {
                field: "preferExamPresence",
                label: "Esami in Presenza",
                infoText: "Seleziona una città per l'eventuale esame in presenza",
                component: MLabel,
                className: "col-md-6 pt-3"
            }
        ], [
            {
                field: "examSiteOnline",
                label: "Inserisci una città",
                component: MInput,
                className: "col-md-6  pt-3"
            },
            {
                field: "examSite",
                payload: { options: this.PAYLOADS.examSites },
                component: MSelect,
                className: "col-md-6 pt-3"
            }
        ]
    ];

    ERROR_MESSAGES = {
        importToPay: "Specificare l'importo da pagare",
        examSite: 'Specificare una sede esami',
        idFreeCourse: 'Selezionare un corso gratuito'
    };

    validation = {
        importToPay: value => value >= 0,
        examSite: value => value > '',
        idFreeCourse: value => value > 0,
    };

    emptyFields = {
        examSiteOnline: '',
        officeCode: '',
        examSite: 0,
        hasBonusCourse: false,
        idFreeCourse: 0,
        importToPay: -1
    };

    state = {
        promoAmount: 0,
        isProvider: false,
        onlyReferred: false,
        loading: true,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    onChangePayment = (_, value) => {
        const index = (this.PAYLOADS.importToPay).findIndex(paring => paring.value === value);
        this.setState({
            isPaymentReferred: (index === 2) ? true : false
        });
    }

    validateApplyButton(officeCode = '') {
        const btnApply = document.getElementById("applyPromoBtn");
        (officeCode === '') ? btnApply.classList.add('d-none') : btnApply.classList.remove('d-none')
    }//validateApplyButton

    applyPromo(officeCode = '') {
        const filter = {
            officeCode,
            courseCode: this.props.enrollData.courseCode
        }

        this.setState({ loading: true });

        adminService.verifyPromotionalCode(filter).then((result) => {
            const { amount, onlyReferred, isProvider } = result.data.payload.promo;

            (parseFloat(amount) > 0) ? this.setState({ promoAmount: amount }) : this.setState({ promoAmount: 0 });

            if (!onlyReferred) {
                //--- update price list ---
                const imports = this.state.PAYLOADS.importToPay.map((item, idx) => {
                    let newItem = { label: item.label, value: item.value };
                    // idx ===0 identifica la "Soluzione Unica"
                    if ((newItem.value - amount) >= 0 && idx === 1) {
                        newItem.label = item.label.replace(item.value, (item.value - amount).toFixed(2));
                        newItem.value = (item.value - amount);
                    }

                    return newItem;
                });

                this.PAYLOADS.importToPay.length = 0;
                this.PAYLOADS.importToPay.push(...imports);
            }

            this.setState({ loading: false, onlyReferred, isProvider });
            //--- hide apply button ---
            document.getElementById("applyPromoBtn").classList.add('d-none');
        })
            .catch(error => {

                this.PAYLOADS.importToPay.length = 0;
                this.PAYLOADS.importToPay.push(...this.state.PAYLOADS.importToPay);

                this.setState({ promoAmount: 0 });

                /** show right message errors */
                if (networkErrorHelper.is422(error) || networkErrorHelper.is404(error)) {
                    const newState = invalidFieldsMapper(
                        error,
                        this.state,
                        this.ERROR_MESSAGES
                    );
                    this.setState({ ...newState });
                } else {
                    networkErrorHelper.notify(error);
                }

                this.setState({ loading: false, onlyReferred: false, isProvider: false });
            })
    }//applyPromo

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const { idExamSession, courseCode } = this.props.enrollData;

        if (this.PAYLOADS.examSites.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.examSites(idExamSession)
                .then(({ data }) => {

                    const { payload } = data;
                    const sites = payload.map(({ value }) => ({ label: value, value: value }));

                    this.PAYLOADS.examSites.unshift({ label: 'Seleziona', value: '' });
                    this.PAYLOADS.examSites.push(...sites);

                    this.setState({
                        examSites: { options: this.PAYLOADS.examSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        if (this.PAYLOADS.freeCourses.length < 1) {
            // load paylods to fill the select options 
            enrollmentAdminService.freeCourses(courseCode)
                .then(({ data }) => {

                    const { payload } = data;
                    const courses = payload.map(({ id, title }) => ({ label: title, value: id }));

                    this.PAYLOADS.freeCourses.unshift({ label: 'Seleziona corso', value: 0 });
                    this.PAYLOADS.freeCourses.push(...courses);

                    this.setState({
                        esamSites: { options: this.PAYLOADS.esamSites }
                    });

                })
                .catch(errors => {
                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        if (this.PAYLOADS.importToPay.length <= 1) {

            enrollmentAdminService
                .importsToPay(courseCode)
                .then(({ data }) => {

                    const { payload } = data;
                    this.PAYLOADS.importToPay.length = 0
                    this.PAYLOADS.importToPay.push({ label: 'Seleziona importo', value: '-1' });
                    this.PAYLOADS.importToPay.push(...payload);

                    let importToPay = [];
                    importToPay.push({ label: 'Seleziona importo', value: '-1' });
                    importToPay.push(...payload);

                    this.setState({
                        loading: false,
                        PAYLOADS: {
                            importToPay,
                        }
                    });
                })
                .catch(errors => {

                    console.log(errors);
                    networkErrorHelper.notify(errors);
                });
        }

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    saveRemote = () => {

        this.payload = payloadBuilder(this.state);
        const { idFreeCourse } = this.state.defaultValues;

        if (idFreeCourse) {
            this.payload.hasBonusCourse = true;
            const courses = this.PAYLOADS.freeCourses.filter(item => (item.value == idFreeCourse));
            if (courses) {
                this.payload.titleFreeCourse = courses[0].label;
            }
        } else {
            this.payload.titleFreeCourse = '';
            this.payload.hasBonusCourse = false;
        }

        this.payload.promoImport = this.state.promoAmount;      
        this.payload.isPaymentReferred = this.state.isPaymentReferred;
        if (parseFloat(this.state.promoAmount) > 0) {
            this.payload.promoDescription = `sconto di euro ${this.state.promoAmount} `;
            this.payload.promoDescription += this.payload.isPaymentReferred ? ' da detrarre da ultima rata ' : ' detratto da soluzione unica';
            this.payload.promoDescription += ` - codice ufficio ${this.state.officeCode.value}`;
        }
        const validations = {
            examSite: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { loading, defaultValues, promoAmount, onlyReferred } = this.state;
        if (loading) return <></>;
        const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

        return (<>
            {code}
            {((promoAmount > 0) && (onlyReferred)) && (<BoxSuccess>sconto di <strong>{promoAmount}</strong> euro da detrarre dall'ultima rata</BoxSuccess>)}
            {((promoAmount > 0) && (!onlyReferred)) && (<BoxSuccess>sconto di <strong>{promoAmount}</strong> euro già detratto dalla soluzione unica o da detrarre dall'ultima rata</BoxSuccess>)}

        </>);
    }
}