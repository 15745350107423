import React, { Component } from "react";
import { enrollmentService, dateHelper, networkErrorHelper } from "libs";
import { ROUTES } from "const";

import {
  MnemoCardSlim,
  MnemoLoading,
  BoxSuccess,
  Title, BoxDanger, SupportoTecnico
} from "components/misc";

import {
  Container
} from 'design-react-kit';
export class EnrollmentPending extends Component {

  state = {
    loading: true,
    loadingError: false,
    myEnrollments: 0
  };

  componentDidMount() {
    this.loadRemote();
  }

  loadRemote() {

    enrollmentService
      .myPending()
      .then(({ data }) => {
                
        const enrollments = data.payload.enrollments.map(enroll => {
         
          let content = 'data avvio ' + dateHelper.toITDate(enroll.dateEnrollmentStart);
          content += ' - stato: ' + enroll.status;

          return Object.assign({},
            {
              title: enroll.code + ' - ' + enroll.title,
              content,
              actionLink: ROUTES.MY_ENROLLMENT,
              actionParam: enroll.orderNumber,
            }
          );
        })

        this.setState({ myEnrollments: enrollments, loading: false, loadingError: false });

      })
      .catch(error => {

        console.log(error);
        let loadingError = false;
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
          loadingError = true;
        }

        this.setState({ loading: false, loadingError });
      });
  }

  render() {

    const { loading, loadingError, myEnrollments } = this.state;

    return (
      <>
        {loading ? (<MnemoLoading></MnemoLoading>) : (
          <>
            <section className="my-4">
              <Container>
                <Title>Le mie iscrizioni pendenti</Title>
                {loadingError ? (<BoxDanger>Si è verificato un errore durante
                                    il caricamento dei dati, se il problema persiste puoi <SupportoTecnico /></BoxDanger>) : (
                    <>
                      {myEnrollments
                        ? myEnrollments.map((enroll, i) => (
                          <div className="row" key={`enroll-${i}`}>
                            <div className="col-12">
                              <MnemoCardSlim {...enroll} />
                            </div>
                          </div>
                        )) : <BoxSuccess>Non risultano domande di iscrizioni in corso da completare.</BoxSuccess>
                      }
                    </>)}
              </Container>
            </section>
          </>)}
      </>
    );
  }
}
