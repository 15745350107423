import { MCheckbox, MSelect } from 'components/forms';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

export class FormTFAOrdinario extends Component {

    state = {
        schoolType: '',
        validationError: '',
        competitionClass: '',     
        accept12: false,
        accept13: false,
        accept14: false,
        accept15: false,
    }

    PAYLOAD_SCHOOL =
        {
            options: [
                { label: 'SELEZIONARE ORDINE E GRADO DI SCUOLA', value: '' },
                { label: 'Scuola Secondaria di Primo Grado', value: 'Secondaria di Primo Grado' },
                { label: 'Scuola Secondaria di Secondo Grado', value: 'Secondaria di Secondo Grado' },
            ]
        }

    PAYLOAD_COMPETITION_CLASS =
        {
            'Secondaria di Primo Grado':
            {
                options: [
                    { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                    { label: 'A001 ARTE E IMMAGINE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A001' },
                    { label: 'A022 ITALIANO, STORIA, GEOGRAFIA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A022' },
                    { label: 'A028 MATEMATICA E SCIENZE', value: 'A028' },
                    { label: 'A030 MUSICA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A030' },
                    { label: 'A049 SCIENZE MOTORIE E SPORTIVE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A049' },
                    { label: 'A056 STRUMENTO MUSICALE NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A056' },
                    { label: 'A060 TECNOLOGIA NELLA SCUOLA SECONDARIA DI I GRADO', value: 'A060' },
                    { label: 'A070 ITALIANO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO SLOVENA O BILINGUE', value: 'A070' },
                    { label: 'A071 SLOVENO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO SLOVENA O BILINGUE', value: 'A071' },
                    { label: 'A077 LINGUA E CULTURA LADINA, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO LADINA', value: 'A077' },
                    { label: 'A078 ITALIANO (SECONDA LINGUA), STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO TEDESCA', value: 'A078' },
                    { label: 'A084 TEDESCO (SECONDA LINGUA), STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO BOLZANO', value: 'A084' },
                    { label: 'A085 TEDESCO, STORIA EDUC. CIVICA, GEOGRAFIA SCUOLA SEC. DI I GRADO IN LING. TEDESCA', value: 'A085' },
                    { label: 'AA25 LINGUA INGLESE E SECONDA LINGUA COMUNITARIA NELLA SCUOLA SECONDARIA  I GRADO', value: 'AA25' },
                ]
            },
            'Secondaria di Secondo Grado':
            {
                options: [
                    { label: 'SELEZIONARE LA CLASSE DI CONCORSO', value: '' },
                    { label: 'A002 DESIGN DEI METALLI, DELL\'OREFICERIA, DELLE PIETRE DURE E DELLE GEMME', value: 'A002' },
                    { label: 'A003 DESIGN DELLA CERAMICA', value: 'A003' },
                    { label: 'A004 DESIGN DEL LIBRO', value: 'A004' },
                    { label: 'A005 DESIGN DEL TESSUTO E DELLA MODA', value: 'A005' },
                    { label: 'A006 DESIGN DEL VETRO', value: 'A006' },
                    { label: 'A007 DISCIPLINE AUDIOVISIVE', value: 'A007' },
                    { label: 'A008 DISCIPLINE GEOMETRICHE, ARCHITETTURA, DESIGN D\'ARREDAMENTO E SCENOTECNICA', value: 'A008' },
                    { label: 'A009 DISCIPLINE GRAFICHE, PITTORICHE E SCENOGRAFICHE', value: 'A009' },
                    { label: 'A010 DISCIPLINE GRAFICO-PUBBLICITARIE', value: 'A010' },
                    { label: 'A011 DISCIPLINE LETTERARIE E LATINO', value: 'A011' },
                    { label: 'A012 DISCIPLINE LETTERARIE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO', value: 'A012' },
                    { label: 'A013 DISCIPLINE LETTERARIE, LATINO E GRECO', value: 'A013' },
                    { label: 'A014 DISCIPLINE PLASTICHE, SCULTOREE E SCENOPLASTICHE', value: 'A014' },
                    { label: 'A015 DISCIPLINE SANITARIE', value: 'A015' },
                    { label: 'A016 DISEGNO ARTISTICO E MODELLAZIONE ODONTOTECNICA', value: 'A016' },
                    { label: 'A017 DISEGNO E STORIA DELL\'ARTE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO', value: 'A017' },
                    { label: 'A018 FILOSOFIA E SCIENZE UMANE', value: 'A018' },
                    { label: 'A019 FILOSOFIA E STORIA', value: 'A019' },
                    { label: 'A020 FISICA', value: 'A020' },
                    { label: 'A021 GEOGRAFIA', value: 'A021' },
                    { label: 'A024 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO', value: 'A024' },
                    { label: 'A026 MATEMATICA', value: 'A026' },
                    { label: 'A027 MATEMATICA E FISICA', value: 'A027' },
                    { label: 'A031 SCIENZE DEGLI ALIMENTI', value: 'A031' },
                    { label: 'A032 SCIENZE DELLA GEOLOGIA E DELLA MINERALOGIA', value: 'A032' },
                    { label: 'A033 SCIENZE E TECNOLOGIE AERONAUTICHE', value: 'A033' },
                    { label: 'A034 SCIENZE E TECNOLOGIE CHIMICHE', value: 'A034' },
                    { label: 'A035 SCIENZE E TECNOLOGIE DELLA CALZATURA E DELLA MODA', value: 'A035' },
                    { label: 'A036 SCIENZE E TECNOLOGIA DELLA LOGISTICA', value: 'A036' },
                    { label: 'A037 SCIENZE E TECNOLOGIE DELLE COSTRUZIONI TECNOLOGIE E TECNICHE DI RAPPRESENTAZIONE GRAFICA', value: 'A037' },
                    { label: 'A038 SCIENZE E TECNOLOGIE DELLE COSTRUZIONI AERONAUTICHE', value: 'A038' },
                    { label: 'A039 SCIENZE E TECNOLOGIE DELLE COSTRUZIONI NAVALI', value: 'A039' },
                    { label: 'A040 SCIENZE E TECNOLOGIE ELETTRICHE ED ELETTRONICHE', value: 'A040' },
                    { label: 'A041 SCIENZE E TECNOLOGIE INFORMATICHE', value: 'A041' },
                    { label: 'A042 SCIENZE E TECNOLOGIE MECCANICHE', value: 'A042' },
                    { label: 'A043 SCIENZE E TECNOLOGIE NAUTICHE', value: 'A043' },
                    { label: 'A044 SCIENZE E TECNOLOGIE TESSILI, DELL\'ABBIGLIAMENTO E DELLA MODA', value: 'A044' },
                    { label: 'A045 SCIENZE ECONOMICO-AZIENDALI', value: 'A045' },
                    { label: 'A046 SCIENZE GIURIDICO-ECONOMICHE', value: 'A046' },
                    { label: 'A047 SCIENZE MATEMATICHE APPLICATE', value: 'A047' },
                    { label: 'A048 SCIENZE MOTORIE E SPORTIVE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO', value: 'A048' },
                    { label: 'A050 SCIENZE NATURALI, CHIMICHE E BIOLOGICHE', value: 'A050' },
                    { label: 'A051 SCIENZE, TECNOLOGIE E TECNICHE AGRARIE', value: 'A051' },
                    { label: 'A052 SCIENZE, TECNOLOGIE E TECNICHE DI PRODUZIONI ANIMALI', value: 'A052' },
                    { label: 'A053 STORIA DELLA MUSICA', value: 'A053' },
                    { label: 'A054 STORIA DELL\'ARTE', value: 'A054' },
                    { label: 'A055 STRUMENTO MUSICALE NELLA SCUOLA SECONDARIA DI II GRADO', value: 'A055' },
                    { label: 'A057 TECNICA DELLA DANZA CLASSICA', value: 'A057' },
                    { label: 'A058 TECNICA DELLA DANZA CONTEMPORANEA', value: 'A058' },
                    { label: 'A059 TECNICA DI ACCOMPAGNAMENTO ALLA DANZA E TEORIA, PRATICA MUSICALE PER LA DANZA', value: 'A059' },
                    { label: 'A061 TECNOLOGIE E TECNICHE DELLE COMUNICAZIONI MULTIMEDIALI', value: 'A061' },
                    { label: 'A062 TECNOLOGIE E TECNICHE PER LA GRAFICA', value: 'A062' },
                    { label: 'A063 TECNOLOGIE MUSICALI', value: 'A063' },
                    { label: 'A064 TEORIA, ANALISI E COMPOSIZIONE', value: 'A064' },
                    { label: 'A065 TEORIA E TECNICA DELLA COMUNICAZIONE', value: 'A065' },
                    { label: 'A072 DISCIPLINE LETTERARIE (ITALIANO SECONDA LINGUA) II GRADO LINGUA SLOVENA', value: 'A072' },
                    { label: 'A073 DISCIPLINE LETTERARIE SCUOLE II GRADO LINGUA SLOVENA O BILINGUE', value: 'A073' },
                    { label: 'A074 DISCIPLINE LETTERARIE E LATINO CON LINGUA DI INSEGNAMENTO SLOVENA', value: 'A074' },
                    { label: 'A075 DISCIPLINE LETTERARIE, LATINO E GRECO CON LINGUA DI INSEGNAMENTO SLOVENA', value: 'A075' },
                    { label: 'A079 DISCIPLINE LETTERARIE (ITALIANO II LINGUA) SCUOLE II GRADO IN LINGUA TEDESCA', value: 'A079' },
                    { label: 'A080 DISCIPLINE LETTERARIE II GRADO IN LINGUA TEDESCA O CON LINGUA TEDESCA IN LOC LADINE', value: 'A080' },
                    { label: 'A081 DISCIPLINE LETTERARIE E LATINO NEI LICEI LINGUA TEDESCA O CON LING TEDESCA IN LOC LADINE', value: 'A081' },
                    { label: 'A082 DISCIPLINE LETTERARIE E LATINO E GRECO NEI LICEI LINGUA TEDESCA O CON LING TEDESCA IN LO', value: 'A082' },
                    { label: 'A083 DISCIPLINE LETTERARIE (TEDESCO II LINGUA) SCUOLE II GRADO IN LINGUA ITALIANA', value: 'A083' },
                    { label: 'AA24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (FRANCESE)', value: 'AA24' },
                    { label: 'AA55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (ARPA)', value: 'AA55' },
                    { label: 'AB24LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (INGLESE)', value: 'AB24' },
                    { label: 'AB55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CHITARRA)', value: 'AB55' },
                    { label: 'AC24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (SPAGNOLO)', value: 'AC24' },
                    { label: 'AC55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CLARINETTO)', value: 'AC55' },
                    { label: 'AD24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (TEDESCO)', value: 'AD24' },
                    { label: 'AD55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CORNO)', value: 'AD55' },
                    { label: 'AE24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (RUSSO)', value: 'AE24' },
                    { label: 'AE55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (FAGOTTO)', value: 'AE55' },
                    { label: 'AF24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (ALBANESE)', value: 'AF24' },
                    { label: 'AF55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (FISARMONICA)', value: 'AF55' },
                    { label: 'AG24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (SLOVENO)', value: 'AG24' },
                    { label: 'AG55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (FLAUTO)', value: 'AG55' },
                    { label: 'AG24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (SERBO-CROATO)', value: 'AH24' },
                    { label: 'AH55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (OBOE)', value: 'AH55' },
                    { label: 'AI24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (CINESE)', value: 'AI24' },
                    { label: 'AI55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (PERCUSSIONI)', value: 'AI55' },
                    { label: 'AJ24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (GIAPPONESE)', value: 'AJ24' },
                    { label: 'AJ55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (PIANOFORTE)', value: 'AJ55' },
                    { label: 'AK24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (EBRAICO)', value: 'AK24' },
                    { label: 'AK55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (SASSOFONO)', value: 'AK55' },
                    { label: 'AL24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (ARABO)', value: 'AL24' },
                    { label: 'AL55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (TROMBA)', value: 'AL55' },
                    { label: 'AM24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (NEO-GRECO)', value: 'AM24' },
                    { label: 'AM55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (VIOLINO)', value: 'AM55' },
                    { label: 'AN24 LINGUE E CULTURE STRANIERE NEGLI ISTITUTI DI ISTRUZIONE DI II GRADO (PORTOGHESE)', value: 'AN24' },
                    { label: 'AN55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (VIOLONCELLO)', value: 'AN55' },
                    { label: 'AO55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CANTO)', value: 'AO55' },
                    { label: 'AP55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CONTRABBASSO)', value: 'AP55' },
                    { label: 'AQ55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (ORGANO)', value: 'AQ55' },
                    { label: 'AR55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (TROMBONE)', value: 'AR55' },
                    { label: 'AS55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (VIOLA)', value: 'AS55' },
                    { label: 'AT55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (BASSO TUBA)', value: 'AT55' },
                    { label: 'AU55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (CLAVICEMBALO)', value: 'AU55' },
                    { label: 'AV55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (MANDOLINO)', value: 'AV55' },
                    { label: 'AW55 STRUMENTO MUSICALE NEGLI ISTITUTI DI ISTRUZIONE SECONDARIA DI II GRADO (FLAUTO TRAVERSO)', value: 'AW55' },
                    { label: 'B002 CONVERSAZIONE IN LINGUA STRANIERA', value: 'B002' },
                    { label: 'B003 LABORATORI DI FISICA', value: 'B003' },
                    { label: 'B004 LABORATORI DI LIUTERIA', value: 'B004' },
                    { label: 'B005 LABORATORIO DI LOGISTICA', value: 'B005' },
                    { label: 'B006 LABORATORIO DI ODONTOTECNICA', value: 'B006' },
                    { label: 'B007 LABORATORIO DI OTTICA', value: 'B007' },
                    { label: 'B008 LABORATORI DI PRODUZIONE INDUSTRIALI ED ARTIGIANALI DELLA CERAMICA', value: 'B008' },
                    { label: 'B009 LABORATORI DI SCIENZE E TECNOLOGIE AERONAUTICHE', value: 'B009' },
                    { label: 'B010 LABORATORI DI SCIENZE E TECNOLOGIE DELLE COSTRUZIONI AERONAUTICHE', value: 'B010' },
                    { label: 'B011 LABORATORI DI SCIENZE E TECNOLOGIE AGRARIE', value: 'B011' },
                    { label: 'B012 LABORATORI DI SCIENZE E TECNOLOGIE CHIMICHE E MICROBIOLOGICHE', value: 'B012' },
                    { label: 'B013 LABORATORI DI SCIENZE E TECNOLOGIE DELLA CALZATURA E DELLA MODA', value: 'B013' },
                    { label: 'B014 LABORATORI DI SCIENZE E TECNOLOGIE DELLE COSTRUZIONI', value: 'B014' },
                    { label: 'B015 LABORATORI DI SCIENZE E TECNOLOGIE ELETTRICHE ED ELETTRONICHE', value: 'B015' },
                    { label: 'B016 LABORATORI DI SCIENZE E TECNOLOGIE INFORMATICHE', value: 'B016' },
                    { label: 'B017 LABORATORI DI SCIENZE E TECNOLOGIE MECCANICHE', value: 'B017' },
                    { label: 'B018 LABORATORI DI SCIENZE E TECNOLOGIE TESSILI, DELL\'ABBIGLIAMENTO E DELLA MODA', value: 'B018' },
                    { label: 'B019 LABORATORI DI SERVIZI DI RICETTIVITA\' ALBERGHIERA', value: 'B019' },
                    { label: 'B020 LABORATORI DI SERVIZI ENOGASTRONOMICI, SETTORE CUCINA', value: 'B020' },
                    { label: 'B021 LABORATORI DI SERVIZI ENOGASTRONOMICI, SETTORE SALA E VENDITA', value: 'B021' },
                    { label: 'B022 LABORATORI DI TECNOLOGIE E TECNICHE DELLE COMUNICAZIONI MULTIMEDIALI', value: 'B022' },
                    { label: 'B023 LABORATORI PER I SERVIZI SOCIO-SANITARI', value: 'B023' },
                    { label: 'B024 LABORATORIO DI SCIENZE E TECNOLOGIE NAUTICHE', value: 'B024' },
                    { label: 'B025 LABORATORIO DI SCIENZE E TECNOLOGIE DELLE COSTRUZIONI NAVALI', value: 'B025' },
                    { label: 'B026 LABORATORIO DI TECNOLOGIE DEL LEGNO', value: 'B026' },
                    { label: 'B027 LABORATORIO DI TECNOLOGIE DEL MARMO', value: 'B027' },
                    { label: 'B028 LABORATORIO DI TECNOLOGIE ORAFE', value: 'B028' },
                    { label: 'B030 ADDETTO ALL\'UFFICIO TECNICO', value: 'B030' },
                    { label: 'BA02 CONVERSAZIONE IN LINGUA STRANIERA (FRANCESE)', value: 'BA02' },
                    { label: 'BB02 CONVERSAZIONE IN LINGUA STRANIERA (INGLESE)', value: 'BB02' },
                    { label: 'BC02 CONVERSAZIONE IN LINGUA STRANIERA (SPAGNOLO)', value: 'BC02' },
                    { label: 'BD02 CONVERSAZIONE IN LINGUA STRANIERA (TEDESCO)', value: 'BD02' },
                    { label: 'BE02 CONVERSAZIONE IN LINGUA STRANIERA (RUSSO)', value: 'BE02' },
                    { label: 'BF02 CONVERSAZIONE IN LINGUA STRANIERA (ALBANESE)', value: 'BF02' },
                    { label: 'BG02 CONVERSAZIONE IN LINGUA STRANIERA (SLOVENO)', value: 'BG02' },
                    { label: 'BH02 CONVERSAZIONE IN LINGUA STRANIERA (SERBO-CROATO)', value: 'BH02' },
                    { label: 'BI02 CONVERSAZIONE IN LINGUA STRANIERA (CINESE)', value: 'BI02' },
                    { label: 'BJ02 CONVERSAZIONE IN LINGUA STRANIERA (GIAPPONESE)', value: 'BJ02' },
                    { label: 'BK02 CONVERSAZIONE IN LINGUA STRANIERA (EBRAICO)', value: 'BK02' },
                    { label: 'BL02 CONVERSAZIONE IN LINGUA STRANIERA (ARABO)', value: 'BL02' },
                    { label: 'BM02 CONVERSAZIONE IN LINGUA STRANIERA (NEO-GRECO)', value: 'BM02' },
                    { label: 'BN02 CONVERSAZIONE IN LINGUA STRANIERA (PORTOGHESE)', value: 'BN02' },
                ]
            }
        }

    getValue() {

        const pluginValue = {
            schoolType: this.state.schoolType,
            competitionClass: this.state.competitionClass,        
            accept12: this.state.accept12,
            accept13: this.state.accept13,
            accept14: this.state.accept14,
            accept15: this.state.accept15,
        };

        return pluginValue;
    }

    checkValidation() {

        let validationError = '';
        let isValid = true;
        const { schoolType, competitionClass } = this.state;

        if (schoolType === '') {
            validationError += '<li>Dovresti selezionare un ordine e grado di scuola</li>';
            isValid = false;
        }

        if (competitionClass === '') {
            validationError += '<li>Dovresti selezionare una classe di concorso</li>';
            isValid = false;
        }

        if (!isValid) validationError = '<ul>' + validationError + '</ul>';
        this.setState({ validationError })
        return (isValid);
    }

    onChangeSchoolType = (id, value) => {

        this.setState({ schoolType: value });
    }

    onChangeCompetitionClass = (id, value) => {

        this.setState({ competitionClass: value });
    }
    
    onConditionClick = (id, value) => {

        this.setState({ [id]: value });
    }

    render() {

        let payloadClass = this.PAYLOAD_COMPETITION_CLASS[this.state.schoolType];
        if (!payloadClass) {
            payloadClass = { options: [] };
            payloadClass.options.push({ label: 'SELEZIONARE ORDINE E GRADO DI SCUOLA', value: '' });
        }

        return (
            <div>
                <div className='text-center'>
                    <h5>TFA SOSTEGNO</h5>                
                </div>
                <h5>Ordine e Grado di Scuola per cui si vuole conseguire la	Specializzazione per le attività di sostegno</h5>
                <MSelect onChange={this.onChangeSchoolType} id="schoolType" payload={this.PAYLOAD_SCHOOL}></MSelect>
                <hr />
                <h5>Posseggo tutti i requisiti previsti dalla normativa vigente per l’accesso alla seguente classe di concorso</h5>
                <MSelect onChange={this.onChangeCompetitionClass} id="completitionClass" payload={payloadClass}></MSelect>
                <hr />
               <h5>Eventuali necessità del Candidato</h5>
                <MCheckbox
                    onChange={this.onConditionClick} id="accept12">Candidato/a con disturbi previsti dalla Legge 170/2010 o con Necessità di trattamento individualizzato </MCheckbox>
                <hr />
                <h5>Eventuale esonero dalle prove preselettive</h5>
                <MCheckbox onChange={this.onConditionClick} id="accept13">Dichiaro di avere prestato almeno tre anni di servizio nei dieci anni precedenti sullo specifico posto di sostegno del grado per cui si concorre (N.B. Una annualità di servizio è considerata tale se il servizio prestato sullo stesso grado per cui si concorre è uguale o superiore a 180 giorni oppure se lo stesso è prestato dal primo febbraio fino al termine delle operazioni di scrutinio finale).</MCheckbox>
                <MCheckbox onChange={this.onConditionClick} id="accept14">Dichiaro di avere un'invalidità maggiore o uguale all'80% ed allego la relativa certificazione medica in un file elettronico in formato PDF.</MCheckbox>
                <hr /><h5>Altre dichiarazioni</h5>
                <MCheckbox onChange={this.onConditionClick} id="accept15">Dichiaro di essere già in possesso del titolo di specializzazione sul sostegno in un altro grado di istruzione e risulto utilmente collocato/a in graduatoria di merito del presente ciclo ovvero ammesso/a in sovrannumero ai sensi dell’art. 4, comma 4, del D.M. n. 92/2019.</MCheckbox>
                <hr />
                {this.state.validationError && (<><span className="form-text text-danger is-invalid">{ReactHtmlParser(this.state.validationError)}</span></>)}
            </div>
        )
    }
}