import React, { Component } from 'react';
import { Toggle, FormGroup, FormText } from 'design-react-kit';
import { formHelper } from 'libs/helpers';

export class MToggle extends Component {

    state = {
        checked: null
    }

    onClick = (event) => {

        const checked = event.target.checked;
        this.setState({ checked: checked });

        const onChange = this.props.onChange;
        if (formHelper.isFunction(onChange)) {
            onChange(this.props.id, checked);
        }

    };

    componentDidMount() {       
        this.setState({ checked: this.props.value });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            checked: nextProps.value
        })
    }

    /**
     * INFO: da utilizzare solamente con campi in db ti tipo boolean
     * altrimenti fare la trasformazione dei valori in true/false nella form.
     * 
     * PROPS: 
     * id       : identifier 
     * label    : example text (brief description of fields)     
     * infoText : addtional text used when the input in invalid
     * disabled : insert for readonly 
     * value    : the value
     */
    render() {

        // get properties
        const { id, name,
            disabled,
            label, infoText
        } = this.props;

        // map properites
        const myProps = {
            id, name,
            disabled,
            label,
        };

        if (this.state.checked){
            myProps.defaultChecked = 'defaultChecked';
        }

        return (
            <>
                <FormGroup check className="form-check-group mb-4">
                    <Toggle
                        {...myProps}
                        onClick={e => this.onClick(e)}
                    />

                    {infoText && (<FormText color="red">{infoText}
                    </FormText>)}
                </FormGroup>
            </>
        );
    }
}