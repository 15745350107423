import { Title, MSection, MnemoLoading, BoxDanger, SupportoTecnico } from "components/misc";
import { ROUTES } from "const";
import { Icon } from "design-react-kit";
import { courseService, learningService, networkErrorHelper } from "libs";
import React, { Component } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import { getCurrentUser } from "store/actions";


class ESBLesson extends Component {

    state = {
        loading: true,
        loadingError: false,
        course: 0,
        rCode: ''
    };

    goBack() {
        window.history.back();
    }

    componentDidMount() {

        const { rCode } = this.props.match.params;
        this.loadRemote(rCode);
    }

    loadRemote(rCode) {

        learningService
            .courseDashboard(rCode)
            .then(({ data }) => {

                const { course, userCourse } = data.payload;
                this.setState({ course, userCourse });

                courseService.getMFPassword(rCode)
                    .then(({ data }) => {
                        const password = data.payload;
                        if (password) {
                            this.setState({ loading: false, loadingError: false, password, rCode });
                        } else {
                            this.setState({ loading: false, loadingError: true, rCode });
                        }
                    })
                    .catch(error => {
                        networkErrorHelper.notify(error);
                        this.setState({ loading: false, loadingError: true });
                    });

            })
            .catch(error => {

                networkErrorHelper.notify(error);
                this.setState({ loading: false, loadingError: true });
            });
    }

    render() {

        const { rCode } = this.props.match.params;
        const { loading, loadingError, password } = this.state;
        if (loading) return (<MnemoLoading></MnemoLoading>);

        const user = getCurrentUser();

        return (
            <>
                <NavLink to={`${ROUTES.COURSE_DASHBOARD}/${rCode}`} className="mx-5 btn btn-outline-primary float-right"><Icon icon="it-presentation" /> TORNA ALLA HOME PAGE DEL CORSO</NavLink>
                <Title >Mediterranea Formazione</Title>
                <MSection>
                    <h6><a href="https://www.mediterraneaformazione.it/login/index.php" target="_blank" rel="noopener noreferrer" >Clicca qui per accedere al materiale didattico</a></h6>
                    <footer className="blockquote-footer">https://www.mediterraneaformazione.it/login/index.php</footer>
                </MSection>
                <MSection className="mt-2">
                    {(loadingError) ? (<>
                        <BoxDanger>Si è verificato un errore durante
                            il caricamento dei dati del corso, se il problema persiste <SupportoTecnico /></BoxDanger>
                    </>) : (<>
                        <p>Dal suddetto link potrai visionare le lezioni del corso richiesto, integrare lo studio con il materiale teorico, le relative esercitazioni e i
                            corrispondenti approfondimenti da noi proposti, per poi completare il percorso con le simulazioni d’esami.
                            Solo a quel punto si potranno prenotare fino a quattro incontri Skype, tenuti dai nostri tutor e docenti madrelingua,
                            per le esercitazioni relative allo "Speaking".</p>
                        <p></p>
                        <ul>
                            <li><b>username:</b> {user.email} </li>
                            <li><b>password:</b> {password}</li>
                        </ul>
                        <p>In caso di necessità utilizza i seguenti servizi di assistenza:</p>
                        <ul>
                            <li>Assistenza On Line:
                                <ul>
                                    <li><a href="mailto:didattica@mediterraneaformazione.it">didattica@mediterraneaformazione.it</a></li>
                                    <li><a href="mailto:assistenzatecnica@mediterraneaformazione.it">assistenzatecnica@mediterraneaformazione.it</a></li>
                                </ul>
                            </li>
                            <li>Assistenza telefonica (dalle h 10.00 alle h 13.00 e dalle h 15.30 alle h 18.30 di tutti i giorni escluso il sabato):
                                <ul>
                                    <li>Area didattica <a href="tel:+39 334 5991460" > 334 5991460</a>, <a href="tel:+39 3275498829" >3275498829</a>,
                                        <a href="tel:+39 3773428498" >3773428498</a>
                                        , <a href="tel:+39 0922 1838106" >0922 1838106</a>, <a href="tel:+39 0922 1838107" >0922 1838107</a></li>
                                    <li>Area tecnica <a href="tel:+39 351 8535555" >351 8535555</a>, <a href="tel:+39 0922 1838105" >0922 1838105</a></li>
                                </ul>
                            </li>
                            <li>Assistenza Amministrativa (dalle ore 09.00 alle ore 13.00 e dalle ore 15.00 alle ore 18.00 dal  lunedì al venerdì):
                                <ul>
                                    <li>Area Amministrativa: <a href="tel:+39" >0925 32656</a> - <a href="mailto:corsilingue@formazionedocenti.it">corsilingue@formazionedocenti.it</a></li>
                                </ul>
                            </li>
                        </ul>
                    </>)}
                </MSection>
            </>);
    }
}

export default withRouter(ESBLesson);