import React, { Component } from "react";
import { adminService, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { BoxDanger } from 'components/misc';

import {
  MnemoLoading,
  ModalHandler
} from "components/misc";

import { NavLink } from 'react-router-dom';
import { ROUTES } from 'const';

import "./CourseManager.css";

class CourseTypeManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        payload: [],
        searchFilter: {
          searchFilter: {}
        },
        rndKey: 0,
    }
  }


  //--- ---
  tableConfig = {
    columns: [
      {
        id: 'id', // identifier of columns
        Header: 'Column 0',
        isVisible: false, // IMPORTANT: hidden columns
        accessor: 'id', // accessor is the "key" in the data

      },
      {
        id: 'code',
        Header: 'Codice',
        accessor: 'code', // accessor is the "key" in the data

      },
      {
        id: 'title',
        Header: 'Titolo',
        accessor: 'title',
      }
    ],
    actions: [
      {
        id: 'detail',
        accessor: 'id',
        label: 'Dettagli',
        icon: 'it-file',
        className: 'MTable-actions',
        onClick: (e, value) => {
          //console.log('dettagli clicked',value)
          window.location.href = ROUTES.COURSE_TYPE_FORM + '/' + value;
        }
      },
      {
        id: 'delete',
        accessor: 'id', // field value received in the onclick event
        label: 'Elimina',
        icon: 'it-delete',
        className: 'MTable-actions',
        onClick: (e, value) => { this.deleteRecord(e, value) }
      }
    ]
  }
  //--- ---


  componentDidMount() {
    this.loadAllCourseType(this.state.searchFilter);
  }


  loadAllCourseType(filter) {
    adminService
      .loadAllCourseType(filter)
      .then(({ data }) => {
          
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.courseTypes).forEach(([key, val]) => {
          items.push(val);
        });
        //--- end: prepare reactTable ---

        this.setState({
          loading: false,
          payload: items,
          rndKey: Math.floor((Math.random() * 1000) + 1)
        });

      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }else{
          this.setState({
            payload:[],
            rndKey: Math.floor((Math.random() * 1000) + 1)
          })          
        }
      });
  }

  

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show(value, 'Eliminazione definitiva del dato',
      'vuoi davvero eliminare?', null, this.deletePermanentlyConfirmed);
  }

  deletePermanentlyConfirmed = (value) => {
    adminService.deleteCourseType(value).then(({ data }) => {    
      this.setState({ loading: true });
      this.loadAllCourseType(this.state.searchFilter);
    })
      .catch(error => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  }
  //--- end functions to delete procedure ---



  render() {

    const { loading } = this.state;

    return (
        <>
            <div className="container mt-3 mb-4">
                <section className="m-0">
                    <div className="form-row">
                        <div className="col-md-12 text-right">
                            <NavLink to={ROUTES.COURSE_TYPE_FORM} className="btn btn-primary bg-dark mx-1">Nuovo</NavLink>                        </div>
                    </div>
                </section>

                <hr />

                <section className="my-4">
                {loading ? (<MnemoLoading></MnemoLoading>) : (
                    <Container>
                    <MTable
                        key={this.state.rndKey}
                        tableData={this.state.payload}
                        tableConfig={this.state.searchFilter.searchFilter.deleted ? this.tableConfigDelete : this.tableConfig}
                    />
                    </Container>
                )}            

                {(this.state.payload.length === 0) && (<BoxDanger key="nrp-1" className="my-3">
                    Ricerca non valida.
                    <div className="small text-info">
                        Nessun dato disponibile.
                    </div>
                </BoxDanger>)}
                </section>

            </div>
        </>
    );
  }
}

export default CourseTypeManager;
