import React from "react";

import { 
    ValidatedForm,
    MFile,
} from "components/forms";

import { BoxDanger, SupportoTecnico } from "components/misc";

import {
    payloadBuilder,
    notNullObjectBuilder
} from "libs";

export class EnrollPayPAgoPaOfflineForm extends ValidatedForm {

    emptyFields = {
        importToPay: 0,
        paymentType: '',
        document: 0,
        datePayment: ''
    };

    state = {
        upload1:false,
        upload2:false,
        paymentSelected: '',
        loading: true,
        loadingError: false,
        ...this.emptyFields,
        defaultValues: {
            ...this.emptyFields
        }
    };

    constructor(props) {
        super(props);

        // bind event handler to invoke it from child component
        this.onChange = this.onChange.bind(this);
    }

    getPayload = () => {
        return this.payload;
    }

    loadRemote(additionalState = {}) {

        // merge not null values and format dates
        const defaultValues = notNullObjectBuilder(
            this.emptyFields,
            this.props.enrollData,
        );

        const newState = {
            defaultValues,
            loading: false,
            ...additionalState,
        };

        this.setState(newState);
    }

    canNext(){
        const {upload1, upload2} =this.state;
        return (upload1 && upload2); 
    }
    onUploadDone = (value) => {

        if (value===1){
            this.setState({upload1:true});
        }else{
            this.setState({upload2:true});
        }
    }
  
    saveRemote = () => {

        this.payload = payloadBuilder(this.state);

        const validations = {
            importToPay: null,
            paymentType: null,
        }

        this.setState({ formActive: false, ...validations });
    };

    render() {

        const { loading, loadingError } = this.state;
        if (loading) return <></>;
        if (loadingError) return <BoxDanger>{loadingError}, <SupportoTecnico /></BoxDanger>;

        const {id, idPayment, isPaymentReferred } = this.props.enrollData;
        const import_description = isPaymentReferred ? "I RATA" : "UNICA SOLUZIONE";
        const config1 = { endPoint: '/enrollment/payment-file', fieldName: 'document', maxFiles: 1, mandatory: true }
        const config2 = { endPoint: '/enrollment/attachment-file', fieldName: 'document', maxFiles: 1, mandatory: true }

        return (<>
            <div className="mb-5">
                <h2>Effettuare i due distinti pagamenti come di seguito:</h2>
                <h3>1. {import_description}</h3>
                Tramite pagoPA raggiungibile al seguente link <a href="https://pagopa.cons.cz.it" rel="noopener noreferrer" target="_blank">https://pagopa.cons.cz.it</a>,
                selezionando come Tipologia del versamento "CONTRIBUTO ISCRIZIONE 30 CFA ART 13 DPCM 4/8/2023 {import_description}";
                <MFile
                    value={idPayment}
                    id="document"
                    field="document"
                    name="document"
                    label="Ricevuta di pagamento"
                    infoText="Caricare le ricevuta del PagoPa che hai pagato"
                    config={config1}
                    onUploadDone={() => {this.onUploadDone(1)}}
                    className="col-md-12"
                />

                <hr />
                <h3>2. Bollo</h3>
                Per il pagamento del bollo, tramite pagoPA raggiungibile al seguente link
                <a href="https://pagopa.cons.cz.it" rel="noopener noreferrer" target="_blank">https://pagopa.cons.cz.it</a>,
                selezionare come Tipologia del versamento "Imposta di Bollo 16€".
                <MFile
                   value={id}
                   id="document"
                   field="document"
                   name="document"
                   label="Ricevuta di pagamento"
                   infoText="Caricare le ricevuta del PagoPa che hai pagato"
                   config={config2}
                   className="col-md-12"
                   onUploadDone={() => {this.onUploadDone(2)}}
                />
            </div>

        </>);
    }
}