import { blogService } from 'libs';
import React, { Component } from 'react';
import { ROUTES } from 'const';
import { CardNewsCategory } from './CardNewsCategory';

export class BlogSidebar extends Component {

    dimTop = 5;

    state = {
        categories: [],
        articles: [],
        articlesTopFixed: [],
        loading: true,
        loadingError: false,
    };

    componentDidMount() {
        this.loadRemote();
    }

    //--- ---

    loadRemote() {

        this.setState({
            loading: true,
            loadingError: false
        });

        /** */
        blogService.topCategories()
            .then(({ data }) => {

                const { categories } = data.payload;

                this.setState({
                    categories,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, /*loadingError: true*/ });
            });

        /** */
        blogService.topArticles(this.dimTop)
            .then(({ data }) => {

                const { articles } = data.payload;

                this.setState({
                    articles,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false, /*loadingError: true*/ });
            });

        /** */
        blogService.topFixedArticles(this.dimTop)
            .then(({ data }) => {

                const { articlesTopFixed } = data.payload;

                this.setState({
                    articlesTopFixed,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false/*, loadingError: true*/ });
            });
    }


    //--- ---


    render() {

        const { loading, loadingError, categories, articles, articlesTopFixed } = this.state;

        if (loading || loadingError) return (<></>);

        return (<>
            { /*   
        TODO: fare box di ricerca
        <div className="articleitem-card" >
                <div className='articleitem-card__body'>
                    <h6>Cerca</h6>
                    <MInput
                        id="search"
                        label="cerca negli articoli"
                    />
                </div>
        </div> */}

            {(categories && categories.length > 0) && (
                <div className="articleitem-card" >
                    <div className='articleitem-card__body'>
                        <h6>Categorie</h6>
                        <CardNewsCategory />
                    </div>
                </div>
            )}
            
            {(articlesTopFixed && articlesTopFixed.length > 0) && (
                <div className="articleitem-card" >
                    <div className='articleitem-card__body'>
                        <h6>Articoli in Evidenza</h6>
                        <ul className="list-left">
                            {articlesTopFixed.map((article, i) => (
                                <li key={`article-${i}`} >
                                    <a className="link-light" title={article.slug} href={`${ROUTES.BLOG_ARTICLE}/${article.slug}`} >{article.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
           )}

            {(articles && articles.length > 0) && (
                <div className="articleitem-card" >
                    <div className='articleitem-card__body'>
                        <h6>Articoli piu letti</h6>
                        <ul className="list-left">
                            {articles.map((article, i) => (

                                <li key={`article-${i}`} >
                                    <a className="link-light" title={article.slug} href={`${ROUTES.BLOG_ARTICLE}/${article.slug}`} >{article.title}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>)
    }
}