import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';
import { withRouter } from 'react-router-dom';
import { AdminLessonLiveEditionForm } from "components/forms/admin/AdminLessonLiveEditionForm";


class LessonLiveEditionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
        idLessonLiveEdition: parseInt(this.props.match.params.idLessonLiveEdition) > 0 ? this.props.match.params.idLessonLiveEdition : 0
    }
  }

  //---

  render() {

    const { idLessonLiveEdition } = this.state

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{(idLessonLiveEdition > 0) ? 'Aggiorna Edizione' : 'Crea Edizione'}</Title>
            <AdminLessonLiveEditionForm idLessonLiveEdition={idLessonLiveEdition || 0}/>
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(LessonLiveEditionForm);
