import React, { Component } from 'react';
import { MnemoLoading } from 'components/misc';
import { adminService, networkErrorHelper, dateHelper } from "libs";
import { MTable } from "components/table";
import { Container } from 'design-react-kit';
import { ROUTES } from 'const';


export class Siteconfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rndKey: 0,
            payload: [],
            loading: true
        };
    }

    //--- ---

    tableConfig = {
        columns: [
            {
                id: 'code',
                Header: 'Codice',
                accessor: 'code',
                className: 'w-75',
            },
            {
                id: 'updateAt',
                Header: 'aggiornato il',
                className: 'w-25',
                accessor: row => { return (row.updatedAt) ? dateHelper.toITDate(row.updatedAt) : ''},
            }
        ],
        actions: [
            {
                id: 'detail',
                accessor: 'id',
                label: 'Dettagli',
                icon: 'it-file',
                className: 'MTable-actions',
                onClick: (e, value) => {
                    window.location.href = ROUTES.SITECONFIG_FORM + '/' + value;
                }
            }
        ]
    }

    //--- ---

    componentDidMount(){
        this.loadRemote();
    }

    loadRemote() {
        this.setState({ loading: true });

        adminService.getSiteconfig().then(({ data }) => {            
            if(!data.payload.siteConfig){return false;}

			//--- start: prepare reactTable ---
			const items = [];
			Object.entries(data.payload.siteConfig).forEach(([key, val]) => {
				items.push(val);
			});

			this.setState({
				loading: false,
				payload: items,
				rndKey: Math.floor((Math.random() * 1000) + 1)
			});
			//--- end: prepare reactTable ---
        })
        .catch(error => {              
            if (!networkErrorHelper.is404(error)) {
                console.log(error);
                networkErrorHelper.notify(error);
            }

            this.setState({ loading: false });
        });
    }

    //--- ---

    render() {

        const { loading } = this.state;
        
        return (
            <>
            <section className="my-4">
            {loading ? (<MnemoLoading></MnemoLoading>) : (
                <Container>
                    <MTable
                        key={this.state.rndKey}
                        tableData={this.state.payload}
                        tableConfig={this.tableConfig}
                    />
                </Container>
            )}
            </section>
            </>
        );
    }

}