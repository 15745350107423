import React, { Component } from "react";
import { Title } from "components/misc";
import { Container } from 'design-react-kit';

import { withRouter } from 'react-router-dom';
import { AdminBlogArticle } from "components/forms/admin/AdminBlogArticle";

class BlogCmsArticleForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      idBlogArticle: parseInt(this.props.match.params.idBlogArticle) > 0 ? this.props.match.params.idBlogArticle : 0,
      title: parseInt(this.props.match.params.idBlogArticle) > 0 ? 'Gestione Articolo' : 'Nuovo Articolo',
    }
  }

  //--- ---

  render() {

    return (
      <>
        <section className="my-4">
          <Container>
            <Title>{this.state.title}</Title>
            {<AdminBlogArticle idBlogArticle={this.state.idBlogArticle} />}
          </Container>
        </section>
      </>
    );
  }

}

export default withRouter(BlogCmsArticleForm);
