import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

export class ErrorNotification {

    static render( message, title){
       
        store.addNotification({
            title: title,
            message: message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });        
    }    
}