import {proxyApiMnemo} from './common';
import { mnemoService } from 'libs/api';
import Axios from 'axios';

export const paymentService = {

    sendPost(aa, url, payload='') {

        const aaURL = aa.toString().slice(-2);
        const completeUrl = process.env.REACT_APP_CRM_URL.replace('[yearTag]', aaURL) + url;

        return Axios.post(completeUrl, payload);
    },

    myPayments(rCode) {
        const payload = mnemoService.buildPayload('list', 'registrationCode', rCode);
        return proxyApiMnemo.get('payment/?payload='+encodeURI(payload));
    },   

    isEnabled(aa, rCode) {

        const url = 'moodleElearningPayment/isPaymentEnabled/rCode/'+rCode;        
        return this.sendPost(aa, url)
    },

    gompPaymentRenew(orderNumber) {
        const payload = mnemoService.buildPayload('renewPayment', 'orderNumber', orderNumber);
        return proxyApiMnemo.get('gomp/?payload='+encodeURI(payload));
    }, 
}