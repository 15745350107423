import React, { Component } from 'react';

export class Countdown extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            time: this.props.params.duration*60 /*force conversion minutes to seconds */
        };
    }


    componentDidMount(){
        if(this.state.time > 0){
            this.handleStart();
        }else{
            this.props.params.onEnd();
        }
    }


    formatCountdown(time) {
        let seconds = parseInt(time % 60);
        let minutes = Math.floor(time / 60);

        if(time > 0){
            minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
            seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
        }else{
            minutes = "00";
            seconds = "00";
        }

        return minutes + ':' + seconds;
    }

    handleStart() {
        this.timer = setInterval(() => {
            this.setState({
                time: this.state.time-1
            })

            //--- stop counter ---
            if(this.state.time <= 0){
                this.handleStop();
            }
        }, 1000);
    }//handleStart

    handleStop() {
        clearInterval(this.timer);
        this.props.params.onEnd()
    }//handleStop


    //--- ---

    render () {

      return (
        <div>
          <h1>{this.formatCountdown(this.state.time)}</h1>
        </div>
      )
    }
}